import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment Clergy on Funeral {
        Clergy {
            ${BookingsFragmentInclude}
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementClergy on Prearrangement {
        Clergy {
            ID
            Comment
            Requested
            Responded
            Confirmed
            Notes
            Contact {
                ID
                Name
            }
        }
    }
`;
