import gql from 'graphql-tag';

export default gql`
    fragment CoffinBearings on Funeral {
        CoffinBearings {
            ID
            Type
            Bearers
            Address
            Date
            Time
            Duration
            NumberOfStaffRequired
            NumberOfFamilyRequired
            Comment
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementCoffinBearings on Prearrangement {
        CoffinBearings {
            ID
            Type
            Bearers
            Address
            Date
            Time
            Duration
            NumberOfStaffRequired
            NumberOfFamilyRequired
            Comment
        }
    }
`;
