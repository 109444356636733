import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from './Grid';
import { Button } from '@material-ui/core';
import AddressAutocomplete, { COMPONENT } from './AddressAutocomplete';
import TextField from './TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

//styles needs to be defined first
const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: 17
    }
});

const CONTACT_COMPONENT_FIELDS = {
    [COMPONENT.line1]: 'AddressLine1',
    [COMPONENT.line2]: 'AddressLine2',
    [COMPONENT.city]: 'Suburb',
    [COMPONENT.state]: 'State',
    [COMPONENT.country]: 'Country',
    [COMPONENT.code]: 'Postcode'
};

/**
 * modal dialogue used to edit the contact card info
 */
class ContactCardModal extends Component {
    static defaultProps = {
        title: 'Enter Contact Details',
        applyLabel: undefined,
        onClose: () => {}
    };

    state = {};

    getId() {
        return `ContactCardModal-${this.props.title}`;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onCancel = () => {
        this.props.onClose();
    };

    onSubmit = () => e => {
        e.preventDefault();

        const { onSubmit } = this.props;

        if (!onSubmit) return;

        onSubmit({
            ID: this.state.ID,
            Name: this.state.Name,
            Phone: this.state.Phone,
            Email: this.state.Email,
            AddressLine1: this.state.AddressLine1,
            AddressLine2: this.state.AddressLine2,
            Suburb: this.state.Suburb,
            Postcode: this.state.Postcode,
            Country: this.state.Country
        });

        this.onCompleted();
    };

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps;
        this.setState({
            applyLabel: value && value.ID ? 'Save' : 'Create',
            ...value
        });
    }

    onCompleted = contact => {
        const { onClose } = this.props;
        if (onClose) onClose(contact);
        const clearedState = Object.keys(this.state).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});
        this.setState(clearedState);
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.onClose} aria-labelledby={this.getId()}>
                {this.renderForm()}
            </Dialog>
        );
    }

    renderForm() {
        const { title } = this.props;
        const { applyLabel } = this.state;
        return (
            <form onSubmit={this.onSubmit()}>
                <DialogTitle id={this.getId()}>{title}</DialogTitle>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.renderTextField('Name', 'Name', true, true)}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.renderAddressField('Address', 'Address', 'Search for an address')}
                        </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            {this.renderTextField('Phone', 'Phone')}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderTextField('Email', 'Email')}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        {applyLabel}
                    </Button>
                </DialogActions>
            </form>
        );
    }

    renderTextField(name, label, autoFocus, required) {
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onChange}
            />
        );
    }

    renderAddressField(name, label, placeholder) {
        const { classes } = this.props;
        return (
            <div className={classes.alignWithFloatingLabel}>
                <AddressAutocomplete
                    label={label}
                    name={name}
                    margin="dense"
                    placeholder={placeholder}
                    componentFields={CONTACT_COMPONENT_FIELDS}
                    form={this}
                />
            </div>
        );
    }

    getField = propertyName => {
        const value = this.state[propertyName];
        return value;
    };

    setField = state => {
        this.setState(state);
    };
}

export default withStyles(styles)(ContactCardModal);
