import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import gql from 'graphql-tag';
import GraphqlGrid from '../../component/form/v2/GraphqlGrid';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class Music extends Component {
    musicQuery = gql`
        query musicQuery($limit: Int, $offset: Int, $filterBy: MusicLibraryFilterFields) {
            readMusicLibraries(limit: $limit, offset: $offset, filter: $filterBy) {
                edges {
                    node {
                        ID
                        SongName
                        Artist
                        Album
                        TrackLocation
                        LastEdited
                        Created
                    }
                }
                pageInfo {
                    totalCount
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    `;

    musicColumns = [
        {
            name: 'ID',
            queryField: 'node.ID',
            gqlSortField: 'Created',
            hidden: true
            //  render: (val, { node }) => {}
        },
        {
            name: 'Song Title',
            queryField: 'node.SongName',
            gqlFilterField: 'SongName',
            noWrap: true
            // render: (val, { node }) => {}
        },
        {
            name: 'Artist',
            queryField: 'node.Artist',
            gqlFilterField: 'Artist',
            noWrap: true
            //     render: (val, { node }) => {}
        },
        {
            name: 'Album',
            queryField: 'node.Album',
            gqlFilterField: 'Album',
            noWrap: true
            //   render: (val, { node }) => {}
        },
        {
            name: 'Storage',
            queryField: 'node.TrackLocation',
            //gqlSortField: 'TrackLocation',
            noWrap: true
            //   render: (val, { node }) => {}
        }
    ];

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>Music</span>
                    </InlineFieldRightAlignChildren>
                </Typography>

                <br />
                <Paper className={classes.paper} elevation={0}>
                    <div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <GraphqlGrid
                                    hideSearch
                                    columns={this.musicColumns}
                                    noResultsMessage={
                                        <Typography>
                                            No enquiries matched your search.
                                            <br />
                                            <small>Try different keywords or filters.</small>
                                        </Typography>
                                    }
                                    gqlReadQuery={this.musicQuery}
                                    gqlReadDataSelector={'readMusicLibraries.edges'}
                                    gqlReadPageInfoSelector={'readMusicLibraries.pageInfo'}
                                    gqlPageSizes={[5, 10, 25, 50]}
                                    onRowClick={(evt, row) => {
                                        evt.preventDefault();
                                        //    history.push(row.ID); // hidden ID field renders the link
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Fragment>
        );
    }
}

const styles = ({ spacing, breakpoints, palette, funeralHome }) => ({
    paper: {
        padding: 42,
        [breakpoints.down('md')]: {
            padding: 16
        }
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(Music), {
    fallback: ErrorFallback
});
