import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../../../component/form/TextField';
import SearchIcon from '../../../component/icon/SearchIcon';
import InputAdornment from '@material-ui/core/InputAdornment';

class SearchWithButton extends Component {
    state = {
        searchWords: ''
    };

    render() {
        const { placeholder, className } = this.props;

        return (
            <div className="search-field">
                <form onSubmit={this.onSearchSubmit}>
                    <TextField
                        type="search"
                        placeholder={placeholder}
                        className={className}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <div className="input-adornment--end" onClick={this.onSearchSubmit}>
                                        <SearchIcon />
                                    </div>
                                </InputAdornment>
                            ),
                            inputProps: {
                                ref: this.onSearchFieldRef
                            }
                        }}
                        onChange={e => this.setState({ searchWords: e.target.value })}
                        value={this.state.searchWords}
                    />
                </form>
            </div>
        );
    }

    onSearchSubmit = e => {
        if (e) e.preventDefault();

        if (this.searchFieldInput) {
            let { onSearchSubmit } = this.props;
            if (onSearchSubmit) {
                onSearchSubmit(this.searchFieldInput.value);
            }
        }
    };

    onSearchFieldRef = input => {
        this.searchFieldInput = input;
    };
}

export default withStyles({})(SearchWithButton);
