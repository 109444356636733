import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import Clear from '@material-ui/icons/Clear';
import { stringIsNullOrEmpty } from '../../../util/strings';
import InfoCircleIcon from '../../../component/icon/InfoCircleIcon';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import ExclamationCircleIcon from '../../../component/icon/ExclamationCircleIcon';
import ExclamationTriangleIcon from '../../../component/icon/ExclamationTriangleIcon';
import Button from './Button';
import Inline from './Inline';

class AlertModal extends Component {
    render() {
        const { open, onClose, variant } = this.props;

        return (
            <Modal
                className="workqueue-page"
                open={open}
                aria-labelledby="alert-modal-title"
                aria-describedby="alert-modal-description"
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
            >
                <div className={`modal modal--alert modal--alert--${variant || 'primary'}`}>
                    <div className="paper">
                        <Fab color="primary" aria-label="Close" className="fab close" onClick={() => onClose()}>
                            <Clear />
                        </Fab>

                        {this.renderContent()}
                    </div>
                </div>
            </Modal>
        );
    }

    renderContent() {
        const {
            children,
            variant,
            primaryAction,
            onClickPrimaryAction,
            secondaryAction,
            onClickSecondaryAction,
            onClose
        } = this.props;

        let icon;
        let buttonVariant;
        if (variant === 'save') {
            icon = <TickCircleIcon />;
            buttonVariant = 'confirmation';
        } else if (variant === 'warning') {
            icon = <ExclamationTriangleIcon />;
            buttonVariant = 'urgent';
        } else if (variant === 'error') {
            icon = <ExclamationCircleIcon />;
            buttonVariant = 'decline';
        } else {
            icon = <InfoCircleIcon />;
            buttonVariant = 'primary';
        }

        return (
            <div className="alert-content">
                <div className="icon">{icon}</div>
                <div className="content">{children}</div>
                <Inline>
                    {!stringIsNullOrEmpty(secondaryAction) && (
                        <Button variant="secondary" onClick={onClickSecondaryAction}>
                            {secondaryAction}
                        </Button>
                    )}
                    {!stringIsNullOrEmpty(primaryAction) && (
                        <Button variant={buttonVariant} onClick={onClickPrimaryAction}>
                            {primaryAction}
                        </Button>
                    )}
                    {stringIsNullOrEmpty(primaryAction) && stringIsNullOrEmpty(secondaryAction) && (
                        <Button variant="modal-action modal-action--cancel" onClick={onClose}>
                            Close
                        </Button>
                    )}
                </Inline>
            </div>
        );
    }
}

export default withStyles({})(AlertModal);
