import React, { Component } from 'react';
import { Icon, withStyles } from '@material-ui/core';
import ModalStepTitle from '../../../component/form/ModalStepTitle';
import TableData from '../../../component/form/TableData';
import Radio from '../../../component/form/Radio';
import TextField from '../../../component/form/TextField';
import { prettyPrice } from '../../../util/strings';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import { flattenConnection } from '../../../util/functions';
import { cloneDeep } from 'apollo-utilities';
import { lookupItemSource } from './QuotesModal';
import { getEnquiryClient } from '../../../apollo';
import { WhiteLoader } from '../../../component/WhiteLoader';
import { ProductPriceOverrides, VariationPriceOverrides } from '../../fragments/Product';
import { withRouter } from 'react-router';
import { getOfficeFromKey } from '../../../util/brands';
import { InlineFlex } from '../../../component/form/Inline';
import Select from '../../../component/form/Select';
import { ENQUIRY_TYPES } from '../EnquiryConstants';
import InputAdornment from '@material-ui/core/InputAdornment';
import { GST } from '../../../util/products';
import LinkButton from '../../../component/form/LinkButton';
import { QuotesIcon } from '../../../component/IconIndex';

class QuotesModalChooseTab extends Component {
    state = {
        searchKeyword: '',
        quotesType: this.props.isImminent ? 'ATNEED' : this.props.quotesType,
        priceList: []
    };

    render() {
        const { classes } = this.props;
        const { quotesType } = this.state;
        const variables = {
            notCurrent: false,
            sortBy: [{ field: 'LegacyKey', direction: 'ASC' }]
        };
        if (quotesType) {
            variables.quoteType = quotesType;
        }
        return (
            <div className={classes.root}>
                <div className={classes.modalHeader}>
                    <ModalStepTitle number="One" title="Please select a quote template">
                        {this.renderFilterForm()}
                    </ModalStepTitle>
                </div>
                <Query client={getEnquiryClient()} query={queryQuoteTemplates} variables={variables}>
                    {results => this.renderTemplateList(results)}
                </Query>
            </div>
        );
    }

    renderFilterForm() {
        return (
            <div style={{ position: 'relative' }}>
                <button type="submit" disabled style={{ zIndex: -999, position: 'absolute' }}>
                    just a hack to prevent page submit when enter is pressed...
                </button>
                <InlineFlex>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="primary">search</Icon>
                                </InputAdornment>
                            ),
                            style: { minWidth: 300 }
                        }}
                        type="search"
                        placeholder="Start typing to filter templates"
                        fullWidth={false}
                        value={this.state.searchKeyword}
                        onChange={e => this.setState({ searchKeyword: e.target.value })}
                    />
                    <Select
                        fullWidth={false}
                        placeholder="All quote types"
                        options={ENQUIRY_TYPES}
                        value={this.state.quotesType}
                        onChange={(evt, val) => this.setState({ quotesType: val })}
                    />
                </InlineFlex>
            </div>
        );
    }

    renderTemplateList = ({ error, loading, data }) => {
        if (error) return 'Error!';
        if (loading) return <WhiteLoader actionWord="Loading quote templates" />;

        const { classes, form, match } = this.props;
        const {
            params: { key }
        } = match;
        const office = getOfficeFromKey(key);
        const templates = data && data.readQuoteTemplates && cloneDeep(data.readQuoteTemplates.edges);
        templates && flattenTemplates(templates);

        const { searchKeyword, quotesType, priceList } = this.state;
        const words = (searchKeyword + '')
            .toLowerCase()
            .split(' ')
            .filter(txt => '' !== txt);

        const tableData =
            templates &&
            templates.length &&
            templates.map(e => {
                const officeTotals = JSON.parse(e.OfficeTotals || '[]');
                let Amount = (officeTotals.find(obj => obj.ID === Number(office.ID)) || { Price: null }).Price;
                if (!Amount || isNaN(Amount)) {
                    Amount = (
                        officeTotals.find(obj => office.BrandID && obj.ID === Number(office.BrandID)) || { Price: null }
                    ).Price;
                }
                if (!Amount || isNaN(Amount)) {
                    Amount = (officeTotals.find(obj => obj.ID === 0) || { Price: null }).Price;
                }
                if (!Amount || isNaN(Amount)) {
                    Amount = e.TotalPrice;
                }
                return {
                    ID: e.ID,
                    Description: e.Description,
                    QuoteType: e.QuoteType,
                    LegacyKey: e.LegacyKey,
                    Amount
                };
            });

        const tableDataFiltered =
            tableData &&
            tableData
                .filter(e =>
                    words.every(word => {
                        if (e.ID === word) return true;
                        if ((e.QuoteType + '').toLowerCase().includes(word)) return true;
                        if ((e.Description + '').toLowerCase().includes(word)) return true;
                        return (e.LegacyKey + '').toLowerCase().includes(word);
                    })
                )
                .filter(e => !quotesType || quotesType === e.QuoteType);

        if (tableDataFiltered && tableDataFiltered.length)
            return (
                <div className={classes.dataList}>
                    <TableData
                        columns={[
                            { id: 'LegacyKey', label: 'Template Key' },
                            { id: 'Description', label: 'Quote description' },
                            {
                                id: 'Amount',
                                label: <>Calculate&nbsp;Price</>,
                                render: (val, row) => {
                                    if (!val) {
                                        val = (priceList.find(obj => obj.ID === row.ID) || { Price: null }).Price;
                                    }
                                    return val ? (
                                        prettyPrice(val)
                                    ) : (
                                        <LinkButton
                                            text={<QuotesIcon />}
                                            onClick={() => this.loadQuoteTemplatePrice(row.ID)}
                                        ></LinkButton>
                                    );
                                },
                                styles: { textAlign: 'center' }
                            },
                            {
                                id: 'ID',
                                label: 'Selected',
                                render: val => {
                                    return (
                                        <Radio
                                            checked={form.getField('template') === val}
                                            name={'template'}
                                            value={val}
                                            form={form}
                                            onChange={e => {
                                                this.setTemplate(e, templates);
                                            }}
                                        />
                                    );
                                },
                                styles: { textAlign: 'center' }
                            }
                        ]}
                        data={tableDataFiltered}
                    />
                </div>
            );
        if (tableData && tableData.length)
            return 'No templates match your filter. Consider typing other words or spelling variants.';
        return 'No templates are currently available.';
    };

    loadQuoteTemplatePrice = ID => {
        getEnquiryClient()
            .query({ query: queryQuoteTemplate, variables: { ID } })
            .then(({ data }) => {
                this.setState({ loading: false });
                const Price = this.calculateTotal(data.readOneQuoteTemplate.QuoteTemplateItems.edges);
                this.setState({ priceList: [...this.state.priceList, { ID, Price }] });
            });
    };

    calculateTotal = edges => {
        const totals = [];
        edges.forEach(({ node: e }) => {
            const { Product, Variation } = e;
            if ((!!e.Product && Number(e.Product.ID) > 0) || (!!e.Variation && Number(e.Variation.ID) > 0)) {
                const source = lookupItemSource({ Product, Variation });
                const total =
                    !!e.Complimentary || !!e.Optional ? 0 : source.Cost * (!!source.GST ? GST + 1 : 1) * Number(e.Qty);
                totals.push(total);
            }
        });
        return totals.reduce((sum, obj) => sum + obj, 0);
    };

    setTemplate(event, templates) {
        const { form } = this.props;
        const template = templates.find((obj, i) => {
            return obj.ID === event.target.value;
        });
        const newState = {};
        newState.template = event.target.value;
        newState.quote = null;

        if (!template) {
            form.setField(newState);
            return;
        }

        this.setState({ loading: true });
        getEnquiryClient()
            .query({ query: queryQuoteTemplate, variables: { ID: template.ID } })
            .then(({ data }) => {
                this.setState({ loading: false });
                const quoteTemplate = data.readOneQuoteTemplate;

                newState.quote = {};
                newState.quote.Title = quoteTemplate.Description;
                newState.quote.QuoteTemplateID = quoteTemplate.ID;
                newState.quote.QuoteType = quoteTemplate.QuoteType;
                newState.quote.QuoteItems = quoteTemplate.QuoteTemplateItems.edges
                    .map(({ node: e }) => {
                        const { Product, Variation } = e;
                        const quoteItem = {
                            ID: undefined,
                            Qty: e.Optional ? 0 : Number(e.Qty),
                            Complimentary: e.Complimentary,
                            Optional: e.Optional,
                            Prepaid: false,
                            Product,
                            Variation
                        };
                        const source = lookupItemSource({ Product, Variation });
                        quoteItem.Price = source.Cost;
                        return quoteItem;
                    })
                    .filter(
                        e => (!!e.Product && Number(e.Product.ID) > 0) || (!!e.Variation && Number(e.Variation.ID) > 0)
                    );
                const Price = this.calculateTotal(quoteTemplate.QuoteTemplateItems.edges);
                this.setState({ priceList: [...this.state.priceList, { ID: quoteTemplate.ID, Price }] });
                form.setField(newState);
            });
    }
}

/**
 * Including price is too slow, we will just load each price on-demand
 */
const queryQuoteTemplates = gql`
    query($limit: Int, $notCurrent: Boolean, $quoteType: String) {
        readQuoteTemplates(limit: $limit, notCurrent: $notCurrent, quoteType: $quoteType) {
            edges {
                node {
                    ID
                    Description
                    QuoteType
                    LegacyKey
                    #OfficeTotals
                    #TotalPrice
                }
            }
        }
    }
`;

const queryQuoteTemplate = gql`
    ${ProductPriceOverrides}
    ${VariationPriceOverrides}
    query($ID: ID!) {
        readOneQuoteTemplate(ID: $ID) {
            ID
            LegacyKey
            Created
            Description
            QuoteType
            OfficeTotals
            TotalPrice
            QuoteTemplateItems {
                edges {
                    node {
                        ID
                        Qty
                        Complimentary
                        Optional
                        Product {
                            ID
                            GST
                            BasePrice
                            Title
                            LegacyKey
                            AllowPurchase
                            InternalItemID
                            ...ProductPriceOverrides
                        }
                        Variation {
                            ID
                            ShortDescription
                            GST
                            InternalItemID
                            Price
                            ...VariationPriceOverrides
                        }
                    }
                }
            }
        }
    }
`;

const flattenTemplates = templates => {
    for (let x = 0; x < templates.length; x++) {
        templates[x] = templates[x].node;
        flattenConnection(templates[x], 'QuoteTemplateItems');
    }
};

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        minHeight: '33vh',
        overflow: 'auto',
        padding: 42,
        position: 'relative',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            height: '100vh',
            padding: 12
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    }
});

export default withStyles(styles)(withRouter(QuotesModalChooseTab));
