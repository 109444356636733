import gql from 'graphql-tag';

export default gql`
    fragment EnterMusicSong on Funeral {
        EnterMusicSong {
            ID
            Name
            Artist
            Pathname
            SongSort
        }
    }
`;
