import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, Mutation } from 'react-apollo';
import Grid from '../../component/form/Grid';
import { IconButton, withStyles } from '@material-ui/core';
import Table, { TableCell, TableRow } from '../../component/form/Table';
import SideBarDrawer from './extras/SideBarDrawer';
import FlexGrid from '../workQueue2/extras/FlexGrid';
import DataFormView, { DataFormViewMode } from '../../component/form/v2/DataFormView';
import { DataFormSaveMode } from '../../component/form/v2/DataForm';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Inline from '../workQueue2/extras/Inline';
import AlertModal from '../workQueue2/extras/AlertModal';
import TextField from '../../component/form/TextField';
import PrimaryButton, { OutlineButton } from '../../component/form/PrimaryButton';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import DocumentHistoryModal from './modals/DocumentHistoryModal';
import { getOfficeFromKey } from '../../util/brands';
import LinkButton from '../../component/form/LinkButton';
import CommentsIcon from '../../component/icon/CommentsIcon';
import { withRouter } from 'react-router';
import moment from 'moment';
import UploadIcon from '../../component/icon/UploadIcon';
import { withTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { createForm } from '../../util/form';

class Documentation_ArrangementForm extends Component {
    state = Documentation_ArrangementForm.buildState(this.props);

    static buildState(props) {
        this.form = false;
        const formObject = (props.tabProps && props.tabProps.formObject) || false;
        props.form.invalidate();
        return {
            formID: 0,
            formObject: formObject,
            showSaveWarningModal: false,
            showSignedWarningModal: false,
            selectedIndex: 0,
            selctedSection: '',
            allowFinalization: false,
            completedSteps: false,
            markAsSigned: false,
            showDocumentHistory: { open: false, documentName: null, documentNotes: null },
            createNewForm: false,
            alreadySigned: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const formObject = (props.tabProps && props.tabProps.formObject) || false;
        //const oldTabProps = oldProps.tabProps || {};
        if (state.formObject !== formObject) {
            //We switched tab, need to reset state
            return Documentation_ArrangementForm.buildState(props);
        }
        return null;
    }

    render() {
        const { form, tabProps } = this.props;
        const { formObject } = tabProps;
        const arrangementForms = !!form && form.getField('Arrangement.ArrangementForms');
        let renderFormList = false;
        let addMutation = false;
        let renderForm = false;
        //if (form.error) return "Error fetching form";

        if (!!form && form.loading === false) {
            if (this.state.formID) {
                //we have our formID already
                renderForm = true;
            } else if (this.state.createNewForm) {
                //new form creation forced
                renderForm = true;
                addMutation = true;
                this.state.createNewForm = false;
            } else if (arrangementForms && arrangementForms.length > 0) {
                //How many forms do we have?
                if (arrangementForms.length === 1) {
                    //only one. But is it still draft?
                    if (!arrangementForms[0].Signed) {
                        //form already exists, extracting ID and loading it
                        this.state.formID = arrangementForms[0].ID;
                        renderForm = true;
                    } else {
                        //nope,it signed already, rendering list of forms
                        renderFormList = true;
                    }
                } else {
                    //have many forms, need to render list so user can select
                    renderFormList = true;
                }
            } else {
                //form not yet present, creating new, connecting it to relevant FuneralArrangement
                renderForm = true;
                addMutation = true;
            }

            if (renderForm) {
                if (addMutation) {
                    const variables = {
                        input: {
                            FuneralArrangementID: form.getField('Arrangement.ID')
                        }
                    };
                    const mutation = gql`
                        mutation create${formObject}($input: Create${formObject}Input!) {
                            create${formObject}(input: $input) {
                                ID
                            }
                        }
                    `;
                    return (
                        <Mutation
                            mutation={mutation}
                            variables={variables}
                            refetchQueries={[{ query: this.props.query, variables: { id: form.getField('ID') } }]}
                        >
                            {this.renderFormMutationWrapper}
                        </Mutation>
                    );
                } else {
                    return this.renderForm({ loading: false, error: false });
                }
            } else if (renderFormList) {
                return this.renderFormSummary();
            } else {
                return 'Something went wrong';
            }
        }
    }

    renderFormSummary() {
        const { showDocumentHistory } = this.state;
        return (
            <Fragment>
                <DocumentHistoryModal
                    open={showDocumentHistory.open}
                    notes={showDocumentHistory.documentNotes}
                    name={showDocumentHistory.documentName}
                    onClose={() => this.handleCloseDocumentHistory()}
                />
                {this.renderFormVersions()}
            </Fragment>
        );
    }

    renderFormVersions() {
        const { key } = this.props.match.params;
        const { form } = this.props;
        const ArrangementForms = form.getField('Arrangement.ArrangementForms');

        const logo = key && getOfficeFromKey(key);
        const rows = [];
        for (let i = 0; i < ArrangementForms.length; i++) {
            rows.push(this.renderTableRow(ArrangementForms[i]));
        }

        return (
            <Grid container className="summary-paper">
                <Grid item xs={12} md={2}>
                    {logo && <img src={logo.logo} alt={logo.label} className="logo" />}
                </Grid>

                <Grid item xs={12} md={10}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <h2>Form Versions</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                columns={[
                                    'ID',
                                    'Version',
                                    'Document Name',
                                    'Status',
                                    'Date Created',
                                    'Date Signed',
                                    'Download',
                                    'Notes'
                                ]}
                            >
                                {rows}
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                onClick={() => {
                                    this.setState({ createNewForm: true });
                                }}
                            >
                                + Create new form
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderTableRow(formData) {
        const { tabProps, classes } = this.props;
        const { formLabel } = tabProps;
        return (
            <TableRow key={formData.ID}>
                <TableCell>
                    #
                    {formData.Void ? (
                        formData.ID
                    ) : (
                        <LinkButton
                            onClick={() => this.handleFormSelection(formData.ID, formData.Signed)}
                            text={formData.ID}
                        />
                    )}
                </TableCell>
                <TableCell>{formData.Version || '-'}</TableCell>
                <TableCell>
                    {!!formData.FormName
                        ? formData.FormName
                        : formLabel + (formData.Version > 1 ? '-V' + formData.Version : '')}
                </TableCell>
                <TableCell>
                    {formData.Void
                        ? 'Void'
                        : formData.Signed
                        ? 'Signed'
                        : formData.ReadyForSignature
                        ? 'Ready for Signature'
                        : 'In-Progress'}
                </TableCell>
                <TableCell>{moment(formData.Created).format('D/MM/YYYY')}</TableCell>
                <TableCell>{formData.Signed ? moment(formData.SignatureDate).format('D/MM/YYYY') : ''}</TableCell>
                <TableCell className={classes.uploadIconButton}>
                    {(formData.Signed || formData.Void) && formData.PDF && formData.PDF.AbsoluteLink ? (
                        <IconButton href={formData.PDF.AbsoluteLink} target="blank">
                            <UploadIcon className="download" />
                        </IconButton>
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell>
                    {!!formData.Notes ? (
                        <IconButton onClick={() => this.handleShowDocumentHistory(formLabel, formData.Notes)}>
                            <CommentsIcon />
                        </IconButton>
                    ) : (
                        ''
                    )}
                </TableCell>
            </TableRow>
        );
    }

    handleShowDocumentHistory(formLabel, formNotes) {
        this.setState({
            showDocumentHistory: {
                open: true,
                documentName: formLabel,
                documentNotes: formNotes
            }
        });
    }

    handleCloseDocumentHistory() {
        this.setState({ showDocumentHistory: { open: false, documentName: null, documentNotes: null } });
    }

    handleFormSelection(formID, signed) {
        this.setState({ formID, alreadySigned: signed });
    }

    renderFormMutationWrapper = (mutation, { loading, error, data }) => {
        const { tabProps } = this.props;
        const { formObject } = tabProps;

        if (loading || error) {
            return this.renderForm({ loading, error });
        }

        if (data === undefined) {
            mutation();
            return this.renderForm({ loading: true, error: false });
        } else {
            if (this.state.formID === 0) {
                this.state.formID = data['create' + formObject].ID;
            }
            return this.renderForm({ loading: false, error: false });
        }
    };

    renderForm = ({ loading, error }) => {
        const { classes, tabProps, form } = this.props;
        const { tabs, formObject, formType, formLabel, hideSidebar, passDown, validList } = tabProps;
        const { selectedIndex, selectedSection } = this.state;
        const arrangementForms = !!form && form.getField('Arrangement.ArrangementForms');
        let haveSignedForm = false;
        if (!!form && form.loading === false) {
            if (arrangementForms && arrangementForms.length > 0) {
                arrangementForms.forEach(form => {
                    if (form.Signed) haveSignedForm = true;
                });
            }
        }

        const additionalActions = [];

        if (this.state.allowFinalization) {
            additionalActions.push({
                onClick: () => {
                    this.updateFieldsForSigning(form);

                    this.form.save({
                        refetchQueries: [{ query: this.props.query, variables: { id: form.getField('ID') } }]
                    });
                },
                disabled: false,
                skipSave: true,
                label: 'Mark as Signed',
                component: PrimaryButton
            });
        } else if (this.form && (haveSignedForm || this.form.getField('Signed'))) {
            additionalActions.push({
                onClick: () => {
                    if (this.form.isModified) {
                        this.historyCallbacks = {
                            confirmCallback: this.closeForm,
                            cancelCallback: () => {}
                        };
                        this.setState({ showSaveWarningModal: true });
                    } else {
                        this.closeForm();
                    }
                },
                disabled: false,
                skipSave: true,
                label: 'Close Form',
                component: OutlineButton
            });
            /*
            Uncomment for testing purposes. Allows to unsign form to re-run signature process and re-render PDF*
            if (this.form.getField('Signed')) {
                additionalActions.push({
                    onClick: () => {
                        this.form.setField({ Signed: false });
                        if (template !== undefined) {
                            this.form.setField({ Template: '' });
                        }
                        this.form.save();
                    },
                    disabled: false,
                    skipSave: true,
                    label: 'Unsign :D',
                    component: PrimaryButton
                });
            }
            /**/
        }

        const buttonLabels = {
            isUnsaved: 'Save Draft',
            isSaved: 'Saved'
        };

        const pages = [];
        for (let i = 0; i < tabs.length; i++) {
            pages.push({
                Title: tabs[i].title || tabs[i].label,
                Disabled: tabs[i].disabled
            });
        }
        if (!!loading)
            return (
                <span className={classes.loading}>
                    <CircularProgress />
                </span>
            );
        if (!!error) return 'Error';

        const { formID, showSaveWarningModal, showSignedWarningModal } = this.state;
        this.tmpForm = this.tmpForm ? this.tmpForm : createForm(this, { Notes: '' });
        return (
            <Fragment>
                <AlertModal
                    variant="warning"
                    open={showSaveWarningModal}
                    title="Prompt - Close task without saving"
                    primaryAction="Oh yeah, save changes"
                    onClickPrimaryAction={() => {
                        if (!!this.historyCallbacks && !!this.form) {
                            if (this.form.getField('Signed')) {
                                this.setState({ showSaveWarningModal: false, showSignedWarningModal: true });
                            } else {
                                this.form.save({ callback: this.historyCallbacks.confirmCallback });
                                this.historyCallbacks = false;
                            }
                        }
                        this.setState({ showSaveWarningModal: false });
                    }}
                    secondaryAction="Nah, discard changes"
                    onClickSecondaryAction={() => {
                        if (!!this.historyCallbacks) {
                            this.historyCallbacks.confirmCallback(true);
                            this.historyCallbacks = false;
                        }
                        this.setState({ showSaveWarningModal: false });
                    }}
                    onClose={() => {
                        if (!!this.historyCallbacks) {
                            this.historyCallbacks.cancelCallback();
                            this.historyCallbacks = false;
                        }
                        this.setState({ showSaveWarningModal: false });
                    }}
                >
                    <p>Woah there! Looks like you're closing the document without saving your changes.</p>
                    <p>Do you want to save your changes?</p>
                </AlertModal>
                <AlertModal
                    variant="warning"
                    open={showSignedWarningModal}
                    title="Prompt - Changing signed form"
                    primaryAction="Save changes and note"
                    onClickPrimaryAction={() => {
                        this.form.setField({ Notes: this.tmpForm.getField('Notes') });
                        this.updateFieldsForSigning(this.form);
                        if (!!this.historyCallbacks) {
                            this.form.save({ callback: this.historyCallbacks.confirmCallback });
                            this.historyCallbacks = false;
                        } else {
                            this.form.save();
                        }
                        this.setState({ showSignedWarningModal: false });
                    }}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => {
                        this.setState({ showSignedWarningModal: false });
                        if (!!this.historyCallbacks) {
                            this.historyCallbacks.cancelCallback();
                            this.historyCallbacks = false;
                        }
                    }}
                    onClose={() => {
                        this.setState({ showSignedWarningModal: false });
                        if (!!this.historyCallbacks) {
                            this.historyCallbacks.cancelCallback();
                            this.historyCallbacks = false;
                        }
                    }}
                >
                    <p>
                        You are about to save changes to a signed document. Are you sure you want to save the changes?
                    </p>
                    <p>If so, please provide comments of why you changed the document:</p>
                    <TextField
                        form={this.tmpForm}
                        name="Notes"
                        placeholder="Document changes notes are written here..."
                        multiline
                    />
                </AlertModal>
                <div style={{ position: 'relative' }}>
                    <FlexGrid variant="no-wrap">
                        {!hideSidebar && (
                            <SideBarDrawer
                                formID={formID}
                                formType={formType}
                                formTitle={formLabel}
                                formObject={formObject}
                                onNavigate={this.onNavigate}
                                pages={pages}
                            />
                        )}
                        <div className="document-container">
                            <div>
                                {this.renderDocumentHeader()}
                                <Grid container>
                                    <Grid item xs={12}>
                                        <DataFormView
                                            key={formObject + formType}
                                            tabs={tabs}
                                            form={form}
                                            createNew={false}
                                            createNewFunc={null}
                                            objectType={formObject}
                                            itemId={formID}
                                            viewMode={DataFormViewMode.stepped}
                                            name="A-Form"
                                            useFixedStepperBehaviour={true}
                                            //className={classes.page}
                                            tabProps={{
                                                page: selectedIndex + 1,
                                                highlightSection: selectedSection,
                                                ...passDown
                                            }}
                                            onStepComplete={this.onStepComplete}
                                            selectedIndex={selectedIndex}
                                            onPageChange={this.onStepChange}
                                            saveMode={DataFormSaveMode.currentFragment}
                                            additionalActions={additionalActions}
                                            onDataChanged={this.onDataChanged}
                                            onBeforeSave={this.onBeforeSave}
                                            onSaved={this.onSaved}
                                            onLoad={this.onLoad}
                                            buttonLabels={buttonLabels}
                                            saveNotValid={true}
                                            continuousValidation={true}
                                            historyBlockHandler={this.historyBlockHandler}
                                            refetchInvalid={true}
                                            validList={(validList && validList.validForms) || {}}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </FlexGrid>
                </div>
            </Fragment>
        );
    };

    historyBlockHandler = (confirmCallback, cancelCallback) => {
        this.historyCallbacks = { confirmCallback, cancelCallback };
        this.setState({ showSaveWarningModal: true });
    };

    closeForm = () => {
        this.props.form.invalidate(true);
        this.setState({ formObject: 'dummy' });
        if (!!this.historyCallbacks) {
            this.historyCallbacks = false;
        }
    };

    renderDocumentHeader() {
        const { theme, classes } = this.props;
        const office = getOfficeFromKey(theme.funeralHome);
        const { formLabel, header } = this.props.tabProps;
        const signed = this.form && this.form.getField('Signed');

        return (
            <Fragment>
                <Hidden smUp>
                    <div className="mobile-sidebar">
                        <div className="title-container">
                            <h2 className={classes.branded}>{formLabel}</h2>
                        </div>
                    </div>
                </Hidden>

                <Grid container>
                    {signed && (
                        <Grid item xs={12}>
                            <BiggerMessageBar messageType="good">This form is marked as signed.</BiggerMessageBar>
                        </Grid>
                    )}
                    {(header && (
                        <Grid item xs={12}>
                            <div className="title-container">
                                <Inline center>
                                    <img src={header.logo} alt={header.alt} className="logo" />
                                    <Hidden xsDown>
                                        <h2 className={classes.branded}>{formLabel}</h2>
                                    </Hidden>
                                </Inline>
                            </div>
                        </Grid>
                    )) || (
                        <Grid item xs={12}>
                            <div className="title-container">
                                <Inline center>
                                    {office && <img src={office.logo} alt={office.label} className="logo" />}
                                    <Hidden xsDown>
                                        <h2 className={classes.branded}>{formLabel}</h2>
                                    </Hidden>
                                </Inline>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    }

    onStepChange = (event, selectedIndex) => {
        this.setState({ selectedIndex: selectedIndex });
    };

    onStepComplete = completedSteps => {
        const { tabProps } = this.props;
        const { tabs } = tabProps;
        //is the last page still disabled?
        if (tabs[tabs.length - 1].disabled) {
            //can we unlock it?
            const indices = tabs.map((x, i) => i);
            const uncompletedStep = indices.find(x => !completedSteps.has(x) && !tabs[x].disabled);
            if (uncompletedStep === undefined) {
                //no uncomplete steps left, unlocking last step
                tabs[tabs.length - 1].disabled = false;
                //switching to last step
                //this.setState({ selectedIndex: tabs.length - 1 });
            }
        }
        this.setState({ completedSteps });
    };

    onNavigate = (page, section) => {
        this.setState({ selectedIndex: page, selectedSection: section });
    };

    onDataChanged = form => {
        const { tabProps } = this.props;
        const { tabs, signatures } = tabProps;
        this.form = form;
        let canAllowFinalization = true; //assuming we can allow users to sign and export form now. Lets check if it is true
        if (!this.state.allowFinalization) {
            //did we already checked and allowed it? If so - no need to recheck

            if (form) {
                //Maybe form already signed?
                if (form.getField('Signed')) canAllowFinalization = false;

                //Are all signatures present?
                if (signatures && signatures.length > 0) {
                    signatures.forEach(fieldID => {
                        const data = form.getField(fieldID);
                        if (data === undefined || data.ID === undefined || data.ID === 0) {
                            canAllowFinalization = false; //some of the signatures are not present
                        }
                    });
                }
            } else {
                canAllowFinalization = false;
            }

            //Are all steps complete?
            const indices = tabs.map((x, i) => i);
            const uncompletedStep =
                this.state.completedSteps && indices.find(x => !this.state.completedSteps.has(x) && !tabs[x].disabled);
            if (uncompletedStep !== undefined) canAllowFinalization = false; //we still have uncompleted steps on form

            if (canAllowFinalization) {
                //so we can allow it after all?
                this.form.setField({ ReadyForSignature: true });
                this.setState({ allowFinalization: canAllowFinalization });
            }
        } else {
            //if we already allowed it but then form was updated. What if it is no longer valid?
            //Are all steps complete?
            const indices = tabs.map((x, i) => i);
            const uncompletedStep =
                this.state.completedSteps && indices.find(x => !this.state.completedSteps.has(x) && !tabs[x].disabled);
            if (uncompletedStep !== undefined) canAllowFinalization = false; //we still have uncompleted steps on form

            if (!canAllowFinalization) {
                //whoopsie, lets revoke that finalization thing
                this.form.setField({ ReadyForSignature: false });
                this.setState({ allowFinalization: canAllowFinalization });
            }
        }
    };

    onLoad = () => {
        this.forceUpdate();
    };

    onBeforeSave = DataForm => {
        const { form } = DataForm;
        if (form.getField('Signed')) {
            this.setState({ showSignedWarningModal: true });
            return false;
        }
        return true;
    };

    onSaved = data => {
        const { tabProps } = this.props;
        const { formObject, validList } = tabProps || {};
        const { formID } = this.state;
        if (this.state.allowFinalization && this.form && this.form.getField('Signed')) {
            this.setState({ allowFinalization: false }); //no more finalization if we signed form
        }
        if (validList) {
            validList.validForms = { [formObject]: { [formID]: true } };
        }
        this.props.form.invalidate(true);
    };

    updateFieldsForSigning = form => {
        const fields = {};
        const { tabProps } = this.props || {};
        const { template, formLabel } = tabProps || {};
        const formVersion = form.getField('Version');
        fields.Signed = true;
        fields.PDFID = 0;
        fields.FormName = formLabel + (formVersion > 1 ? '-V' + formVersion : '');
        fields.SignedOn = null;

        if (template !== undefined) {
            if (typeof template == 'function') {
                fields.Template = template(form);
            } else {
                fields.Template = template;
            }
        }
        this.form.setField(fields);
    };
}

const styles = ({ typography, palette, transitions }) => ({
    page: {
        // display: 'flex',
        // height: '100%',
        // flexDirection: 'column'
    },
    pageTitleContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    pageTitle: {
        color: palette.action.active,
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1',
        flexWrap: 'wrap'
    },
    keyline: {
        width: 1,
        height: 30,
        display: 'inline-block',
        margin: '0 16px',
        opacity: 0.5,
        backgroundColor: palette.action.active
    },
    nameTitle: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        opacity: 0,
        color: palette.text.primary,
        transition: transitions.create(['opacity'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    nameTitleActive: {
        opacity: 1
    },
    paper: {
        borderRadius: 4,
        paddingTop: 12
    },
    form: {
        flexGrow: 1,
        '& form > :last-child': {
            position: 'fixed',
            zIndex: 1202,
            bottom: 22,
            right: 24
        }
    },
    branded: {
        color: palette.primary.main
    },
    uploadIconButton: {
        textAlign: 'center'
    }
});

export default compose(withStyles(styles), withTheme(), withRouter)(Documentation_ArrangementForm);
