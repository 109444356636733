import gql from 'graphql-tag';

export default gql`
    fragment SpecialInstructions on Funeral {
        SpecialInstructions {
            ID
            Action
            Check
            Comment
        }
    }
`;
