import gql from 'graphql-tag';

export default gql`
    fragment ReflectionMusicSongs on Funeral {
        ReflectionMusicSongs {
            ID
            Name
            Artist
            Pathname
            IncludeInLifeStory
            ForFlowerPlacement
            #Other
            OtherText
            SongSort
        }
    }
`;
