import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';
import Select from '../../../component/form/Select';
import TextField from '../../../component/form/TextField';
import RadioGroup from '../../../component/form/RadioGroup';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import {
    ABORIGINAL_OPTIONS,
    GENDER_OPTIONS,
    PENSION_TYPE_OPTIONS,
    PENSIONER_OPTIONS,
    PLACE_OF_BIRTH_COMPONENTS,
    RESIDENTIAL_ADDRESS_COMPONENTS,
    RETIRED_OPTIONS,
    TORRES_OPTIONS
} from '../../funeral/deceased/DeceasedConstants';
import ColumnLayout from '../../../component/ColumnLayout';
import InputAdornment from '@material-ui/core/InputAdornment';
import DisplayContact from '../../../component/bookings/DisplayContact';
import AddressBookAutocomplete from '../../../component/form/AddressBookAutocomplete';
import { ADDRESS_BOOK_CATEGORY } from '../../funeral/funeralConstants';
import { PreNeedTypes } from '../../quotes/EnquiryConstants';

class Client extends Component {
    state = {
        funeralId: undefined,
        surnameDifferent: false
    };

    componentDidMount() {
        this.updateStateFromProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateStateFromProps(nextProps);
    }

    updateStateFromProps(props) {
        if (props.form.fields) {
            const { ID, FamilyNameAtBirth, Surname } = props.form.fields;
            if (ID === this.state.funeralId) return;
            this.setState({
                funeralId: ID,
                surnameDifferent: !!FamilyNameAtBirth && FamilyNameAtBirth !== Surname
            });
        }
    }

    onToggleSurname = e => {
        if (this.state.surnameDifferent) {
            this.props.form.setField({ FamilyNameAtBirth: null });
        }
        this.setState({ surnameDifferent: !this.state.surnameDifferent });
    };

    render() {
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const { form } = this.props;
        const { surnameDifferent } = this.state;

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid pc={0.5}>
                        <Select required={true} name="Type" options={PreNeedTypes} form={form} label={'Type'} />
                    </Grid>
                    <Grid pc={1}>
                        <Label text="Name" />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField name="FirstName" label="First name" form={form} />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField name="MiddleName" label="Middle name(s)" form={form} />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField name="Surname" label="Surname" form={form} />
                    </Grid>
                    <Grid pc={0.5}>
                        <TextField name="KnownAs" label="Known as" form={form} />
                    </Grid>
                    <Grid pc={0.5}>
                        <Checkbox
                            label="Surname different at birth"
                            checked={surnameDifferent}
                            onChange={this.onToggleSurname}
                        />
                    </Grid>
                    {surnameDifferent && (
                        <Grid pc={0.5}>
                            <TextField name="FamilyNameAtBirth" label="Surname at birth" form={form} />
                        </Grid>
                    )}
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Date of Birth" htmlFor="text-DateOfBirth" />
                        <Grid container item spacing={24}>
                            <Grid item xs={6} sm={4} lg={6}>
                                <TextField
                                    name="DateOfBirth"
                                    type="date"
                                    form={form}
                                    disabled={form.getField('DateOfBirth') === null}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} lg={6}>
                                <Checkbox
                                    checked={form.getField('DateOfBirth') === null}
                                    label="DOB Unknown"
                                    onChange={e => {
                                        form.setField({ DateOfBirth: e.target.checked ? null : '' });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid pc={0.5}>
                        <Label text="Gender" htmlFor="select-Gender" />
                        <Select name="Gender" options={GENDER_OPTIONS} form={form} />
                    </Grid>
                </Grid>

                {this.renderFund()}
            </Grid>
        );
    }

    renderFund() {
        const { form } = this.props;
        const myKey = form.getField('FuneralFundCode') || null;
        return (
            <Grid bucket={true}>
                <Grid pc={1}>
                    <Label text={'Funeral Fund'} />
                    <AddressBookAutocomplete
                        form={form}
                        name={'FuneralFundName'}
                        canSearchByKey={true}
                        categories={[ADDRESS_BOOK_CATEGORY.funeralFund]}
                        onSelect={(_, result) =>
                            form.setField({
                                FuneralFundName: result.Name,
                                FuneralFundCode: result.LegacyKey
                            })
                        }
                    />
                </Grid>
                {!!myKey && (
                    <Grid pc={1}>
                        <DisplayContact contactKey={myKey} />
                    </Grid>
                )}
                <Grid pc={1}>
                    <Label text={'Contract Amount'} />
                    <Grid container item spacing={24}>
                        <Grid item xs>
                            <TextField
                                form={form}
                                name={'ContractAmount'}
                                type={'number'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Checkbox label={'Travel Protection'} form={form} name={'TravelProtection'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderSecondColumn() {
        const { classes, form } = this.props;

        // const placeOfDeathResidential = form.state.PlaceOfDeathType === PLACE_OF_DEATH_TYPE.Residential;
        // const transferFromResidential = form.state.TransferFromType === PLACE_OF_DEATH_TYPE.Residential;
        // const sameAsResidential = form.getState('UseResidentialAddress');
        // const sameAsPlaceOfDeath = form.getState('UsePlaceOfDeathAddress');

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid item xs={12} sm={6} lg={12}>
                        <Label text="Residential Address" htmlFor="ResidentialAddress" />
                        <AddressAutocomplete
                            id="ResidentialAddress"
                            placeholder="Search for residential address"
                            componentFields={RESIDENTIAL_ADDRESS_COMPONENTS}
                            allowCustomAddress={true}
                            form={form}
                        />
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Place of Birth" htmlFor="text-PlaceOfBirth1" />
                        <AddressAutocomplete
                            onlySuburbs={true}
                            placeholder="Enter a suburb"
                            form={form}
                            componentFields={PLACE_OF_BIRTH_COMPONENTS}
                        />
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Occupation" htmlFor="text-Occupation" />
                        <TextField name="Occupation" form={form} />
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <RadioGroup
                            name="Retired"
                            label="Retired"
                            options={RETIRED_OPTIONS}
                            form={form}
                            labelProps={{
                                className: classes.retPenRadioLabel
                            }}
                        />
                    </Grid>

                    <Grid pc={1} className={classes.pensionGroup}>
                        <RadioGroup
                            name="Pensioner"
                            label="Pensioner"
                            options={PENSIONER_OPTIONS}
                            form={form}
                            className={classes.pensionRadio}
                            labelProps={{
                                className: classes.retPenRadioLabel
                            }}
                            fullWidth={false}
                        />
                        {form.getField('Pensioner') === 'Y' && (
                            <Select
                                name="PensionType"
                                options={PENSION_TYPE_OPTIONS}
                                form={form}
                                fullWidth={false}
                                className={classes.pensionSelect}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <RadioGroup
                            name="Aboriginal"
                            label="Aboriginal"
                            options={ABORIGINAL_OPTIONS}
                            form={form}
                            labelProps={{
                                className: classes.aboTorRadioLabel
                            }}
                            groupProps={{
                                className: classes.aboTorRadioGroup
                            }}
                        />
                    </Grid>

                    <Grid pc={1}>
                        <RadioGroup
                            name="TorresStrait"
                            label="Torres Strait Islander"
                            options={TORRES_OPTIONS}
                            form={form}
                            labelProps={{
                                className: classes.aboTorRadioLabel
                            }}
                            groupProps={{
                                className: classes.aboTorRadioGroup
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const styles = ({ spacing }) => ({
    noColumn: { margin: `-${spacing.unit * 3}px ${spacing.unit}px ${spacing.unit * 3}px ${spacing.unit}px` },
    column: {
        '& > *': {
            marginBottom: -spacing.unit * 1.5
        }
    },
    placeOfDeathHeader: {
        display: 'flex',
        marginBottom: -24,
        alignItems: 'flex-end'
    },
    placeOfDeathLabel: {
        flex: '1 1 auto'
    },
    pensionGroup: {
        display: 'flex'
    },
    pensionRadio: {
        flex: '0 1 auto'
    },
    pensionSelect: {
        flex: '1 0 auto',
        maxWidth: 180
    },
    retPenRadioLabel: {
        minWidth: 70
    },
    aboTorRadioLabel: {
        flex: '1 1 auto',
        maxWidth: 200
    },
    aboTorRadioGroup: {
        flex: '0 0 auto'
    }
});

export default withStyles(styles)(Client);
