import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../util/strings';

export default withStyles({})(({ variant, title, data }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `content--${variant}`;

    return (
        <div className="score-pill">
            <p className={`content ${cssVariant || ''}`}>{title}</p>
            <p className={`content ${cssVariant || ''}`}>{data}</p>
        </div>
    );
});
