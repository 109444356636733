import { IconButton, withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withRouter } from 'react-router';
import TextField from '../../../component/form/TextField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import Select from '../../../component/form/Select';
import Label from '../../../component/form/Label';
import RadioGroup from '../../../component/form/RadioGroup';
import Checkbox from '../../../component/form/Checkbox';
import { TitleOptions } from '../../quotes/EnquiryConstants';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import SignatureField from '../../../component/form/SignatureField';
import moment from 'moment';
import { GENDER_OPTIONS } from '../../funeral/deceased/DeceasedConstants';
import { MARITAL_STATUS_OPTIONS } from '../../funeral/family/FamilyConstants';
import {
    GRAVE_DEPTH_OPTIONS,
    GROUND_TYPE_OPTIONS,
    PLACE_OF_SERVICE_TYPE_OPTIONS
} from '../../funeral/details/DetailsConstants';
import AutoComplete from '../../../component/form/AutoComplete';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../../funeral/certification/CertificationConstants';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../../component/form/AddressBookAutocomplete';
import { ADDRESS_BOOK_CATEGORY } from '../../funeral/funeralConstants';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import PrimaryButton, { OutlineButton } from '../../../component/form/PrimaryButton';
import AddIcon from '../../../component/icon/AddIcon';
import InputAdornment from '@material-ui/core/InputAdornment';

class BurialDocument extends Component {
    render() {
        const { page } = this.props.tabProps;
        return (
            <Fragment>
                {page === 1 && this.renderStepOne()}
                {page === 2 && this.renderStepTwo()}
                {page === 3 && this.renderStepThree()}
                {page === 4 && this.renderStepFour()}
            </Fragment>
        );
    }

    renderStepOne() {
        return (
            <Fragment>
                {this.renderCompanyDetails()}
                {this.renderDeceasedDetails()}
            </Fragment>
        );
    }

    renderCompanyDetails() {
        const { form } = this.props;
        return (
            <section id="companyDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Funeral Director Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Funeral Director Company"
                            placeholder="Funeral Director Company"
                            name="FuneralCompany"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Phone Number" placeholder="Phone Number" name="FuneralPhone" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField type={'email'} label="Email" placeholder="Email" form={form} name="Email" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Arranger Name" placeholder="Arranger Name" name="ArrangerName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AddressAutocomplete
                            placeholder="Funeral director address"
                            componentFields={{
                                line1: 'OfficeAddress.AddressLine1',
                                line2: 'OfficeAddress.AddressLine2',
                                city: 'OfficeAddress.Suburb',
                                state: 'OfficeAddress.State',
                                country: 'OfficeAddress.Country',
                                code: 'OfficeAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDetails() {
        const { form, tabProps } = this.props;
        const { diseaseLists } = tabProps;
        const isAddressBook = form.getField('DeceasedPlaceOfDeathType') === 'AddressBook';
        return (
            <section className="booking" id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Deceased Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="First name" placeholder="First Name" name="DeceasedFirstName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Middle name(s)"
                            placeholder="Middle name(s)"
                            name="DeceasedMiddleName"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Family Name" placeholder="Family Name" name="DeceasedLastName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Sex" options={GENDER_OPTIONS} name="DeceasedGender" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Occupation" />
                        <ComboListAutoComplete
                            placeholder="Occupation"
                            category={'OCCUPATION'}
                            name="DeceasedOccupation"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for deceased's address"
                            componentFields={{
                                line1: 'DeceasedAddress.AddressLine1',
                                line2: 'DeceasedAddress.AddressLine2',
                                city: 'DeceasedAddress.Suburb',
                                state: 'DeceasedAddress.State',
                                country: 'DeceasedAddress.Country',
                                code: 'DeceasedAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            type="date"
                            label="Date of Birth"
                            placeholder="Date of Birth"
                            name="DeceasedDateOfBirth"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            type="date"
                            label="Date of Death"
                            placeholder="Date of Death"
                            name="DeceasedDateOfDeath"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Age" placeholder="Deceased Age" name="DeceasedAge" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Place of death</h3>
                        <RadioGroup
                            className="doc"
                            label="Address type:"
                            options={[
                                {
                                    label: 'Residential',
                                    value: 'Residential'
                                },
                                {
                                    label: 'AddressBook',
                                    value: 'AddressBook'
                                }
                            ]}
                            name="DeceasedPlaceOfDeathType"
                            form={form}
                        />
                        {isAddressBook ? (
                            <AddressBookAutocomplete
                                placeholder="Death location"
                                name="DeceasedPlaceOfDeathAddressBook"
                                labelFieldFunc={getLabelWithSuburb}
                                form={form}
                            />
                        ) : (
                            <AddressAutocomplete
                                placeholder="Death location"
                                componentFields={{
                                    line1: 'DeceasedPlaceOfDeath.AddressLine1',
                                    line2: 'DeceasedPlaceOfDeath.AddressLine2',
                                    city: 'DeceasedPlaceOfDeath.Suburb',
                                    state: 'DeceasedPlaceOfDeath.State',
                                    country: 'DeceasedPlaceOfDeath.Country',
                                    code: 'DeceasedPlaceOfDeath.Postcode'
                                }}
                                form={form}
                                allowCustomAddress
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select
                            label="Marital Status"
                            options={MARITAL_STATUS_OPTIONS}
                            name="DeceasedMartialStatus"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Religion" placeholder="Religion" name="DeceasedReligion" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Next of Kin Name"
                            placeholder="Next of Kin"
                            name="NextOfKinFullName"
                            form={form}
                        />
                    </Grid>
                    {(diseaseLists && (
                        <Fragment>
                            <Grid item xs={12}>
                                Notifiable diseases:
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="List A" name="DiseaseListA" form={form} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="List B" name="DiseaseListB" form={form} />
                            </Grid>
                        </Fragment>
                    )) || (
                        <Grid item xs={12}>
                            <RadioGroup
                                form={form}
                                name="InfectiousDisease"
                                label="Did the Deceased die of an infectious disease?"
                                options={[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: '' }
                                ]}
                            />
                            {form.getField('InfectiousDisease') && (
                                <Fragment>
                                    <p>If Yes please provide details:</p>
                                    <TextField
                                        label="Disease Details"
                                        placeholder="Disease Details"
                                        name="InfectiousDiseaseDetails"
                                        form={form}
                                    />
                                </Fragment>
                            )}
                        </Grid>
                    )}
                </Grid>
            </section>
        );
    }

    renderStepTwo() {
        const { form } = this.props;
        return (
            <Fragment>
                {this.renderBookingDetails()}
                {this.renderGraveDetails()}
                {form.getField('GraveGroundType') === 'Reopened' && this.renderGraveReopen()}
            </Fragment>
        );
    }

    renderBookingDetails() {
        const { form } = this.props;
        return (
            <section className="booking" id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Booking Details</h3>
                        <h4>Burial Details</h4>
                        <Label text="Cemetery Name" />
                        <AddressBookAutocomplete
                            placeholder="Cemetery"
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfCommittal]}
                            name="CemeteryLocation"
                            form={form}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {/*Refers to bookingDate*/}
                        <TextField
                            type="date"
                            label="Date of Burial"
                            placeholder="Date of Burial"
                            name="BookingDate"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {/*Refers to bookingTime*/}
                        <TextField
                            type="time"
                            label="Time of Burial"
                            placeholder="Time of Burial"
                            name="BookingTime"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Service Details</h4>
                        <Select
                            allowNone={false}
                            label="Service type"
                            options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                            name="ServiceType"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="HasCelebrant"
                            label="Is a Clergy or Celebrant required?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                        {form.getField('HasCelebrant') && (
                            <AddressBookAutocomplete
                                placeholder="Search for Clergy or Celebrant..."
                                categories={[ADDRESS_BOOK_CATEGORY.clergy]}
                                name="Celebrant"
                                form={form}
                                required
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="HasSpecialRequirements"
                            label="Are there any special instructions?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                        {form.getField('HasSpecialRequirements') && (
                            <TextField
                                form={form}
                                name="SpecialRequirements"
                                label="Special Instructions"
                                placeholder="Special Instructions"
                                multiline
                                required
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="HeavenAddress"
                            label="Heaven address required?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }
    renderGraveDetails() {
        const { form, tabProps } = this.props;
        const { childBabyGraveType, coffinShapes, coffinLidRounded } = tabProps;
        return (
            <section id="graveDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Grave Details</h3>
                    </Grid>
                    {childBabyGraveType && (
                        <Fragment>
                            <Grid item xs={12} sm={6}>
                                <Checkbox label="Child" name="GraveTypeChild" form={form} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Checkbox label="Baby" name="GraveTypeBaby" form={form} />
                            </Grid>
                        </Fragment>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Select
                            allowNone={false}
                            label={'Ground Type'}
                            options={GROUND_TYPE_OPTIONS}
                            form={form}
                            name="GraveGroundType"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select label={'Depth of Grave'} options={GRAVE_DEPTH_OPTIONS} form={form} name="GraveDepth" />
                    </Grid>
                    {form.getField('GraveDepth') === 'Double Depth' && (
                        <Grid item xs={12} sm={6}>
                            <Select
                                label={'Order'}
                                options={[
                                    { label: 'First Interment', value: 'First' },
                                    { label: 'Second Interment', value: 'Second' }
                                ]}
                                form={form}
                                name="IntermentOrder"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <TextField label="Denomination" form={form} name="GraveDenomination" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Portion/garden name" form={form} name="GravePortionGardenName" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Beam/row/lot" form={form} name="GraveBeamRowLot" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Side/section" form={form} name="GraveSection" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Grave number" name="GraveNumber" form={form} />
                    </Grid>
                    {!!coffinShapes && (
                        <Grid item xs={12} sm={6}>
                            <Select
                                allowNone={false}
                                label={'Coffin Shape'}
                                options={coffinShapes}
                                form={form}
                                name="CoffinShape"
                            />
                        </Grid>
                    )}
                    {!!coffinLidRounded && (
                        <Grid item xs={12} sm={6}>
                            <Checkbox label="Coffin lid is rounded" name="CoffinLidRounded" form={form} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Checkbox label="Reservation required" name="GraveReservationRequired" form={form} />
                    </Grid>
                    {form.getField('GraveReservationRequired') && (
                        <Fragment>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="First name"
                                    placeholder="First Name"
                                    name="ReservationFirstName"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Surname"
                                    placeholder="Surname"
                                    name="ReservationLastName"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AddressAutocomplete
                                    componentFields={{
                                        line1: 'ReservationAddress.AddressLine1',
                                        line2: 'ReservationAddress.AddressLine2',
                                        city: 'ReservationAddress.Suburb',
                                        state: 'ReservationAddress.State',
                                        country: 'ReservationAddress.Country',
                                        code: 'ReservationAddress.Postcode'
                                    }}
                                    form={form}
                                    allowCustomAddress
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Phone"
                                    placeholder="Phone"
                                    name="IntermentReservationPhone"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="E-Mail"
                                    placeholder="E-Mail"
                                    name="IntermentReservationMail"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Certificate date"
                                    placeholder="Certificate date"
                                    type="date"
                                    name="IntermentRightsCertificateDate"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Receipt #"
                                    placeholder="Receipt #"
                                    name="IntermentRightsCertificateReceipt"
                                    form={form}
                                />
                            </Grid>
                        </Fragment>
                    )}
                    {form.getField('GraveGroundType') === 'New Ground' && (
                        <Grid item xs={12}>
                            <RadioGroup
                                form={form}
                                name="FamilyWillSelectGrave"
                                label="Family coming to office to select new grave?"
                                options={[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: '' }
                                ]}
                            />
                        </Grid>
                    )}
                </Grid>
            </section>
        );
    }

    renderGraveReopen() {
        const { form } = this.props;
        const numBurried = form.getField('NumberOfCurrentlyBurried');
        console.dir(numBurried);

        return (
            <section id="graveReopenDetails">
                <Grid container spacing={24}>
                    {/*Only to appear if grave is re-opened*/}
                    <Grid item xs={12}>
                        <h3>Re-open Grave</h3>
                        <Label>Name of person(s) currently buried</Label>
                        <div className="currently-buried">
                            <TextField
                                className="name-field"
                                label="First Name"
                                placeholder="First name"
                                name="CurrentlyBurriedFirstName"
                                form={form}
                            />
                            <TextField
                                className="name-field"
                                label="Surname"
                                placeholder="Surname"
                                name="CurrentlyBurriedLastName"
                                form={form}
                            />
                            {numBurried > 1 && (
                                <IconButton onClick={() => this.deleteCurrentlyBurriedAtIndex(1)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                        {numBurried > 1 && (
                            <div className="currently-buried">
                                <TextField
                                    className="name-field"
                                    label="First Name"
                                    placeholder="First name"
                                    name="SecondCurrentlyBurriedFirstName"
                                    form={form}
                                />
                                <TextField
                                    className="name-field"
                                    label="Surname"
                                    placeholder="Surname"
                                    name="SecondCurrentlyBurriedLastName"
                                    form={form}
                                />
                                {numBurried > 1 && (
                                    <IconButton onClick={() => this.deleteCurrentlyBurriedAtIndex(2)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </div>
                        )}
                        {(numBurried > 2 && (
                            <div className="currently-buried">
                                <TextField
                                    className="name-field"
                                    label="First Name"
                                    placeholder="First name"
                                    name="ThirdCurrentlyBurriedFirstName"
                                    form={form}
                                />
                                <TextField
                                    className="name-field"
                                    label="Surname"
                                    placeholder="Surname"
                                    name="ThirdCurrentlyBurriedLastName"
                                    form={form}
                                />
                                {numBurried > 1 && (
                                    <IconButton onClick={() => this.deleteCurrentlyBurriedAtIndex(2)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </div>
                        )) || (
                            <PrimaryButton onClick={this.addCurrentlyBurried}>
                                <AddIcon />
                                Add another person
                            </PrimaryButton>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="ApplicantForReopen"
                            label="Applicant for re-open"
                            options={[
                                { label: 'Original Applicant', value: 'OriginalApplicant' },
                                { label: 'Next of Kin', value: 'NextOfKin' },
                                { label: 'Other', value: 'Other' }
                            ]}
                        />
                        {form.getField('ApplicantForReopen') === 'Other' && (
                            <Grid container spacing={16}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="First name"
                                        placeholder="First Name"
                                        name="ReopenApplicantFirstName"
                                        form={form}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Family Name"
                                        placeholder="Family Name"
                                        name="ReopenApplicantLastName"
                                        form={form}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Label>Authority to re-open</Label>
                                    <Select
                                        className="doc"
                                        options={[
                                            { label: 'Statutory declaration', value: 'StatutoryDeclaration' },
                                            {
                                                label: 'Original applicant written permission',
                                                value: 'WrittenPermission'
                                            }
                                        ]}
                                        name="AuthorityForReopen"
                                        form={form}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderStepThree() {
        return (
            <Fragment>
                {this.renderApplicantDetails()}
                {this.renderIntermentDetails()}
            </Fragment>
        );
    }

    renderApplicantDetails() {
        const { form } = this.props;
        return (
            <section id="applicantDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Applicant Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutoComplete
                            name="ApplicantRelationship"
                            label="Relationship to Deceased"
                            form={form}
                            limit={100}
                            options={APPLICANT_RELATIONSHIP_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Title" options={TitleOptions} name="ApplicantTitle" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="First name" placeholder="First name" name="ApplicantFirstName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Middle name"
                            placeholder="Middle name"
                            name="ApplicantMiddleName"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Surname" placeholder="Surname" name="ApplicantLastName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Age" placeholder="Age" name="ApplicantAge" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Business"
                            placeholder="Business Name"
                            name="ApplicantBusinessName"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Phone" placeholder="Phone" name="ApplicantPhone" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Mobile" placeholder="Mobile" name="ApplicantMobile" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Email" placeholder="Email" name="ApplicantEmail" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for applicant's address"
                            componentFields={{
                                line1: 'ApplicantAddress.AddressLine1',
                                line2: 'ApplicantAddress.AddressLine2',
                                city: 'ApplicantAddress.Suburb',
                                state: 'ApplicantAddress.State',
                                country: 'ApplicantAddress.Country',
                                code: 'ApplicantAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderIntermentDetails() {
        const { form, classes, tabProps } = this.props;
        const { intermentMoreThanOne } = tabProps;
        return (
            <section id="intermentDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Interment Rights</h3>
                        <RadioGroup
                            form={form}
                            name="IntermentRightsHolder"
                            label="Was the deceased the interment rights holder?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                        {(form.getField('IntermentRightsHolder') && (
                            <Fragment>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Interment Right Certificate No"
                                            placeholder="Interment Right Certificate No"
                                            name="IntermentRightsCertificateNo"
                                            form={form}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Name on Certificate"
                                            placeholder="Name on Certificate"
                                            name="IntermentRightsCertificateName"
                                            form={form}
                                        />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )) || (
                            <Fragment>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <h4>Consent to Interment</h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {intermentMoreThanOne && (
                                            <Fragment>
                                                <RadioGroup
                                                    form={form}
                                                    name="IntermentRightsMoreThanOne"
                                                    label="Is there more than 1 person registered as the holder of the interment rights for this site?"
                                                    options={[
                                                        { label: 'Yes', value: 'true' },
                                                        { label: 'No', value: '' }
                                                    ]}
                                                />
                                                {form.getField('IntermentRightsMoreThanOne') && (
                                                    <Fragment>
                                                        <RadioGroup
                                                            form={form}
                                                            name="IntermentRightsMoreThanOneInformed"
                                                            label="Has the holder/s of the right of interment been informed of this application?"
                                                            options={[
                                                                { label: 'Yes', value: 'true' },
                                                                { label: 'No', value: '' }
                                                            ]}
                                                        />
                                                        {!form.getField('IntermentRightsMoreThanOneInformed') && (
                                                            <TextField
                                                                label="Why not?"
                                                                placeholder="Reason why they were not informed"
                                                                name="IntermentRightsMoreThanOneWhyNotInformed"
                                                                form={form}
                                                            />
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Interment Rights No"
                                            placeholder="Interment Rights No"
                                            name="IntermentRightsCertificateNo"
                                            form={form}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Select
                                            label="Interment Rights Holder Title"
                                            options={TitleOptions}
                                            name="IntermentRightsHolderTitle"
                                            form={form}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="First Name"
                                            placeholder="First Name"
                                            name="IntermentRightsHolderName"
                                            form={form}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Middle Name"
                                            placeholder="Middle Name"
                                            name="IntermentRightsHolderMiddleName"
                                            form={form}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Last Name"
                                            placeholder="Last Name"
                                            name="IntermentRightsHolderLastName"
                                            form={form}
                                        />
                                    </Grid>
                                    {form.getField('GraveReservationRequired') && (
                                        <Grid item xs={12} md={12}>
                                            <AddressAutocomplete
                                                placeholder="Interment rights holder address"
                                                componentFields={{
                                                    line1: 'IntermentRightsAddress.AddressLine1',
                                                    line2: 'IntermentRightsAddress.AddressLine2',
                                                    city: 'IntermentRightsAddress.Suburb',
                                                    state: 'IntermentRightsAddress.State',
                                                    country: 'IntermentRightsAddress.Country',
                                                    code: 'IntermentRightsAddress.Postcode'
                                                }}
                                                form={form}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12} style={{ marginTop: '1em' }}>
                                        <Checkbox
                                            label="I hereby declare that I am the interment rights holder for the above allotment and consent to the person named above to be interred in the allotment"
                                            form={form}
                                            name="IntermentConsent"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Full Name"
                                            placeholder="Full name"
                                            form={form}
                                            name="IntermentRightsSigneeName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            type="date"
                                            label="Declaration Date"
                                            name="IntermentSignatureDate"
                                            form={form}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <OutlineButton
                                                            className={classes.addNewButton}
                                                            onClick={() =>
                                                                form.setField({
                                                                    IntermentSignatureDate: moment().format(
                                                                        'YYYY-MM-DD'
                                                                    )
                                                                })
                                                            }
                                                            title="Add Today's Date"
                                                        >
                                                            Add Today's Date
                                                        </OutlineButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SignatureField form={form} name="IntermentSignature" />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderStepFour() {
        return <Fragment>{this.renderSignature()}</Fragment>;
    }

    renderSignature() {
        const { form, classes } = this.props;
        return (
            <section id="signature">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Consent</h3>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <h3>Applicant</h3>
                        <SignatureField form={form} name="ApplicantSignature" />
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    label="Signature Date"
                                    name="DeclarationDate"
                                    form={form}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <OutlineButton
                                                    className={classes.addNewButton}
                                                    onClick={() =>
                                                        form.setField({
                                                            DeclarationDate: moment().format('YYYY-MM-DD')
                                                        })
                                                    }
                                                    title="Add Today's Date"
                                                >
                                                    Add Today's Date
                                                </OutlineButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <h3>Witness</h3>
                        <SignatureField form={form} name="WitnessSignature" />
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    label="Signature Date"
                                    name="SignatureDate"
                                    form={form}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <OutlineButton
                                                    className={classes.addNewButton}
                                                    onClick={() =>
                                                        form.setField({ SignatureDate: moment().format('YYYY-MM-DD') })
                                                    }
                                                    title="Add Today's Date"
                                                >
                                                    Add Today's Date
                                                </OutlineButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AddressAutocomplete
                            placeholder="Signed at"
                            componentFields={{
                                line1: 'DeclarationLocation.AddressLine1',
                                line2: 'DeclarationLocation.AddressLine2',
                                city: 'DeclarationLocation.Suburb',
                                state: 'DeclarationLocation.State',
                                country: 'DeclarationLocation.Country',
                                code: 'DeclarationLocation.Postcode'
                            }}
                            form={form}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    addCurrentlyBurried = () => {
        const { form } = this.props;
        form.setField({ NumberOfCurrentlyBurried: form.getField('NumberOfCurrentlyBurried') + 1 });
    };

    deleteCurrentlyBurriedAtIndex = index => {
        const { form } = this.props;
        switch (index) {
            default:
            case 1:
                form.setField({
                    CurrentlyBurriedFirstName: form.getField('SecondCurrentlyBurriedFirstName'),
                    CurrentlyBurriedLastName: form.getField('SecondCurrentlyBurriedLastName'),
                    SecondCurrentlyBurriedFirstName: form.getField('ThirdCurrentlyBurriedFirstName'),
                    SecondCurrentlyBurriedLastName: form.getField('ThirdCurrentlyBurriedLastName'),
                    ThirdCurrentlyBurriedFirstName: '',
                    ThirdCurrentlyBurriedLastName: '',
                    NumberOfCurrentlyBurried: form.getField('NumberOfCurrentlyBurried') - 1
                });
                break;
            case 2:
                form.setField({
                    SecondCurrentlyBurriedFirstName: form.getField('ThirdCurrentlyBurriedFirstName'),
                    SecondCurrentlyBurriedLastName: form.getField('ThirdCurrentlyBurriedLastName'),
                    ThirdCurrentlyBurriedFirstName: '',
                    ThirdCurrentlyBurriedLastName: '',
                    NumberOfCurrentlyBurried: form.getField('NumberOfCurrentlyBurried') - 1
                });
                break;
            case 3:
                form.setField({
                    ThirdCurrentlyBurriedFirstName: '',
                    ThirdCurrentlyBurriedLastName: '',
                    NumberOfCurrentlyBurried: form.getField('NumberOfCurrentlyBurried') - 1
                });
                break;
        }
    };
}

const styles = () => ({
    addressCustom: {
        width: 'auto'
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export default compose(withRouter, withStyles(styles))(BurialDocument);
