import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { prettyNumber, stringIsNullOrEmpty } from '../../util/strings';
import Spinner from '../Spinner';

export default withStyles({})(({ variant, colour, label, stats, goal, statsNumber, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `bar-fill--${variant}`;

    const maxStats = Math.max(0, Math.min(100, Number((stats && stats.slice(0, -1)) || 0)));
    const heightStyle = { height: `${maxStats}%` };
    if (colour) heightStyle.backgroundColor = colour;

    return (
        <div className="chart-container">
            {!!goal && <div className="horizontal-number">{goal}</div>}
            <div className="bar">
                <div className="bar-track">
                    {(!!loading && <Spinner />) || (
                        <div className={`bar-fill ${cssVariant || ''}`} style={heightStyle}>
                            <div className="stats-container">{prettyNumber(statsNumber)}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="label-container">
                <label className="chart-label">{label}</label>
            </div>
        </div>
    );
});
