import React, { Component } from 'react';
import { Button, Card, Typography, withStyles } from '@material-ui/core';
import OptionalTextField from '../form/OptionalTextField';
import TextField from '../form/TextField';
import Select from '../form/Select';
import Spinner from '../Spinner';
import {
    getItemPrice,
    getItemVariation,
    getVariationByAttributeValues,
    ProductConfig,
    ProductMetadata
} from '../../util/products.js';
import cx from 'classnames';
import { prettyPrice } from '../../util/strings';
import DeleteIcon from '../icon/DeleteIcon';

class CartItem extends Component {
    onRemove = () => {
        const { item, onRemove } = this.props;
        onRemove(item);
    };

    onChangeAttribute = e => {
        const { item, config, onChange } = this.props;
        const attributes = {
            ...getItemVariation(item, config).attributes,
            [e.target.name]: e.target.value
        };
        item.variationId = getVariationByAttributeValues(item.productId, attributes, config).id;
        onChange(item);
        this.forceUpdate();
    };

    onChange = e => {
        const { item, onChange } = this.props;
        const { name, value, type } = e.target;
        item[name] = ('' + value).trim() === '' ? undefined : type === 'number' ? parseInt(value, 10) : value;
        onChange(item);
        this.forceUpdate();
    };

    onChangeQuantity = (e, maxPurchaseQuantity) => {
        const { value } = e.target;
        const qty = ('' + value).trim() === '' ? 0 : parseInt(value, 10);
        if (isNaN(qty) || qty < 1 || (maxPurchaseQuantity !== null && qty > maxPurchaseQuantity) || qty > 999) return;

        if (!isNaN(this.props.item.returns) && qty < this.props.item.returns) {
            this.props.item.returns = qty;
        }

        this.onChange(e);
    };

    onChangeReturns = e => {
        const { quantity } = this.props.item;
        const { value } = e.target;
        const returns = ('' + value).trim() === '' ? 0 : parseInt(value, 10);

        if (isNaN(returns) || returns < 0 || returns > quantity) return;

        this.onChange(e);
    };

    render() {
        const { className, classes, config, item, officeID } = this.props;
        const { productId, quantity, comment } = item;
        const product = config.productMap[productId];
        if (!product) {
            // If the product isn't in the config then we're still loading the product details for this cart item
            return (
                <Card className={cx(classes.root, className)}>
                    <Spinner />
                </Card>
            );
        }
        const { image, title } = product;
        const itemPrice = getItemPrice(item, config, officeID);
        const totalPrice = quantity ? prettyPrice(quantity * itemPrice) : '-';
        const metadata = getMetadata(item);

        return (
            <Card className={cx(classes.root, className, item.quantity > 0 ? '' : classes.disabled)}>
                <div className={classes.top}>
                    <div>
                        <div style={{ backgroundImage: `url(${image}` }} className={classes.image} />
                    </div>
                    <div className={classes.details}>
                        <Typography>{title}</Typography>
                        {this.renderAttributeOptions(itemPrice, metadata)}
                        <OptionalTextField
                            name="comment"
                            linkbutton="true"
                            value={comment}
                            onChange={this.onChange}
                            buttonlabel="+ Add comment"
                            //label="Comment"
                            placeholder="Enter comments here..."
                            className={classes.addCommentButton}
                        />
                    </div>
                </div>
                <div className={classes.bottom}>
                    <Button onClick={this.onRemove} className={classes.removeButton}>
                        <DeleteIcon style={{ fontSize: 18 }} />
                        <span>&nbsp;Remove</span>
                    </Button>
                    <div>
                        <strong>Item Total: {totalPrice}</strong>
                    </div>
                </div>
            </Card>
        );
    }

    renderReturns() {
        const { item, classes } = this.props;
        return (
            <div className={classes.option}>
                {/*<Typography variant="caption" align="left">*/}
                {/*Return*/}
                {/*</Typography>*/}
                <Select
                    label={'Return'}
                    allowNone={false}
                    name="returns"
                    options={[
                        { value: '', label: 'Stay with coffin' },
                        { value: 1, label: 'To be returned' }
                    ]}
                    value={item.returns || ''}
                    onChange={e => this.onChangeReturns(e)}
                    className={classes.optionSelect}
                />
            </div>
        );
    }

    renderAttributeOptions(itemPrice, metadata) {
        const { classes, config, item } = this.props;
        const { productId, quantity } = item;
        const product = config.productMap[productId];
        const optionKeys = Object.keys(product.attributeOptions);
        const omitQuantity = metadata.maxPurchaseQuantity === 1;
        return (
            <div className={classes.options}>
                {optionKeys.map(o => this.renderOption(o))}
                <div style={{ display: 'flex' }}>
                    {(!omitQuantity &&
                        this.renderOption(
                            'Qty',
                            <TextField
                                label={'Quantity'}
                                type="number"
                                inputProps={{ min: '0' }}
                                name="quantity"
                                value={quantity}
                                onChange={e => this.onChangeQuantity(e, metadata.maxPurchaseQuantity)}
                                className={classes.quantityInput}
                            />
                        )) || (
                        <div className={classes.option}>
                            <TextField
                                label={'Qty'}
                                InputProps={{
                                    readOnly: true
                                }}
                                value={'' + quantity}
                            />
                        </div>
                    )}
                    {1 === 2 && metadata.canBeReturned && this.renderReturns()}
                    <div className={classes.option}>
                        <small>Unit Price</small>
                        <br />
                        {itemPrice ? prettyPrice(itemPrice, true) : 'FREE'}
                    </div>
                </div>
            </div>
        );
    }

    renderOption = (key, children) => {
        const { classes, config, item } = this.props;
        const product = config.productMap[item.productId];
        const attribute = config.attributeTypes[key];
        const variation = getItemVariation(item, config);
        return (
            <div key={key} className={classes.option}>
                {/*<Typography variant="caption">{attribute ? attribute.label : key}</Typography>*/}
                {children || (
                    <Select
                        label={attribute ? attribute.label : key}
                        className={classes.optionSelect}
                        options={product.attributeOptions[key]}
                        name={key}
                        value={variation.attributes[key]}
                        onChange={attribute ? this.onChangeAttribute : this.onChange}
                        allowNone={false}
                    />
                )}
            </div>
        );
    };
}

const getMetadata = item => {
    const categoryKey = ProductConfig.productMap[item.productId].urlSegment;
    if (categoryKey) {
        const metadataKey = Object.keys(ProductMetadata).find(x => ProductMetadata[x].queryKey === categoryKey);
        if (metadataKey) return ProductMetadata[metadataKey];
    }

    return {
        canBeReturned: false,
        maxPurchaseQuantity: null
    };
};

const styles = ({ palette, typography, spacing, breakpoints }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 12px 6px',
        marginBottom: 20,
        minHeight: '200px',
        overflow: 'auto',
        justifyContent: 'space-between'
    },
    top: {
        display: 'flex',
        marginBottom: spacing.unit,
        [breakpoints.down(breakpoints.custom.dt)]: {
            //flexDirection: 'column'
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        //paddingTop: 4,
        flex: '1 1 auto'
    },
    image: {
        minWidth: 128,
        minHeight: 128,
        marginRight: spacing.unit * 1.5,
        border: `1px solid ${palette.divider}`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        [breakpoints.down(breakpoints.custom.dt)]: {
            // width: 128,
            // marginRight: 0,
            // marginBottom: 4
        }
    },
    options: {
        display: 'flex',
        //marginBottom: 12,
        width: '100%',
        flexDirection: 'column',
        [breakpoints.down(breakpoints.custom.dt)]: {
            // flexDirection: 'column',
            // marginTop: 8
        }
    },
    option: {
        flex: '1 0 auto',
        //[breakpoints.up(breakpoints.custom.dt)]: {
        '&:not(:last-child)': {
            //paddingRight: 8
        },
        '& > :first-child': {
            //marginBottom: 4,
            minWidth: 60,
            color: typography.body1.color
            //}
        },

        [breakpoints.down(breakpoints.custom.dt)]: {
            // display: 'flex',
            alignItems: 'center',
            paddingBottom: 8,
            '& > :first-child': {
                marginRight: 4,
                minWidth: 40
            }
        },
        '&:last-of-type': {
            textAlign: 'right'
        }
    },
    optionSelect: {
        // maxWidth: 120
    },
    removeButton: {
        textTransform: 'none',
        color: palette.error.main
    },
    addCommentButton: {
        // height: 36,
        //marginTop: -16
    },
    quantityInput: {
        width: 60,
        [breakpoints.down(breakpoints.custom.dt)]: {
            // width: '100%'
        }
    },
    bottom: {
        //marginTop: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    disabled: {
        opacity: 0.5,
        backgroundColor: '#f0f0f0'
    }
});

export default withStyles(styles)(CartItem);
