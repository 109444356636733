import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import withTheme from '@material-ui/core/styles/withTheme';
import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import Button from './extras/Button';
import StarWorkspace from './StarWorkspace';
import StarIcon from '../../component/icon/StarIcon';
import CloseIcon from '../../component/icon/CloseIcon';

const styles = ({ sizes }) => ({
    drawerBox: {
        marginTop: sizes.headerHeight,
        height: 'calc(100% - ' + (sizes.footerHeight + sizes.headerHeight) + 'px)',
        overflowX: 'hidden',
        boxShadow: '-2px 0px 6px rgba(0, 0, 0, 0.1)'
    },
    drawerBig: {
        overflowX: 'hidden'
    }
});

export default compose(
    withTheme(),
    withStyles(styles)
)(({ theme, classes, tasks, refetch, mobile = false }) => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const variant = mobile || window.innerWidth < theme.breakpoints.values['md'] ? 'temporary' : 'persistent';

    return (
        <div>
            <Button
                variant="primary icon-button white shadow"
                size="lg"
                color="inherit"
                aria-label="open drawer"
                edge="end"
                title="Show Your Starred tasks"
                onClick={handleDrawerOpen}
            >
                <StarIcon filled={!!tasks && tasks.length > 0 ? 'filled' : ''} className="star-icon" />
            </Button>
            {!!open && (
                <Drawer
                    classes={{ paper: variant !== 'temporary' ? classes.drawerBox : classes.drawerBig }}
                    variant={variant}
                    anchor="right"
                    open
                >
                    <div className="workqueue-page">
                        <div className="wq-page" style={{ padding: 0 }}>
                            <div className="workspace-header">
                                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    <div style={{ lineHeight: 1 }}>
                                        <Button
                                            onClick={handleDrawerClose}
                                            variant="primary icon-button white"
                                            size="lg"
                                            color="inherit"
                                            aria-label="close drawer"
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                    <h2 style={{ color: theme.palette.primary.main }}>
                                        <span style={{ fontWeight: 200, fontSize: '75%' }}>Task Queue:</span>
                                        <br />
                                        Your Starred Tasks
                                    </h2>
                                </div>
                            </div>
                            <StarWorkspace tasks={tasks} refetch={refetch} />
                        </div>
                    </div>
                </Drawer>
            )}
        </div>
    );
});
