import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import SideBarDrawerList from './SideBarDrawerList';
import { Query } from 'react-apollo';
import { ArrangementChecksQuery } from '../ArrangementChecklistConstants';
import IconButton from '@material-ui/core/IconButton/IconButton';
import TaskChecklistIcon from '../../../component/icon/TaskChecklistIcon';

class SideBarDrawer extends React.Component {
    state = {
        open: true
    };

    render() {
        return (
            <Fragment>
                <Hidden mdDown>{this.renderDesktopDrawer()}</Hidden>
                <Hidden lgUp only={['xs']}>
                    {this.renderTabletDrawer()}
                </Hidden>
                <Hidden smUp>{this.renderMobileDrawer()}</Hidden>
            </Fragment>
        );
    }

    onClose = e => {
        const { onToggleOpen } = this.props;
        const { open } = this.state;
        this.setState({ open: !open }, onToggleOpen);
    };

    renderDesktopDrawer() {
        const { open } = this.state;
        return (
            <div style={{ flex: '0 1 auto', borderRight: '1px solid #eee', paddingRight: 20, marginRight: 20 }}>
                {(!open && (
                    <div className="sidebar-title">
                        <IconButton
                            onClick={this.onClose}
                            title="Open Checklist"
                            style={{ boxShadow: '0px 5px 20px -10px #5c5c5c' }}
                        >
                            <TaskChecklistIcon />
                        </IconButton>
                    </div>
                )) ||
                    this.renderDrawerList()}
            </div>
        );
    }

    renderMobileDrawer() {
        const { classes } = this.props;
        const { open } = this.state; // gets reversed for mobile

        return (
            <Fragment>
                {(!!open && (
                    <div style={{ position: 'absolute', left: '0', top: -10 }}>
                        <IconButton
                            onClick={this.onClose}
                            title="Open Checklist"
                            style={{ boxShadow: '0px 5px 20px -10px #5c5c5c' }}
                        >
                            <TaskChecklistIcon />
                        </IconButton>
                    </div>
                )) || (
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', height: '100%', top: -20 }}>
                            <Drawer
                                variant="temporary"
                                open={!open}
                                onClose={this.onClose}
                                classes={{
                                    root: classes.drawerRoot,
                                    paper: classes.drawerPaper
                                }}
                                ModalProps={{
                                    keepMounted: true // Better open performance on mobile.
                                }}
                            >
                                {this.renderDrawerList()}
                            </Drawer>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }

    renderTabletDrawer() {
        const { classes } = this.props;
        const { open } = this.state; // gets reversed for mobile

        return (
            <div style={{ flex: '0 1 auto', borderRight: '1px solid #eee', paddingRight: 20, marginRight: 20 }}>
                <div style={{ position: 'relative', height: '100%' }}>
                    <div className="sidebar-title">
                        <IconButton
                            onClick={this.onClose}
                            title="Open Checklist"
                            style={{ boxShadow: '0px 5px 20px -10px #5c5c5c' }}
                        >
                            <TaskChecklistIcon />
                        </IconButton>
                    </div>
                    {!open && (
                        <div
                            style={{
                                position: 'absolute',
                                height: '100%',
                                top: -20,
                                left: -20,
                                paddingLeft: 20,
                                background: 'white'
                            }}
                        >
                            <Drawer
                                variant="permanent"
                                open={!open}
                                onClose={this.onClose}
                                classes={{
                                    root: classes.drawerRoot,
                                    paper: classes.drawerPaper
                                }}
                                ModalProps={{
                                    keepMounted: true // Better open performance on mobile.
                                }}
                            >
                                {this.renderDrawerList()}
                            </Drawer>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderDrawerList() {
        const { formType, formID, formObject, formTitle, onNavigate, pages } = this.props;
        if (formType === undefined || formID === undefined || formObject === undefined) {
            return 'Summary section is not setup yet for that form.';
            // Please provide as props to that object next values:
            // formType (e.g. AForm or MVCBooking, etc.),
            // formID (ArrangementForm.ID),
            // formObject (form DB class name, e.g. ArrangementFormStatutoryForms)
            // Also you can add:
            // onNavigate(page, section) callback handler to react to navigation links
            // pages[ -array of page/section_header properties
            //  {
            //        Title: "Applicant Details"    - title of page
            //        Disabled: true                - if that page is disabled
            //  }
            //]
        }
        return (
            <Query query={ArrangementChecksQuery} variables={{ formType, formID }}>
                {({ loading, error, data }) => {
                    return (
                        <SideBarDrawerList
                            data={data && data['readFormArrangementChecks']}
                            formObject={formObject}
                            formID={formID}
                            loading={loading}
                            formTitle={formTitle}
                            error={error}
                            onNavigate={onNavigate}
                            pages={pages}
                            hideDrawer={this.onClose}
                        />
                    );
                }}
            </Query>
        );
    }
}

const styles = ({ breakpoints, sizes, transitions }) => ({
    root: {
        height: '100%',
        minHeight: '100%'
    },
    drawerRoot: {
        height: 'calc(100% + 56px)'
    },
    drawerPaper: {
        width: sizes.tasksDrawerWidth,
        position: 'relative',
        zIndex: 999,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        }),
        overflow: 'hidden',
        paddingRight: 20,
        paddingTop: 20,
        [breakpoints.down('xs')]: {
            padding: 20
        },
        maxWidth: '95vw'
    },
    drawerPaperMinimized: {
        width: sizes.drawerWidthMinimized
    }
});

export default withStyles(styles)(SideBarDrawer);
