import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withRouter } from 'react-router';
import TextField from '../../../component/form/TextField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../../component/form/AddressBookAutocomplete';
import Select from '../../../component/form/Select';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../../funeral/certification/CertificationConstants';
import RadioGroup from '../../../component/form/RadioGroup';
import AutoComplete from '../../../component/form/AutoComplete';
import SignatureField from '../../../component/form/SignatureField';
import { ADDRESS_BOOK_CATEGORY } from '../../funeral/funeralConstants';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import moment from 'moment';
import { GENDER_OPTIONS } from '../../funeral/deceased/DeceasedConstants';
import { MARITAL_STATUS_OPTIONS } from '../../funeral/family/FamilyConstants';
import InputAdornment from '@material-ui/core/InputAdornment';
import { OutlineButton } from '../../../component/form/PrimaryButton';

class StatDocument extends Component {
    render() {
        const { page } = this.props.tabProps;
        switch (page) {
            case 1:
                return this.renderStepOne();
            case 2:
                return this.renderStepTwo();
            case 3:
                return this.renderStepThree();
            case 4:
                return this.renderStepFour();
            default:
                return null;
        }
    }

    renderStepOne() {
        return (
            <Fragment>
                {this.renderApplicantDetails()}
                {this.renderRelationshipDetails()}
            </Fragment>
        );
    }

    renderStepTwo() {
        return (
            <Fragment>
                {this.renderDeceasedDirections()}
                {this.renderDeceasedDeath()}
                {this.renderDeathLocation()}
                {this.renderDeathCause()}
                {this.renderExamination()}
            </Fragment>
        );
    }

    renderStepThree() {
        return (
            <Fragment>
                {this.renderMedicalDetails()}
                {this.renderMedicalDetailsIllness()}
                {this.renderDeathRegistery()}
                {this.renderDeceasedDevices()}
            </Fragment>
        );
    }

    renderStepFour() {
        const { form, classes } = this.props;
        return (
            <section id="consentSignature">
                <Grid container spacing={16}>
                    <Grid pc={1}>
                        <p>
                            I hereby certify that all particulars stated above are true and accurate, and that to the
                            best of my knowledge and belief no particular material has been omitted; I therefore make
                            this solemn declaration conscientiously believing the same to be true and virtue of the
                            Oaths Act, 1900. I am aware that the deceased may not be cremated on the day of service ate
                            the cremation.
                        </p>
                    </Grid>
                    <Grid pc={1}>
                        <span>Declared at</span>
                        <AddressAutocomplete
                            className="doc address"
                            placeholder="Declaration location"
                            componentFields={{
                                line1: 'DeclarationLocation.AddressLine1',
                                line2: 'DeclarationLocation.AddressLine2',
                                city: 'DeclarationLocation.Suburb',
                                state: 'DeclarationLocation.State',
                                country: 'DeclarationLocation.Country',
                                code: 'DeclarationLocation.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                        <span>on</span>
                        <TextField
                            className="doc"
                            type="date"
                            label="date"
                            name="DeclarationDate"
                            form={form}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <OutlineButton
                                            className={classes.addNewButton}
                                            onClick={() =>
                                                form.setField({ DeclarationDate: moment().format('YYYY-MM-DD') })
                                            }
                                            title="Add Today's Date"
                                        >
                                            Add Today's Date
                                        </OutlineButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid pc={1}>
                        <SignatureField form={form} name="ApplicantSignature" />
                    </Grid>
                    <Grid pc={1}>
                        <p className="full-width">in the presence of an authorised witness, who states:</p>
                    </Grid>
                    <Grid pc={1}>
                        <span>I,</span>
                        <TextField
                            className="doc"
                            label="First name"
                            placeholder="First name of authorised witness"
                            name="WitnessFirstName"
                            form={form}
                        />
                        <TextField
                            className="doc"
                            label="Surname"
                            placeholder="Surname of authorised witness"
                            name="WitnessLastName"
                            form={form}
                        />
                        <span>, a</span>
                        <TextField
                            className="doc address"
                            placeholder="Qualification of authorised witness"
                            name="WitnessQualification"
                            form={form}
                        />
                        <span>
                            , certify the following matters concerning the making of this statutory declaration by the
                            person who made it:
                        </span>
                    </Grid>
                    <Grid pc={1}>
                        <ol>
                            <li>
                                <RadioGroup
                                    className="doc-radio"
                                    options={[
                                        {
                                            label: 'I saw the face of the person',
                                            value: 'Seen'
                                        },
                                        {
                                            label:
                                                'I did not see the face of the person because the person was wearing a face covering, but I am satisfied that the person had a special justification for not removing the covering.',
                                            value: 'NotSeen'
                                        }
                                    ]}
                                    name="WitnessSeenFace"
                                    form={form}
                                />
                            </li>
                            <h3>AND</h3>
                            <li>
                                <RadioGroup
                                    className="doc-radio"
                                    options={[
                                        {
                                            label: 'I have known the person for at least 12 months',
                                            value: 'Know'
                                        },
                                        {
                                            label:
                                                "I have not known the person for at least 12 months, but I have confirmed the person's identity using an identification document and the document I relied on was ",
                                            value: 'DontKnow'
                                        }
                                    ]}
                                    name="WitnessFamiliar"
                                    form={form}
                                />
                                <TextField
                                    placeholder="Describe identification document relied on"
                                    name="WitnessSeenDocument"
                                    form={form}
                                    disabled={form.getField('WitnessFamiliar') !== 'DontKnow'}
                                />
                            </li>
                        </ol>
                    </Grid>

                    <Grid pc={1}>
                        <h3>Authorised Witness</h3>
                        <SignatureField form={form} name="WitnessSignature" />
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <TextField
                                type="date"
                                label="Signature Date"
                                name="SignatureDate"
                                form={form}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <OutlineButton
                                                className={classes.addNewButton}
                                                onClick={() =>
                                                    form.setField({ SignatureDate: moment().format('YYYY-MM-DD') })
                                                }
                                                title="Add Today's Date"
                                            >
                                                Add Today's Date
                                            </OutlineButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid pc={1}>
                        <p className="full-width">
                            *This declaration must be signed in the present if an authorised witness. A list of people
                            who can be witness is set out in Schedule 2 to the Statutory Declaration Regulations 1993.
                        </p>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderApplicantDetails() {
        const { form, classes } = this.props;
        return (
            <section id="applicantDetails">
                <Grid container>
                    <Grid pc={1}>
                        <p className="full-width">
                            (This form should be completed by an executor or nearest surviving relative of the deceased
                            or other person, and all questions must be fully answered)
                        </p>
                    </Grid>
                    <Grid pc={1}>
                        <span>I,</span>
                        <TextField
                            className="doc"
                            label="First name"
                            placeholder="First name of applicant"
                            name="ApplicantFirstName"
                            form={form}
                        />
                        <TextField
                            className="doc"
                            label="Surname"
                            placeholder="Surname of applicant"
                            name="ApplicantLastName"
                            form={form}
                        />
                        <span>, of</span>

                        <AddressAutocomplete
                            className="doc address"
                            placeholder="Search applicant address"
                            componentFields={{
                                line1: 'ApplicantAddress.AddressLine1',
                                line2: 'ApplicantAddress.AddressLine2',
                                city: 'ApplicantAddress.Suburb',
                                state: 'ApplicantAddress.State',
                                country: 'ApplicantAddress.Country',
                                code: 'ApplicantAddress.Postcode'
                            }}
                            name="ApplicantAddress"
                            form={form}
                            allowCustomAddress
                        />
                        <span>age</span>
                        <TextField
                            className="doc age"
                            label="Age"
                            type="number"
                            placeholder="Applicant Age"
                            name="ApplicantAge"
                            form={form}
                        />
                        <span>hereby apply for permission to cremate the remains of the late</span>
                        <TextField
                            className="doc"
                            label="Name of deceased"
                            placeholder="Name of deceased"
                            name="DeceasedFirstName"
                            form={form}
                        />
                        <TextField
                            className="doc"
                            label="Name of deceased"
                            placeholder="Name of deceased"
                            name="DeceasedLastName"
                            form={form}
                        />
                        <span>of</span>
                        <AddressAutocomplete
                            className="doc address"
                            label="Last address of deceased"
                            placeholder="Last address of deceased"
                            componentFields={{
                                line1: 'DeceasedAddress.AddressLine1',
                                line2: 'DeceasedAddress.AddressLine2',
                                city: 'DeceasedAddress.Suburb',
                                state: 'DeceasedAddress.State',
                                country: 'DeceasedAddress.Country',
                                code: 'DeceasedAddress.Postcode'
                            }}
                            form={form}
                            name="DeceasedAddress"
                            allowCustomAddress
                        />
                        <span>at the</span>
                        <AddressBookAutocomplete
                            className={`${classes.addressCustom} doc address`}
                            placeholder="Crematorium"
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfCommittal]}
                            name="CrematoriumLocation"
                            labelFieldFunc={getLabelWithSuburb}
                            form={form}
                        />
                        <span>.</span>
                    </Grid>
                    <Grid pc={1}>
                        <span>State the deceased's:</span>
                        <ol className="deceasedDetails" type="i">
                            <li className="detail-item">
                                <Select
                                    className="doc select full-width"
                                    label="Deceased Marital Status"
                                    options={MARITAL_STATUS_OPTIONS}
                                    name="DeceasedMartialStatus"
                                    form={form}
                                />
                            </li>
                            <li className="detail-item">
                                <TextField
                                    className="doc full-width"
                                    label="Age"
                                    placeholder="Deceased Age"
                                    name="DeceasedAge"
                                    form={form}
                                />
                            </li>
                            <li className="detail-item">
                                <Select
                                    className="doc select full-width"
                                    label="Sex"
                                    options={GENDER_OPTIONS}
                                    name="DeceasedGender"
                                    form={form}
                                />
                            </li>
                            <li className="detail-item">
                                <ComboListAutoComplete
                                    className="doc full-width"
                                    label="Occupation"
                                    placeholder="Occupation"
                                    category={'OCCUPATION'}
                                    name="DeceasedOccupation"
                                    form={form}
                                />
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderRelationshipDetails() {
        const { form } = this.props;
        return (
            <section id="relationshipDetails">
                <Grid container>
                    <Grid item xs={12}>
                        <ol style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <ol type="a">
                                    <li>
                                        <span>Are you the nearest surviving relative of the deceased?</span>
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            name="SurvivingRelative"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <span>Are you an executor of the deceased's estate?</span>
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            name="ExecutorOfEstate"
                                            form={form}
                                        />
                                    </li>
                                    <div className="full-width">Please complete one of the following:</div>
                                    <RadioGroup
                                        className="doc"
                                        options={[
                                            {
                                                label: 'Option A: if you are a relative of the deceased or;',
                                                value: 'true'
                                            },
                                            {
                                                label: 'Option B: if you have been nominated by the deceased',
                                                value: ''
                                            }
                                        ]}
                                        name="IsRelative"
                                        form={form}
                                    />
                                    {form.getField('IsRelative') ? (
                                        <Fragment>
                                            <h2>Option A:</h2>
                                            <li>
                                                <span>
                                                    If neither an executor nor nearest surviving relative, state:
                                                </span>
                                                <ol type="i">
                                                    <li>
                                                        relationship to deceased
                                                        <AutoComplete
                                                            name="ApplicantRelationship"
                                                            label="Relationship to the deceased"
                                                            form={form}
                                                            limit={100}
                                                            options={APPLICANT_RELATIONSHIP_OPTIONS}
                                                        />
                                                    </li>
                                                    <li>
                                                        reason(s) why this application is being made by you
                                                        <TextField
                                                            className="doc full-width"
                                                            label="Reason"
                                                            placeholder="Reasons"
                                                            name="ApplicationReasons"
                                                            form={form}
                                                        />
                                                    </li>
                                                    <li>
                                                        written authority for making this application
                                                        <Select
                                                            className="doc select"
                                                            options={[
                                                                { label: 'Yes', value: true },
                                                                { label: 'No', value: false }
                                                            ]}
                                                            name="WrittenAuthority"
                                                            form={form}
                                                        />
                                                    </li>
                                                </ol>
                                            </li>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <h2>Option B:</h2>
                                            <li>
                                                <div>Complete the following statement:</div>
                                                <div>
                                                    <span>I have been requested by</span>
                                                    <TextField
                                                        className="doc"
                                                        placeholder=""
                                                        name="RequestedBy"
                                                        form={form}
                                                        label="Full name"
                                                    />
                                                    <span>the deceased's</span>

                                                    <AutoComplete
                                                        name="ApplicantRelationship"
                                                        label="Relationship to the deceased"
                                                        form={form}
                                                        limit={100}
                                                        options={APPLICANT_RELATIONSHIP_OPTIONS}
                                                        className="doc address flex"
                                                    />

                                                    <span>
                                                        and his/her next of kin, to make this application of cremation
                                                        and I am fully aware of the information contained herein
                                                    </span>
                                                </div>
                                            </li>
                                        </Fragment>
                                    )}
                                    <li>
                                        <ol type="i">
                                            <li>
                                                <span>
                                                    Have all near relatives of the deceased been informed of the
                                                    proposed cremation?
                                                </span>
                                                <Select
                                                    className="doc select"
                                                    options={[
                                                        { label: 'Yes', value: true },
                                                        { label: 'No', value: false }
                                                    ]}
                                                    name="RelativesInformed"
                                                    form={form}
                                                />
                                            </li>
                                            <li>
                                                <span>
                                                    Has any near relative of the deceased expressed any objection to the
                                                    cremation?
                                                </span>
                                                <Select
                                                    className="doc select"
                                                    options={[
                                                        { label: 'Yes', value: true },
                                                        { label: 'No', value: false }
                                                    ]}
                                                    name="RelativeObjections"
                                                    form={form}
                                                />
                                                <span>If so, state the reason for objection</span>
                                                <TextField
                                                    className="doc select full-width"
                                                    label="Objection Reason"
                                                    placeholder=""
                                                    disabled={!form.getField('RelativeObjections')}
                                                    name="ReasonForObjection"
                                                    form={form}
                                                />
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDirections() {
        const { form } = this.props;
        return (
            <section id="deceasedDirections">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="2" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <ol type="a">
                                    <li>
                                        <span>
                                            Did the deceased leave any written directions as to mode of disposal of the
                                            remains of the deceased?
                                        </span>
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            name="WrittenInstruction"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <span>If yes, what directions?</span>
                                        <TextField
                                            className="doc"
                                            label="Directions"
                                            name="Instructions"
                                            form={form}
                                            disabled={!form.getField('WrittenInstruction')}
                                        />
                                    </li>
                                    <li>
                                        <span>
                                            Are you satisfied that the directions of the deceased were made in a state
                                            of sound mind?
                                        </span>
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            name="SoundStateOfMind"
                                            form={form}
                                            disabled={!form.getField('WrittenInstruction')}
                                        />
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDeath() {
        const { form } = this.props;
        return (
            <section id="deceasedDeath">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="3" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <span>When did the deceased die? (State date and time of death):</span>
                                <TextField
                                    className="doc"
                                    type="date"
                                    label="Date"
                                    name="DeceasedDateOfDeath"
                                    form={form}
                                />
                                <TextField
                                    className="doc"
                                    label="Time"
                                    type="time"
                                    name="DeceasedTimeOfDeath"
                                    form={form}
                                />
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeathLocation() {
        const { form } = this.props;
        const isAddressBook = form.getField('DeceasedPlaceOfDeathType') === 'AddressBook';
        return (
            <section id="deathLocation">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="4" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <div>
                                    Where did the death occur? (State address and location, ie. own residence, hospital,
                                    nursing home, hotel, etc)
                                </div>

                                <RadioGroup
                                    label="Address:"
                                    className="doc"
                                    options={[
                                        {
                                            label: 'Residential Address',
                                            value: 'Residential'
                                        },
                                        {
                                            label: 'NSW Hospital or Nursing Home',
                                            value: 'AddressBook'
                                        }
                                    ]}
                                    name="DeceasedPlaceOfDeathType"
                                    form={form}
                                />
                                {isAddressBook ? (
                                    <AddressBookAutocomplete
                                        className={`doc address`}
                                        placeholder="Select Location of Death..."
                                        name="DeceasedPlaceOfDeathAddressBook"
                                        labelFieldFunc={getLabelWithSuburb}
                                        form={form}
                                        noAddNew
                                        categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                                    />
                                ) : (
                                    <AddressAutocomplete
                                        className={`doc address`}
                                        placeholder="Enter Residential Address of Death..."
                                        componentFields={{
                                            line1: 'DeceasedPlaceOfDeath.AddressLine1',
                                            line2: 'DeceasedPlaceOfDeath.AddressLine2',
                                            city: 'DeceasedPlaceOfDeath.Suburb',
                                            state: 'DeceasedPlaceOfDeath.State',
                                            country: 'DeceasedPlaceOfDeath.Country',
                                            code: 'DeceasedPlaceOfDeath.Postcode'
                                        }}
                                        form={form}
                                        allowCustomAddress
                                    />
                                )}
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeathCause() {
        const { form } = this.props;
        return (
            <section id="deathCause">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="5" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <div>
                                    {' '}
                                    Do you know or have any reason to suspect, that the death of the deceased was due,
                                    directly or indirectly, to:
                                </div>
                                <ol type="a" className="two-col">
                                    <li>
                                        <RadioGroup
                                            label="Violence"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedViolence"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Poison"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedPoison"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Abuse or Neglect"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedAbuseOrNeglect"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Drowning"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedDrowning"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Suffocation"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedSuffocation"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Burns"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedBurns"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="During custodial care"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedCustodialCare"
                                            form={form}
                                        />
                                    </li>
                                    <li>
                                        <RadioGroup
                                            label="Illegal Operations"
                                            className="doc"
                                            options={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: '' }
                                            ]}
                                            name="SuspectedIllegalOperations"
                                            form={form}
                                        />
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderExamination() {
        const { form } = this.props;
        return (
            <section id="examination">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="6" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <span>
                                    Have you any reason whatever for supposing that an examination of the remains of the
                                    deceased may be desirable/required by law?
                                </span>
                                <Select
                                    className="doc select"
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    name="ExaminationRequired"
                                    form={form}
                                />
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderMedicalDetails() {
        const { form } = this.props;
        return (
            <section id="examination">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="7" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <div>
                                    Give the name and address of the usual attending medical practitioner of the
                                    deceased:
                                </div>
                                <RadioGroup
                                    label="Attending Medical Practitioner:"
                                    options={[
                                        {
                                            label: 'Same as Certification Doctor',
                                            value: 'CertificationDoctor'
                                        },
                                        {
                                            label: 'Other medical practitioner',
                                            value: 'DontCopy'
                                        }
                                    ]}
                                    name="MedicalPractitionerCopyFrom"
                                    form={form}
                                />
                                <div>
                                    <TextField
                                        className="doc"
                                        label="Full name"
                                        name="MedicalPractitionerFullName"
                                        form={form}
                                        disabled={form.getField('MedicalPractitionerCopyFrom') !== 'DontCopy'}
                                    />

                                    <AddressAutocomplete
                                        className="doc address"
                                        placeholder="Address"
                                        componentFields={{
                                            line1: 'MedicalPractitionerAddress.AddressLine1',
                                            line2: 'MedicalPractitionerAddress.AddressLine2',
                                            city: 'MedicalPractitionerAddress.Suburb',
                                            state: 'MedicalPractitionerAddress.State',
                                            country: 'MedicalPractitionerAddress.Country',
                                            code: 'MedicalPractitionerAddress.Postcode'
                                        }}
                                        form={form}
                                        disabled={form.getField('MedicalPractitionerCopyFrom') !== 'DontCopy'}
                                        allowCustomAddress
                                    />
                                </div>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderMedicalDetailsIllness() {
        const { form } = this.props;
        return (
            <section>
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="8" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <div>
                                    Give the name and address of the medical practitioner who attended the deceased's
                                    last illness:
                                </div>

                                <RadioGroup
                                    label="Doctor for last illness:"
                                    options={[
                                        {
                                            label: 'Same as Certification Doctor',
                                            value: 'CertificationDoctor'
                                        },
                                        {
                                            label: 'Same as Attending Medical Practitioner',
                                            value: 'AttendingPractitioner'
                                        },
                                        {
                                            label: 'Other medical practitioner',
                                            value: 'DontCopy'
                                        }
                                    ]}
                                    name="LatestPractitionerCopyFrom"
                                    form={form}
                                />
                                <div>
                                    <TextField
                                        className="doc"
                                        label="Full name"
                                        name="LatestPractitionerFullName"
                                        form={form}
                                        disabled={form.getField('LatestPractitionerCopyFrom') !== 'DontCopy'}
                                    />

                                    <AddressAutocomplete
                                        className="doc address"
                                        placeholder="Address"
                                        componentFields={{
                                            line1: 'LatestPractitionerAddress.AddressLine1',
                                            line2: 'LatestPractitionerAddress.AddressLine2',
                                            city: 'LatestPractitionerAddress.Suburb',
                                            state: 'LatestPractitionerAddress.State',
                                            country: 'LatestPractitionerAddress.Country',
                                            code: 'LatestPractitionerAddress.Postcode'
                                        }}
                                        form={form}
                                        disabled={form.getField('LatestPractitionerCopyFrom') !== 'DontCopy'}
                                        allowCustomAddress
                                    />
                                </div>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeathRegistery() {
        const { form } = this.props;
        return (
            <section id="examination">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="9" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <div>
                                    Give the name of the Registry Office where the death has been, or is to be,
                                    registered
                                </div>
                                <TextField
                                    className="doc full-width"
                                    label="Registry Office"
                                    name="RegistryOffice"
                                    form={form}
                                />
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDevices() {
        const { form } = this.props;
        return (
            <section id="examination">
                <Grid container>
                    <Grid item xs={12}>
                        <ol start="10" style={{ paddingInlineStart: '1rem', marginBlockStart: 0, marginBlockEnd: 0 }}>
                            <li>
                                <ol type="a">
                                    <li>
                                        Was any battery powered device attached to or present in body of deceased?
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: 'Yes' },
                                                { label: 'No', value: 'No' },
                                                { label: 'Not aware', value: 'NotAware' }
                                            ]}
                                            name="BatteryPoweredDevice"
                                            form={form}
                                        />
                                        If yes, what kind of device?
                                        <TextField
                                            className="doc"
                                            name="DeviceType"
                                            form={form}
                                            disabled={form.getField('BatteryPoweredDevice') !== 'Yes'}
                                        />
                                    </li>
                                    <li>
                                        Has the device been removed?
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            name="DeviceRemoved"
                                            form={form}
                                            disabled={form.getField('BatteryPoweredDevice') !== 'Yes'}
                                        />
                                    </li>
                                    <li>
                                        If no, do you give permission for removal by an appropriately qualified person?
                                        <Select
                                            className="doc select"
                                            options={[
                                                { label: 'Yes', value: 'Yes' },
                                                { label: 'No', value: 'No' },
                                                { label: 'Not aware', value: 'NotAware' }
                                            ]}
                                            name="PermissionForDeviceRemoval"
                                            form={form}
                                            disabled={form.getField('DeviceRemoved')}
                                        />
                                    </li>
                                </ol>
                                <div>
                                    (If device is present, crematory authorities may decline to cremate the deceased as
                                    battery powered device may explode during cremation)
                                </div>
                            </li>
                        </ol>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

const styles = () => ({
    addressCustom: {
        width: 'auto'
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export default compose(withRouter, withStyles(styles))(StatDocument);
