import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = ({ palette, funeralHome }) => ({
    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        fontSize: '0.875rem',
        width: '100%',
        '& th, & td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px'
        },
        '& th:last-child, & td:last-child': {
            borderWidth: '1px 1px 0px 1px'
        },
        '& tr:last-child th, & tr:last-child td': {
            borderWidth: '1px 0px 1px 1px'
        },
        '& tr:last-child th:last-child, & tr:last-child td:last-child': {
            borderRightWidth: '1px'
        }
    },
    tableBody: {
        '& tr:last-of-type > td': { borderBottom: '2px solid ' + palette.baseSecondary[funeralHome] }
    },
    tableHeaderRow: {
        '& th': {
            background: palette.baseSecondary[funeralHome],
            border: '1px solid ' + palette.baseSecondary[funeralHome],
            borderLeft: '1px dotted ' + palette.basePrimary[funeralHome] + 33,
            padding: '3px 0.5rem',
            height: '2.5rem',
            color: palette.table.header.foregroundColor,
            fontSize: '0.75rem',
            fontWeight: '400',
            textAlign: 'left'
        },
        '& th:first-child': {
            borderTopLeftRadius: '6px'
        },
        '& th:last-child': {
            borderTopRightRadius: '6px'
        },
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            fontSize: '0.875rem;',
            padding: '0px 0.5rem',
            borderColor: palette.basePrimary[funeralHome] + 33
        }
    },

    tableSecondaryHeaderRow: {
        fontVariant: 'all-small-caps',
        '& th': {
            background: palette.table.headerSecondary.backgroundColor,
            borderColor: palette.basePrimary[funeralHome] + 33,
            padding: '0.625rem 0.5rem',
            color: palette.table.headerSecondary.foregroundColor,
            fontSize: '0.875rem',
            fontWeight: '500',
            textAlign: 'left',
            borderLeftStyle: 'dotted',
            borderTopColor: palette.baseSecondary[funeralHome]
        },
        '& th:first-child': {
            borderTopLeftRadius: '0px',
            borderLeftStyle: 'solid'
        },
        '& th:last-child': {
            borderTopRightRadius: '0px'
        }
    },

    tableRow: {
        '&:nth-child(2n+1)': { background: palette.baseSecondary[funeralHome] + '19' },
        '&:nth-child(2n)': { background: palette.baseSecondary[funeralHome] + '10' },
        '& td': {
            borderStyle: 'dotted',
            borderTopStyle: 'solid',
            borderBottomStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            fontSize: '0.875rem;',
            padding: '0px 0.5rem',
            borderColor: palette.basePrimary[funeralHome] + 33,
            borderTopColor: palette.basePrimary[funeralHome] + 66,
            '&:first-child': {
                borderLeftStyle: 'solid'
            },
            '&:last-child': {
                borderRightStyle: 'solid'
            }
        },
        '&:nth-child(n):hover': {
            backgroundColor: palette.table.row.hoverColor
            // cursor: 'pointer'
        }
    },

    clickRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    },

    tableNestedRow: {
        '& td': {
            borderStyle: 'solid',
            borderWidth: '1px 0px 0px 1px',
            borderColor: palette.basePrimary[funeralHome] + 33,
            background: palette.table.nestedRow.backgroundColor
        },
        '& td:last-child:first-child': {
            borderWidth: '1px 1px 0px 1px'
        },
        '& td:last-child': {
            borderWidth: '1px 1px 0px 0px'
        },
        '& tr:last-child td': {
            borderWidth: '1px 0px 1px 1px'
        },
        '& tr:last-child td:last-child': {
            //     borderWidth: '0px 1px 1px 0px '
        },
        '& td:first-child': {
            padding: '0.5rem 0.5rem 0.5rem 1.5rem'
        },
        '&:hover td': {
            backgroundColor: palette.table.nestedRow.hoverColor
        }
    },

    paddedRow: {
        '& td': {
            padding: '0.5rem'
        }
    },

    colXs: {
        width: '60px'
    },

    colSm: {
        width: '80px'
    },

    colMd: {
        width: '130px'
    },
    containerClassName: {
        overflow: 'auto',
        padding: 6,
        margin: -6,
        paddingLeft: 12,
        marginLeft: -12 // leave room for decorators
    }
});

/**
 * Table
 * see the sampleTableData object to see what data needs to look like
 * @param {*} param0
 */

const Table = withStyles(styles)(({ children, ...props }) => {
    const { classes, containerClassName, className, columns, columnspans } = props;

    return (
        <div className={cx(classes.containerClassName, containerClassName)}>
            <table className={cx(classes.table, className)}>
                {!!columns && (
                    <thead>
                        <TableHeaderRow>
                            {columns.map((column, columnIndex) => (
                                <TableHeaderCell
                                    colSpan={(!!columnspans && columnspans[columnIndex]) || null}
                                    key={columnIndex}
                                >
                                    {column}
                                </TableHeaderCell>
                            ))}
                        </TableHeaderRow>
                    </thead>
                )}
                <tbody className={classes.tableBody}>{children}</tbody>
            </table>
        </div>
    );
});

const TableHeaderRow = withStyles(styles)(({ pad, children, classes, className }) => {
    return (
        <tr className={cx(classes.tableHeaderRow, pad && classes.paddedRow, className, 'row--header')}>{children}</tr>
    );
});

const TableSecondaryHeaderRow = withStyles(styles)(({ pad, children, classes }) => {
    return <tr className={cx(classes.tableSecondaryHeaderRow, pad && classes.paddedRow)}>{children}</tr>;
});

const TableRow = withStyles(styles)(
    ({ pad, children, classes, className, onClick, onDragOver = null, onDragLeave = null, onDrop = null }) => {
        return (
            <tr
                className={cx(classes.tableRow, pad && classes.paddedRow, onClick && classes.clickRow, className)}
                onClick={onClick}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                {children}
            </tr>
        );
    }
);

const TableNestedRow = withStyles(styles)(({ pad, children, classes }) => {
    return <tr className={pad ? cx(classes.tableNestedRow, classes.paddedRow) : classes.tableNestedRow}>{children}</tr>;
});

const TableCell = withStyles(styles)(
    ({ className, pad, tiny = false, children, colSpan, dataLabel, centred = false }) => {
        const style = {};
        if (pad) style.padding = '8px';
        if (false === pad) style.padding = '0';
        if (tiny) style.width = '1px';
        if (centred) style.textAlign = 'center';
        return (
            <td className={className} style={style} colSpan={colSpan} data-label={dataLabel}>
                {children}
            </td>
        );
    }
);

const TableHeaderCell = withStyles(styles)(({ className, pad, children, colSpan }) => {
    return (
        <th className={className} style={pad ? { padding: '8px' } : {}} colSpan={colSpan}>
            {children}
        </th>
    );
});

export default Table;
export { TableHeaderRow, TableSecondaryHeaderRow, TableRow, TableNestedRow, TableCell, TableHeaderCell };
