import gql from 'graphql-tag';

export default gql`
    fragment WebServiceLogs on Funeral {
        WebServiceLogs {
            ID
            Created
            TestMode
            Environment
            RequestEndpoint
            Request
            Response
            ResponseBody
        }
    }
`;
