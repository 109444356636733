import { Arrangement, AuthorisedContacts, Certification, Informant } from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';
import Disposal from '../../fragments/Disposal';
import Grave from '../../fragments/Grave';

export default buildTabDataFragment(
    [
        gql`
            fragment CertificationFields on Funeral {
                ApplicantTitle
                ApplicantSurname
                ApplicantGivenName
                ApplicantMiddleName

                TransferActioned
                TransferCertificates
                TransferReady
                TransferComment

                RefereeDoctorDateSigned
                RefereeCremationCheck
                #RefereeCode

                #DoctorCode
                DoctorComment
                #DoctorAttachedToCode
                DoctorAttachedToFlag
                DoctorCoronerComment

                #CertificateSpecification
                #CertificateProductcode

                #DeathCertificateNumber
                RemovePacemaker
                CertificationChangesFromBDM
            }
        `,
        Arrangement,
        AuthorisedContacts,
        Certification,
        Informant,
        Disposal,
        Grave
    ],
    'Funeral'
);
