import React, { Component, createContext } from 'react';

const { Provider, Consumer } = createContext({ message: '' });

let globalSnackbarMessage;

// Call this to show a snackbar from anywhere in the code
export function setSnackbarMessage(message, success = false, snackbarDuration = 5000, snackbarException = false) {
    globalSnackbarMessage(message, success, snackbarDuration, snackbarException);
}

export class SnackbarMessageProvider extends Component {
    constructor(props) {
        super(props);

        globalSnackbarMessage = this.setSnackbarMessage;

        this.state = {
            snackbarMessage: '',
            snackbarHappy: false,
            snackbarDuration: 5000,
            setSnackbarMessage: this.setSnackbarMessage
        };
    }

    setSnackbarMessage = (snackbarMessage, snackbarHappy = false, snackbarDuration = 5000, snackbarException = false) => {
        this.setState({
            snackbarMessage: snackbarMessage,
            snackbarHappy: snackbarHappy,
            snackbarDuration: snackbarDuration,
            snackbarException: snackbarException
        });
    };

    render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }
}

export const SnackbarMessageConsumer = Consumer;

export function withSnackbarMessage(C) {
    return props => <Consumer>{value => <C {...value} {...props} />}</Consumer>;
}
