import gql from 'graphql-tag';

export default gql`
    fragment Conductors on Funeral {
        Conductors {
            ID
            Allocation
            Member {
                ID
                Surname
                FirstName
                Email
            }
        }
    }
`;
