import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { getClient } from './apollo';
import { theme } from './Theme';
import history from './History';
import { SearchTermProvider } from './context/searchTerm';
import { SnackbarMessageProvider } from './context/SnackbarMessage';
import { EditAppointmentProvider } from './context/EditAppointment';

const client = getClient();

export default ({ children }) => (
    <SnackbarMessageProvider>
        <SearchTermProvider>
            <EditAppointmentProvider>
                <ApolloProvider client={client}>
                    <Router history={history}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline />
                            {children}
                        </MuiThemeProvider>
                    </Router>
                </ApolloProvider>
            </EditAppointmentProvider>
        </SearchTermProvider>
    </SnackbarMessageProvider>
);
