import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment DefaultMusicians on Funeral {
        DefaultMusicians {
            ${BookingsFragmentInclude}
        }
    }
`;
