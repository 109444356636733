import gql from 'graphql-tag';
import { getClient } from '../../apollo';
import { niceDateFromString } from '../../util/date';
import { isNullOrUndefined } from '../../util/objects';
import { joinDefined } from '../../util/strings';

const MortuaryListFragment = gql`
    fragment MortuaryListFragment on Funeral {
        ID
        LegacyID
        LegacyKey
        FirstName
        MiddleName
        Surname

        PlaceOfViewingRequired

        ReadyForMortuary
        MortuaryActionReturnedReason
        DatetimeOfMortuaryCheckout
        DateTransferredToMortuary
        DatetimeReadyForMortuaryCheckout

        Disposal {
            ID
            Date
            Time
            CrematedOrBuried
            LocationFlattened
            Location {
                ID
                Name
            }
        }

        Certification {
            ID
            TypeOfBdmDeathCertificate
            Referee {
                ID
            }
        }

        RefereeCremationCheck

        PlaceOfService {
            ID
            Date
            Time
            Type
            LocationFlattened
            Location {
                ID
                Name
            }
        }

        PlaceOfViewingItems {
            ID
            Date
            Time
            Type
            LocationFlattened
            Location {
                ID
                Name
            }
        }
    }
`;

const MortuaryListQuery = gql`
    ${MortuaryListFragment}

    query {
        readFunerals(mortuary: true, checkout: false) {
            edges {
                node {
                    ...MortuaryListFragment
                }
            }
        }
    }
`;

export const getMortuaryList = () => {
    return getClient()
        .query({ query: MortuaryListQuery, fetchPolicy: 'network-only' })
        .then(
            ({ data }) => {
                const { readFunerals } = data;
                if (readFunerals) {
                    return {
                        items: readFunerals.edges.map(({ node }) => {
                            return {
                                ...node
                            };
                        })
                    };
                }
            },
            e => {
                console.error('failed to get mortuary list', e);
                return { error: 'failed to get mortuary list' };
            }
        );
};

export const formatLocationDateTime = contact => {
    if (isNullOrUndefined(contact)) return null;

    const date = !isNullOrUndefined(contact.Date) && contact.Date.length > 0 ? niceDateFromString(contact.Date) : null;

    return joinDefined([date, contact.Time], ' ');
};
