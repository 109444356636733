import gql from 'graphql-tag';

export default gql`
    fragment Hearse on Funeral {
        HearseRequired
        HearseComment
        HearseDriveAway
        HearseDriveAwayDate
        HearseDriveAwayTime
        HearseToPassHouse
        HearseToPassHouseDate
        HearseToPassHouseTime
        HearseToPassHouseAfterService
        HearseToPassHouseAfterServiceDate
        HearseToPassHouseAfterServiceTime
        FollowToDisposal
    }
`;

