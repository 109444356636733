module.exports = {
    // AdminChecks: require('../fragments/AdminChecks').default,
    AshesDetail: require('../fragments/AshesDetail').Prearrangement,
    // AuthorisedContacts: require('../fragments/AuthorisedContacts').Prearrangement,
    // BdmResponse: require('../fragments/BdmResponse').default,
    // BillTo: require('../fragments/BillTo').default,
    Cars: require('../fragments/Cars').Prearrangement,
    // Caterers: require('../fragments/Caterers').default,
    // CateringStaffs: require('../fragments/CateringStaffs').default,
    CemeteryCremetorium: require('../fragments/CemeteryCremetorium').Prearrangement,
    Certification: require('../fragments/Certification').Prearrangement,
    ChildrenDetails: require('../fragments/ChildrenDetails').Prearrangement,
    Clergy: require('../fragments/Clergy').Prearrangement,
    CoffinBearings: require('../fragments/CoffinBearings').Prearrangement,
    // DefaultMusicians: require('../fragments/DefaultMusicians').default,
    Disposal: require('../fragments/Disposal').Prearrangement,
    // EnterMusicians: require('../fragments/EnterMusicians').default,
    // EnterMusicSong: require('../fragments/EnterMusicSong').default,
    // ExitMusicians: require('../fragments/ExitMusicians').default,
    // ExitMusicSongs: require('../fragments/ExitMusicSongs').default,
    // Florist: require('../fragments/Florist').default,
    // FuneralDressing: require('../fragments/FuneralDressing').default,
    Grave: require('../fragments/Grave').Prearrangement,
    // GravesideMusicians: require('../fragments/GravesideMusicians').default,
    // GravesideMusicSongs: require('../fragments/GravesideMusicSongs').default,
    Informant: require('../fragments/Informant').Prearrangement,
    // Invoices: require('../fragments/Invoices').default,
    LegacyDocuments: require('../fragments/LegacyDocuments').Prearrangement,
    // Lifestory: require('../fragments/Lifestory').default,
    Limos: require('../fragments/Limos').Prearrangement,
    Marriages: require('../fragments/Marriages').Prearrangement,
    // MemorialStationary: require('../fragments/MemorialStationary').default,
    // NewspaperNotice: require('../fragments/NewspaperNotice').default,
    Parent1: require('../fragments/Parent1').Prearrangement,
    Parent2: require('../fragments/Parent2').Prearrangement,
    // PersonalTouches: require('../fragments/PersonalTouches').default,
    // PlaceOfDeath: require('../fragments/PlaceOfDeath').default,
    // TransferFrom: require('../fragments/TransferFrom').default,
    // PlaceOfDeathResidentialAddress: require('../fragments/PlaceOfDeathResidentialAddress').default,
    // TransferFromResidentialAddress: require('../fragments/TransferFromResidentialAddress').default,
    PlaceOfService: require('../fragments/PlaceOfService').Prearrangement,
    PlaceOfViewingItems: require('../fragments/PlaceOfViewingItems').Prearrangement
    // ReflectionMusicians: require('../fragments/ReflectionMusicians').default,
    // ReflectionMusicSongs: require('../fragments/ReflectionMusicSongs').default,
    // RefreshmentsVenue: require('../fragments/RefreshmentsVenue').default,
    // RslMasonic: require('../fragments/RslMasonic').default,
    // SpecialInstructions: require('../fragments/SpecialInstructions').default,
    // ValuableItems: require('../fragments/ValuableItems').default,
    // Arrangers: require('../fragments/Arrangers').default,
    // Coordinators: require('../fragments/Coordinators').default
};
