import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getValidationDecorations } from '../../util/validation';

class ValidationPlaceholder extends Component {
    render() {
        const decorations = getValidationDecorations(this.props, this.props.classes.root);

        const { children, classes } = this.props;

        if (!decorations.inError) return children;

        return (
            <div className={decorations.rootClass}>
                {children && <div className={classes.content}>{children}</div>}
                <div className={decorations.validationLabel}>{decorations.errorMsg}</div>
            </div>
        );
    }
}

const styles = ({ palette }) => ({
    content: {
        //child content
        margin: '7px 10px 0 10px'
    },
    root: {
        margin: '0 -3px',
        padding: 0,
        borderWidth: '1.5px',
        borderStyle: 'solid',
        display: 'inline-block',
        borderRadius: '5px',
        width: 'calc(100% + 6px)'
    },
    validationErrorBorder: {
        borderColor: `${palette.validation.error} !important`
    },
    validationErrorLabel: {
        color: `${palette.validation.error} !important`,
        borderColor: `${palette.validation.error} !important`
    },
    validationSuggestedBorder: {
        borderColor: `${palette.validation.suggested} !important`
    },
    validationSuggestedLabel: {
        color: `${palette.validation.suggested} !important`,
        borderColor: `${palette.validation.suggested} !important`
    },
    validationOptionalBorder: {
        borderColor: `${palette.validation.optional} !important`
    },
    validationOptionalLabel: {
        color: `${palette.validation.optional} !important`,
        borderColor: `${palette.validation.optional} !important`
    },
    validationLabel: {
        lineHeight: '1 !important',
        fontSize: '12px !important',
        padding: '5px 15px 5px 15px'
    }
});

export default withStyles(styles)(ValidationPlaceholder);
