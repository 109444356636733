import React, { Fragment } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import FileIcon from '@material-ui/icons/ContentCopy';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import { CustomPaging, FilteringState, PagingState, SearchState, SortingState } from '@devexpress/dx-react-grid';
import {
    Grid,
    PagingPanel,
    SearchPanel,
    Table,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { withRouter } from 'react-router-dom';
import { getClient } from '../../../apollo';
import { getProperty } from '../../../util/objects';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal/Modal';
import TableCell from '@material-ui/core/TableCell/TableCell';
import gql from 'graphql-tag';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalGQLError from './ModalGQLError';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const viewRow = row => {
    if (row.onView) {
        row.onView(row.id);
    } else {
        console.log('Viewing Row: ID ' + row);
    }
};

const IconsCell = props => {
    const styles = { marginRight: 10, cursor: 'pointer', width: 18, height: 18 };
    return (
        <Table.Cell>
            {props.icons.includes('view') ? (
                <SearchIcon style={{ color: '#4CAF50', ...styles }} onClick={() => viewRow(props.row)} />
            ) : null}
            {props.icons.includes('edit') ? (
                <CreateIcon style={{ color: '#4CAF50', ...styles }} onClick={props.row.onEditRow} />
            ) : null}
            {props.icons.includes('copy') ? (
                <FileIcon style={{ color: '#0174AB', ...styles }} onClick={props.row.onCopyRow} />
            ) : null}
            {props.icons.includes('delete') ? (
                <ClearIcon style={{ color: '#F45246', ...styles }} onClick={props.row.onDelete} />
            ) : null}
        </Table.Cell>
    );
};

const Cell = props => {
    const {
        column,
        row,
        value,
        tableRow: { rowId }
    } = props;
    if (column.name === 'icons') {
        return (
            <IconsCell
                {...props}
                set={row.id}
                icons={row.icons}
                //       onEditFunction={props.onEditFunction}
                //       onDeleteFunction={props.onDeleteFunction}
                //       onViewFunction={props.onViewFunction}
                //       icon_function={props.row.icon_functions}
            />
        );
    }
    let niceValue = column.render ? column.render(value, row, rowId) : value;
    if (column.format) niceValue = column.format(value);
    if (column.textAlign) niceValue = <div style={{ textAlign: column.textAlign }}>{niceValue}</div>;

    return (
        <Table.Cell
            row={row}
            value={niceValue}
            style={{ whiteSpace: !column.noWrap && 'normal' }}
            className={cx(column.className)}
        />
    );
};

const CustomFilterCell = props => {
    const { filter, onFilter, column } = props;
    const emptyLabel = 'Filter...';
    const { gqlFilterOperation } = column;
    return (
        <TableCell style={{ paddingRight: 8, paddingLeft: 8 }}>
            <Select
                className={column.className}
                style={{ width: '100%' }}
                displayEmpty
                value={filter ? filter.value : ''}
                options={column.gqlFilterOptions}
                onChange={({ target: { value } }) => onFilter({ value, operation: gqlFilterOperation || 'eq' })}
                renderValue={val => {
                    const obj = column.gqlFilterOptions.find(obj => obj.value === val);
                    return obj ? obj.label : <span style={{ color: '#33333366' }}>{emptyLabel}</span>;
                }}
            >
                <MenuItem value={''}>{emptyLabel}</MenuItem>
                {column.gqlFilterOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>
    );
};

// page sizes setting
const defaultAllowedGridPageSizes = [5, 10, 25, 50, 100, 250];

class GraphqlGrid extends React.Component {
    constructor(props) {
        super(props);

        this.serverFilterFields = [];
        this.serverSortFields = [];
        this.isSubscribed = true;

        this.state = {
            gqlQueryVariablesStr: '',
            loading: true,
            // sortable
            sorting: [],
            // search against table data
            searchValue: '',
            // filters
            filters: [],
            // pagination
            totalCount: 0,
            currentPage: 0,
            pageLimit: 10, // pageSize
            // data
            rows: [],
            // modal
            deleteModalOpen: false,
            deleteRecord: null,
            // reload
            reloadData: false,
            // delete error
            deleteErrorModalOpen: false,
            deleteRecordError: ''
        };
    }

    static getDerivedStateFromProps(newProps, oldState) {
        const { gqlQueryVariables } = newProps;
        const gqlQueryVariablesStr = JSON.stringify(gqlQueryVariables);
        if (gqlQueryVariablesStr !== oldState.gqlQueryVariablesStr) {
            return { ...oldState, gqlQueryVariablesStr, loading: true, currentPage: 0 };
        }
        return null;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentWillUnmount() {
        this.isSubscribed = false;
    }

    setState = (state, callback) => {
        if (this.isSubscribed) {
            super.setState(state, callback);
        }
    };

    onSortingChange = sorting => {
        this.setState({
            sorting,
            currentPage: 0,
            loading: true,
            reloadData: true
        });
    };

    changeFilters = filters => {
        //clear update delay timer
        if (!!this.filterChangeTimer) {
            clearTimeout(this.filterChangeTimer);
        }
        //add delay for data entering UX.
        this.filterChangeTimer = setTimeout(() => {
            this.setState({
                filters,
                currentPage: 0,
                loading: true,
                reloadData: true
            });
        }, 500);
    };

    changePageSize = value => {
        this.setState({
            pageLimit: value,
            loading: true
        });
    };

    changeCurrentPage = newPage => {
        this.setState({
            currentPage: newPage,
            loading: true
        });
    };

    changeSearchValue = value => {
        this.setState({ searchValue: value });
    };

    getPageOffset = () => {
        return this.state.currentPage * this.state.pageLimit;
    };

    Row = props => {
        const { onRowClick } = this.props;
        return (
            <Table.Row
                {...props}
                onClick={
                    onRowClick &&
                    (e => {
                        const cellText = document.getSelection();
                        if (cellText.type === 'Range') e.stopPropagation();
                        else onRowClick(e, props.row);
                    })
                }
                className={props.classes.hover}
                {...{ hover: true }}
            />
        );
    };

    PagingContainer = props => <PagingPanel.Container {...props} className={this.props.classes.pagerPanel} />;
    NoDataCellComponent = props => {
        const isFetchingData = this.state.loading;
        const { noResultsMessage } = this.props;
        return (
            <Table.NoDataCell
                {...props}
                getMessage={() => (isFetchingData ? 'Loading...' : noResultsMessage || 'No results.')}
            />
        );
    };

    render() {
        const { pageLimit, currentPage, totalCount, searchValue } = this.state;
        const styles = () => ({
            hover: {
                cursor: 'pointer'
            }
        });
        const requireFiltering = this.requireFilteringColumns();
        return (
            <Fragment>
                <Paper className={this.props.classes.paper}>
                    <div>
                        {this.state.loading && <div className={this.props.classes.reloading}></div>}
                        <Grid rows={this.state.rows} columns={this.props.columns}>
                            {!this.props.hideSearch ? (
                                <SearchState value={searchValue} onValueChange={this.changeSearchValue} />
                            ) : null}

                            {requireFiltering && (
                                <FilteringState
                                    filter={this.state.filters}
                                    onFiltersChange={this.changeFilters}
                                    columnExtensions={this.getFilteringColumnExtensions()}
                                />
                            )}

                            <SortingState
                                sorting={this.state.sorting}
                                onSortingChange={this.onSortingChange}
                                columnExtensions={this.getSortingColumnExtensions()}
                            />

                            <PagingState
                                pageSize={pageLimit}
                                onPageSizeChange={this.changePageSize}
                                currentPage={currentPage}
                                onCurrentPageChange={this.changeCurrentPage}
                            />
                            <CustomPaging totalCount={totalCount} />
                            <PagingPanel
                                containerComponent={this.PagingContainer}
                                pageSizes={this.props.gqlPageSizes || defaultAllowedGridPageSizes}
                            />

                            <Table
                                rowComponent={withStyles(styles)(this.Row)}
                                cellComponent={withRouter(Cell)}
                                columnExtensions={this.props.columns.map(col => {
                                    const item = { columnName: col.title || col.name };
                                    if (col.width) item.width = col.width;
                                    item.align = col.textAlign || 'left';
                                    return { ...item };
                                })}
                                noDataCellComponent={this.NoDataCellComponent}
                            />

                            <TableHeaderRow showSortingControls />
                            <TableColumnVisibility
                                hiddenColumnNames={this.props.columns.filter(col => col.hidden).map(col => col.name)}
                            />

                            {requireFiltering && <TableFilterRow cellComponent={this.getFilterCell} />}

                            {!this.props.hideSearch ? (
                                <>
                                    <Toolbar />
                                    <SearchPanel />
                                </>
                            ) : null}
                        </Grid>
                    </div>
                    {this.state.loading && <LinearProgress />}
                    <Modal open={this.state.deleteModalOpen} onClose={this.handleDeleteModalClose}>
                        <ModalConfirmDelete
                            record={this.state.deleteRecord}
                            onClose={this.handleDeleteModalClose}
                            onDelete={this.deleteRecord}
                            titleField={this.props.titleField}
                        />
                    </Modal>
                    <Modal open={this.state.deleteErrorModalOpen} onClose={this.handleDeleteErrorModalClose}>
                        <ModalGQLError
                            onClose={this.handleDeleteErrorModalClose}
                            message={this.state.deleteRecordError}
                        />
                    </Modal>
                </Paper>
            </Fragment>
        );
    }

    // GraphQL setup & data loading
    readQuery = () => {
        let queryStr = this.props.gqlReadQuery;

        //append searchable field introspect query when `serverFilterFields` is empty
        if (!!this.props.gqlReadQueryFilterInputType && !this.serverFilterFields.length) {
            //prepend query fragment first
            queryStr =
                `
        fragment IntrospectInputEnumValues on __Type{
            name
            kind
            inputFields {
              name
              type {
                name
                kind
                ofType {
                  kind
                  name
                  description
                  enumValues {
                    name
                    description
                    isDeprecated
                    deprecationReason
                  }
                }
              }
            }
        }
      ` + queryStr;

            const filterFieldsIntroQuery = `
        filterIntrospect: __type(name: "${this.props.gqlReadQueryFilterInputType}") {
          ...IntrospectInputEnumValues
        }
      `;
            queryStr = queryStr.slice(0, queryStr.lastIndexOf('}')) + filterFieldsIntroQuery + '}';
        }

        //append sortable field introspect query when `serverSortFields` is empty
        if (!!this.props.gqlReadQuerySortInputType && !this.serverSortFields.length) {
            const sortFieldsIntroQuery = `
        sortIntrospect: __type(name: "${this.props.gqlReadQuerySortInputType}") {
          ...IntrospectInputEnumValues
        }
      `;
            queryStr = queryStr.slice(0, queryStr.lastIndexOf('}')) + sortFieldsIntroQuery + '}';
        }

        return gql(queryStr);
    };

    readQueryDataSelector = () => {
        return this.props.gqlReadDataSelector;
    };

    readQueryPageInfoSelector = () => {
        return this.props.gqlReadPageInfoSelector;
    };

    getSortingColumnExtensions = () => {
        const { columns } = this.props;
        if (columns && columns.length) {
            return columns.map(columnObj => {
                return {
                    columnName: columnObj.name,
                    sortingEnabled: this.isColumnSortable(columnObj)
                };
            });
        }
    };

    updatePropColumnObjectField(columnName, fieldName, fieldValue) {
        const { columns } = this.props;
        for (let i = 0; i < columns.length; i++) {
            let propColumnObj = columns[i];
            if (propColumnObj.name === columnName) {
                propColumnObj[fieldName] = fieldValue;
            }
        }
    }

    isColumnSortable(columnObj) {
        if (!!columnObj.gqlSortField && columnObj.gqlSortField.length) {
            //props config
            return true;
        } else if (!!columnObj.queryField && this.serverSortFields.length) {
            //check server config
            const match = this.serverSortFields.filter(
                /* @TODO this is just partial matching. It could be buggy for some complex user cases. */
                f => columnObj.queryField.indexOf(f) >= 0
            );
            if (match.length) {
                //update add `gqlSortField` value in props `columns`.
                //`loadData` function requires it for remote field lookup.
                this.updatePropColumnObjectField(columnObj.name, 'gqlSortField', match[0]);
            }

            return !!match.length;
        }

        return false;
    }

    getRemoteFieldNameBy = (gridColumnName, remoteFieldType) => {
        const { columns } = this.props;

        const match = columns.filter(colObj => colObj.name === gridColumnName && !!colObj[remoteFieldType]);

        return match.length ? match[0][remoteFieldType] : false;
    };

    getFilteringColumnExtensions = () => {
        const { columns } = this.props;
        if (columns && columns.length) {
            this.FilteringColumnExtensionsVar = columns.map(columnObj => {
                return {
                    columnName: columnObj.name,
                    filteringEnabled: this.isColumnFilterable(columnObj)
                };
            });
        }

        return this.FilteringColumnExtensionsVar;
    };

    isColumnFilterable(columnObj) {
        if (!!columnObj.gqlFilterField && columnObj.gqlFilterField.length) {
            //props config
            return true;
        } else if (!!columnObj.queryField && this.serverFilterFields.length) {
            //check server config
            const match = this.serverFilterFields.filter(
                /* @TODO this is just partial matching. It could be buggy for some complex user cases. */
                f => columnObj.queryField.indexOf(f) >= 0
            );
            if (match.length) {
                //update add `gqlFilterField` value in props `columns`.
                //`loadData` function requires it for remote field lookup.
                this.updatePropColumnObjectField(columnObj.name, 'gqlFilterField', match[0]);
            }
            return !!match.length;
        }

        return false;
    }

    requireFilteringColumns = () => {
        const columns = this.getFilteringColumnExtensions();
        for (let i = 0; i < columns.length; i++) {
            if (!!columns[i]['filteringEnabled']) return true;
        }
        return false;
    };

    getFilterCell = props => {
        if (props.filteringEnabled) {
            //normal filter input
            if (props.column.gqlFilterOptions) return <CustomFilterCell {...props} />;
            return <TableFilterRow.Cell {...props} />;
        } else {
            //return empty cell if filter is disabled.
            return <TableCell />;
        }
    };

    deleteQuery = () => {
        return this.props.gqlDeleteQuery;
    };

    setCurrentQueryHash = queryConfigObj => {
        this.currentQueryHash = JSON.stringify(queryConfigObj);
    };

    isQueryUpdated = queryConfigObj => {
        return this.currentQueryHash !== JSON.stringify(queryConfigObj);
    };

    loadData = () => {
        const client = getClient();
        const { gqlQueryVariables, gqlReadQuery, gqlDefaultMode } = this.props;
        const { filters, pageLimit, sorting, reloadData } = this.state;
        //pagination setting
        const queryVars = {
            ...gqlQueryVariables,
            offset: this.getPageOffset(),
            limit: pageLimit
        };

        //sort setting
        if (sorting && sorting.length) {
            if (gqlDefaultMode) {
                const mySorting = {};
                sorting.forEach(sortingObj => {
                    const sortingField = this.getRemoteFieldNameBy(sortingObj.columnName, 'gqlSortField');
                    // explode nested fields
                    const fieldNesting = sortingField.split('.');
                    let currentField = mySorting;
                    fieldNesting.forEach((field, idx) => {
                        if (idx === fieldNesting.length - 1) {
                            currentField[field] = sortingObj.direction.toUpperCase();
                        } else if (undefined === currentField[field]) {
                            currentField[field] = {};
                        }
                        currentField = currentField[field];
                    });
                });
                queryVars.sort = mySorting;
            } else {
                queryVars.sortBy = sorting.map(sortObj => {
                    // GQL sort config object
                    // `field` = SS field name
                    return {
                        field: this.getRemoteFieldNameBy(sortObj.columnName, 'gqlSortField'),
                        direction: sortObj.direction.toUpperCase()
                    };
                });
            }
        }
        //filters setting
        if (filters && filters.length) {
            if (gqlDefaultMode) {
                const myFilters = { ...queryVars.filter };
                filters.forEach(filterObj => {
                    if ('' !== filterObj.value) {
                        const filterField = this.getRemoteFieldNameBy(filterObj.columnName, 'gqlFilterField');
                        const filterOperation = this.getRemoteFieldNameBy(filterObj.columnName, 'gqlFilterOperation');
                        const filterNumber = this.getRemoteFieldNameBy(filterObj.columnName, 'gqlFilterNumber');
                        let filterValue = filterObj.value;
                        if (!(true === filterValue || false === filterValue)) {
                            filterValue = ('' + filterObj.value).replace(/[$#]/gi, ''); //ignore these
                        }
                        if (filterNumber) {
                            filterValue = Number(filterValue);
                        }
                        // explode nested fields
                        const fieldNesting = filterField.split('.');
                        let currentField = myFilters;
                        fieldNesting.forEach((field, idx) => {
                            if (idx === fieldNesting.length - 1) {
                                currentField[field] = {
                                    [filterOperation || filterObj.operation || 'contains']: filterValue
                                };
                            } else if (undefined === currentField[field]) {
                                currentField[field] = {};
                            }
                            currentField = currentField[field];
                        });
                    }
                });
                queryVars.filter = myFilters;
            } else {
                queryVars.filterBy = filters.map(filterObj => {
                    // GQL sort config object
                    // `field` = SS field name
                    return {
                        field: this.getRemoteFieldNameBy(filterObj.columnName, 'gqlFilterField'),
                        value: filterObj.value
                    };
                });
            }
        }

        //check query vars
        if (!this.isQueryUpdated(queryVars) && !reloadData) {
            return;
        }
        // set current query config to prevent same query loaded twice.
        this.setCurrentQueryHash(queryVars);
        if (reloadData) {
            this.setState({ reloadData: false });
        }

        const queryConfig = {
            query: gqlReadQuery || this.readQuery(),
            variables: queryVars,
            fetchPolicy: 'network-only' //reloadData ? 'network-only' : 'cache-first',
        };

        client.query(queryConfig).then(
            result => {
                //update filter settings if we got it from server
                const filterInfo = getProperty(result, 'data.filterIntrospect.inputFields.0.type.ofType.enumValues');
                if (filterInfo && filterInfo.length) {
                    this.updateFilterFields(filterInfo);
                }

                //update sort settings if we got it from server
                const sortInfo = getProperty(result, 'data.sortIntrospect.inputFields.0.type.ofType.enumValues');
                if (sortInfo && sortInfo.length) {
                    this.updateSortFields(sortInfo);
                }

                //update rows
                if (result && result.data) {
                    //success and got data
                    const dataRows = getProperty(result.data, this.readQueryDataSelector());

                    if (dataRows !== undefined) {
                        // push data to state according to data map
                        let tmpRows = [];
                        dataRows.forEach(dataObject => {
                            let newRow = this.dataFieldsMapping(dataObject);
                            //inject edit callback
                            if (!!this.props.onEditRow) {
                                newRow.onEditRow = () => this.props.onEditRow(newRow.id);
                            }
                            if (!!this.props.onCopyRow) {
                                newRow.onCopyRow = () => this.props.onCopyRow(newRow.id);
                            }
                            //inject delete callback
                            newRow.onDelete = () => this.openDeleteModal(newRow);

                            // inject view callback
                            if (!!this.props.onViewRow) {
                                newRow.onView = () => this.props.onViewRow(newRow.id);
                            }

                            tmpRows.push(newRow);
                        });
                        // update rows
                        this.setState({ rows: tmpRows });
                    }
                }

                const { totalCount } = getProperty(result.data, this.readQueryPageInfoSelector());
                //update count
                this.setState({
                    totalCount,
                    loading: false
                });
            },
            e => {
                console.error('an error occurred while building the rows', e);
                this.setState({ loading: false });
            }
        );
    };

    updateFilterFields = searchableFields => {
        this.serverFilterFields = [];
        searchableFields.forEach(filterFieldInputObj => {
            if (!filterFieldInputObj.isDeprecated) {
                this.serverFilterFields.push(filterFieldInputObj.name);
            }
        });
    };

    updateSortFields = sortableFields => {
        this.serverSortFields = [];
        sortableFields.forEach(sortFieldInputObj => {
            if (!sortFieldInputObj.isDeprecated) {
                this.serverSortFields.push(sortFieldInputObj.name);
            }
        });
    };

    /**
     * Get map from Grid column name to GraphQL field name.
     *
     * Try to get it from props `gqlReadDataMap` first.
     *
     * Then will try to extract it from props `columns`.
     *
     * output
     *
     * {
     *   id: "node.ID",
     *   title: "node.Title",
     *   membername: "node.Member.Title",
     * }
     *
     * @returns {*}
     */
    getGraphqlDataMap() {
        //from config
        if (!!this.props.gqlReadDataMap) {
            return this.props.gqlReadDataMap;
        }
        //from columns config
        let map = {};
        this.props.columns.forEach(column => {
            if (!!column.queryField) {
                map[column.name] = column.queryField;
            }
        });
        return map;
    }

    dataFieldsMapping = (sourceObject, fieldsMap) => {
        if (fieldsMap === undefined) {
            fieldsMap = this.getGraphqlDataMap();
        }

        const tmpObj = {};

        Object.keys(fieldsMap).forEach(destKey => {
            //create key with empty value
            tmpObj[destKey] = '';
            //get value from source object
            let sourceKey = fieldsMap[destKey];
            let sourceValue = getProperty(sourceObject, sourceKey);
            //set value to row element if it's not empty
            if (sourceValue !== undefined) {
                tmpObj[destKey] = sourceValue;
            }
        });

        // actions - permission
        tmpObj.icons = this.props.icons ? this.props.icons : ['edit', 'delete'];
        tmpObj.node = sourceObject.node;
        return tmpObj;
    };

    deleteRecord = recordId => {
        this.setState({ loading: true });

        getClient()
            .mutate({
                mutation: this.deleteQuery(),
                variables: { ids: [recordId] }
            })
            .then(result => {
                this.handleDeleteModalClose(true); // close modal and reload data
            })
            .catch(error => {
                console.log(error);
                if (this.props.onGqlError) {
                    this.props.onGqlError(error, 'Delete ' + this.props.gqlObjectName);
                } else {
                    this.openDeleteErrorModal(error.message);
                }
                this.handleDeleteModalClose();
            });
    };

    handleDeleteModalClose = doReload => {
        this.setState({
            deleteModalOpen: false,
            loading: !!doReload, // show up loading spinner if it requires to reload data.
            reloadData: !!doReload
        });
    };

    openDeleteModal = record => {
        this.setState({
            deleteModalOpen: true,
            deleteRecord: record
        });
    };

    openDeleteErrorModal(error) {
        this.setState({
            deleteErrorModalOpen: true,
            deleteRecordError: error
        });
    }

    handleDeleteErrorModalClose = () => {
        this.setState({
            deleteErrorModalOpen: false,
            deleteRecordError: ''
        });
    };
}

const styles = ({ palette }) => ({
    paper: {
        '& > div': { position: 'relative' },
        '& table': {
            overflow: 'unset',
            '& tbody tr:hover, & tbody tr:hover td': {
                backgroundColor: palette.action.selected + '!important'
            },
            '& thead th:first-of-type, & tbody td:first-of-type': {
                position: 'sticky',
                left: 0,
                zIndex: 2,
                background: 'linear-gradient(270deg, transparent 0, white 18px, white 100%)!important'
            },
            '& tbody tr:hover td:first-of-type': {
                background: `linear-gradient(270deg, transparent 0, ${palette.action.selected} 18px, ${palette.action.selected} 100%)!important`
            }
        },
        '& > div > div:last-child button:not([class*=disabled])': {
            borderBottom: '2px solid transparent',
            color: palette.primary.main
        },
        '& > div > div:last-child button[class*=activeButton]': {
            color: 'black',
            borderBottom: '2px solid ' + palette.primary.main,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    pagerPanel: {
        '& button:first-of-type': {
            padding: 0
        },
        '& button:last-of-type': {
            padding: 0
        }
    },
    reloading: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#ffffff99',
        zIndex: 9
    }
});
export default withRouter(withStyles(styles)(GraphqlGrid));
