import React, { Component } from 'react';
import { Step, StepConnector, StepIcon, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';

class FormStepper extends Component {
    render() {
        const {
            alternativeLabel,
            nonLinear,
            activeStep,
            children,
            classes,
            orientation,
            tabs,
            completedSteps,
            onStepperStep
        } = this.props;
        return (
            <Stepper
                alternativeLabel={alternativeLabel}
                nonLinear={nonLinear}
                activeStep={activeStep}
                children={children}
                classes={classes}
                /*connector={<StepperConnector />}*/
                orientation={orientation}
            >
                {tabs &&
                    tabs.map((t, index) => {
                        const completed = completedSteps.has(index);
                        const optional = t.isOptional === true;
                        const props = { completed };
                        const buttonProps = {};
                        const disabled = t.disabled;

                        if (optional) {
                            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                        }

                        return (
                            <StepperStep key={t.label} {...props}>
                                <StepperStepLabel
                                    onClick={e => onStepperStep(index)}
                                    completed={completed}
                                    disabled={disabled}
                                    {...buttonProps}
                                    StepIconComponent={StepperStepIcon}
                                >
                                    {disabled ? (
                                        <div className={classes.stepLabel}>{t.label}</div>
                                    ) : (
                                        <div className={classes.stepLabel}>{t.label}</div>
                                    )}
                                </StepperStepLabel>
                            </StepperStep>
                        );
                    })}
            </Stepper>
        );
    }
}

const stepperStyles = theme => ({
    root: {
        width: '100%'
    }
});

const stepStyles = theme => ({
    root: {
        width: '100%'
    }
});

const labelStyles = theme => ({
    root: {
        width: '100%'
    }
});

const connectorStyles = theme => ({
    active: {
        '& $line': {
            backgroundColor: '#26CC6F'
        }
    },
    completed: {
        '& $line': {
            backgroundColor: '#26CC6F'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
});

const iconStyles = theme => ({
    root: {
        transform: 'scale(1.5)',
        zIndex: 1,
        '& circle': {
            fill: '#ffffff',
            stroke: '#D5D5D5',
            strokeWidth: 1,
            r: 11
        },
        '& text': {
            fill: '#D5D5D5',
            fontSize: '0.7rem'
        }
    },
    completed: {
        transform: 'scale(1.5)',
        '& path': {
            fill: '#26CC6F'
        }
    },
    active: {
        transform: 'scale(2)',
        '& circle': {
            fill: theme.palette.contentForeground[theme.funeralHome || 'none'],
            r: 11,
            strokeWidth: 0
        },
        '& text': {
            fill: '#FFFFFF',
            fontSize: '0.6rem'
        }
    }
});

export const StepperStep = withStyles(stepStyles)(Step);
export const StepperStepIcon = withStyles(iconStyles)(StepIcon);
export const StepperStepLabel = withStyles(labelStyles)(StepLabel);
export const StepperConnector = withStyles(connectorStyles)(StepConnector);

export default withStyles(stepperStyles)(FormStepper);
