import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../context/SnackbarMessage';
import { withTheme } from '@material-ui/core/styles';
import { IconButton, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import * as Icons from '../component/IconIndex';
import { AddIcon } from '../component/IconIndex';
import Grid from '../component/form/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import PrimaryButton, { AltButton, DestroyButton, OutlineButton, SaveButton } from '../component/form/PrimaryButton';
import LinkButton from '../component/form/LinkButton';
import ActionButton from '../component/form/ActionButton';
import Icon from '@material-ui/core/Icon';
import ColumnLayout from '../component/ColumnLayout';
import ButtonWrapper from './workQueue2/extras/Button';
import BiggerMessageBar from '../component/BiggerMessageBar';

class IconsPreview extends Component {
    state = {
        activeTab: 0
    };

    render() {
        const { activeTab } = this.state;
        return (
            <Paper>
                <Tabs value={activeTab} onChange={(evt, idx) => this.setState({ activeTab: idx })}>
                    <Tab label="Icons" />
                    <Tab label="Buttons" />
                    <Tab label="Messages" />
                </Tabs>
                <Grid pc={1} style={{ padding: '12px 24px' }}>
                    <Grid container spacing={8}>
                        {activeTab === 0 && this.renderIcons()}
                        {activeTab === 1 && this.renderButtons()}
                        {activeTab === 2 && this.renderMessages()}
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    renderIcons() {
        return Object.keys(Icons).map(key => {
            const Icon = Icons[key];
            return (
                <Grid item xs={6} md={3} key={key}>
                    <Icon value={66} /> &nbsp;&lt;{key}/&gt;
                </Grid>
            );
        });
    }

    renderButtons() {
        const { classes } = this.props;
        return (
            <ColumnLayout>
                <div className={classes.buttonList}>
                    <Grid item xs={12}>
                        Simple Buttons
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton>PrimaryButton</PrimaryButton>
                        {'<PrimaryButton/>'}
                        <br />
                        Basic button, default.
                    </Grid>
                    <Grid item xs={12}>
                        <AltButton>AltButton</AltButton>
                        {'<AltButton/>'}
                        <br />
                        Highlighted action.
                    </Grid>
                    <Grid item xs={12}>
                        <OutlineButton>OutlineButton</OutlineButton>
                        {'<OutlineButton/>'}
                        <br />
                        Cancel, close or minor action.
                    </Grid>
                    <Grid item xs={12}>
                        <SaveButton>SaveButton</SaveButton>
                        {'<SaveButton/>'}
                        <br />
                        Write/save to database. Immediate action.
                    </Grid>
                    <Grid item xs={12}>
                        <DestroyButton>DestroyButton</DestroyButton>
                        {'<DestroyButton/>'}
                        <br />
                        Write/delete to database. Immediate action.
                    </Grid>
                    <Grid item xs={12}>
                        <LinkButton href={'#'} text={'text'}>
                            LinkButton
                        </LinkButton>
                        {'<LinkButton text="text" href/>'}
                        <br />
                        Link
                    </Grid>
                    <Grid item xs={12}>
                        <IconButton>
                            <Icon>delete</Icon>
                        </IconButton>
                        {'<IconButton/>'}
                        <br />
                        Icons.
                    </Grid>
                </div>
                <div className={classes.buttonList}>
                    <Grid item xs={12}>
                        <div style={{ width: 150, display: 'inline-block' }}>
                            <ActionButton text={'ActionButton'}></ActionButton>
                        </div>
                        {'<ActionButton icon text/>'}
                        <br />
                        Action button
                    </Grid>
                    <Grid item xs={12} key={1}>
                        <ButtonWrapper icon={<AddIcon />}>ButtonWrapper</ButtonWrapper>
                        {'<ButtonWrapper icon>text</ButtonWrapper>'}
                        <br />
                        Include an icon.
                    </Grid>
                    <Grid item xs={12} key={2}>
                        <ButtonWrapper endIcon={<AddIcon />}>ButtonWrapper</ButtonWrapper>
                        {'<ButtonWrapper endIcon/>'}
                        <br />
                        Include an icon at the end.
                    </Grid>
                    <Grid item xs={12} key={3}>
                        <ButtonWrapper icon={<AddIcon />} />
                        {'<ButtonWrapper icon/>'}
                        <br />
                        Icon button
                    </Grid>
                    <Grid item xs={12} key={4}>
                        <ButtonWrapper variant="tag" onClick={() => undefined}>
                            I'm a tag!
                        </ButtonWrapper>
                        {'<ButtonWrapper variant="tag"/>'}
                        <br />
                        Tag
                    </Grid>
                    <Grid item xs={12} key={5}>
                        <ButtonWrapper variant="link" href="#">
                            I'm a link!
                        </ButtonWrapper>
                        {'<ButtonWrapper variant="link" href/>'}
                        <br />
                        Link
                    </Grid>
                </div>
            </ColumnLayout>
        );
    }

    renderMessages() {
        return (
            <Grid item xs={12}>
                <BiggerMessageBar>Default (info) message</BiggerMessageBar>
                <BiggerMessageBar messageType="good">"good" message</BiggerMessageBar>
                <BiggerMessageBar messageType="error">"error" message</BiggerMessageBar>
                <BiggerMessageBar messageType="approve">"approve" message</BiggerMessageBar>
                <BiggerMessageBar messageType="warning">"warning" message</BiggerMessageBar>
                <BiggerMessageBar messageType="loading">"loading" message</BiggerMessageBar>
            </Grid>
        );
    }
}

const styles = () => ({
    buttonList: {}
});
export default compose(withSnackbarMessage, withTheme(), withStyles(styles))(IconsPreview);
