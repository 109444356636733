import gql from 'graphql-tag';
import { getClient } from '../../../apollo';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { deleteTypeName } from '../../../util/objects';
import { cloneDeep } from 'apollo-utilities';
import { flattenConnection } from '../../../util/functions';
import { dateTimeToString } from '../../../util/date';

const FuneralAdminCheckFragment = gql`
    fragment FuneralAdminCheck on FuneralAdminCheck {
        ID
        Task {
            ID
            Title
            Description
            RequiredAction
            Completed
            AssignedMembers {
                ID
                FirstName
                Surname
            }
        }
        Checked
        MortuaryMember {
            ID
            FirstName
            Surname
        }
        Member {
            ID
            FirstName
            Surname
        }
        AdminCheck {
            ID
        }
    }
`;

export const CheckoutItemFragment = gql`
    fragment CheckoutItem on Funeral {
        ID
        LegacyKey
        Cancelled
        FirstName
        MiddleName
        Surname

        DateOfBirth
        DateOfDeath1
        DateOfDeath2
        DateOfDeathType
        Age
        AgeCode

        Certification {
            ID
            TypeOfBdmDeathCertificate
            Referee {
                ID
            }
        }

        RefereeDoctorDateSigned
        RefereeCremationCheck
        RemovePacemaker

        ReadyForMortuary
        DateTransferredToMortuary
        DatetimeReadyForMortuaryCheckout
        DatetimeOfMortuaryCheckout
        MortuaryActionReturnedReason
        EstimatedDeceasedWeight

        FuneralDressing {
            ID
            Status
            Received
            Comment
            GreekShroud
            Shroud
            Date
            Time
        }

        Disposal {
            ID
            CrematedOrBuried
            Date
            Time
            Start
            LocationFlattened
            Location {
                ID
                Name
            }
            DisposalBookingItems {
                ID
                Quantity
                Comment
                Returns
                Product {
                    ID
                    CoffinTrim
                    Parent {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                    ProductCategories {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                }
                Variation {
                    ID
                }
            }
        }

        Clergy {
            ID
            Contact {
                ID
                Name
                ClergyCategory
            }
        }

        RslMasonic {
            ID
            Contact {
                ID
                Name
            }
        }

        ValuableItems {
            ID
            Description
            Quantity
            ToBePlaced
            CurrentLocation
            CurrentLocationDetail
            WhereToBeReturned
            ToBeReturned
            Note
        }

        PlaceOfService {
            ID
            Date
            Time
            Start
            Type
            LocationFlattened
            Location {
                ID
                Name
            }
        }

        PlaceOfViewingRequired
        PlaceOfViewingItems {
            ID
            Date
            Time
            Start
            Type
            LocationFlattened
            Location {
                ID
                Name
            }
        }

        RefreshmentsVenue {
            ID
            Date
            Time
            Start
            Type
            LocationFlattened
            Location {
                ID
                Name
            }
        }

        Florists {
            ID
            Confirmed
            Comment
            Contact {
                ID
                Name
            }
            BookingItems {
                edges {
                    node {
                        ID
                        Quantity
                        Comment
                        Product {
                            ID
                            ProductCategories {
                                ... on ProductCategory {
                                    ID
                                    URLSegment
                                }
                                ... on CoreProductCategory {
                                    ID
                                    URLSegment
                                }
                            }
                        }
                        Variation {
                            ID
                        }
                    }
                }
            }
        }
        DateOfService
        CoffinComment

        DeceasedPhotos {
            ... on File {
                ID
                AbsoluteLink
                Name
            }
            ... on Image {
                ID
                AbsoluteLink
                Name
            }
        }

        Grave {
            ID
            GroundDescription
            DepthOption
            ReservationRequired
            ReservationName
            PortionGardenName
            BeamRowLot
            SideSection
            Number
        }

        Coordinators {
            ID
            Member {
                ID
                FirstName
                Surname
            }
        }

        Arrangers {
            ID
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const saveFuneralAdminCheckQuery = gql`
    ${FuneralAdminCheckFragment}
    mutation UpdateFuneralAdminCheck($input: UpdateFuneralAdminCheckInput!) {
        updateFuneralAdminCheck(input: $input) {
            ...FuneralAdminCheck
        }
    }
`;

export const saveFuneralAdminCheck = funeralAdminCheck => {
    return getClient()
        .mutate({ mutation: saveFuneralAdminCheckQuery, variables: { input: funeralAdminCheck } })
        .then(
            ({ data }) => {
                let result = cloneDeep(data.updateFuneralAdminCheck);

                if (isRelatedObjectDefined(result.Task)) {
                    flattenConnection(result.Task, 'AssignedMembers');
                } else {
                    delete result.Task;
                }

                return result;
            },
            e => {
                console.error('an error occurred saving admin checks', e);
                return false;
            }
        );
};

export const sanitizeFuneralAdminCheck = funeralAdminCheck => {
    if (isRelatedObjectDefined(funeralAdminCheck.Member)) {
        funeralAdminCheck.MemberID = funeralAdminCheck.Member.ID;
    } else {
        funeralAdminCheck.MemberID = null;
    }
    //always delete, we never create a new member
    delete funeralAdminCheck.Member;

    if (isRelatedObjectDefined(funeralAdminCheck.MortuaryMember)) {
        funeralAdminCheck.MortuaryMemberID = funeralAdminCheck.MortuaryMember.ID;
    } else {
        funeralAdminCheck.MortuaryMemberID = null;
    }
    //always delete, we never create a new member
    delete funeralAdminCheck.MortuaryMember;

    //undefined means we're adding a new ID
    if (funeralAdminCheck.Task && funeralAdminCheck.Task !== null && funeralAdminCheck.Task.ID === '0') {
        //only delete if task defined
        delete funeralAdminCheck.Task;
    }

    if (!!funeralAdminCheck.Checked && isRelatedObjectDefined(funeralAdminCheck.Task)) {
        funeralAdminCheck.Task.Completed = dateTimeToString(new Date());
        delete funeralAdminCheck.Task.AssignedMembers;
    }

    if (funeralAdminCheck.ID === null) delete funeralAdminCheck.ID;

    return deleteTypeName(funeralAdminCheck);
};
