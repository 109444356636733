export const PLACE_OF_SERVICE_TYPE_OPTIONS = [
    { label: 'Graveside', value: 'Graveside' },
    { label: 'Mass', value: 'Mass' },
    { label: 'Memorial Service', value: 'Memorial Service' },
    { label: 'No Service No Attendance', value: 'No Service No Attendance' },
    { label: 'Prayers', value: 'Prayers' },
    { label: 'Full Service', value: 'Service' },
    { label: 'Sit in Chapel', value: 'Sit in Chapel' },
    { label: 'Thanksgiving Service', value: 'Thanksgiving Service' }
];

export const DISPOSAL_METHOD_OPTIONS = [
    { label: 'Body Donation', value: 'Body Donation' },
    { label: 'Buried', value: 'Buried' },
    { label: 'Cremated', value: 'Cremated' },
    { label: 'Repatriated', value: 'Repatriated' }
];

export const PLACE_OF_VIEWING_TYPE_OPTIONS = [
    { label: 'Viewing', value: 'Viewing' },
    { label: 'Prayers', value: 'Prayers' },
    { label: 'Rosary', value: 'Rosary' },
    { label: 'Family Wish to Dress', value: 'Family Wish to Dress' },
    { label: 'Memorial Service', value: 'Memorial Service' },
    { label: 'Witness of Insertion', value: 'Witness of Insertion' },
    { label: 'Other Appointment', value: 'Appointment' }
];

export const COFFIN_BEARERS_OPTIONS = [
    { label: 'Bearers by Family', value: 'Bearers by Family' },
    { label: 'Bearers by Funeral Director', value: 'Bearers by Funeral Director' },
    { label: 'Bearers by both', value: 'Bearers by both' }
];

export const COFFIN_BEARING_TYPE_OPTIONS = [
    { label: 'Carry In', value: 'CarryIn' },
    { label: 'Carry Out', value: 'CarryOut' },
    { label: 'Carry Graveside', value: 'CarryGraveside' }
];

export const GROUND_TYPE_OPTIONS = [
    { label: 'Crypt', value: 'Crypt' },
    { label: 'New Ground', value: 'New Ground' },
    { label: 'Old res', value: 'Old res' },
    { label: 'Reopened', value: 'Reopened' }
];

export const GRAVE_DEPTH_OPTIONS = [
    { label: 'Crypt', value: 'Crypt' },
    { label: 'Double Depth', value: 'Double Depth' },
    { label: 'Single Depth', value: 'Single Depth' },
    { label: 'Triple Depth', value: 'Triple Depth' }
];

export const DRESSING_DELIVER_TO_FUNERAL_HOME = 'Clothing will be delivered to Funeral Home';

export const DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT = 'Clothing collected at time of Arrangement';

export const DRESSING_STATUS_OPTIONS = [
    {
        label: DRESSING_DELIVER_TO_FUNERAL_HOME,
        value: DRESSING_DELIVER_TO_FUNERAL_HOME
    },
    { label: 'Already dressed', value: 'Already dressed' },
    { label: 'Family wish to dress', value: 'Family wish to dress' },
    {
        label: DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT,
        value: DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT
    }
];
