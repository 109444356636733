import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from '../../../component/form/PrimaryButton';
import FilterIcon from '../../../component/icon/FilterIcon';
import Grid from '../../../component/form/Grid';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import Checkbox from '../../../component/form/Checkbox';
import { TAB_URL_SEGMENTS } from '../components/TaskConstants';
import Hidden from '@material-ui/core/Hidden';

class FilterTasksPopover extends Component {
    state = {
        onClose: () => {},
        open: false,
        filters: this.createEmptyFilters()
    };

    render() {
        const { classes } = this.props;
        const { anchorEl, open } = this.state;
        return (
            <Fragment>
                <Hidden smDown>
                    <PrimaryButton
                        onClick={e => this.setState({ open: true, anchorEl: e.currentTarget })}
                        className={classes.btnUp}
                    >
                        <FilterIcon className={classes.filterIcon} />
                        Filters{hasActiveFilters(this.state.filters) && !open ? ' ON' : ''}...
                    </PrimaryButton>
                </Hidden>
                <Hidden mdUp>
                    <PrimaryButton
                        onClick={e => this.setState({ open: true, anchorEl: e.currentTarget })}
                        className={classes.btnSm}
                    >
                        <FilterIcon className={classes.filterIcon} />
                        {hasActiveFilters(this.state.filters) ? ' ON' : ''}
                    </PrimaryButton>
                </Hidden>
                <Popover
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => this.onClose()}
                    anchorReference={'anchorEl'}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <div className={classes.paper}>
                        <Typography variant="headline" id="modal-title">
                            Filter
                        </Typography>
                        <div className={classes.paperScroll}>{this.renderTaskFilters()}</div>
                        <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                            <Button onClick={() => this.onClose()} size="small" className={cx(classes.buttonCancel)}>
                                CANCEL
                            </Button>
                            &nbsp;
                            <Button
                                onClick={() => this.onClearFilters()}
                                size="small"
                                className={cx(classes.buttonCancel)}
                            >
                                CLEAR
                            </Button>
                            &nbsp;
                            <Button onClick={() => this.onApply()} size="small" className={cx(classes.buttonOk)}>
                                OK
                            </Button>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                </Popover>
            </Fragment>
        );
    }

    renderTaskFilters() {
        const { classes } = this.props;
        const { filters } = this.state;
        return (
            <Fragment>
                <Typography variant="title" id="standard" className={classes.buttonGroup}>
                    Task State
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Checkbox
                            label="Starred"
                            checked={filters.isStarred}
                            onChange={e => this.setFilter('isStarred', e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            label="Completed"
                            checked={filters.isComplete}
                            onChange={e => this.setFilter('isComplete', e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            label="Overdue"
                            checked={filters.isOverdue}
                            onChange={e => this.setFilter('isOverdue', e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            label="New (this week)"
                            checked={filters.isNew}
                            onChange={e => this.setFilter('isNew', e.target.checked)}
                        />
                    </Grid>
                </Grid>
                <Typography variant="title" id="segments" className={classes.buttonGroup}>
                    Funeral Section
                </Typography>
                <Grid container spacing={0}>
                    {TAB_URL_SEGMENTS.map((x, i) => {
                        const propertyPath = x.value.replace('-', '');
                        return (
                            <Grid item xs={6} key={i}>
                                <Checkbox
                                    label={x.label}
                                    checked={filters[propertyPath]}
                                    onChange={e => this.setFilter(propertyPath, e.target.checked)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Fragment>
        );
    }

    onSubmit(e) {
        const { onSubmit, task } = this.props;
        const { mutate } = this.state;
        if (onSubmit) onSubmit(task, mutate);
    }

    setFilter(property, value) {
        const { filters } = this.state;
        filters[property] = value;
        this.setState({ filters });
    }

    createEmptyFilters() {
        let filters = {
            offset: 0,
            limit: 1000,
            isComplete: null,
            isStarred: null,
            isOverdue: null,
            isNew: null
        };

        for (let x = 0; x < TAB_URL_SEGMENTS.length; x++) {
            filters[TAB_URL_SEGMENTS[x].value.replace('-', '')] = null;
        }

        return filters;
    }

    onApply() {
        const { onApply } = this.props;
        if (onApply) onApply({ ...this.state.filters });

        this.onClose();
    }

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({ open: false, anchorEl: null });
    }

    onClearFilters() {
        const filters = this.createEmptyFilters();
        this.setState({ filters });
        const { onApply } = this.props;
        if (onApply) onApply({ ...filters });

        this.onClose();
    }
}

export const hasActiveFilters = filters => {
    return filters && Object.keys(filters).find(x => x !== 'offset' && x !== 'limit' && filters[x] === true);
};

const styles = () => ({
    root: {
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            borderRadius: '10px'
        }
    },
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
        // marginRight: '10px'
        // boxShadow: 'none',
        // borderRadius: '18px'
    },
    paper: {
        width: '100%',
        maxWidth: 440,
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa',
        padding: 25
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 160px)',
        padding: '6px 0'
    },
    selectButton: {
        marginRight: 10,
        marginTop: 15
    },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    btnUp: {
        padding: '13px',
        '& svg': {
            marginRight: '5px'
        }
    },
    btnSm: {
        padding: '13px',
        minWidth: '40px',
        '& > span': {
            margin: '0',
            '& > svg': {
                margin: '0'
            }
        }
    }
});

export default withStyles(styles)(FilterTasksPopover);
