export const CALENDAR_TYPE = {
    appointment: {
        color: '#db23b2',
        backgroundColor: '#e1a1d3',
        label: 'General Appointment',
        className: 'FuneralManagerAppointment',
        type: 'Appointment'
    },
    prefuneral: {
        color: '#4f3fc0',
        backgroundColor: '#a5aef1',
        label: 'Pre-Funeral Appointment',
        className: 'FuneralManagerAppointment',
        type: 'PreFuneral'
    },
    arrangement: {
        color: '#bab821',
        backgroundColor: '#f4edbe',
        label: 'Arrangement',
        className: 'FuneralManagerAppointment',
        type: 'Arrangement'
    },
    viewing: {
        color: '#1f9bc9',
        backgroundColor: '#cde5f0',
        label: 'Viewing',
        className: 'FuneralManagerFuneralPlaceOfViewing',
        type: 'Viewing'
    },
    service: {
        color: '#26cc6f',
        backgroundColor: '#b8ecd1',
        label: 'Service',
        className: 'FuneralManagerFuneralPlaceOfService',
        type: 'Service'
    },
    committal: {
        color: '#e67d40',
        backgroundColor: '#f4dfd5',
        label: 'Committal',
        className: 'FuneralManagerFuneralDisposal',
        type: 'Committal'
    },
    disposal: {
        color: '#e67d40',
        backgroundColor: '#f4dfd5',
        label: 'Disposal',
        className: 'FuneralManagerFuneralDisposal',
        type: 'Disposal'
    },
    refreshments: {
        color: '#9840e6',
        backgroundColor: '#d1c0e4',
        label: 'Refreshments',
        className: 'FuneralManagerRefreshmentsVenue',
        type: 'Refreshments'
    },
    reflection: {
        color: '#1f9bc9',
        backgroundColor: '#cde5f0',
        label: 'Reflection Room',
        className: 'FuneralManagerReflectionRoom',
        type: 'ReflectionRoom'
    },
    ashCollection: {
        color: '#919078',
        backgroundColor: '#dddcd5',
        label: 'Ashes Collection',
        className: 'FuneralManagerAppointment',
        type: 'AshCollection'
    },
    unavailableStaff: {
        color: '#e64343',
        backgroundColor: '#f5d4d6',
        label: 'Staff Unavailable',
        className: 'FuneralManagerAppointment',
        type: 'Unavailable'
    },
    unavailableLocation: {
        color: '#e64343',
        backgroundColor: '#f5d4d6',
        label: 'Location Unavailable',
        className: 'FuneralManagerCalendarEvent',
        type: 'VenueUnavailable'
    }
};

export const CALENDAR_TYPES = Object.values(CALENDAR_TYPE);

export function getCalendarCategoryByClassName(className) {
    let t = CALENDAR_TYPES.find(h => h.className === className);
    if (t) return t;
    return CALENDAR_TYPES.find(h => h.type === 'VenueUnavailable');
}

export function getCalendarCategoryByType(type) {
    let t = CALENDAR_TYPES.find(h => h.type === type);
    if (t) return t;
    return CALENDAR_TYPES.find(h => h.type === 'Unavailable');
}

export const APPOINTMENT_OPTIONS = [
    { label: 'General Appointment', value: 'Appointment', type: 'Appointment' },
    { label: 'Pre-Funeral Appointment', value: 'PreFuneral', type: 'Appointment' },
    { label: 'Arrangement Appointment', value: 'Arrangement', type: 'Appointment' },
    { label: 'Staff Unavailable', value: 'Unavailable', type: 'Appointment' },
    { label: 'Location Unavailable', value: 'VenueUnavailable', type: 'VenueUnavailable' },
    { label: 'Service Allocation', value: 'Service', type: 'StaffAllocation' },
    { label: 'Committal Allocation', value: 'Committal', type: 'StaffAllocation' },
    { label: 'Disposal Allocation', value: 'Disposal', type: 'StaffAllocation' },
    { label: 'Viewing Allocation', value: 'Viewing', type: 'StaffAllocation' },
    { label: 'Refreshments Allocation', value: 'Refreshments', type: 'StaffAllocation' }
];

export function getAppointmentOptionType(value) {
    let t = APPOINTMENT_OPTIONS.find(h => h.value === value);
    if (t) return t.type;
    return 'Appointment';
}

export const appointmentTypesByView = ({ type, view }) => {
    return view === 'day'
        ? type !== 'VenueUnavailable'
        : ['Service', 'Disposal', 'Committal', 'Refreshments', 'Viewing', 'VenueUnavailable'].indexOf(type) > -1;
};
