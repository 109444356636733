import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';
import Grid from '../../component/form/Grid';
import gql from 'graphql-tag';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

class Financial extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Financial</span>
                    <span className={classes.header}>Overview</span>
                </Typography>
                <br />
                <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            {this.renderInvoices()}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.renderPayments()}
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12} className="metrics-page">
                            {this.renderCashflow()}
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        );
    }

    renderInvoices() {
        const { classes } = this.props;

        const query = gql`
            query readInvoices($dateBegin: String!, $dateUntil: String!, $isPurchase: Boolean!) {
                readInvoices(
                    limit: 1
                    offset: 0
                    filter: { IsPurchase: { eq: $isPurchase }, InvoiceDueDate: { gte: $dateBegin, lte: $dateUntil } }
                ) {
                    pageInfo {
                        totalCount
                    }
                }
            }
        `;

        const dateBegin = moment()
            .startOf('month')
            .format('YYYY-MM-DD');
        const dateUntil = moment()
            .endOf('month')
            .format('YYYY-MM-DD');
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <p className={classes.countAmount}>
                        Invoices
                        <br />
                        <small>due this month</small>
                        <br />
                        <Query query={query} variables={{ dateBegin, dateUntil, isPurchase: false }}>
                            {results => this.renderThing(results)}
                        </Query>
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <p className={classes.countAmount}>
                        Bills
                        <br />
                        <small>due this month</small>
                        <br />
                        <Query query={query} variables={{ dateBegin, dateUntil, isPurchase: true }}>
                            {results => this.renderThing(results)}
                        </Query>
                    </p>
                </Grid>
            </Grid>
        );
    }

    renderPayments() {
        const { classes } = this.props;

        const query = gql`
            query readPayments($dateBegin: String!, $dateUntil: String!, $isPurchase: Boolean!) {
                readSalesRegisters(
                    limit: 1
                    offset: 0
                    filter: {
                        Invoice: { IsPurchase: { eq: $isPurchase } }
                        TransDate: { gte: $dateBegin, lte: $dateUntil }
                    }
                ) {
                    nodes {
                        ID
                        TransDate
                    }
                    pageInfo {
                        totalCount
                    }
                }
            }
        `;

        const dateBegin = moment()
            .startOf('month')
            .format('YYYY-MM-DD');
        const dateUntil = moment()
            .endOf('month')
            .format('YYYY-MM-DD');
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <p className={classes.countAmount}>
                        Receipts
                        <br />
                        <small>collected this month</small>
                        <br />
                        <Query query={query} variables={{ dateBegin, dateUntil, isPurchase: false }}>
                            {results => this.renderThing(results)}
                        </Query>
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <p className={classes.countAmount}>
                        Payments
                        <br />
                        <small>sent this month</small>
                        <br />
                        <Query query={query} variables={{ dateBegin, dateUntil, isPurchase: true }}>
                            {results => this.renderThing(results)}
                        </Query>
                    </p>
                </Grid>
            </Grid>
        );
    }

    renderThing({ data, loading, error }) {
        const { pageInfo } = Object.values(data)[0] || {};
        if (loading) return 'Loading...';
        if (error) return 'Error!';
        return <span>{pageInfo.totalCount}</span>;
    }

    renderCashflow() {
        const myData = {
            labels: [],
            datasets: [
                {
                    type: 'line',
                    label: 'Invoices',
                    data: [],
                    fill: false,
                    borderColor: 'rgb(68 175 105)'
                },
                {
                    spanGaps: true,
                    type: 'line',
                    label: 'Bills',
                    data: [],
                    fill: false,
                    borderColor: 'rgb(209 79 87)'
                },
                {
                    type: 'bar',
                    label: 'Receipts',
                    data: [],
                    borderColor: 'rgb(68 175 105)',
                    backgroundColor: 'rgba(68, 175, 105, 0.5)'
                },
                {
                    type: 'bar',
                    label: 'Payments',
                    data: [],
                    borderColor: 'rgb(209 79 87)',
                    backgroundColor: 'rgba(209, 79, 87, 0.5)'
                }
            ]
        };
        const options = {
            dataset: { maxBarThickness: 100 },
            scales: {
                xAxes: [{ gridLines: { offsetGridLines: true } }],
                yAxes: [{ ticks: { beginAtZero: true } }]
            }
        };

        const query = gql`
            query Cashflow($metricList: [MetricsRequest]!) {
                loadMetrics(metrics: $metricList) {
                    result
                }
            }
        `;
        return (
            <Fragment>
                <p>Cashflow (last six months)</p>
                <Query query={query} variables={{ metricList: [{ name: 'allCashflow' }] }} fetchPolicy="network-only">
                    {({ data, loading, error }) => {
                        const { loadMetrics } = (!error && data) || null;
                        const arrResult = (loadMetrics && JSON.parse(loadMetrics[0].result)) || [];
                        arrResult.reverse().forEach((element, index) => {
                            myData.labels.push(element.label);
                            const invoices = myData.datasets.find(obj => obj.label === 'Invoices');
                            invoices.data.push(element.invoiceTotal);
                            const bills = myData.datasets.find(obj => obj.label === 'Bills');
                            bills.data.push(element.billTotal);
                            const sent = myData.datasets.find(obj => obj.label === 'Payments');
                            sent.data.push(element.sentTotal);
                            const paid = myData.datasets.find(obj => obj.label === 'Receipts');
                            paid.data.push(element.paidTotal);
                        });

                        return (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Bar
                                    options={{ ...options, maintainAspectRatio: false, tooltips: { enabled: true } }}
                                    height={400}
                                    legend={{
                                        position: 'bottom',
                                        labels: {
                                            fontColor: '#000000',
                                            fontSize: 16
                                        }
                                    }}
                                    data={myData}
                                    redraw={loading}
                                />
                            </div>
                        );
                    }}
                </Query>
            </Fragment>
        );
    }
}

const styles = ({ spacing, breakpoints, palette, funeralHome, typography }) => ({
    paper: {
        padding: `${spacing.unit * 2}px ${spacing.unit * 5}px`,
        [breakpoints.down('md')]: {
            padding: spacing.unit * 2
        }
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    countAmount: {
        textAlign: 'center',
        '& span': { fontSize: '4rem' }
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(Financial), {
    fallback: ErrorFallback
});
