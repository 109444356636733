import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import { AltButton } from '../PrimaryButton';

class ModalConfirmDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policyID: ''
        };
    }

    handleChange = () => {};

    render() {
        const { classes, record, titleField } = this.props;
        let recordTitle;

        if (titleField) {
            recordTitle = record[titleField];
        }
        if (!recordTitle && record.title) {
            recordTitle = record.title;
        }
        if (!recordTitle) {
            recordTitle = record.id;
        }

        return (
            <div className={classes.paper}>
                <Typography variant="subheading" align="center" style={{ marginBottom: 10, marginTop: 10 }}>
                    <div>Are you sure you want to delete this item: {recordTitle}</div>
                </Typography>

                <Grid item xs={6} className={classes.modalButtonContainer}>
                    <AltButton onClick={this.props.onClose} className={classes.button} variant="contained">
                        <Typography variant="button" style={{ color: '#FFFFFF', textDecoration: 'capitalize' }}>
                            Close
                        </Typography>
                    </AltButton>

                    <AltButton
                        onClick={() => {
                            let id = this.props.record;
                            if (this.props.record && this.props.record.ID) {
                                id = this.props.record && this.props.record.ID;
                            } else if (this.props.record && this.props.record.id) {
                                id = this.props.record && this.props.record.id;
                            }
                            this.props.onDelete(id, this.props.refetch);
                        }}
                        className={classes.leftButton}
                        variant="contained"
                    >
                        <SaveIcon fontSize="inherit" />
                        <Typography variant="button" style={{ color: '#FFFFFF', textDecoration: 'capitalize' }}>
                            Confirm
                        </Typography>
                    </AltButton>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        maxWidth: '100%',
        maxHeight: '100%',
        overflowY: 'scroll',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        border: '0 solid #FFFFFF',
        //color: "#FFFFFF",
        padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    formControl: {
        margin: theme.spacing.unit,
        width: '100%'
    },
    textBody: {
        paddingLeft: 10
    },
    statusField: {
        marginBottom: 20
    },

    modalButtonContainer: {
        display: 'inline',
        bottom: 20,
        right: 20
    },

    button: {
        float: 'right',
        marginRight: 10,
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF'
    },

    leftButton: {
        float: 'left',
        marginRight: 10,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF'
    },
    buttonSecondary: {
        float: 'right',
        marginRight: 10,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF'
    }
});

export default withStyles(styles)(ModalConfirmDelete);
