import gql from 'graphql-tag';

export default gql`
    fragment AuthorisedContacts on Funeral {
        AuthorisedContacts {
            ID
            Name
            Phone
            Email
            AddressLine1
            AddressLine2
            Suburb
            Postcode
            Country
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementAuthorisedContacts on Prearrangement {
        AuthorisedContacts {
            ID
            Name
            Phone
            Email
            AddressLine1
            AddressLine2
            Suburb
            Postcode
            Country
        }
    }
`;
