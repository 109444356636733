import {
    Caterers,
    Florists,
    MemorialStationary,
    NewspaperNotices,
    PersonalisedTouches,
    PlaceOfService,
    RefreshmentsVenue
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment OptionalsFields on Funeral {
                ID
                FirstName
                Surname
                MiddleName
                ComplementaryNoticeRequired
                ComplementaryNoticeMessage
                PersonalTouchesComment
                MemorialStationaryBy
                FlowersRequired
                NewspaperNoticeRequired
            }
        `,

        Caterers,
        Florists,
        MemorialStationary,
        NewspaperNotices,
        PersonalisedTouches,
        RefreshmentsVenue,
        PlaceOfService
    ],
    'Funeral'
);
