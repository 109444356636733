import gql from 'graphql-tag';
import { getMyClient } from '../../../apollo';
import NotificationFragment from './NotificationFragment';
import { getUser } from '../../../util/sessions';

//notification query
export const getUserNotificationsQuery = gql`
    ${NotificationFragment}
    query readNotifications($userId: Int!, $limit: Int!, $offset: Int!) {
        readNotifications(userId: $userId, limit: $limit, offset: $offset) {
            edges {
                node {
                    ...Notification
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

//notification query but with tab url segment
export const getUserNotificationsTabSegmentUrlQuery = gql`
    ${NotificationFragment}
    query readQuoteNotifications($filters: [Filters]!) {
        readNotifications(
            #userId: $userId
            #limit: $limit
            filters: $filters
        ) {
            edges {
                node {
                    ...Notification
                }
            }
        }
    }
`;

//notification query but with tab url segment
export const getUserNotificationQuotesQuery = gql`
    query readNotificationQuotes($filters: [Filters]!) {
        readQuotes(filters: $filters) {
            edges {
                node {
                    ID
                    Created
                    Title
                    QuoteType
                    Released
                    Accepted
                    RespondedDate
                    Enquiry {
                        ID
                        LegacyKey
                        GivenName
                        MiddleName
                        Surname
                        DateOfDeathType
                        DateOfDeath1
                        DateOfDeath2
                        EnquirerGivenName
                        EnquirerMiddleName
                        EnquirerSurname
                    }
                }
            }
        }
    }
`;

//update notification members mutation
const updateNotificationMembersMutation = gql`
    mutation UpdateNotificationMembersMutation($input: UpdateNotificationMembersInput!) {
        updateNotificationMembers(input: $input) {
            ID
            Read
        }
    }
`;

//clear member notifications mutation. just uses MemberID
const clearMemberNotificationsQuery = gql`
    mutation DismissNotifications($id: ID!) {
        dismissNotifications(ID: $id) {
            ID
        }
    }
`;

//gets the users notifications from the database
const getUserNotificationsFunc = async (userId, offset = 0, limit = 99) => {
    const asyncQuery = await getMyClient().query({
        fetchPolicy: 'network-only',
        query: getUserNotificationsQuery,
        variables: {
            userId,
            offset,
            limit
        }
    });
    return asyncQuery && asyncQuery.data
        ? asyncQuery.data.readNotifications
        : { edges: [], pageInfo: { hasNextPage: false } };
};

const clearMemberNotificationsFunc = async () => {
    const userId = getUser().ID;
    return await getMyClient().mutate({
        refetchQueries: [
            {
                query: getUserNotificationsQuery,
                variables: { userId, offset: 0, limit: 99, awaitRefetchQueries: true }
            }
        ],
        mutation: clearMemberNotificationsQuery,
        variables: {
            id: userId
        }
    });
};

//saves the notifications to the database
const updateNotificationMembersFunc = async (notification, original) => {
    let input = getMemberChanges(notification, original);

    if (!input) return Promise.resolve(false);
    const userId = getUser().ID;
    return await getMyClient().mutate({
        refetchQueries: [
            {
                query: getUserNotificationsQuery,
                variables: { userId, offset: 0, limit: 99, awaitRefetchQueries: true }
            }
        ],
        mutation: updateNotificationMembersMutation,
        variables: {
            input
        }
    });
};

//gets the first 'diff' in member's who've READ the notification
const getMemberChanges = (updatedNotification, originalNotification) => {
    const orig = getJoins(originalNotification);
    const update = getJoins(updatedNotification);

    let input = null;
    for (let x = 0; x < orig.length && x < update.length && input === null; x++) {
        if (orig[x].Read !== update[x].Read) input = update[x];
    }

    return input;
};

//formats a notificaiton into it's notified members READ joins
const getJoins = notification => {
    return notification.node.AssignedMembers.map(x => {
        return {
            ID: x._join.NotificationMembers.ID,
            Read: x._join.NotificationMembers.Read
        };
    });
};

export { getUserNotificationsFunc, updateNotificationMembersFunc, clearMemberNotificationsFunc };
