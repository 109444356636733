import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Grid from '../../../component/form/Grid';
import DataHighlight from '../../../component/metrics/DataHighlight';
import BarChart from '../../../component/metrics/BarChart';
import Graph from '../../../component/metrics/Graph';
import MetricCard from '../../../component/metrics/MetricCard';
import { getMyClient } from '../../../apollo';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import PieChart from '../../../component/metrics/PieChart';
import { CONVERSION_TYPE_COLOURS } from '../Metrics';
import { prettyNumber } from '../../../util/strings';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type, label } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myArrangements', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myArrangementsConverted', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myEnquiries', variables: JSON.stringify({ ...period, variant: variant }) },
            { name: 'myConversions', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ScoreArrangerPerformanceIndividual($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const heading = variant === 'team' ? "Your Team's" : variant === 'company' ? "The Company's" : 'Your';
    const titles = variant === 'team' ? 'Your team have' : variant === 'company' ? 'The company has' : "You've";

    const { colourPreneed, colourPhone, colourAtneed } = CONVERSION_TYPE_COLOURS;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;

        // arrangements - variables
        const arrMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myArrangements')) || null;
        const arrResult = (arrMetric && JSON.parse(arrMetric.result)) || [];
        const arrangementsPhone = parseInt(arrResult.PhoneCount);
        const arrangementsVisit = parseInt(arrResult.VisitCount);
        const arrangements = arrangementsPhone + arrangementsVisit;

        const arrConMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myArrangementsConverted')) || null;
        const arrConResult = (arrConMetric && JSON.parse(arrConMetric.result)) || [];
        const conversionsPhone = parseInt(arrConResult.PhoneCount);
        const conversionsVisit = parseInt(arrConResult.VisitCount);
        const arrangementsConverted = conversionsPhone + conversionsVisit;

        const arrGoal = (
            ((arrResult && arrResult.Goals && arrResult.Goals.Total) || 0) /
            (type === 'quarter' ? 4 : type === 'month' ? 12 : 1)
        ).toFixed(0);
        const arrRate = Math.min(1, arrangements / arrGoal);
        const arrColor = metricColour(arrRate);

        const arrConGoal = (
            ((arrConResult && arrConResult.Goals && arrConResult.Goals.Conversions) || 0) /
            (type === 'quarter' ? 4 : type === 'month' ? 12 : 1)
        ).toFixed(0);
        const arrConRate = Math.min(1, arrangementsConverted / arrConGoal);
        const arrConColor = metricColour(arrConRate);

        // enquiry type numbers - variables
        const enqMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myEnquiries')) || null;
        const enqResultRaw = (enqMetric && JSON.parse(enqMetric.result)) || [];
        const enqResult = (enqResultRaw && enqResultRaw.Results) || [];
        // const enqCount = (enqResult.length && enqResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;

        const enqPhoneType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PHONE');
        const enqAtneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'ATNEED');
        const enqPreneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PRENEED');
        const enqPhoneCount = (enqPhoneType && enqPhoneType.Count) || 0;
        const enqAtneedCount = (enqAtneedType && enqAtneedType.Count) || 0;
        const enqPreneedCount = (enqPreneedType && enqPreneedType.Count) || 0;
        // const enqPhoneRate = enqPhoneCount / enqCount || 0;
        // const enqAtneedRate = enqAtneedCount / enqCount || 0;
        // const enqPreneedRate = enqPreneedCount / enqCount || 0;

        // conversion rate by enquiry type - variables
        const conMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myConversions')) || null;
        const conResultRaw = (conMetric && JSON.parse(conMetric.result)) || [];
        const conResult = (conResultRaw && conResultRaw.Results) || [];
        // const conCount = (conResult.length && conResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;

        const conPhoneType = conResult.length && conResult.find(e => e.EnquiryType === 'PHONE');
        const conAtneedType = conResult.length && conResult.find(e => e.EnquiryType === 'ATNEED');
        const conPreneedType = conResult.length && conResult.find(e => e.EnquiryType === 'PRENEED');
        const conPhoneCount = (conPhoneType && conPhoneType.Count) || 0;
        const conAtneedCount = (conAtneedType && conAtneedType.Count) || 0;
        const conPreneedCount = (conPreneedType && conPreneedType.Count) || 0;
        const conPhoneRate = (conPhoneCount / enqPhoneCount) * 100 || 0;
        const conAtneedRate = (conAtneedCount / enqAtneedCount) * 100 || 0;
        const conPreneedRate = (conPreneedCount / enqPreneedCount) * 100 || 0;

        return (
            <Fragment>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="highlight-container">
                        <MetricCard variant={arrColor}>
                            <DataHighlight
                                topText={`${titles} conducted`}
                                data={
                                    <Fragment>
                                        {prettyNumber(arrangementsPhone)} phone
                                        <br />
                                        {prettyNumber(arrangementsVisit)} face-to-face
                                    </Fragment>
                                }
                                bottomText={`arrangements for ${label}`}
                                variant={arrColor}
                                loading={reloading}
                            />
                        </MetricCard>
                        <MetricCard variant={arrConColor}>
                            <DataHighlight
                                topText={`${titles} converted`}
                                data={
                                    <Fragment>
                                        {prettyNumber(conversionsPhone)} phone
                                        <br />
                                        {prettyNumber(conversionsVisit)} face-to-face
                                    </Fragment>
                                }
                                bottomText={`arrangements for ${label}`}
                                variant={arrConColor}
                                loading={reloading}
                            />
                        </MetricCard>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="two">
                            <BarChart
                                label="Arrangements Conducted"
                                goal={`Goal: ${arrGoal}`}
                                stats={`${(arrRate * 100).toFixed(0)}%`}
                                statsNumber={`${arrangements}`}
                                variant={arrColor}
                                loading={reloading}
                            />
                            <BarChart
                                label="Arrangement Converted"
                                goal={`Goal: ${arrConGoal}`}
                                stats={`${(arrConRate * 100).toFixed(0)}%`}
                                statsNumber={`${arrangementsConverted}`}
                                variant={arrColor}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="three" title="Enquiries Taken By Type:">
                            {/*<VerticalNumber max={enqCount} />
                            <BarChart
                                label="Phone"
                                stats={`${(enqPhoneRate * 100).toFixed(0)}%`}
                                statsNumber={enqPhoneCount}
                                variant={metricColour(enqPhoneCount / enqCount)}
                                loading={reloading}
                            />

                            <BarChart
                                label="Pre-Need"
                                stats={`${(enqPreneedRate * 100).toFixed(0)}%`}
                                statsNumber={enqPreneedCount}
                                variant={metricColour(enqPreneedCount / enqCount)}
                                loading={reloading}
                            />

                            <BarChart
                                label="At-Need"
                                stats={`${(enqAtneedRate * 100).toFixed(0)}%`}
                                statsNumber={enqAtneedCount}
                                variant={metricColour(enqAtneedCount / enqCount)}
                                loading={reloading}
                            />*/}
                            <PieChart
                                loading={reloading}
                                data={[
                                    { label: 'Phone', value: enqPhoneCount, colour: colourPhone },
                                    { label: 'Pre-Need', value: enqPreneedCount, colour: colourPreneed },
                                    { label: 'At-Need', value: enqAtneedCount, colour: colourAtneed }
                                ]}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="three" title="Enquiry Conversion Rates:">
                            <BarChart
                                label="Phone"
                                stats={`${conPhoneRate}%`}
                                statsNumber={`${conPhoneRate.toFixed(0)}%`}
                                variant={metricColour(conPhoneRate)}
                                // colour={colourPhone}
                                loading={reloading}
                            />
                            <BarChart
                                label="Pre-Need"
                                stats={`${conPreneedRate}%`}
                                statsNumber={`${conPreneedRate.toFixed(0)}%`}
                                variant={metricColour(conPreneedRate)}
                                // colour={colourPreneed}
                                loading={reloading}
                            />
                            <BarChart
                                label="At-Need"
                                stats={`${conAtneedRate}%`}
                                statsNumber={`${conAtneedRate.toFixed(0)}%`}
                                variant={metricColour(conAtneedRate)}
                                // colour={colourAtneed}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
            </Fragment>
        );
    };

    return (
        <div className="AdminPerformance">
            <Grid container spacing={24}>
                <Grid item pc>
                    <h2>{heading} Performance - Arrangements</h2>
                </Grid>
                <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                    {result => renderResult(result)}
                </Query>
            </Grid>
        </div>
    );
});
