import gql from 'graphql-tag';

export default gql`
    fragment Cars on Funeral {
        Cars {
            ID
            Date
            Time
            Duration
            Address
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementCars on Prearrangement {
        Cars {
            ID
            Date
            Time
            Duration
            Address
        }
    }
`;
