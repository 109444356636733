import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withStyles } from '@material-ui/core';
import { AltButton } from '../../../component/form/PrimaryButton';
import ReactToPrint from 'react-to-print';
import Table from '../../../component/form/Table';
import {
    CheckListTable,
    CoffinDetailsTable,
    DeceasedDetailsTable,
    formStyles,
    FuneralDetailsTable,
    MortuaryActionTable
} from './FormFields';
import PrintIcon from '../../../component/icon/PrintIcon';
import { dateTimeToString } from '../../../util/date';

class CheckoutForm extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <ReactToPrint
                    pageStyle={'@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'}
                    trigger={() => (
                        <AltButton>
                            <PrintIcon />
                            Print Checkout Form
                        </AltButton>
                    )}
                    content={() => this.formRef}
                />
                <div className={classes.hidePrint}>{this.renderForm()}</div>
            </Fragment>
        );
    }

    renderForm() {
        const { classes, form, mortuaryListData } = this.props;
        return (
            <div ref={e => (this.formRef = e)} className={classes.root}>
                <Grid container spacing={24} className={classes.print}>
                    <Grid container item xs={12}>
                        <span className={classes.center}>Checkout Form - Printed {dateTimeToString(new Date())}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <DeceasedDetailsTable form={form} />
                        </Table>
                    </Grid>

                    <Grid item xs={12}>
                        <Table>
                            <CoffinDetailsTable form={form} />
                        </Table>
                    </Grid>

                    <Grid item xs={12}>
                        <Table>
                            <FuneralDetailsTable form={form} />
                        </Table>
                    </Grid>

                    <Grid item xs={12}>
                        <Table>
                            <CheckListTable form={form} />
                        </Table>
                    </Grid>

                    <Grid item xs={12}>
                        <Table>
                            <MortuaryActionTable data={mortuaryListData} />
                        </Table>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(formStyles)(CheckoutForm);
