import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import DataTable from '../../component/dataTable/DataTable';
import GatedComponent from '../../component/GatedComponent';
import { withTheme } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import LinkButton from '../../component/form/LinkButton';
import { joinDefined } from '../../util/strings';
import { niceTimeFromString, shortDateFromString } from '../../util/date';
import Checkbox from '../../component/form/Checkbox';
import { PLACE_OF_SERVICE_TYPE_OPTIONS } from '../funeral/details/DetailsConstants';
import { SaveButton } from '../../component/form/PrimaryButton';
import SaveIcon from '../../component/icon/SaveIcon';
import Spinner from '../../component/Spinner';
import { getClient } from '../../apollo';
import TextField from '../../component/form/TextField';
import { InlineField } from '../../component/form/Inline';
import ErrorFallback from '../../component/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class DepositsReport extends Component {
    state = { changeList: [], loading: false };

    componentDidMount() {
        this.unblock = this.props.history.block(this.onHistoryBlock);
    }

    componentWillUnmount() {
        this.unblock();
    }

    onHistoryBlock = () => {
        const { changeList } = this.state;
        return changeList.length ? 'You have unsaved changes, are you sure you want to leave this page?' : null;
    };

    render() {
        const { classes } = this.props;
        const { changeList, loading } = this.state;
        return (
            <div className="deposits-list">
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            Deposits
                            <span className={classes.header}>Pending List</span>
                        </Typography>
                        <br />
                    </Grid>
                </Grid>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.wideScroll}>
                        <GatedComponent showComponentCode={'FM_ACCESS_Funeral_View'}>
                            {() => this.renderTable()}
                        </GatedComponent>
                    </div>

                    <GatedComponent showComponentCode="FM_ACCESS_Funeral_Edit">
                        {() => {
                            return (
                                <div className={classes.footer}>
                                    <SaveButton disabled={changeList.length === 0} onClick={() => this.commitChanges()}>
                                        {!loading ? <SaveIcon /> : <Spinner />}
                                        {!loading ? 'Process Deposits' : 'Processing...'}
                                    </SaveButton>
                                </div>
                            );
                        }}
                    </GatedComponent>
                </Paper>
            </div>
        );
    }

    renderTable() {
        return (
            <Fragment>
                <Grid item>
                    <Grid container spacing={24}>
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <DataTable
                                dataObject="Funeral"
                                queryName="readFunerals"
                                fragment={this.depositListFragment}
                                columns={this.depositListColumns}
                                variables={{
                                    limit: 10,
                                    filterBy: [],
                                    filters: [
                                        { field: 'AdminChecksComplete:LessThan', value: '1' },
                                        { field: 'Created:GreaterThan', value: '2024-07-01' },
                                        { field: 'Cancelled', value: '0' },
                                        { field: 'DepositReceived', value: '0' }
                                    ],
                                    sortBy: [{ field: 'DateOfService', direction: 'DESC' }]
                                }}
                                searchVariable="contains"
                                tableTitle="Funeral records"
                                brandProperty="LegacyKey"
                                isSortable
                                isFilterable={['Brand']}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    handleItemChange = (row, evt, inputType) => {
        const { changeList } = this.state || [];
        let idx = changeList.findIndex(obj => obj.ID === row.ID);
        if (idx < 0) {
            changeList.push({ ID: row.ID, checked: !!row.DepositReceived, value: row.DepositAmount || '' });
            idx = changeList.length - 1;
        }

        const { checked, value } = evt.target;
        if ('tick' === inputType) {
            changeList[idx] = { ID: row.ID, checked, value: changeList[idx].value };
        } else if ('text' === inputType) {
            changeList[idx] = { ID: row.ID, checked: changeList[idx].checked, value };
        }

        const wasChecked = !!row.DepositReceived;
        const wasValue = row.DepositAmount || '';
        if (wasValue === changeList[idx].value && wasChecked === changeList[idx].checked) {
            changeList.splice(idx, 1);
        }

        this.setState(changeList);
    };

    commitChanges = () => {
        const { changeList } = this.state || [];
        this.setState({ loading: true });
        const client = getClient();
        const variables = {
            input: changeList.map(obj => ({
                ID: obj.ID,
                DepositReceived: obj.checked,
                DepositAmount: obj.value
            }))
        };
        const mutation = gql`
            ${this.depositListFragment}
            mutation updateManyFunerals($input: [UpdateFuneralInput]!) {
                updateManyFunerals(input: $input) {
                    edges {
                        node {
                            ...Deposits
                        }
                    }
                }
            }
        `;
        client.mutate({ mutation, variables }).then(() => this.setState({ loading: false, changeList: [] }));
    };

    depositListColumns = [
        {
            label: 'Key',
            propertyPath: 'LegacyKey',
            renderCell: (row, value) => <LinkButton href={`/funeral/${row.LegacyKey}/${row.ID}`}>{value}</LinkButton>
        },
        {
            label: 'Deceased Name',
            propertyPath: 'Surname',
            renderCell: row => (
                <span>{joinDefined([(row.Surname || '').toUpperCase(), row.FirstName, row.MiddleName], ' ')}</span>
            )
        },
        {
            label: 'Deposit Received?',
            propertyPath: 'DepositReceived',
            renderCell: row => {
                const { classes } = this.props;
                const { changeList } = this.state;
                const stateVal = changeList.find(obj => obj.ID === row.ID);
                const checked = stateVal ? stateVal.checked : row.DepositReceived;
                const value = stateVal ? stateVal.value : row.DepositAmount;
                return (
                    <InlineField center>
                        <Checkbox
                            title={'Has it been received?'}
                            className={classes.checkBox}
                            checked={checked}
                            onClick={evt => this.handleItemChange(row, evt, 'tick')}
                        />
                        <TextField
                            title={'What is the amount?'}
                            className={classes.textBox}
                            value={value}
                            onChange={evt => this.handleItemChange(row, evt, 'text')}
                            placeholder="Amount TBC"
                        />
                    </InlineField>
                );
            }
        },
        {
            label: 'Service',
            propertyPath: 'DateOfService',
            renderCell: (row, value) => {
                const service = row.PlaceOfService;
                const committal = row.Disposal;
                const serviceType = PLACE_OF_SERVICE_TYPE_OPTIONS.find(e => e.value === service.Type);
                const useCommittal =
                    !!service && (service.Type === 'Graveside' || service.Type === 'No Service No Attendance');
                const startTime = !useCommittal ? service.Start || value : (committal && committal.Start) || value;
                return (
                    <div>
                        <strong>{serviceType ? serviceType.label : 'Service TBC'}</strong>
                        <br />
                        {startTime ? (
                            <>
                                <span>
                                    {!useCommittal
                                        ? shortDateFromString(service.Start || value)
                                        : committal && shortDateFromString(committal.Start || value)}
                                </span>
                                ,{' '}
                                <span>
                                    {!useCommittal
                                        ? niceTimeFromString(service.Start)
                                        : committal && niceTimeFromString(committal.Start)}
                                </span>
                            </>
                        ) : (
                            'Time TBC'
                        )}
                        <div>
                            <small>
                                {(!useCommittal ? service.LocationFlattened : committal.LocationFlattened) ||
                                    'Location TBC'}
                            </small>
                        </div>
                    </div>
                );
            }
        },
        {
            label: 'Coordinators',
            renderCell: row =>
                row.Coordinators.map(({ Member: obj }) => (
                    <div key={obj.ID}>
                        <small>{joinDefined([obj.FirstName, obj.Surname], ' ')}</small>
                    </div>
                ))
        },
        {
            label: 'Arrangers',
            renderCell: row =>
                row.Arrangers.map(({ Member: obj }) => (
                    <div key={obj.ID}>
                        <small>{joinDefined([obj.FirstName, obj.Surname], ' ')}</small>
                    </div>
                ))
        }
    ];

    depositListFragment = gql`
        fragment Deposits on Funeral {
            ID
            LegacyKey
            FirstName
            MiddleName
            Surname
            DateOfService
            AdminChecksComplete
            DepositAmount
            DepositReceived
            Disposal {
                ID
                Start
                CrematedOrBuried
                LocationFlattened
            }
            PlaceOfService {
                ID
                LocationFlattened
                Start
                Type
            }
            Arrangers {
                ID
                Member {
                    ID
                    Surname
                    FirstName
                    Email
                }
            }
            Coordinators {
                ID
                Member {
                    ID
                    Surname
                    FirstName
                    Email
                }
            }
        }
    `;
}

const styles = ({ spacing, typography, palette, breakpoints, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingTop: 0,
        paddingLeft: 24,
        marginLeft: -12,
        '& td > div + div': {
            marginTop: 8
        }
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    checkBox: {
        '& svg': {
            fontSize: '2.75rem',
            marginLeft: spacing.unit,
            marginTop: 2
        }
    },
    textBox: {
        minWidth: 100,
        marginBottom: 0
    },
    footer: {
        zIndex: 1202,
        position: 'fixed',
        right: 24,
        bottom: 15,
        [breakpoints.down('xs')]: {
            bottom: 15,
            right: 29
        },
        '& button': {
            minHeight: 48,
            [breakpoints.down('xs')]: {
                minWidth: 36,
                padding: '12px 30px',
                borderRadius: 24,
                '& span > span, & span > svg': {
                    margin: 0,
                    height: 25,
                    width: 25,
                    fontSize: 38
                }
            }
        }
    }
});

export default withErrorBoundary(compose(withSnackbarMessage, withTheme(), withStyles(styles))(DepositsReport), {
    fallback: ErrorFallback
});
