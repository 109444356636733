import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { serverUrl } from '../../environment';
import { withStyles } from '@material-ui/core/styles';

/*
* These import are for TinyMCE7, but that requires a licence.
* We're going to use TinyMCE4 from the Silverstripe CMS instead.
*
// TinyMCE so the global var exists
import 'tinymce/tinymce';

// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';
*/

const applyShortcodes = (loadedValue, shortcodes) => {
    if (loadedValue && shortcodes) {
        for (let key in shortcodes) {
            if (shortcodes[key]) {
                loadedValue = loadedValue.split(key).join(shortcodes[key]);
            }
        }
    }
    return loadedValue;
};

const applyMissingTags = loadedValue => {
    if (-1 === loadedValue.search(/^\s*?</)) {
        loadedValue = '<p>' + loadedValue.replace(/(\r?\n){2}/g, '</p><p>') + '</p>';
        loadedValue = loadedValue.replace(/\r?\n/g, '<br/>');
        loadedValue = loadedValue.replace(/<p><\/p>/g, '');
    }
    return loadedValue;
};

function HtmlEditor({ classes, name, form, label, required = false, disabled = false, readOnly = false, shortcodes }) {
    const editorRef = useRef(null);
    if (!(!!form && !!name)) return null;
    const scriptsSrc = [
        `${serverUrl}/resources/silverstripe/admin/thirdparty/tinymce/tinymce.js` /* import TinyMCE4 from Silverstripe */
    ];
    const initialValue = applyMissingTags(form.getOriginal(name) || '');
    const noEdit = !!(disabled || readOnly);
    const loadedValue = applyMissingTags(form.getField(name) || '');
    const value = !noEdit ? applyShortcodes(loadedValue, shortcodes) : loadedValue;
    return (
        <div id={'editor_container_' + name}>
            {label && (
                <span className={classes.labelStyle}>
                    {label}
                    {required && ' *'}
                </span>
            )}
            <Editor
                key={noEdit} /* required: this does a reset on the editor config when noEdit changes */
                tinymceScriptSrc={scriptsSrc}
                licenseKey="gpl"
                id={'editor_' + name}
                initialValue={initialValue}
                onInit={(_evt, editor) => (editorRef.current = editor)}
                onEditorChange={!noEdit ? newValue => form.setField({ [name]: newValue }) : undefined}
                value={value}
                //disabled={noEdit} /* removed: it disables the toolbar & content selection, so not using it! */
                init={{
                    skin: 'silverstripe',
                    toolbar_mode: 'sliding',
                    toolbar_sticky: true,
                    ui_mode: 'split',
                    promotion: false,
                    branding: false,
                    menubar: false,
                    height: 250,
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'contextmenu',
                        'anchor',
                        'searchreplace',
                        'code',
                        'fullscreen',
                        'hr',
                        'media',
                        'table',
                        'code',
                        'help',
                        'print',
                        'preview',
                        'wordcount'
                    ],
                    toolbar: !noEdit
                        ? [
                              'undo redo | bold italic underline removeformat | align | numlist bullist | outdent indent | code',
                              'searchreplace fullscreen | cut copy pastebutton | shortcodes table image link charmap hr | print'
                          ]
                        : 'fullscreen | copy | print',
                    contextmenu: !!noEdit ? 'copy' : 'undo redo | cut copy pastebutton',
                    link_context_toolbar: true,
                    content_style: 'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:1rem }',
                    setup: function(editor) {
                        editor.on('PostRender', function() {
                            /* required: prevent updates to the content area, but allow selections */
                            if (!!noEdit) {
                                editor.getBody().setAttribute('contenteditable', false);
                            }
                        });
                        editor.on('SetContent', function() {
                            /* required: send any click on links to new window */
                            if (!!noEdit) {
                                const links = editor.getBody().getElementsByTagName('a');
                                for (let i = 0; i < links.length; i++) {
                                    links[i].setAttribute('target', '_blank');
                                }
                            }
                        });

                        if (!!noEdit) {
                            return;
                        }

                        editor.addContextToolbar(
                            node => node.nodeName.toLowerCase() === 'img',
                            'alignleft aligncenter alignright image link'
                        );
                        editor.addContextToolbar(
                            node => node.nodeName.toLowerCase() === 'a' && !!node.getAttribute('href'),
                            'link unlink'
                        );
                        editor.addContextToolbar(
                            node => node.nodeName.toLowerCase() === 'a' && !!node.getAttribute('id'),
                            'anchor'
                        );
                        editor.addMenuItem('pastebutton', {
                            text: 'Paste',
                            icon: 'paste',
                            onclick: async () => {
                                try {
                                    const text = await navigator.clipboard.readText();
                                    editor.insertContent(text);
                                } catch (error) {
                                    console.warn('Failed to read clipboard');
                                }
                            }
                        });
                        editor.addButton('pastebutton', {
                            tooltip: 'Paste',
                            icon: 'paste',
                            onclick: async () => {
                                try {
                                    const text = await navigator.clipboard.readText();
                                    editor.insertContent(text);
                                } catch (error) {
                                    console.warn('Failed to read clipboard');
                                }
                            }
                        });
                        if (shortcodes) {
                            const menu = [];
                            for (let key in shortcodes) {
                                menu.push({
                                    icon: !!shortcodes[key] ? 'checkmark' : 'unselected',
                                    text: key,
                                    onclick: () => editor.insertContent(shortcodes[key] || key)
                                });
                            }
                            editor.addButton('shortcodes', {
                                text: 'Data',
                                tooltip: 'Insert data',
                                type: 'menubutton',
                                icon: false,
                                menu
                            });
                        }
                    },
                    images_upload_handler: (blob, success, failure) => {
                        const img = 'data:image/jpeg;base64,' + blob.base64();
                        success(img);
                    }
                }}
            />
        </div>
    );
}

const styles = ({ palette }) => ({
    labelStyle: {
        color: 'white',
        cursor: 'pointer',
        padding: '1px 7px',
        borderRadius: '4px',
        backgroundColor: palette.action.active,
        borderBottomLeftRadius: 0,
        textTransform: 'capitalize',
        fontSize: '0.75rem'
    }
});
export default withStyles(styles)(HtmlEditor);
