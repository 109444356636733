import { getOffices, getSiteConfig } from '../../environment';
import BrandDot from '../table/BrandDot';
import React from 'react';
import Select from './Select';

const SelectOffice = ({
    allowRetired = false,
    name,
    label,
    value,
    form,
    allowNone = true,
    placeholder = 'Select an office...',
    required = false,
    fullWidth = false,
    disabled,
    className,
    onChange
}) => {
    const { CMBrandID } = getSiteConfig();
    const allOffices = getOffices();
    const enquiryOffices = [];
    allOffices.forEach(obj => {
        if (CMBrandID === obj.ID) return;
        if (!allowRetired && !!obj.IsRetired) return;
        const makeOffice = office => ({
            value: office.ID,
            label: office.Title,
            disabled: !!office.IsRetired && !allowRetired,
            canCreate: !office.IsRetired
        });
        if (obj.Branches) {
            const idxBrand = enquiryOffices.findIndex(brand => brand.value === obj.ID);
            if (idxBrand < 0) {
                const office = makeOffice(obj);
                enquiryOffices.push(office);
            }
        } else if (obj.BrandID) {
            const theBrand = allOffices.find(brand => brand.ID === obj.BrandID);
            const idxBrand = enquiryOffices.findIndex(brand => brand.value === obj.BrandID);
            if (idxBrand < 0 && theBrand) {
                const office = makeOffice(theBrand);
                enquiryOffices.push({
                    label: office.label,
                    value: office.value,
                    options: [office, makeOffice(obj)]
                });
            } else {
                enquiryOffices[idxBrand].options
                    ? enquiryOffices[idxBrand].options.push(makeOffice(obj))
                    : (enquiryOffices[idxBrand] = {
                          label: enquiryOffices[idxBrand].label,
                          value: enquiryOffices[idxBrand].value,
                          options: [makeOffice(obj)]
                      });
            }
        }
    });

    const renderValue = val => {
        const { OfficeColour, ForegroundColour, Title } = allOffices.find(obj => obj.ID === val) || {};
        const backgroundColor = OfficeColour || ForegroundColour;
        return val ? <BrandDot dotColour={backgroundColor} label={Title} /> : placeholder;
    };

    return (
        <Select
            label={label}
            name={name}
            value={value}
            required={required}
            allowNone={allowNone}
            fullWidth={fullWidth}
            placeholder={placeholder}
            options={enquiryOffices}
            className={className}
            form={form}
            disabled={disabled}
            renderValue={renderValue}
            onChange={onChange}
        />
    );
};
export default SelectOffice;
