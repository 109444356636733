import gql from 'graphql-tag';

export default gql`
    fragment TransferFromResidentialAddress on Funeral {
        TransferFromResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
        }
    }
`;
