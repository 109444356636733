import React, { useState } from 'react';
import Button from '../../page/workQueue2/extras/Button';
import Filter from '../modal/Filter';
import Checkbox from '../form/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '../form/Grid';
import Inline, { inlineAlignment } from '../../page/workQueue2/extras/Inline';

export default props => {
    const { showFilterPop, onClose, filterByInfo, filterByKeys, setFilters, activeFilters } = props;
    const [filterBy, setFilterBy] = useState(activeFilters.map(obj => ({ name: obj.field, value: obj.value })));
    const renderFilterSection = (key, node) => {
        const label = node.description || node.name;
        const options = node.type && node.type.enumValues;
        if (!Array.isArray(options)) {
            return null;
        }

        return (
            <Grid container spacing={8} key={key}>
                <Grid item xs={12}>
                    <Typography variant="h6">{label}</Typography>
                </Grid>

                {options &&
                    options.map(filter => {
                        const cKey = filter.name || filter;
                        const cValue = filter.description || filter;
                        const isChecked =
                            filterBy && !!filterBy.find(obj => obj.name === node.name && obj.value === cKey);
                        return (
                            <Grid item xs={6} key={cKey}>
                                <Checkbox
                                    label={cValue}
                                    value={cKey}
                                    onChange={handleCheckboxChange(node.name)}
                                    checked={isChecked || false}
                                />
                            </Grid>
                        );
                    })}
                <div style={{ padding: 6 }}></div>
            </Grid>
        );
    };

    const handleCheckboxChange = key => event => {
        const oldFilterBy = filterBy.map(x => x);
        const { value, checked } = event.target;
        const newFilterBy = oldFilterBy ? oldFilterBy.map(x => ({ ...x })) : [];
        const hasIdx = newFilterBy.findIndex(obj => obj.name === key && obj.value === value);

        if (checked) {
            hasIdx < 0 && newFilterBy.push({ name: key, value });
        } else {
            hasIdx > -1 && newFilterBy.splice(hasIdx, 1);
        }
        setFilterBy(newFilterBy);
    };

    return (
        <Filter open={!!showFilterPop} anchorEl={showFilterPop} onClose={onClose} className="data-table--filter-modal">
            <>
                <div className="data-table--filter-content">
                    {filterByInfo &&
                        filterByKeys.map(key =>
                            renderFilterSection(
                                key,
                                filterByInfo.find(obj => obj.name === key)
                            )
                        )}
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <Inline alignment={inlineAlignment.right}>
                            <Button onClick={() => setFilterBy([])} variant="modal-action modal-action--clear">
                                Reset
                            </Button>
                            <Button onClick={() => setFilters(filterBy)} variant="modal-action modal-action--ok">
                                Apply
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </>
        </Filter>
    );
};
