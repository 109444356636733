import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import SearchWithButton from '../extras/SearchWithButton';

function SearchPopout(props) {
    const { classes, open, onClose, className, anchorEl, onSearchSubmit, searchKeyword } = props;

    return (
        <Popover
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={open}
            anchorEl={anchorEl || null}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            classes={{
                paper: classes.paper
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <div className={`popover popover--search ${className || ''} search-field`} style={{ marginTop: 0 }}>
                <SearchWithButton
                    placeholder="Search for a task"
                    onSearchSubmit={onSearchSubmit}
                    searchKeyword={searchKeyword}
                />
            </div>
        </Popover>
    );
}
export default withStyles({})(SearchPopout);
