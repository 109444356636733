export const PLACE_OF_SERVICE_TYPE_OPTIONS = [
    { label: 'Full Service', value: 'Service' },
    { label: 'Memorial Service', value: 'Memorial Service' },
    { label: 'Graveside', value: 'Graveside' },
    { label: 'Mass', value: 'Mass' },
    { label: 'No Service No Attendance', value: 'No Service No Attendance' },
    { label: 'Prayers', value: 'Prayers' },
    { label: 'Sit in Chapel', value: 'Sit in Chapel' },
    { label: 'Thanksgiving Service', value: 'Thanksgiving Service' }
];

export const DISPOSAL_METHOD_OPTIONS = [
    { label: 'Cremation', value: 'Cremated' },
    { label: 'Burial', value: 'Buried' },
    { label: 'Repatriation', value: 'Repatriated' },
    { label: 'Body Donation', value: 'Body Donation' },
    { label: 'Body Not Recovered (no committal)', value: 'Body Not Recovered' }
];

export const REFLECTION_ROOM_REQUIRED_OPTIONS = [
    { label: 'Yes, room is required', value: 'Required' },
    { label: 'No, room is not required', value: 'NotRequired' },
    { label: 'Unsure', value: '' }
];

export const PLACE_OF_VIEWING_TYPE_OPTIONS = [
    { label: 'Viewing', value: 'Viewing' },
    { label: 'Prayers', value: 'Prayers' },
    { label: 'Rosary', value: 'Rosary' },
    { label: 'Family Wish to Dress', value: 'Family Wish to Dress' },
    { label: 'Memorial Service', value: 'Memorial Service' },
    { label: 'Witness of Insertion', value: 'Witness of Insertion' },
    { label: 'Other Appointment', value: 'Appointment' }
];

export const COFFIN_BEARERS_OPTIONS = [
    { label: 'Bearers by Family', value: 'Bearers by Family' },
    { label: 'Bearers by Funeral Director', value: 'Bearers by Funeral Director' },
    { label: 'Bearers by both', value: 'Bearers by both' }
];

export const COFFIN_BEARING_TYPE_OPTIONS = [
    { label: 'Carry In', value: 'CarryIn' },
    { label: 'Carry Out', value: 'CarryOut' },
    { label: 'Carry Graveside', value: 'CarryGraveside' }
];

export const GROUND_TYPE_OPTIONS = [
    { label: 'Crypt', value: 'Crypt' },
    { label: 'New Ground', value: 'New Ground' },
    { label: 'Reserved', value: 'Old res' },
    { label: 'Reopened', value: 'Reopened' },
    { label: 'Tomb', value: 'Tomb' },
    { label: 'Vault', value: 'Vault' },
    { label: 'Niche', value: 'Niche' },
    { label: 'Cremated remains', value: 'Cremated' }
];

export const GRAVE_DEPTH_OPTIONS = [
    { label: 'Crypt', value: 'Crypt' },
    { label: 'Double Depth', value: 'Double Depth' },
    { label: 'Single Depth', value: 'Single Depth' },
    { label: 'Triple Depth', value: 'Triple Depth' }
];

export const PARSONS_GROUP_FUNERAL_HOMES = [
    7, //Parsons Funeral Home Wollongong
    7376, //Parsons Funeral Home Warilla
    7377, //Parsons Funeral Home Bulli
    7378, //Parsons Funeral Home Dapto
    289, //Rankins Funeral Home
    365, //Stan Crapp Funeral Home
    7494, //Wollongong City Funerals - Autumn Chapel
    9372, //kiama
    9599 //wandandian
    // TODO: replace as assets
];

export const DRESSING_DELIVER_TO_FUNERAL_HOME = 'Clothing will be delivered to Funeral Home';
export const DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT = 'Clothing collected at time of Arrangement';
export const DRESSING_ALREADY_DRESSED = 'Already dressed';
export const DRESSING_FAMILY_WISH_TO_DRESS = 'Family wish to dress';

export const DRESSING_STATUS_OPTIONS = [
    {
        label: DRESSING_DELIVER_TO_FUNERAL_HOME,
        value: DRESSING_DELIVER_TO_FUNERAL_HOME
    },
    { label: DRESSING_ALREADY_DRESSED, value: DRESSING_ALREADY_DRESSED },
    { label: DRESSING_FAMILY_WISH_TO_DRESS, value: DRESSING_FAMILY_WISH_TO_DRESS },
    {
        label: DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT,
        value: DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT
    }
];
