import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, sanitizeForRegExp } from '../../util/strings';
import Typography from '@material-ui/core/Typography';
import { getBrandsOnly, getOfficeFromKey } from '../../util/brands';
import cx from 'classnames';
import BrandDot from '../table/BrandDot';

const viewAllFunerals = {
    key: 'VIEW_ALL',
    label: 'View all funerals',
    path: '/search/all'
};

const viewAllPrearrangements = {
    key: 'prearrangements',
    label: 'View all prearrangements',
    path: '/search/prearrangements'
};

const viewMoreResults = {
    key: 'more',
    label: 'View more results...',
    path: '/search/'
};

// PREARRANGEMENTS: {
//     color: '#C2C2C2',
//         label: 'View all Prearrangements',
//         letterCode: 'prearrangements',
//         path: '/search/prearrangements'
// }

class HeaderSearchResults extends Component {
    highlight(str) {
        if (!str) return '';
        // Clean up the input term to work within a regex
        const term = sanitizeForRegExp(this.props.resultsTerm);
        // Split the search term into each word to highlight
        return term.split(' ').reduce((acc, word) => {
            // Wrap all matches for the word with bold tags
            return acc.replace(new RegExp(`(${word})`, 'ig'), '<b>$1</b>');
        }, str);
    }

    formatDateOfBirth(dob) {
        return dob ? dob.split('-').join('/') : '';
    }

    render() {
        const { term, resultsTerm, classes } = this.props;
        return (
            <Paper className={classes.paper} square>
                {term && resultsTerm ? this.renderResults() : this.renderDefaultItems()}
            </Paper>
        );
    }

    renderDefaultItems() {
        const brands = getBrandsOnly();
        return [...brands, viewAllFunerals, viewAllPrearrangements].map(this.renderItem);
    }

    renderResults() {
        const { results, classes, totalCount } = this.props;
        if (!results || !results.length) return <Typography className={classes.noResult}>No Results</Typography>;
        viewMoreResults.label = 'View ' + totalCount + ' matching funerals...';
        const niceResults = results.length > 1 ? [].concat(results, [viewMoreResults]) : results;
        return niceResults.map(this.renderResult);
    }

    renderResult = (result, index) => {
        const { LegacyKey, ID } = result;
        if (!ID) return this.renderItem(result, index);
        const funeralHome = getOfficeFromKey(LegacyKey);
        const option = {
            key: LegacyKey,
            label: this.renderHighlightedLabel(result),
            color: funeralHome ? funeralHome.color : 'grey',
            path: `/funeral/${LegacyKey}/${ID}`
        };
        return this.renderItem(option, index);
    };

    renderHighlightedLabel({ LegacyKey, FirstName, MiddleName, Surname, DateOfBirth }) {
        const { resultsTerm } = this.props;
        let name = joinDefined([(Surname || '').toUpperCase(), FirstName, MiddleName], ' ');
        if (isNaN(parseInt(resultsTerm, 10)) && name) {
            name = this.highlight(name);
        } else if (LegacyKey) {
            LegacyKey = `${this.highlight(String(LegacyKey))}`;
        }
        const dob = this.formatDateOfBirth(DateOfBirth);
        // We use dangerouslySetInnerHTML so we can render the
        // bold tags as html elements instead of plain text string
        return <span dangerouslySetInnerHTML={{ __html: joinDefined([LegacyKey, name, dob], ' - ') }} />;
    }

    renderItem = ({ key, label, color, path }, index) => {
        if (!key) key = label;
        const { downshiftData: data, classes, history, onItemSelected } = this.props;
        const itemProps = data.getItemProps({ item: key });
        const isViewAll =
            key === viewAllFunerals.key || key === viewAllPrearrangements.key || key === viewMoreResults.key;
        const { resultsTerm, term } = this.props || '';
        return (
            <MenuItem
                {...itemProps}
                className={cx(classes.item, isViewAll && classes.viewAll)}
                selected={data.highlightedIndex === index}
                key={key}
                onClick={() => {
                    history.push(path);
                    onItemSelected(key === viewMoreResults.key ? resultsTerm : term);
                }}
            >
                <span className={classes.itemLabel}>
                    {color ? <BrandDot dotColour={color} label={label} /> : label}
                </span>
            </MenuItem>
        );
    };
}

const styles = ({ palette, typography }) => ({
    paper: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
        top: 'calc(100% - 2px)',
        border: `1px solid ${palette.grey.A100}`,
        padding: `8px 0`
    },
    item: {
        padding: '6px 16px',
        display: 'flex'
    },
    itemLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: typography.body1.fontSize
    },
    dot: {
        flex: '0 0 auto',
        borderRadius: 5,
        width: 10,
        height: 10,
        marginRight: 16
    },
    noResult: {
        padding: '8px 16px'
    },
    viewAll: {
        marginBottom: -8,
        paddingLeft: 40
    }
});

export default compose(withRouter, withStyles(styles))(HeaderSearchResults);
