import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

class FormTextField extends Component {
    render() {
        let { classes, value, label = 'Notes History' } = this.props;

        const newVal = value;
        return (
            <div className={classes.readOnlyBucket}>
                <div className={cx(classes.readOnlyLabel, classes.label)}>{label}</div>
                <div className={classes.readOnly}>{newVal ? ('' + newVal).replace(/(\r\n|\r)/g, '\n') : ''}</div>
            </div>
        );
    }
}

const styles = ({ typography, palette }) => ({
    readOnlyBucket: {
        borderBottom: '1px solid ' + palette.action.active + '22',
        width: '100%'
    },
    readOnly: {
        backgroundColor: 'white',
        whiteSpace: 'pre-wrap',
        padding: 8,
        minHeight: 33,
        maxHeight: 333,
        overflowY: 'scroll',
        fontWeight: typography.fontWeightLight,
        lineHeight: '1.5'
    },
    label: {
        zIndex: 1,
        cursor: 'text',
        pointerEvents: 'none',
        top: 2,
        left: 8,
        color: palette.text.hint
    },
    readOnlyLabel: {
        backgroundColor: palette.action.active,
        color: palette.common.white,
        borderRadius: 4,
        padding: '2px 7px 0',
        fontSize: '12px',
        borderBottomLeftRadius: 0,
        display: 'inline-block'
    }
});

export default withStyles(styles)(FormTextField);
