import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const TooltipWrapper = ({ children, title, classes, placement = 'top', ...props }) => {
    const [arrowRef, setArrowRef] = React.useState(null);
    const handleArrowRef = node => {
        setArrowRef(node);
    };

    return (
        <Tooltip
            className="tooltip"
            title={
                <>
                    {title}
                    <span className={classes.arrow} ref={handleArrowRef} />
                </>
            }
            placement={placement}
            {...props}
            classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
            PopperProps={{
                popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef } } }
            }}
        >
            {children}
        </Tooltip>
    );
};

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`
            }
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`
            }
        }
    };
}

const styles = ({ palette }) => ({
    arrowPopper: arrowGenerator(palette.grey[700]),
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    tooltip: {
        marginBottom: 6,
        fontSize: '0.875rem'
    }
});
export default withStyles(styles)(TooltipWrapper);
