import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DELIVERY_OPTIONS, FLAG_TYPES, LIFESTORY_OPTIONS, MUSIC_OPTIONS, MUSIC_TYPES } from './presentationConstants';
import ColumnLayout from '../../../component/ColumnLayout';
import Grid from '../../../component/form/Grid';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import RadioGroup from '../../../component/form/RadioGroup';
import Checkbox from '../../../component/form/Checkbox';
import Label from '../../../component/form/Label';
import Select from '../../../component/form/Select';
import TextField from '../../../component/form/TextField';
import OptionalTextField from '../../../component/form/OptionalTextField';
import { Icon, IconButton } from '@material-ui/core';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import AddressBookAutocomplete from '../../../component/form/AddressBookAutocomplete';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import EditContactModal from '../../../component/EditContactModal';
import MusicAutoComplete from '../../../component/form/MusicAutoComplete';
import { stringIsNullOrEmpty } from '../../../util/strings';
import SupplierRequest from '../../../component/bookings/SupplierRequest';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SongDragger from '../../../component/SongDragger';

class Presentation extends Component {
    state = {
        loading: true,
        lifestoryType: undefined,
        lifestoryNoP: true, // deprecated option, allows for legacy
        hasDonations: undefined,
        RslMasonicRequired: false,
        EnterMusicDefaultMusician: undefined,
        GravesideMusicDefaultMusician: undefined,
        ReflectionMusicDefaultMusician: undefined,
        ExitMusicDefaultMusician: undefined
    };

    setLifestory = (obj, event, type) => {
        const { form } = this.props;
        const newState = { Lifestory: form.getField('Lifestory') || {} };
        newState.Lifestory[obj] = event.target[type] || (type === 'checked' ? false : null);
        form.setField(newState);
    };

    reorderSongs = ({ draggableId, source, destination }) => {
        const { form } = this.props;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        const sourceName = source.droppableId === 'EnterMusic' ? 'EnterMusicSong' : source.droppableId + 'Songs';
        const destinationName =
            destination.droppableId === 'EnterMusic' ? 'EnterMusicSong' : destination.droppableId + 'Songs';

        const sourceSongs = (form.getField(sourceName) || []).map((e, i) => {
            e.draggableId = source.droppableId + i;
            return e;
        });
        const destinationSongs = (form.getField(destinationName) || []).map((e, i) => {
            e.draggableId = source.droppableId + i;
            return e;
        });

        const item = sourceSongs.find(e => e.draggableId === draggableId);

        sourceSongs.splice(source.index, 1);

        if (source.droppableId === destination.droppableId) {
            sourceSongs.splice(destination.index, 0, item);
        } else {
            if (item.OriginalList === destinationName) delete item.OriginalList;
            else if (!item.OriginalList) item.OriginalList = sourceName;
            destinationSongs.splice(destination.index, 0, item);
        }
        form.setField({
            [sourceName]: sourceSongs.map((e, i) => {
                delete e.draggableId;
                e.SongSort = i;
                return e;
            })
        });
        if (source.droppableId !== destination.droppableId) {
            form.setField({
                [destinationName]: destinationSongs.map((e, i) => {
                    delete e.draggableId;
                    e.SongSort = i;
                    return e;
                })
            });
        }
    };

    updateMusicList = (result, field) => {
        if (!result) return null;

        const { form } = this.props;
        const oldList = form.getField(field);
        const newList = { [field]: [].concat(oldList) };

        if (!newList[field]) newList[field] = [];
        newList[field].unshift({
            SongSort: (oldList && oldList.length) || 0,
            Name: result.SongName,
            Artist: result.Artist,
            Pathname: result.TrackLocation
            //SongID: result.ID - there is no SongID Property
        });
        form.setField(newList);
    };

    static getDerivedStateFromProps({ form }, state) {
        //reset the start
        if (form.loading && !state.loading) {
            return { loading: true };
        }

        //load defaults from form
        if (state.loading && !form.loading) {
            let newState = {
                loading: false
            };

            // there is no db value for RSL/Masonic, so pre-tick if RslMasonicID exists
            const masonic = form.getField('RslMasonic') || [];
            const hasMasonic = masonic.filter(e => !!e && !e.Cancelled).length > 0;
            if (
                !!hasMasonic ||
                !!form.getField('LastpostRequired') ||
                !!form.getField('ReveilleRequired') ||
                !!form.getField('PoppiesRequired')
            ) {
                newState.RslMasonicRequired = true;
            }
            // lifestory is composite data...
            if (!!form.getField('Lifestory') && state.lifestoryType === undefined) {
                if (form.getField('Lifestory').Presentation) {
                    newState.lifestoryType =
                        form.getField('Lifestory').PresentationByWho === 'family'
                            ? form.getField('Lifestory').PresentationFamilyToPrepareWhat === 'Scanned Photos'
                                ? 'P'
                                : 'V'
                            : 'C';
                }
                if ('P' === newState.lifestoryType) {
                    newState.lifestoryNoP = false;
                }
            }
            // there is no db value for donations, so pre-tick if DonationsName/Donations2Name exists
            if (
                (!!form.getField('DonationsName') || !!form.getField('Donations2Name')) &&
                state.hasDonations === undefined
            ) {
                newState.hasDonations = true;
            }

            // there is no db value for DefaultMusician
            newState.hasDefaultMusician = !!(
                form.getField('DefaultMusicians') &&
                form.getField('DefaultMusicians').filter(e => !!e && !e.Cancelled).length > 0
            );
            newState.EnterMusicDefaultMusician =
                !!newState.hasDefaultMusician &&
                !(
                    form.getField('EnterMusicians') &&
                    form.getField('EnterMusicians').filter(e => !!e && !e.Cancelled).length > 0
                );
            newState.GravesideMusicDefaultMusician =
                !!newState.hasDefaultMusician &&
                !(
                    form.getField('GravesideMusicians') &&
                    form.getField('GravesideMusicians').filter(e => !!e && !e.Cancelled).length > 0
                );
            newState.ReflectionMusicDefaultMusician =
                !!newState.hasDefaultMusician &&
                !(
                    form.getField('ReflectionMusicians') &&
                    form.getField('ReflectionMusicians').filter(e => !!e && !e.Cancelled).length > 0
                );
            newState.ExitMusicDefaultMusician =
                !!newState.hasDefaultMusician &&
                !(
                    form.getField('ExitMusicians') &&
                    form.getField('ExitMusicians').filter(e => !!e && !e.Cancelled).length > 0
                );

            return newState;
        }

        return null;
    }

    render() {
        const { form } = this.props;

        const { addressBookModalField, addressBookModalCategory } = this.state;

        return (
            <Fragment>
                <EditContactModal
                    onClose={this.onCloseAddressBookModal}
                    open={!!addressBookModalField}
                    name={addressBookModalField}
                    category={addressBookModalCategory}
                    form={form}
                />
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const { classes, form, locked } = this.props;

        return (
            <Grid container spacing={24}>
                {this.renderLifestory()}

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <InlineHeader header="Name sheets">
                            <Checkbox
                                name="NamesheetsRequired"
                                label="Required"
                                form={form}
                                onChange={() => {
                                    const newState = { NamesheetsRequired: !!form.getField('NamesheetsRequired') };
                                    if (!newState.NamesheetsRequired) {
                                        newState.NamesheetsNotRequired = false;
                                    }
                                    form.setField(newState);
                                }}
                                disabled={form.getField('NamesheetsNotRequired') || locked}
                            />
                            <Checkbox
                                name="NamesheetsNotRequired"
                                label="Not required"
                                form={form}
                                onChange={() => {
                                    const newState = {
                                        NamesheetsNotRequired: !!form.getField('NamesheetsNotRequired')
                                    };
                                    if (newState.NamesheetsNotRequired) {
                                        newState.NamesheetsRequired = false;
                                    }
                                    form.setField(newState);
                                }}
                                disabled={form.getField('NamesheetsRequired') || locked}
                            />
                        </InlineHeader>
                    </Grid>
                    {form.getField('NamesheetsRequired') && (
                        <Fragment>
                            <Grid pc={1}>
                                <TextField
                                    disabled={locked}
                                    name="NamesheetsDetails"
                                    label="Name sheet details"
                                    form={form}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>

                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Mass book" className={classes.inlineInputs} />
                        <Checkbox disabled={locked} name="MassbookRequired" label="Mass book required" form={form} />
                    </Grid>
                    {form.getField('MassbookRequired') && (
                        <Fragment>
                            <Grid pc={1}>
                                <TextField
                                    disabled={locked}
                                    name="MassbookDetails"
                                    label="Mass book details"
                                    form={form}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>

                {this.renderDonations()}

                {this.renderMasonic()}
            </Grid>
        );
    }

    renderMasonic() {
        const { classes, form, locked } = this.props;
        const { RslMasonicRequired } = this.state;
        return (
            <Fragment>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="RSL/Masonic" className={classes.inlineInputs} />
                        <Checkbox
                            name="RslMasonicRequired"
                            label="Yes"
                            checked={!!RslMasonicRequired}
                            onChange={e => {
                                if (
                                    !!form.getField('PoppiesRequired') ||
                                    !!form.getField('LastpostRequired') ||
                                    !!form.getField('ReveilleRequired')
                                )
                                    return;
                                const newState = {
                                    FlagRequired: form.getField('FlagRequired')
                                };
                                if (e.target.checked) {
                                    newState.FlagRequired = true;
                                }
                                form.setField(newState);
                                this.setState({ RslMasonicRequired: !!e.target.checked });
                            }}
                            disabled={locked}
                        />
                    </Grid>
                    {!!RslMasonicRequired && (
                        <Fragment>
                            <SupplierRequest
                                form={form}
                                name={'RslMasonic'}
                                categories={[ADDRESS_BOOK_CATEGORY.supportServices]}
                                labelSupplier={'RSL/Masonic Representative'}
                                label={'RSL/Masonic Services'}
                                allowPurchaseOrder={true}
                                disabled={locked}
                            />

                            <Grid pc={1}>
                                <Label text="Additional RSL/Masonic details" htmlFor="RslMasonicOptions" />
                                <br />
                                <Checkbox
                                    disabled={locked}
                                    name="LastpostRequired"
                                    label="Last Post is to be played"
                                    form={form}
                                />
                                <br />
                                <Checkbox
                                    disabled={locked}
                                    name="ReveilleRequired"
                                    label="Reveille is to be played"
                                    form={form}
                                />
                                <br />
                                <Checkbox
                                    disabled={locked}
                                    name="PoppiesRequired"
                                    label="Poppies are required"
                                    form={form}
                                />
                            </Grid>
                            <Grid pc={1}>
                                <OptionalTextField
                                    buttonlabel={'+ Add Additional RSL/Masonic Comments'}
                                    name="RslMasonicComment"
                                    label="RSL/Masonic Comments"
                                    multiline
                                    form={form}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text={'Flag'} className={classes.inlineInputs} />
                        <Checkbox disabled={locked} name="FlagRequired" label="Flag is required" form={form} />
                    </Grid>
                    {form.getField('FlagRequired') && (
                        <Fragment>
                            <Grid pc={0.5}>
                                <Select
                                    disabled={locked}
                                    label="Type of flag"
                                    name={'FlagType'}
                                    options={FLAG_TYPES}
                                    form={form}
                                />
                            </Grid>
                            {form.getField('FlagType') && form.getField('FlagType') === 'Other' && (
                                <Grid pc={0.5}>
                                    <TextField
                                        disabled={locked}
                                        name={'FlagDetails'}
                                        label={'Other flag details'}
                                        form={form}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderLifestory() {
        const { classes, form, locked } = this.props;
        const { lifestoryNoP } = this.state;
        const filesReceived = form.getField('Lifestory.PresentationFamilyToPreparePowerpointReceived');
        return (
            <Grid bucket={true}>
                <Grid pc={1}>
                    <Label text="Life Story Presentation" htmlFor="choose-lifestoryType" />
                </Grid>
                <Grid pc={1}>
                    <Select
                        disabled={locked}
                        label={'Life story presentation'}
                        placeholder={'There will not be a life story presentation.'}
                        name="lifestoryType"
                        options={LIFESTORY_OPTIONS.filter(opt => (lifestoryNoP ? 'P' !== opt.value : true))}
                        onChange={e => {
                            const newState = { Lifestory: form.getField('Lifestory') || {} };
                            const selected = e.target.value;
                            if (selected && selected.toString() !== 'N') {
                                newState.Lifestory.Presentation = true;
                                if (e.target.value === 'C') {
                                    newState.Lifestory.PresentationByWho = 'director';
                                    newState.Lifestory.PresentationFamilyToPrepareWhat = null;
                                } else {
                                    newState.Lifestory.PresentationByWho = 'family';
                                    newState.Lifestory.PresentationFamilyToPrepareWhat =
                                        selected === 'P' ? 'Scanned Photos' : 'Complete video including music';
                                }
                            } else {
                                newState.Lifestory.Presentation = false;
                                newState.Lifestory.PresentationByWho = null;
                                newState.Lifestory.PresentationFamilyToPrepareWhat = null;
                            }
                            form.setField(newState);
                            this.setState({ lifestoryType: e.target.value });
                        }}
                        value={this.state.lifestoryType}
                    />
                </Grid>

                {this.state.lifestoryType === 'V' && (
                    <Fragment>
                        <Grid pc={1}>
                            <Checkbox
                                disabled={locked}
                                name="Lifestory.PresentationFamilyToPreparePowerpointReceived"
                                label="Files received"
                                checked={form && form.getField('Lifestory') ? filesReceived : false}
                                onChange={e =>
                                    this.setLifestory('PresentationFamilyToPreparePowerpointReceived', e, 'checked')
                                }
                            />
                        </Grid>
                        {form.getField('Lifestory') && !filesReceived ? (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Date to receive video"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverDate"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverDate"
                                        type="date"
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverDate',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Estimated time of receival"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverTime"
                                    />
                                    <TextField
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverTime"
                                        type="time"
                                        label=""
                                        form={form}
                                        disabled={locked}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverTime',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Will be received via"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                    />
                                    <Select
                                        disabled={locked}
                                        options={DELIVERY_OPTIONS}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHowSend',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                            </Fragment>
                        ) : (
                            <Grid pc={0.5}>
                                <Label
                                    text="Was received via"
                                    htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                />
                                <Select
                                    disabled={locked}
                                    options={DELIVERY_OPTIONS}
                                    name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                    form={form}
                                    onChange={e =>
                                        this.setLifestory('PresentationFamilyToPreparePowerpointHowSend', e, 'value')
                                    }
                                />
                            </Grid>
                        )}
                    </Fragment>
                )}

                {this.state.lifestoryType === 'P' && (
                    <Fragment>
                        <Grid pc={1}>
                            <Checkbox
                                disabled={locked}
                                name="Lifestory.PresentationFamilyToPreparePowerpointReceived"
                                label="Files received"
                                checked={form && form.getField('Lifestory') ? filesReceived : false}
                                onChange={e =>
                                    this.setLifestory('PresentationFamilyToPreparePowerpointReceived', e, 'checked')
                                }
                            />
                        </Grid>
                        {!filesReceived ? (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Date to receive files"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverDate"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverDate"
                                        type="date"
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverDate',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Estimated time of receival"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverTime"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverTime"
                                        type="time"
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverTime',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid pc={0.5}>
                                    <Label
                                        text="How many files to be received?"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverQty',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                {Number(form.getField('Lifestory.PresentationFamilyToPreparePowerpointHandoverQty')) >
                                    0 && (
                                    <Grid pc={0.5}>
                                        <Label
                                            text="Will be received via"
                                            htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                        />
                                        <Select
                                            disabled={locked}
                                            name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                            options={DELIVERY_OPTIONS}
                                            form={form}
                                            onChange={e =>
                                                this.setLifestory(
                                                    'PresentationFamilyToPreparePowerpointHowSend',
                                                    e,
                                                    'value'
                                                )
                                            }
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <Label
                                        text="How many files were received?"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverQty',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Was received via"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                    />
                                    <Select
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                        options={DELIVERY_OPTIONS}
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHowSend',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {this.state.lifestoryType === 'C' && (
                    <Fragment>
                        <Grid pc={1}>
                            <Checkbox
                                disabled={locked}
                                name="Lifestory.PresentationFamilyToPreparePowerpointReceived"
                                label="Files received"
                                checked={form && form.getField('Lifestory') ? filesReceived : false}
                                onChange={e =>
                                    this.setLifestory('PresentationFamilyToPreparePowerpointReceived', e, 'checked')
                                }
                            />
                        </Grid>
                        {!filesReceived && (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Date to receive files & photos"
                                        htmlFor="Lifestory.PhotoScanningRequiredDate"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PhotoScanningRequiredDate"
                                        type="date"
                                        form={form}
                                        onChange={e => this.setLifestory('PhotoScanningRequiredDate', e, 'value')}
                                    />
                                </Grid>
                                <Grid pc={0.5}>
                                    <Label
                                        text="Estimated time of receival"
                                        htmlFor="Lifestory.PhotoScanningRequiredTime"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PhotoScanningRequiredTime"
                                        type="time"
                                        form={form}
                                        onChange={e => this.setLifestory('PhotoScanningRequiredTime', e, 'value')}
                                    />
                                </Grid>

                                <Grid pc={0.5}>
                                    <Label
                                        text="How many files to be received?"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverQty',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>
                                {form.fields &&
                                    form.getField('Lifestory').PresentationFamilyToPreparePowerpointHandoverQty > 0 && (
                                        <Grid pc={0.5}>
                                            <Label
                                                text="Files will be received via"
                                                htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                            />
                                            <Select
                                                disabled={locked}
                                                name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                                options={DELIVERY_OPTIONS}
                                                onChange={e =>
                                                    this.setLifestory(
                                                        'PresentationFamilyToPreparePowerpointHowSend',
                                                        e,
                                                        'value'
                                                    )
                                                }
                                                form={form}
                                            />
                                        </Grid>
                                    )}
                                <Grid pc={0.5}>
                                    <Label
                                        text="How many photos need scanning?"
                                        htmlFor="Lifestory.PresentationAdditionalPhotosQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationAdditionalPhotosQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e => this.setLifestory('PresentationAdditionalPhotosQty', e, 'value')}
                                    />
                                </Grid>
                                {form.fields && form.getField('Lifestory').PresentationAdditionalPhotosQty > 0 && (
                                    <Grid pc={0.5}>
                                        <Label
                                            text="Photos will be received via"
                                            htmlFor="Lifestory.PresentationAdditionalPhotosHowSend"
                                        />
                                        <Select
                                            disabled={locked}
                                            name="Lifestory.PresentationAdditionalPhotosHowSend"
                                            options={DELIVERY_OPTIONS}
                                            onChange={e =>
                                                this.setLifestory('PresentationAdditionalPhotosHowSend', e, 'value')
                                            }
                                            form={form}
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        )}
                        {form.fields && filesReceived && (
                            <Fragment>
                                <Grid pc={0.5}>
                                    <Label
                                        text="How many files were received?"
                                        htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationFamilyToPreparePowerpointHandoverQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e =>
                                            this.setLifestory(
                                                'PresentationFamilyToPreparePowerpointHandoverQty',
                                                e,
                                                'value'
                                            )
                                        }
                                    />
                                </Grid>

                                {form.getField('Lifestory.PresentationFamilyToPreparePowerpointHandoverQty') > 0 && (
                                    <Grid pc={0.5}>
                                        <Label
                                            text="Files were received via"
                                            htmlFor="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                        />
                                        <Select
                                            disabled={locked}
                                            name="Lifestory.PresentationFamilyToPreparePowerpointHowSend"
                                            options={DELIVERY_OPTIONS}
                                            onChange={e =>
                                                this.setLifestory(
                                                    'PresentationFamilyToPreparePowerpointHowSend',
                                                    e,
                                                    'value'
                                                )
                                            }
                                            form={form}
                                        />
                                    </Grid>
                                )}

                                <Grid pc={0.5}>
                                    <Label
                                        text="How many photos needed scanning?"
                                        htmlFor="Lifestory.PresentationAdditionalPhotosQty"
                                    />
                                    <TextField
                                        disabled={locked}
                                        name="Lifestory.PresentationAdditionalPhotosQty"
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e => this.setLifestory('PresentationAdditionalPhotosQty', e, 'value')}
                                    />
                                </Grid>
                                {form.getField('Lifestory.PresentationAdditionalPhotosQty') > 0 && (
                                    <Grid pc={0.5}>
                                        <Label
                                            text="Photos were received via"
                                            htmlFor="Lifestory.PresentationAdditionalPhotosHowSend"
                                        />
                                        <Select
                                            disabled={locked}
                                            name="Lifestory.PresentationAdditionalPhotosHowSend"
                                            options={DELIVERY_OPTIONS}
                                            onChange={e =>
                                                this.setLifestory('PresentationAdditionalPhotosHowSend', e, 'value')
                                            }
                                            form={form}
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {!form.getField('Lifestory.Presentation') && (
                    <Grid pc={1}>
                        <Label text="Photo for Foyer Display" className={classes.inlineInputs} />
                        <Checkbox
                            disabled={locked}
                            name="Lifestory.FoyerPhotoRequired"
                            label="Photo Required"
                            form={form}
                        />
                    </Grid>
                )}

                <Grid pc={1}>
                    <OptionalTextField
                        buttonlabel={'+ Add comment'}
                        label="Comments"
                        name="Lifestory.PresentationFamilyToPreparePowerpointNotes"
                        multiline
                        value={
                            (form.getField('Lifestory')
                                ? form.getField('Lifestory').PresentationFamilyToPreparePowerpointNotes
                                : '') || null
                        }
                        onChange={e => this.setLifestory('PresentationFamilyToPreparePowerpointNotes', e, 'value')}
                        form={form}
                    />
                </Grid>
            </Grid>
        );
    }

    renderSecondColumn() {
        const { classes, form, locked } = this.props;
        return (
            <Grid container spacing={24}>
                <DragDropContext onDragEnd={this.reorderSongs}>
                    <Grid bucket={true}>
                        <Grid pc={1}>
                            <Label text="Would you like to set a default musician?" className={classes.inlineInputs} />
                            <Checkbox
                                disabled={locked}
                                name="HasDefaultMusician"
                                label="Yes"
                                checked={!!this.state.hasDefaultMusician}
                                onChange={e => {
                                    const newState = {};
                                    const newForm = {};
                                    if (!e.target.checked) {
                                        MUSIC_TYPES.forEach((v, i, a) => {
                                            let def = a[i].type + 'DefaultMusician';
                                            newState[def] = null;
                                        });
                                    }
                                    form.setField(newForm);
                                    this.setState({ hasDefaultMusician: !!e.target.checked, ...newState });
                                }}
                            />
                        </Grid>

                        {this.state.hasDefaultMusician && (
                            <SupplierRequest
                                disabled={locked}
                                form={form}
                                name={'DefaultMusicians'}
                                categories={[ADDRESS_BOOK_CATEGORY.supportServices]}
                                labelSupplier={'Default Musician'}
                                label={'Musician'}
                                allowPurchaseOrder={true}
                            />
                        )}
                    </Grid>
                    {MUSIC_TYPES.map((o, i) => {
                        const musicProvider = form.getField(o.type + 'ByWho') || '';

                        return (
                            <Grid bucket={true} key={i}>
                                <Grid pc={1}>
                                    <RadioGroup
                                        disabled={locked}
                                        label={o.label}
                                        options={MUSIC_OPTIONS}
                                        value={musicProvider}
                                        onChange={e => form.setField({ [o.type + 'ByWho']: e.target.value })}
                                    />
                                </Grid>
                                {musicProvider !== '' && (
                                    <Fragment>
                                        {musicProvider === 'family' && (
                                            <Fragment>
                                                <Grid pc={1}>
                                                    <Checkbox
                                                        disabled={locked}
                                                        name={o.type + 'ByFamilyReceived'}
                                                        label={'Received'}
                                                        form={form}
                                                    />
                                                </Grid>
                                                {!form.getField(o.type + 'ByFamilyReceived') && (
                                                    <Fragment>
                                                        <Grid pc={0.5}>
                                                            <Label
                                                                text={'Date to receive music'}
                                                                htmlFor={o.type + 'ByFamilyHandoverDate'}
                                                            />
                                                            <TextField
                                                                disabled={locked}
                                                                name={o.type + 'ByFamilyHandoverDate'}
                                                                type="date"
                                                                form={form}
                                                            />
                                                        </Grid>
                                                        <Grid pc={0.5}>
                                                            <Label
                                                                text={'Time to receive music'}
                                                                htmlFor={o.type + 'ByFamilyHandoverTime'}
                                                            />
                                                            <TextField
                                                                disabled={locked}
                                                                name={o.type + 'ByFamilyHandoverTime'}
                                                                type="time"
                                                                form={form}
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}

                                        {musicProvider === 'musician' && (
                                            <Fragment>
                                                <Grid pc={1}>
                                                    <Checkbox
                                                        disabled={form ? !this.state.hasDefaultMusician : locked}
                                                        name={o.type + 'DefaultMusician'}
                                                        label={o.label + ' music performed by default musician'}
                                                        checked={
                                                            !!this.state[o.type + 'DefaultMusician'] &&
                                                            !!this.state.hasDefaultMusician
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                [o.type + 'DefaultMusician']: e.target.checked
                                                            })
                                                        }
                                                    />
                                                </Grid>

                                                {form && this.state[o.type + 'DefaultMusician'] !== true && (
                                                    <SupplierRequest
                                                        form={form}
                                                        name={o.type + 'ians'}
                                                        categories={[ADDRESS_BOOK_CATEGORY.supportServices]}
                                                        labelSupplier={'Musician for ' + o.label}
                                                        label={'Musician'}
                                                        allowPurchaseOrder={true}
                                                        disabled={locked}
                                                    />
                                                )}
                                            </Fragment>
                                        )}

                                        <Grid pc={1}>
                                            <Label text={`Select ${o.label} songs`} />
                                            <InlineField>
                                                <MusicAutoComplete
                                                    disabled={locked}
                                                    placeholder="Select from music library..."
                                                    clearOnSelect={true}
                                                    onSelect={(e, result) => {
                                                        this.updateMusicList(result, o.list);
                                                    }}
                                                    onAddNewSong={e => this.updateMusicList(e, o.list)}
                                                />
                                            </InlineField>
                                        </Grid>

                                        <Grid pc={1}>
                                            <Label text={'Selected ' + o.label + ' Songs'} />

                                            <Droppable droppableId={o.type}>
                                                {provided => (
                                                    <SongDragger
                                                        disabled={locked}
                                                        listType={o}
                                                        form={form}
                                                        provided={provided}
                                                    />
                                                )}
                                            </Droppable>
                                        </Grid>
                                        <Grid pc={1}>
                                            <OptionalTextField
                                                buttonlabel={'+ Add ' + o.label + ' Notes'}
                                                label={o.label + ' Notes'}
                                                name={o.type + 'Notes'}
                                                form={form}
                                                placeholder="Enter any notes..."
                                            />
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        );
                    })}
                </DragDropContext>
            </Grid>
        );
    }

    renderDonations() {
        const { classes, locked, form } = this.props;
        const hasDonations = !!form.getField('DonationsName') || !!form.getField('Donations2Name');
        return (
            <Grid bucket={true}>
                <Grid pc={1}>
                    <Label text="Donations" className={classes.inlineInputs} />
                    <Checkbox
                        disabled={locked || hasDonations}
                        name="hasDonations"
                        label="Yes"
                        checked={!!this.state.hasDonations}
                        onChange={e => {
                            this.setState({ hasDonations: !!e.target.checked });
                        }}
                    />
                </Grid>
                {this.state.hasDonations && (
                    <Fragment>
                        {this.renderDonation('1. ', 'DonationsName', 'DonationsCode')}
                        {this.renderDonation('2. ', 'Donations2Name', 'Donations2Code')}
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderDonation(donationLabel, donationNameProperty, donationCodeProperty) {
        const { classes, form, locked } = this.props;
        const validationResult = 'DonationsName' === donationNameProperty ? { valid: false } : null;
        const donationName = form.getField(donationNameProperty);

        return (
            <div className={classes.listItem}>
                {!stringIsNullOrEmpty(donationName) ? (
                    <Fragment>
                        <span style={{ float: 'right' }}>
                            <IconButton
                                disabled={locked}
                                title={'Remove'}
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            'The donation will be discarded when you save.\nAre you sure you want to remove it?'
                                        )
                                    )
                                        return;

                                    form.setField({
                                        [donationCodeProperty]: null,
                                        [donationNameProperty]: null
                                    });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                        <span>{donationLabel}</span>
                        <IconButton disabled={locked}>
                            <Icon>check_circle_outline</Icon>
                        </IconButton>
                        {form.getField(donationNameProperty)}
                    </Fragment>
                ) : (
                    <InlineField>
                        <AddressBookAutocomplete
                            validationResult={validationResult}
                            disabled={locked}
                            canSearchByKey={true}
                            categories={[ADDRESS_BOOK_CATEGORY.charity]}
                            name={donationCodeProperty}
                            label={`${donationLabel} Search by charity name`}
                            form={form}
                            style={{ marginBottom: '1rem' }}
                            onSelect={(_, result) =>
                                form.setField({
                                    [donationNameProperty]: result.Name,
                                    [donationCodeProperty]: result.LegacyKey
                                })
                            }
                            onAddNewContact={result =>
                                form.setField({
                                    [donationNameProperty]: result.Name,
                                    [donationCodeProperty]: result.LegacyKey
                                })
                            }
                        />
                    </InlineField>
                )}
            </div>
        );
    }
}

const styles = ({ spacing, palette, funeralHome }) => ({
    column: {
        '& > *': {
            marginBottom: -spacing.unit * 1.5
        }
    },
    bucket: {
        borderRadius: 10,
        margin: 0,
        marginBottom: 4,
        paddingBottom: 12,
        border: '1px solid transparent',
        // backgroundColor: palette.contentBackground[funeralHome],
        '&:hover, .active': {
            // backgroundColor: 'white',
            backgroundColor: palette.contentBackground[funeralHome]
            // border: '1px dotted ' + palette.contentForeground[funeralHome],
            // border: '1px solid ' + palette.contentForeground[funeralHome],
        }
    },
    inlineInputs: {
        marginRight: '1rem'
    },
    listItem: {
        width: '100%',
        padding: '0 6px',
        margin: '6px 6px 0',
        borderRadius: 5,
        // backgroundColor: 'rgba(0,0,0,0.035)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        },
        ' > span': {
            display: 'inline-block',
            fontSize: '0.875rem',
            verticalAlign: 'middle'
        },
        '& > p': {
            margin: '0.65rem 0'
        }
    },
    dragMe: {
        cursor: 'move'
    }
});

export default withStyles(styles)(Presentation);
