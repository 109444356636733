import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '../icon/DeleteIcon';
import cx from 'classnames';
import Tooltip from './Tooltip';

const styles = ({ breakpoints, palette, funeralHome }) => ({
    imageList: {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridAutoRows: '150px',
        border: '1px solid transparent',
        width: '100%',
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)'
        },
        [breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)'
        }
    },

    imageListItem: {
        backgroundColor: '#f9f9f9',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #AAA',
        position: 'relative',
        overflow: 'hidden',
        [breakpoints.down('xs')]: {
            gridColumn: 'span 1 !important'
        }
    },
    itemLabel: {
        background: 'rgba(255,255,255,0.75)',
        color: '#000',
        padding: 5,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderWidth: '0 0 0 1px',
        fontSize: '90%'
    },

    itemImage: {
        position: 'relative',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    actionTopLeft: {
        top: 3,
        left: 3,
        bottom: 3,
        right: 3,
        position: 'absolute'
    },
    imgActions: {
        display: 'flex',
        justifyItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'nowrap'
    },
    buttonWrapper: {
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        border: '0.5px solid #AAA'
    },
    isActive: {
        backgroundColor: palette.basePrimary[funeralHome],
        borderColor: palette.basePrimary[funeralHome],
        '& svg': {
            color: 'white'
        }
    },
    iconButton: {
        opacity: 0.5,
        height: '25px',
        width: '25px',
        '& > span > svg': {
            fontSize: '16px'
        },
        '&:hover': {
            opacity: 1
        }
    },
    trashIcon: {
        color: 'red'
    },
    flowerItemQty: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: `#35327C`,
        color: 'white',
        fontSize: '1rem',
        borderRadius: '0px 0px 0px 5px',
        padding: '1px 5px'
    },
    noQuantity: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: `#35327C66`,
        color: 'white',
        textAlign: 'center'
    }
});

const ImageList = withStyles(styles)(({ value, classes, children, ...props }) => {
    return (
        <div className={classes.imageList} {...props}>
            {children}
        </div>
    );
});

const ImageListItem = withStyles(styles)(
    ({ key, value, classes, onClick, onDelete, customActions, title, quantity = 1, image, ...props }) => {
        const style = {
            gridColumn: 'span ' + (isNaN(value.tileWidth) ? '1' : value.tileWidth),
            gridRow: 'span ' + (value.tileHeight || '1'),
            cursor: onClick ? 'pointer' : 'default',
            backgroundImage: ' url(' + (!!image ? image : value.image) + ')',
            backgroundSize: value.tileWidth || value.tileHeight ? 'contain' : 'cover'
        };

        if (quantity < 1) return null;

        return (
            <span
                key={key}
                className={classes.imageListItem}
                style={style}
                onClick={onClick}
                tabIndex={onClick ? 0 : null}
                onKeyUp={evt => {
                    if (!onClick) return undefined;
                    evt.stopPropagation();
                    if (evt.key === 'Enter') onClick();
                }}
            >
                {quantity > 1 && <span className={classes.flowerItemQty}>x{quantity}</span>}
                {(title || value.title) && <span className={classes.itemLabel}>{title || value.title}</span>}
                {(onDelete || customActions) && (
                    <div className={classes.actionTopLeft}>
                        <div className={classes.imgActions}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                {customActions &&
                                    customActions.map((action, idx) => <ActionButton key={idx} action={action} />)}
                            </div>
                            {onDelete && (
                                <ActionButton
                                    className={classes.trashIcon}
                                    action={{
                                        title: 'Remove',
                                        onClick: onDelete,
                                        icon: <DeleteIcon />
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
            </span>
        );
    }
);

const ActionButton = withStyles(styles)(({ action, classes, className }) => (
    <div className={cx(classes.buttonWrapper, action.isActive && classes.isActive)}>
        <Tooltip title={action.title}>
            <IconButton
                className={cx(classes.iconButton, className)}
                onClick={e => {
                    e.stopPropagation();
                    if (action.onClick) action.onClick();
                }}
            >
                {action.icon}
            </IconButton>
        </Tooltip>
    </div>
));

export { ImageList, ImageListItem, ActionButton };
