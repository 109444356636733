import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({})(({ value, form, name }) => {
    const notesValue = (!!form && !!name && form.getField(name)) || value || '';

    return (
        <div className="notes-container">
            <div className="notes-readonly">
                <pre>{notesValue}</pre>
            </div>
        </div>
    );
});
