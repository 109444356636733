import React, { Component } from 'react';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import TableData from '../../../../component/form/TableData';
import { VALUABLES_LOCATION_OPTIONS } from './ValuablesConstants';
import Select from '../../../../component/form/Select';
import Checkbox from '../../../../component/form/Checkbox';
import EditIcon from '../../../../component/icon/EditIcon';
import TextField from '../../../../component/form/TextField';
import DeleteIcon from '../../../../component/icon/DeleteIcon';

class ValuablesModalListTab extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.modalHeader}>
                    <Typography variant="title" className={classes.title}>
                        All Documented Valuables:
                        {/*<PrimaryButton style={{ float: 'right' }} onClick={e => this.props.onEditRecord('0')}>*/}
                        {/*    + Add Valuable*/}
                        {/*</PrimaryButton>*/}
                    </Typography>
                </div>
                <div style={{ clear: 'both' }} />
                {this.renderTemplateList()}
            </div>
        );
    }

    renderTemplateList() {
        const { classes, form, disabled } = this.props;
        const data = form.getField('valuableItems') || {};
        const tableData = Object.values(data);

        return (
            <div className={classes.dataList}>
                <TableData
                    columns={[
                        { id: 'Description', label: 'Description' },
                        { id: 'Quantity', label: 'Qty' },
                        {
                            id: 'ToBePlaced',
                            label: 'Where Placed'
                            // render: (val, row) => {
                            //     return (
                            //         <Select
                            //             name={'valuableItems.id' + row.ID + '.ToBePlaced'} //form.data.valuableItems is processed as Object in order to work with form.getField().
                            //             form={form}
                            //             options={VALUABLES_TO_BE_PLACE_OPTIONS}
                            //         />
                            //     );
                            // },
                            // styles: { minWidth: '200px' }
                        },
                        {
                            id: 'ToBeReturned',
                            label: 'To Be Returned',
                            render: (val, row) => {
                                // return <Checkbox name={'valuableItems.id' + row.ID + '.ToBeReturned'} form={form} />;
                                return !!val
                                    ? !!row.WhereToBeReturned
                                        ? 'Return to ' + row.WhereToBeReturned
                                        : 'Yes'
                                    : 'No';
                            }
                        },
                        {
                            id: 'CurrentLocation',
                            label: 'Current Location',
                            render: (val, row) => {
                                return (
                                    <div>
                                        <Select
                                            name={'valuableItems.id' + row.ID + '.CurrentLocation'}
                                            form={form}
                                            options={VALUABLES_LOCATION_OPTIONS}
                                            disabled={disabled}
                                        />
                                        <br />
                                        <TextField
                                            name={'valuableItems.id' + row.ID + '.CurrentLocationDetail'}
                                            form={form}
                                            placeholder="Where?"
                                            disabled={disabled}
                                        />
                                    </div>
                                );
                            },
                            styles: { minWidth: '200px' }
                        },
                        {
                            id: 'Signed',
                            label: 'Form Signed',
                            render: (val, row) => {
                                const isDisabled =
                                    !row.CurrentLocation || row.CurrentLocation === 'Awaiting Delivery' || disabled;
                                return (
                                    <Checkbox
                                        name={'valuableItems.id' + row.ID + '.Signed'}
                                        title={`Item is ${val ? '' : 'not '}signed on Valuables Form`}
                                        form={form}
                                        disabled={isDisabled}
                                    />
                                );
                            },
                            styles: { width: 1 }
                        },
                        {
                            id: 'EditButton',
                            label: '',
                            render: (val, row) => {
                                return (
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        <IconButton
                                            className={classes.iconButton}
                                            disabled={row.Signed || disabled}
                                            title={'Edit Valuable'}
                                            onClick={() => {
                                                this.props.onEditRecord(row.ID);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            className={classes.iconButton}
                                            disabled={row.Signed || disabled}
                                            title={'Delete Valuable'}
                                            onClick={() => {
                                                this.props.onDeleteRecord(row.ID);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                );
                            }
                        }
                    ]}
                    data={tableData}
                />
            </div>
        );
    }
}

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        width: 800,
        maxWidth: '95vw',
        overflow: 'auto',
        padding: 42,
        marginBottom: '1rem',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            padding: 12
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: '20px'
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    }
});

export default withStyles(styles)(ValuablesModalListTab);
