import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core';

const ListItemCM = props => {
    const { selected } = props;
    return <ListItem className={`task-bucket-list-item${selected ? ' selected' : ''}`} {...props} />;
};

export default withStyles({})(ListItemCM);
