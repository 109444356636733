import cloneDeep from 'lodash.clonedeep';
import { COMPONENT } from '../../../component/form/AddressAutocomplete';

export const DATE_OF_DEATH_OPTION = {
    APPROX: { label: 'Approximately', value: 'Approx.' },
    ON: { label: 'On', value: 'On' },
    ABOUT: { label: 'On or about', value: 'On or about' },
    AFTER: { label: 'On or after', value: 'Sometime On or After' },
    BETWEEN: { label: 'Between', value: 'Between' },
    UNKNOWN: { label: 'Unknown', value: 'Unknown' }
};

export const DATE_OF_DEATH_OPTIONS = Object.values(DATE_OF_DEATH_OPTION);

export const GENDER_OPTIONS = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Intersex', value: 'Intersex' },
    { label: 'Unknown', value: 'Unknown' }
];

export const RETIRED_OPTIONS = [
    { label: 'Unknown', value: 'UN' },
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' }
];

export const PENSIONER_OPTIONS = cloneDeep(RETIRED_OPTIONS);

export const PENSION_TYPE_OPTIONS = [
    { label: 'Aged', value: 'Aged' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Invalid', value: 'Invalid' },
    { label: 'Veteran', value: 'Veteran' },
    { label: 'Unknown', value: 'Unknown' }
];

export const ABORIGINAL_OPTIONS = cloneDeep(RETIRED_OPTIONS).concat([{ label: 'Not Stated', value: 'NS' }]);

export const TORRES_OPTIONS = cloneDeep(ABORIGINAL_OPTIONS);

export const RESIDENTIAL_ADDRESS_COMPONENTS = {
    [COMPONENT.line1]: 'ResidentialAddress1',
    [COMPONENT.line2]: 'ResidentialAddress2',
    [COMPONENT.city]: 'ResidentialTown',
    [COMPONENT.state]: 'ResidentialState',
    [COMPONENT.country]: 'ResidentialCountry',
    [COMPONENT.code]: 'PostCode'
};

export const PLACE_OF_DEATH_TYPE = {
    AddressBook: 'AddressBook',
    Residential: 'Residential'
};

export const PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS = {
    [COMPONENT.line1]: 'PlaceOfDeathResidentialAddress.AddressLine1',
    [COMPONENT.line2]: 'PlaceOfDeathResidentialAddress.AddressLine2',
    [COMPONENT.city]: 'PlaceOfDeathResidentialAddress.Suburb',
    [COMPONENT.state]: 'PlaceOfDeathResidentialAddress.State',
    [COMPONENT.code]: 'PlaceOfDeathResidentialAddress.Postcode',
    [COMPONENT.country]: 'PlaceOfDeathResidentialAddress.Country'
};

export const PLACE_OF_BIRTH_COMPONENTS = {
    [COMPONENT.city]: 'PlaceOfBirth1',
    [COMPONENT.state]: 'PlaceOfBirthState',
    [COMPONENT.country]: 'PlaceOfBirthCountry'
};

export const TRANSFER_FROM_RESIDENTIAL_COMPONENTS = {
    [COMPONENT.line1]: 'TransferFromResidentialAddress.AddressLine1',
    [COMPONENT.line2]: 'TransferFromResidentialAddress.AddressLine2',
    [COMPONENT.city]: 'TransferFromResidentialAddress.Suburb',
    [COMPONENT.state]: 'TransferFromResidentialAddress.State',
    [COMPONENT.code]: 'TransferFromResidentialAddress.Postcode',
    [COMPONENT.country]: 'TransferFromResidentialAddress.Country'
};

export const PLACE_OF_BIRTH_AUSTRALIA = '0';
export const PLACE_OF_BIRTH_OVERSEAS = '1';
export const PLACE_OF_BIRTH_UNKNOWN = '2';

export const PLACE_OF_BIRTH_OPTIONS = [
    { label: 'Unknown', value: PLACE_OF_BIRTH_UNKNOWN },
    { label: 'Australia', value: PLACE_OF_BIRTH_AUSTRALIA },
    { label: 'Overseas', value: PLACE_OF_BIRTH_OVERSEAS }
];

export const AGE_OPTIONS = [
    { label: 'Stillborn', value: 'Stillborn' },
    { label: 'Seconds', value: 'Seconds' },
    { label: 'Minutes', value: 'Minutes' },
    { label: 'Hours', value: 'Hours' },
    { label: 'Days', value: 'Days' },
    { label: 'Weeks', value: 'Weeks' },
    { label: 'Months', value: 'Months' },
    { label: 'Years', value: 'Years' },
    { label: 'Not Stated', value: 'Not Stated' },
    { label: 'Unknown', value: 'Unknown' }
];
