import { GST } from '../../../../util/products';
import { round } from '../../../../util/objects';

export const INVOICE_STATUS_OPTIONS = [
    { label: 'Normal', value: 'Normal' },
    { label: 'Debt Collector', value: 'DEBT COLLECTOR' },
    { label: 'Installments', value: 'INSTALLMENTS' },
    { label: 'Solicitor', value: 'SOLICITOR' }
];

export const calculateTotals = invoice => {
    let total = 0,
        gst = 0,
        totalWithGst = 0;

    for (let x = 0; x < invoice.InvoiceItems.length; x++) {
        const item = invoice.InvoiceItems[x];
        if (item.Quantity !== 0 && !item.Prepaid) {
            const product = item.Product || { GST: 0 };
            const variant = item.Variation || { GST: 0 };
            const itemTotal = item.Price * item.Quantity;

            total += itemTotal;

            if (product.GST || variant.GST) {
                const itemTotalWithGst = round(itemTotal * (GST + 1), 2);
                totalWithGst += itemTotalWithGst;

                const itemGst = itemTotalWithGst / ((GST + 1) * 10);
                gst += itemGst;
            } else {
                totalWithGst += itemTotal;
            }
        }
    }

    let amountDue = 0;
    let amountPaid = totalWithGst;
    if (!invoice.InvoicePaid) {
        amountDue = totalWithGst;
        amountPaid = 0;
        for (let x = 0; x < invoice.SalesRegisterTransactions.length; x++) {
            const transaction = invoice.SalesRegisterTransactions[x];
            if (transaction.TransType !== 'PAY') continue;

            const increment = parseFloat(transaction.Nett) + parseFloat(transaction.Gst); // non-Xero records had GST
            if (isNaN(increment)) continue;

            amountDue -= increment;
            amountPaid += increment;
        }
    }
    return { total, gst, totalWithGst, amountDue, amountPaid };
};
