import gql from 'graphql-tag';

export default gql`
    fragment AdminCheck on AdminCheck {
        ID
        Title
        TabURLSegment
        Active
    }
`;
