import {
    DefaultMusicians,
    EnterMusicians,
    EnterMusicSong,
    ExitMusicians,
    ExitMusicSongs,
    GravesideMusicians,
    GravesideMusicSongs,
    Lifestory,
    ReflectionMusicians,
    ReflectionMusicSongs,
    RslMasonic
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment PresentationFields on Funeral {
                NamesheetsRequired
                NamesheetsNotRequired
                NamesheetsDetails
                MassbookRequired
                MassbookDetails

                FlagRequired
                FlagType
                FlagDetails

                RslMasonicComment
                LastpostRequired
                ReveilleRequired
                PoppiesRequired

                DonationsCode
                DonationsName
                Donations2Code
                Donations2Name

                EnterMusicByWho
                EnterMusicByFamilyHandoverDate
                EnterMusicByFamilyHandoverTime
                EnterMusicByFamilyReceived
                EnterMusicNotes

                ReflectionMusicByWho
                ReflectionMusicByFamilyHandoverDate
                ReflectionMusicByFamilyHandoverTime
                ReflectionMusicByFamilyReceived
                ReflectionMusicNotes

                GravesideMusicByWho
                GravesideMusicByFamilyHandoverDate
                GravesideMusicByFamilyHandoverTime
                GravesideMusicByFamilyReceived
                GravesideMusicNotes

                ExitMusicByWho
                ExitMusicByFamilyHandoverDate
                ExitMusicByFamilyHandoverTime
                ExitMusicByFamilyReceived
                ExitMusicNotes
            }
        `,
        DefaultMusicians,
        EnterMusicians,
        EnterMusicSong,
        ExitMusicSongs,
        ExitMusicians,
        GravesideMusicians,
        GravesideMusicSongs,
        Lifestory,
        ReflectionMusicians,
        ReflectionMusicSongs,
        RslMasonic
    ],
    'Funeral'
);
