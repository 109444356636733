import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { joinDefined } from '../../util/strings';

const FuneralAutoComplete = ({ category, className, name, ...props }) => {
    return (
        <GqlAutocomplete
            className={className}
            readOneQuery={readOneFuneralQuery}
            readAllQuery={readAllFuneralsQuery}
            labelFieldFunc={e => e.LegacyKey + ' - ' + (e.NameOfDeceased || joinDefined([e.FirstName, e.Surname], ' '))}
            name={name}
            {...props}
            canSearchByKey={true}
        />
    );
};

const funFrag = gql`
    fragment funFrag on Funeral {
        ID
        LegacyKey
        FirstName
        MiddleName
        Surname
        Informant {
            ID
            InformantsName
            FirstName
            Surname
            Phone
            Mobile
        }
    }
`;

const readAllFuneralsQuery = gql`
    ${funFrag}
    query Search($key: String, $contains: String, $limit: Int, $offset: Int) {
        searchFunerals(key: $key, contains: $contains, limit: $limit, offset: $offset) {
            key
            contains
            edges {
                node {
                    ...funFrag
                }
            }
        }
    }
`;

const readOneFuneralQuery = gql`
    ${funFrag}
    query Search($id: ID!) {
        readOneFuneral(ID: $id) {
            ...funFrag
        }
    }
`;

export default FuneralAutoComplete;
