import gql from 'graphql-tag';

export default gql`
    fragment TaskFragment1 on Task {
        ID
        Created
        LastEdited
        Title
        Description
        RequiredAction
        TabURLSegment
        LinkURL
        Category
        TriggerField
        InProgress
        Due
        Completed
        Archived
        Comment

        Master {
            ID
        }

        EnquiryFM {
            ID
        }

        Funeral {
            ID
            LegacyKey
            NameOfDeceased
            Confirmed
            DateOfService
            AdminChecksComplete
            AdminChecks {
                ID
            }
        }

        ChecklistItems {
            ID
            Created
            Title
            Completed
            CompletedBy {
                ID
                Email
                Surname
                FirstName
            }
        }

        Audits {
            edges {
                node {
                    ID
                    Created
                    Type
                    Comment
                    AddedBy {
                        ID
                        Email
                        Surname
                        FirstName
                    }
                }
            }
        }

        AssignedMembers {
            ID
            Email
            Surname
            FirstName
            _join {
                TaskMembers {
                    ID
                    Starred
                }
            }
        }

        AssignedTeams {
            ID
            Title
        }

        Attachments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
