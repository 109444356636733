import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from './TextField';

class Alert extends React.Component {
    state = {
        inputValue: null
    };

    render() {
        const { open, onCancel, onAccept, title, message, acceptInput = false, placeholder = null } = this.props;
        const { inputValue } = this.state;

        return (
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                    {acceptInput && (
                        <TextField
                            multiline
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={e => this.setState({ inputValue: e.target.value })}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    {onAccept ? (
                        <Fragment>
                            <Button onClick={onCancel} style={{ color: '#E64040' }}>
                                Cancel
                            </Button>
                            <Button onClick={() => onAccept(inputValue)} style={{ color: '#26CC6F' }} autoFocus>
                                Ok
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Button onClick={onCancel} color="primary" autoFocus>
                                Close
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles({
    viewRoot: {
        padding: '40px',
        width: 600,
        maxWidth: '100%'
    }
})(Alert);

/*
export default withStyles({})(({ open, onCancel, onAccept, title, message }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onAccept ? (
                    <Fragment>
                        <Button onClick={onCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={onAccept} color="primary" autoFocus>
                            Ok
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button onClick={onCancel} color="primary" autoFocus>
                            Close
                        </Button>
                    </Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
});
*/
