import React, { Component, Fragment } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PrimaryButton, { SaveButton } from './PrimaryButton';
import ImageIcon from '../icon/ImageIcon';
import CloseIcon from '../icon/CloseIcon';
import PenIcon from '../icon/PenIcon';
import { Button, CircularProgress, Hidden, Typography, withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import SaveIcon from '../icon/SaveIcon';
import { Mutation, Query } from 'react-apollo';
import { getAssetsClient } from '../../apollo';
import UploadIcon from '../icon/UploadIcon';
import Inline from '../../page/workQueue2/extras/Inline';
import { stringIsNullOrEmpty } from '../../util/strings';
import Label from './Label';
import { getMaxUploadSize } from '../../environment';

/*
* Signature Field component. Main use: signature sections on ArrangementForms
************************************************************************************************************************************
* Props:
*   Parameters:
*       - form              <REQUIRED> - parent form
*       - name              <REQUIRED> - name of field in form. Should be of type Image or Image.ID
*       - fieldIsID         <optional> - should be set to true if image ID provided instead of full image record
*       - folderPath        <optional> - path on server where file would be saved. If not provided "/documents/form/<formID>" will be used
*       - placeholderID     <optional> - id for placeholder image to be used if form field is empty or component is not connected to form yet
*       - placeholderImage  <optional> - same as placeholdeID but image url
*       - label             <optional> - label. By default: Signature
*       NOTE: if required parameters are not provided control will generate error in browser console and switch to
*             visualOnly mode, in that mode most of the logic is disabled. It made so control can be used during design cut without
*             causing react error screen.
*   Callbacks:
*       - onChange()                                            - called when image drawen on canvas is changed or new file
*                                                                 selected in upload field. Also triggered by changing mode
*                                                                 from Draw to Image and back. Provides no data about change
*                                                                 except of actual fact of change happening
************************************************************************************************************************************
* Useful stuff accessible through referencing that component
*   Properties:
*       - Boolean isChanged     - true when component holds unsaved changes
        - Boolean isBeingSaved  - true when component triggered GQL mutation and waiting result
*/

export const SignatureFieldMode = {
    Canvas: 0,
    Image: 1
    //Text:2,
};

class SignatureField extends Component {
    state = {
        viewMode: SignatureFieldMode.Canvas,
        visualOnly: false,
        imageData: {}
    };

    isChanged = false;
    isBeingSaved = false;

    constructor(props) {
        super(props);
        const { form, name, fieldIsID } = this.props;
        this.canvasRef = React.createRef();
        if (form === undefined || name === undefined) {
            console.error('SignatureField missing one of required parameters. Component switched to visuallOnly mode');
            this.state.visualOnly = true;
        } else {
            const field = form.getField(name);
            if (typeof field === 'object' && fieldIsID === true) {
                console.error(
                    'SignatureField provided with field name that mapped to object but fieldIsID set to true. If fieldIsID set to true, field name must target ID field keeping ID of Image object. Component switched to visuallOnly mode'
                );
                this.state.visualOnly = true;
            } else if (typeof field !== 'object' && field !== undefined && !fieldIsID) {
                console.error(
                    'SignatureField fieldIsID set to false, but field that name parameter refers to is not a object. If fieldIsID set to false, field name must target Image object. Component switched to visuallOnly mode'
                );
                this.state.visualOnly = true;
            }
        }
        if (!this.state.visualOnly) {
            this.onLoad();
        }
    }

    componentDidUpdate() {
        this.onLoad();
    }

    onLoad() {
        const { form, name, fieldIsID, placeholderID, placeholderImage } = this.props;
        const data = form.getField(name);
        let imageData = false;

        if (fieldIsID && data) {
            imageData = { id: data };
        } else if (data && data.ID) {
            imageData = { id: data.ID, url: data.AbsoluteLink };
        } else if (placeholderID) {
            imageData = { id: placeholderID };
        } else if (placeholderImage) {
            imageData = { url: placeholderImage };
        }

        if (imageData) {
            this.state.imageData = imageData;
            this.state.viewMode = SignatureFieldMode.Image;
        }
    }

    render() {
        const { label } = this.props;
        const { viewMode } = this.state;
        return (
            <Fragment>
                <div className="signature-container">
                    <div className="signature-buttons">
                        <Label>{label || 'Signature'}</Label>
                        <div className="toggle-container">
                            <PrimaryButton
                                className={`toggle-button${viewMode === SignatureFieldMode.Canvas ? ' active' : ''}`}
                                onClick={() => {
                                    this.setState({ viewMode: SignatureFieldMode.Canvas });
                                }}
                            >
                                <PenIcon />
                                <Hidden smDown>Draw</Hidden>
                            </PrimaryButton>
                            <PrimaryButton
                                className={`toggle-button${viewMode === SignatureFieldMode.Image ? ' active' : ''}`}
                                onClick={() => {
                                    this.setState({ viewMode: SignatureFieldMode.Image });
                                }}
                            >
                                <ImageIcon />
                                <Hidden smDown>Image</Hidden>
                            </PrimaryButton>
                            {/*<PrimaryButton className="toggle-button">
                            <KeyboardIcon />
                            Text
                            </PrimaryButton>*/}
                        </div>
                    </div>
                    {this.renderMutationWrapper()}
                </div>
            </Fragment>
        );
    }

    renderField = (mutate, data) => {
        switch (this.state.viewMode) {
            case SignatureFieldMode.Canvas:
                return this.renderCanvas(mutate, data);
            case SignatureFieldMode.Image:
            default:
                return this.renderImage(mutate, data);
        }
    };

    renderMutationWrapper() {
        return (
            <Mutation
                mutation={uploadSignatureQuery}
                fetchPolicy="no-cache"
                onCompleted={this.onFileUploaded}
                client={getAssetsClient()}
            >
                {this.renderField}
            </Mutation>
        );
    }

    renderCanvas(mutate, data) {
        const { loading } = data;
        return (
            <Fragment>
                <SignatureCanvas
                    ref={this.canvasRef}
                    canvasProps={{ className: 'draw-signature' }}
                    id="signature-pad"
                />
                <div className="clear">
                    <PrimaryButton
                        className="clear-btn"
                        onClick={() => {
                            this.canvasRef.current.clear();
                        }}
                    >
                        <CloseIcon />
                        Clear
                    </PrimaryButton>
                    &nbsp;
                    <SaveButton disabled={loading} onClick={() => this.onCanvasChange(mutate)}>
                        <SaveIcon />
                        {loading ? 'Saving...' : 'Save'}
                    </SaveButton>
                </div>
            </Fragment>
        );
    }

    renderImage(mutate, data) {
        const { imageData } = this.state;
        return (
            <Fragment>
                <div className="draw-signature image">
                    {imageData.url ? (
                        this.renderImgTag(imageData.url)
                    ) : imageData.id ? (
                        <Query query={ImageQuery} variables={{ id: imageData.id }}>
                            {this.renderQuariedImage}
                        </Query>
                    ) : (
                        ''
                    )}
                    {stringIsNullOrEmpty(imageData.url) && stringIsNullOrEmpty(imageData.id)
                        ? this.renderUploadField(mutate, data)
                        : ''}
                </div>
                {!stringIsNullOrEmpty(imageData.url) || !stringIsNullOrEmpty(imageData.id) ? (
                    <div className="clear">{this.renderUploadField(mutate, data)}</div>
                ) : (
                    ''
                )}
            </Fragment>
        );
    }

    renderQuariedImage = ({ data, loading, error }) => {
        if (loading) return 'Loading image';
        if (error || !data.readOneImage.AbsoluteLink) return 'Error fetching saved signature';

        return this.renderImgTag(data.readOneImage.AbsoluteLink);
    };

    renderImgTag(url) {
        return <img alt="" className="signature-img" src={url} />;
    }

    renderUploadField(mutate, data) {
        const { classes } = this.props;
        const randomID = Math.floor(Math.random() * 10000 + 1);
        const loading = data.loading;
        return (
            <Fragment>
                <input
                    type="file"
                    accept={'image/*'}
                    required
                    style={{ display: 'none' }}
                    className={classes.input}
                    id={'raised-button-file' + randomID}
                    onChange={this.onImageChange(mutate)}
                />
                <Inline center>
                    <label htmlFor={'raised-button-file' + randomID}>
                        <div className="upload-field">
                            <div className={classes.wrapper}>
                                <Button
                                    variant="upload"
                                    component="span"
                                    className={`${classes.uploadImageButton} upload`}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    ) : (
                                        <UploadIcon style={{ fontSize: '17px', marginRight: 6 }} />
                                    )}

                                    <Typography
                                        variant="button"
                                        style={{
                                            textTransform: 'capitalize',
                                            margin: 0,
                                            fontWeight: 'normal'
                                        }}
                                    >
                                        {'Upload Image'}...
                                    </Typography>
                                </Button>
                                <p className="label">Select from a folder</p>
                            </div>
                        </div>
                    </label>
                </Inline>
            </Fragment>
        );
    }

    onCanvasChange = mutate => {
        const { folderPath, form } = this.props;
        this.isBeingSaved = true;
        if (this.props.onChange) this.props.onChange();
        this.canvasRef.current.getTrimmedCanvas().toBlob(blob => {
            blob.name = 'signature.png';
            mutate({
                variables: {
                    file: blob,
                    publish: true,
                    folderPath: folderPath || `/documents/form/${form.getField('ID')}`
                }
            });
        }, 'image/png');
    };

    onImageChange = mutate => ({
        target: {
            validity,
            files: [file]
        }
    }) => {
        const { folderPath, form } = this.props;
        this.isBeingSaved = true;
        if (this.props.onChange) this.props.onChange();

        if (typeof file !== 'undefined') {
            const size = file.size;
            const maxSize = getMaxUploadSize();
            if (maxSize < size) return alert('Sorry, file is too big!\nLimit is ' + maxSize / 1024 / 1024 + ' MB');
        }

        return (
            validity.valid &&
            mutate({
                variables: {
                    file: file,
                    publish: true,
                    folderPath: folderPath || `/documents/form/${form.getField('ID')}`
                }
            })
        );
    };

    onFileUploaded = data => {
        const { form, name, fieldIsID } = this.props;
        this.isBeingSaved = false;
        this.isChanged = true;
        if (this.props.onChange) this.props.onChange();

        if (data && data.uploadFile && data.uploadFile.AbsoluteLink) {
            this.setState({
                imageData: { url: data.uploadFile.AbsoluteLink, id: data.uploadFile.ID },
                viewMode: SignatureFieldMode.Image
            });
            if (!this.state.visualOnly) {
                //not causing update to the form, since it is not provided anyway or props are misconfigured
                if (fieldIsID) {
                    form.setField({ [name]: data.uploadFile.ID });
                } else {
                    form.setField({ [name + '.ID']: data.uploadFile.ID });
                }
            }
        }
    };
}

const uploadSignatureQuery = gql`
    mutation($file: UploadType!, $publish: Boolean, $folderPath: String) {
        uploadFile(file: $file, publish: $publish, folderPath: $folderPath) {
            ID
            FileName
            Title
            ParentID
            AbsoluteLink
        }
    }
`;

const ImageQuery = gql`
    query imageQuery($id: ID!) {
        readOneImage(ID: $id) {
            ID
            AbsoluteLink
        }
    }
`;

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    },
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        left: ' 110%'
    },
    card: {
        display: 'flex',
        height: 80
    },
    content: {
        flex: '1 0 auto'
    },
    cover: {
        minWidth: 80,
        maxWidth: 320,
        width: '33%'
    },
    cardText: {
        flexGrow: 2,
        overflow: 'hidden',
        wordBreak: 'break-all'
    },
    details: {
        display: 'flex',
        flexDirection: 'column'
    },
    fileNameLink: {
        color: theme.palette.primary.main
    },
    uploadImageButton: {
        backgroundColor: theme.palette.primary.main,
        float: 'left',
        borderRadius: 30,
        padding: '0.15rem 2rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        letterSpacing: '0.05rem'
    },
    deleteImageButton: {
        backgroundColor: theme.palette.secondary.main,
        float: 'left',
        marginLeft: 10
    }
});

export default withStyles(styles)(SignatureField);
