import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import cx from 'classnames';

/**
 * Extension on the FormLabel from material ui adding:
 * - default theme/style
 * - ability so set the label value via the `text` prop
 */
const Label = ({ classes, text, children, className, ...props }) => (
    <FormLabel className={cx(classes.root, className)} {...props}>
        {children || text}
    </FormLabel>
);

const styles = ({ typography, spacing }) => ({
    root: {
        whiteSpace: 'pre-wrap',
        marginRight: spacing.unit * 3,
        ...typography.body2
    }
});

export default withStyles(styles)(Label);
