import { getServiceURLHostname } from '../../apollo';
import {
    CartIcon,
    CoffinIcon,
    ContactsIcon,
    DashboardIcon,
    DonateIcon,
    FinancialIcon,
    InvoicingIcon,
    MortuaryIcon,
    NurseIcon,
    PayableIcon,
    QuotesIcon,
    ReportsIcon,
    StockIcon,
    TransfersIcon,
    UsersIcon
} from '../IconIndex';
import MetricDashboardIcon from '../metrics/icons/MetricDashboardIcon';

/**
 * Nested list of items for the Drawer
 */
export const navigationItems = [
    {
        path: '/',
        label: 'Dashboard',
        icon: DashboardIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: '/metrics',
                label: 'Performance Metrics',
                icon: MetricDashboardIcon,
                permission: 'FM_ACCESS_Metrics'
            }
        ]
    },
    {
        path: '/quotes',
        label: 'Enquiries',
        icon: PayableIcon,
        permission: 'FM_ACCESS_Enquiries',
        items: [
            {
                path: '/quotes/orders',
                label: 'Online Orders',
                icon: CartIcon,
                permission: 'FM_ACCESS_Enquiries'
            }
        ]
    },
    {
        path: '/search',
        label: 'Funeral Records',
        icon: ReportsIcon,
        permission: 'FM_ACCESS_Funeral_View',
        items: [
            {
                path: '/search/prearrangements',
                label: 'Prearrangements',
                icon: ReportsIcon,
                permission: 'FM_ACCESS_Funeral_View'
            }
        ]
    },
    {
        path: '/contacts',
        label: 'Contacts',
        icon: ContactsIcon,
        permission: 'FM_ACCESS_Contacts_View'
    },
    {
        path: '/refereedoctor',
        label: 'Referee Doctor',
        icon: NurseIcon,
        permission: 'FM_ACCESS_RefereeDoctors_View'
    },
    {
        path: '/deposits',
        label: 'Deposits',
        icon: DonateIcon,
        permission: 'FM_ACCESS_Funeral_View'
    },
    {
        path: '/transfers',
        label: 'Transfers',
        icon: TransfersIcon,
        permission: 'FM_ACCESS_Transfers_View'
    },
    {
        path: '/mortuary',
        label: 'Mortuary',
        icon: MortuaryIcon,
        permission: 'FM_ACCESS_Mortuary_View',
        items: [
            {
                path: '/mortuary/checkout',
                permission: 'FM_ACCESS_Mortuary_Deceased_Checkout_View',
                label: 'Checkout',
                icon: MortuaryIcon
            },
            {
                path: '/mortuary/coffins',
                permission: 'FM_ACCESS_Mortuary_Coffin_View',
                label: 'Coffin Distribution',
                icon: CoffinIcon
            }
        ]
    },
    // {
    //     path: '/music',
    //     label: 'Music',
    //     icon: MusicIcon,
    //     permission: 'FM_ACCESS_Music_View'
    // },
    {
        path: '/financial',
        label: 'Financial',
        icon: FinancialIcon,
        permission: 'FM_ACCESS_Invoicing',
        items: [
            {
                path: '/financial/invoices-bills',
                label: 'Invoices & Bills',
                icon: QuotesIcon,
                permission: 'FM_ACCESS_Invoicing'
            },
            {
                path: '/financial/payments',
                label: 'Payments',
                icon: DonateIcon,
                permission: 'FM_ACCESS_Invoicing'
            }
        ]
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/reports',
        label: 'Reports (CMS)',
        icon: InvoicingIcon,
        newTab: true,
        permission: 'CMS_ACCESS_ReportAdmin'
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/catalog',
        label: 'Stock (CMS)',
        icon: StockIcon,
        newTab: true,
        permission: 'CMS_ACCESS_SilverShop\\Admin\\ProductCatalogAdmin'
    },
    {
        path: '//' + getServiceURLHostname() + '/admin/security',
        label: 'Users (CMS)',
        icon: UsersIcon,
        newTab: true,
        permission: 'CMS_ACCESS_SecurityAdmin'
    },
    {
        path: '/icons',
        label: 'Style Guide',
        icon: DashboardIcon,
        permission: 'CMS_ACCESS'
    }
];

export const buildFlatList = items => {
    const itemsList = [];

    items.forEach(item => {
        itemsList.push(item);
        if (item.items) {
            buildFlatList(item.items);
        }
    });

    return itemsList;
};
