import React, { Component, Fragment } from 'react';
import ModalStepTitle from '../../../component/form/ModalStepTitle';
import { Typography, withStyles } from '@material-ui/core';
import { niceDateTimeFromString } from '../../../util/date';
import SaveIcon from '../../../component/icon/SaveIcon';
import EnvelopeIcon from '../../../component/icon/EnvelopeIcon';
import TickIcon from '../../../component/icon/TickIcon';
import { joinDefined } from '../../../util/strings';
import CloseIcon from '../../../component/icon/CloseIcon';
import BiggerMessageBar from '../../../component/BiggerMessageBar';

class QuotesModalHistoryTab extends Component {
    render() {
        const { classes, quote } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.modalHeader}>
                    <ModalStepTitle
                        number={quote && (quote.Released || quote.Accepted) ? false : 'Three'}
                        title={
                            quote && (quote.Released || quote.Accepted)
                                ? quote.Title
                                : 'Please print or send to customer'
                        }
                        header={
                            quote && (quote.Released || quote.Accepted)
                                ? quote.Accepted
                                    ? `Accepted Quote #${quote.ID}:`
                                    : quote.RespondedDate
                                    ? `Declined Quote #${quote.ID}:`
                                    : `Released Quote #${quote.ID}:`
                                : false
                        }
                    />
                    {!quote && <Typography variant="title">Please select a quote first.</Typography>}
                </div>
                {quote ? this.renderPreview() : null}
            </div>
        );
    }

    renderPreview() {
        const { classes, quote } = this.props;
        let injectApproved = false;

        const renderApproved = !!quote.RespondedDate && (
            <p className={classes.icons}>
                {quote.Accepted ? <TickIcon color="primary" /> : <CloseIcon color="primary" />}
                <strong>Date {quote.Accepted ? 'Accepted' : 'Declined'}:</strong>{' '}
                {niceDateTimeFromString(quote.RespondedDate)}
                {quote.AcceptedBy && Number(quote.AcceptedBy.ID) > 0 && (
                    <Fragment>
                        <br />
                        <strong>Actioned By:</strong> {quote.AcceptedBy.FirstName + ' ' + quote.AcceptedBy.Surname}
                    </Fragment>
                )}
            </p>
        );

        return (
            <div>
                <div className={classes.previewColumn}>
                    <Typography variant="title" style={{ marginBottom: 20 }}>
                        The quote appears as below:
                    </Typography>
                    <div style={{ background: 'grey' }}>
                        {quote.PDFLastEdited && quote.AbsolutePDFLink && quote.AbsolutePDFLink.endsWith('.pdf') ? (
                            <iframe
                                src={quote.AbsolutePDFLink + '?modified=' + encodeURIComponent(quote.PDFLastEdited)}
                                style={{ width: '100%', height: 595 }}
                                title="quote-pdf"
                            />
                        ) : (
                            <BiggerMessageBar messageType={'error'}>Sorry, file is missing.</BiggerMessageBar>
                        )}
                    </div>
                </div>
                <div className={classes.emailColumn}>
                    <Typography variant="title" style={{ marginBottom: 20 }}>
                        History:
                    </Typography>
                    <p className={classes.icons}>
                        <SaveIcon color="primary" />
                        <strong>Date Created:</strong> {niceDateTimeFromString(quote.Created)}
                        <br />
                        <strong>Created By:</strong>
                        {' ' +
                            (quote.CreatedBy
                                ? joinDefined([quote.CreatedBy.FirstName, quote.CreatedBy.Surname], ' ') ||
                                  quote.CreatedBy.Email
                                : '(unknown)')}
                    </p>
                    {quote.QuoteActions &&
                        quote.QuoteActions.map((quoteAction, index) => {
                            if (!injectApproved && quote.RespondedDate && quoteAction.Created > quote.RespondedDate) {
                                injectApproved = quoteAction.ID;
                            }
                            return (
                                <Fragment key={quoteAction.ID}>
                                    {injectApproved === quoteAction.ID && renderApproved}
                                    <p className={classes.icons}>
                                        <EnvelopeIcon color="primary" />
                                        <strong>Date Sent:</strong> {niceDateTimeFromString(quoteAction.Created)}
                                        {quoteAction.ActionedBy && (
                                            <>
                                                <br />
                                                <strong>Sent By:</strong> {quoteAction.ActionedBy.FirstName}{' '}
                                                {quoteAction.ActionedBy.Surname}
                                            </>
                                        )}
                                        <br />
                                        <strong>Sent To:</strong> {quoteAction.EmailTo}
                                    </p>
                                </Fragment>
                            );
                        })}
                    {!injectApproved && renderApproved}
                </div>
            </div>
        );
    }
}

const styles = ({ spacing, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        minHeight: '33vh',
        overflow: 'auto',
        padding: 42,
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            padding: 12
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    },
    emailColumn: {
        marginLeft: 700,
        [breakpoints.down('md')]: { float: 'none', marginLeft: 0, width: '100%' }
    },
    previewColumn: {
        float: 'left',
        width: 660,
        maxWidth: '100%',
        [breakpoints.down('md')]: { float: 'none', marginBottom: 20, width: '100%' }
    },
    labelGap: {
        marginTop: '1rem',
        display: 'block'
    },
    icons: {
        paddingLeft: spacing.unit * 5,
        '& svg': { float: 'left', marginLeft: `-${spacing.unit * 5}px` }
    }
});

export default withStyles(styles)(QuotesModalHistoryTab);
