import gql from 'graphql-tag';

export default gql`
    fragment Coordinators on Funeral {
        Coordinators {
            ID
            Allocation
            Member {
                ID
                Surname
                FirstName
                Email
            }
        }
    }
`;
