import gql from 'graphql-tag';

export default gql`
    fragment PlaceOfDeathResidentialAddress on Funeral {
        PlaceOfDeathResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
        }
    }
`;
