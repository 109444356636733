import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../../component/form/Grid';
import PrimaryButton, { ModalPrimaryButton, OutlineButton, SaveButton } from '../../../../component/form/PrimaryButton';
import { InlineField, InlineFieldRightAlignChildren, InlineHeader } from '../../../../component/form/Inline';
import ModalStepTitle from '../../../../component/form/ModalStepTitle';
import LineItemAutoComplete from '../../../../component/form/LineItemAutoComplete';
import ModalStepSubHeader from '../../../../component/form/ModalStepSubHeader';
import Select from '../../../../component/form/Select';
import TextField from '../../../../component/form/TextField';
import SaveIcon from '../../../../component/icon/SaveIcon';
import CloseIcon from '../../../../component/icon/CloseIcon';
import NextIcon from '../../../../component/icon/NextIcon';
import { DialogActions, Hidden, IconButton } from '@material-ui/core';
import { GST, price_amendment_range } from '../../../../util/products';
import Table, { TableCell, TableRow } from '../../../../component/form/Table';
import { calculateTotals, INVOICE_STATUS_OPTIONS } from './InvoiceModalConstants';
import BackIcon from '../../../../component/icon/BackIcon';
import UsdCircleIcon from '../../../../component/icon/UsdCircleIcon';
import { joinDefined, prettyPrice } from '../../../../util/strings';
import { isNullOrUndefined, limitRange, round } from '../../../../util/objects';
import Checkbox from '../../../../component/form/Checkbox';
import { isRelatedObjectUndefined } from '../../../../util/graphql';
import { lookupItemSource } from '../../../quotes/quotes-modal/QuotesModal';
import GatedComponent from '../../../../component/GatedComponent';
import TickIcon from '../../../../component/icon/TickIcon';

class InvoiceDetailsTab extends Component {
    state = {
        loaded: false,
        showPrefill: false,
        gstPrices: [],
        lastInvoiceId: null
    };

    static getDerivedStateFromProps = ({ prefill, invoice }, { loaded, lastInvoiceId }) => {
        if (!!prefill && prefill.quotes && !loaded && !!invoice) {
            const prefillQuotes = prefill.quotes.length > 0 ? prefill.quotes[0] : false;
            const usePO = prefill.purchaseOrder.length > 0;
            const toggles = {};
            const usePrefill = isNaN(invoice.ID) && (!!prefillQuotes || !!usePO);
            if (!!usePrefill) {
                if (!!usePO) {
                    setInvoiceFromPrefill({ value: 'PO', checked: true }, { prefill, invoice });
                    toggles.togglePO = true;
                }
                if (!!prefillQuotes) {
                    prefill.quotes.forEach(e => {
                        setInvoiceFromPrefill({ value: e.quoteID, checked: true }, { prefill, invoice });
                        toggles['toggle' + e.quoteID] = true;
                    });
                }
            }

            return {
                gstPrices: invoice.InvoiceItems.map(x => getPriceIncGst(x)),
                showPrefill: !!prefillQuotes || usePO,
                loaded: true,
                lastInvoiceId: invoice.ID,
                ...toggles
            };
        }

        if (!isNullOrUndefined(invoice) /*&& invoice.ID !== lastInvoiceId*/) {
            return {
                gstPrices: (invoice.InvoiceItems || []).map(x => getPriceIncGst(x)),
                lastInvoiceId: invoice.ID
            };
        }

        return null;
    };

    render() {
        const {
            classes,
            nextTab,
            previousTab,
            onCancel,
            loading,
            onSubmit,
            onSubmitToXero,
            canEdit,
            canSubmit,
            stepNumber,
            hasExportedToXero,
            onAuthoriseToXero,
            invoice,
            form
        } = this.props;
        const isDirty = form.getDirty().length;
        const typeTitle = invoice.IsPurchase ? 'Bill' : 'Invoice';
        let title;
        if (stepNumber === 'Two') {
            title = 'Update the line items and other details';
        } else {
            title = `#${invoice.ID}`;
            if (invoice.LegacyKey) {
                title += ` (${invoice.LegacyKey})`;
            }
            if (stepNumber === 'Three') {
                title = invoice.ID ? `Submit ${typeTitle} to Xero` : `Preview the new ${typeTitle}`;
            }
        }

        const lockedByXero = ['PAID', 'AUTHORISED', 'DELETED', 'VOIDED'].indexOf(this.props.invoice.XeroStatus) > -1;

        return (
            <Fragment>
                <Grid container spacing={16} className={classes.root}>
                    <ModalStepTitle number={stepNumber} title={title} header={typeTitle} />
                    <Grid item xs={12} className={classes.modalContent}>
                        {canEdit ? this.renderInvoiceDetails() : this.renderReadOnlyInvoiceDetails()}
                        <br />
                        <br />
                        <GatedComponent isEnabledCode={'ADMIN'}>
                            {canSetAnyPrice => this.renderInvoiceItems(canSetAnyPrice)}
                        </GatedComponent>
                        <br />
                        {this.renderSummary()}
                    </Grid>
                </Grid>
                <DialogActions className={classes.modalFooter}>
                    <OutlineButton onClick={loading ? undefined : onCancel} color="primary" disabled={loading}>
                        <CloseIcon />
                        <Hidden smDown>Close</Hidden>
                    </OutlineButton>

                    {canEdit || canSubmit ? (
                        <PrimaryButton onClick={previousTab} disabled={loading} className={classes.back}>
                            <BackIcon />
                            <Hidden smDown>Back</Hidden>
                        </PrimaryButton>
                    ) : null}

                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span />

                        {canEdit ? (
                            <PrimaryButton onClick={nextTab} disabled={loading || !!isDirty}>
                                <Hidden smDown>Next Step</Hidden>
                                <NextIcon />
                            </PrimaryButton>
                        ) : null}

                        {canEdit ? (
                            <SaveButton disabled={loading || !isDirty} onClick={() => onSubmit()}>
                                <SaveIcon />
                                <span className={classes.svgLabel}>Save</span>
                            </SaveButton>
                        ) : null}

                        {canSubmit ? (
                            <ModalPrimaryButton
                                disabled={loading || !(!!invoice && !!invoice.ID && invoice.ID !== '0')}
                                onClick={() => onSubmitToXero()}
                            >
                                <UsdCircleIcon />
                                <span className={classes.svgLabel}>Submit To Xero</span>
                            </ModalPrimaryButton>
                        ) : null}

                        {!canEdit && !canSubmit ? (
                            <PrimaryButton onClick={nextTab} disabled={loading}>
                                <Hidden smDown>{hasExportedToXero ? 'View PDF' : 'Payments'}</Hidden>
                                <NextIcon />
                            </PrimaryButton>
                        ) : null}

                        {hasExportedToXero && !lockedByXero ? (
                            <GatedComponent showComponentCode={'FM_ACCESS_Invoicing'}>
                                {() => {
                                    return (
                                        <ModalPrimaryButton disabled={loading} onClick={() => onAuthoriseToXero()}>
                                            <UsdCircleIcon />
                                            <span className={classes.svgLabel}>Authorise {typeTitle}</span>
                                        </ModalPrimaryButton>
                                    );
                                }}
                            </GatedComponent>
                        ) : null}
                    </InlineFieldRightAlignChildren>
                </DialogActions>
            </Fragment>
        );
    }

    renderInvoiceDetails() {
        const { invoice, canEdit, hasExportedToXero } = this.props;

        return (
            <Fragment>
                <InlineField>
                    <Grid container spacing={16}>
                        <Grid item xs={3}>
                            <TextField
                                label={`${invoice.IsPurchase ? 'Bill' : 'Invoice'} Dated`}
                                type="date"
                                value={invoice.InvoiceDate}
                                onChange={e => this.onChangeInvoiceProperty('InvoiceDate', e.target.value)}
                                disabled={!canEdit || !!invoice.InvoiceSent}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Select
                                label="Payment Method"
                                options={INVOICE_STATUS_OPTIONS}
                                value={invoice.InvoiceStatus || 'Normal'}
                                onChange={e =>
                                    this.onChangeInvoiceProperty(
                                        'InvoiceStatus',
                                        e.target.value === 'Normal' ? null : e.target.value
                                    )
                                }
                                allowNone={false}
                                //disabled={!canEdit}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Due Date"
                                type="date"
                                value={invoice.InvoiceDueDate}
                                onChange={e => this.onChangeInvoiceProperty('InvoiceDueDate', e.target.value)}
                                disabled={!canEdit}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Xero Status"
                                value={hasExportedToXero ? invoice.XeroStatus : 'NOT EXPORTED'}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </InlineField>
            </Fragment>
        );
    }

    renderReadOnlyInvoiceDetails() {
        const { invoice, classes, hasExportedToXero } = this.props;
        if (!invoice.Customer) invoice.Customer = { CustomerName: '' };
        let splitName = invoice.Customer.FirstName || invoice.Customer.Surname;
        let customerName = splitName
            ? joinDefined([invoice.Customer.FirstName, invoice.Customer.MiddleName, invoice.Customer.Surname], ' ')
            : invoice.Customer.CustomerName;

        const addressMapping = [
            invoice.Customer.AddressLine1,
            invoice.Customer.AddressLine2,
            invoice.Customer.Suburb,
            invoice.Customer.State,
            invoice.Customer.Postcode,
            invoice.Customer.Country
        ];

        let result = addressMapping.reduce((string, val) => {
            let space = string.length ? ' ' : '';
            return string + (val ? space + val : '');
        }, '');
        return (
            <Fragment>
                <InlineField>
                    <table className={classes.detailsTable}>
                        <thead>
                            <tr>
                                <th>Addressed To</th>
                                <th>{`${invoice.IsPurchase ? 'Bill' : 'Invoice'} Dated`}</th>
                                <th>Payment Method</th>
                                <th>Due Date</th>
                                <th>Xero Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {customerName}
                                    <br />
                                    {result}
                                </td>
                                <td>{invoice.InvoiceDate}</td>
                                <td>
                                    {invoice.InvoiceStatus
                                        ? INVOICE_STATUS_OPTIONS.find(e => e.value === invoice.InvoiceStatus).label
                                        : 'Normal'}
                                </td>
                                <td>{invoice.InvoiceDueDate}</td>
                                <td>{hasExportedToXero ? invoice.XeroStatus : 'NOT EXPORTED'}</td>
                            </tr>
                        </tbody>
                    </table>
                </InlineField>
            </Fragment>
        );
    }

    renderInvoiceItems(canSetAnyPrice) {
        const { classes, invoice, canEdit, onEdit, loading, hasExportedToXero, prefill } = this.props;
        const { gstPrices } = this.state;
        const lockedByXero = ['PAID', 'AUTHORISED', 'DELETED', 'VOIDED'].indexOf(this.props.invoice.XeroStatus) > -1;
        let prefillQuotes = [];
        if (!invoice.IsPurchase && prefill && !!this.state.showPrefill) {
            prefillQuotes = prefill.quotes.map(e => {
                return { label: `Quote #${e.quoteID}`, value: '' + e.quoteID };
            });
            //if (!!prefillQuotes && prefillQuotes.length > 0) prefillQuotes.unshift({ label: '(none)', value: '' + 0 });
            prefillQuotes.unshift({ label: 'Bills', value: 'PO' });
        }

        const tableColumns = [
            'Product Code/SKU',
            'Line Item Description',
            'Price',
            'Quantity',
            'Prepaid',
            'Subtotal (exGST)',
            'GST',
            'Total',
            'Source'
        ];

        if (!canEdit) {
            tableColumns.pop();
        }

        if (!!invoice.IsPurchase) {
            tableColumns.splice(4, 1);
        }

        return (
            <Fragment>
                {hasExportedToXero ? (
                    <ModalPrimaryButton
                        disabled={lockedByXero ? true : loading}
                        onClick={() => onEdit()}
                        className={classes.floatRight}
                    >
                        Edit {invoice.IsPurchase ? 'Bill' : 'Invoice'}...
                    </ModalPrimaryButton>
                ) : null}

                {!!canEdit ? (
                    <InlineField>
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={8}>
                                {(!!this.state.showPrefill && (
                                    <InlineHeader header={'Source Line Items from:'}>
                                        {prefillQuotes.map((quote, i) => (
                                            <Checkbox
                                                key={i}
                                                checked={!!this.state['toggle' + quote.value]}
                                                label={quote.label}
                                                value={'' + quote.value}
                                                onChange={e => this.onChooseQuote(e.target)}
                                            />
                                        ))}
                                    </InlineHeader>
                                )) || (
                                    <ModalStepSubHeader
                                        header={`Review ${invoice.IsPurchase ? 'Bill' : 'Invoice'} Line Items`}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LineItemAutoComplete
                                    variations
                                    disabled={!canEdit}
                                    onSelect={item => this.onAddNewLineItem(item)}
                                />
                            </Grid>
                        </Grid>
                    </InlineField>
                ) : (
                    <ModalStepSubHeader header={`${invoice.IsPurchase ? 'Bill' : 'Invoice'} Line Items`} />
                )}
                <br />
                <InlineField lineHeight={'normal'}>
                    <Grid item xs={12}>
                        <Table columns={tableColumns}>
                            {invoice.InvoiceItems.map((row, i) => {
                                const gstPrice = round(gstPrices[i], 2);
                                const total = row.Prepaid ? 0 : gstPrice * row.Quantity;
                                const product = row.Product || { ID: 0, GST: 0, Title: '', InternalItemID: '' };
                                const variant = row.Variation || {
                                    ID: 0,
                                    GST: 0,
                                    ShortDescription: '',
                                    InternalItemID: ''
                                };

                                const title =
                                    row.Title ||
                                    product.Title +
                                        (!!Number(variant.ID)
                                            ? ' | ' +
                                              (!!variant.ShortDescription
                                                  ? variant.ShortDescription
                                                  : variant.InternalItemID)
                                            : '');
                                const code =
                                    product.InternalItemID +
                                    (!!Number(variant.ID) ? ' | ' + variant.InternalItemID : '');

                                return (
                                    <TableRow key={i} pad={true} className={canEdit ? classes.removableRow : null}>
                                        <TableCell>
                                            {code}
                                            {!!canEdit && (
                                                <IconButton
                                                    className={classes.iconButton}
                                                    title={'Delete'}
                                                    onClick={() => this.onDeleteLineItem(i)}
                                                >
                                                    <CloseIcon className={classes.icon} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                        <TableCell>{title}</TableCell>
                                        <TableCell className={classes.rightCell}>
                                            {canEdit ? (
                                                <TextField
                                                    className={classes.priceField}
                                                    type="number"
                                                    onChange={e => this.onChangeTotalPrice(i, e.target.value, true)}
                                                    onBlur={e =>
                                                        this.onChangeTotalPrice(i, e.target.value, canSetAnyPrice)
                                                    }
                                                    value={gstPrice || '0'}
                                                />
                                            ) : (
                                                <span>{prettyPrice(gstPrice)}</span>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.rightCell}>
                                            {!!canEdit ? (
                                                <TextField
                                                    className={classes.quantityField}
                                                    type="number"
                                                    inputProps={{ min: '0' }}
                                                    onChange={e => this.onChangeQuantity(i, e.target.value)}
                                                    value={row.Quantity || '0'}
                                                />
                                            ) : (
                                                <span>{Number(row.Quantity || '0').toFixed(0)}</span>
                                            )}
                                        </TableCell>
                                        {!invoice.IsPurchase && (
                                            <TableCell className={classes.centreCell}>
                                                {!!canEdit ? (
                                                    <Checkbox
                                                        onChange={e => this.onChangePrepaid(i, e.target.checked)}
                                                        checked={!!row.Prepaid || false}
                                                    />
                                                ) : row.Prepaid ? (
                                                    <TickIcon color={'primary'} />
                                                ) : (
                                                    <CloseIcon color={'disabled'} />
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell className={classes.rightCell}>
                                            {prettyPrice(row.Prepaid ? 0 : row.Price * row.Quantity)}
                                        </TableCell>
                                        <TableCell className={classes.rightCell}>
                                            {hasGst(row) ? prettyPrice(total / ((GST + 1) * 10)) : 'N/A'}
                                        </TableCell>
                                        <TableCell className={classes.rightCell}>{prettyPrice(total)}</TableCell>
                                        {!!canEdit && (
                                            <TableCell>
                                                {isNaN(row.QuoteID) ? row.QuoteID : 'Quote #' + row.QuoteID}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </Grid>
                </InlineField>
            </Fragment>
        );
    }

    renderSummary() {
        const { classes, invoice, canEdit } = this.props;
        const totals = calculateTotals(invoice);

        return (
            <Fragment>
                <InlineField>
                    <Grid item xs={9}>
                        {invoice.ID && (
                            <Fragment>
                                <table className={classes.detailsTable}>
                                    <thead>
                                        <tr>
                                            <th>Created</th>
                                            <th>Xero Reference</th>
                                            <th>{!invoice.IsPurchase && 'Sent to Customer'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {invoice.Created}
                                                {' by '}
                                                {invoice.CreatedBy
                                                    ? joinDefined(
                                                          [invoice.CreatedBy.FirstName, invoice.CreatedBy.Surname],
                                                          ' '
                                                      )
                                                    : 'system'}
                                            </td>
                                            <td>{invoice.XeroReference}</td>
                                            <td>{!invoice.IsPurchase && (invoice.InvoiceSent ? 'Yes' : 'No')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Fragment>
                        )}
                    </Grid>
                    {this.calculateAmountPaid(totals, canEdit, classes)}
                </InlineField>
            </Fragment>
        );
    }

    onChangeInvoiceProperty(property, value) {
        const { invoice } = this.props;
        invoice[property] = value;
        this.forceUpdate();
    }

    onChangePrepaid(lineItemIndex, booleanValue) {
        const { invoice } = this.props;
        const lineItem = invoice.InvoiceItems[lineItemIndex];
        lineItem.Prepaid = booleanValue || false;
        this.forceUpdate();
    }

    onChangeQuantity(lineItemIndex, stringValue) {
        const value = typeof stringValue === 'number' ? stringValue : parseInt(stringValue, 10);
        if (isNaN(value) || value < 0) return 0;

        const { gstPrices } = this.state;
        const { invoice } = this.props;
        const lineItem = invoice.InvoiceItems[lineItemIndex];
        lineItem.Quantity = value;
        recalculatePrice(lineItem, gstPrices[lineItemIndex]);

        this.forceUpdate();
    }

    onChangeTotalPrice(lineItemIndex, stringValue, canSetAnyPrice = false) {
        const value = typeof stringValue === 'number' ? stringValue : parseFloat(stringValue);
        if (isNaN(value)) return 0;

        const { gstPrices } = this.state;
        const { invoice } = this.props;
        const lineItem = invoice.InvoiceItems[lineItemIndex];
        const originalCost = isNullOrUndefined(lineItem.CostIncGST) ? gstPrices[lineItemIndex] : lineItem.CostIncGST;
        const limitedValue = !!canSetAnyPrice
            ? value
            : limitRange(value, originalCost, price_amendment_range, originalCost < 0);

        gstPrices[lineItemIndex] = limitedValue;
        recalculatePrice(lineItem, limitedValue);

        this.setState({ gstPrices });
    }

    onDeleteLineItem(index) {
        const { invoice } = this.props;
        invoice.InvoiceItems.splice(index, 1);

        const { gstPrices } = this.state;
        gstPrices.splice(index, 1);
        this.setState({ gstPrices });
    }

    onAddNewLineItem(item) {
        const { invoice } = this.props;
        const source = lookupItemSource(item);
        const lineItem = {
            Quantity: 1,
            Price: source.Cost,
            Prepaid: false,
            QuoteID: 'Added',
            Product: {
                ...item.Product
            },
            Variation: {
                ...item.Variation
            }
        };

        invoice.InvoiceItems.push(lineItem);

        const { gstPrices } = this.state;
        gstPrices.push(getPriceIncGst(lineItem));
        this.setState({ gstPrices });
    }

    onChooseQuote(target) {
        setInvoiceFromPrefill(target, this.props);
        const updateToggle = 'toggle' + target.value;
        const { invoice } = this.props;
        this.setState({
            [updateToggle]: !!target.checked,
            gstPrices: invoice.InvoiceItems.map(x => getPriceIncGst(x))
        });
    }

    calculateAmountPaid(totals, canEdit, classes) {
        let totalPaid = totals.amountPaid;
        let amountDue = totals.amountDue;
        return (
            <Grid item xs={3}>
                <table className={classes.costSummaryTable}>
                    <tbody>
                        <tr>
                            <td>
                                <span>Subtotal</span>
                            </td>
                            <td>
                                <span>{prettyPrice(totals.total)}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Total GST ({GST * 100}%)</span>
                            </td>
                            <td>
                                <span>{prettyPrice(totals.gst)}</span>
                            </td>
                        </tr>
                        {(!canEdit && (
                            <Fragment>
                                <tr className={classes.totalRow}>
                                    <td>
                                        <span>TOTAL</span>
                                    </td>
                                    <td>
                                        <span>{prettyPrice(totals.totalWithGst)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Amount Paid</span>
                                    </td>
                                    <td>
                                        <span>{prettyPrice(totalPaid)}</span>
                                    </td>
                                </tr>

                                <tr className={classes.amountDueRow}>
                                    <td>
                                        <span>AMOUNT DUE</span>
                                    </td>
                                    <td>
                                        <span>{prettyPrice(amountDue)}</span>
                                    </td>
                                </tr>
                            </Fragment>
                        )) || (
                            <tr className={classes.totalRow}>
                                <td>
                                    <span>TOTAL</span>
                                </td>
                                <td>
                                    <span>{prettyPrice(totals.totalWithGst)}</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Grid>
        );
    }
}

const recalculatePrice = (invoiceLineItem, newGstPrice) => {
    if (isNullOrUndefined(invoiceLineItem.CostIncGST)) invoiceLineItem.CostIncGST = getPriceIncGst(invoiceLineItem);
    invoiceLineItem.Price = hasGst(invoiceLineItem) ? newGstPrice / (GST + 1) : newGstPrice;
};

const hasGst = invoiceLineItem => {
    return !isRelatedObjectUndefined(invoiceLineItem.Variation)
        ? invoiceLineItem.Variation.GST
        : !isRelatedObjectUndefined(invoiceLineItem.Product) && invoiceLineItem.Product.GST;
};

const getPriceIncGst = invoiceLineItem => {
    const price = parseFloat(invoiceLineItem.Price);
    return hasGst(invoiceLineItem) ? price * (GST + 1) : price;
};

const setInvoiceFromPrefill = ({ value, checked }, { prefill, invoice }) => {
    const newItems = { list: [] };
    const oldList = invoice.InvoiceItems.filter(e => !!e); // clone
    if (value !== false) {
        if (value === 'PO') {
            // strip the PO items
            newItems.list = oldList.filter(e => !prefill.purchaseOrder.find(f => f.Product.ID === e.Product.ID));
            // add back in if wanted
            if (checked) {
                newItems.list = [].concat(
                    prefill.purchaseOrder.map(e => {
                        e.QuoteID = 'Bills';
                        return e;
                    }),
                    newItems.list
                );
            }
        } else {
            const quote = prefill.quotes.find(e => e.quoteID === value);
            if (quote) {
                //strip the quote items
                newItems.list = oldList.filter(e => e.QuoteID !== value);
                // add back in if wanted
                if (checked) newItems.list = [].concat(quote.items, newItems.list);
            }
        }
    }
    invoice.InvoiceItems = newItems.list;
};
/*
const queryReadInvoicePayments = gql`
    query($ID: ID) {
        readInvoicePayments(ID: $ID)
    }
`;
*/
const styles = () => ({
    root: {
        margin: '0',
        padding: '50px 30px 30px 30px',
        height: 'auto',
        alignContent: 'start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%'
    },

    centreCell: {
        textAlign: 'center'
    },
    rightCell: {
        textAlign: 'right'
    },

    quoteTemplateColumn: {
        width: '140px'
    },

    selectedColumn: {
        width: '70px'
    },

    detailsTable: {
        width: '100%',
        '& th': {
            textAlign: 'left',
            verticalAlign: 'top',
            fontWeight: 'bold',
            background: 'white'
        },
        '& td': {
            textAlign: 'left',
            verticalAlign: 'top'
        }
    },

    costSummaryTable: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        width: '100%',
        fontSize: '12px',
        '& tr > :first-child': {
            textAlign: 'right',
            padding: '0 20px 0 0'
        },
        '& tr > :not(:first-child)': {
            textAlign: 'right'
        }
    },

    totalRow: {
        '& td': {
            borderWidth: '1px 0',
            borderStyle: 'solid',
            borderColor: '#000'
        }
    },

    amountDueRow: {
        '& td': {
            borderWidth: '0 0 1px 0',
            borderStyle: 'solid',
            borderColor: '#000',
            fontSize: '18px'
        }
    },

    iconButton: {
        width: '17px;',
        height: '17px;',
        float: 'right'
    },

    icon: {
        width: '14px;',
        height: '14px;'
    },

    quantityField: {
        width: '60px;'
    },

    priceField: {
        width: '100px;'
    },

    submitToXeroButton: {
        height: '15px'
    },

    floatRight: {
        float: 'right'
    },

    back: {
        float: 'left'
    }
});

export default withStyles(styles)(InvoiceDetailsTab);
