import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import cx from 'classnames';
import Spinner from './Spinner';
import FireIcon from './icon/FireIcon';
import { ExclamationCircleIcon, ExclamationTriangleIcon, InfoCircleIcon, TickCircleIcon } from './IconIndex';

const BiggerMessageBar = ({ messageType, classes, children }) => {
    const types = [];
    types['good'] = { icon: <TickCircleIcon />, class: 'happiness' };
    types['error'] = { icon: <ExclamationCircleIcon />, class: 'sadness' };
    types['approve'] = { icon: <ExclamationTriangleIcon />, class: 'bossiness' };
    types['warning'] = { icon: <ExclamationTriangleIcon />, class: 'worriedness' };
    types['fire'] = { icon: <FireIcon />, class: 'worriedness' };
    types['loading'] = { icon: <Spinner width={'24px'} />, class: 'curiousness' };
    types['info'] = { icon: <InfoCircleIcon />, class: 'curiousness' };

    if (!types[messageType]) {
        messageType = 'info';
    }

    return (
        <div className={cx(classes.message, classes[types[messageType].class])}>
            {types[messageType].icon}
            <div>{children}</div>
        </div>
    );
};

const styles = ({ spacing }) => ({
    message: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #35327c',
        backgroundColor: '#eff8fd',
        borderRadius: spacing.unit / 2,
        fontSize: '1rem',
        padding: '12px 15px',
        margin: `${spacing.unit}px 0`,
        width: '100%',
        '& > svg': {
            color: '#35327c'
        },
        '& > :not(:first-child)': {
            flexGrow: '1',
            marginLeft: spacing.unit
        },
        minHeight: 40
    },
    happiness: {
        borderColor: '#54be82',
        background: '#e9faf0',
        '& > svg': {
            color: '#54be82'
        }
    },
    sadness: {
        borderColor: '#ea5d5d',
        background: '#fdebeb',
        '& > svg': {
            color: '#ea5d5d'
        }
    },
    worriedness: {
        borderColor: '#eab35d',
        background: '#fdfceb',
        '& > svg': {
            color: '#eab35d'
        }
    },
    curiousness: {
        borderColor: '#6eb9f7',
        background: '#ebf6fd',
        '& > svg': {
            color: '#6eb9f7'
        }
    },
    bossiness: {
        borderColor: '#990CFF',
        background: '#f7ecff',
        '& > svg': {
            color: '#990CFF'
        }
    }
});

export default withStyles(styles)(BiggerMessageBar);
