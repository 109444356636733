import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 27 27" {...props}>
        <g id="CompletedIcon" transform="translate(-532 -506)">
            <circle
                id="Ellipse_91"
                data-name="Ellipse 91"
                cx="13.5"
                cy="13.5"
                r="13.5"
                transform="translate(532 506)"
                fill="#26cc6f"
            />
            <path
                id="check"
                d="M17.073,88.56l-9.8,9.8L3.809,94.894a.42.42,0,0,0-.594,0l-.4.4a.42.42,0,0,0,0,.594l4.157,4.157a.42.42,0,0,0,.594,0L18.063,89.549a.42.42,0,0,0,0-.594l-.4-.4A.42.42,0,0,0,17.073,88.56Z"
                transform="translate(534.803 425.063)"
                fill="#fff"
                stroke="#fff"
                strokeWidth="1"
            />
        </g>
    </SvgIcon>
);
