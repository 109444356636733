import React from 'react';
import Button from '@material-ui/core/Button';
import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import history from '../../../History';
import { IconButton } from '@material-ui/core';
import { DeleteIcon } from '../../../component/IconIndex';

/**
 * Primary coloured button
 * variants:
 	primary
	secondary
	tertiary
 	quaternary
 	urgent
	confirmation
	modal-action
	tag
 */
const ButtonWrapper = ({
    classes,
    onClick,
    children,
    className,
    color,
    href,
    size,
    icon,
    startIcon,
    endIcon,
    target,
    title,
    variant = 'primary',
    ...props
}) => {
    const variants = variant.split(' ');
    const sizeCss = !stringIsNullOrEmpty(size) ? `button-base--${size}` : null;

    if (target) {
        title = joinDefined([title, '(Opens in a new window)'], ' ');
    }

    const onClickEvent = e => {
        if (e && href) {
            e.preventDefault();
        }
        if (onClick) return onClick(e);
        else if (target) return window.open(href, target);
        return history.push(href);
    };

    if (variant.includes('link')) {
        return (
            <a
                href={href}
                target={target || undefined}
                title={title}
                onClick={onClickEvent}
                //classes={{ root: 'button-label' }}
                className={`button-base ${variant} ${sizeCss || ''} ${className || ''} ${(!!target && 'newwindow') ||
                    ''}`}
            >
                {children}
            </a>
        );
    }

    if (variants.indexOf('tag') > -1) {
        const { disabled } = props;
        return (
            <div
                className={`button-base ${variant} ${sizeCss || ''}${onClick ? ' with-delete' : ''}${
                    disabled ? ' disabled' : ''
                }`}
            >
                <span className="button-label" title={title}>
                    {children}
                </span>
                {onClick && (
                    <span
                        style={disabled ? { opacity: 0.25 } : null}
                        onClick={!disabled && onClickEvent}
                        onKeyDown={!disabled && onClickEvent}
                        role="button"
                        tabIndex="0"
                        className={`icon-button ${variant}`}
                        title="Remove"
                    >
                        <DeleteIcon />
                    </span>
                )}
            </div>
        );
    }
    if (icon && !children) {
        return (
            <IconButton
                variant="contained"
                color="primary"
                onClick={onClickEvent}
                className={`button-base icon-button ${joinDefined([variant, sizeCss], ' ')}`}
                classes={{ label: `button-label ${!!target && 'newwindow'}` }}
                title={title}
                {...props}
            >
                {icon}
            </IconButton>
        );
    }

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClickEvent}
            className={`button-base ${joinDefined([variant, sizeCss, className], ' ')}`}
            classes={{ label: `button-label ${!!target && 'newwindow'}` }}
            title={title}
            {...props}
        >
            {icon || startIcon || endIcon ? (
                <>
                    {icon || startIcon}
                    <span className="button-txt">{children}</span>
                    {endIcon}
                </>
            ) : (
                children
            )}
        </Button>
    );
};

export default ButtonWrapper;
