import gql from 'graphql-tag';

export const QuoteAction = gql`
    fragment QuoteAction on QuoteAction {
        ID
        Created
        EmailTo
        EmailSubject
        EmailBody
        Quote {
            ID
        }
        ActionedBy {
            ID
            FirstName
            Surname
        }
        QuoteEmailTemplate {
            ID
            LegacyKey
            EmailAttachments {
                ... on File {
                    ID
                    AbsoluteLink
                    Name
                    Size
                }
                ... on Image {
                    ID
                    AbsoluteLink
                    Name
                    Size
                }
            }
        }
    }
`;
