import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from './TextField';

class MusicModal extends Component {
    state = {};

    render() {
        const { open, onClose } = this.props;
        if (!open) return null;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby={`CreateSong`}>
                <Mutation mutation={createSongQuery} onCompleted={this.onCompleted}>
                    {this.renderForm}
                </Mutation>
            </Dialog>
        );
    }

    renderForm = (mutate, { loading }) => {
        return (
            <form onSubmit={this.onSubmit(mutate, loading)}>
                <DialogTitle id="CreateSong">Add Song</DialogTitle>
                <DialogContent>
                    {this.renderField('SongName', 'Song Name', true, true)}
                    {this.renderField('Artist', 'Artist', false, true)}
                    {this.renderField('Album', 'Album', false, false)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={loading ? undefined : this.onCancel} disabled={loading}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        );
    };

    renderField(name, label, autoFocus, required) {
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onChange}
            />
        );
    }

    onCancel = () => {
        this.props.onClose();
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = (mutate, loading) => e => {
        e.preventDefault();
        if (loading) return;
        mutate({
            variables: {
                input: {
                    ...this.state
                }
            }
        });
    };

    onCompleted = ({ createMusicLibrary: song }) => {
        const { onClose } = this.props;
        onClose(song);

        const clearedState = Object.keys(this.state).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});

        this.setState(clearedState);
    };
}

const createSongQuery = gql`
    mutation CreateMusicLibrary($input: CreateMusicLibraryInput!) {
        createMusicLibrary(input: $input) {
            SongName
            Artist
            Album
        }
    }
`;

const styles = () => ({});

export default withStyles(styles)(MusicModal);
