import React from 'react';
import RainbowSpinner from '../_asset/rainbow-spinner.gif';

export const WhiteLoader = ({ actionWord = 'Processing' }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: '#ffffff99',
                zIndex: 9,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div>
                <img src={RainbowSpinner} alt="spinning..." />
                <br />
                {actionWord}, please wait a moment...
            </div>
        </div>
    );
};
