import gql from 'graphql-tag';
import { OrdersFragmentInclude } from './Bookings';

export default gql`
    fragment PersonalisedTouches on Funeral {
        PersonalisedTouches {
            ${OrdersFragmentInclude}
        }
    }
`;
