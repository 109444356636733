import gql from 'graphql-tag';

export default gql`
    fragment DocumentTemplate on DocumentTemplate {
        ID
        Type
        Title
        Office {
            ID
        }
        Template {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
    # just some junk to ignore...
    fragment CremationDocumentTemplate on CMDocumentTemplate {
        ID
    }
`;
