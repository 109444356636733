import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../../component/form/Grid';
import TextField from '../../../../component/form/TextField';
import PrimaryButton, { OutlineButton, SaveButton } from '../../../../component/form/PrimaryButton';
import ModalStepTitle from '../../../../component/form/ModalStepTitle';
import CloseIcon from '../../../../component/icon/CloseIcon';
import BackIcon from '../../../../component/icon/BackIcon';
import { calculateTotals, INVOICE_STATUS_OPTIONS } from './InvoiceModalConstants';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../../component/form/Table';
import ModalStepHeader from '../../../../component/form/ModalStepHeader';
import ColumnLayout from '../../../../component/ColumnLayout';
import { DialogActions, Hidden } from '@material-ui/core';
import Label from '../../../../component/form/Label';
import { InlineFieldRightAlignChildren } from '../../../../component/form/Inline';
import SaveIcon from '../../../../component/icon/SaveIcon';
import RadioGroup from '../../../../component/form/RadioGroup';
import { prettyPrice } from '../../../../util/strings';
import BiggerMessageBar from '../../../../component/BiggerMessageBar';

class PaymentHistoryTab extends Component {
    state = {
        paymentMethod: this.props.invoice.InvoiceStatus ? '' + this.props.invoice.InvoiceStatus : 'Normal'
    };

    render() {
        const { classes, onCancel, loading, invoice, previousTab } = this.props;
        const { paymentMethod } = this.state;
        const propsStatus = invoice.InvoiceStatus || 'Normal';
        return (
            <Fragment>
                <Grid container spacing={16} className={classes.root}>
                    <ModalStepTitle
                        header={invoice.IsPurchase ? 'Bill' : 'Invoice'}
                        title={`#${invoice.ID} ${invoice.LegacyKey ? '(' + invoice.LegacyKey + ')' : ''}`}
                    />
                    <Grid item xs={12}>
                        <ColumnLayout className={classes.columnRoot}>
                            {this.renderPaymentHistory()}
                            {this.renderDetails()}
                        </ColumnLayout>
                    </Grid>
                </Grid>
                <DialogActions className={classes.modalFooter}>
                    <OutlineButton onClick={loading ? undefined : onCancel} className={classes.back} disabled={loading}>
                        <CloseIcon />
                        <span className={classes.svgLabel}>Close</span>
                    </OutlineButton>
                    <PrimaryButton onClick={previousTab} disabled={loading} className={classes.back}>
                        <BackIcon />
                        <span className={classes.svgLabel}>Back</span>
                    </PrimaryButton>
                    {!invoice.IsPurchase && (
                        <InlineFieldRightAlignChildren lineHeight={'normal'}>
                            <span />
                            <SaveButton
                                disabled={paymentMethod === propsStatus}
                                onClick={this.handleUpdatePaymentMethod}
                            >
                                <SaveIcon />
                                <Hidden smDown>Save</Hidden>
                            </SaveButton>
                        </InlineFieldRightAlignChildren>
                    )}
                </DialogActions>
            </Fragment>
        );
    }

    renderPaymentMethod() {
        const { invoice } = this.props;
        const { paymentMethod } = this.state;
        const canEdit =
            !invoice.IsPurchase &&
            !(invoice.XeroStatus === 'VOIDED' || invoice.XeroStatus === 'DELETED' || !!invoice.InvoicePaid);
        return (
            <>
                {canEdit && (
                    <Grid bucket>
                        <Grid pc={1}>
                            <Label text={'Update Payment Method'} />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                value={paymentMethod || 'Normal'}
                                options={INVOICE_STATUS_OPTIONS}
                                onChange={e => this.setState({ paymentMethod: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                )}
                {!invoice.IsPurchase && !canEdit && (
                    <Grid item xs={12}>
                        <TextField
                            label={'Payment Method'}
                            readOnly
                            value={INVOICE_STATUS_OPTIONS.find(e => e.value === paymentMethod).label}
                        />
                    </Grid>
                )}
            </>
        );
    }

    renderDetails() {
        const { invoice } = this.props;
        const totals = calculateTotals(invoice);
        let status = 'error';
        if ('DELETED' === invoice.XeroStatus || 'VOIDED' === invoice.XeroStatus) {
            status = 'info';
        }

        return (
            <Grid container={true} spacing={16}>
                <Grid item xs={12}>
                    <ModalStepHeader header={'Payment Status'} />
                    <BiggerMessageBar messageType={invoice.InvoicePaid ? 'good' : status}>
                        {invoice.IsPurchase ? 'Bill' : 'Invoice'}
                        {invoice.InvoicePaid ? ' has been paid' : ' has not been paid'}
                        {!invoice.IsPurchase && !invoice.InvoicePaid && !invoice.InvoiceSent ? ' or sent.' : '.'}
                    </BiggerMessageBar>
                </Grid>

                {this.renderPaymentMethod()}

                <Grid item xs={6} sm={4}>
                    <TextField readOnly label={'Total Amount'} value={'$' + Number(totals.totalWithGst).toFixed(2)} />
                </Grid>

                <Grid item xs={6} sm={4}>
                    <TextField readOnly label={'Amount Paid'} value={'$' + Number(totals.amountPaid).toFixed(2)} />
                </Grid>

                <Grid item xs={6} sm={4}>
                    <TextField readOnly label={'Amount Due'} value={'$' + Number(totals.amountDue).toFixed(2)} />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        readOnly
                        label={'Xero Status'}
                        value={invoice.XeroID ? invoice.XeroStatus : 'NOT EXPORTED'}
                    />
                </Grid>
            </Grid>
        );
    }

    renderPaymentHistory() {
        const { invoice } = this.props;

        return (
            /*
            * Let's trust the data we already have :)
            *
            <Query query={queryReadInvoicePayments} variables={{ ID: invoice.ID }}>
                {({ data, networkStatus, error, loading }) => {
                    if (loading) {
                        return (
                            <Fragment>
                                <Spinner /> ...fetching payments from Xero
                            </Fragment>
                        );
                    }
                    let { readInvoicePayments } = data || {};

                    if (readInvoicePayments) {
                        readInvoicePayments = JSON.parse(readInvoicePayments);

                        return (
                            <Grid container={true} spacing={16}>
                                <Grid item xs={12}>
                                    <ModalStepHeader
                                        header={invoice.IsPurchase ? 'Purchase Register' : 'Sales Register'}
                                    />
                                    <Table>
                                        <TableHeaderRow>
                                            <TableHeaderCell>Date</TableHeaderCell>
                                            <TableHeaderCell>Xero Reference</TableHeaderCell>
                                            <TableHeaderCell>Amount</TableHeaderCell>
                                        </TableHeaderRow>
                                        {readInvoicePayments.map((row, i) => {
                                            return (
                                                <TableRow key={i} pad={true}>
                                                    <TableCell>{row.Date}</TableCell>
                                                    <TableCell>{row.Reference}</TableCell>
                                                    <TableCell>{Number(row.Amount).toFixed(2)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                </Grid>
                            </Grid>
                        );
                    }

                    return null;
                }}
            </Query>*/
            <Grid container={true} spacing={16}>
                <Grid item xs={12}>
                    <ModalStepHeader header={invoice.IsPurchase ? 'Purchase Register' : 'Sales Register'} />

                    <Table>
                        <TableHeaderRow>
                            <TableHeaderCell>Reference</TableHeaderCell>
                            <TableHeaderCell>Type</TableHeaderCell>
                            <TableHeaderCell>Date</TableHeaderCell>
                            <TableHeaderCell>Amount</TableHeaderCell>
                        </TableHeaderRow>
                        {invoice.SalesRegisterTransactions.map((row, i) => {
                            return (
                                <TableRow key={i} pad={true}>
                                    <TableCell>{row.LegacyKey || row.BankTransNo || row.XeroReference}</TableCell>
                                    <TableCell>{row.TransType}</TableCell>
                                    <TableCell>{row.TransDate}</TableCell>
                                    <TableCell>{prettyPrice(Number(row.Nett) + Number(row.Gst))}</TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    {!(invoice.SalesRegisterTransactions && invoice.SalesRegisterTransactions.length) && (
                        <p>No transactions yet.</p>
                    )}
                </Grid>
            </Grid>
        );
    }

    handleUpdatePaymentMethod = () => {
        const { onSubmit, form } = this.props;
        const { paymentMethod } = this.state;
        form.setField({ InvoiceStatus: paymentMethod });
        if (onSubmit) onSubmit();
    };
}

/*
const queryReadInvoicePayments = gql`
    query($ID: ID) {
        readInvoicePayments(ID: $ID)
    }
`;
*/
const styles = () => ({
    root: {
        margin: '0',
        padding: '50px 30px 30px 30px',
        height: 'auto',
        alignContent: 'start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%'
    },

    columnRoot: {
        //width: '100%'
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    back: {
        float: 'left'
    }
});

export default withStyles(styles)(PaymentHistoryTab);
