import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import { getCheckoutList } from './CheckoutListConstants';
import { formatLocationDateTime } from '../MortuaryListConstants';
import TickIcon from '../../../component/icon/TickIcon';
import { getOfficeFromKey } from '../../../util/brands';
import { joinDefined, prettyDisposal } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';
import { InlineField } from '../../../component/form/Inline';
import BrandedTableKey from '../../../component/BrandedTableKey';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { shortDateTimeFromString } from '../../../util/date';
import moment from 'moment';
import GatedComponent from '../../../component/GatedComponent';
import cx from 'classnames';
import { PLACE_OF_SERVICE_TYPE_OPTIONS } from '../../funeral/details/DetailsConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import BrandDot from '../../../component/table/BrandDot';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../../component/ErrorFallback';

class CheckoutList extends Component {
    state = {
        mortuary: null
    };

    componentDidMount() {
        getCheckoutList().then(mortuary => this.setState({ mortuary }));
    }

    render() {
        const { mortuary } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Mortuary</span>
                    <span className={classes.header}>Checkout List</span>
                </Typography>
                <br />
                {isNullOrUndefined(mortuary) ? this.renderLoading() : this.renderBody()}
            </Fragment>
        );
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Paper className={classes.paper} elevation={0}>
                <LinearProgress />
            </Paper>
        );
    }

    renderKey() {
        return (
            <InlineField center wrap>
                <h5 className="key-heading">Legend:</h5>
                <BrandedTableKey />
            </InlineField>
        );
    }

    renderBody() {
        const { classes } = this.props;
        const { mortuary } = this.state;

        if (mortuary.error) return <div>failed to get checkout items: {mortuary.error}</div>;
        if (mortuary.items === null || mortuary.items.length === 0)
            return (
                <Paper className={classes.paper} elevation={0}>
                    <div>There are currently no checkout items.</div>
                </Paper>
            );

        return (
            <Paper className={classes.paper} elevation={0}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title">
                            Checkout List - {mortuary.items.length} item{mortuary.items.length === 1 ? '' : 's'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderKey()}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <GatedComponent
                        showComponentCode={'FM_ACCESS_Mortuary_View'}
                        isEnabledCode={'FM_ACCESS_Mortuary_Deceased_Checkout_View'}
                    >
                        {isEnabled => {
                            return (
                                <Grid item xs={12}>
                                    <Table
                                        className={cx(classes.table, 'table')}
                                        columns={[
                                            'Key',
                                            'Deceased',
                                            'Disposal',
                                            'Referee',
                                            'Viewing',
                                            'Service',
                                            'To be released to',
                                            'Released for checkout on'
                                        ]}
                                    >
                                        {mortuary.items
                                            .sort((a, b) => this.sortNearestEvent(a, b))
                                            .map((mortuaryItem, index) =>
                                                this.renderRow(mortuaryItem, index, isEnabled)
                                            )}
                                    </Table>
                                </Grid>
                            );
                        }}
                    </GatedComponent>
                </Grid>
            </Paper>
        );
    }

    firstViewing = item => {
        let latestDate = moment().add(100, 'years');
        let viewing = false;
        if (!!item.PlaceOfViewingRequired) {
            item.PlaceOfViewingItems.forEach(e => {
                const theDate = moment(e.Date + ' ' + e.Time);
                if (moment(theDate).isValid() && theDate < latestDate) {
                    latestDate = theDate;
                    viewing = e;
                }
            });
        }
        return viewing;
    };

    firstEvent = item => {
        const serviceObj = item.PlaceOfService.Type === 'Graveside' ? item.Disposal : item.PlaceOfService;
        const service = moment(serviceObj.Date + ' ' + serviceObj.Time);
        let latestDate = service.isValid() ? service : moment().add(100, 'years');
        let viewing = service.isValid() ? serviceObj : false;
        const theViewing = this.firstViewing(item);
        if (!!theViewing) {
            const theDate = moment(theViewing.Date + ' ' + theViewing.Time);
            if (moment(theDate).isValid() && theDate < latestDate) {
                viewing = theViewing;
            }
        }
        return viewing;
    };

    sortNearestEvent = (itemA, itemB) => {
        const eventA = this.firstEvent(itemA);
        const eventB = this.firstEvent(itemB);
        const timeA = (!!eventA && moment(eventA.Date + ' ' + eventA.Time)) || moment().add(100, 'years');
        const timeB = (!!eventB && moment(eventB.Date + ' ' + eventB.Time)) || moment().add(100, 'years');
        return timeA > timeB ? 1 : -1;
    };

    renderRow(mortuaryItem, index, canEdit) {
        const { classes } = this.props;

        const letterCode = getOfficeFromKey(mortuaryItem.LegacyKey);
        const rowStyle = 'row--' + letterCode.style;

        const isCremation = !!mortuaryItem.Disposal && mortuaryItem.Disposal.CrematedOrBuried === 'Cremated';
        const isGraveside =
            !!mortuaryItem.Disposal &&
            !!mortuaryItem.PlaceOfService &&
            mortuaryItem.PlaceOfService.Type === 'Graveside';
        const serviceObj = !!isGraveside ? mortuaryItem.Disposal : mortuaryItem.PlaceOfService;
        const serviceType = PLACE_OF_SERVICE_TYPE_OPTIONS.find(e => e.value === serviceObj.Type);

        const needsReferee =
            !!isCremation &&
            !(!!mortuaryItem.Certification && mortuaryItem.Certification.TypeOfBdmDeathCertificate === 'Coroner');

        const firstViewing = this.firstViewing(mortuaryItem);
        const releaseTo = serviceObj => {
            const service = moment(serviceObj.Date + ' ' + serviceObj.Time);
            let latestDate = service.isValid() ? service : moment('2100-01-01');
            let viewing = serviceObj;
            const theViewing = firstViewing;
            if (!!theViewing) {
                const theDate = moment(theViewing.Date + ' ' + theViewing.Time);
                if (moment(theDate).isValid() && theDate < latestDate) {
                    viewing = theViewing;
                }
            }
            return viewing;
        };
        const { color, label } = getOfficeFromKey(mortuaryItem.LegacyKey);
        return (
            <TableRow
                pad
                key={index}
                className={cx('row', rowStyle)}
                onClick={canEdit ? () => this.viewMortuaryItem(mortuaryItem, canEdit) : undefined}
            >
                <TableCell className={'td'} dataLabel={'Key'}>
                    <BrandDot dotColour={color} title={label} label={mortuaryItem.LegacyKey} />
                </TableCell>
                <TableCell className={'td'} dataLabel={'Deceased'}>
                    {joinDefined(
                        [(mortuaryItem.Surname || '').toUpperCase(), mortuaryItem.FirstName, mortuaryItem.MiddleName],
                        ' '
                    )}
                </TableCell>
                <TableCell className={'td td-center'} dataLabel={'Disposal'}>
                    {prettyDisposal(!!mortuaryItem.Disposal && mortuaryItem.Disposal.CrematedOrBuried)}
                </TableCell>
                <TableCell className={'td'} dataLabel={'Referee'}>
                    <span className={classes.iconContainer}>
                        {isCremation ? (
                            !!needsReferee ? (
                                !!isRelatedObjectDefined(mortuaryItem.Certification, 'Referee') ? (
                                    !!mortuaryItem.RefereeCremationCheck ? (
                                        <TickIcon className={classes.icon} title={'Completed'} />
                                    ) : (
                                        'Assigned'
                                    )
                                ) : (
                                    'NOT ASSIGNED'
                                )
                            ) : (
                                'Not Required'
                            )
                        ) : (
                            '-'
                        )}
                    </span>
                </TableCell>
                <TableCell className={'td'} dataLabel={'Viewing'}>
                    {firstViewing ? (
                        <div>
                            <strong>{firstViewing.Type}</strong>
                            <div>{shortDateTimeFromString(formatLocationDateTime(firstViewing))}</div>
                        </div>
                    ) : (
                        <span className={classes.iconContainer}>-</span>
                    )}
                </TableCell>
                <TableCell className={'td'} dataLabel={'Service'}>
                    <strong>{serviceType ? serviceType.label : 'TBC'}</strong>
                    <div>{shortDateTimeFromString(formatLocationDateTime(serviceObj))}</div>
                </TableCell>
                <TableCell className={'td'} dataLabel={'To be released to'}>
                    {isRelatedObjectDefined(serviceObj, 'Location') ? releaseTo(serviceObj).LocationFlattened : 'TBC'}
                </TableCell>
                <TableCell className={'td'} dataLabel={'Released for checkout on'}>
                    {shortDateTimeFromString(mortuaryItem.DatetimeReadyForMortuaryCheckout)}
                </TableCell>
            </TableRow>
        );
    }

    viewMortuaryItem({ ID, LegacyKey }, canEdit) {
        const { history } = this.props;

        if (canEdit) {
            history.push({
                pathname: `/mortuary/checkout/${LegacyKey}/${ID}`
            });
        }
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    dotList: {
        minHeight: 16,
        width: 16,
        display: 'inline-block',
        position: 'absolute',
        left: -18,
        margin: '0 -10px 0 0',
        padding: '0',
        listStyle: 'none',
        '& > :not(:first-child)': {
            marginTop: 2
        }
    },
    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },
    icon: {
        fontSize: 16,
        // color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& .td-center': {
            textAlign: 'center'
        }
    }
});

export default withErrorBoundary(compose(withSnackbarMessage, withStyles(styles))(CheckoutList), {
    fallback: ErrorFallback
});
