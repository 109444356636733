import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';

const CateringStaffAutoComplete = ({ category, className, name, clearOnSelect = true, ...props }) => {
    return (
        <GqlAutocomplete
            className={className}
            readAllQuery={readAllCateringStaff}
            placeholder="Choose specific person..."
            labelFieldFunc={e => e.Name}
            multiple={true}
            name={name}
            clearOnSelect={clearOnSelect}
            {...props}
        />
    );
};

const readAllCateringStaff = gql`
    fragment CateringStaffFragment on FuneralManagerCateringStaff {
        ID
        Name
    }

    query ReadCaterers($contains: String) {
        readCateringStaffs(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...CateringStaffFragment
                }
            }
        }
    }
`;

export default CateringStaffAutoComplete;
