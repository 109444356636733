import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles/index';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

class AudioSampler extends React.Component {
    state = {
        isPlaying: false,
        isValid: true
    };
    audio = new Audio();
    componentDidMount = () => {
        this.audio.onerror = e => {
            this.setState({ isValid: false });
        };
        if (this.props.filename && 1 === 2) {
            // TODO: need to adjust all the mp3 file references... play sample for now.
            this.audio.src = this.props.filename;
        } else if (this.props.filename) {
            this.audio.src = 'https://archive.org/download/testmp3testfile/mpthreetest.mp3';
        } else {
            this.setState({ isValid: false });
        }
    };

    togglePlay = e => {
        if (this.state.isValid) {
            if (this.state.isPlaying) {
                this.audio.pause();
                this.setState({ isPlaying: false });
            } else {
                this.audio.currentTime = 0;
                this.audio.play();
                this.setState({ isPlaying: true });
            }
        }
    };

    render() {
        const { classes, artist, title } = this.props;
        return (
            <Fragment>
                <IconButton onClick={this.togglePlay}>
                    {this.state.isValid ? (
                        this.state.isPlaying ? (
                            <Icon>pause_circle_outline</Icon>
                        ) : (
                            <Icon>play_circle_outline</Icon>
                        )
                    ) : (
                        <Icon title={'File is missing or not specified.'}>error_outline</Icon>
                    )}
                </IconButton>
                <span className={classes.infoText}>&ldquo;{title}&rdquo;</span>
                <span className={classes.infoText}>&nbsp;-&nbsp;</span>
                <span className={classes.infoText}>{artist}</span>
            </Fragment>
        );
    }
}

const styles = ({ palette, typography, sizes }) => ({
    infoText: {
        fontSize: '0.875rem',
        maxWidth: '33%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        'white-space': 'nowrap',
        verticalAlign: 'middle'
    }
});

export default withRouter(withStyles(styles)(AudioSampler));
