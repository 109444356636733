import gql from 'graphql-tag';

export default gql`
    fragment AdminSummary on Funeral {
        ID
        AdminChecks {
            ID
            Title
            TabURLSegment
            OptionsFlag
            Active
            _join {
                FuneralAdminCheck {
                    ID
                    Task {
                        ID
                        Title
                        Description
                        Comment
                        RequiredAction
                        Completed
                        AssignedMembers {
                            ID
                            FirstName
                            Surname
                        }
                    }
                    Checked
                    MortuaryMember {
                        ID
                        FirstName
                        Surname
                    }
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                }
            }
        }
        AdminChecksComplete
        Coordinators {
            ID
            Allocation
            Member {
                ID
                Surname
                FirstName
                Email
            }
        }
    }
`;
