import gql from 'graphql-tag';
import EnquiryFragment from './EnquiryFragment';
import * as moment from 'moment';
import { getAllOffices } from '../../util/brands';
import { getClient } from '../../apollo';
import { cleanUpQuoteConnections } from './QuotesConstants';
import { isEmailValid, messages, validationHelper } from '../../util/validation';
import { DATE_OF_DEATH_OPTION } from '../funeral/deceased/DeceasedConstants';
import { isContactDefined } from '../../util/bookable';
import { deleteTypeName, isNullOrUndefined } from '../../util/objects';
import cloneDeep from 'lodash.clonedeep';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../funeral/certification/CertificationConstants';

export const TitleOptions = [
    { label: 'Ms', value: 'Ms' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Fr', value: 'Fr' },
    { label: 'Mr', value: 'Mr' },
    { label: 'Rev', value: 'Rev' }
];

export const ENQUIRY_TYPES = [
    { value: 'PHONE', label: 'Phone Quotation' },
    { value: 'PRENEED', label: 'Pre-need' },
    { value: 'ATNEED', label: 'At-need' }
    // { value: 'PLAQUE', label: 'Plaque' }
];

export const LOCATIONS = [
    { value: 'Wollongong & The Illawarra', label: 'Wollongong & The Illawarra' },
    { value: 'Sydney & Surrounding Suburbs', label: 'Sydney & Surrounding Suburbs' },
    { value: 'Macarthur & The Southern Highlands', label: 'Macarthur & The Southern Highlands' },
    { value: 'Nowra & The Shoalhaven', label: 'Nowra & The Shoalhaven' },
    { value: 'South Coast NSW', label: 'South Coast NSW' },
    { value: 'Rest of NSW', label: 'Rest of NSW' }
];

export const PreNeedTypes = [
    { label: 'Prearrangement', value: 'Prearrangment' },
    { label: 'Prepaid', value: 'Prepaid' },
    { label: 'Bond', value: 'Bond' },
    { label: 'Imminent', value: 'Imminent' }
];

export const PhoneTypes = [
    { label: 'Pricing Quote', value: 'Pricing' },
    { label: 'Comparison Quote', value: 'Comparison' },
    { label: 'Time/Place Enquiry', value: 'TimePlace' },
    { label: 'Service Enquiry', value: 'Service' },
    { label: 'Mistaken Enquiry', value: 'Mistaken' },
    { label: 'Established Service Enquiry', value: 'Established' }
];

export const createEnquiry = () => {
    return gql`
        ${EnquiryFragment}
        mutation CreateEnquiry($input: CreateEnquiryInput!) {
            createEnquiry(input: $input) {
                ...Enquiry
            }
        }
    `;
};

export const updateEnquiry = () => {
    return gql`
        ${EnquiryFragment}
        mutation UpdateEnquiry($input: UpdateEnquiryInput!) {
            updateEnquiry(input: $input) {
                ...Enquiry
            }
        }
    `;
};

export const queryEnquiry = () => {
    return gql`
        ${EnquiryFragment}
        query QueryEnquiry($id: ID!) {
            readOneEnquiry(ID: $id) {
                ...Enquiry
            }
        }
    `;
};

export const viewCalendar = () => {
    const date = moment(new Date());
    const url = `/calendar/${date.format('YYYY')}${date.format('MM')}${date.format('DD')}`;
    const win = window.open(url, '_blank');
    win.focus();
};

export const createFuneralMutation = gql`
    mutation CreateFuneral($input: CreateFuneralInput!) {
        createFuneral(input: $input) {
            ID
            LegacyKey
        }
    }
`;

export const createOnlineOrderMutation = gql`
    mutation CreateOnlineOrder($input: CreateOnlineOrderInput!) {
        createOnlineOrder(input: $input) {
            ID
            LegacyKey
            FAOrderID
            FAID
        }
    }
`;

export const createOnlineOrderAsync = async enquiryForm => {
    const officeId = parseInt(enquiryForm.getField('Office.ID') || enquiryForm.getField('OfficeID'), 10);
    const matchingOffice = getAllOffices().find(obj => Number(obj.ID) === Number(officeId));
    if (isNullOrUndefined(matchingOffice)) {
        console.error('CANT FIND OFFICE!', officeId);
        throw new Error('Matching office not found');
    }
    const rel = enquiryForm.getField('RelationshipToDeceased');
    const relation = !!rel && APPLICANT_RELATIONSHIP_OPTIONS.find(e => e.value.toLowerCase() === rel.toLowerCase());
    if (1 === 2) return relation; // just a stub to keep compiler happy ;)
    const variables = {
        input: {
            OrderSource: 'Funeral Manager Enquiry',
            OrderType: 'Funeral',
            FirstName: enquiryForm.getField('EnquirerGivenName'),
            MiddleNames: enquiryForm.getField('EnquirerMiddleName'),
            Surname: enquiryForm.getField('EnquirerSurname'),
            Phone: enquiryForm.getField('EnquirerPhone'),
            Email: enquiryForm.getField('EnquirerEmail'),
            ServiceType: 'TODO',
            LocationArea: 'TODO',
            PackageName: 'TODO',

            Funeral: {
                ID: enquiryForm.getField('Funeral.ID'),
                EnquiryID: enquiryForm.getField('ID'),
                OriginatingOffice: matchingOffice.LegacyKey,
                OfficeID: officeId,
                ApplicantGivenName: enquiryForm.getField('EnquirerGivenName'),
                ApplicantMiddleName: enquiryForm.getField('EnquirerMiddleName'),
                ApplicantSurname: enquiryForm.getField('EnquirerSurname'),

                Surname: enquiryForm.getField('Surname'),
                Gender: enquiryForm.getField('Gender'),
                DateOfBirth: enquiryForm.getField('DateOfBirth'),
                FirstName: enquiryForm.getField('GivenName'),
                MiddleName: enquiryForm.getField('MiddleName'),
                ResidentialAddress1: enquiryForm.getField('ResidentialAddress1'),
                ResidentialAddress2: enquiryForm.getField('ResidentialAddress2'),
                ResidentialTown: enquiryForm.getField('ResidentialSuburb'),
                ResidentialState: enquiryForm.getField('ResidentialState'),
                ResidentialCountry: enquiryForm.getField('ResidentialCountry'),
                PostCode: enquiryForm.getField('ResidentialPostcode'),

                DateOfDeathType: enquiryForm.getField('DateOfDeathType'),
                DateOfDeath1: enquiryForm.getField('DateOfDeath1'),
                DateOfDeath2: enquiryForm.getField('DateOfDeath2'),
                UseResidentialAddress: enquiryForm.getField('DeathUseResidentialAddress'),
                PlaceOfDeathType: enquiryForm.getField('PlaceOfDeathType'),
                PlaceOfDeathID:
                    (enquiryForm.getField('PlaceOfDeathType') === 'AddressBook' &&
                        isContactDefined(enquiryForm.getField('PlaceOfDeath')) &&
                        enquiryForm.getField('PlaceOfDeath.ID')) ||
                    null,

                PlaceOfDeathResidentialAddress:
                    (enquiryForm.getField('PlaceOfDeathType') === 'Residential' &&
                        enquiryForm.getField('PlaceOfDeathResidentialAddress')) ||
                    null,
                DeathOccuredInHospital:
                    enquiryForm.getField('PlaceOfDeathType') === 'AddressBook' &&
                    isContactDefined(enquiryForm.getField('PlaceOfDeath')) &&
                    enquiryForm.getField('PlaceOfDeath.State') === 'NSW',

                UsePlaceOfDeathAddress: enquiryForm.getField('TransferUsePlaceOfDeathAddress'),
                TransferFromType: enquiryForm.getField('TransferFromType'),
                TransferFromID:
                    (enquiryForm.getField('TransferFromType') === 'AddressBook' &&
                        isContactDefined(enquiryForm.getField('TransferFrom')) &&
                        enquiryForm.getField('TransferFrom.ID')) ||
                    null,
                TransferFromResidentialAddress:
                    (enquiryForm.getField('TransferFromType') === 'Residential' &&
                        enquiryForm.getField('TransferFromResidentialAddress')) ||
                    null,
                DateTransferredToMortuary: null,
                ReadyForMortuary: null,
                DatetimeReadyForMortuaryCheckout: null,
                MortuaryActionReturnedReason: null,
                DatetimeOfMortuaryCheckout: null,
                BillingNotes: enquiryForm.getField('OldNotes')
                    ? 'Enquiry notes: \n' + enquiryForm.getField('OldNotes')
                    : null,
                Disposal: {
                    CrematedOrBuried: enquiryForm.getField('CrematedOrBuried')
                }
            }
        }
    };

    return await getClient().mutate({
        mutation: createOnlineOrderMutation,
        variables
    });
};

export const createFuneralAsync = async enquiryForm => {
    const officeId = parseInt(enquiryForm.getField('Office.ID') || enquiryForm.getField('OfficeID'), 10);
    const matchingOffice = getAllOffices().find(obj => Number(obj.ID) === Number(officeId));
    if (isNullOrUndefined(matchingOffice)) {
        console.error('CANT FIND OFFICE!', officeId);
        throw new Error('Matching office not found');
    }
    const rel = enquiryForm.getField('RelationshipToDeceased');
    const relation = !!rel && APPLICANT_RELATIONSHIP_OPTIONS.find(e => e.value.toLowerCase() === rel.toLowerCase());
    if (1 === 2) return relation; // just a stub to keep compiler happy ;)
    const variables = {
        input: {
            EnquiryID: enquiryForm.getField('ID'),
            OriginatingOffice: matchingOffice.LegacyKey,
            OfficeID: officeId,

            ApplicantFirstName: enquiryForm.getField('EnquirerFirstName'),
            ApplicantMiddleName: enquiryForm.getField('EnquirerMiddleName'),
            ApplicantSurname: enquiryForm.getField('EnquirerSurname'),
            /*Informant: {
                InformantsName: joinDefined(
                    [
                        enquiryForm.getField('EnquirerGivenName'),
                        enquiryForm.getField('EnquirerMiddleName'),
                        enquiryForm.getField('EnquirerSurname')
                    ],
                    ' '
                ),
                FirstName: enquiryForm.getField('EnquirerGivenName'),
                MiddleName: enquiryForm.getField('EnquirerMiddleName'),
                Surname: enquiryForm.getField('EnquirerSurname'),

                RelationshipToDeceased: relation && relation.value,

                PostalAddressLine1: enquiryForm.getField('EnquirerPostalAddress1'),
                PostalAddressLine2: enquiryForm.getField('EnquirerPostalAddress2'),
                PostalSuburb: enquiryForm.getField('EnquirerPostalSuburb'),
                PostalState: enquiryForm.getField('EnquirerPostalState'),
                PostalPostcode: enquiryForm.getField('EnquirerPostalPostcode'),
                PostalSameAsResidential: enquiryForm.getField('PostalUseResidentialAddress'),
                ResidentialAddress1: joinDefined(
                    [
                        enquiryForm.getField('EnquirerResidentialAddress.AddressLine1'),
                        enquiryForm.getField('EnquirerResidentialAddress.AddressLine2')
                    ],
                    ', '
                ),
                ResidentialState: enquiryForm.getField('EnquirerResidentialAddress.State'),
                SuburbCityAust: enquiryForm.getField('EnquirerResidentialAddress.Suburb'),
                ResidentialPostcode: enquiryForm.getField('EnquirerResidentialAddress.Postcode')
            },*/

            Surname: enquiryForm.getField('Surname'),
            Gender: enquiryForm.getField('Gender'),
            DateOfBirth: enquiryForm.getField('DateOfBirth'),
            FirstName: enquiryForm.getField('GivenName'),
            MiddleName: enquiryForm.getField('MiddleName'),
            ResidentialAddress1: enquiryForm.getField('ResidentialAddress1'),
            ResidentialAddress2: enquiryForm.getField('ResidentialAddress2'),
            ResidentialTown: enquiryForm.getField('ResidentialSuburb'),
            ResidentialState: enquiryForm.getField('ResidentialState'),
            ResidentialCountry: enquiryForm.getField('ResidentialCountry'),
            PostCode: enquiryForm.getField('ResidentialPostcode'),

            DateOfDeathType: enquiryForm.getField('DateOfDeathType'),
            DateOfDeath1: enquiryForm.getField('DateOfDeath1'),
            DateOfDeath2: enquiryForm.getField('DateOfDeath2'),
            UseResidentialAddress: enquiryForm.getField('DeathUseResidentialAddress'),
            PlaceOfDeathType: enquiryForm.getField('PlaceOfDeathType'),
            PlaceOfDeathID:
                (enquiryForm.getField('PlaceOfDeathType') === 'AddressBook' &&
                    isContactDefined(enquiryForm.getField('PlaceOfDeath')) &&
                    enquiryForm.getField('PlaceOfDeath.ID')) ||
                null,

            PlaceOfDeathResidentialAddress:
                (enquiryForm.getField('PlaceOfDeathType') === 'Residential' &&
                    enquiryForm.getField('PlaceOfDeathResidentialAddress')) ||
                null,
            DeathOccuredInHospital:
                enquiryForm.getField('PlaceOfDeathType') === 'AddressBook' &&
                isContactDefined(enquiryForm.getField('PlaceOfDeath')) &&
                enquiryForm.getField('PlaceOfDeath.State') === 'NSW',

            UsePlaceOfDeathAddress: enquiryForm.getField('TransferUsePlaceOfDeathAddress'),
            TransferFromType: enquiryForm.getField('TransferFromType'),
            TransferFromID:
                (enquiryForm.getField('TransferFromType') === 'AddressBook' &&
                    isContactDefined(enquiryForm.getField('TransferFrom')) &&
                    enquiryForm.getField('TransferFrom.ID')) ||
                null,
            TransferFromResidentialAddress:
                (enquiryForm.getField('TransferFromType') === 'Residential' &&
                    enquiryForm.getField('TransferFromResidentialAddress')) ||
                null,
            DateTransferredToMortuary: null,
            ReadyForMortuary: null,
            DatetimeReadyForMortuaryCheckout: null,
            MortuaryActionReturnedReason: null,
            DatetimeOfMortuaryCheckout: null,
            BillingNotes: enquiryForm.getField('OldNotes')
                ? 'Enquiry notes: \n' + enquiryForm.getField('OldNotes')
                : null,
            Disposal: {
                CrematedOrBuried: enquiryForm.getField('CrematedOrBuried')
            }
        }
    };

    return await getClient().mutate({
        mutation: createFuneralMutation,
        variables
    });
};

export const createPrearrangementMutation = gql`
    mutation CreatePrearrangement($input: CreatePrearrangementInput!) {
        createPrearrangement(input: $input) {
            ID
            LegacyKey
        }
    }
`;

export const createPrearrangementAsync = async (enquiryForm, acceptedQuote) => {
    const officeId = parseInt(enquiryForm.getField('Office.ID') || enquiryForm.getField('OfficeID'), 10);
    const matchingOffice = getAllOffices().find(obj => Number(obj.ID) === Number(officeId));
    if (isNullOrUndefined(matchingOffice)) {
        console.error('CANT FIND OFFICE!', officeId);
        throw new Error('Matching office not found');
    }
    const variables = {
        input: {
            EnquiryID: enquiryForm.getField('ID'),
            OriginatingOffice: matchingOffice.LegacyKey,
            OfficeID: officeId,
            Type: enquiryForm.getField('PreneedType'),
            Surname: enquiryForm.getField('Surname'),
            Gender: enquiryForm.getField('Gender'),
            DateOfBirth: enquiryForm.getField('DateOfBirth'),
            FirstName: enquiryForm.getField('GivenName'),
            MiddleName: enquiryForm.getField('MiddleName'),
            ResidentialAddress1: enquiryForm.getField('ResidentialAddress1'),
            ResidentialAddress2: enquiryForm.getField('ResidentialAddress2'),
            ResidentialTown: enquiryForm.getField('ResidentialSuburb'),
            ResidentialState: enquiryForm.getField('ResidentialState'),
            ResidentialCountry: enquiryForm.getField('ResidentialCountry'),
            PostCode: enquiryForm.getField('ResidentialPostcode'),
            BillingNotes: enquiryForm.getField('Notes') ? 'Enquiry notes: ' + enquiryForm.getField('Notes') : null,
            QuoteID: !!acceptedQuote ? acceptedQuote.ID : null
        }
    };

    return await getClient().mutate({
        mutation: createPrearrangementMutation,
        variables
    });
};

export const cleanUpConnections = enquiry => {
    if (enquiry && enquiry.Quotes) {
        for (let x = 0; x < enquiry.Quotes.length; x++) {
            cleanUpQuoteConnections(enquiry.Quotes[x]);
        }
    }
};

/**
 * validation definition for enquiry
 */
export const validation = {
    enquiry: {
        required: [
            'EnquiryType',
            'EnquirerGivenName',
            'EnquirerSurname',
            'EnquirerTitle',
            'RelationshipToDeceased',
            'EnquirerPhone',
            'EnquirerResidentialAddress',
            'Gender',
            'Office',
            'Situation'
        ],

        onValidate: {
            GivenName: (newValue, persistedValue, hasValue, getField) => {
                const isSelf = getField('RelationshipToDeceased') === 'self';
                if (!!isSelf || !!newValue || !!persistedValue) return validationHelper.ok();

                return validationHelper.required();
            },
            Surname: (newValue, persistedValue, hasValue, getField) => {
                const isSelf = getField('RelationshipToDeceased') === 'self';
                if (!!isSelf || !!newValue || !!persistedValue) return validationHelper.ok();

                return validationHelper.required();
            },
            DateOfBirth: (newValue, persistedValue, hasValue, getField) => {
                const dobUnknown = getField('UnknownDOB') === true;
                if (dobUnknown || hasValue) return validationHelper.ok();

                return validationHelper.required();
            },
            EnquirerEmail: (newValue, persistedValue, hasValue, getField) => {
                if (getField('HiddenUnknownEmailField') === true) return validationHelper.ok();

                if (!hasValue) return validationHelper.required();

                return isEmailValid(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required(messages.invalidEmail);
            },
            DateOfDeathType: (newValue, persistedValue, hasValue, getField) => {
                const situation = getField('Situation');
                if (situation !== 'dead' || hasValue) return validationHelper.ok();

                return validationHelper.required();
            },
            DateOfDeath1: (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('DateOfDeathType');
                if (
                    getField('Situation') !== 'dead' ||
                    dateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value ||
                    hasValue
                )
                    return validationHelper.ok();

                return validationHelper.required();
            },
            DateOfDeath2: (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('DateOfDeathType');
                if (
                    getField('Situation') !== 'dead' ||
                    dateOfDeathType !== DATE_OF_DEATH_OPTION.BETWEEN.value ||
                    hasValue
                )
                    return validationHelper.ok();

                return validationHelper.required();
            }
        }
    }
};

export const copyEnquiry = ({
    ID,
    BillingNotes, // Prearrangement only
    ClassName,
    DateOfBirth,
    FirstName, // Prearrangement only
    Gender,
    GivenName, // Enquiry only
    LegacyDocuments,
    LegacyKey,
    MiddleName,
    Notes, // Enquiry only
    Office,
    PostCode, // Prearrangement only
    Prearrangement, // Enquiry only
    PriorEnquiry, // Enquiry only
    Quote, // Enquiry only
    ResidentialAddress1,
    ResidentialAddress2,
    ResidentialPostcode,
    ResidentialState,
    ResidentialCountry,
    ResidentialSuburb, // Enquiry only
    ResidentialTown, // Prearrangement only
    Surname,
    UnknownDOB
}) => {
    if (ClassName === 'FuneralManagerPrearrangement') {
        PriorEnquiry = { ID: Quote.Enquiry.ID, LegacyKey: Quote.Enquiry.LegacyKey };
        Prearrangement = { ID, LegacyKey };
        LegacyDocuments = [].concat(LegacyDocuments, Quote && Quote.Enquiry.LegacyDocuments);
    } else if (ClassName === 'FuneralManagerEnquiry') {
        Prearrangement = { ID: Prearrangement.ID, LegacyKey: Prearrangement.LegacyKey };
        PriorEnquiry = { ID, LegacyKey };
    }
    const notes = Notes || BillingNotes;
    const copied = {
        DateOfBirth,
        Gender,
        GivenName: GivenName || FirstName,
        LegacyDocuments,
        MiddleName,
        Notes: notes ? 'Copied previous notes...\n\n' + notes : null,
        Office,
        PriorEnquiry: PriorEnquiry.ID && Number(PriorEnquiry.ID) > 0 ? PriorEnquiry : null,
        ResidentialAddress1,
        ResidentialAddress2,
        ResidentialPostcode: ResidentialPostcode || PostCode,
        ResidentialState,
        ResidentialCountry,
        ResidentialSuburb: ResidentialSuburb || ResidentialTown,
        Surname,
        Prearrangement: Prearrangement.ID && Number(Prearrangement.ID) > 0 ? Prearrangement : null,
        UnknownDOB
    };

    return deleteTypeName(cloneDeep(copied));
};
