import gql from 'graphql-tag';

export default gql`
    fragment Application on Funeral {
        Application {
            ID
            CertificateRequired
            Quantity
        }
    }
`;
