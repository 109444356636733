import gql from 'graphql-tag';
import { getClient } from '../../../apollo';
import WebServiceLogs from '../../fragments/WebServiceLogs';

const readFunerals = gql`
    ${WebServiceLogs}

    query readFunerals($id: ID!) {
        readFunerals(id: $id, pushLifeLink: true) {
            edges {
                node {
                    ID
                    SendToBdmLifeLinkField {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ExportStatus
                    ExportId
                    DeathCertificateStatus
                    DeathCertificateNumber
                    ExportDate
                    ...WebServiceLogs
                }
            }
        }
    }
`;

const testReadFunerals = gql`
    ${WebServiceLogs}

    query testReadFunerals($id: ID!) {
        readFunerals(id: $id, pushTestLifeLink: true) {
            edges {
                node {
                    ID
                    SendToBdmLifeLinkField {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ExportStatus
                    ExportId
                    DeathCertificateStatus
                    DeathCertificateNumber
                    ExportDate
                    ...WebServiceLogs
                }
            }
        }
    }
`;

const submitToLifeLink = async id => {
    const asyncQuery = await getClient().query({
        query: readFunerals,
        fetchPolicy: 'network-only',
        variables: { id }
    });

    return asyncQuery.data;
};

const testSubmitToLifeLink = async id => {
    const asyncQuery = await getClient().query({
        query: testReadFunerals,
        fetchPolicy: 'network-only',
        variables: { id }
    });

    return asyncQuery.data;
};

export { submitToLifeLink, testSubmitToLifeLink };
