import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <g>
            <ellipse ry="56" rx="56" cy="80" cx="256" fill="currentColor" />
            <ellipse ry="56" rx="56" cy="256" cx="256" fill="currentColor" />
            <ellipse ry="56" rx="56" cy="432" cx="256" fill="currentColor" />
        </g>
    </SvgIcon>
);
