import React, { Component } from 'react';
import { ButtonBase, Card, Icon, Typography, withStyles } from '@material-ui/core';
import ModalStepTitle from '../form/ModalStepTitle';
import ModalStepSubHeader from '../form/ModalStepSubHeader';
import PrimaryButton, { OutlineButton } from '../form/PrimaryButton';
import Spinner from '../Spinner';
import CloseIcon from '../icon/CloseIcon';
import Cart from './Cart';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import { getDefaultVariationId, ProductMetadata } from '../../util/products';
import Hidden from '@material-ui/core/Hidden';

const SCROLL_LOAD_TOLERANCE = 40;

class SelectionTab extends Component {
    productContainer = React.createRef();

    render() {
        const {
            classes,
            loading,
            onConfirm,
            onCancel,
            onNext,
            onBack,
            stepLabel,
            stepTitle,
            stepSubtitle,
            config,
            metadata,
            officeID
        } = this.props;

        const allProductIds = metadata.limitProductIds || config.categoryIds[metadata.queryKey];
        const productIds = allProductIds.filter(e => !!config.productMap[e] && !!config.productMap[e].purchase);

        return (
            <div className={classes.root}>
                <div className={classes.contentLeft}>
                    <div className={classes.header}>
                        <ModalStepTitle header={stepLabel} title={stepTitle} />
                        {!!stepSubtitle && <ModalStepSubHeader header={stepSubtitle} />}
                    </div>
                    <div className={classes.content}>{this.renderProductList(productIds)}</div>
                    <div className={classes.footer}>
                        <OutlineButton onClick={() => onCancel()} color="primary" disabled={loading}>
                            <CloseIcon />
                            <Hidden xsDown>Cancel</Hidden>
                        </OutlineButton>
                        {onBack && (
                            <PrimaryButton disabled={loading} onClick={() => onBack()}>
                                <Icon>chevron_left</Icon>
                                <Hidden xsDown>Previous Step</Hidden>
                            </PrimaryButton>
                        )}
                        {onNext && (
                            <PrimaryButton onClick={() => onNext()} disabled={loading} className={classes.rightButton}>
                                <Hidden xsDown>Next Step</Hidden>
                                <Icon>chevron_right</Icon>
                            </PrimaryButton>
                        )}
                    </div>
                </div>
                <Hidden smDown>
                    <div className={classes.contentRight}>
                        <Cart cart={this.props.cart} config={config} onConfirm={onConfirm} officeID={officeID} />
                    </div>
                </Hidden>
            </div>
        );
    }

    renderProductList(productIds) {
        const { classes, loading } = this.props;
        return (
            <div
                className={cx(classes.productListContainer, !loading && classes.containerLoaded)}
                onScroll={this.checkLoadMore}
                ref={this.productContainer}
            >
                {loading ? (
                    <Spinner className={classes.spinner} />
                ) : productIds.length ? (
                    <div className={classes.productList}>
                        {productIds.map(this.renderProduct)}
                        {this.checkLoadMore()}
                    </div>
                ) : (
                    <span className={classes.noResults}>No results</span>
                )}
            </div>
        );
    }

    renderProduct = id => {
        const { classes, config } = this.props;
        const product = config.productMap[id];
        if (!product) {
            console.error(`failed to map product ${id} in `, config.productMap);
            return null;
        }

        return (
            <ButtonBase key={product.id} onClick={() => this.onAddToCart(product.id)} className={classes.productItem}>
                {/* span is for the hover border */}
                <span />
                <Card className={classes.productItemCard}>
                    <div style={{ backgroundImage: `url(${product.image}` }} className={classes.productItemImage} />
                    <Typography className={classes.productItemName} align="center">
                        {product.title}
                    </Typography>
                </Card>
            </ButtonBase>
        );
    };

    onAddToCart(productId) {
        const { config, cart, metadata } = this.props;
        const { maxProductTypeQuantity } = metadata;
        const urlSegment = config.productMap[productId].urlSegment;

        //check if there's a purchase limit
        if (maxProductTypeQuantity > 0) {
            const purchases = cart
                .map((x, index) => {
                    const obj = config.productMap[x.productId] || { urlSegment: '' };
                    return { category: obj.urlSegment, index, quantity: x.quantity };
                })
                .filter(x => x.category === metadata.queryKey && x.quantity > 0);

            //check if adding one will go over the limit

            if (purchases.length + 1 > maxProductTypeQuantity) {
                const excessProduct = cart[purchases[0].index];
                if (excessProduct.onRemove) excessProduct.onRemove();
                cart.splice(purchases[0].index, 1);
            }
        }

        const key = cart.length > 0 ? Math.max(...cart.map(x => x.key)) + 1 : 0;
        let cartItem = {
            key,
            productId,
            quantity: 1,
            returns: 0,
            comment: null,
            urlSegment: urlSegment,
            variationId: getDefaultVariationId(productId, config)
        };

        limitOtherProducts(cartItem, config);

        cart.push(cartItem);

        this.forceUpdate();
    }

    checkLoadMore = debounce(() => {
        const { loading, loadMore } = this.props;
        if (loading) return;
        if (!this.productContainer.current) return;
        const { scrollTop, clientHeight, scrollHeight } = this.productContainer.current;
        if (scrollTop + clientHeight > scrollHeight - SCROLL_LOAD_TOLERANCE) {
            loadMore();
        }
    }, 100);
}

export const limitOtherProducts = (cartItem, config) => {
    const product = config.productMap[cartItem.productId];
    if (!product) return;
    const { urlSegment } = product;
    const metadataProperty = Object.keys(ProductMetadata).find(x => ProductMetadata[x].queryKey === urlSegment);
    const metadata = ProductMetadata[metadataProperty];

    //limit products of a certain type if the current product has related products
    if (product && metadata.relatedProductsKey && product.relatedProducts) {
        const targetMetadataProperty = Object.keys(ProductMetadata).find(
            x => ProductMetadata[x].queryKey === metadata.relatedProductsKey
        );
        const targetMetadata = ProductMetadata[targetMetadataProperty];
        targetMetadata.limitProductIds = product.relatedProducts.map(x => x.id);
        cartItem.onRemove = () => {
            delete targetMetadata.limitProductIds;
        };
    }
};

const styles = ({ spacing, palette, typography, breakpoints }) => ({
    root: {
        width: '100%',
        display: 'flex'
    },

    content: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'auto'
    },

    contentLeft: {
        height: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },

    contentRight: {
        height: '100%',
        width: 512,
        maxWidth: '40vw',
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: `1px solid ${palette.divider}`,
        background: palette.common.white,
        [breakpoints.down(breakpoints.custom.dt)]: {
            margin: 1
        },
        borderTopRightRadius: spacing.unit,
        borderBottomRightRadius: spacing.unit
    },

    header: {
        padding: '40px 60px 10px 60px',
        [breakpoints.down('xs')]: {
            padding: spacing.unit,
            '& h1': {
                fontSize: 16,
                display: 'inline'
            }
        }
    },

    footer: {
        padding: '40px 40px',
        [breakpoints.down('xs')]: {
            padding: spacing.unit
        },
        '& button': {
            //padding: '0.25em 2em',
            marginLeft: 16,
            '&:first-child': {
                marginLeft: 0
            },
            '& svg': {
                fontSize: '24px',
                margin: 0
            },
            [breakpoints.down('xs')]: {
                margin: '0 3px',
                minWidth: '2em',
                padding: '0.5em'
            }
        }
    },

    productItem: {
        display: 'block',
        position: 'relative',

        '& > :first-child': {
            display: 'none'
        },
        '&:hover > :first-child, &:focus > :first-child': {
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            borderRadius: 2,
            border: `2px solid ${palette.primary.main}`,
            opacity: 0.75
        },
        flexGrow: '0',
        flexBasis: '200px',
        margin: spacing.unit
    },
    productItemCard: {
        display: 'flex',
        padding: '16px 16px 12px',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        minHeight: '225px'
    },
    productItemImage: {
        width: '100%',
        height: 128,
        maxWidth: 164,
        border: `1px solid ${palette.divider}`,
        marginBottom: 12,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    productItemName: {
        fontSize: typography.caption.fontSize
    },

    productListContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    productList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        [breakpoints.down(breakpoints.custom.dt)]: {
            padding: 24
        },
        padding: '2px 60px'
    },

    containerLoaded: {
        // paddingTop: '40px',
        // paddingLeft: '60px',
        // paddingRight: '60px',
        paddingBottom: SCROLL_LOAD_TOLERANCE
    },
    spinner: {
        marginBottom: 24,
        alignSelf: 'center'
    },
    noResults: {
        alignSelf: 'center'
    },

    rightButton: {
        float: 'right'
    }
});

export default withStyles(styles)(SelectionTab);
