const SharedBits = `
    ID
    Created
    Confirmed
    Cancelled
    Responded
    Requested
    Comment
    Notes
    Contact {
        ID
		Name
		ClergyCategory
    }
    PurchaseOrder {
        ID
        XeroID
        Invoice {
            ID
            XeroID
            XeroReference
        }
    }
    Responses {
        edges {
            node {
                ID
                Declined
                Responded
                Comment
                Supplier {
                    ID
                    Name
                }
            }
        }
    }
    DeliveryDate
    DeliveryTime
    DeliveryAddress
    Duration
`;

export const OrdersFragmentInclude = `
    ${SharedBits}
    BookingItems(limit: 999) {
        edges {
            node {
                ID
                Title
                Quantity
                UnitPrice
                Total
                Comment
                Returns
                Product {
                    ID
                    Title
                    BasePrice
                    ImagePortraitURL
                    ImageLandscapeURL
                    GST
                  }
                Variation {
                    ID
                    GST
                    Price
                }
            }
        }
    }
`;

export const BookingsFragmentInclude = `
    ${SharedBits}
`;
