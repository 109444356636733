import React from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Label from '../../../component/form/Label';
import Select from '../../../component/form/Select';
import { stringIsNullOrEmpty } from '../../../util/strings';

class SortByDropdown extends React.Component {
    render() {
        const { className, variant, label, placeholder, sortField, sortDirection, options } = this.props;

        let cssVariant = null;
        if (!stringIsNullOrEmpty(variant)) cssVariant = `label--${variant}`;

        return (
            <div className={`sort-by-container ${className || ''}`}>
                {label && <Label className={`label ${cssVariant || ''}`}>{label}</Label>}
                <Select
                    value={sortField + sortDirection}
                    placeholder={placeholder}
                    options={options}
                    onChange={this.handleChange}
                    className="select"
                    allowNone={false}
                />
            </div>
        );
    }

    handleChange = event => {
        const { setSortField } = this.props;
        setSortField(event.target.value);
    };
}

export default compose(withRouter)(SortByDropdown);
