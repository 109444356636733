import React from 'react';
import Select from '../../../component/form/Select';

const TaskLabelAutoComplete = ({ onSelect, placeholder, labels }) => {
    const state = { labels };

    const gotLabels =
        (state.labels && (state.labels.edges ? state.labels.edges.map(e => e.node) : state.labels.filter(e => !!e))) ||
        [];
    const myLabels = gotLabels
        .map(e => ({
            label: e.Title.split(' ')
                .map(w => w.replace(w[0], w[0].toUpperCase()))
                .join(' '),
            value: e.ID
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));

    return (
        <Select
            value="" // never show a value
            placeholder={placeholder}
            options={myLabels}
            onChange={(e, selected) => {
                const selectedLabel = myLabels.find(mine => mine.value === selected);
                const label = selectedLabel && { ID: selectedLabel.value, Title: selectedLabel.label };
                return onSelect(label);
            }}
        />
    );
};

export default TaskLabelAutoComplete;
