import { COMPONENT } from '../../../component/form/AddressAutocomplete';

export const REFRESHMENTS_VENUE_OPTIONS = [
    { label: 'Funeral Home', value: 'Venue' },
    { label: 'Offsite', value: 'Offsite' },
    { label: 'Not Required', value: 'Not Required' },
    { label: 'Unsure', value: '' }
];

export const CONFIRMATION_STATUS_OPTIONS = [
    { label: 'Available', value: 'Available' },
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'Not Available', value: 'Not Available' }
];

export const SUPPLIER_OPTIONS = [
    { label: 'By Family', value: 'Family' },
    { label: 'From Supplier', value: 'Supplier' },
    { label: 'Not Required', value: 'Not Required' },
    { label: 'Unsure', value: '' }
];

export const OFFSITE_COMPONENT_FIELDS = {
    [COMPONENT.line1]: 'RefreshmentsVenue.OffsiteAddressLine1',
    [COMPONENT.city]: 'RefreshmentsVenue.OffsiteSuburb',
    [COMPONENT.state]: 'RefreshmentsVenue.OffsiteState',
    [COMPONENT.country]: 'RefreshmentsVenue.OffsiteCountry',
    [COMPONENT.code]: 'RefreshmentsVenue.OffsitePostCode'
};

export const PERSONAL_TOUCHES_OPTIONS = [
    {
        label: 'Dove Release',
        value: 'Dove Release'
    }
];

export const CATERING_STAFF_REQUIRED = numberPeopleCatering =>
    Math.min(Math.ceil((Number(numberPeopleCatering) + 1 || 0) / 50), 3);
