/**
 * Calculate the total of a quote
 * @param quoteItems Array of nodes
 * @return Int
 */
import { GST } from './products';
import { isContactDefined } from './bookable';

export function calcQuoteTotal(quoteItems) {
    const quote = {};
    quote.total = 0;
    if (quoteItems.length > 1) {
        quote.total = quoteItems.reduce((sum, obj) => {
            return sum + calcQuoteItemSubtotal(obj);
        }, 0);
    } else if (quoteItems.length === 1) {
        quote.total = calcQuoteItemSubtotal(quoteItems[0]);
    }

    return quote.total;
}

const calcQuoteItemSubtotal = quoteItem => {
    const { Product, Variation } = quoteItem;
    const exPrice = Number(
        quoteItem.Price ||
            (!!isContactDefined(Variation) ? Variation.Price : !!isContactDefined(Product) ? Product.BasePrice : 0)
    );
    const needGST = !!isContactDefined(Variation)
        ? !!Variation.GST
        : !!isContactDefined(Product)
        ? !!Product.GST
        : false;
    const price = !!needGST ? exPrice * (GST + 1) : exPrice;
    return !!quoteItem.Prepaid || !!quoteItem.Complimentary || !!quoteItem.Optional ? 0 : price * Number(quoteItem.Qty);
};

// don't rename these keys!
export const quoteTypes = { 'Pre-Need': 'PRENEED', 'At-Need': 'ATNEED', Phone: 'PHONE', Plaque: 'PLAQUE' };
