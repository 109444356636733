import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { OutlineButton } from './PrimaryButton';
import { withStyles } from '@material-ui/core';

const CoronerAutoComplete = ({ addNewOnClick, classes, disabled, ...props }) => {
    return (
        <div className={classes.root}>
            <GqlAutocomplete
                readOneQuery={readOneCoronerQuery}
                readAllQuery={readAllCoronersQuery}
                disabled={disabled}
                {...props}
                form={props.form}
                addNewButton={
                    !disabled && <OutlineButton
                        disabled={disabled}
                        title="Add a new Coroner"
                        className={classes.addNewButton}
                        text="Add new&hellip;"
                        onClick={addNewOnClick}
                    />
                }
            />
        </div>
    );
};

const styles = () => ({
    root: {
        width: '100%',
        '& button': {
            width: 0,
            padding: 0,
            opacity: 0,
            overflow: 'hidden',
            transition: 'opacity 0.4s, width 0.5s ease-out 0s'
        },
        '&:hover button': {
            width: 80,
            opacity: 1,
            transition: 'opacity 0.25s'
        }
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 4px 4px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 8px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

const readAllCoronersQuery = gql`
    fragment Coroners on Coroner {
        ID
        Created
        LegacyID
        LegacyKey
        Name
        District
        Type
        Phone
        Mobile
    }

    query ReadCoroners($contains: String) {
        readCoroners(offset: 0, contains: $contains) {
            edges {
                node {
                    ...Coroners
                }
            }
        }
    }
`;

const readOneCoronerQuery = gql`
    fragment Coroners on Coroner {
        ID
        Created
        LegacyID
        LegacyKey
        Name
        District
        Type
        Phone
        Mobile
    }

    query ReadOneCoroner($id: ID!) {
        readOneCoroner(ID: $id) {
            ...Coroners
        }
    }
`;

export default withStyles(styles)(CoronerAutoComplete);
