import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withRouter } from 'react-router';
import TextField from '../../../component/form/TextField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import Select from '../../../component/form/Select';
import Label from '../../../component/form/Label';
import RadioGroup from '../../../component/form/RadioGroup';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../../funeral/certification/CertificationConstants';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import SignatureField from '../../../component/form/SignatureField';
import AutoComplete from '../../../component/form/AutoComplete';
import moment from 'moment';
import { GENDER_OPTIONS } from '../../funeral/deceased/DeceasedConstants';
import { MARITAL_STATUS_OPTIONS } from '../../funeral/family/FamilyConstants';
import { TitleOptions } from '../../quotes/EnquiryConstants';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../../component/form/AddressBookAutocomplete';
import { PLACE_OF_SERVICE_TYPE_OPTIONS } from '../../funeral/details/DetailsConstants';
import InputAdornment from '@material-ui/core/InputAdornment';
import { OutlineButton } from '../../../component/form/PrimaryButton';

class CrematoriaBookingDocument extends Component {
    render() {
        const { page } = this.props.tabProps;
        return (
            <Fragment>
                {page === 1 && this.renderStepOne()}
                {page === 2 && this.renderStepTwo()}
                {page === 3 && this.renderStepThree()}
            </Fragment>
        );
    }

    renderStepOne() {
        return (
            <Fragment>
                {this.renderCompanyDetails()}
                {this.renderDeceasedDetails()}
            </Fragment>
        );
    }

    renderCompanyDetails() {
        const { form } = this.props;
        return (
            <section id="companyDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Funeral Director Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Funeral Director Company"
                            placeholder="Funeral Director Company"
                            name="FuneralCompany"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Phone Number" placeholder="Phone Number" name="FuneralPhone" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField type={'email'} label="Email" placeholder="Email" form={form} name="Email" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Arranger Name" placeholder="Arranger Name" name="ArrangerName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AddressAutocomplete
                            placeholder="Funeral director address"
                            componentFields={{
                                line1: 'OfficeAddress.AddressLine1',
                                line2: 'OfficeAddress.AddressLine2',
                                city: 'OfficeAddress.Suburb',
                                state: 'OfficeAddress.State',
                                country: 'OfficeAddress.Country',
                                code: 'OfficeAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDetails() {
        const { form } = this.props;
        const isAddressBook = form.getField('DeceasedPlaceOfDeathType') === 'AddressBook';
        return (
            <section className="booking" id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Deceased Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="First name" placeholder="First Name" name="DeceasedFirstName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Middle name(s)"
                            placeholder="Middle name(s)"
                            name="DeceasedMiddleName"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Family Name" placeholder="Family Name" name="DeceasedLastName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Sex" options={GENDER_OPTIONS} name="DeceasedGender" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Occupation" />
                        <ComboListAutoComplete
                            placeholder="Occupation"
                            category={'OCCUPATION'}
                            name="DeceasedOccupation"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for deceased's address"
                            componentFields={{
                                line1: 'DeceasedAddress.AddressLine1',
                                line2: 'DeceasedAddress.AddressLine2',
                                city: 'DeceasedAddress.Suburb',
                                state: 'DeceasedAddress.State',
                                country: 'DeceasedAddress.Country',
                                code: 'DeceasedAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            type="date"
                            label="Date of Birth"
                            placeholder="Date of Birth"
                            name="DeceasedDateOfBirth"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            type="date"
                            label="Date of Death"
                            placeholder="Date of Death"
                            name="DeceasedDateOfDeath"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Age" placeholder="Deceased Age" name="DeceasedAge" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Place of death</h3>
                        <RadioGroup
                            className="doc"
                            label="Address type:"
                            options={[
                                {
                                    label: 'Residential',
                                    value: 'Residential'
                                },
                                {
                                    label: 'AddressBook',
                                    value: 'AddressBook'
                                }
                            ]}
                            name="DeceasedPlaceOfDeathType"
                            form={form}
                        />
                        {isAddressBook ? (
                            <AddressBookAutocomplete
                                placeholder="Death location"
                                name="DeceasedPlaceOfDeathAddressBook"
                                labelFieldFunc={getLabelWithSuburb}
                                form={form}
                            />
                        ) : (
                            <AddressAutocomplete
                                placeholder="Death location"
                                componentFields={{
                                    line1: 'DeceasedPlaceOfDeath.AddressLine1',
                                    line2: 'DeceasedPlaceOfDeath.AddressLine2',
                                    city: 'DeceasedPlaceOfDeath.Suburb',
                                    state: 'DeceasedPlaceOfDeath.State',
                                    country: 'DeceasedPlaceOfDeath.Country',
                                    code: 'DeceasedPlaceOfDeath.Postcode'
                                }}
                                form={form}
                                allowCustomAddress
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select
                            label="Marital Status"
                            options={MARITAL_STATUS_OPTIONS}
                            name="DeceasedMartialStatus"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Religion" placeholder="Religion" name="DeceasedReligion" form={form} />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderStepTwo() {
        return <Fragment>{this.renderBookingDetails()}</Fragment>;
    }

    renderBookingDetails() {
        const { form } = this.props;
        return (
            <section id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Booking Details</h3>
                        <h4>Delivery Details</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="date"
                            label="Date of Delivery"
                            placeholder="Date of Delivery"
                            name="DeliveryDate"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            type="time"
                            label="Time of Delivery"
                            placeholder="Time of Delivery"
                            name="DeliveryTime"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Service Details</h4>
                        <Select
                            allowNone={false}
                            label="Service type"
                            options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                            name="ServiceType"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Pre-arrangement Details</h4>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Label text="Cemetery Name (if known)" />
                        <TextField label="Cemetery Name" placeholder="Cemetery Name" form={form} name="CemeteryName" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Label text="Site Reference(if known)" />
                        <TextField
                            label="Site Reference"
                            placeholder="Site Reference"
                            form={form}
                            name="SiteReference"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RadioGroup
                            label="Are there any special instructions?"
                            name="HasSpecialInstructions"
                            form={form}
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                        <TextField
                            label="Special Instructions"
                            placeholder="Special Instructions"
                            name="SpecialInstructions"
                            form={form}
                            disabled={!form.getField('HasSpecialInstructions')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="HeavenAddress"
                            label="Heaven address required?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="Record"
                            label="Service record required?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            form={form}
                            name="AVPresentation"
                            label="AV presentation required?"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderStepThree() {
        return (
            <Fragment>
                {this.renderApplicantDetails()}
                {this.renderSignature()}
            </Fragment>
        );
    }

    renderApplicantDetails() {
        const { form } = this.props;
        return (
            <section id="applicantDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Applicant Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutoComplete
                            name="ApplicantRelationship"
                            label="Relationship to the deceased"
                            form={form}
                            limit={100}
                            options={APPLICANT_RELATIONSHIP_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Title" options={TitleOptions} name="ApplicantTitle" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="First name" placeholder="First name" name="ApplicantFirstName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Surname" placeholder="Surname" name="ApplicantLastName" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Age" placeholder="Age" name="ApplicantAge" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Business"
                            placeholder="Business Name"
                            name="ApplicantBusinessName"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Phone" placeholder="Phone" name="ApplicantPhone" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Mobile" placeholder="Mobile" name="ApplicantMobile" form={form} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Email" placeholder="Email" name="ApplicantEmail" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for applicant's address"
                            componentFields={{
                                line1: 'ApplicantAddress.AddressLine1',
                                line2: 'ApplicantAddress.AddressLine2',
                                city: 'ApplicantAddress.Suburb',
                                state: 'ApplicantAddress.State',
                                country: 'ApplicantAddress.Country',
                                code: 'ApplicantAddress.Postcode'
                            }}
                            form={form}
                            allowCustomAddress
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderSignature() {
        const { form, classes } = this.props;
        return (
            <section id="signature">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Consent & Signature</h3>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <p>
                            Conditions: Mountain View Crematoria cannot guarantee the cremation will take place same day
                            as delivery although every attempt will be made to do so. Request for same day service for
                            deliveries after 1:00pm, an additional fee will apply. All cremations will be carried out in
                            accordance with NSW State Health Authority guidelines.
                        </p>
                    </Grid>*/}

                    <Grid item xs={12} md={12}>
                        <SignatureField form={form} name="ApplicantSignature" />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Declaration location" />
                        <AddressAutocomplete
                            componentFields={{
                                line1: 'DeclarationLocation.AddressLine1',
                                line2: 'DeclarationLocation.AddressLine2',
                                city: 'DeclarationLocation.Suburb',
                                state: 'DeclarationLocation.State',
                                country: 'DeclarationLocation.Country',
                                code: 'DeclarationLocation.Postcode'
                            }}
                            form={form}
                            required
                            allowCustomAddress
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="date"
                            label="Declaration Date"
                            name="SignatureDate"
                            form={form}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <OutlineButton
                                            className={classes.addNewButton}
                                            onClick={() =>
                                                form.setField({ SignatureDate: moment().format('YYYY-MM-DD') })
                                            }
                                            title="Add Today's Date"
                                        >
                                            Add Today's Date
                                        </OutlineButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }
}
const styles = () => ({
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export default compose(withRouter, withStyles(styles))(CrematoriaBookingDocument);
