import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { AltButton } from '../form/PrimaryButton';
import SupplierBooking from './SupplierBooking';
import Grid from '../form/Grid';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '../icon/DeleteIcon';
import Label from '../form/Label';
import OptionalTextField from '../form/OptionalTextField';
import AlertModal from '../../page/workQueue2/extras/AlertModal';
import { isNullOrUndefined } from '../../util/objects';
import { getUser } from '../../util/sessions';
import { InlineField } from '../form/Inline';
import TimeSelect from '../form/TimeSelect';
import TextField from '../form/TextField';

class SupplierRequest extends Component {
    state = {
        deletingLabel: null
    };

    render() {
        const { name, form, children, max = 9, disabled } = this.props;
        const items = form.getField(name) || [];
        const { deletingLabel } = this.state;
        const count = items.length;
        const hasChildren = !isNullOrUndefined(children);
        const hasMoreThanOne = items.length > 1;
        const { FirstName } = getUser();
        return (
            <Fragment>
                {!!deletingLabel && (
                    <AlertModal
                        variant={!!deletingLabel.isDelete ? 'warning' : 'error'}
                        open
                        title="Prompt - Delete Item"
                        primaryAction="Yeah, it's gone"
                        onClickPrimaryAction={() => this.handleDeleteThing(deletingLabel.ID, !!deletingLabel.isDelete)}
                        secondaryAction="Nah, keep it"
                        onClickSecondaryAction={() => this.setState({ deletingLabel: null })}
                        onClose={() => this.setState({ deletingLabel: null })}
                    >
                        <p>
                            Woah there {FirstName}! Looks like you're about to {deletingLabel.text}.
                        </p>
                        <p>Are you sure? (You will still need to save this change.)</p>
                    </AlertModal>
                )}
                {items
                    .sort((a, b) => (moment(a.Created).isAfter(b.Created) ? 1 : Number(a.ID) < Number(b.ID) ? -1 : 0))
                    .map((item, i) => this.renderItem(item, i, hasMoreThanOne))}
                {count < max && (
                    <Grid pc={1}>
                        <AltButton disabled={disabled && count > 0} onClick={this.onClickAdd}>
                            {`+ Add ${hasChildren
                                ? ((count > 0 && 'another ') || 'an ') + 'Order'
                                : ((count > 0 && 'another ') || 'a ') + 'Booking'
                                }`}
                        </AltButton>
                    </Grid>
                )}
            </Fragment>
        );
    }

    renderItem(item, i, hasMoreThanOne) {
        const {
            name,
            form,
            label,
            labelSupplier = 'Supplier',
            exposeBookingFields,
            exposeBookingDuration,
            allowPurchaseOrder,
            disabled
        } = this.props;
        const { children, max, classes, ...props } = this.props;
        const hasChildren = !isNullOrUndefined(children);
        const responses = (item.Responses && item.Responses.edges && item.Responses.edges.map(e => e.node)) || [];
        const hasBill = item.PurchaseOrder && item.PurchaseOrder.Invoice && item.PurchaseOrder.Invoice.XeroID;
        const canDelete = !(!!item.Confirmed || !!item.Cancelled || responses.length > 0 || !!hasBill) && !hasBill && !disabled;
        const canCancel = !item.Cancelled && !disabled;
        const itemName = name + (isNaN(i) ? '' : '[' + i + ']');

        return (
            <div key={i} className={classes.listItem}>
                <span className={classes.iconButtonContainer}>
                    {(!!canDelete && (
                        <IconButton
                            title={`Remove ${hasChildren ? 'Order' : 'Booking'}`}
                            onClick={() =>
                                this.setState({
                                    deletingLabel: {
                                        ID: item.ID,
                                        text: `discard this ${hasChildren ? 'order' : 'booking'}`,
                                        isDelete: true
                                    }
                                })
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    )) ||
                        (!!canCancel && (
                            <IconButton
                                title={`Cancel ${hasChildren ? 'Order' : 'Booking'}`}
                                onClick={() =>
                                    this.setState({
                                        deletingLabel: {
                                            ID: item.ID,
                                            text: `cancel this ${hasChildren ? 'order' : 'booking'}`
                                        }
                                    })
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        ))}
                </span>

                {hasChildren && (
                    <Fragment>
                        <p>
                            <Label text={'Selected ' + label + (hasMoreThanOne ? ' #' + (1 + i) : '')} />
                        </p>
                        {children(name, i, label)}
                    </Fragment>
                )}

                <SupplierBooking
                    index={i}
                    isOrder={hasChildren}
                    labelSupplier={labelSupplier}
                    allowPurchaseOrder={allowPurchaseOrder}
                    {...props}
                />

                {!!exposeBookingFields && !item.Cancelled && (
                    <div style={{ clear: 'both' }}>
                        <InlineField>
                            <Grid container spacing={16}>
                                <Grid item xs={6} sm={exposeBookingDuration ? 4 : 6}>
                                    <TextField
                                        type="date"
                                        label="Date required"
                                        form={form}
                                        name={`${itemName}.DeliveryDate`}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={exposeBookingDuration ? 4 : 6}>
                                    <TextField
                                        type="time"
                                        label="Time required"
                                        form={form}
                                        name={`${itemName}.DeliveryTime`}
                                        disabled={disabled}
                                    />
                                </Grid>
                                {!!exposeBookingDuration && (
                                    <Grid item xs={6} sm={4}>
                                        <TimeSelect
                                            label="Duration (Hours)"
                                            form={form}
                                            name={`${itemName}.Duration`}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </InlineField>

                        <InlineField>
                            <Grid item xs={12} sm={12}>
                                <TextField disabled={disabled} label="Address" form={form} name={`${itemName}.DeliveryAddress`} />
                            </Grid>
                        </InlineField>
                    </div>
                )}
                {!item.Cancelled && (
                    <div style={{ margin: '8px 0' }}>
                        <OptionalTextField
                            name={`${itemName}.Comment`}
                            buttonlabel={'+ Add Comment'}
                            textfieldlabel={'Comments'}
                            form={form}
                        />
                    </div>
                )}
                {!!this.props.hasNotes && (
                    <div style={{ margin: '8px 0' }}>
                        <OptionalTextField
                            name={`${itemName}.Notes`}
                            buttonlabel="+ Add Supplier Instructions"
                            textfieldlabel="Supplier Instructions"
                            multiline
                            form={form}
                        />
                    </div>
                )}
            </div>
        );
    }

    onClickAdd = () => {
        const { name, form, defaultSupplierID } = this.props;
        const array = form.getField(name) || [];
        array.push({ Comment: null, Contact: !!defaultSupplierID ? { ID: defaultSupplierID } : null, ID: null });
        form.setField({ [name]: array });
    };

    handleDeleteThing = (ID, isDelete) => {
        const { name, form } = this.props;
        const oldState = form.getField(name);
        const newState = { [name]: form.getField(name) };
        if (!!isDelete) newState[name] = oldState.filter(e => e.ID !== ID);
        else
            newState[name] = oldState.map(e => {
                if (e.ID === ID) e.Cancelled = true;
                return e;
            });
        this.setState({ deletingLabel: null }, () => form.setField(newState));
    };
}

const styles = ({ typography }) => ({
    listItem: {
        width: '100%',
        padding: '0 8px 8px 8px',
        margin: '6px 0 0 !important',
        borderRadius: 5,
        position: 'relative',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
        '& > p': {
            margin: '1.125rem 0 0 0',
            lineHeight: typography.body2.lineHeight
        }
    },
    iconButtonContainer: {
        // right: 0,
        // position: 'absolute'
        float: 'right',
        marginRight: -8
    }
});

export default withStyles(styles)(SupplierRequest);
