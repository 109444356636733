import gql from 'graphql-tag';

export default gql`
    fragment TransferFrom on Funeral {
        TransferFrom {
            ID
            Name
        }
    }
`;
