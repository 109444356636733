import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import Modal from '../../workQueue2/extras/Modal';
import NotesReadOnly from '../../workQueue2/extras/NotesReadOnly';

class DocumentHistoryModal extends Component {
    render() {
        const { open, onClose, notes, name } = this.props;
        return (
            <Modal
                className=""
                open={open}
                variant="primary"
                onClose={onClose}
                canClickOut="true"
                title="Note History"
                subtitle="Document Changes"
            >
                <Grid container>
                    <Grid item>
                        <h3>Note history for {name}</h3>
                        <NotesReadOnly value={notes} />
                    </Grid>
                </Grid>
            </Modal>
        );
    }
}

export default withStyles({})(DocumentHistoryModal);
