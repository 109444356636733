import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 19.201 19.201" {...props}>
        <path
            fill="currentColor"
            d="M18.6,15.6H16.8V7.5A7.4,7.4,0,0,0,9.822,0,7.2,7.2,0,0,0,2.4,7.2v8.4H.6a.6.6,0,0,0-.6.6v2.4a.6.6,0,0,0,.6.6h18a.6.6,0,0,0,.6-.6V16.2A.6.6,0,0,0,18.6,15.6ZM3.6,7.2a6,6,0,0,1,12,0v8.4H3.6ZM18,18H1.2l0-1.2H18L18,18Z"
        />
    </SvgIcon>
);
