import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { orientationMode } from '../../../util/objects';

const Separator = ({ orientation }) => {
    const orientationClass = orientation === orientationMode.vertical ? 'separator--vertical' : 'separator--horizontal';

    return <div className={`separator ${orientationClass}`}>&nbsp;</div>;
};

export default withStyles({})(Separator);
