import Details from './Details';
import DetailsFragment from './DetailsFragment';
import { fixBoolean, flattenConnection, flattenStaffAllocations, isDefinedAndDifferent } from '../../../util/functions';
import { sanitizeObjectWithFK } from '../../../util/bookable';
import { flattenBookingItems } from '../../../util/products';
import { sanitizeAndAppendFuneralData } from '../../calendar/AppointmentConstants';
import { validationHelper } from '../../../util/validation';

export default {
    label: 'Funeral Details',
    component: Details,
    fragment: DetailsFragment,
    onLoad: data => {
        // Temporary patch fixes for backend data
        if (data.FamilyNameAtBirth && data.FamilyNameAtBirth === data.Surname) {
            data.FamilyNameAtBirth = null;
        }
        fixBoolean(data, 'HiddenUnknownDOBField');
        // ^

        data.SurnameDifferent = !!data.FamilyNameAtBirth;

        flattenConnection(data, 'Cars');
        flattenConnection(data, 'CemeteryCremetorium');
        flattenConnection(data, 'Clergy');
        flattenConnection(data, 'CoffinBearings');
        flattenConnection(data, 'Limos');
        flattenConnection(data, 'PlaceOfViewingItems');
        flattenConnection(data, 'ValuableItems');
        flattenConnection(data, 'Arrangers');
        flattenBookingItems(data.Disposal, 'DisposalBookingItems');
        flattenStaffAllocations(data.Disposal);
        flattenStaffAllocations(data.PlaceOfService);
        if (data.PlaceOfViewingItems) {
            for (let x = 0; x < data.PlaceOfViewingItems.length; x++)
                flattenStaffAllocations(data.PlaceOfViewingItems[x]);
        }
        data.DetailsChangesFromPrePlan = JSON.parse(data.DetailsChangesFromPrePlan) || [];
    },
    formatSaveData: (saveData, state) => {
        if (state.SurnameDifferent && state.FamilyNameAtBirth === state.Surname) {
            saveData.FamilyNameAtBirth = null;
        }
        if (state.DateOfBirth && state.HiddenUnknownDOBField) {
            saveData.DateOfBirth = null;
        }
        // if (state.DateOfDeath1 && state.DateOfDeathType === DATE_OF_DEATH_OPTION.UNKNOWN.value) {
        //     saveData.DateOfDeath1 = null;
        // }
        if (state.Pensioner !== 'Y' && !!state.PensionType) {
            saveData.PensionType = null;
        }
        const placeOfDeathFields = [
            'PlaceOfDeath1',
            'PlaceOfDeath2',
            'PlaceOfDeathCode',
            'PlaceOfDeathState',
            'PlaceOfDeathPostcode',
            'PlaceOfDeathAddressLine1',
            'PlaceOfDeathAddressLine2'
        ];
        if (!!state.UseResidentialAddress) {
            placeOfDeathFields.forEach(f => {
                if (state[f]) saveData[f] = null;
            });
        }

        if (saveData.AshesDetail) saveData.AshesDetail = sanitizeObjectWithFK(saveData.AshesDetail, 'Crematorium');

        if (saveData.CemeteryCremetorium && saveData.CemeteryCremetorium.length > 0) {
            saveData.CemeteryCremetorium[0] = sanitizeObjectWithFK(saveData.CemeteryCremetorium[0], 'Contact');
        }

        if (saveData.Clergy && saveData.Clergy.length > 0) {
            saveData.Clergy[0] = sanitizeObjectWithFK(saveData.Clergy[0], 'Contact');
        }

        if (saveData.PlaceOfViewingItems) {
            for (let x = 0; x < saveData.PlaceOfViewingItems.length; x++) {
                saveData.PlaceOfViewingItems[x] = sanitizeObjectWithFK(saveData.PlaceOfViewingItems[x], 'Location');
            }
        }

        if (saveData.Disposal && saveData.Disposal.DisposalBookingItems) {
            if (state.Disposal.DisposalBookingItems.length > 0) {
                saveData.Disposal.DisposalBookingItems = state.Disposal.DisposalBookingItems.map(x => {
                    return {
                        ProductID: x.ProductID,
                        Quantity: x.Quantity,
                        VariationID: x.VariationID,
                        Returns: x.Returns,
                        Comment: x.Comment
                    };
                });
            }
        }

        if (saveData.Disposal) {
            const locationID1 = state.Disposal.Location ? state.Disposal.Location.ID : null;
            saveData.Disposal.LocationID = locationID1;
            delete saveData.Disposal.Location;
        }

        if (saveData.PlaceOfService) {
            const locationID2 = state.PlaceOfService.Location ? state.PlaceOfService.Location.ID : null;
            saveData.PlaceOfService.LocationID = locationID2;
            delete saveData.PlaceOfService.Location;
            if (state.PlaceOfService.Type === 'No Service No Attendance' || state.PlaceOfService.Type === 'Graveside') {
                saveData.PlaceOfService.LocationID = null;
            }
        }

        if (saveData.PlaceOfViewingItems) {
            for (let x = 0; x < saveData.PlaceOfViewingItems.length; x++) {
                const locationID = saveData.PlaceOfViewingItems[x].LocationID;
                if (saveData.PlaceOfViewingItems[x].StaffAllocations) {
                    for (let y = 0; y < saveData.PlaceOfViewingItems[x].StaffAllocations.length; y++) {
                        saveData.PlaceOfViewingItems[x].StaffAllocations[y] = sanitizeAndAppendFuneralData(
                            'Viewing',
                            saveData.ID,
                            locationID,
                            saveData.PlaceOfViewingItems[x].StaffAllocations[y]
                        );
                    }
                }
            }
        }
    },

    validation: {
        onValidate: {
            ContractAmount: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'ContractAmount';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            FuneralFundName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'FuneralFundName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            FuneralFundCode: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'FuneralFundCode';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            TravelProtection: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'TravelProtection';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            }
        }
    }
};
