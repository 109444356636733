import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withStyles } from '@material-ui/core';
import { AltButton } from '../../../component/form/PrimaryButton';
import ReactToPrint from 'react-to-print';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import { joinDefined } from '../../../util/strings';
import { dateTimeToString } from '../../../util/date';
import { formStyles } from './FormFields';
import PrintIcon from '../../../component/icon/PrintIcon';

class ValuablesForm extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <ReactToPrint
                    pageStyle={'@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'}
                    trigger={() => (
                        <AltButton>
                            <PrintIcon />
                            Print Valuables Form
                        </AltButton>
                    )}
                    content={() => this.formRef}
                />
                <div className={classes.hidePrint}>{this.renderForm()}</div>
            </Fragment>
        );
    }

    renderForm() {
        const { classes, form } = this.props;
        const loading = !(form && form.getField);
        const valuables = (form && form.getField && form.getField('ValuableItems')) || [];

        const date = dateTimeToString(new Date());

        return (
            <div ref={e => (this.formRef = e)} className={classes.root}>
                {(loading && <h1>Data is still loading, please close and try again in a moment...</h1>) || (
                    <Grid container spacing={24} className={classes.print}>
                        <Grid item xs={12}>
                            <span className={classes.center}>Valuables Form - Printed {date}</span>
                        </Grid>

                        <span className={classes.spacer} />

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                Date:
                            </Grid>
                            <Grid item xs={7}>
                                _____ /_____ /____________
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                Name of Deceased:
                            </Grid>
                            <Grid item xs={7}>
                                {joinDefined(
                                    [form.getField('FirstName'), form.getField('MiddleName'), form.getField('Surname')],
                                    ' '
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            List of Valuables:
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHeaderRow>
                                    <TableHeaderCell>Description</TableHeaderCell>
                                    <TableHeaderCell>Quantity</TableHeaderCell>
                                    <TableHeaderCell>To be Returned?</TableHeaderCell>
                                    <TableHeaderCell>Notes</TableHeaderCell>
                                </TableHeaderRow>
                                {valuables.map((valuable, index) => (
                                    <TableRow key={index} pad={true}>
                                        <TableCell>{valuable.Description}</TableCell>
                                        <TableCell>{valuable.Quantity}</TableCell>
                                        <TableCell>{valuable.ToBeReturned ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{valuable.Note}</TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Grid>

                        <span className={classes.spacer} />

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                Signature of Funeral Director:
                            </Grid>
                            <Grid item xs={7}>
                                ______________________________________________
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                Name:
                            </Grid>
                            <Grid item xs={7}>
                                ______________________________________________
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <span className={classes.title}>I, the undersigned acknowledge the following:</span>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                1)
                            </Grid>
                            <Grid item xs={11}>
                                I have received the items listed for return, indicated by a "Yes" in the return column
                                of the above table and
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                2)
                            </Grid>
                            <Grid item xs={11}>
                                Items listed not to be returned, indicated by a "No" selected in the return column of
                                the above table will remain with the deceased.
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <span className={classes.title}>Signature of the Person Responsible:</span>
                            </Grid>
                            <Grid item xs={7}>
                                ______________________________________________
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <span className={classes.title}>Name:</span>
                            </Grid>
                            <Grid item xs={7}>
                                ______________________________________________
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

export default withStyles(formStyles)(ValuablesForm);
