import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from './Drawer';
import { navigationItems } from './drawerConstants';

const styles = () => {};

export default withStyles(styles)(({ ...props }) => (
    <Drawer items={navigationItems} showTitleIcon={true} drawerTitle={'Funeral Manager'} {...props} />
));
