import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = ({ palette, funeralHome }) => ({
    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        width: '100%'
    },
    tableBody: {
        '& tr:last-of-type > td': { borderBottom: '2px solid ' + palette.baseSecondary[funeralHome] }
    },
    tableBodyMulti: {
        '&:last-of-type tr:last-of-type > td': { borderBottom: '1px solid ' + palette.baseSecondary[funeralHome] },
        '&:hover td': {
            backgroundColor: palette.table.row.hoverColor
        },
        '& tr > td': { padding: 8 },
        '&:nth-child(2n+1) tr': { background: palette.baseSecondary[funeralHome] + '19' },
        '&:nth-child(2n) tr': { background: palette.baseSecondary[funeralHome] + '10' }
    },
    tableBodyMultiBottomBorder: {
        borderBottom: '1px solid ' + palette.baseSecondary[funeralHome] + '!important'
    },
    tableBodyMultiRightBorder: {
        borderRightStyle: 'unset!important'
    },
    tableFooter: {
        borderBottom: '2px solid ' + palette.contentForeground[funeralHome]
    },
    tableHeaderRow: {
        '& th': {
            background: palette.baseSecondary[funeralHome],
            border: '1px solid ' + palette.baseSecondary[funeralHome],
            borderLeft: '1px dotted ' + palette.basePrimary[funeralHome] + 33,
            padding: '3px 0.5rem',
            height: '2.5rem',
            color: palette.table.header.foregroundColor,
            fontSize: '0.75rem',
            fontWeight: '400',
            textAlign: 'left'
        },
        '& th:first-child': {
            borderLeft: 0,
            borderRadius: '6px 0 0 0'
        },
        '& th:last-child': {
            borderRadius: '0 6px 0 0'
        }
    },

    tableFooterRow: {
        '& th': {
            background: palette.contentForeground[funeralHome],
            border: '1px solid ' + palette.contentForeground[funeralHome] + 33,
            padding: '12px 8px',
            color: palette.table.header.foregroundColor,
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'left',
            borderLeft: 1
        },
        '& td': {
            borderStyle: 'dotted',
            borderWidth: '0 1px 0 0',
            fontSize: 14,
            fontWeight: 'bolder',
            padding: '8px 8px',
            borderColor: palette.basePrimary[funeralHome] + 33,
            borderBottom: '2px solid ' + palette.contentForeground[funeralHome]
        },
        '&:hover, & td:hover': {
            backgroundColor: palette.table.row.hoverColor
        }
    },

    tableRow: {
        '&:nth-child(2n+1)': { background: palette.baseSecondary[funeralHome] + '19' },
        '&:nth-child(2n)': { background: palette.baseSecondary[funeralHome] + '10' },
        '& td': {
            borderStyle: 'dotted',
            borderBottomStyle: 'solid',
            borderWidth: '0 0 1px 1px',
            fontSize: 14,
            padding: '12px 8px',
            borderColor: palette.basePrimary[funeralHome] + 33,
            borderTopColor: palette.basePrimary[funeralHome] + 66,
            '&:first-child': {
                borderLeftStyle: 'solid'
            },
            '&:last-child': {
                borderRightStyle: 'solid',
                borderRightWidth: 1
            }
        },
        '&:hover': {
            backgroundColor: palette.table.row.hoverColor
        },
        '&:first-child': {
            borderTop: '1px solid ' + palette.basePrimary[funeralHome] + 33
        },
        '&:last-child': {
            borderBottom: '1px solid ' + palette.basePrimary[funeralHome] + 33
        }
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        paddingLeft: 1 // weird Firefox bug
    }
});

/**
 * TableData
 * @param {{id,label,render,rowSpan:Int,colSpan:Int,rowPos:Int,styles}[]} columns The table columns.
 * Use render((value,row, index)) for custom display. Use rowPos & rowSpan to span rows.
 * @param {Object[]} data array of structured objects, eg. [ {id1: value, id2: value}, { id1: value, id2: value } ]
 * @exports TableData the complete table, with horizontal scrollbar for wide tables.
 */
const TableData = withStyles(styles)(({ ...props }) => {
    const { classes, columns, data, footer } = props;
    if (data && data.length) {
        const rowSpans = Math.max(...columns.map(col => (col.rowPos || 1) + ((col.rowSpan || 1) - 1)));
        const tHead = (
            <thead>
                <tr className={classes.tableHeaderRow}>
                    {columns
                        .filter(col => !col.colSpan)
                        .map((column, columnIndex) => (
                            <th key={columnIndex} style={{ width: column.label ? null : 1 }}>
                                {column.label}
                            </th>
                        ))}
                </tr>
            </thead>
        );

        const tFoot = footer && footer.length && (
            <tfoot className={classes.tableFooter}>
                <tr className={classes.tableFooterRow}>
                    {columns.map((column, columnIndex) => {
                        const me = footer.find(foot => {
                            return column.id === foot.id;
                        });
                        return me ? (
                            me.label ? (
                                <th key={columnIndex}>{me.label}</th>
                            ) : (
                                <td key={columnIndex} style={column.styles}>
                                    {me.render ? me.render(column.id, data) : me.value}
                                </td>
                            )
                        ) : (
                            <td
                                key={columnIndex}
                                style={{
                                    visibility: 'hidden',
                                    borderColor: 'transparent'
                                }}
                            />
                        );
                    })}
                </tr>
            </tfoot>
        );

        const tBodies = [];
        if (rowSpans === 1) {
            const bodyRow = (
                <tbody key={0} className={classes.tableBody}>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={classes.tableRow}>
                            {columns.map((column, columnIndex) => (
                                <td colSpan={column.colSpan} key={columnIndex} style={column.styles}>
                                    {row[column.id]
                                        ? column.render
                                            ? column.render(row[column.id], row, rowIndex)
                                            : row[column.id]
                                        : column.render
                                        ? column.render('', row, rowIndex)
                                        : ''}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            );
            tBodies.push(bodyRow);
        } else {
            data.forEach((row, rowIndex) => {
                const bodyRow = (
                    <tbody key={rowIndex} className={cx(classes.tableBody, classes.tableBodyMulti)}>
                        {Array.from({ length: rowSpans }, (_, i) => 1 + i).map(rowPos => (
                            <tr key={rowPos} className={classes.tableRow}>
                                {columns.map((column, columnIndex) => {
                                    if (rowPos > 1 && !column.rowPos) return null;
                                    if (!!column.rowPos && rowPos !== column.rowPos) return null;
                                    const bottomBorder =
                                        (column.rowPos || 1) + ((column.rowSpan || 1) - 1) === rowSpans;
                                    const rightBorder =
                                        !column.rowSpan &&
                                        (column.colSpan || 1) + columnIndex ===
                                            columns.filter(col => !col.colSpan).length;
                                    return (
                                        <td
                                            key={columnIndex}
                                            colSpan={column.colSpan}
                                            rowSpan={column.rowSpan}
                                            style={column.styles}
                                            className={cx(
                                                bottomBorder && classes.tableBodyMultiBottomBorder,
                                                rightBorder && classes.tableBodyMultiRightBorder
                                            )}
                                        >
                                            {row[column.id]
                                                ? column.render
                                                    ? column.render(row[column.id], row, rowIndex)
                                                    : row[column.id]
                                                : column.render
                                                ? column.render('', row, rowIndex)
                                                : ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                );
                tBodies.push(bodyRow);
            });
        }

        return (
            <div className={classes.wideScroll}>
                <table className={classes.table}>
                    {tHead}
                    {tBodies}
                    {tFoot}
                </table>
            </div>
        );
    }
    return null;
});

export default TableData;
