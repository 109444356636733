import React from 'react';
import ReactDOM from 'react-dom';
import Providers from './Providers';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import './page/workQueue2/css/workqueue.scss';
import './page/workQueue2/css/referee-doctor-list.scss';
import * as Sentry from '@sentry/react';

const { hostname } = window.location;
const env =
    hostname.indexOf('funeralmanager-uat.internetrix.net') > -1
        ? 'uat'
        : hostname.indexOf('funeralmanager.com.au') > -1
        ? 'live'
        : false;
if (env) {
    1 === 2 &&
        Sentry.init({
            dsn: 'https://c7ea17b88e78449dab6f4c1ba906fc85@o52687.ingest.sentry.io/5436536',
            environment: env,
            autoSessionTracking: true,
            release: localStorage.getItem('commit_ref')
        });
}

ReactDOM.render(
    <Providers>
        <App />
    </Providers>,
    document.getElementById('root')
);

if (1 === 2) registerServiceWorker();
