import gql from 'graphql-tag';

export default gql`
    fragment Transfers on Funeral {
        ID
        Created
        LastEdited

        FirstName
        MiddleName
        Surname

        UseResidentialAddress
        UsePlaceOfDeathAddress
        PlaceOfDeathType
        TransferFromType
        LegacyKey

        ResidentialAddress1
        ResidentialAddress2
        ResidentialState
        ResidentialTown
        ResidentialCountry

        RefereeCremationCheck
        DateOfService

        Disposal {
            ID
            CrematedOrBuried
        }

        PlaceOfDeath {
            ID
            Name
            AddressLine1
            AddressLine2
            Suburb
            State
            Phone
        }

        TransferFrom {
            ID
            Name
            AddressLine1
            AddressLine2
            Suburb
            State
            Phone
        }

        PlaceOfDeathResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
        }

        TransferFromResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
        }

        Certification {
            ID
            TypeOfBdmDeathCertificate
            Doctor {
                ID
                Name
                Mobile
                Phone
            }

            Coroner {
                ID
                Name
                Mobile
                Phone
            }
        }

        TransferComment
        TransferCalled
        TransferCertificates
        TransferReady
        TransferActioned
    }
`;
