import React from 'react';
import Grid from './form/Grid';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = ({ spacing, palette, breakpoints }) => ({
    root: {
        flexGrow: 1,
        margin: -12,
        [breakpoints.up('lg')]: {
            '& > :first-child': {
                paddingRight: spacing.unit * 3
                //paddingTop: 0
            },
            '& > :last-child': {
                paddingLeft: spacing.unit * 3
                //paddingTop: 0
            },
            '& > :nth-child(n + 2)': {
                paddingLeft: `${spacing.unit * 3}px !important`,
                borderLeft: `1px solid ${palette.divider}`
            }
        }
    },

    snappableLayout: {
        flexGrow: 1,
        margin: -12,
        position: 'relative',
        [breakpoints.up('lg')]: {
            '& > :not(:first-child)': {
                borderLeft: `1px solid ${palette.divider}`
            }
        }
    },

    snappableComponent: {
        padding: '0px !important',
        margin: '12px 0 0 -10px',
        [breakpoints.down('md')]: {
            position: 'absolute',
            zIndex: 9,
            outline: `3px solid ${palette.divider}`,
            margin: '-1rem 0rem 0rem 0.88rem',
            right: 0,
            // maxHeight: 'calc(100vh - 15rem)',
            // overflow: 'hidden',
            width: 550
        }
    }
});

const ColumnLayout = withStyles(styles)(({ classes, className, children }) => {
    //for single children, just return the object
    if (!children.length || children.length <= 0) {
        return (
            <Grid container spacing={24} className={cx(classes.root, className)}>
                <Grid item xs={12} lg={12}>
                    {children}
                </Grid>
            </Grid>
        );
    }

    //multi children
    const factor = 12 / children.length;
    return (
        <Grid container spacing={24} className={cx(classes.root, className)}>
            {children.map((child, i) => (
                <Grid key={i} item xs={12} lg={factor}>
                    {child}
                </Grid>
            ))}
        </Grid>
    );
});

const SnappableLayout = withStyles(styles)(({ classes, className, snappableComponent, children }) => {
    const maxWidth = 12;
    const centerWidth = snappableComponent ? 8 : maxWidth;
    const snapWidth = maxWidth - centerWidth;

    return (
        <Grid container spacing={24} className={cx(classes.snappableLayout, className)}>
            <Grid item xs={12} lg={centerWidth}>
                <ColumnLayout children={children} />
            </Grid>
            {snapWidth > 0 && (
                <Grid item xs={12} lg={snapWidth} className={classes.snappableComponent}>
                    {snappableComponent}
                </Grid>
            )}
        </Grid>
    );
});

export default ColumnLayout;
export { SnappableLayout };
