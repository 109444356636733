import React, { useRef } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

function getUniqueResults(edges) {
    if (!edges) return null;
    return Object.values(
        edges.reduce((map, edge) => {
            map[edge.node.ID + '-' + edge.node.LegacyKey] = edge.node;
            return map;
        }, {})
    );
}

export default ({ term, children, limit, offset, skip, brand, sortBy, oneLoad }) => {
    const cursorRef = useRef({ term: '', prearrangements: false }); // use a ref since updating it does not cause a re-render
    const variables = {
        limit: limit || 10,
        offset: offset || 0,
        brand: brand || '',
        contains: null,
        key: null
    };
    variables.sortBy = sortBy;
    let prearrangements = false;
    if (brand && brand === 'prearrangements') {
        prearrangements = true;
        brand = null;
        variables.brand = null;
    } else if (brand && 'recent' === brand) {
        variables.brand = 'recent';
        variables.sortBy = null; // always recent first
    }

    if (brand === 'all' || brand === 'prearrangements') {
        let index = variables.sortBy && variables.sortBy.findIndex(sortingItem => sortingItem.field === 'LegacyKey');
        if (index > -1) {
            let currentSort = variables.sortBy[index];
            variables.sortBy = [{ field: 'Created', direction: currentSort.direction !== 'ASC' ? 'DESC' : 'ASC' }];
        }
    }

    term = term ? term.trim() : '';
    // If the term is non-numeric then we do a `contains` search
    // otherwise we search for a `key` match
    if (term.length) {
        if (isNaN(parseInt(term, 10))) variables.contains = term;
        else variables.key = term;
        variables.solr = true;
    } else {
        variables.solr = false;
    }
    if (!oneLoad) cursorRef.current = { term, prearrangements }; // to track current query vs next

    return (
        <Query
            query={prearrangements ? prearrangementsQuery : funeralsQuery}
            variables={variables}
            fetchPolicy={'cache-and-network'}
            skip={'recent' !== brand && (skip || !(term || brand || prearrangements))}
            context={{ debounceKey: 1 }}
            notifyOnNetworkStatusChange
        >
            {({ data, fetchMore, networkStatus, error }) => {
                const { searchFunerals, searchPrearrangements } = data || {};
                const functionName = searchFunerals || searchPrearrangements || {};
                const prearrangements = !!searchPrearrangements;
                const { key, contains, edges, pageInfo, solr } = functionName || {};
                const { hasNextPage, totalCount } = pageInfo || false;
                const resultsTerm = key || contains || '';
                const results = getUniqueResults(edges);

                const onLoadMore = async () => {
                    if (hasNextPage && !oneLoad) {
                        try {
                            await fetchMore({
                                variables: {
                                    offset: results ? results.length : 0
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return previousResult;
                                    if (resultsTerm === cursorRef.current.term) {
                                        // same search term?
                                        try {
                                            if (searchFunerals && !cursorRef.current.prearrangements) {
                                                return {
                                                    ...previousResult,
                                                    searchFunerals: {
                                                        ...fetchMoreResult.searchFunerals,
                                                        edges: [
                                                            ...previousResult.searchFunerals.edges,
                                                            ...fetchMoreResult.searchFunerals.edges
                                                        ]
                                                    }
                                                };
                                            } else if (searchPrearrangements && !!cursorRef.current.prearrangements) {
                                                return {
                                                    ...previousResult,
                                                    searchPrearrangements: {
                                                        ...fetchMoreResult.searchPrearrangements,
                                                        edges: [
                                                            ...previousResult.searchPrearrangements.edges,
                                                            ...fetchMoreResult.searchPrearrangements.edges
                                                        ]
                                                    }
                                                };
                                            }
                                        } catch (e) {
                                            // when someone changes the search before the result comes back, it throws error.
                                        }
                                    }
                                    return null; // result didn't match original query, discard
                                }
                            });
                        } catch (e) {
                            // when someone leaves the search page before the result comes back, it throws error.
                        }
                    }
                };

                return children({
                    term,
                    resultsTerm,
                    results,
                    brand,
                    onLoadMore,
                    networkStatus,
                    solr,
                    error,
                    prearrangements,
                    totalCount
                });
            }}
        </Query>
    );
};

const funeralsQuery = gql`
    query SearchFunerals(
        $key: String
        $contains: String
        $limit: Int
        $offset: Int
        $brand: String
        $solr: Boolean
        $sortBy: [SortField]
    ) {
        searchFunerals(
            key: $key
            contains: $contains
            limit: $limit
            offset: $offset
            brand: $brand
            sortBy: $sortBy
            solr: $solr
        ) {
            key
            contains
            solr
            edges {
                node {
                    ID
                    LegacyKey
                    FirstName
                    MiddleName
                    Surname
                    DateOfBirth
                    DateOfService
                    Confirmed
                    AdminChecksComplete
                    FuneralDataStatus
                    ExportDate
                    ExportStatus
                }
            }
            pageInfo {
                hasNextPage
                totalCount
            }
        }
    }
`;

const prearrangementsQuery = gql`
    query SearchPrearrangements(
        $key: String
        $contains: String
        $limit: Int
        $offset: Int
        $brand: String
        $solr: Boolean
        $sortBy: [SortField]
    ) {
        searchPrearrangements(
            key: $key
            contains: $contains
            limit: $limit
            offset: $offset
            brand: $brand
            sortBy: $sortBy
            solr: $solr
        ) {
            key
            contains
            solr
            edges {
                node {
                    ID
                    LegacyKey
                    FirstName
                    MiddleName
                    Surname
                    DateOfBirth
                    FuneralFundName
                }
            }
            pageInfo {
                hasNextPage
                totalCount
            }
        }
    }
`;
