import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 11.34 11.29" {...props}>
        <path
            fill="currentColor"
            d="M5.48.09,5.3.27a.3.3,0,0,0,0,.42h0L9.81,5.21H.3a.3.3,0,0,0-.3.3v.26a.3.3,0,0,0,.3.3H9.81L5.3,10.59a.29.29,0,0,0,0,.42h0l.18.18a.3.3,0,0,0,.42,0h0l5.34-5.34a.3.3,0,0,0,0-.43h0L5.91.09a.31.31,0,0,0-.43,0Z"
            transform="translate(0 0)"
        />
    </SvgIcon>
);
