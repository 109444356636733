import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbarMessage } from '../../../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';

class DocumentModal extends Component {
    state = {
        document: null,
        loading: false
    };

    render() {
        const { open, document, onClose } = this.props;

        if (!document || !open) {
            return null;
        }

        const winner = window.open(
            document.AbsoluteLink,
            '_blank',
            'menubar=no,width=' + Math.min(window.screen.width, 1024)
        );
        winner.focus();
        onClose();
        return null;
    }
}

const styles = ({ palette }) => ({
    root: {
        '& > div:nth-child(2) > div': {
            maxWidth: 'unset',
            height: '100%'
        },
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            overflowX: 'visible',
            borderRadius: '10px',
            maxWidth: 'unset',
            '& > div': {
                overflowY: 'unset'
                // flexDirection: 'unset'
            }
        }
    },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    modalFooter: {
        padding: '10px 20px 20px',
        margin: 0
    },

    modalContent: {
        height: 'auto',
        maxWidth: '1200px',
        width: 'calc(100vw - 6em)',
        overflowY: 'hidden',
        overflowX: 'auto'
    },

    columnRoot: {
        width: '100%'
    },

    pdfGrid: {
        height: 510
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    back: {
        float: 'left'
    },
    loading: {
        minHeight: '300px',
        minWidth: '400px',
        display: 'flex',
        justifyContent: 'space-around',
        '& > div': {
            alignSelf: 'center'
        }
    },
    contentLeft: {
        height: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(DocumentModal);
