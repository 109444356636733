import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { joinDefined } from '../../util/strings';
import TickIcon from '../../component/icon/TickIcon';
import Checkbox from '../../component/form/Checkbox';
import { getClient, getServiceURLHostname } from '../../apollo';
import cx from 'classnames';
import LinkButton from '../../component/form/LinkButton';
import { niceDateFromString } from '../../util/date';
import { IconButton } from '@material-ui/core';
import FileUpload from '../../component/form/FileUpload';
import DownloadIcon from '../../component/icon/DownloadIcon';
import { setSnackbarMessage } from '../../context/SnackbarMessage';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleMenu from '../../component/form/SimpleMenu';

export const refereeDoctorListFragment = gql`
    fragment RefereeDoctor on Funeral {
        ID
        LegacyKey
        FirstName
        MiddleName
        Surname
        DateOfBirth
        DateOfService
        Confirmed
        AdminChecksComplete
        FuneralDataStatus
        ExportDate
        ExportStatus
        RefereeCremationCheck
        RefereeDoctorDateSigned
        Certification {
            ID
            Referee {
                ID
                Name
            }
            FileRefereeingDoctorForm {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                    Created
                }
            }
        }
        Disposal {
            ID
            Date
            CrematedOrBuried
        }
    }
`;

export function isAwaitingDocumentation(row) {
    return (
        row.Certification.Referee &&
        row.Certification.Referee.ID > 0 &&
        !row.RefereeCremationCheck &&
        (!row.Certification.FileRefereeingDoctorForm || !row.Certification.FileRefereeingDoctorForm.AbsoluteLink)
    );
}

function isRefereeAssigned(row) {
    return row.Certification.Referee && row.Certification.Referee.ID > 0;
}

function isRefereeCompleted(row) {
    return row.Certification.Referee && row.Certification.Referee.ID > 0 && row.RefereeCremationCheck;
}

export const refereeDoctorListColumns = [
    {
        renderLabel: (data, classes, onHeaderClick, extraData) => {
            const downloadables = data.reduce((result, { node }) => {
                if (isAwaitingDocumentation(node)) {
                    result[node.LegacyKey] = node.ID;
                }
                return result;
            }, {});
            const checked = Object.keys(extraData.downloads).length > 0;
            const disabled = Object.keys(extraData.funerals).length > 0;
            return (
                <div style={{ marginBottom: -10 }}>
                    <Checkbox
                        checked={checked}
                        disabled={disabled}
                        onChange={onHeaderClick(checked ? {} : downloadables)}
                        className={cx(classes.floatRight, 'larger')}
                    />
                </div>
            );
        },
        label: '',
        renderCell: (row, value, rowIndex, classes, onCellClick, extraData) => {
            return isAwaitingDocumentation(row) ? (
                <Checkbox
                    checked={isRefereeCompleted(row) || row.LegacyKey in extraData.downloads}
                    disabled={Object.keys(extraData.funerals).length > 0}
                    onChange={onCellClick('download', row.LegacyKey, row.ID)}
                    className={cx(classes.floatRight, 'larger')}
                />
            ) : (
                ''
            );
        }
    },
    {
        label: 'Key',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => <LinkButton href={`/funeral/${row.LegacyKey}/${row.ID}`} text={value} />
    },
    {
        label: 'Deceased Name',
        propertyPath: 'Surname',
        renderCell: row => (
            <span>{joinDefined([(row.Surname || '').toUpperCase(), row.FirstName, row.MiddleName], ' ')}</span>
        )
    },
    {
        label: 'Commital Date',
        propertyPath: 'DateOfService',
        renderCell: row => <span>{row.Disposal && niceDateFromString(row.Disposal.Date)}</span>
    },
    {
        label: 'Refereeing Doctor',
        renderCell: row => <span>{isRefereeAssigned(row) ? row.Certification.Referee.Name : 'NOT ASSIGNED'}</span>
    },
    {
        label: 'Assign Referee',
        renderCell: (row, value, rowIndex, classes, onCellClick, extraData) => {
            return (
                <Fragment>
                    {isRefereeAssigned(row) ? (
                        <TickIcon className="confirmed-check" />
                    ) : (
                        <Checkbox
                            checked={isRefereeAssigned(row) || row.LegacyKey in extraData.funerals}
                            disabled={Object.keys(extraData.downloads).length > 0}
                            onChange={onCellClick('addReferee', row.LegacyKey, {
                                ID: row.ID,
                                Certification: {
                                    ID: row.Certification.ID,
                                    Referee: {
                                        ID: 'NEW'
                                    }
                                }
                            })}
                            className={cx(classes.floatRight, 'larger')}
                        />
                    )}
                </Fragment>
            );
        }
    },
    {
        label: 'Referee Completed',
        renderCell: (row, value, rowIndex, classes, onCellClick, extraData) => (
            <span>
                {isRefereeAssigned(row) ? (
                    isRefereeCompleted(row) ? (
                        <TickIcon className="confirmed-check" />
                    ) : isAwaitingDocumentation(row) ? (
                        'Awaiting Documentation'
                    ) : (
                        row.Certification.FileRefereeingDoctorForm.Name
                    )
                ) : (
                    '-'
                )}
            </span>
        )
    },
    {
        label: 'Actions',
        renderCell: (row, value, rowIndex, classes, onCellClick, extraData) => {
            return isAwaitingDocumentation(row) ? (
                <SimpleMenu>
                    <MenuItem>
                        <IconButton
                            href={
                                '//' +
                                getServiceURLHostname() +
                                '/merge-doc-funeral/download_document/medical/' +
                                row.ID
                            }
                            target="downloads"
                        >
                            <DownloadIcon /> Download Document
                        </IconButton>
                    </MenuItem>
                    <MenuItem>
                        <FileUpload
                            iconButton
                            label="Upload Document"
                            folderPath={`/documents/funeral/${row.ID}`}
                            onComplete={({ uploadFile }) => {
                                const input = {
                                    ID: row.ID,
                                    Certification: {
                                        ID: row.Certification.ID,
                                        FileRefereeingDoctorFormID: uploadFile.id || 0
                                    }
                                };
                                getClient()
                                    .mutate({
                                        mutation: uploadRefereeMutation,
                                        variables: { input }
                                    })
                                    .then(() => {
                                        setSnackbarMessage(
                                            'Success, referee document has successfully been uploaded.',
                                            true
                                        );
                                    });
                            }}
                        />
                    </MenuItem>
                </SimpleMenu>
            ) : (
                ''
            );
        }
    }
];

const assignDoctorsMutation = gql`
    ${refereeDoctorListFragment}
    mutation updateManyFunerals($input: [UpdateFuneralInput]!) {
        updateManyFunerals(input: $input) {
            edges {
                node {
                    ...RefereeDoctor
                }
            }
        }
    }
`;

export const uploadRefereeMutation = gql`
    ${refereeDoctorListFragment}
    mutation updateFuneral($input: UpdateFuneralInput!) {
        updateFuneral(input: $input) {
            ...RefereeDoctor
        }
    }
`;

const assignDoctorsFunc = async input => {
    const asyncQuery = await getClient().mutate({
        mutation: assignDoctorsMutation,
        variables: { input }
    });

    return asyncQuery.data;
};

export { assignDoctorsFunc };
