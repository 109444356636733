import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment ExitMusicians on Funeral {
        ExitMusicians {
            ${BookingsFragmentInclude}
        }
    }
`;
