import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { AuditEventTypes } from '../workQueue/components/TaskConstants';
import Grid from '../../component/form/Grid';
import Button from '../../component/form/LinkButton';
import { InlineField, InlineFieldRightAlignChildren } from '../../component/form/Inline';
import UserCircle from '../../component/form/UserCircle';

class TaskAudit extends Component {
    state = {
        limitActivity: 1,
        limitActivityLabel: 'Show All'
    };

    render() {
        const { audits, classes, label = 'Task Audit:' } = this.props;
        const { limitActivityLabel, limitActivity } = this.state;
        return (
            <Fragment>
                <Grid item xs={12}>
                    <InlineFieldRightAlignChildren>
                        <h3>{label}</h3>
                        {audits.length > 1 && (
                            <span className={classes.toggleActivity}>
                                <Button
                                    variant="orange-link"
                                    text={
                                        <Fragment>
                                            {limitActivityLabel}
                                            {limitActivity > 1 ? <ExpandLess /> : <ExpandMore />}
                                        </Fragment>
                                    }
                                    onClick={() => this.onClickTaskActivity()}
                                />
                            </span>
                        )}
                    </InlineFieldRightAlignChildren>
                </Grid>
                {limitActivity > 0 && (
                    <Grid item xs={12} className={classes.audit}>
                        {audits.filter((_, index) => index < limitActivity).map(audit => this.renderAudit(audit))}
                    </Grid>
                )}
            </Fragment>
        );
    }

    renderAudit = audit => {
        const { classes } = this.props;
        return (
            <InlineField className={classes.auditItem} key={audit.node.ID}>
                <span>
                    <UserCircle person={audit.node.AddedBy} />
                </span>
                <span>
                    {(this.renderAuditType(audit.node.Type) === 'Comment' && (
                        <Fragment>
                            <span className={classes.auditDetails}>
                                {`Added notes on ${moment(audit.node.Created).format('ll [at] LT')}`}
                            </span>
                            <br />
                            <span className={classes.auditType}>{audit.node.Comment}</span>
                        </Fragment>
                    )) || (
                        <Fragment>
                            <span className={classes.auditDetails}>
                                {`${audit.node.Comment} on ${moment(audit.node.Created).format('ll [at] LT')}`}
                            </span>
                        </Fragment>
                    )}
                </span>
            </InlineField>
        );
    };

    renderAuditType(type) {
        switch (type) {
            case AuditEventTypes.Comment:
                return 'Comment';
            case AuditEventTypes.Assign:
                return 'Assigned';
            case AuditEventTypes.Unassign:
                return 'Unassigned';
            case AuditEventTypes.Archive:
                return 'Archive';
            case AuditEventTypes.Restore:
                return 'Restore';
            case AuditEventTypes.Completed:
                return 'Completed';
            case AuditEventTypes.Due:
                return 'Due';
            case AuditEventTypes.InProgress:
                return 'In Progress';
            default:
                throw new Error('unknown audit type ' + type);
        }
    }

    onClickTaskActivity() {
        const { audits } = this.props;
        const { limitActivity } = this.state;
        const auditsLength = audits.length;
        this.cycleToNextTaskActivityLimit(auditsLength, limitActivity);
    }

    cycleToNextTaskActivityLimit(auditsLength, currentLimit) {
        let limitActivityLabel;
        let limitActivity;

        if (auditsLength === 0) {
            limitActivityLabel = '';
            limitActivity = 0;
        } else if (auditsLength === 1) {
            limitActivityLabel = '';
            limitActivity = 1;
        } else {
            if (currentLimit === 0) {
                limitActivityLabel = 'Show All';
                limitActivity = 1;
            } else if (currentLimit < 2) {
                limitActivityLabel = 'Only Show Latest';
                limitActivity = auditsLength;
            } else {
                limitActivityLabel = 'Show All';
                limitActivity = 1;
            }
        }

        this.setState({ limitActivity, limitActivityLabel });
    }
}

const styles = ({ palette }) => ({
    toggleActivity: {
        fontSize: '13px',
        marginTop: 16,
        '& > a svg': {
            verticalAlign: 'middle'
        }
    },

    userCircleCell: {
        width: '50px'
    },
    userCircle: {
        height: '35px',
        width: '35px',
        background: '#7BDBFF',
        borderRadius: '20px',
        fontSize: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        display: 'inline-block',
        '& > *': {
            lineHeight: '34px'
        }
    },
    auditType: {
        fontSize: '14px',
        whiteSpace: 'pre-wrap'
    },
    auditDetails: {
        fontSize: '13px',
        fontWeight: 'lighter'
    },
    auditItem: {
        alignItems: 'baseline',
        margin: '16px 0',
        '&:first-of-type': { marginTop: 0 },
        '& > span': { lineHeight: '16px!important' }
    },
    audit: {
        maxHeight: '250px',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: 16
    }
});

export default compose(withStyles(styles))(TaskAudit);
