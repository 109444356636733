import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 30 34.286" {...props}>
        <path
            fill="currentColor"
            d="M29.464,10.714H6.429V9.643a5.352,5.352,0,0,1,4.509-5.271,3.208,3.208,0,0,0,2.991,2.057h4.286a3.214,3.214,0,0,0,0-6.429H13.929a3.2,3.2,0,0,0-3.046,2.234,7.486,7.486,0,0,0-6.6,7.409v1.071H.536A.536.536,0,0,0,0,11.25v1.071a.536.536,0,0,0,.536.536H4.292v.119L6.429,32.143H2.679a.536.536,0,0,0-.536.536V33.75a.536.536,0,0,0,.536.536H27.321a.536.536,0,0,0,.536-.536V32.679a.536.536,0,0,0-.536-.536H23.578l2.136-19.286h3.75A.536.536,0,0,0,30,12.321V11.25A.536.536,0,0,0,29.464,10.714ZM13.929,2.143h4.286a1.071,1.071,0,0,1,0,2.143H13.929a1.071,1.071,0,0,1,0-2.143Zm7.5,30H8.565v-.119L6.429,12.857H23.565Z"
        />
    </SvgIcon>
);
