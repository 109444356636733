import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import cx from 'classnames';
import Grid from '../../../component/form/Grid';
import TextField from '../../../component/form/TextField';
import { Typography, withStyles } from '@material-ui/core';
import Select from '../../../component/form/Select';
import PrimaryButton from '../../../component/form/PrimaryButton';
import DataFormView from '../../../component/form/v2/DataFormView';
import Checkbox from '../../../component/form/Checkbox';
import { isNullOrUndefined } from 'util';
import gql from 'graphql-tag';
import {
    VALUABLES_LOCATION_OPTIONS,
    VALUABLES_TO_BE_PLACE_OPTIONS
} from '../../funeral/details/valuables-modal/ValuablesConstants';
import { flattenConnection } from '../../../util/graphql';
import { niceDateTimeFromString } from '../../../util/date';
import { validationHelper } from '../../../util/validation';

class SimpleValuableDetailsModal extends Component {
    state = {
        valuableFormSigned: null,
        toBeReturned: null,
        category: null,
        showHistory: true
    };

    render() {
        const { classes, form } = this.props;
        const { showHistory } = this.state;

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Typography variant="headline" id="modal-title" className={classes.popoverTitle}>
                        Valuable Details
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Description" form={form} name="Description" />
                </Grid>

                <Grid item xs={12}>
                    <Select
                        label="To Be Placed"
                        allowNone={false}
                        name="ToBePlaced"
                        options={VALUABLES_TO_BE_PLACE_OPTIONS}
                        form={form}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Quantity" form={form} type="number" name="Quantity" />
                </Grid>

                <Grid item xs={12}>
                    <Checkbox label="Valuables form signed" name="Signed" form={form} />
                </Grid>

                <Grid item xs={12}>
                    <Select
                        required={true}
                        name={'CurrentLocation'}
                        form={form}
                        options={VALUABLES_LOCATION_OPTIONS}
                        label="Current location of item"
                        allowNone={false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Location Details" form={form} name="CurrentLocationDetail" />
                </Grid>

                <Grid item xs={12}>
                    <PrimaryButton
                        size="small"
                        className={classes.smallerButton}
                        onClick={_ => this.setState({ showHistory: !showHistory })}
                    >
                        {showHistory ? '- Collapse History' : '+ Show History'}
                    </PrimaryButton>
                </Grid>

                {showHistory && this.renderHistory()}

                <Grid item xs={12}>
                    <Checkbox label="To be returned" name="ToBeReturned" form={form} />
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Where to be Returned" form={form} name="WhereToBeReturned" />
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Notes" form={form} name="Note" multiline rows={4} />
                </Grid>

                <Grid item xs={12} />
            </Grid>
        );
    }

    renderHistory() {
        const { form, classes } = this.props;

        const changes = (form.getState('Changes') || []).map((item, index) => ({ item, index })).reverse();

        return (
            <Grid item xs={12} className={classes.historyGrid}>
                {changes.map((change, i) => this.renderHistoryItem(change, changes.length))}
            </Grid>
        );
    }

    renderHistoryItem({ item, index }, count) {
        const { classes } = this.props;
        return (
            <Fragment key={index}>
                <div className={classes.historyRowContainer}>
                    <span className={classes.historyRow}>
                        <span
                            className={
                                index === count - 1
                                    ? classes.changeCircle
                                    : cx(classes.changeCircle, classes.changeCircleOld)
                            }
                        >
                            {index + 1}
                        </span>

                        <span
                            className={
                                index === count - 1
                                    ? classes.historyTextContainer
                                    : cx(classes.historyTextContainer, classes.historyTextOld)
                            }
                        >
                            <span className={classes.historyTitle}>{`${item.Location}: ${item.LocationDetail ||
                                ''}`}</span>
                            <span className={classes.historySubtitle}>{`Entered by ${item.UserName ||
                                'Unknown'}, ${niceDateTimeFromString(item.Created)}`}</span>
                        </span>
                    </span>
                </div>

                {index < count && index !== 0 && (
                    <div className={classes.historyRowContainer}>
                        <div className={classes.historyPath} />
                    </div>
                )}
            </Fragment>
        );
    }
}

const styles = ({ palette, breakpoints }) => ({
    smallerButton: {
        marginRight: 15,
        '& > span': {
            fontSize: 10
        }
    },
    viewRoot: {
        padding: '40px'
    },
    diagRoot: {
        maxWidth: '700px'
    },

    historyGrid: {
        margin: '10px 0 0 0'
    },
    historyRow: {
        display: 'flex'
    },
    historyRowContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    historyPath: {
        height: '32px',
        width: '1px',
        background: '#CCCCCC',
        margin: '5px 0px 5px 15px'
    },
    changeCircle: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '31px',
        width: '31px',
        background: palette.action.active,
        color: '#FFFFFF',
        fontSize: '14px'
    },
    historyTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px'
    },
    historyTitle: {
        fontSize: '16px'
    },
    historySubtitle: {
        marginTop: '3px',
        fontSize: '10px',
        color: '#666'
    },
    changeCircleOld: {
        backgroundColor: '#D8CACA'
    },
    historyTextOld: {
        color: '#666'
    },
    saveButtonContainer: {
        marginLeft: 10,
        position: 'static',
        minWidth: 'unset'
    },
    saveButton: {
        [breakpoints.down('sm')]: {
            minWidth: 'unset',
            bottom: 0,
            right: 0,
            padding: '10px',
            borderRadius: 64,
            height: 'fit-content',
            '& span > span': {
                display: 'block'
            },

            '& span > svg': {
                display: 'block',
                margin: 0,
                height: 20,
                width: 20,
                marginRight: 12
            }
        }
    }
});

const SimpleValuableDetailsModalFragment = gql`
    fragment SimpleValuableDetailsModalFragment on FuneralValuableItem {
        ID
        Description
        Signed
        ToBePlaced
        Quantity
        CurrentLocation
        CurrentLocationDetail
        ToBeReturned
        WhereToBeReturned
        Changes {
            edges {
                node {
                    ID
                    UserName
                    Created
                    Status
                    Location
                    LocationDetail
                }
            }
        }
        Note
    }
`;

const createNewValuableItem = () => {
    return {
        readOneFuneralValuableItem: {
            Description: null,
            Signed: null,
            ToBePlaced: null,
            Quantity: 0,
            CurrentLocation: null,
            CurrentLocationDetail: null,
            ToBeReturned: false,
            WhereToBeReturned: null,
            Changes: [],
            Note: null
        }
    };
};

const Tabs = [
    {
        id: 'SimpleValuableDetailsModal',
        label: 'SimpleValuableDetailsModal',
        component: withStyles(styles)(SimpleValuableDetailsModal),
        fragment: SimpleValuableDetailsModalFragment,
        onLoad: data => {
            if (data.Changes) flattenConnection(data, 'Changes');

            /*
            //todo:remove
            data.Changes = [
                {
                    ID: '1',
                    UserName: 'Bob Smith',
                    Created: dateTimeToString(new Date()),
                    Status: 'new',
                    Location: 'Rankins Warrawong Chapel',
                    LocationDetail: 'Received'
                },
                {
                    ID: '2',
                    UserName: 'Sarah Smith',
                    Created: dateTimeToString(new Date()),
                    Status: 'new',
                    Location: 'HParsons Wollongong',
                    LocationDetail: 'return'
                },
                {
                    ID: '3',
                    UserName: 'Jessica Jones',
                    Created: dateTimeToString(new Date()),
                    Status: 'new',
                    Location: 'Mountain View Crematorium',
                    LocationDetail: 'placed'
                },
                {
                    ID: '4',
                    UserName: 'Bob Smith',
                    Created: dateTimeToString(new Date()),
                    Status: 'new',
                    Location: 'Wollongong Church',
                    LocationDetail: 'return'
                }
            ];*/
        },
        formatSaveData: (saveData, state) => {
            delete saveData.Changes;
        },
        validation: {
            //required: ['Description']
            //suggested: ['Description']
            onValidate: {
                Description: (newValue, persistedValue, hasValue, getField) => {
                    return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
                },
                CurrentLocation: (newValue, persistedValue, hasValue, getField) => {
                    return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
                },
                Quantity: (newValue, persistedValue, hasValue, getField) => {
                    return Number(persistedValue) < 1
                        ? validationHelper.required('Please enter a quantity greater than zero.')
                        : validationHelper.ok();
                },
                WhereToBeReturned: (newValue, persistedValue, hasValue, getField) => {
                    const ToBeReturned = getField('ToBeReturned');
                    return isNullOrUndefined(persistedValue) && !!ToBeReturned
                        ? validationHelper.required()
                        : validationHelper.ok();
                }
            }
        }
    }
];

class SimpleValuableDetailsModalView extends Component {
    render() {
        const { context, itemId, parentId, onClose, onSaved, onCreateNew, open, classes } = this.props;

        if (!open) return <span />;

        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={open}
                classes={{ paper: classes.diagRoot }}
            >
                <DataFormView
                    tabs={Tabs}
                    createNew={isNullOrUndefined(itemId)}
                    createNewFunc={createNewValuableItem}
                    objectType="FuneralValuableItem"
                    itemId={itemId}
                    parentId={parentId}
                    parentObjectType="Funeral"
                    name="SimpleValuableDetailsModal"
                    context={context}
                    onClose={onClose}
                    onCreateNew={onCreateNew}
                    onSaved={onSaved}
                    className={classes.viewRoot}
                    classes={{ footerButtonsContainer: classes.saveButtonContainer, saveButton: classes.saveButton }}
                />
            </Dialog>
        );
    }
}

export default withStyles(styles)(SimpleValuableDetailsModalView);
