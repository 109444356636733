import gql from 'graphql-tag';

export default gql`
    fragment Parent2 on Funeral {
        Parent2 {
            ID
            LastEdited
            Created
            Type
            Gender
            FirstName
            MiddleName
            Surname
            SurnameAtBirth
            Occupation
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementParent2 on Prearrangement {
        Parent2 {
            ID
            LastEdited
            Created
            Type
            Gender
            FirstName
            MiddleName
            Surname
            SurnameAtBirth
            Occupation
        }
    }
`;
