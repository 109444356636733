import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm0-184c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm0 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm-28.7-140.7c6.2-6.2 6.2-16.4 0-22.6L190.6 192l28.7-28.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L168 169.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l28.7 28.7-28.7 28.7c-6.2 6.2-6.2 16.4 0 22.6 6.2 6.2 16.4 6.3 22.6 0l28.7-28.7 28.7 28.7c6.2 6.3 16.4 6.3 22.6 0zm160-102.6c-6.2-6.2-16.4-6.2-22.6 0L328 169.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l28.7 28.7-28.7 28.7c-6.2 6.2-6.2 16.4 0 22.6 6.2 6.2 16.4 6.3 22.6 0l28.7-28.7 28.7 28.7c6.2 6.2 16.4 6.3 22.6 0 6.2-6.2 6.2-16.4 0-22.6L350.6 192l28.7-28.7c6.3-6.2 6.3-16.4 0-22.6z"
        />
    </SvgIcon>
);
