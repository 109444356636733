import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import DisplayContact from './DisplayContact';
import Label from '../form/Label';
import Checkbox from '../form/Checkbox';
import BiggerMessageBar from '../BiggerMessageBar';
import moment from 'moment';
import { InlineField } from '../form/Inline';
import AddressBookAutocomplete from '../form/AddressBookAutocomplete';
import LinkButton from '../form/LinkButton';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../Spinner';
import Grid from '../form/Grid';
import RequestBookingModal from './RequestBookingModal';
import QuotesIcon from '../icon/QuotesIcon';
import PurchaseOrderModal, {
    createBookingPurchaseOrderMutation,
    createNewBookingPurchaseOrderMutation
} from './PurchaseOrderModal';
import GatedComponent from '../GatedComponent';
import { isRelatedObjectUndefined } from '../../util/graphql';
import { isNullOrUndefined, setProperty } from '../../util/objects';
import { AltButton, OutlineButton } from '../form/PrimaryButton';
import { niceDateTimeFromString } from '../../util/date';
import PurchaseInvoiceModal from './PurchaseInvoiceModal';
import { getUtilitiesClient } from '../../apollo';

class SupplierBooking extends Component {
    state = {
        requestModalOpen: null,
        orderModalOpen: null
    };

    render() {
        const { form, name, categories, labelSupplier, index = 0, isOrder, forceVenue, disabled } = this.props;
        const fieldArray = form.getField(name) || (!!forceVenue && []);
        if (isNullOrUndefined(fieldArray)) {
            return null;
        }

        if (!!forceVenue && isNullOrUndefined(fieldArray[index]))
            fieldArray[index] = {
                Contact: form.getField(forceVenue + '.Location'),
                Confirmed: form.getField(forceVenue + '.Confirmed') || false
            };
        const item = fieldArray[index];

        return (
            <Fragment>
                {isRelatedObjectUndefined(item.Contact) ? (
                    <Grid pc={1} style={{ paddingBottom: 16, paddingTop: !!isOrder ? 16 : null }}>
                        <InlineField>
                            <Label
                                text={'Select ' + labelSupplier + (fieldArray.length > 1 ? ' #' + (1 + index) : '')}
                                htmlFor={'select-' + name}
                            />
                        </InlineField>
                        <InlineField>
                            <AddressBookAutocomplete
                                disabled={disabled}
                                name={`${name}[${index}].Contact`}
                                form={form}
                                placeholder={'Search for a ' + labelSupplier + '...'}
                                categories={categories}
                                onSelect={(e, result) => this.setContact(result, e)}
                                onAddNewContact={result => this.setContact(result)}
                            />
                        </InlineField>
                        {this.renderResponses(item)}
                    </Grid>
                ) : (
                    this.renderContact(item, index)
                )}
            </Fragment>
        );
    }

    renderResponses(item) {
        const { classes } = this.props;

        const messages =
            item.Responses &&
            item.Responses.edges
                .filter(e => !!e.node.Declined)
                .map(e => {
                    const isSupplier =
                        !isRelatedObjectUndefined(item.Contact) && item.Contact.ID === e.node.Supplier.ID;

                    return {
                        messageType: !!isSupplier ? 'error' : 'info',
                        message: (
                            <Fragment>
                                {!!e.node.Supplier ? e.node.Supplier.Name : 'The supplier'} declined this request.
                                {!!e.node.Comment && (
                                    <div className={classes.response}>&ldquo;{e.node.Comment}&rdquo;</div>
                                )}
                            </Fragment>
                        )
                    };
                });
        return (
            (!!messages &&
                messages.map((e, i) => (
                    <BiggerMessageBar key={i} messageType={e.messageType}>
                        {e.message}
                    </BiggerMessageBar>
                ))) ||
            null
        );
    }

    renderContact(item, index) {
        if (!(item && !isRelatedObjectUndefined(item.Contact))) return;
        return (
            <Query query={nameQuery} client={getUtilitiesClient()} variables={{ id: item.Contact.ID }} key={index}>
                {({ loading, error, data }) => {
                    const contact = (data && data.readOneAddressBook) || {};
                    if (!contact || !contact.ID || error) {
                        return (
                            <Fragment key={index}>
                                <div style={{ float: 'left' }}>
                                    <Spinner />
                                </div>
                                <div style={{ paddingLeft: '1.25rem', fontSize: '75%', opacity: 0.5 }}>
                                    {error ? error.toString() : 'loading...'}
                                </div>
                            </Fragment>
                        );
                    }
                    return this.renderPanel(contact, item, index);
                }}
            </Query>
        );
    }

    renderPanel(contact, item, index) {
        const {
            form,
            name,
            classes,
            labelSupplier,
            isOrder,
            forceVenue,
            eventField,
            allowPurchaseOrder,
            disabled
        } = this.props;
        const { requestModalOpen, orderModalOpen } = this.state;
        const field = form.getField(name) || (!!forceVenue && []);
        const actionType = isOrder ? 'Order' : 'Booking';
        let checkbox = { label: actionType + ' Confirmed', disabled: false };
        const status = {};
        const supplerDeclined = item.Cancelled
            ? [{ node: { Declined: true } }]
            : (!!item.Responses &&
                  item.Responses.edges.filter(e => !!e.node.Declined && e.node.Supplier.ID === contact.ID)) ||
              [];

        if (item.Confirmed && !item.Cancelled && !contact.NotCurrent) {
            const response = item.Responses && item.Responses.edges.length > 0 && item.Responses.edges[0].node;
            status.messageType = 'good';
            status.message = (
                <Fragment>
                    The {actionType.toLowerCase()} with {contact.Name} has been confirmed.
                    {!!response && !!response.Comment && (
                        <div>
                            <span className={classes.response}>{response.Comment.trim()}</span>
                        </div>
                    )}
                </Fragment>
            );
            if (supplerDeclined.length > 0) checkbox.disabled = true;
        } else {
            if (contact.NotCurrent) {
                status.messageType = 'error';
                status.message = <Fragment>This contact is no longer available.</Fragment>;
            } else if (supplerDeclined.length > 0) {
                status.messageType = 'error';
                const declinedDate = supplerDeclined[0].node.Responded;
                status.message = (
                    <Fragment>
                        {contact.Name}
                        {(!!declinedDate && ` declined this request on ${niceDateTimeFromString(declinedDate)}`) ||
                            ` has been cancelled`}
                        . Please ask someone else.
                        {!!supplerDeclined[0].node.Comment && (
                            <div className={classes.response}>&ldquo;{supplerDeclined[0].node.Comment}&rdquo;</div>
                        )}
                    </Fragment>
                );
                checkbox.label = 'Declined';
                checkbox.disabled = true;
            } else if (item.Requested) {
                status.messageType = 'info';
                status.message = (
                    <Fragment>
                        The {actionType.toLowerCase()} request was sent to{' '}
                        <u style={{ wordBreak: 'break-all' }}>{contact.Email}</u> {moment(item.Requested).fromNow()} and
                        they have not responded yet.
                    </Fragment>
                );
            } else {
                if (!contact.Email) {
                    status.messageType = 'warning';
                    status.message = (
                        <Fragment>{contact.Name} requires that you contact them directly for this request.</Fragment>
                    );
                } else {
                    status.messageType = 'warning';
                    status.message = <Fragment>{contact.Name} has not been sent any request emails.</Fragment>;
                }
            }
        }

        return (
            <Fragment key={index}>
                {(!item.Cancelled && (
                    <Fragment>
                        <RequestBookingModal
                            disabled={disabled}
                            open={requestModalOpen === item.ID && !!requestModalOpen}
                            booking={item}
                            contact={contact}
                            label={labelSupplier}
                            isOrder={isOrder}
                            funeralInfo={form.fields}
                            hasVenue={
                                (forceVenue && form.getField(forceVenue)) ||
                                (eventField && form.getField(eventField)) ||
                                form.getField('PlaceOfService')
                            }
                            onClose={reload => {
                                const me = this;
                                if (reload && form.doRefetch)
                                    form.doRefetch(() => me.setState({ requestModalOpen: false }));
                                else this.setState({ requestModalOpen: false });
                            }}
                        />
                        <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                            {isEnabled => {
                                return (
                                    <PurchaseOrderModal
                                        canEdit={isEnabled && !disabled}
                                        disabled={disabled}
                                        open={orderModalOpen === item.ID && !!orderModalOpen}
                                        booking={item}
                                        contact={contact}
                                        funeralID={form.getField('ID')}
                                        eventObj={
                                            (forceVenue && form.getField(forceVenue)) ||
                                            (eventField && form.getField(eventField))
                                        }
                                        label={labelSupplier}
                                        isOrder={isOrder}
                                        purchaseOrderField="PurchaseOrder"
                                        mutation={
                                            item.ID
                                                ? createBookingPurchaseOrderMutation
                                                : createNewBookingPurchaseOrderMutation
                                        }
                                        onClose={reload => {
                                            const me = this;
                                            if (reload && form.doRefetch)
                                                form.doRefetch(() => me.setState({ orderModalOpen: false }));
                                            else this.setState({ orderModalOpen: false });
                                        }}
                                    />
                                );
                            }}
                        </GatedComponent>

                        <p className={classes.itemSelected}>
                            <Label text={'Selected ' + labelSupplier + (field.length > 1 ? ' #' + (1 + index) : '')} />
                            {(isOrder || forceVenue) && (
                                <span style={{ float: 'right', fontSize: '0.875rem' }}>
                                    <LinkButton
                                        disabled={disabled}
                                        text={'Change ' + labelSupplier}
                                        onClick={() => {
                                            this.setContact(null);
                                        }}
                                    />
                                </span>
                            )}
                        </p>

                        <DisplayContact disabled={disabled} contactItem={contact} />
                    </Fragment>
                )) || (
                    <p className={classes.itemSelected}>
                        <Label text={'Selected ' + labelSupplier + (field.length > 1 ? ' #' + (1 + index) : '')} />
                    </p>
                )}
                {<BiggerMessageBar messageType={status.messageType}>{status.message}</BiggerMessageBar>}
                {!item.Cancelled && (
                    <Fragment>
                        <div className={classes.confirmationActions}>
                            <Label text="Confirmation status" className={classes.inlineInputs} />
                            <Checkbox
                                disabled={checkbox.disabled || contact.NotCurrent || disabled}
                                label={checkbox.label}
                                checked={item.Confirmed}
                                onChange={e => this.setConfirmation(e, index)}
                            />
                            {contact.Email &&
                                !contact.NotCurrent &&
                                !(supplerDeclined.length > 0) &&
                                (item.Requested ? (
                                    <OutlineButton
                                        onClick={() => this.setState({ requestModalOpen: item.ID })}
                                        disabled={!!form.isDirty || disabled}
                                    >
                                        <Icon>mail</Icon>
                                        Resend Request...
                                    </OutlineButton>
                                ) : (
                                    <AltButton
                                        onClick={() => this.setState({ requestModalOpen: item.ID })}
                                        disabled={!!form.isDirty || disabled}
                                    >
                                        <Icon>mail</Icon>
                                        Send Request...
                                    </AltButton>
                                ))}
                            {(!!item.PurchaseOrder && !isRelatedObjectUndefined(item.PurchaseOrder) && (
                                <span>
                                    <PurchaseInvoiceModal
                                        hasPurchaseOrder={item.PurchaseOrder}
                                        labelSupplier={labelSupplier}
                                        form={form}
                                    />
                                </span>
                            )) ||
                                (item.Confirmed && allowPurchaseOrder && !contact.NotCurrent && (
                                    <AltButton
                                        onClick={e => this.setState({ orderModalOpen: item.ID })}
                                        disabled={!!form.isDirty || disabled}
                                    >
                                        <QuotesIcon />
                                        Create Bill...
                                    </AltButton>
                                ))}
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }

    setConfirmation = (e, index) => {
        const { form, name, forceVenue } = this.props;
        const newState = {};
        const isConfirmed = !!e.target.checked || false;
        if (forceVenue) {
            if (forceVenue.indexOf('[') > 0) {
                const myObject = forceVenue.replace(/\[.*$/, '');
                const myIndex = forceVenue.replace(/.*\[(\d+)\]$/, '$1');
                newState[myObject] = form.getField(myObject);
                newState[myObject][myIndex].Confirmed = isConfirmed;
                newState[myObject][myIndex].Bookings[index].Confirmed = isConfirmed;
            } else {
                newState[forceVenue + '.Confirmed'] = isConfirmed;
                newState[name] = form.getField(name) || [{}];
                newState[name][index].Confirmed = isConfirmed;
            }
        } else {
            newState[name] = form.getField(name) || [{}];
            newState[name][index].Confirmed = isConfirmed;
        }
        form.setField(newState);
    };

    setContact = (newContact, event) => {
        if (event) event.preventDefault();
        const { name, form, index = 0, forceVenue, labelSupplier } = this.props;
        const wasData = form.getField(name) || [];
        const item = wasData && wasData[index];
        if (!!item) {
            if (item.Confirmed) {
                if (!window.confirm('This ' + labelSupplier + ' was confirmed, are you sure?')) return;
            } else if (item.Requested) {
                if (!window.confirm('This ' + labelSupplier + ' was requested, are you sure?')) return;
            }
        } else {
            wasData[index] = {};
        }

        const newState = {};
        if (forceVenue) {
            const oldVenue = form.getField(forceVenue) || {};
            if (!oldVenue.Bookings) oldVenue.Bookings = [{}];
            oldVenue.Location = newContact;
            oldVenue.Confirmed = false;
            oldVenue.EventConflicts = { nodes: [] };
            if (forceVenue.indexOf('[') > 0) {
                const myField = forceVenue.replace(/\[.*$/, '');
                const myIndex = forceVenue.replace(/.*\[(\d+)\]$/, '$1');
                newState[myField] = form.getField(myField);
                newState[myField][myIndex] = oldVenue;
            } else {
                newState[forceVenue] = oldVenue;
            }
        } else {
            newState[name] = form.getField(name);
        }
        setProperty(newState, `${name}[${index}].PurchaseOrder`, null);
        setProperty(newState, `${name}[${index}].Confirmed`, false);
        if (forceVenue && !newContact) {
            // cancel this booking so another gets injected
            setProperty(newState, `${name}[${index}].Cancelled`, true);
        } else {
            setProperty(newState, `${name}[${index}].Contact`, newContact);
        }
        form.setField(newState);
    };
}

export const nameQuery = gql`
    query ContactLookup($id: ID!) {
        readOneAddressBook(ID: $id) {
            ID
            LegacyKey
            Name
            Contact
            Email
            Phone
            Fax
            Mobile
            State
            Suburb
            Postcode
            AddressBookCategory
            AddressLine1
            AddressLine2
            Notes
            ClergyBaseRate
            ClergyFollowToCommittalRate
            ClergyFollowAfterRefreshmentsRate
            ClergyCategory
            Religion
            NotCurrent
        }
    }
`;

const styles = ({ typography, palette }) => ({
    inlineInputs: {
        marginRight: '1rem'
    },
    response: {
        clear: 'both',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        whiteSpace: 'pre-line'
    },
    itemSelected: {
        margin: '6px 0!important'
        //lineHeight: typography.body2.lineHeight,
        //borderTop: `1px solid ${palette.divider}`,
        //paddingTop: 8
    },
    confirmationActions: {
        margin: '8px 0',
        '& > button, & > span': {
            float: 'right',
            marginBottom: 6,
            marginLeft: 6
        }
    }
});

export default withStyles(styles)(SupplierBooking);
