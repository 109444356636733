import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RatingCard from './RatingCard';
import FlexGrid from '../../page/workQueue2/extras/FlexGrid';

export default withStyles({})(({ rate, loading, type }) => {
    return (
        <div>
            <h3 className="metrics">{type} Conversion Rate</h3>
            <FlexGrid className="rating-bar-container">
                {!loading && (
                    <div className="score-indicator-container" data-rate={rate} style={{ left: rate }}>
                        <div className="score-indicator" />
                    </div>
                )}
                <RatingCard variant="twenty" />
                <RatingCard variant="forty" />
                <RatingCard variant="sixty" />
                <RatingCard variant="eighty" />
                <RatingCard variant="hundred" />
            </FlexGrid>
        </div>
    );
});
