import RecentIcon from '../icon/RecentIcon';
import CalendarIcon from '../icon/CalendarIcon';
import ReportsIcon from '../icon/ReportsIcon';
import CateringIcon from '../icon/CateringIcon';
import WorkQueueIcon from '../icon/WorkQueueIcon';

export const items = [
    {
        path: '/search/recent',
        label: 'Recent',
        icon: RecentIcon,
        permission: 'FM_ACCESS_Funeral_View'
    },
    {
        path: '/work-queue',
        label: 'Task Queue',
        icon: WorkQueueIcon,
        permission: 'FM_ACCESS_Tasks_View'
    },
    {
        path: '/calendar',
        label: 'Calendar',
        icon: CalendarIcon,
        permission: 'FM_ACCESS_Calendar_View'
    },
    {
        path: '/daily-run-sheet',
        label: 'Run Sheet',
        icon: ReportsIcon,
        permission: 'OBJ_EDIT_ALL'
    },
    {
        path: '/catering-usage',
        label: 'Catering',
        icon: CateringIcon,
        permission: 'CMS_ACCESS_FuneralManager\\Admin\\CateringStaffAdmin'
    }
];
