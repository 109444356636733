import { Query } from 'react-apollo';
import React, { Fragment } from 'react';
import { getAuthClient, resetClient } from '../apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import { IndexOf } from '../util/arrays';
import { clearToken } from '../util/sessions';
import { setSnackbarMessage } from '../context/SnackbarMessage';

class GatedComponent extends React.Component {
    render() {
        const { showLoading, showComponentCode, isEnabledCode, children } = this.props;
        if (!showComponentCode && !isEnabledCode) {
            return children();
        }

        return (
            <Fragment>
                <Query query={permissionsQuery} client={getAuthClient()}>
                    {({ data, loading }) => {
                        if (loading) return showLoading ? <Spinner /> : null;
                        const user = data && data.readCurrentUser;
                        if (!user) {
                            clearToken();
                            resetClient();
                            setSnackbarMessage('Unrecognised user was logged out.');
                            return null;
                        }
                        const userCodes = JSON.parse(user.AllPermissions);

                        if (
                            showComponentCode &&
                            IndexOf(userCodes, x => x === showComponentCode || x === 'ADMIN') < 0
                        ) {
                            return null;
                        }

                        let isEnabled = !!(
                            isEnabledCode && IndexOf(userCodes, x => x === isEnabledCode || x === 'ADMIN') >= 0
                        );

                        return children(isEnabled, data);
                    }}
                </Query>
            </Fragment>
        );
    }
}

GatedComponent.propTypes = {
    children: PropTypes.func.isRequired
};

export const CurrentUserFragment = gql`
    fragment CurrentUserFragment on Member {
        ID
        FirstName
        Surname
        Email
        Phone
        AllPermissions
        MyLastOfficeID
        Groups {
            ID
            Code
            Title
        }
    }
`;

export const permissionsQuery = gql`
    ${CurrentUserFragment}
    query {
        readCurrentUser {
            ...CurrentUserFragment
        }
    }
`;

export default GatedComponent;
