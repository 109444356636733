import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose, Query } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../../component/form/Grid';
import TextField from '../../component/form/TextField';
import gql from 'graphql-tag';
import DataForm from '../../component/form/DataForm';
import Select from '../../component/form/Select';
import { CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from './contactConstants';
import GatedComponent from '../../component/GatedComponent';
import Checkbox from '../../component/form/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import PrimaryButton, { OutlineButton } from '../../component/form/PrimaryButton';
import { CloseIcon, ContactsIcon, EditIcon } from '../../component/IconIndex';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import cx from 'classnames';
import { Hidden } from '@material-ui/core';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import Spinner from '../../component/Spinner';
import AddressAutocomplete from '../../component/form/AddressAutocomplete';
import { joinDefined } from '../../util/strings';

class ContactView extends React.Component {
    state = {
        open: false,
        readOnly: true,
        loadedData: {}
    };

    constructor(props) {
        super(props);
        if (props.editable === true) {
            this.state.readOnly = false;
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, readOnly: true });
    };

    render() {
        const { contactId, children, classes } = this.props;
        return (
            <Fragment>
                <span onClick={this.handleClickOpen}>{children}</span>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    disableBackdropClick={!this.state.readOnly}
                    disableEscapeKeyDown={!this.state.readOnly}
                    classes={{ paper: cx(classes.root, 'dialog-modal modal') }}
                >
                    <Query query={contactQuery} variables={{ id: contactId }}>
                        {results => this.renderContent(results)}
                    </Query>
                </Dialog>
            </Fragment>
        );
    }

    renderContent = ({ loading, error, data }) => {
        const closeButton = (
            <OutlineButton onClick={this.handleClose}>
                <CloseIcon />
                Close
            </OutlineButton>
        );

        if (error) {
            return (
                <div style={{ padding: 150 }}>
                    <BiggerMessageBar messageType="error">Error: Can't read data!</BiggerMessageBar>
                    {closeButton}
                </div>
            );
        }

        const contactEdges = data.readAddressBooks && data.readAddressBooks.edges;

        //start to run Query, return loading message.
        if (!contactEdges) {
            return (
                <div style={{ padding: 150, textAlign: 'center' }}>
                    <p>
                        <Spinner /> Loading...
                    </p>
                </div>
            );
        }

        //loaded, but no edges, return not found message.
        if (contactEdges.length <= 0) {
            return (
                <div style={{ padding: 150 }}>
                    <BiggerMessageBar messageType="warning">Contact Not Found!</BiggerMessageBar>
                    {closeButton}
                </div>
            );
        }

        const contactData = contactEdges[0].node;
        const { classes } = this.props;

        return (
            <DataForm
                id={contactData.id}
                loading={loading}
                error={error}
                data={data && contactData}
                buttonStyles={classes.saveButt}
                mutation={this.getUpdate()}
            >
                {(form, saveButton) => {
                    return (
                        <Fragment>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    overflow: 'hidden',
                                    pointerEvents: 'none'
                                }}
                            >
                                <ContactsIcon
                                    style={{
                                        opacity: 0.025,
                                        width: 300,
                                        height: 300,
                                        position: 'absolute',
                                        right: -24,
                                        top: 124,
                                        transform: 'rotate(20deg)',
                                        zIndex: 0
                                    }}
                                />
                            </div>
                            <DialogTitle className={classes.diagHeader}>
                                <Hidden xsDown>
                                    <ContactsIcon className={classes.diagHeaderIcon} />
                                    <div className={classes.diagHeaderTitle}>
                                        {!this.state.readOnly ? 'Edit' : 'View'} #{form.getField('ID')}
                                    </div>
                                    <div className={classes.diagHeaderSubtitle}>Contact Details</div>
                                </Hidden>
                                <Hidden smUp>
                                    <div className={classes.diagHeaderTitle}>Contact Details</div>
                                </Hidden>
                            </DialogTitle>
                            <DialogContent className={classes.content}>
                                <Grid container spacing={24}>
                                    <Grid pc={1}>
                                        <TextField
                                            name="Name"
                                            label="Business Name"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={1}>
                                        {this.state.readOnly ? (
                                            <TextField
                                                readOnly
                                                label="Street Address"
                                                value={joinDefined(
                                                    [
                                                        joinDefined(
                                                            [
                                                                form.getField('AddressLine1'),
                                                                form.getField('AddressLine2'),
                                                                form.getField('Suburb')
                                                            ],
                                                            ', '
                                                        ),
                                                        form.getField('Postcode'),
                                                        form.getField('State')
                                                    ],
                                                    ' '
                                                )}
                                            />
                                        ) : (
                                            <AddressAutocomplete
                                                label={'Search for a street address'}
                                                placeholder="Search for a street address"
                                                componentFields={{
                                                    line1: 'AddressLine1',
                                                    line2: 'AddressLine2',
                                                    city: 'Suburb',
                                                    state: 'State',
                                                    //country: 'country',
                                                    code: 'Postcode'
                                                }}
                                                form={form}
                                            />
                                        )}
                                    </Grid>

                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Phone"
                                            label="Primary Number"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Mobile"
                                            label="Secondary Number"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Email"
                                            label="Email"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="Fax"
                                            label="Fax"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={1}>
                                        <TextField
                                            name="Contact"
                                            label="Primary Contact Person"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <Select
                                            name="AddressBookCategory"
                                            label="Main Category"
                                            form={form}
                                            selectProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                            options={CONTACT_CATEGORY_OPTIONS}
                                        />
                                    </Grid>

                                    {form.getField('AddressBookCategory') === 'DOCTOR' && (
                                        <Grid pc={0.5}>
                                            <TextField
                                                name="RegistrationNoMisc"
                                                label="Registration Number"
                                                form={form}
                                                InputProps={{
                                                    readOnly: this.state.readOnly
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    {form.getField('AddressBookCategory') === 'CLERGY' && (
                                        <Fragment>
                                            <Grid pc={0.5}>
                                                <Select
                                                    name="ClergyCategory"
                                                    label="Religious affiliation"
                                                    form={form}
                                                    selectProps={{
                                                        readOnly: this.state.readOnly
                                                    }}
                                                    options={CONTACT_CLERGY_OPTIONS}
                                                    onChange={e => {
                                                        const newState = { ClergyType: null };
                                                        const val = Number(e.target.value);
                                                        if (val === 1 || val === 2) newState.ClergyType = 'CELEBRANT';
                                                        form.setField(newState);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid pc={1}>
                                                <TextField
                                                    name="Religion"
                                                    label="Extra categorisation details"
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: this.state.readOnly
                                                    }}
                                                />
                                            </Grid>
                                        </Fragment>
                                    )}
                                    <div style={{ width: '100%' }} />
                                    <Grid pc={0.5}>
                                        <Checkbox
                                            name="FeesInclGst"
                                            label="Fees include GST"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                        />
                                    </Grid>
                                    <Grid pc={0.5}>
                                        <TextField
                                            name="ClergyBaseRate"
                                            label="Base Rate"
                                            type={'number'}
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly,
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    {form.getField('AddressBookCategory') === 'CLERGY' && (
                                        <>
                                            <Grid pc={0.5}>
                                                <TextField
                                                    name="ClergyFollowToCommittalRate"
                                                    label="Attend Committal"
                                                    type={'number'}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: this.state.readOnly,
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid pc={0.5}>
                                                <TextField
                                                    name="ClergyFollowAfterRefreshmentsRate"
                                                    label="Attend Refreshments"
                                                    type={'number'}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: this.state.readOnly,
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid pc={1}>
                                        <TextField
                                            name="Notes"
                                            label="Notes"
                                            form={form}
                                            InputProps={{
                                                readOnly: this.state.readOnly
                                            }}
                                            multiline={true}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={cx(classes.diagActions, 'modal-footer', 'paper')}>
                                {this.renderActionButtons(form, saveButton)}
                            </DialogActions>
                        </Fragment>
                    );
                }}
            </DataForm>
        );
    };

    renderActionButtons(form, saveButton) {
        const { disabled } = this.props;
        if (this.state.readOnly) {
            return (
                <InlineFieldRightAlignChildren>
                    <div>
                        <OutlineButton onClick={this.handleClose}>
                            <CloseIcon />
                            Close
                        </OutlineButton>
                    </div>
                    {!disabled && (
                        <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Create'}>
                            {() => {
                                return (
                                    <PrimaryButton onClick={e => this.setState({ readOnly: false })}>
                                        <EditIcon />
                                        Edit
                                    </PrimaryButton>
                                );
                            }}
                        </GatedComponent>
                    )}
                </InlineFieldRightAlignChildren>
            );
        } else {
            return (
                <InlineFieldRightAlignChildren>
                    <div>
                        {form.isDirty ? (
                            <OutlineButton onClick={this.handleClose}>
                                <CloseIcon />
                                Cancel
                            </OutlineButton>
                        ) : (
                            <OutlineButton onClick={this.handleClose}>
                                <CloseIcon />
                                Close
                            </OutlineButton>
                        )}
                    </div>
                    {saveButton}
                </InlineFieldRightAlignChildren>
            );
        }
    }

    getUpdate = () => {
        return gql`
            mutation UpdateAddressBook($input: UpdateAddressBookInput!) {
                updateAddressBook(input: $input) {
                    ID
                    LastEdited
                    Created
                    Name
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    Phone
                    Fax
                    Email
                    Contact
                    AddressBookCategory
                    Mobile
                    Religion
                    Salutation
                    RegistrationNoMisc
                    NotCurrent
                    FeesInclGst
                    ClergyBaseRate
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                    Notes
                    Biography
                    ClergyType
                    ClergyCategory
                    PlaceOfServiceCategory
                    ExcludeFromTabletDisplay
                }
            }
        `;
    };
}

const contactQuery = gql`
    query($id: String) {
        readAddressBooks(id: $id) {
            edges {
                node {
                    ID
                    ClassName
                    LastEdited
                    Created
                    LegacyID
                    LegacyKey
                    Name
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    Phone
                    Fax
                    Email
                    Contact
                    AddressBookCategory
                    AsPlaceOfDeath
                    Mobile
                    Religion
                    Salutation
                    RegistrationNoMisc
                    NotCurrent
                    FeesInclGst
                    ClergyBaseRate
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                    Office
                    Notes
                    Biography
                    ClergyType
                    ClergyCategory
                    PlaceOfServiceCategory
                    ExcludeFromTabletDisplay
                    Address {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ClergyAllocationsCodes {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    NotusedAssociatedChargesDescription {
                        AllItems {
                            Key
                            Value
                        }
                    }
                    ClergyPlaceOfServiceCodes {
                        AllItems {
                            Key
                            Value
                        }
                    }
                }
            }
        }
    }
`;

const styles = ({ breakpoints, spacing, palette, funeralHome }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset',
        [breakpoints.down('xs')]: {
            margin: spacing.unit
        }
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: spacing.unit * 3
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    diagHeaderIcon: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    },
    content: {
        width: '100%',
        padding: spacing.unit * 3,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340,
        backgroundColor: '#fafafa',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 12rem)',
            padding: spacing.unit * 2
        }
    },
    diagActions: {
        padding: spacing.unit * 3,
        paddingTop: spacing.unit * 2,
        margin: 0,
        justifyContent: 'space-between'
    },
    saveButt: {
        position: 'static!important',
        minHeight: 'unset!important'
    }
});

export default compose(withRouter, withStyles(styles))(ContactView);
