import Summary from './Summary';
import SummaryFragment from './SummaryFragment';

export default {
    label: 'Summary',
    component: Summary,
    fragment: SummaryFragment,
    onLoad: data => {
        // create a stub to detect new billing notes on save!
        if (!!data.BillingNotes) {
            data.OldBillingNotes = '' + (data.BillingNotes || '');
        }
        delete data.BillingNotes;
    },
    formatSaveData: (saveData, state) => {
        if (saveData.LegacyDocuments) {
            saveData.LegacyDocuments = saveData.LegacyDocuments.map(({ ID }) => ({ ID }));
        }
    },
    validation: {
        required: [],
        suggested: [],
        onValidate: {}
    }
};
