import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 44 39.111" {...props}>
        <path
            fill="currentColor"
            d="M42.166,4.889H9.876L9.239,1.5A1.833,1.833,0,0,0,7.437,0H.917A.917.917,0,0,0,0,.917v.611a.917.917,0,0,0,.917.917H6.93l5.342,28.489a4.889,4.889,0,1,0,7.85.844H31.211a4.889,4.889,0,1,0,7.641-1.059,1.834,1.834,0,0,0-1.779-1.385H14.459l-.917-4.889H38.856a1.833,1.833,0,0,0,1.795-1.459L43.961,7.1A1.833,1.833,0,0,0,42.166,4.889ZM35.444,31.778A2.444,2.444,0,1,1,33,34.222,2.447,2.447,0,0,1,35.444,31.778Zm-19.556,0a2.444,2.444,0,1,1-2.444,2.444A2.447,2.447,0,0,1,15.889,31.778ZM38.359,22H13.084L10.334,7.333H41.415ZM20.778,14.972v-.611a.917.917,0,0,1,.917-.917h2.75v-2.75a.917.917,0,0,1,.917-.917h.611a.917.917,0,0,1,.917.917v2.75h2.75a.917.917,0,0,1,.917.917v.611a.917.917,0,0,1-.917.917h-2.75v2.75a.917.917,0,0,1-.917.917h-.611a.917.917,0,0,1-.917-.917v-2.75h-2.75A.917.917,0,0,1,20.778,14.972Z"
        />
    </SvgIcon>
);
