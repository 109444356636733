import React from 'react';
import Select from './Select';
import { withStyles } from '@material-ui/core/styles';

const TimeSelect = ({ form, name, allowNone, ...props }) => {
    return <Select options={TIME_OPTIONS} name={name} form={form} allowNone={allowNone || false} {...props} />;
};

// NOTE: your values will need to be strings, numbers are not supported!
export const TIME_OPTIONS = [
    { label: '0 min', value: 0.0 },
    { label: '15 min', value: 0.25 },
    { label: '30 min', value: 0.5 },
    { label: '45 min', value: 0.75 },
    { label: '1 hr', value: 1.0 },
    { label: '1.5 hr', value: 1.5 },
    { label: '2 hrs', value: 2.0 },
    { label: '2.5 hrs', value: 2.5 },
    { label: '3 hrs', value: 3.0 },
    { label: '3.5 hrs', value: 3.5 },
    { label: '4 hrs', value: 4.0 },
    { label: '4.5 hrs', value: 4.5 },
    { label: '5 hrs', value: 5.0 },
    { label: '5.5 hrs', value: 5.5 },
    { label: '6 hrs', value: 6.0 },
    { label: '6.5 hrs', value: 6.5 },
    { label: '7 hrs', value: 7.0 },
    { label: '7.5 hrs', value: 7.5 },
    { label: '8 hrs', value: 8.0 }
];

const styles = () => {};

export default withStyles(styles)(TimeSelect);
