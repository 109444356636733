import gql from 'graphql-tag';

export default gql`
    fragment Enquiry on Enquiry {
        ID
        Created
        LastEdited
        LegacyKey
        EnquiryType
        PreneedType
        PhoneType
        Notes
        Cancelled
        EnquirerTitle
        EnquirerGivenName
        EnquirerMiddleName
        EnquirerSurname
        EnquirerEmail
        EnquirerPhone
        EnquirerMobile
        RelationshipToDeceased
        EnquirerPostalAddress1
        EnquirerPostalAddress2
        EnquirerPostalSuburb
        EnquirerPostalState
        EnquirerPostalPostcode
        GivenName
        MiddleName
        Surname
        Gender
        DateOfBirth
        Situation
        DateOfDeathType
        DateOfDeath1
        DateOfDeath2
        ResidentialAddress1
        ResidentialAddress2
        ResidentialSuburb
        ResidentialState
        ResidentialCountry
        ResidentialPostcode
        PostalUseResidentialAddress
        DeathUseResidentialAddress
        TransferUsePlaceOfDeathAddress
        PlaceOfDeathType
        TransferFromType
        UnknownDOB
        CrematedOrBuried

        PlaceOfDeath {
            ID
            Name
        }
        TransferFrom {
            ID
            Name
        }
        EnquirerResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        PlaceOfDeathResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        TransferFromResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        Office {
            ID
            RegistrantSuffix
            BusinessName
            Phone
        }
        Arrangers {
            ID
        }
        Coordinators {
            ID
            Allocation
            Member {
                ID
                Surname
                FirstName
                Email
            }
            Funeral {
                ID
            }
            Enquiry {
                ID
            }
        }
        Funeral {
            ID
            FirstName
            Surname
            LegacyKey
            OnlineOrder {
                ID
                FAID
                FAOrderID
            }
        }
        Prearrangement {
            ID
            LegacyKey
            LegacyDocuments {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
        Prearranged {
            ID
            LegacyKey
        }
        LegacyDocuments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
        CreatedBy {
            ID
            Email
            FirstName
            Surname
        }
        PriorEnquiry {
            ID
            LegacyKey
        }
        LaterEnquiries {
            ID
            LegacyKey
        }
    }
`;
