import gql from 'graphql-tag';

export default gql`
    fragment ValuableItems on Funeral {
        ValuableItems {
            ID
            Description
            Quantity
            ToBePlaced
            CurrentLocation
            CurrentLocationDetail
            ToBeReturned
            WhereToBeReturned
            Signed
            Note
        }
    }
`;
