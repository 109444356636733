import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

class TaskCardContainer extends Component {
    render() {
        const { classes, className, children } = this.props;
        return (
            <Fragment>
                <div className={cx(classes.root, className)}>
                    {children.map((C, index) => (
                        <div key={index}>{C}</div>
                    ))}
                </div>
            </Fragment>
        );
    }
}

const styles = ({ breakpoints }) => ({
    root: {
		display: 'grid',
        gridGap: '18px 3%',
        gridTemplateColumns: 'repeat(auto-fit, 100%)',
        [breakpoints.up('xs')]: {
			gridGap: '18px 3%',
            gridTemplateColumns: '100%',
        },
        [breakpoints.up('sm')]: {
			gridGap: '18px 2.5%',
            gridTemplateColumns: 'repeat(auto-fit, 48%)',
        },
		[breakpoints.up('md')]: {
			gridGap: '18px 2%',
            gridTemplateColumns: 'repeat(auto-fit, 32%)',
        },
        [breakpoints.up('lg')]: {
			gridGap: '18px 1.5%',
            gridTemplateColumns: 'repeat(auto-fit, 23.5%)',
        },
        [breakpoints.up('xl')]: {
			gridGap: '18px 18px',
            gridTemplateColumns: 'repeat(auto-fit, 18.95%)',
        },
        [breakpoints.up('xxl')]: {
            gridTemplateColumns: 'repeat(auto-fit, 11.7%)',
        }
    }
});

export default withStyles(styles)(TaskCardContainer);
