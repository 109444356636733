import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = ({ palette, funeralHome }) => ({
    bucket: {
        borderRadius: 10,
        margin: 0,
        marginBottom: 4,
        paddingBottom: 12,
        border: '1px solid transparent',
        '&:hover, .active': {
            backgroundColor: palette.contentBackground[funeralHome]
        }
    }
});

export default withStyles(styles)(
    ({ pc, children, bucket, style, className, classes, spacing, xs, sm, md, lg, xl, item, container, key }) => {
        let pcProps;
        if (typeof pc !== 'undefined' || bucket === true) {
            pcProps = {
                item: true,
                xs: 12,
                sm: Math.floor(12 * (pc || 1))
            };
        }

        if (bucket) {
            pcProps.container = true;
            pcProps.spacing = 16;
        }

        return (
            <Grid
                style={style}
                className={bucket ? cx(className, classes.bucket) : className}
                spacing={spacing}
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                xl={xl}
                item={item}
                container={container}
                key={key}
                {...pcProps}
            >
                {children}
            </Grid>
        );
    }
);
