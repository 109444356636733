import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 384 512" {...props}>
        <path
            fill="currentColor"
            d="M280,240H168a8,8,0,0,0-8,8v16a8,8,0,0,0,8,8H280a8,8,0,0,0,8-8V248A8,8,0,0,0,280,240Zm0,96H168a8,8,0,0,0-8,8v16a8,8,0,0,0,8,8H280a8,8,0,0,0,8-8V344A8,8,0,0,0,280,336ZM112,232a24,24,0,1,0,24,24A23.94,23.94,0,0,0,112,232Zm0,96a24,24,0,1,0,24,24A23.94,23.94,0,0,0,112,328ZM336,64H247.4a52.87,52.87,0,0,0,.6-8,56,56,0,0,0-112,0,52.87,52.87,0,0,0,.6,8H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V112A48,48,0,0,0,336,64ZM192,32a24,24,0,1,1-24,24A23.94,23.94,0,0,1,192,32ZM352,464a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V112A16,16,0,0,1,48,96H96v20a12,12,0,0,0,12,12H276a12,12,0,0,0,12-12V96h48a16,16,0,0,1,16,16Z"
        />
    </SvgIcon>
);
