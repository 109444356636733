import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { getCalendarClient, getEnquiryClient } from '../../apollo';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TickIcon from '../../component/icon/TickIcon';
import Grid from '../../component/form/Grid';
import { InlineField, InlineFieldRightAlignChildren, InlineHeader } from '../../component/form/Inline';
import ColumnLayout from '../../component/ColumnLayout';
import Label from '../../component/form/Label';
import Select from '../../component/form/Select';
import AlertModal from '../../component/form/AlertModal';
import TextField from '../../component/form/TextField';
import {
    DATE_OF_DEATH_OPTION,
    DATE_OF_DEATH_OPTIONS,
    GENDER_OPTIONS,
    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS,
    PLACE_OF_DEATH_TYPE,
    TRANSFER_FROM_RESIDENTIAL_COMPONENTS
} from '../funeral/deceased/DeceasedConstants';
import AddressAutocomplete, { COMPONENT } from '../../component/form/AddressAutocomplete';
import Checkbox from '../../component/form/Checkbox';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import * as moment from 'moment';
import DataForm from '../../component/form/DataForm';
import { joinDefined, prettyPrice } from '../../util/strings';
import cx from 'classnames';
import { compose, Query } from 'react-apollo';
import PrimaryButton, { OutlineButton, SaveButton } from '../../component/form/PrimaryButton';
import TableData from '../../component/form/TableData';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../component/form/Table';
import QuotesModal from './quotes-modal/QuotesModal';
import { quoteTypes } from '../../util/quotes';
import { getToken, getUser } from '../../util/sessions';
import { isNullOrUndefined } from '../../util/objects';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppointmentPopover from '../calendar/AppointmentPopover';
import { cloneDeep } from 'apollo-utilities';
import { IndexOf } from '../../util/arrays';
import { ADDRESS_BOOK_CATEGORY } from '../funeral/funeralConstants';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../component/form/AddressBookAutocomplete';
import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '../../component/icon/EditIcon';
import DeleteIcon from '../../component/icon/DeleteIcon';
import Spinner from '../../component/Spinner';
import { ALLOCATION, ALLOCATION_OPTIONS } from '../funeral/summary/SummaryConstants';
import StaffAutoComplete from '../../component/form/StaffAutoComplete';
import {
    cleanUpConnections,
    copyEnquiry,
    createEnquiry,
    createFuneralAsync,
    createOnlineOrderAsync,
    createPrearrangementAsync,
    ENQUIRY_TYPES,
    PhoneTypes,
    PreNeedTypes,
    queryEnquiry,
    TitleOptions,
    updateEnquiry,
    validation,
    viewCalendar
} from './EnquiryConstants';
import { isRelatedObjectDefined } from '../../util/bookable';
import GatedComponent from '../../component/GatedComponent';
import SearchIcon from '../../component/icon/SearchIcon';
import EnquirySearchModal from './EnquirySearchModal';
import DocumentModal from '../funeral/billing/documentModal/DocumentModal';
import LinkButton from '../../component/form/LinkButton';
import FileUpload, { getFileName } from '../../component/form/FileUpload';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../funeral/certification/CertificationConstants';
import AutoComplete from '../../component/form/AutoComplete';
import RadioGroup from '../../component/form/RadioGroup';
import ValidationPlaceholder from '../../component/form/ValidationPlaceholder';
import { validationHelper } from '../../util/validation';
import RecordDrawer from '../workQueue2/RecordDrawer';
import HistoryField from '../../component/form/HistoryField';
import CommunicationNotes from '../../component/modal/CommunicationNote';
import SaveIcon from '../../component/icon/SaveIcon';
import { Quote } from './fragments/Quote';
import { cleanUpQuoteConnections } from './QuotesConstants';
import { Dialog, DialogContent } from '@material-ui/core';
import { WhiteLoader } from '../../component/WhiteLoader';
import { ArrangementIcon, CalendarIcon, CartIcon, NoteIcon, PayableIcon, QuotesIcon } from '../../component/IconIndex';
import { getOffices, getSiteConfig } from '../../environment';
import SelectOffice from '../../component/form/SelectOffice';
import { getOfficeFromKey } from '../../util/brands';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';
import AppointmentPill from '../calendar/AppointmentPill';

class Enquiry extends Component {
    form = null;
    office = null;
    enquiryTypes = quoteTypes;
    state = {
        openSearchModal: false,
        searchModalSuggestion: null,
        enqType: '',
        loadingQuote: false,
        popupQuoteOpen: false,
        popupQuote: null,
        Quotes: null,
        addMeetingOpen: false,
        documentModal: { open: false, document: null },
        alertModalState: {
            open: false,
            title: '',
            message: '',
            onCancel: () => this.closeAlertModal(),
            onAccept: () => undefined
        },
        generating: false,
        optimalCoordinatorID: null
    };

    refetchApps = () => null;

    /*
     * massage form data when it is loaded, to preset form stuff that isn't in the data, and remove edges/nodes
     */
    presetData = data => {
        cleanUpConnections(data);
        this.updateSituation(data.EnquiryType);

        data.HiddenUnknownEmailField = false;
        if (data.ID) {
            if (data.EnquirerEmail === null) {
                data.HiddenUnknownEmailField = true;
            }
        }

        data.UnknownDOB = false;
        if (data.ID) {
            if (data.DateOfBirth === null) {
                data.UnknownDOB = true;
            }
        }
        if (!!data.Office) {
            data.OfficeID = data.Office.ID;
            delete data.Office;
        }
        if (!!data.RelationshipToDeceased) {
            const relation = APPLICANT_RELATIONSHIP_OPTIONS.find(
                e => e.value.toLowerCase() === data.RelationshipToDeceased
            );
            if (!!relation) data.RelationshipToDeceased = relation.value;
        }
        // need to reconstruct from the depreciated address field
        if (!!data.DeathUseResidentialAddress) {
            data.DeathUseResidentialAddress = false;
            data.PlaceOfDeathType = 'Residential';
            if (
                !(
                    !!data.PlaceOfDeathResidentialAddress &&
                    !!data.PlaceOfDeathResidentialAddress.ID &&
                    Number(data.PlaceOfDeathResidentialAddress.ID) > 0
                )
            ) {
                data.PlaceOfDeathResidentialAddress = {
                    AddressLine1: data.ResidentialAddress1,
                    AddressLine2: data.ResidentialAddress2,
                    Suburb: data.ResidentialSuburb,
                    State: data.ResidentialState,
                    Postcode: data.ResidentialPostcode
                };
            }
        }

        data.OldNotes = data.Notes;
        data.Notes = null;

        const getQuotes = async () => {
            const enquiries = [data.ID]
                .concat(
                    [data.PriorEnquiry && data.PriorEnquiry.ID],
                    data.LaterEnquiries.map(e => e.ID)
                )
                .filter(Boolean);

            return await getEnquiryClient().query({
                query: readQuoteSummaries,
                fetchPolicy: 'network-only',
                variables: {
                    filters: [{ field: 'EnquiryID', value: `[${enquiries.map(e => e)}]` }]
                }
            });
        };
        !!data.ID &&
            getQuotes().then(({ data }) => {
                if (data && data.readQuotes && data.readQuotes.edges) {
                    this.setState({ Quotes: data.readQuotes.edges.map(e => e.node) });
                }
            });

        !!data.OfficeID && this.setOptimalCoordinatorID(data.OfficeID);
    };

    officeFromKey = () => {
        const { key } = this.props.match.params;
        const office = getOfficeFromKey(key);
        return office ? office.ID : '0';
    };

    /*
     * massage form data when it is saving, to remove form stuff that isn't in the database
     */
    cleanupData = input => {
        const output = { ...input };

        const { form } = this;
        if (output.UnknownDOB) output.DateOfBirth = null;

        if (output.HiddenUnknownEmailField) output.EnquirerEmail = null;
        delete output.HiddenUnknownEmailField;

        if (output.Funeral && output.Funeral.ID) output.FuneralID = 1 * output.Funeral.ID;
        delete output.Funeral;

        const officeId = Number(form.getField('OfficeID'));
        if (isNaN(officeId) || officeId < 1) {
            output.OfficeID = '' + this.officeFromKey();
        } else {
            output.OfficeID = officeId;
        }
        delete output.Office;

        // weed out the depreciated fields
        output.DeathUseResidentialAddress = false;
        if (isRelatedObjectDefined(output.PlaceOfDeath) && form.getField('PlaceOfDeathType') !== 'Residential') {
            output.PlaceOfDeathID = output.PlaceOfDeath.ID;
            output.PlaceOfDeathType = 'AddressBook';
        } else if (
            !isRelatedObjectDefined(form.getField('PlaceOfDeathResidentialAddress')) &&
            form.getField('PlaceOfDeathType') === 'Residential'
        ) {
            output.PlaceOfDeathType = 'Residential';
            output.PlaceOfDeathResidentialAddress = form.getField('PlaceOfDeathResidentialAddress');
        }

        if (!form.getField('TransferUsePlaceOfDeathAddress')) {
            output.TransferUsePlaceOfDeathAddress = false;
            if (isRelatedObjectDefined(output.TransferFrom) && form.getField('TransferFromType') !== 'Residential') {
                output.TransferFromID = output.TransferFrom.ID;
            }
        } else output.TransferUsePlaceOfDeathAddress = true;

        delete output.PlaceOfDeath;
        delete output.TransferFrom;

        // These two handled elsewhere, don't let them seep in here.
        delete output.Quotes;
        delete output.Appointments;

        const rel = form.getField('RelationshipToDeceased') || '';
        const relTitle = form.getField('EnquirerTitle');
        const relFemale = ['Miss', 'Ms', 'Mrs'].indexOf(relTitle) > -1;
        const relMale = ['Mr', 'Fr', 'Rev'].indexOf(relTitle) > -1;
        if (rel === 'self') {
            output.ResidentialAddress1 = form.getField('EnquirerResidentialAddress.AddressLine1') || '';
            output.ResidentialAddress2 = form.getField('EnquirerResidentialAddress.AddressLine2') || '';
            output.ResidentialSuburb = form.getField('EnquirerResidentialAddress.Suburb') || '';
            output.ResidentialState = form.getField('EnquirerResidentialAddress.State') || '';
            output.ResidentialPostcode = form.getField('EnquirerResidentialAddress.Postcode') || '';
            output.GivenName = form.getField('EnquirerGivenName');
            output.Surname = form.getField('EnquirerSurname');
            output.Situation = 'alive';
        } else if (rel === 'none') {
            output.RelationshipToDeceased = 'no relation';
        } else if (rel === 'child') {
            if (relFemale) output.RelationshipToDeceased = 'daughter';
            else if (relMale) output.RelationshipToDeceased = 'son';
        } else if (rel === 'sibling') {
            if (relFemale) output.RelationshipToDeceased = 'sister';
            else if (relMale) output.RelationshipToDeceased = 'brother';
        }

        const relation = APPLICANT_RELATIONSHIP_OPTIONS.find(
            e => e.value.toLowerCase() === (output.RelationshipToDeceased || rel).toLowerCase()
        );
        if (!!relation) output.RelationshipToDeceased = relation.value;

        if (!!output.LegacyDocuments && output.LegacyDocuments.length > 0) {
            output.LegacyDocuments = output.LegacyDocuments.map(e => {
                return { ID: e.ID };
            });
        }

        return output;
    };

    updateSituation = Situation => {
        const { form } = this;
        if (Situation === 'PHONE') {
            this.setState({ enqType: 'Phone' });
            if (!isNullOrUndefined(this.form)) {
                if (form.getField('RelationshipToDeceased') === 'self') {
                    form.setField({ Situation: 'alive' });
                }
            }
        } else if (Situation === 'PRENEED') {
            this.setState({ enqType: 'Pre-Need' });
            if (!isNullOrUndefined(this.form)) {
                form.setField({ Situation: 'alive' });
            }
        } else if (Situation === 'ATNEED') {
            this.setState({ enqType: 'At-Need' });
            if (!isNullOrUndefined(this.form)) {
                form.setField({ Situation: 'dead' }); // note: could be alive but imminent? change to preneed
                if (form.getField('RelationshipToDeceased') === 'self') {
                    form.setField({ RelationshipToDeceased: null });
                }
            }
        } else if (Situation === 'alive') {
            //this.setState({ enqType: 'Pre-Need' });
            if (!isNullOrUndefined(this.form)) {
                if (form.getField('EnquiryType') === 'ATNEED' || !form.getField('EnquiryType')) {
                    form.setField({ EnquiryType: 'PRENEED' });
                }
                form.setField({ Situation: 'alive' });
            }
        } else if (Situation === 'dead') {
            if (!isNullOrUndefined(this.form)) {
                if (form.getField('EnquiryType') === 'PRENEED' || !form.getField('EnquiryType')) {
                    form.setField({ EnquiryType: 'ATNEED' });
                    if (form.getField('RelationshipToDeceased') === 'self') {
                        form.setField({ RelationshipToDeceased: null });
                    }
                    this.setState({ enqType: 'At-Need' });
                }
                form.setField({ Situation: 'dead' });
            }
        } else {
            this.setState({ enqType: '' });
        }
    };

    onClickAddQuote = e => {
        this.setState({ popupQuoteOpen: true, popupQuote: null });
    };

    onClickViewQuote = quoteID => {
        this.setState({ loadingQuote: true, popupQuoteOpen: false });
        const getQuote = async () => {
            return await getEnquiryClient().query({
                query: readQuote,
                variables: { id: quoteID },
                fetchPolicy: 'network-only'
            });
        };
        getQuote().then(({ data }) => {
            const { readOneQuote } = data;
            if (readOneQuote && readOneQuote.ID) {
                const quote = cloneDeep(readOneQuote);
                cleanUpQuoteConnections(quote);
                this.setState({
                    loadingQuote: false,
                    popupQuoteOpen: true,
                    popupQuote: quote
                });
            } else {
                this.setState({ loadingQuote: false });
            }
        });
    };

    onClickAddMeeting = form => {
        const enquiryId = parseInt(form.getField('ID'), 10);
        if (isNaN(enquiryId) || enquiryId === 0) {
            this.openAlertModal('Error', 'You must save this enquiry before you can add meetings');
            return;
        }
        const funeralId = form.getField('Funeral.ID') || null;
        const officeId = form.getField('OfficeID');
        if (!officeId || isNaN(parseInt(officeId, 10)) || officeId === '0') {
            this.openAlertModal('Error', 'Please select a Brand first!');
            return;
        }

        const Relation = APPLICANT_RELATIONSHIP_OPTIONS.find(
            e => e.value.toLowerCase() === form.getField('RelationshipToDeceased')
        );
        const Reason = `${
            form.getField('Situation') === 'alive' ? 'Pre-Need' : 'At-Need'
        } appointment for ${joinDefined(
            [form.getField('GivenName'), form.getField('MiddleName'), form.getField('Surname')],
            ' '
        )} ${(form.getField('RelationshipToDeceased') !== 'self' &&
            joinDefined(
                [
                    ' - with ',
                    form.getField('EnquirerGivenName'),
                    form.getField('EnquirerSurname'),
                    Relation && '(' + Relation.label + ')'
                ],
                ' '
            )) ||
            ''}`;
        const Phone = !!form.getField('EnquirerPhone') ? form.getField('EnquirerPhone') : null;
        const LocationResidentialAddress = { ...form.getField('EnquirerResidentialAddress') };

        this.setState({
            addMeetingOpen: true,
            appointmentDefaults: {
                Type: 'Arrangement', // how about 'Arrangement'?
                Funeral: funeralId ? form.getField('Funeral') : null,
                Enquiry: {
                    ID: enquiryId,
                    LegacyKey: form.getField('LegacyKey') || null,
                    EnquirerGivenName: form.getField('EnquirerGivenName') || null,
                    EnquirerSurname: form.getField('EnquirerSurname') || null
                },
                LocationResidentialAddress,
                Reason,
                Phone,
                Members: [{ ...getUser() }]
            }
        });
    };

    onEditMeeting(editAppointmentId, officeId) {
        if (!officeId) throw new Error('office id not set');

        this.setState({
            addMeetingOpen: true,
            editAppointmentId,
            appointmentDefaults: {}
        });
    }

    onCompleted = ({ createEnquiry }) => {
        if (createEnquiry) {
            this.props.history.replace('/quotes/enquiry/' + createEnquiry.LegacyKey + '/' + createEnquiry.ID);
        }
    };

    render() {
        return (
            <Fragment>
                {this.renderTitle()}
                <br />
                {this.renderBody()}
            </Fragment>
        );
    }

    renderTitle = () => {
        const { classes, match } = this.props;
        const { id } = match.params;
        return (
            <Grid pc={1}>
                {(id && id > 0 && (
                    <Query
                        fetchPolicy="cache-and-network"
                        client={getEnquiryClient()}
                        query={queryEnquiry()}
                        variables={{ id }}
                    >
                        {this.renderName}
                    </Query>
                )) || (
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        {(id && id > 0 ? '' : 'New ') + this.state.enqType + ' Enquiry'}
                        {id && id > 0 && <div className={classes.keyline} />}
                    </Typography>
                )}
            </Grid>
        );
    };

    renderName = ({ loading, error, data }) => {
        const { classes, match } = this.props;
        const { GivenName, MiddleName, Surname, LegacyKey, Cancelled } = (data && data.readOneEnquiry) || {};
        const { id, key } = match.params;
        return (
            <Fragment>
                {Cancelled && <BiggerMessageBar messageType="error">This record is cancelled.</BiggerMessageBar>}
                <InlineFieldRightAlignChildren lineHeight={'inherit'}>
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        {(!loading ? '' : 'Loading ') + this.state.enqType + ' Enquiry'}
                        <div className={classes.keyline} />
                        {LegacyKey || key}
                        <span className={cx(classes.nameTitle, !loading && classes.nameTitleActive)}>
                            {joinDefined([GivenName, MiddleName, Surname], ' ')}
                        </span>
                    </Typography>

                    <RecordDrawer enquiryFMId={id} legacyKey={key} />
                </InlineFieldRightAlignChildren>
            </Fragment>
        );
    };

    renderBody = () => {
        const { id } = this.props.match.params;
        if (id && id > 0) {
            return (
                <Query
                    fetchPolicy="cache-and-network"
                    query={queryEnquiry()}
                    variables={{ id }}
                    client={getEnquiryClient()}
                >
                    {results => this.renderForm(results)}
                </Query>
            );
        }

        const officeID = this.officeFromKey();
        return this.renderForm({
            loading: false,
            error: false,
            data: {
                readOneEnquiry: {
                    EnquiryType: null,
                    OfficeID: '' + officeID,
                    EnquirerTitle: null,
                    EnquirerGivenName: null,
                    EnquirerSurname: null,
                    EnquirerEmail: null,
                    EnquirerPhone: null,
                    RelationshipToDeceased: null,
                    GivenName: null,
                    Surname: null,
                    Gender: null,
                    Situation: null,
                    DateOfDeath1: null
                }
            }
        });
    };

    renderForm = ({ loading, error, data, refetch }) => {
        const { classes } = this.props;
        const { id } = this.props.match.params || 0;
        const { alertModalState, openSearchModal, documentModal, searchModalSuggestion } = this.state;

        if (loading && id > 0) {
            return (
                <Fragment>
                    <LinearProgress />
                </Fragment>
            );
        } else if (loading) {
            return (
                <Fragment>
                    Starting new enquiry...
                    <LinearProgress />
                </Fragment>
            );
        }
        const formData = data && cloneDeep(data.readOneEnquiry);
        if (data && data.readOneEnquiry && data.readOneEnquiry.Office) {
            this.office = data.readOneEnquiry.Office;
        }

        if (!formData) return `Enquiry #${id} not found.`;

        return (
            <DataForm
                id={formData.ID}
                loading={loading}
                error={error}
                data={formData}
                onLoad={this.presetData}
                customClient={getEnquiryClient()}
                mutation={id && id > 0 ? updateEnquiry() : createEnquiry()}
                variables={this.cleanupData}
                onSaved={this.onCompleted}
                validation={validation}
                requireValid={false}
            >
                {(form, button) => {
                    this.form = form;

                    let userName = '';
                    if (!id || id < 1) {
                        userName = getToken().name;
                    } else if (form.getField('CreatedBy')) {
                        userName +=
                            (form.getField('CreatedBy').FirstName || '') +
                            ' ' +
                            (form.getField('CreatedBy').Surname || '');
                        userName = userName.trim();
                    }
                    if (!userName) userName = form.getField('CreatedBy') && form.getField('CreatedBy').Email;
                    if (!userName) userName = '(unknown)';
                    const allOffices = getOffices();
                    return (
                        <Fragment>
                            <EnquirySearchModal
                                open={openSearchModal}
                                initialSearchTerm={searchModalSuggestion}
                                ignoreEnquiryId={id}
                                onAccept={e => this.copyDetailsFromEnquiry(e, form)}
                                onClose={e => this.setState({ openSearchModal: false, searchModalSuggestion: null })}
                            />
                            {button}
                            <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                                {(canEdit, user) => {
                                    return (
                                        <QuotesModal
                                            onQuoteUpdated={q => this.updateQuote(q, form)}
                                            onQuoteCreated={q => this.addQuote(q, form)}
                                            onQuoteReleased={q => this.updateReleaseQuote(q, form)}
                                            onClose={e => {
                                                this.setState({
                                                    popupQuoteOpen: false,
                                                    popupQuote: null,
                                                    popupQuoteType: null
                                                });
                                            }}
                                            open={!!this.state.popupQuoteOpen}
                                            viewQuote={this.state.popupQuote}
                                            enqType={this.enquiryTypes[this.state.enqType]}
                                            isImminent={
                                                form.getField('PreneedType') === 'Imminent' &&
                                                form.getField('EnquiryType') === 'PRENEED'
                                            }
                                            emailTo={form.getField('EnquirerEmail')}
                                            form={form}
                                            substitutionFields={{
                                                '[FirstName]': form.getField('EnquirerGivenName'),
                                                '[DeceasedFirstName]': form.getField('GivenName'),
                                                '[DeceasedSurname]': form.getField('Surname'),
                                                '[RelationshipToDeceased]': form.getField('RelationshipToDeceased'),
                                                '[OfficeName]': this.office && this.office['BusinessName'],
                                                '[OfficePhone]': this.office && this.office['Phone'],
                                                '[EnquiryKey]': form.getField('LegacyKey'),
                                                '[StaffName]':
                                                    user.readCurrentUser.FirstName + ' ' + user.readCurrentUser.Surname
                                            }}
                                        />
                                    );
                                }}
                            </GatedComponent>

                            <AlertModal {...alertModalState} />

                            <Dialog open={this.state.loadingQuote}>
                                <DialogContent>
                                    <div style={{ width: 500, height: 400, maxWidth: '100vw', maxHeight: '100vh' }}>
                                        <WhiteLoader actionWord="Loading Quote" />
                                    </div>
                                </DialogContent>
                            </Dialog>

                            <AppointmentPopover
                                open={!!this.state.addMeetingOpen}
                                onClose={() =>
                                    this.setState({
                                        addMeetingOpen: false,
                                        appointmentDefaults: null,
                                        editAppointmentId: null
                                    })
                                }
                                onMutate={this.refetchApps}
                                appointmentType={'Appointment'}
                                allowRecurring={false}
                                allowLocation={true}
                                allowOfficeChange={false}
                                defaults={this.state.appointmentDefaults}
                                appointmentId={this.state.editAppointmentId}
                                viewCalendar={viewCalendar}
                            />

                            <DocumentModal
                                onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                                open={!!documentModal.open}
                                form={form}
                                document={documentModal.document}
                            />

                            <Paper className={classes.paper} elevation={0}>
                                <div>
                                    <Grid container spacing={24}>
                                        <Grid item md={6} xs={12}>
                                            <InlineField>
                                                <strong>Brand:</strong>
                                                <SelectOffice
                                                    allowRetired={id > 0}
                                                    name="OfficeID"
                                                    required={true}
                                                    allowNone={false}
                                                    fullWidth={false}
                                                    className={classes.pickHome}
                                                    form={form}
                                                    onChange={e => {
                                                        /* a bit hacky here.
                                                    because we're changing the route and updating the state, we need to ensure it happens in
                                                    two phases. (1 update, 2 change route). otherwise it just doesn't update the form state
                                                    and the value gets lost.
                                                    */
                                                        e.persist();
                                                        return Promise.resolve(true)
                                                            .then(() => {
                                                                const newOffice = allOffices.find(
                                                                    obj => obj.ID === e.target.value
                                                                );
                                                                form.setField({ OfficeID: '' + newOffice.ID });
                                                                this.setState({ woot: newOffice.ID });
                                                                return newOffice.RegistrantSuffix;
                                                            })
                                                            .then(newLetterCode => {
                                                                const oldID = form.getField('ID')
                                                                    ? form.getField('ID')
                                                                    : 0;
                                                                const oldKey = form.getField('LegacyKey');
                                                                const newKey =
                                                                    oldKey && oldKey.length > 1
                                                                        ? oldKey.replace(/\D+$/, '') + newLetterCode
                                                                        : newLetterCode;

                                                                this.props.history.replace(
                                                                    '/quotes/enquiry/' + newKey + '/' + oldID
                                                                );
                                                            })
                                                            .then(() => {
                                                                this.setOptimalCoordinatorID(form.getField('OfficeID'));
                                                            });
                                                    }}
                                                />
                                            </InlineField>
                                        </Grid>
                                        <Grid pc={0.5} style={{ textAlign: 'right' }}>
                                            <InlineFieldRightAlignChildren>
                                                <span>
                                                    <strong>Date Created: </strong>

                                                    {moment(form.getField('Created')).format('Do MMMM Y')}
                                                </span>
                                                <span>
                                                    <strong>By: </strong>
                                                    {userName}
                                                </span>
                                            </InlineFieldRightAlignChildren>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <div
                                className={classes.paper}
                                style={{
                                    clear: 'both',
                                    borderTop: '1px solid #e7e7e7',
                                    backgroundColor: 'white',
                                    paddingBottom: '0'
                                }}
                            />
                            <Paper className={cx(classes.paper, classes.bodyPadding)} elevation={0}>
                                <div>
                                    <ColumnLayout>
                                        {this.renderFirstColumn(form)}
                                        {this.renderSecondColumn(form, refetch)}
                                    </ColumnLayout>
                                </div>
                            </Paper>
                            <GatedComponent showComponentCode="porky pig">
                                {() => (
                                    <Paper className={cx(classes.paper, classes.bodyPadding)} elevation={0}>
                                        <div
                                            className={classes.paper}
                                            style={{
                                                clear: 'both',
                                                borderTop: '1px solid #e7e7e7',
                                                backgroundColor: 'white'
                                                // paddingBottom: '0'
                                            }}
                                        />
                                        <div>
                                            <ColumnLayout>
                                                <Grid container spacing={24}>
                                                    <Grid bucket xs={12} lg={6}>
                                                        <Grid item xs={12}>
                                                            <Grid pc={1}>
                                                                <Label
                                                                    text={'Cancel Enquiry'}
                                                                    className={classes.sectionHeading}
                                                                />
                                                            </Grid>
                                                            <Grid pc={1}>
                                                                <Checkbox
                                                                    form={form}
                                                                    name="Cancelled"
                                                                    label="Cancel this enquiry, so that it will not count toward staff metrics or appear in searches."
                                                                />
                                                            </Grid>
                                                            <Grid pc={1}>
                                                                {(!form.isDirtyField('Cancelled') &&
                                                                    (form.getField('Cancelled') ? (
                                                                        <BiggerMessageBar messageType="error">
                                                                            This enquiry has been cancelled. It will not
                                                                            be included in staff metrics or appear in
                                                                            searches.
                                                                        </BiggerMessageBar>
                                                                    ) : (
                                                                        <BiggerMessageBar messageType="info">
                                                                            If this is ticked, the enquiry will not be
                                                                            included in staff metrics or appear in
                                                                            searches. Only Administrators can action
                                                                            this.
                                                                        </BiggerMessageBar>
                                                                    ))) || (
                                                                    <BiggerMessageBar messageType="warning">
                                                                        This value has been changed. Please save to
                                                                        confirm.
                                                                    </BiggerMessageBar>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid bucket xs={12} lg={6}>
                                                        <Grid item xs={12}>
                                                            <Grid pc={1}>
                                                                <Label
                                                                    text={'Enquiry Key Override'}
                                                                    className={classes.sectionHeading}
                                                                />
                                                            </Grid>
                                                            <Grid pc={1}>
                                                                <TextField
                                                                    form={form}
                                                                    name="LegacyKey"
                                                                    label="Enquiry Key Override"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ColumnLayout>
                                        </div>
                                    </Paper>
                                )}
                            </GatedComponent>
                        </Fragment>
                    );
                }}
            </DataForm>
        );
    };

    renderFirstColumn(form) {
        const { classes } = this.props;

        const placeOfDeathResidential = form.getField('PlaceOfDeathType') === PLACE_OF_DEATH_TYPE.Residential;
        const transferFromResidential = form.getField('TransferFromType') === PLACE_OF_DEATH_TYPE.Residential;
        const sameDeathAsResidential = form.getField('DeathUseResidentialAddress');
        const sameAsPlaceOfDeath = form.getField('TransferUsePlaceOfDeathAddress');
        const clientTheEnquirer = !(
            form.getField('EnquiryType') === 'ATNEED' || form.getField('RelationshipToDeceased') !== 'self'
        );
        const isDead = form.getField('Situation') === 'dead';

        const relationshipOptions = [{ label: 'The enquirer is the client', value: 'self' }]
            .concat(APPLICANT_RELATIONSHIP_OPTIONS)
            .filter(e => (isDead ? e.value !== 'self' : true));

        return (
            <Grid container spacing={24}>
                <Grid bucket>
                    <Grid pc={1}>
                        <Label text={'Type of Enquiry'} className={classes.sectionHeading} />
                    </Grid>
                    <Grid pc={0.5}>
                        <Select
                            label={'Type of Enquiry'}
                            form={form}
                            allowNone={false}
                            name="EnquiryType"
                            options={ENQUIRY_TYPES}
                            required={true}
                            onChange={e => {
                                this.updateSituation(e.target.value);
                                form.setField({ EnquiryType: e.target.value });
                            }}
                        />
                    </Grid>

                    {form.getField('EnquiryType') === 'PHONE' && (
                        <Grid pc={0.5}>
                            <Select
                                required={true}
                                name="PhoneType"
                                options={PhoneTypes}
                                form={form}
                                label={'Nature of Phone Quotation'}
                            />
                        </Grid>
                    )}

                    {form.getField('EnquiryType') === 'PRENEED' && (
                        <Grid pc={0.5}>
                            <Select
                                required={true}
                                name="PreneedType"
                                options={PreNeedTypes}
                                form={form}
                                label={'Preneed Type'}
                            />
                        </Grid>
                    )}

                    {!(
                        form.getField('EnquiryType') === 'ATNEED' || isNullOrUndefined(form.getField('EnquiryType'))
                    ) && (
                        <Grid pc={1}>
                            <RadioGroup
                                options={[
                                    { label: 'Yes', value: 'Yes' },
                                    { label: 'No', value: 'No' }
                                ]}
                                label="Is the enquirer the client?"
                                value={clientTheEnquirer ? 'Yes' : 'No'}
                                onChange={e => {
                                    if (form.getField('EnquiryType') === 'PHONE' && e.target.value === 'Yes') {
                                        form.setField({ Situation: 'alive' });
                                    }
                                    form.setField({ RelationshipToDeceased: e.target.value === 'Yes' ? 'self' : null });
                                }}
                            />
                        </Grid>
                    )}
                    <Grid pc={1}>
                        <RadioGroup
                            options={[
                                { label: 'Cremation', value: 'Cremated' },
                                { label: 'Burial', value: 'Buried' }
                            ]}
                            label="Select Committal Type:"
                            form={form}
                            name={'CrematedOrBuried'}
                        />
                    </Grid>
                </Grid>
                <Grid bucket>
                    {(isNullOrUndefined(form.getField('EnquiryType')) ||
                        (form.getField('EnquiryType') === 'PHONE' && !clientTheEnquirer)) && (
                        <Grid pc={0.5}>
                            <Select
                                label="Client Status"
                                name="Situation"
                                options={[
                                    { label: 'Is alive', value: 'alive' },
                                    { label: 'Is deceased', value: 'dead' }
                                ]}
                                onChange={e => this.updateSituation(e.target.value)}
                                form={form}
                            />
                        </Grid>
                    )}
                    <Fragment>
                        <Grid pc={1}>
                            <Label
                                text={!isDead ? 'Client Details' : 'Deceased Details'}
                                className={classes.sectionHeading}
                            />
                        </Grid>
                    </Fragment>

                    {form.getField('RelationshipToDeceased') !== 'self' ? (
                        <Fragment>
                            <Grid pc={0.5}>
                                <TextField
                                    name="GivenName"
                                    label={'First name'}
                                    form={form}
                                    required={true}
                                    titleCase
                                />
                            </Grid>
                            <Grid pc={0.5}>
                                <TextField name="MiddleName" label={'Middle names (optional)'} form={form} />
                            </Grid>
                            <Grid pc={0.5}>
                                <TextField name="Surname" label={'Surname'} form={form} required={true} titleCase />
                            </Grid>
                            <Grid pc={0.5}>
                                <Select
                                    name="Gender"
                                    form={form}
                                    label={'Gender'}
                                    options={GENDER_OPTIONS}
                                    required={true}
                                />
                            </Grid>
                            <Grid pc={1}>
                                <Label text="Date of birth" htmlFor="DateOfBirth" />
                                <Grid container item spacing={24}>
                                    {!form.getField('UnknownDOB') && (
                                        <Grid item xs={6} sm={4} lg={6}>
                                            <TextField
                                                name="DateOfBirth"
                                                type="date"
                                                notFuture
                                                form={form}
                                                required={!form.getField('UnknownDOB')}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={6} sm={4} lg={6}>
                                        <Checkbox name="UnknownDOB" label="DOB Unknown" form={form} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment>{this.renderContactFields(form, relationshipOptions)}</Fragment>
                    )}
                    <InlineField>
                        {(form.getField('PriorEnquiry.ID') && Number(form.getField('PriorEnquiry.ID')) > 0 && (
                            <OutlineButton
                                href={`/quotes/enquiry/${form.getField('PriorEnquiry.LegacyKey')}/${form.getField(
                                    'PriorEnquiry.ID'
                                )}`}
                                target="_blank"
                            >
                                Prefilled from Enquiry {form.getField('PriorEnquiry.LegacyKey')}
                            </OutlineButton>
                        )) ||
                            (form.getField('Prearrangement.ID') &&
                                Number(form.getField('Prearrangement.ID')) > 0 &&
                                form.getField('Prearrangement.Quote.Enquiry.ID') !== form.getField('ID') && (
                                    <OutlineButton
                                        href={`/prearrangement/${form.getField(
                                            'Prearrangement.LegacyKey'
                                        )}/${form.getField('Prearrangement.ID')}`}
                                        target="_blank"
                                    >
                                        Prefilled from Prearrangement {form.getField('Prearrangement.LegacyKey')}
                                    </OutlineButton>
                                ))}
                        {!(form.getField('Funeral.ID') && Number(form.getField('Funeral.ID')) > 0) && (
                            <ValidationPlaceholder
                                validationResult={
                                    form.getField('EnquiryType') === 'ATNEED' && !form.getField('ID')
                                        ? validationHelper.required('')
                                        : validationHelper.ok()
                                }
                            >
                                <PrimaryButton onClick={e => this.openSearchModal(form)}>
                                    <SearchIcon />
                                    <span>Prefill from existing record...</span>
                                </PrimaryButton>
                            </ValidationPlaceholder>
                        )}
                    </InlineField>
                    {form.getField('EnquiryType') !== 'PRENEED' && (
                        <Fragment>
                            {form.getField('Situation') === 'dead' && (
                                <Fragment>
                                    <Grid pc={1}>
                                        <Label text="Date of Death" htmlFor="text-DateOfDeath1" />
                                        <Grid container item spacing={24}>
                                            <Grid item xs={12} sm={4}>
                                                <Select
                                                    name="DateOfDeathType"
                                                    options={DATE_OF_DEATH_OPTIONS}
                                                    form={form}
                                                    allowNone={false}
                                                    required={true}
                                                />
                                            </Grid>
                                            {form.getField('DateOfDeathType') !== DATE_OF_DEATH_OPTION.UNKNOWN.value &&
                                                (form.getField('DateOfDeathType') ===
                                                DATE_OF_DEATH_OPTION.BETWEEN.value ? (
                                                    <Fragment>
                                                        <Grid item xs={6} sm={4}>
                                                            <TextField
                                                                name="DateOfDeath1"
                                                                type="date"
                                                                notFuture
                                                                form={form}
                                                                required={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={4}>
                                                            <TextField
                                                                name="DateOfDeath2"
                                                                type="date"
                                                                notFuture
                                                                form={form}
                                                                required={true}
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                ) : (
                                                    <Grid item xs={6} sm={5} lg={5}>
                                                        <TextField
                                                            name="DateOfDeath1"
                                                            type="date"
                                                            notFuture
                                                            form={form}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                ))}

                                            {form.getField('DateOfDeath') && form.getField('DateOfBirth') && (
                                                <Grid pc={1}>
                                                    <strong>
                                                        {moment(form.getField('DateOfDeath')).diff(
                                                            moment(form.getField('DateOfBirth')),
                                                            'years'
                                                        )}{' '}
                                                        years of age
                                                    </strong>{' '}
                                                    at time of death
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid pc={1}>
                                        <InlineHeader header="Place of Death">
                                            <Checkbox
                                                label="Use a NSW Hospital/Nursing Home"
                                                checked={!placeOfDeathResidential}
                                                onChange={e => {
                                                    form.setField({
                                                        PlaceOfDeathType: placeOfDeathResidential
                                                            ? PLACE_OF_DEATH_TYPE.AddressBook
                                                            : PLACE_OF_DEATH_TYPE.Residential
                                                    });
                                                }}
                                            />
                                        </InlineHeader>

                                        <Grid pc={1}>
                                            {placeOfDeathResidential ? (
                                                <AddressAutocomplete
                                                    id="PlaceOfDeath"
                                                    placeholder="Search for an address..."
                                                    componentFields={PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS}
                                                    allowCustomAddress={true}
                                                    form={form}
                                                />
                                            ) : (
                                                <AddressBookAutocomplete
                                                    noAddNew
                                                    name="PlaceOfDeath"
                                                    placeholder="Select an address book contact..."
                                                    form={form}
                                                    labelField="Name"
                                                    categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid pc={1}>
                                        <InlineHeader header="Transfer From">
                                            <Checkbox
                                                name="TransferUsePlaceOfDeathAddress"
                                                label="Same as Place of Death address"
                                                form={form}
                                            />
                                            {!form.getField('TransferUsePlaceOfDeathAddress') && (
                                                <Checkbox
                                                    name="TransferUsePlaceOfDeathAddress"
                                                    label="Use a NSW Hospital/Nursing Home"
                                                    checked={!transferFromResidential}
                                                    onChange={e => {
                                                        form.setField({
                                                            TransferFromType: transferFromResidential
                                                                ? PLACE_OF_DEATH_TYPE.AddressBook
                                                                : PLACE_OF_DEATH_TYPE.Residential
                                                        });
                                                    }}
                                                    disabled={sameAsPlaceOfDeath}
                                                />
                                            )}
                                        </InlineHeader>

                                        {!form.getField('TransferUsePlaceOfDeathAddress') && (
                                            <Grid pc={1}>
                                                {sameAsPlaceOfDeath && sameDeathAsResidential ? (
                                                    {
                                                        /*not used*/
                                                    }
                                                ) : sameAsPlaceOfDeath && !placeOfDeathResidential ? (
                                                    <TextField
                                                        placeholder="Place of Death Address"
                                                        value={
                                                            form.getField('PlaceOfDeath')
                                                                ? form.getField('PlaceOfDeath').Name
                                                                : null
                                                        }
                                                        disabled={true}
                                                    />
                                                ) : sameAsPlaceOfDeath && placeOfDeathResidential ? (
                                                    <TextField
                                                        placeholder="Place of Death Residential Address"
                                                        value={joinDefined(
                                                            [
                                                                form.getField(
                                                                    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.line1
                                                                ),
                                                                form.getField(
                                                                    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.line2
                                                                ),
                                                                form.getField(
                                                                    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.city
                                                                ),
                                                                form.getField(
                                                                    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.state
                                                                ),
                                                                form.getField(
                                                                    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.code
                                                                )
                                                            ],
                                                            ', '
                                                        )}
                                                        disabled={true}
                                                    />
                                                ) : transferFromResidential ? (
                                                    <AddressAutocomplete
                                                        id="TransferFrom"
                                                        placeholder="Search for an address..."
                                                        componentFields={TRANSFER_FROM_RESIDENTIAL_COMPONENTS}
                                                        disabled={!!form.getField('TransferUsePlaceOfDeathAddress')}
                                                        allowCustomAddress={true}
                                                        form={form}
                                                    />
                                                ) : (
                                                    <AddressBookAutocomplete
                                                        noAddNew
                                                        placeholder="Select an address book contact..."
                                                        name="TransferFrom"
                                                        form={form}
                                                        labelFieldFunc={getLabelWithSuburb}
                                                        categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Grid>

                {form.getField('RelationshipToDeceased') !== 'self' && (
                    <Grid bucket>
                        <Grid pc={1}>
                            <Label text={'Enquirer/Contact Details'} className={classes.sectionHeading} />
                        </Grid>
                        {this.renderContactFields(form, relationshipOptions)}
                    </Grid>
                )}
            </Grid>
        );
    }

    renderContactFields(form, relationshipOptions) {
        const { classes } = this.props;
        const clientTheEnquirer = !(
            form.getField('EnquiryType') === 'ATNEED' || form.getField('RelationshipToDeceased') !== 'self'
        );
        return (
            <Fragment>
                {!clientTheEnquirer && (
                    <Grid pc={1}>
                        <AutoComplete
                            name="RelationshipToDeceased"
                            label="Relationship to the client"
                            form={form}
                            limit={100}
                            options={relationshipOptions}
                            onChange={selected => {
                                const { value } = selected || null;
                                if (value === 'self') {
                                    const newState = { Situation: 'alive' };
                                    form.setField(newState);
                                }
                            }}
                        />
                    </Grid>
                )}
                <Grid pc={0.5}>
                    <Select name="EnquirerTitle" required={true} options={TitleOptions} label={'Title'} form={form} />
                </Grid>
                <Grid pc={0.5}>
                    <TextField name="EnquirerGivenName" label={'First name'} form={form} required={true} titleCase />
                </Grid>
                <Grid pc={0.5}>
                    <TextField name="EnquirerMiddleName" label={'Middle names (optional)'} form={form} />
                </Grid>
                <Grid pc={0.5}>
                    <TextField name="EnquirerSurname" label={'Surname'} form={form} required={true} titleCase />
                </Grid>

                {form.getField('RelationshipToDeceased') === 'self' && (
                    <Fragment>
                        <Grid pc={0.5}>
                            <Select
                                name="Gender"
                                form={form}
                                label={'Gender'}
                                options={GENDER_OPTIONS}
                                required={true}
                            />
                        </Grid>
                        <Grid pc={1}>
                            <Label text="Date of birth" htmlFor="DateOfBirth" />
                            <Grid container item spacing={24}>
                                {!form.getField('UnknownDOB') && (
                                    <Grid item xs={6} sm={4} lg={6}>
                                        <TextField
                                            name="DateOfBirth"
                                            type="date"
                                            notFuture
                                            form={form}
                                            required={!form.getField('UnknownDOB')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={6} sm={4} lg={6}>
                                    <Checkbox name="UnknownDOB" label="DOB Unknown" form={form} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
                <Grid pc={0.5}>
                    <TextField
                        name="EnquirerPhone"
                        label={'Primary number'}
                        form={form}
                        required={true}
                        inputProps={{ pattern: '\\d{6,13}' }}
                        title={'Please enter only digits for phone number.'}
                    />
                </Grid>
                <Grid pc={0.5}>
                    <TextField name="EnquirerMobile" label={'Secondary number (optional)'} form={form} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="EnquirerEmail"
                        label={'Email'}
                        type={'email'}
                        form={form}
                        disabled={!!form.getField('HiddenUnknownEmailField')}
                        required={!form.getField('HiddenUnknownEmailField')}
                    />
                    <Checkbox
                        name="HiddenUnknownEmailField"
                        label="No email available"
                        form={form}
                        onChange={e => {
                            if (e.target.checked) {
                                form.setField({ EnquirerEmail: '' });
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Label text="Enquirier's Residential Address" htmlFor="EnquirerResidentialAddress" />
                    <AddressAutocomplete
                        id="EnquirerResidentialAddress"
                        form={form}
                        allowCustomAddress={true}
                        placeholder="Search for an address..."
                        componentFields={{
                            [COMPONENT.line1]: 'EnquirerResidentialAddress.AddressLine1',
                            [COMPONENT.line2]: 'EnquirerResidentialAddress.AddressLine2',
                            [COMPONENT.city]: 'EnquirerResidentialAddress.Suburb',
                            [COMPONENT.state]: 'EnquirerResidentialAddress.State',
                            [COMPONENT.code]: 'EnquirerResidentialAddress.Postcode'
                        }}
                    />
                </Grid>

                <Grid pc={1}>
                    <Label text="Postal address" htmlFor="EnquirerPostalAddress" className={classes.inlineInputs} />
                    <Checkbox
                        name="PostalUseResidentialAddress"
                        label="Same as Enquirer's Residential Address"
                        form={form}
                    />
                    {!form.getField('PostalUseResidentialAddress') && (
                        <AddressAutocomplete
                            id="EnquirerPostalAddress"
                            form={form}
                            allowCustomAddress={true}
                            placeholder="Search for an address..."
                            componentFields={{
                                [COMPONENT.line1]: 'EnquirerPostalAddress1',
                                [COMPONENT.line2]: 'EnquirerPostalAddress2',
                                [COMPONENT.city]: 'EnquirerPostalSuburb',
                                [COMPONENT.state]: 'EnquirerPostalState',
                                [COMPONENT.code]: 'EnquirerPostalPostcode'
                            }}
                        />
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderSecondColumn(form, refetch) {
        const { classes } = this.props;
        const { Quotes } = this.state;
        const buttons = this.renderRelatedRecords(form, refetch);
        const needCoordinators =
            (!form.getField('Coordinators') || form.getField('Coordinators').length === 0) && !!form.getField('ID');
        const isPrepaid = form.getField('PreneedType') === 'Prepaid';
        const isBond = form.getField('PreneedType') === 'Bond';
        const { AdminFee, BondPercent } = getSiteConfig() || { AdminFee: 300, BondPercent: 0.02 };
        return (
            <Grid container spacing={24}>
                <GatedComponent showComponentCode={'FM_ACCESS_Quotes_View'}>
                    {() => {
                        return (
                            <Grid bucket>
                                <Grid pc={1}>
                                    <Label text={'Quotes'} />
                                    {form.getField('EnquiryType') === 'PRENEED' && (!!isPrepaid || !!isBond) && (
                                        <BiggerMessageBar>
                                            Please remember to mention to the caller the{' '}
                                            {isPrepaid && (
                                                <span>
                                                    Pre-paid Funeral Administration Fee ({prettyPrice(AdminFee)})
                                                </span>
                                            )}
                                            {isBond && (
                                                <span>Funeral Bond Establishment Fee ({BondPercent * 100}%)</span>
                                            )}
                                        </BiggerMessageBar>
                                    )}
                                    {(!form.getField('ID') && <p>Quotes can be added after the enquiry is saved.</p>) ||
                                        (!!Quotes && Quotes.length === 0 && <p>There are currently no quotes.</p>)}
                                </Grid>
                                {form.getField('ID') && this.renderQuotesTable()}
                            </Grid>
                        );
                    }}
                </GatedComponent>
                {this.renderMeetingsTable(form)}
                <Grid bucket>
                    <ValidationPlaceholder
                        validationResult={needCoordinators ? validationHelper.required() : validationHelper.ok()}
                    >
                        <Grid pc={1}>
                            <Label text={'Coordinators'} />
                            {(!form.getField('ID') && <p>Coordinators can be added after the enquiry is saved.</p>) ||
                                ((!form.getField('Coordinators') || form.getField('Coordinators').length === 0) && (
                                    <p>There are currently no coordinators.</p>
                                ))}
                        </Grid>

                        {!!form.getField('Coordinators') &&
                            form.getField('Coordinators').map((obj, i) => (
                                <InlineField key={i}>
                                    <Grid item pc={1}>
                                        <StaffAutoComplete
                                            label={`Coordinator`}
                                            placeholder={`Search for a Staff Member`}
                                            onSelect={(_, staff) => this.onSelectCoordinator(i, staff)}
                                            value={obj.Member}
                                            clearOnSelect={false}
                                        />
                                    </Grid>
                                    <Grid item pc={1}>
                                        <InlineField>
                                            <Select
                                                label="Allocation"
                                                options={ALLOCATION_OPTIONS}
                                                name={`Coordinators[${i}].Allocation`}
                                                form={form}
                                                allowNone={false}
                                            />
                                            <IconButton
                                                className={cx(classes.tableIconButton, classes.alignWithFloatingLabel)}
                                                onClick={() => this.deleteCoordinator(i)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </InlineField>
                                    </Grid>
                                </InlineField>
                            ))}

                        {!!form.getField('ID') && (
                            <Grid pc={1}>
                                <InlineField lineHeight={1}>
                                    <PrimaryButton onClick={() => this.addCoordinator()}>
                                        + Add Coordinator
                                    </PrimaryButton>
                                </InlineField>
                            </Grid>
                        )}
                    </ValidationPlaceholder>
                </Grid>

                <GatedComponent showComponentCode={'FM_ACCESS_Enquiries'}>
                    {(isEnabled, user) => {
                        return (
                            <CommunicationNotes
                                recordID={form.getField('ID')}
                                recordKey={form.getField('LegacyKey')}
                                recordType="Enquiry"
                                substitutionFields={{
                                    '[FirstName]': form.getField('EnquirerGivenName'),
                                    '[DeceasedFirstName]': form.getField('GivenName'),
                                    '[DeceasedSurname]': form.getField('Surname'),
                                    '[RelationshipToDeceased]': form.getField('RelationshipToDeceased'),
                                    '[OfficeName]': this.office && this.office['BusinessName'],
                                    '[OfficePhone]': this.office && this.office['Phone'],
                                    '[EnquiryKey]': form.getField('LegacyKey'),
                                    '[StaffName]': user.readCurrentUser.FirstName + ' ' + user.readCurrentUser.Surname
                                }}
                            />
                        );
                    }}
                </GatedComponent>
                <Grid bucket>
                    <Grid pc={1}>
                        <Label text={'Staff Notes'} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name={'Notes'} multiline placeholder={'Write your notes here...'} form={form} />
                        <InlineField>
                            <HistoryField value={form.getField('OldNotes')} />
                        </InlineField>
                    </Grid>
                </Grid>
                <Grid bucket>{this.renderLegacyDocuments(form)}</Grid>
                <Grid bucket>
                    <Grid pc={1}>
                        <Label text={'Related Records'} />
                        {!(!!buttons && buttons.length > 0) && <p>There are currently no related records.</p>}
                    </Grid>
                    {!!buttons &&
                        buttons.length > 0 &&
                        buttons.map((e, i) => (
                            <Grid item xs={12} key={i}>
                                {e}
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        );
    }

    setOptimalCoordinatorID(officeId) {
        if (officeId && !isNaN(parseInt(officeId, 10)) && officeId !== '0') {
            getEnquiryClient()
                .query({
                    query: readOfficeHighestCompletionRateMemberID,
                    variables: {
                        id: officeId
                    }
                })
                .then(({ data }) => {
                    if (
                        data &&
                        data.readOneOfficesUsername &&
                        data.readOneOfficesUsername.getHighestCompletionRateMemberID
                    ) {
                        this.setState({
                            optimalCoordinatorID: data.readOneOfficesUsername.getHighestCompletionRateMemberID
                        });
                    }
                });
        }
    }

    onSelectCoordinator(index, staff) {
        const array = this.form.getField('Coordinators');
        delete staff['__typename'];
        array[index].Member = { ...staff };
        this.form.setField({ Coordinators: array });
    }

    addCoordinator() {
        const array = this.form.getField('Coordinators') || [];

        let member = this.state.optimalCoordinatorID;

        if (!member || member <= 0) {
            member = (getUser() || {}).ID;
        }

        array.push({
            ID: null,
            Allocation:
                array.filter(x => x.Allocation === ALLOCATION.Primary).length === 0
                    ? ALLOCATION.Primary
                    : ALLOCATION.Secondary,
            Member: { ID: member }
        });
        this.form.setField({ Coordinators: array });
    }

    deleteCoordinator(i) {
        const array = this.form.getField('Coordinators') || [];
        array.splice(i, 1);
        this.form.setField({ Coordinators: array });
    }

    renderRelatedRecords(form, refetch) {
        const { generating, Quotes } = this.state;
        const currentEnquiryID = form.getField('ID');
        const disabled = !!generating || !!form.isDirty || currentEnquiryID < 1;
        const quoteAccepted =
            (!!Quotes && Quotes.find(e => !!e.Accepted && e.Enquiry.ID === currentEnquiryID)) || false;

        const relatedFuneralObj = form.getField('Funeral');
        const relatedFuneral =
            !!relatedFuneralObj && !!relatedFuneralObj.Funeral ? relatedFuneralObj.Funeral : relatedFuneralObj;

        const onlineOrder = relatedFuneral && relatedFuneral.OnlineOrder;

        const relatedPrearranged = form.getField('Prearranged') || form.getField('Prearrangement');
        const canCreatePre = !!quoteAccepted && !(!!relatedPrearranged && relatedPrearranged.ID !== '0');
        const genPre =
            (!!Quotes &&
                !!quoteAccepted &&
                quoteAccepted.QuoteType !== 'ATNEED' &&
                form.getField('Situation') !== 'dead') ||
            (!quoteAccepted &&
                form.getField('EnquiryType') === 'PRENEED' &&
                form.getField('PreneedType') === 'Imminent');
        /*

        Some confusing logic follows, here's a breakdown...

        IF this Enquiry was generated by a Prearrangement, link to the Prearrangement!
        ELSE IF this Enquiry generated a Prearrangement, link to the Prearrangement.
        ELSE IF this Enquiry is not ATNEED and has an accepted Quote without an Arrangement, link to Generate Prearrangement.
        ELSE IF this Enquiry is PRENEED and Imminent and does not have an accepted Quote, link to Generate Prearrangement anyway.

        */

        const pre =
            (relatedPrearranged && Number(relatedPrearranged.ID) > 0 && (
                <OutlineButton
                    target="_blank"
                    href={'/prearrangement/' + relatedPrearranged.LegacyKey + '/' + relatedPrearranged.ID}
                >
                    <NoteIcon /> View Prearrangement {relatedPrearranged.LegacyKey}
                </OutlineButton>
            )) ||
            (genPre && (
                <SaveButton
                    disabled={disabled || !canCreatePre}
                    onClick={e => this.generatePrearrangement({ form, refetch })}
                >
                    {!generating ? (
                        <Fragment>
                            <SaveIcon /> Generate Prearrangement
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Spinner /> Generating Prearrangement...
                        </Fragment>
                    )}
                </SaveButton>
            ));

        /*

        More buttons...

        IF this Enquiry has generated a Funeral, link to the Funeral.
        ELSE IF this Enquiry has an accepted Quote with an Arrangement linked to any Enquiry which has generated a Funeral, link to the Funeral.
        ELSE IF this Enquiry is PHONE and has an accepted Quote, link to Generate Funeral.
        ELSE IF this Enquiry is ATNEED, link to Generate Funeral.

        */

        const fun =
            (relatedFuneral && Number(relatedFuneral.ID) > 0 && (
                <OutlineButton target="_blank" href={'/funeral/' + relatedFuneral.LegacyKey + '/' + relatedFuneral.ID}>
                    <NoteIcon /> View Funeral {relatedFuneral.LegacyKey}
                </OutlineButton>
            )) ||
            ((form.getField('EnquiryType') === 'ATNEED' ||
                (form.getField('Situation') === 'dead' &&
                    form.getField('EnquiryType') === 'PHONE' &&
                    !!quoteAccepted)) && (
                <SaveButton disabled={disabled} onClick={e => this.generateFuneral({ form, refetch })}>
                    {!generating ? (
                        <Fragment>
                            <SaveIcon /> Generate Funeral Record
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Spinner /> Generating Funeral...
                        </Fragment>
                    )}
                </SaveButton>
            ));

        const old = form.getField('PriorEnquiry.ID') && Number(form.getField('PriorEnquiry.ID')) > 0 && (
            <OutlineButton
                href={`/quotes/enquiry/${form.getField('PriorEnquiry.LegacyKey')}/${form.getField('PriorEnquiry.ID')}`}
                target="_blank"
            >
                <PayableIcon /> View Prior Enquiry {form.getField('PriorEnquiry.LegacyKey')}
            </OutlineButton>
        );
        const neu =
            form.getField('LaterEnquiries') &&
            form.getField('LaterEnquiries').length > 0 &&
            form.getField('LaterEnquiries').map(
                e =>
                    Number(e.ID) && (
                        <OutlineButton key={e.ID} href={`/quotes/enquiry/${e.LegacyKey}/${e.ID}`} target="_blank">
                            <PayableIcon /> View Later Enquiry {e.LegacyKey}
                        </OutlineButton>
                    )
            );

        const clientTheEnquirer = !(
            form.getField('EnquiryType') === 'ATNEED' || form.getField('RelationshipToDeceased') !== 'self'
        );
        const isDead = form.getField('Situation') === 'dead';
        const formDeath = !clientTheEnquirer || !!isDead;
        const ded = (
            <OutlineButton
                disabled={disabled}
                target="_blank"
                href={
                    getSiteConfig()[formDeath ? 'getDeathRegistrationPage' : 'getPrePlanRegistrationPage'] +
                    '?Type=enquiry&ID=' +
                    form.getField('ID') +
                    '&Key=' +
                    form.getField('LegacyKey')
                }
            >
                <ArrangementIcon /> {formDeath ? 'Death' : 'Prearrangement'} Registration Form
            </OutlineButton>
        );

        let faButton = null;
        const { FuneralArrangerDomain } = getSiteConfig();
        if (onlineOrder && onlineOrder.FAOrderID && !!FuneralArrangerDomain) {
            const faLink = ['//', FuneralArrangerDomain, '/arrangement?orderid=', onlineOrder.FAOrderID].join('');
            faButton = (
                <OutlineButton href={faLink} target="_blank">
                    <CartIcon /> View Funeral Arranger Order {onlineOrder.FAID || `#${onlineOrder.FAOrderID}`}
                </OutlineButton>
            );
        }
        const fab =
            faButton ||
            (!!FuneralArrangerDomain && (
                <SaveButton disabled={disabled} onClick={e => this.generateOnlineOrder({ form, refetch })}>
                    {!generating ? (
                        <Fragment>
                            <SaveIcon /> Generate Order for Funeral Arranger
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Spinner /> Generating Order for Funeral Arranger...
                        </Fragment>
                    )}
                </SaveButton>
            ));
        return [old, neu, ded, pre, fun, fab].filter(Boolean);
    }

    renderQuotesTable = () => {
        const { classes } = this.props;
        const { Quotes } = this.state;
        const loading = Quotes === null;
        const form = this.form;
        const currentEnquiryID = form.getField('ID');
        const tableData =
            (Quotes &&
                Quotes.length &&
                Quotes.map(e => {
                    const str = e.Title || '';
                    const n = 120;
                    return {
                        id: e.ID,
                        quoteType: Object.keys(this.enquiryTypes).find(key => this.enquiryTypes[key] === e.QuoteType),
                        quoteTitle: str.substr(0, n - 1) + (str.length > n ? '\u2026' : ''),
                        quoteDesc: str,
                        quoteStatus: e.Accepted
                            ? 'Accepted'
                            : e.Released
                            ? e.RespondedDate
                                ? 'Declined'
                                : 'Released'
                            : 'Draft',
                        quoteAmount: e.CalcQuoteTotal,
                        quoteDate: e.Created,
                        quoteAccepted: e.Accepted,
                        quoteTemplateKey: e.QuoteTemplate.LegacyKey,
                        quoteID: e.ID,
                        enquiryID: e.Enquiry.ID
                    };
                })) ||
            [];

        const myQuotes = tableData.filter(e => e.enquiryID === currentEnquiryID) || [];
        const otherQuotes = tableData.filter(e => e.enquiryID !== currentEnquiryID) || [];

        const renderTable = tableData => {
            return (
                <div className={classes.tableContainer}>
                    <TableData
                        columns={[
                            {
                                rowSpan: 2,
                                label: <Fragment>Quote&nbsp;ID</Fragment>,
                                id: 'id',
                                render: (val, row) => (
                                    <LinkButton text={val} onClick={e => this.onClickViewQuote(row.quoteID)}>
                                        #
                                    </LinkButton>
                                )
                            },
                            {
                                label: 'Status',
                                id: 'quoteStatus',
                                styles: { textAlign: 'center', width: 1 },
                                render: obj => {
                                    return obj === 'Accepted' ? (
                                        <small>
                                            <TickIcon className={classes.tickIcon} />
                                            <br />
                                            Accepted
                                        </small>
                                    ) : obj === 'Released' ? (
                                        <small>
                                            <PayableIcon color="primary" />
                                            <br />
                                            Released
                                        </small>
                                    ) : (
                                        obj
                                    );
                                }
                            },
                            {
                                rowPos: 2,
                                colSpan: 5,
                                id: 'quoteTitle',
                                render: (val, row) => <small title={row.quoteDesc}>{val}</small>
                            },
                            {
                                label: 'Created',
                                id: 'quoteDate',
                                render: val => moment(val).format('D/MM/YYYY h:mma')
                            },
                            {
                                label: 'Template',
                                id: 'quoteTemplateKey'
                            },
                            {
                                label: 'Amount',
                                id: 'quoteAmount',
                                render: val => prettyPrice(val),
                                styles: { textAlign: 'right' }
                            },
                            { label: 'Type', id: 'quoteType' },
                            {
                                rowSpan: 2,
                                id: 'foo',
                                render: (val, row) => {
                                    return (
                                        <IconButton
                                            className={this.props.classes.tableIconButton}
                                            onClick={e => this.onClickViewQuote(row.quoteID)}
                                            text={row.quoteStatus === 'Draft' ? 'edit' : 'view'}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    );
                                }
                            }
                        ]}
                        data={tableData}
                    />
                </div>
            );
        };
        return (
            <Fragment>
                {tableData.length === 0 ? (
                    !!loading && (
                        <Grid pc={1}>
                            <LinearProgress />
                        </Grid>
                    )
                ) : (
                    <Grid pc={1}>
                        {(myQuotes.length > 0 && renderTable(myQuotes)) || (
                            <p style={{ marginTop: 0 }}>There are currently no quotes for this enquiry.</p>
                        )}
                    </Grid>
                )}

                <GatedComponent showComponentCode={'FM_ACCESS_Quotes_Create'}>
                    {() => {
                        if (!!Quotes && Quotes.find(e => !!e.Accepted && e.Enquiry.ID === currentEnquiryID))
                            return null;
                        const isImminent =
                            form.getField('EnquiryType') === 'PRENEED' && form.getField('PreneedType') === 'Imminent';
                        const quoteType = !!isImminent ? 'Imminent' : this.state.enqType;
                        return (
                            <InlineField lineHeight={1}>
                                <PrimaryButton onClick={this.onClickAddQuote}>
                                    <QuotesIcon />
                                    Add {quoteType} quote...
                                </PrimaryButton>
                            </InlineField>
                        );
                    }}
                </GatedComponent>

                {otherQuotes.length > 0 && (
                    <Grid pc={1}>
                        <Label>Quotes from Related Enquiries</Label>
                        {renderTable(otherQuotes)}
                    </Grid>
                )}
            </Fragment>
        );
    };

    renderMeetingsTable(form) {
        const enquiryID = form.getField('ID');
        const skip = !enquiryID || enquiryID === '0';
        const variables = { filters: [{ field: 'EnquiryID', value: enquiryID }] };
        return (
            <Query
                client={getCalendarClient()}
                fetchPolicy={'network-only'}
                variables={variables}
                skip={skip}
                query={readEnquiryAppointments}
            >
                {result => this.renderMeetings(result)}
            </Query>
        );
    }

    renderMeetings = ({ data, loading, error, refetch }) => {
        this.refetchApps = refetch;
        const form = this.form;
        const officeId = form.getField('OfficeID') ? form.getField('OfficeID') : null;
        const appointments = ((data && data.readAppointments && data.readAppointments.edges) || []).map(e => e.node);
        const needAppointments = (!appointments || appointments.length === 0) && !!form.getField('ID');
        return (
            <Grid bucket>
                <ValidationPlaceholder
                    validationResult={needAppointments ? validationHelper.required() : validationHelper.ok()}
                >
                    <Grid pc={1}>
                        <Label text={'Appointments'} />
                        {(!form.getField('ID') && (
                            <p>Arrangement appointments can be added after the enquiry is saved.</p>
                        )) ||
                            (!loading && !!form.getField('ID') && !(appointments && appointments.length > 0) && (
                                <p>There are currently no appointments.</p>
                            ))}
                    </Grid>
                    {(appointments &&
                        appointments.length > 0 &&
                        appointments.map(appointment => (
                            <AppointmentPill
                                allocation={{
                                    Appointment: appointment,
                                    Type: appointment.Type,
                                    Start: joinDefined([appointment.Date, appointment.TimeFrom], ' '),
                                    End: joinDefined([appointment.Date, appointment.TimeTo], ' '),
                                    LocationFlattened: appointment.LocationFlattened
                                }}
                                onEdit={() => this.onEditMeeting(appointment.ID, officeId)}
                            />
                        ))) ||
                        (!!loading && (
                            <Grid pc={1}>
                                <LinearProgress />
                            </Grid>
                        ))}

                    <Grid pc={1}>
                        <InlineField lineHeight={1}>
                            {!!form.getField('ID') && (
                                <PrimaryButton onClick={e => this.onClickAddMeeting(form)}>
                                    <CalendarIcon />
                                    Add Appointment...
                                </PrimaryButton>
                            )}
                            <OutlineButton target="_blank" onClick={() => viewCalendar()}>
                                Open calendar
                            </OutlineButton>
                        </InlineField>
                    </Grid>
                </ValidationPlaceholder>
            </Grid>
        );
    };

    renderLegacyDocuments(form) {
        const enquiryDocuments = form.getField('LegacyDocuments') || [];
        const arrangementDocuments = form.getField('Prearrangement.LegacyDocuments') || [];
        const legacyDocuments = [].concat(enquiryDocuments, arrangementDocuments);

        return (
            <Fragment>
                <Grid item xs={12}>
                    <Label text={'Related Documents'} />
                    {legacyDocuments.length > 0 ? (
                        <Table>
                            <TableHeaderRow>
                                <TableHeaderCell>ID</TableHeaderCell>
                                <TableHeaderCell>Filename</TableHeaderCell>
                                <TableHeaderCell>Size</TableHeaderCell>
                            </TableHeaderRow>
                            {legacyDocuments.map((obj, i) => {
                                return (
                                    <TableRow key={i} pad={true}>
                                        <TableCell>#{obj.ID}</TableCell>
                                        <TableCell>
                                            <LinkButton
                                                onClick={() =>
                                                    this.setState({
                                                        documentModal: { open: true, document: obj }
                                                    })
                                                }
                                                text={obj.Name}
                                            />
                                        </TableCell>
                                        <TableCell>{obj.Size}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    ) : (
                        <p>There are currently no documents.</p>
                    )}
                    {this.renderNewUpload(form)}
                </Grid>
            </Fragment>
        );
    }

    renderNewUpload(form) {
        return (
            <div>
                <FileUpload
                    label={'Upload Document'}
                    buttonOnly={true}
                    folderPath={`/documents/enquiries/${form.getField('ID')}`}
                    onComplete={({ uploadFile }) => this.onFileUploaded(uploadFile, form)}
                />
            </div>
        );
    }

    onFileUploaded(upload, form) {
        const LegacyDocuments = form.getField('LegacyDocuments') || [];
        LegacyDocuments.push({
            ID: upload.id,
            AbsoluteLink: upload.AbsoluteLink,
            Name: getFileName(upload.filename),
            Size: 'Save to confirm'
        });
        form.setField({ LegacyDocuments });
    }

    handleMutateError(e) {
        console.error(e);
        this.props.setSnackbarMessage('Error - the record may have failed to generate.', false, null, new Error(e));
    }

    addQuote(quote, form) {
        const { Quotes } = this.state;
        Quotes.push(quote);
        this.setState(Quotes);
        this.onClickViewQuote(quote.ID);
    }

    updateQuote(quote, form) {
        const { Quotes } = this.state;
        const index = IndexOf(Quotes, x => x.ID === quote.ID);
        Object.assign(Quotes[index], quote);
        this.setState(Quotes);
        this.onClickViewQuote(quote.ID);
    }

    updateReleaseQuote(id, form) {
        const { Quotes } = this.state;
        const index = IndexOf(Quotes, x => x.ID === id);

        let releaseQuote = Quotes[index];
        releaseQuote.Released = true;

        Object.assign(Quotes[index], releaseQuote);
        this.setState(Quotes);
        this.onClickViewQuote(id);
    }

    generateFuneral = ({ form, refetch }) => {
        const me = this;
        me.setState({ generating: true });

        return createFuneralAsync(form).then(
            ({ data }) => {
                if (data.createFuneral) {
                    refetch();
                    me.setState({ generating: false });
                }
            },
            e => this.handleMutateError(e)
        );
    };

    generateOnlineOrder = ({ form, refetch }) => {
        const me = this;
        me.setState({ generating: true });

        return createOnlineOrderAsync(form).then(
            ({ data }) => {
                if (data.createOnlineOrder) {
                    refetch();
                    me.setState({ generating: false });
                }
            },
            e => this.handleMutateError(e)
        );
    };

    generatePrearrangement = ({ form, refetch }) => {
        const { Quotes } = this.state;
        const currentEnquiryID = form.getField('ID');
        const relatedPrearranged = form.getField('Prearranged') || form.getField('Prearrangement');
        const quoteAccepted = !!Quotes && !!Quotes.find(e => !!e.Accepted && e.Enquiry.ID === currentEnquiryID);
        const canCreate = !!quoteAccepted && !(!!relatedPrearranged && relatedPrearranged.ID !== '0');

        if (!canCreate) {
            return Promise.resolve(false);
        }

        const me = this;
        me.setState({ generating: true });

        return createPrearrangementAsync(form, quoteAccepted).then(
            ({ data }) => {
                if (data.createPrearrangement) {
                    refetch();
                    me.setState({ generating: false });
                }
            },
            e => this.handleMutateError(e)
        );
    };

    openAlertModal(title, message, onAccept) {
        this.setState({
            alertModalState: {
                open: true,
                title,
                message,
                onAccept: onAccept
                    ? () => {
                          onAccept();
                          this.closeAlertModal();
                      }
                    : null,
                onCancel: () => this.closeAlertModal()
            }
        });
    }

    closeAlertModal() {
        this.setState({
            alertModalState: {
                open: false,
                title: '',
                message: '',
                onAccept: () => undefined,
                onCancel: () => this.closeAlertModal()
            }
        });
    }

    openSearchModal(form) {
        let searchModalSuggestion = joinDefined(
            [form.getField('GivenName'), form.getField('MiddleName'), form.getField('Surname')],
            ' '
        );
        this.setState({ openSearchModal: true, searchModalSuggestion });
    }

    copyDetailsFromEnquiry(e, form) {
        if (!isNullOrUndefined(e)) {
            const state = copyEnquiry(e);
            const type = form.getField('EnquiryType');
            const situation = form.getField('Situation');
            if (type === 'ATNEED') state.Situation = 'dead';
            else if (type === 'PRENEED') state.Situation = 'alive';
            else state.Situation = situation;
            form.setField(state);
        }
        this.setState({ openSearchModal: false, searchModalSuggestion: null });
    }
}

const styles = ({ typography, breakpoints, palette, transitions, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome],
        display: 'flex',
        flexFlow: 'wrap',
        alignItems: 'center'
    },
    keyline: {
        width: 1,
        height: 30,
        display: 'inline-block',
        margin: '0 16px',
        opacity: 0.5,
        backgroundColor: palette.action.active
    },
    nameTitle: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        opacity: 0,
        color: palette.text.primary,
        transition: transitions.create(['opacity'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    nameTitleActive: {
        opacity: 1
    },

    paper: {
        padding: '16px 25px'
    },
    bucket: {
        borderRadius: 10,
        margin: 0,
        marginBottom: 4,
        paddingBottom: 12,
        border: '1px solid transparent',
        // backgroundColor: palette.contentBackground[funeralHome],
        '&:hover, .active': {
            // backgroundColor: 'white',
            backgroundColor: palette.contentBackground[funeralHome]
            // border: '1px dotted ' + palette.contentForeground[funeralHome],
            // border: '1px solid ' + palette.contentForeground[funeralHome],
        }
    },
    pickHome: {
        width: 245,
        maxWidth: '100%'
    },
    inlineInputs: {
        marginRight: '1rem'
    },
    bodyPadding: {
        padding: '1em 2em 2em 2em',
        [breakpoints.down('sm')]: {
            padding: '1em 1em 2em 1em'
        }
    },
    tableIconButton: {
        height: '32px',
        width: '32px',
        '& svg': {
            height: '18px'
        }
    },
    tableContainer: {
        [breakpoints.down('xs')]: {
            '& table tr > :nth-child(2), & table tr > :nth-child(3), & table tr > :nth-child(5), & table tr > :nth-child(7), & table tr > :nth-child(8)': {
                display: 'none'
            }
        }
    },
    appDetail: {
        marginTop: '10px'
    },
    tickIcon: { color: palette.button.save }
});

const readOfficeHighestCompletionRateMemberID = gql`
    query ReadOfficeHighestCompletionRateMemberID($id: ID!) {
        readOneOfficesUsername(ID: $id) {
            ID
            getHighestCompletionRateMemberID
        }
    }
`;

const readEnquiryAppointments = gql`
    query ReadEnquiryAppointments($filters: [Filters]!) {
        readAppointments(filters: $filters) {
            edges {
                node {
                    ID
                    Reason
                    Type
                    Date
                    TimeFrom
                    TimeTo
                    Phone
                    Members {
                        ID
                        FirstName
                        Surname
                        Phone
                        Email
                    }
                    LocationFlattened
                }
            }
        }
    }
`;

const readQuote = gql`
    ${Quote}
    query ReadQuote($id: ID!) {
        readOneQuote(ID: $id) {
            ...Quote
        }
    }
`;

const readQuoteSummaries = gql`
    query ReadQuoteSummaries($filters: [Filters]!) {
        readQuotes(filters: $filters) {
            edges {
                node {
                    ID
                    Title
                    Created
                    Accepted
                    Released
                    QuoteType
                    RespondedDate
                    CalcQuoteTotal
                    Enquiry {
                        ID
                    }
                    QuoteTemplate {
                        ID
                        LegacyKey
                    }
                }
            }
        }
    }
`;

export default withErrorBoundary(compose(withSnackbarMessage, withStyles(styles))(Enquiry), {
    fallback: ErrorFallback
});
