import gql from 'graphql-tag';

export default gql`
    fragment Marriages on Funeral {
        Marriages {
            ID
            Type
            Gender
            FirstName
            MiddleName
            Surname
            CurrentSurname
            AgeMarried
            Country
            State
            Suburb
            LastEdited
            Created
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementMarriages on Prearrangement {
        Marriages {
            ID
            Type
            Gender
            FirstName
            MiddleName
            Surname
            CurrentSurname
            AgeMarried
            Country
            State
            Suburb
            LastEdited
            Created
        }
    }
`;
