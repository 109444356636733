import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '../form/Grid';
import TextField from '../form/TextField';
import Checkbox from '../form/Checkbox';
import Inline from '../../page/workQueue2/extras/Inline';
import Button from '../../page/workQueue2/extras/Button';
import { createForm } from '../../util/form';
import Spinner from '../Spinner';
import FileUpload, { getFileName } from '../form/FileUpload';
import Select from '../form/Select';
import { getMyClient } from '../../apollo';
import FlexGrid from '../../page/workQueue2/extras/FlexGrid';
import FileUploadCard from '../../page/workQueue2/extras/FileUploadCard';
import HtmlEditor from '../form/HtmlEditor';
import { InlineFieldRightAlignChildren } from '../form/Inline';
import PrimaryButton from '../form/PrimaryButton';
import TableData from '../form/TableData';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Hidden,
    IconButton,
    LinearProgress,
    withStyles
} from '@material-ui/core';
import CommunicationNote from '../../page/fragments/CommunicationNote';
import { cloneDeep } from 'apollo-utilities';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import {
    niceDateFromString,
    niceDateTimeFromString,
    niceTimeFromString,
    shortDateTimeFromString
} from '../../util/date';
import { joinDefined } from '../../util/strings';
import { WhiteLoader } from '../WhiteLoader';
import Label from '../form/Label';
import BiggerMessageBar from '../BiggerMessageBar';
import cx from 'classnames';
import { CloseIcon, EditIcon, EnvelopeIcon, PayableIcon, SaveIcon, TickIcon } from '../IconIndex';
import moment from 'moment';

class CommunicationNotes extends Component {
    state = {
        communicationNoteTemplates: [],
        savingCommunicationNote: false,
        sendingCommunicationNote: false,
        open: false
    };
    form = createForm(this, {});

    onClose = () => {
        this.form = createForm(this, {});
        this.refetchModal = () => null;
        this.setState({ open: false });
    };

    onOpen = noteID => {
        this.setState({ open: noteID || 0, LockoutWarning: false });
    };

    render() {
        const { recordID, recordType } = this.props;
        const variables = { recordID: recordID, recordField: recordType ? recordType + 'ID' : 'ID' };
        return (
            <Grid bucket>
                <Grid item pc>
                    <Label text={'Communication Notes'} />
                    {!recordID && (
                        <p>
                            Communication notes can be added after the{' '}
                            {recordType ? recordType.toLowerCase() : 'record'} is saved.
                        </p>
                    )}

                    {!!recordID && (
                        <Query query={queryMyNotes} variables={variables} client={getMyClient()}>
                            {({ data, loading, error, refetch }) => {
                                if (error) return 'error';
                                this.refetchList = refetch;
                                const result =
                                    !!data &&
                                    !!data.readCommunicationNotes &&
                                    data.readCommunicationNotes.edges.map(e => e.node);
                                return this.renderNotesTable(loading, result);
                            }}
                        </Query>
                    )}
                </Grid>
                {!!recordID && (
                    <Grid item xs={12}>
                        <PrimaryButton onClick={() => this.onOpen(0)}>
                            <PayableIcon />
                            Add new note...
                        </PrimaryButton>
                    </Grid>
                )}
                {this.renderNoteModal()}
            </Grid>
        );
    }

    renderNotesTable = (loading, data) => {
        const { classes } = this.props;
        if (!data || !data.length) return <p>There are currently no communication notes.</p>;
        return (
            <div style={{ marginTop: 16 }}>
                {(!!loading && <LinearProgress />) || (
                    <TableData
                        columns={[
                            {
                                label: 'Sent',
                                render: (val, row) => {
                                    return this.getIsSent(row) ? <TickIcon className={classes.tickIcon} /> : null;
                                },
                                styles: { padding: 0, textAlign: 'center' }
                            },
                            {
                                label: 'Subject',
                                id: 'Title',
                                render: (val, row) => {
                                    const isSent = !!this.getIsSent(row);
                                    const isSentEmail = !!row.EmailSent;
                                    return (
                                        <>
                                            <strong>{val}</strong>
                                            <br />
                                            <small>
                                                {isSent
                                                    ? (isSentEmail ? 'Emailed ' : 'Posted ') +
                                                      shortDateTimeFromString(
                                                          row.EmailSent ||
                                                              joinDefined([row.MailDate, row.MailTime], ' ')
                                                      )
                                                    : 'Draft only'}
                                            </small>
                                        </>
                                    );
                                }
                            },
                            {
                                render: (val, row) => (
                                    <IconButton onClick={() => this.onOpen(row.ID)}>
                                        <EditIcon />
                                    </IconButton>
                                ),
                                styles: { padding: 0, textAlign: 'center' }
                            }
                        ]}
                        data={data}
                    />
                )}
            </div>
        );
    };

    renderNoteModal() {
        const { open } = this.state;
        if (!open) {
            return this.renderModal();
        }
        const variables = { noteId: open };
        return (
            <Query query={queryCommunicationNote} variables={variables} client={getMyClient()}>
                {({ data, error, loading, refetch }) => {
                    const oldID = this.form.getField('ID');
                    const newID = !!data && !!data.readOneCommunicationNote && data.readOneCommunicationNote.ID;
                    if (newID) {
                        if (newID !== oldID) {
                            this.form.original = cloneDeep(data.readOneCommunicationNote);
                            this.form.state = cloneDeep(data.readOneCommunicationNote);
                        } else {
                            const oldStamp = this.form.getField('LastEdited');
                            const newStamp = data.readOneCommunicationNote.LastEdited;
                            if (newStamp !== oldStamp) {
                                this.form.original = cloneDeep(data.readOneCommunicationNote);
                                this.form.state = cloneDeep(data.readOneCommunicationNote);
                            }
                        }
                    }
                    this.refetchModal = refetch;
                    return this.renderModal(loading);
                }}
            </Query>
        );
    }

    renderModal(loading) {
        const { classes } = this.props;
        const { open } = this.state;
        if (open === false) return null;
        return (
            <Dialog
                classes={{ paper: cx(classes.root, 'dialog-modal modal') }}
                open={open !== false}
                onClose={this.onClose}
                disableEnforceFocus
                disableBackdropClick
                disableEscapeKeyDown
            >
                {!!loading && <WhiteLoader />}
                {this.renderNoteDetails()}
            </Dialog>
        );
    }

    renderFooter = () => {
        const { savingCommunicationNote, sendingCommunicationNote } = this.state;
        const { state: noteObj, original } = this.form;
        const readonlyCommunicationNote = this.getIsSent(original);

        const disabledSendMailButton =
            !!savingCommunicationNote ||
            !!sendingCommunicationNote ||
            !!noteObj.EmailSent ||
            !!this.form.getDirty().length ||
            !noteObj.ID ||
            !noteObj.Title ||
            !noteObj.Description ||
            !noteObj.SendEmail ||
            !(noteObj.EmailApplicant || noteObj.EmailAuthorised || (noteObj.EmailOther && noteObj.OtherEmailAddress));

        const disabledSaveButton =
            !!savingCommunicationNote || !!sendingCommunicationNote || !this.form.getDirty().length;

        let saveButtonIcon = <SaveIcon />;
        let saveButtonText = !this.form.getDirty().length && this.form.getField('ID') ? 'Saved' : 'Save Changes';
        let sendButtonIcon = <EnvelopeIcon />;
        let sendButtonText = 'Send Email';

        if (!!sendingCommunicationNote) {
            sendButtonIcon = <Spinner size="xs" />;
            sendButtonText = 'Sending';
        } else if (!!savingCommunicationNote) {
            saveButtonIcon = <Spinner size="xs" />;
            saveButtonText = 'Saving';
        }

        return (
            <InlineFieldRightAlignChildren>
                <div>
                    <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                        Close
                    </Button>
                </div>

                {!readonlyCommunicationNote && (
                    <>
                        <Button
                            variant="primary"
                            disabled={disabledSendMailButton}
                            onClick={this.onSendEmail}
                            startIcon={sendButtonIcon}
                        >
                            {sendButtonText}
                        </Button>
                        <Button
                            variant="confirmation"
                            disabled={disabledSaveButton}
                            onClick={this.onSave}
                            startIcon={saveButtonIcon}
                        >
                            {saveButtonText}
                        </Button>
                    </>
                )}
            </InlineFieldRightAlignChildren>
        );
    };

    renderNoteDetails() {
        const {
            orderClient,
            orderAuthorisedContacts,
            classes,
            recordType,
            recordKey,
            recordID,
            substitutionFields
        } = this.props;

        const form = this.form;
        const { original, state: noteObj } = form;
        const attachments = form.getField('Attachments') || [];

        let readonlyCommunicationNote = this.getIsSent(original);

        let displayAttachmentsHeading = !readonlyCommunicationNote || (!!attachments && !!attachments.length);

        let noClient = !orderClient;
        let noClientEmail = noClient || !orderClient.Email;

        let noAuthorisedContact = !orderAuthorisedContacts || orderAuthorisedContacts.length === 0;
        let noAuthorisedContactEmail = noAuthorisedContact;

        if (!noAuthorisedContact) {
            noAuthorisedContactEmail = true;

            orderAuthorisedContacts.forEach(function(authorisedContact, index, object) {
                if (!!authorisedContact.Email) {
                    noAuthorisedContactEmail = false;
                }
            });
        }
        const recordTypeTitle = recordType.replace(/([a-z]+)([A-Z])/, '$1 $2');
        return (
            <>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                        pointerEvents: 'none'
                    }}
                >
                    <PayableIcon
                        style={{
                            opacity: 0.025,
                            width: 300,
                            height: 300,
                            position: 'absolute',
                            right: -24,
                            top: 124,
                            transform: 'rotate(20deg)',
                            zIndex: 0
                        }}
                    />
                </div>
                <DialogTitle className={classes.diagHeader}>
                    <Hidden xsDown>
                        <PayableIcon className={classes.diagHeaderIcon} />
                        <div className={classes.diagHeaderTitle}>
                            {recordTypeTitle} {recordKey || <> #{recordID}</>}
                        </div>
                        <div className={classes.diagHeaderSubtitle}>Communication Note</div>
                    </Hidden>
                    <Hidden smUp>
                        <div className={classes.diagHeaderTitle}>Communication Note</div>
                    </Hidden>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <Grid container spacing={16}>
                        <Grid bucket>
                            <Grid item xs={12}>
                                {!readonlyCommunicationNote && <Label>Prefill from template</Label>}
                                {!readonlyCommunicationNote ? (
                                    <Query query={queryCommunicationNoteTemplates} client={getMyClient()}>
                                        {results => this.renderCommunicationNoteTemplateList(results)}
                                    </Query>
                                ) : (
                                    !!form.getField('Template') &&
                                    !!form.getField('Template.ID') && (
                                        <TextField
                                            label="Communication Template"
                                            readOnly
                                            value={`#${form.getField('Template.ID')}: ${form.getField(
                                                'Template.Title'
                                            )}`}
                                        />
                                    )
                                )}
                                {this.state.LockoutWarning && (
                                    <BiggerMessageBar messageType="info">
                                        This Funeral record will be locked once the note has been sent to the client,
                                        please ensure you complete bookings and data entry beforehand
                                    </BiggerMessageBar>
                                )}
                            </Grid>
                        </Grid>
                        <Grid bucket>
                            <Grid item xs={12}>
                                {!readonlyCommunicationNote && <Label>Compose the note</Label>}
                                <TextField
                                    required
                                    label="Subject"
                                    placeholder="Enter subject of note..."
                                    form={form}
                                    name="Title"
                                    readOnly={readonlyCommunicationNote}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <HtmlEditor
                                    label="Body"
                                    placeholder="Enter body of note, or choose a new template..."
                                    shortcodes={substitutionFields}
                                    required
                                    form={form}
                                    name="Description"
                                    readOnly={readonlyCommunicationNote}
                                />
                            </Grid>

                            {!readonlyCommunicationNote && (
                                <Grid item xs={12}>
                                    {!!displayAttachmentsHeading && <Label>Attach a File</Label>}
                                    <FileUpload
                                        buttonOnly
                                        folderPath={this.getDocumentPath()}
                                        onComplete={({ uploadFile }) =>
                                            this.handleUploadAttachment(uploadFile, attachments)
                                        }
                                    />
                                </Grid>
                            )}

                            {!!attachments &&
                                !!attachments.length &&
                                this.renderAttachmentLinks(attachments, readonlyCommunicationNote)}
                        </Grid>

                        {(!readonlyCommunicationNote || !!form.getField('SendEmail')) && (
                            <Grid bucket>
                                <Grid item xs={12}>
                                    <Inline center>
                                        <Label>Send note via email</Label>

                                        {(!readonlyCommunicationNote && (
                                            <Checkbox
                                                label="Yes, email this note"
                                                form={form}
                                                name="SendEmail"
                                                disabled={readonlyCommunicationNote}
                                            />
                                        )) || (
                                            <Checkbox
                                                label="Send via email"
                                                form={form}
                                                name="SendEmail"
                                                disabled={readonlyCommunicationNote}
                                            />
                                        )}
                                    </Inline>
                                    {!!form.getField('SendEmail') && (
                                        <Fragment>
                                            {(!readonlyCommunicationNote || !!form.getField('EmailApplicant')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to Applicant's email address"
                                                        form={form}
                                                        name="EmailApplicant"
                                                        disabled={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {(!readonlyCommunicationNote || !!form.getField('EmailAuthorised')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to Authorised Contact email addresses"
                                                        form={form}
                                                        name="EmailAuthorised"
                                                        disabled={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {(!readonlyCommunicationNote || !!form.getField('EmailOther')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to other email address"
                                                        form={form}
                                                        name="EmailOther"
                                                        disabled={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {!!form.getField('EmailOther') &&
                                                (!readonlyCommunicationNote ||
                                                    !!form.getField('OtherEmailAddress')) && (
                                                    <TextField
                                                        label="Other email address"
                                                        form={form}
                                                        name="OtherEmailAddress"
                                                        readOnly={readonlyCommunicationNote}
                                                    />
                                                )}
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        )}

                        {(!readonlyCommunicationNote || !!form.getField('SendMail')) && (
                            <Grid bucket>
                                <Grid item xs={12}>
                                    <Inline center>
                                        <Label>Mail note as letter</Label>

                                        {(!readonlyCommunicationNote && (
                                            <Checkbox
                                                label="Yes, mail to a postal address"
                                                form={form}
                                                name="SendMail"
                                                disabled={readonlyCommunicationNote}
                                            />
                                        )) || (
                                            <Checkbox
                                                label="Mail to postal address"
                                                form={form}
                                                name="SendMail"
                                                disabled={readonlyCommunicationNote}
                                            />
                                        )}
                                    </Inline>
                                    {!!form.getField('SendMail') && (
                                        <Fragment>
                                            {(!readonlyCommunicationNote || !!form.getField('MailApplicant')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to Applicant's postal address"
                                                        form={form}
                                                        name="MailApplicant"
                                                        disabled={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {(!readonlyCommunicationNote || !!form.getField('MailAuthorised')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to Authorised Contact postal addresses"
                                                        form={form}
                                                        name="MailAuthorised"
                                                        disabled={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {(!readonlyCommunicationNote || !!form.getField('MailOther')) && (
                                                <div>
                                                    <Checkbox
                                                        label="Send to other postal address"
                                                        form={form}
                                                        name="MailOther"
                                                        readOnly={readonlyCommunicationNote}
                                                    />
                                                </div>
                                            )}

                                            {!!form.getField('MailOther') &&
                                                (!readonlyCommunicationNote || !!form.getField('OtherMailAddress')) && (
                                                    <TextField
                                                        label="Other postal address"
                                                        form={form}
                                                        name="OtherMailAddress"
                                                        readOnly={readonlyCommunicationNote}
                                                    />
                                                )}
                                        </Fragment>
                                    )}
                                </Grid>

                                {!!form.getField('SendMail') && (
                                    <Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Mail Date"
                                                type="date"
                                                form={form}
                                                name="MailDate"
                                                readOnly={readonlyCommunicationNote}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Mail Time"
                                                type="time"
                                                form={form}
                                                name="MailTime"
                                                readOnly={readonlyCommunicationNote}
                                            />
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        )}
                        {form.getField('ID') > 0 && (
                            <Grid item xs={12} style={{ margin: '10px 10px 0 8px' }}>
                                {!!form.getField('EmailSent') && (
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Label>Note History</Label>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Email Date"
                                                value={niceDateFromString(form.getField('EmailSent'))}
                                                name="EmailSent"
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Email Time"
                                                value={niceTimeFromString(form.getField('EmailSent'))}
                                                name="EmailSent"
                                                readOnly
                                            />
                                        </Grid>
                                    </Fragment>
                                )}

                                {form.getField('CreatedBy.ID') > 0 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            readOnly
                                            label={'Created'}
                                            value={this.showAuthor(
                                                form.getField('CreatedBy'),
                                                form.getField('Created')
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions className={cx(classes.diagActions, 'modal-footer', 'paper')}>
                    {this.renderFooter()}
                </DialogActions>
            </>
        );
    }

    renderCommunicationNoteTemplateList = ({ error, loading, data }) => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;
        let readonlyCommunicationNote = this.getIsSent(form.original);
        const { recordType } = this.props;

        if (error) {
            return 'Error!';
        }

        if (loading) {
            return <span>Loading...</span>;
        }

        if (!data) {
            return '';
        }

        const templates = data.readCommunicationNoteTemplates.edges;

        let selectTemplateOptions = [];

        for (let x = 0; x < templates.length; x++) {
            if (templates[x].node.AddLinkToFuneralArranger && recordType !== 'Funeral') {
                continue;
            }
            selectTemplateOptions.push({
                label: '#' + templates[x].node.ID + ': ' + templates[x].node.Title,
                value: templates[x].node.ID
            });
            communicationNoteTemplates[templates[x].node.ID] = templates[x].node;
        }

        return (
            <Select
                label="Communication Template"
                placeholder="Choose a template..."
                name="Template.ID"
                options={selectTemplateOptions}
                onChange={this.handleCommunicationNoteTemplateChange}
                form={form}
                readOnly={readonlyCommunicationNote}
            />
        );
    };

    getDocumentPath() {
        return `Uploads/CommunicationNotes/FM2/${this.form.getField('ID')}`;
    }

    clearState() {
        this.setState({
            communicationNoteTemplates: [],
            savingCommunicationNote: false,
            sendingCommunicationNote: false
        });
    }

    getIsSent(row) {
        return !!(row.EmailSent || this.getIsMailed(row));
    }

    getIsMailed(row) {
        const mailStamp = moment(row.MailDate + ' ' + row.MailTime);
        return mailStamp && mailStamp.isValid() && mailStamp.isBefore();
    }

    showAuthor(author, date) {
        const { FirstName, Surname, Email } = author || {};
        const Name = joinDefined([FirstName, Surname], ' ');
        return joinDefined(['By', Name || Email || 'Unknown', 'on', niceDateTimeFromString(date)], ' ');
    }

    onSave = () => {
        const { onSave, setSnackbarMessage } = this.props;
        const { sendingCommunicationNote } = this.state;

        if (onSave) {
            onSave(this.form.state);
        }

        this.setState({
            savingCommunicationNote: true
        });

        this.saveCommunicationNoteFunction().then(
            ({ data }) => {
                this.setState({
                    savingCommunicationNote: false
                });

                if (!!data) {
                    if ((!!this.form.state.ID && !!data.updateCommunicationNote) || !!data.createCommunicationNote) {
                        const result = data.createCommunicationNote || data.updateCommunicationNote;
                        setSnackbarMessage('Success, your changes have been saved.', true);
                        if (!!data.createCommunicationNote) this.setState({ open: data.createCommunicationNote.ID });
                        else this.refetchModal();

                        if (sendingCommunicationNote) {
                            this.onSendEmail();
                        }
                    } else {
                        console.error('unexpected result: ', data);
                    }
                } else {
                    this.onGqlError('Failed to save communication note');
                }
                this.refetchList();
                this.clearState();
            },
            error => {
                this.clearState();
                this.onGqlError('Failed to save communication note', error, null, new Error(error));
            }
        );
    };

    onSendEmail = () => {
        const { onSendEmail, setSnackbarMessage } = this.props;

        if (onSendEmail) {
            onSendEmail(this.form.state);
        }

        this.setState({
            sendingCommunicationNote: true
        });

        this.sendCommunicationNoteFunction().then(
            ({ data }) => {
                this.setState({
                    sendingCommunicationNote: false
                });

                if (!!data) {
                    if (
                        !!this.form.state.ID &&
                        !!data.updateCommunicationNote &&
                        data.updateCommunicationNote.EmailSent
                    ) {
                        setSnackbarMessage('Success, your email has been sent.', true);
                        this.onClose();
                    } else {
                        setSnackbarMessage('Oops, your email was not sent. Do you have a recipient email address?');
                    }
                    this.refetchModal();
                }
                this.refetchList();
                this.clearState();
            },
            error => {
                this.clearState();
                this.onGqlError('Failed to send communication note!', error);
            }
        );
    };

    onGqlError = (action, error) => {
        const { message } = error;
        const { setSnackbarMessage } = this.props;
        setSnackbarMessage(joinDefined([action, message], '\n'));
    };

    saveCommunicationNoteFunction = async () => {
        const { recordID, recordType } = this.props;
        const form = this.form;
        const data = {
            [recordType + 'ID']: recordID,
            Title: form.state.Title,
            Description: form.state.Description,
            SendEmail: form.state.SendEmail,
            EmailApplicant: form.state.EmailApplicant,
            EmailAuthorised: form.state.EmailAuthorised,
            EmailOther: form.state.EmailOther,
            OtherEmailAddress: form.state.OtherEmailAddress,
            SendMail: form.state.SendMail,
            MailApplicant: form.state.MailApplicant,
            MailAuthorised: form.state.MailAuthorised,
            MailOther: form.state.MailOther,
            OtherMailAddress: form.state.OtherMailAddress,
            MailDate: form.state.MailDate,
            MailTime: form.state.MailTime,
            Attachments: [],
            ScheduledToSendEmail: false, // always false, need to save before we can send
            TemplateID: null
        };

        if (!!form.state.Template && !!form.state.Template.ID) {
            data.TemplateID = form.state.Template.ID;
        }

        let mutation = createCommunicationNote;
        if (!!form.state.ID) {
            data.ID = form.state.ID;
            mutation = updateCommunicationNote;
        }
        if (!!form.state.Attachments && form.state.Attachments.length > 0) {
            form.state.Attachments.forEach(attachment => {
                if (!!attachment.ID) {
                    data.Attachments.push({
                        ID: attachment.ID
                    });
                }
            });
        }
        return await getMyClient().mutate({
            mutation,
            variables: {
                input: data
            }
        });
    };

    sendCommunicationNoteFunction = async () => {
        // assume it is already saved before we send, so attachments are not lost.
        const { recordID, recordType } = this.props;
        const form = this.form;
        const data = {
            [recordType + 'ID']: recordID,
            ID: form.state.ID,
            ScheduledToSendEmail: true
        };

        return await getMyClient().mutate({
            mutation: updateCommunicationNote,
            variables: {
                input: data
            }
        });
    };

    handleCommunicationNoteTemplateChange = event => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;

        let communicationNoteTemplate = communicationNoteTemplates[event.target.value];

        if (!communicationNoteTemplate) {
            this.state.LockoutWarning = false;
            return;
        }

        form.state.Title = this.makeSubstitutions(communicationNoteTemplate.Title);
        form.state.Description = this.makeSubstitutions(communicationNoteTemplate.Description);
        form.state.Attachments = communicationNoteTemplate.Attachments;
        this.setState({ LockoutWarning: !!communicationNoteTemplate.AddLinkToFuneralArranger });
        form.context.forceUpdate();
    };

    makeSubstitutions(description) {
        const { substitutionFields } = this.props;

        if (substitutionFields) {
            for (let key in substitutionFields) {
                if (substitutionFields[key]) {
                    description = description.split(key).join(substitutionFields[key]);
                }
            }
        }
        return description;
    }

    renderAttachmentLinks(attachments, readOnly) {
        return (
            <Grid item>
                <Label> Attached Files </Label>
                <FlexGrid>
                    {attachments.map(attachment => (
                        <FileUploadCard
                            variant={'mini'}
                            key={'file-' + attachment.ID}
                            fileName={attachment.Name}
                            fileLink={attachment.AbsoluteLink}
                            fileId={attachment.ID}
                            onClickRemoveAction={!readOnly ? () => this.onAttachmentRemove(attachment.ID) : undefined}
                        />
                    ))}
                </FlexGrid>
            </Grid>
        );
    }

    handleUploadAttachment(uploadFile, attachments) {
        const form = this.form;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        form.setField({
            Attachments: attachments
        });
    }

    onAttachmentRemove(fileId) {
        const form = this.form;

        let Attachments = form.getField('Attachments');
        Attachments = Attachments.filter(obj => fileId !== obj.ID);

        form.setField({ Attachments });
    }

    refetchModal = () => null;
    refetchList = () => null;
}

const queryCommunicationNoteTemplates = gql`
    query($limit: Int) {
        readCommunicationNoteTemplates(limit: $limit) {
            edges {
                node {
                    ID
                    Title
                    Description
                    AddLinkToFuneralArranger

                    Attachments {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
            }
        }
    }
`;

const createCommunicationNote = gql`
    ${CommunicationNote}
    mutation createCommunicationNote($input: CreateCommunicationNoteInput!) {
        createCommunicationNote(input: $input) {
            ...CommunicationNote
        }
    }
`;

const updateCommunicationNote = gql`
    ${CommunicationNote}
    mutation updateCommunicationNote($input: UpdateCommunicationNoteInput!) {
        updateCommunicationNote(input: $input) {
            ...CommunicationNote
        }
    }
`;

const queryCommunicationNote = gql`
    ${CommunicationNote}
    query readOneCommunicationNote($noteId: ID!) {
        readOneCommunicationNote(ID: $noteId) {
            ...CommunicationNote
        }
    }
`;

const queryMyNotes = gql`
    query readCommunicationNotes($recordID: String!, $recordField: String!) {
        readCommunicationNotes(filters: [{ field: $recordField, value: $recordID }]) {
            edges {
                node {
                    ID
                    Created
                    Title
                    EmailSent
                    MailDate
                    MailTime
                }
            }
        }
    }
`;
const styles = ({ palette, funeralHome, spacing, breakpoints }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset',
        [breakpoints.down('xs')]: {
            margin: spacing.unit
        }
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: spacing.unit * 3
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    diagHeaderIcon: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    },
    content: {
        width: '100%',
        padding: spacing.unit * 3,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340,
        backgroundColor: '#fafafa',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 12rem)',
            padding: spacing.unit
        }
    },
    diagActions: {
        padding: spacing.unit * 3,
        paddingTop: spacing.unit * 2,
        margin: 0,
        justifyContent: 'space-between'
    },
    tickIcon: { color: palette.button.save }
});
export default compose(withSnackbarMessage, withStyles(styles))(CommunicationNotes);
