import gql from 'graphql-tag';

export default gql`
    fragment Informant on Funeral {
        Informant {
            ID
            Signed
            DateOfSignature
            InformantsName
            FirstName
            MiddleName
            Surname
            Email
            Phone
            Mobile
            PostalAddressLine1
            PostalAddressLine2
            PostalState
            PostalSuburb
            PostalCountry
            PostalPostcode
            PostalSameAsResidential
            RelationshipToDeceased
            SuburbCityAust
            ResidentialState
            ResidentialAddress1
            ResidentialPostcode
            Country
            Comment
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementInformant on Prearrangement {
        Informant {
            ID
            Signed
            Email
            Phone
            Mobile
            PostalAddressLine1
            PostalAddressLine2
            PostalState
            PostalSuburb
            PostalCountry
            PostalPostcode
            PostalSameAsResidential
            RelationshipToDeceased
            SuburbCityAust
            ResidentialState
            ResidentialAddress1
            ResidentialPostcode
        }
    }
`;
