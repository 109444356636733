import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../../util/strings';

export default withStyles({})(({ children, variant, className = '' }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `flex-container--${variant}`;

    return <div className={`flex-container ${cssVariant || ''} ${className}`}>{children}</div>;
});
