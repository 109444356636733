import gql from 'graphql-tag';

export default gql`
    fragment Grave on Funeral {
        Grave {
            ID
            GroundDescription
            DepthOption
            ReservationRequired
            ReservationName
            PortionGardenName
            BeamRowLot
            SideSection
            Number
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementGrave on Prearrangement {
        Grave {
            ID
            GroundDescription
            DepthOption
            ReservationRequired
            ReservationName
            PortionGardenName
            BeamRowLot
            SideSection
            Number
        }
    }
`;
