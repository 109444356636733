import React, { Component, Fragment } from 'react';
import { matchPath, withRouter } from 'react-router';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Header from './component/header/Header';
import AppNavDrawer from './component/drawer/AppNavDrawer';
import Footer from './component/footer/Footer';
import Main from './page/Main';
import { isSignedIn } from './util/sessions';
import { getOfficeFromKey, getOfficeFromURLSegment } from './util/brands';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarMessageConsumer, withSnackbarMessage } from './context/SnackbarMessage';
import { compose } from 'react-apollo';
import TickCircleIcon from './component/icon/TickCircleIcon';
import ExclamationCircleIcon from './component/icon/ExclamationCircleIcon';
import { IconButton } from '@material-ui/core';
import CloseIcon from './component/icon/CloseIcon';
import { getCurrentUser, getOffices, loadSiteConfig } from './environment';
import { WhiteLoader } from './component/WhiteLoader';
import cloneDeep from 'lodash.clonedeep';
import { recolour } from './Theme';

class App extends Component {
    state = {
        signedIn: false,
        drawerOpen: false,
        drawerMinimized: false,
        funeralHome: undefined,
        loadedConfig: false
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { location } = newProps;
        const newState = {};
        const signedIn = isSignedIn();
        if (signedIn !== oldState.signedIn) {
            newState.signedIn = signedIn;
        }

        let funeralHome;

        let searchmatch = false;
        if ((searchmatch = matchPath(location.pathname, { path: `/search/:brand` }))) {
            const urlSegment = searchmatch.params.brand;
            funeralHome = urlSegment && getOfficeFromURLSegment(urlSegment);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/funeral/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getOfficeFromKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/documentation/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getOfficeFromKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/prearrangement/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getOfficeFromKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/mortuary/:junk/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getOfficeFromKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/quotes/enquiry/:key/:id` }))) {
            const key = searchmatch && searchmatch.params && searchmatch.params.key;
            funeralHome = key && getOfficeFromKey(key);
        } else if ((searchmatch = matchPath(location.pathname, { path: `/quotes/` }))) {
            const urlParams = new URLSearchParams(window.location.search);
            const key = urlParams.get('office');
            funeralHome = key && getOfficeFromKey(key);
        } else if (['/', '/account'].includes(location.pathname)) {
            const { MyLastOfficeID } = getCurrentUser();
            const isFound = MyLastOfficeID && getOffices().find(obj => obj.ID === MyLastOfficeID);
            funeralHome = isFound && {
                ID: isFound.ID,
                letterCode: isFound.RegistrantSuffix,
                label: isFound.Title,
                logo: isFound.Logo,
                color: isFound.OfficeColour || isFound.ForegroundColour,
                style: 'tablestyle' + isFound.ID
            };
        }

        if (funeralHome !== oldState.funeralHome) {
            newState.funeralHome = funeralHome;
        }

        if (Object.keys(newState).length) {
            return newState;
        }
        return null;
    }

    getBaseTheme() {
        const allOffices = getOffices().map(({ ID, RegistrantSuffix, OfficeColour, ForegroundColour }) => ({
            ID: ID,
            letterCode: RegistrantSuffix,
            color: OfficeColour || ForegroundColour
        }));

        return themeObj => {
            const theme = { ...themeObj };
            theme.palette.contentBackground = Object.fromEntries([
                ...allOffices.map(({ letterCode, color }) => [letterCode, recolour(color, '#FFFFFF', 0.9)]),
                ['none', '#EBF6FD']
            ]);
            theme.palette.contentForeground = Object.fromEntries([
                ...allOffices.map(({ letterCode, color }) => [letterCode, color]),
                ['none', '#35327c']
            ]);
            theme.palette.basePrimary = Object.fromEntries([
                ...allOffices.map(({ letterCode, color }) => [letterCode, color]),
                ['none', '#35327c']
            ]);
            theme.palette.baseSecondary = Object.fromEntries([
                ...allOffices.map(({ letterCode, color }) => [letterCode, recolour(color, '#000000', 0.3)]),
                ['none', '#413BBE']
            ]);
            theme.palette.baseTertiary = Object.fromEntries([
                ...allOffices.map(({ letterCode, color }) => [letterCode, recolour(color, '#FFFFFF', 0.4)]),
                ['none', '#35327c']
            ]);

            const head = document.head;
            if (!head.querySelector('#theme-css') && allOffices.length) {
                const styleSheet = document.createElement('style');
                styleSheet.rel = 'stylesheet';
                styleSheet.id = 'theme-css';
                head.appendChild(styleSheet);
                [...allOffices, { ID: 0, letterCode: 'none' }].forEach(obj => {
                    const tableStyle = `tablestyle${obj.ID}`;
                    const cDark = theme.palette.contentForeground[obj.letterCode];
                    const cLight = theme.palette.contentBackground[obj.letterCode];
                    [
                        `.table .row--${tableStyle} { outline-color: ${cDark};}`,
                        `.table .row--${tableStyle} > .td { background-color: ${cLight}; border-color: ${cDark}66; border-left-color: ${cDark}33;}`,
                        `.table .row--${tableStyle}:hover > .td { background-color: ${cLight}44;}`,
                        `.table .row--${tableStyle} > .td svg, .table .row--${tableStyle} > .td a, .table .row--${tableStyle} > .td a > span {color: ${cDark};}`,
                        `@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) { .table .row--${tableStyle} > .td:after { border-color: ${cDark}; } }`,
                        `.table-key--${tableStyle} {background-color: ${cLight}; color: ${cDark}; border-color: ${cDark};}`
                    ].forEach(rule => styleSheet.sheet.insertRule(rule, styleSheet.sheet.cssRules.length));
                });
            }
            theme.palette.primary = { ...theme.palette.primary, main: '#35327c' };
            theme.palette.secondary = { ...theme.palette.secondary, main: '#413BBE' };
            theme.palette.action = { ...theme.palette.action, active: '#413BBE' };
            theme.funeralHome = 'none';
            return theme;
        };
    }

    getBrandTheme() {
        const { funeralHome } = this.state;
        return themeObj => {
            const theme = { ...themeObj };
            if (funeralHome) {
                const secondaryColour = theme.palette.baseSecondary[funeralHome.letterCode];
                theme.palette.primary = { ...theme.palette.primary, main: funeralHome.color };
                theme.palette.secondary = { ...theme.palette.secondary, main: secondaryColour };
                theme.palette.action = { ...theme.palette.action, active: funeralHome.color };
            }
            theme.funeralHome = (funeralHome && funeralHome.letterCode) || 'none';
            return theme;
        };
    }

    onToggleDrawerOpen = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    };

    onToggleDrawerMinimized = () => {
        this.setState({ drawerMinimized: !this.state.drawerMinimized });
    };

    render() {
        const { classes } = this.props;
        const { signedIn, loadedConfig } = this.state;
        if (signedIn && !loadedConfig) {
            loadSiteConfig().then(() => this.setState({ loadedConfig: true })); // load siteConfig into cache
        }
        const fmTheme = this.getBaseTheme();
        const brandTheme = this.getBrandTheme(cloneDeep(fmTheme));
        return (
            <div className={signedIn ? classes.root : ''}>
                <MuiThemeProvider theme={fmTheme}>
                    {signedIn && (
                        <Fragment>
                            <Header
                                onToggleDrawerOpen={this.onToggleDrawerOpen}
                                onToggleDrawerMinimized={this.onToggleDrawerMinimized}
                                drawerMinimized={this.state.drawerMinimized}
                            />
                            <AppNavDrawer
                                onToggleOpen={this.onToggleDrawerOpen}
                                onToggleMinimized={this.onToggleDrawerMinimized}
                                open={this.state.drawerOpen}
                                minimized={this.state.drawerMinimized}
                            />
                        </Fragment>
                    )}
                    {!signedIn || loadedConfig ? (
                        <MuiThemeProvider theme={brandTheme}>
                            <Main signedIn={signedIn} />
                        </MuiThemeProvider>
                    ) : (
                        <div style={{ margin: 'auto' }}>
                            <WhiteLoader actionWord="Loading" />
                        </div>
                    )}
                    <SnackbarMessageConsumer>{this.renderSnackbar}</SnackbarMessageConsumer>
                    {signedIn && <Footer />}
                </MuiThemeProvider>
            </div>
        );
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.setSnackbarMessage('');
    };

    renderSnackbar = ({ snackbarMessage, snackbarHappy, snackbarDuration, snackbarException }) => {
        const open = !!snackbarMessage;
        const snackbarExceptionText =
            !!snackbarException &&
            (Array.isArray(snackbarException) ? (
                <ul>
                    {snackbarException.map(e => {
                        return <li>{e.message}</li>;
                    })}{' '}
                </ul>
            ) : (
                snackbarException.message
            ));
        const { classes } = this.props;
        const key = Math.random();
        return (
            <Snackbar
                key={key}
                message={
                    <span id="message-id" className={classes.message}>
                        {snackbarMessage &&
                            (snackbarHappy ? (
                                <TickCircleIcon style={{ color: 'white' }} />
                            ) : (
                                <ExclamationCircleIcon style={{ color: 'white' }} />
                            ))}
                        <span>
                            {snackbarMessage}
                            {!!snackbarException && (
                                <>
                                    <br />
                                    <small>{snackbarExceptionText}</small>
                                </>
                            )}
                        </span>
                    </span>
                }
                open={open}
                action={
                    <IconButton onClick={this.handleCloseSnackbar}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
                autoHideDuration={snackbarDuration}
                onClose={this.handleCloseSnackbar}
                className={classes.snackbar}
                classes={{
                    root: !!snackbarMessage ? (!!snackbarHappy ? classes.snackbarHappy : classes.snackbarSad) : null
                }}
            />
        );
    };
}

const styles = ({ spacing, sizes, palette }) => ({
    root: {
        paddingBottom: sizes.footerHeight,
        display: 'flex',
        width: '100%',
        height: '100vh'
    },
    snackbar: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        '& > div': {
            borderRadius: '6px 6px 0 0',
            flexWrap: 'nowrap',
            maxHeight: '50vh',
            overflow: 'hidden',
            overflowY: 'auto',
            alignItems: 'start',
            '& > div:last-of-type': {
                paddingLeft: 0
            }
        }
    },
    snackbarHappy: { '& > div': { background: palette.validation.good } },
    snackbarSad: { '& > div': { background: palette.validation.error } },
    message: {
        display: 'flex',
        //alignItems: 'center',
        '& > span': {
            fontSize: '1.25em',
            marginLeft: spacing.unit * 2,
            marginRight: spacing.unit,
            '& small': {
                fontFamily: 'monospace',
                display: 'block',
                borderTop: '1px dotted white',
                paddingTop: spacing.unit * 2,
                marginTop: spacing.unit * 4
            }
        }
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withRouter,
    withStyles(styles)
)(App);
