import React from 'react';
import gql from 'graphql-tag';
import { joinDefined, prettyPrice } from '../../util/strings';
import moment from 'moment';
import TickIcon from '../../component/icon/TickIcon';
import { stringToDate } from '../../util/date';
import { flattenConnection } from '../../util/functions';
import { deleteTypeName } from '../../util/objects';
import { quoteTypes } from '../../util/quotes';
import LinkButton from '../../component/form/LinkButton';

export const searchEnquiriesQuery = gql`
    query searchEnquiries(
        $createdByID: ID
        $accepted: Boolean
        $released: Boolean
        $contains: String
        $enqType: String
        $preneedType: String
        $officeID: ID
        $limit: Int
        $offset: Int
        $filters: [Filters]
        $sortBy: [SortField]
    ) {
        searchEnquiries(
            CreatedByID: $createdByID
            Accepted: $accepted
            Released: $released
            EnquiryType: $enqType
            PreneedType: $preneedType
            OfficeID: $officeID
            contains: $contains
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            filters: $filters
        ) {
            pageInfo {
                totalCount
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    ID
                    Created
                    LegacyKey
                    EnquiryType
                    RelationshipToDeceased
                    EnquirerGivenName
                    EnquirerSurname
                    GivenName
                    Surname
                    Quotes {
                        ID
                        Created
                        Released
                        RespondedDate
                        Accepted
                        CalcQuoteTotal
                    }
                }
            }
        }
    }
`;

export const searchEnquiryTotals = gql`
    query(
        $Accepted: Boolean
        $Released: Boolean
        $contains: String
        $enqType: String
        $officeID: ID
        $filters: [Filters]
    ) {
        searchEnquiries(
            Accepted: $Accepted
            Released: $Released
            contains: $contains
            EnquiryType: $enqType
            OfficeID: $officeID
            filters: $filters
        ) {
            pageInfo {
                totalCount
            }
            allCount
            draftCount
            releasedCount
            acceptedCount
            myReleasedCount
            myAcceptedCount
        }
    }
`;

export const getPats = effort => {
    const GOOD_WORK = [
        'Nice work!',
        'Good job!',
        'Congratulations!',
        'Well done!',
        'Amazing!',
        'Woohoo!',
        'Crikey!',
        'You beauty!',
        'High five!',
        'Excellent!',
        'Wow!',
        'Super effort!',
        'Strewth!',
        'Fantastic!',
        'GJWD!',
        "You're doing great!",
        'Nice work speedy gonzalez!',
        'Thank you!'
    ];

    const MORE_WORK = [
        'Just keep at it!',
        "C'mon!",
        "Don't give up!",
        "Let's get on it!",
        'Keep trying!',
        'Starting now!',
        'Warming up!',
        'Bring it on!',
        "Roll up 'ya sleeves!",
        "Let's do it!",
        'You got this!',
        'Who seeks, shall find.',
        "Don't quit now!",
        'Never, never, never give up.',
        'We believe in you!',
        'You can do it!'
    ];
    if (effort > 3) return GOOD_WORK[Math.floor(Math.random() * GOOD_WORK.length)];
    return MORE_WORK[Math.floor(Math.random() * MORE_WORK.length)];
};

export const findQuote = quotes => {
    const sortedQuotes = quotes
        .map(quote => {
            return {
                time: stringToDate(quote.Created),
                quote
            };
        })
        .sort((a, b) => b.time.getTime() - a.time.getTime());

    let lastReleased;
    let lastAccepted;

    for (let x = 0; x < sortedQuotes.length && !lastAccepted; x++) {
        const quote = sortedQuotes[x].quote;
        if (!lastAccepted && quote.Accepted) {
            lastAccepted = quote;
        } else if (!lastReleased && quote.Released) {
            lastReleased = quote;
        }
    }

    return lastAccepted || lastReleased || (sortedQuotes.length > 0 && sortedQuotes[0].quote);
};

export const createColumns = (enquiries, context) => {
    const { history, classes } = context.props;
    const { sortField, sortDirection } = context.state;
    return [
        {
            label: 'Enquiry',
            id: 'enquiryId',
            render: (obj, row, i) => {
                const link = `/quotes/enquiry/${enquiries[i].node.LegacyKey ||
                    enquiries[i].node.Office.RegistrantSuffix}/${obj}`;
                return (
                    <LinkButton
                        href={link}
                        onClick={e => {
                            e.preventDefault();
                            history.push(link);
                        }}
                        text={enquiries[i].node.LegacyKey}
                    />
                );
            },
            styles: { width: '5rem' }
        },
        {
            label: 'Type',
            id: 'enquiryType',
            render: (obj, row, i) =>
                Object.keys(quoteTypes).find(key => quoteTypes[key] === enquiries[i].node.EnquiryType),
            styles: { whiteSpace: 'nowrap' }
        },
        { label: 'Enquirer', id: 'applicant' },
        {
            label: (
                <LinkButton
                    onClick={() =>
                        context.setState({
                            sortField: 'Created',
                            sortDirection: sortDirection === 'DESC' ? 'ASC' : 'DESC'
                        })
                    }
                >
                    {sortField === 'Created' && (sortDirection === 'DESC' ? '\u2193' : '\u2191')} Created
                </LinkButton>
            ),
            id: 'enquiryDate',
            render: val => (val ? moment(val).format('D/MM/YYYY h:mma') : ''),
            styles: { textAlign: 'right', width: '9rem' }
        },
        { label: 'On Behalf Of', id: 'subject' },
        { label: 'Quotes', id: 'quoteCount', styles: { width: '5rem' } },
        { label: 'Status', id: 'lastQuoteStatus', styles: { width: '5rem' } },

        {
            label: 'Amount',
            id: 'lastQuoteAmount',
            render: val => (val ? prettyPrice(val) : ''),
            styles: { textAlign: 'right', width: '5rem' }
        },
        {
            label: 'Accepted',
            id: 'lastQuoteAccepted',
            styles: { textAlign: 'center', width: 1, padding: 0 },
            render: obj => (obj ? <TickIcon className={classes.tickIcon} /> : '')
        }
    ];
};

export const createTableData = enquiries => {
    return (
        enquiries &&
        enquiries.length &&
        enquiries.map(({ node }) => {
            let name = '';
            if (node.RelationshipToDeceased === 'self') {
                name = joinDefined([node.EnquirerGivenName, node.EnquirerSurname], ' ');
            } else {
                name = joinDefined([node.GivenName, node.Surname], ' ');
            }

            let row = {
                legacyKey: node.LegacyKey,
                enquiryId: node.ID,
                enquiryType: node.EnquiryType,
                applicant: joinDefined([node.EnquirerGivenName, node.EnquirerSurname], ' '),
                subject: name,
                quoteCount: node.Quotes.length,
                enquiryDate: node.Created
            };

            if (node.Quotes.length > 0) {
                let quote = findQuote(node.Quotes);

                // Determine status
                let status = 'Draft';
                if (!!quote.Accepted) {
                    status = 'Accepted';
                } else if (!!quote.Released) {
                    status = !!quote.RespondedDate ? 'Declined' : 'Released';
                }

                row.lastQuoteStatus = status;
                row.lastQuoteDate = quote.Created;
                row.lastQuoteAmount = quote.CalcQuoteTotal;
                row.lastQuoteAccepted = quote.Accepted;
            }

            return row;
        })
    );
};

export const cleanUpQuoteConnections = quote => {
    flattenConnection(quote, `QuoteItems`);
    flattenConnection(quote, `QuoteActions`);
    deleteTypeName(quote);
};
