import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import cx from 'classnames';

const FooterItem = ({ item, active, classes, onClick }) => (
    <Fragment>
        <BottomNavigationAction
            key={item.label}
            label={item.label}
            icon={<item.icon />}
            classes={{
                label: classes.label
            }}
            className={cx(classes.root, { [classes.active]: active })}
            showLabel
            onClick={e => onClick(e, item)}
            component={'a'}
            href={item.path}
        />
    </Fragment>
);

const styles = ({ spacing, palette, typography, breakpoints }) => ({
    root: {
        maxWidth: 106,
        color: palette.basePrimary['none'],
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: palette.action.hover
        },
        minWidth: 60,
        [breakpoints.down('sm')]: {
            fontSize: 11,
            minWidth: 'unset'
        }
    },
    active: {
        backgroundColor: palette.action.selected,
        borderBottom: `2px solid ${palette.basePrimary['none']}`,
        borderTop: '2px solid transparent',
        fontWeight: typography.fontWeightMedium
    },
    label: {
        fontSize: typography.subheading.fontSize,
        marginTop: spacing.unit,
        whiteSpace: 'pre',
        [breakpoints.down('sm')]: {
            fontSize: 13
        }
    }
});

export default withStyles(styles)(FooterItem);
