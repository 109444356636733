import React, { Fragment } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FilterIcon from '../../component/icon/FilterIcon';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PrimaryButton, { OutlineButton } from '../../component/form/PrimaryButton';
import Popover from '@material-ui/core/Popover';
import { CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from './contactConstants';

class ContactsFilters extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        activeOnly: false,
        filter: {
            categories: {},
            clergies: {}
        }
    };

    handleBulkSelectChange = name => event => {
        const filter = { ...this.state.filter };

        if (name === 'categories') {
            CONTACT_CATEGORY_OPTIONS.forEach(opt => (filter.categories[opt.value] = true));
        } else {
            CONTACT_CLERGY_OPTIONS.forEach(opt => (filter.clergies[opt.value] = true));
        }

        this.setState({
            filter: filter
        });
    };

    handleBulkUnselectChange = name => event => {
        const filter = { ...this.state.filter };
        filter[name] = {};
        this.setState({ filter: filter });
    };

    handleCategoryChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            //add category
            filter.categories[value] = true;
        } else {
            //remove category
            filter.categories[value] = false;
        }
        this.setState({ filter: filter });
    };

    handleClergyChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            //add clergy
            filter.clergies[value] = true;
        } else {
            //remove clergy
            filter.clergies[value] = false;
        }
        this.setState({ filter: filter });
    };

    handleApplyFilters = event => {
        const thisComp = this;
        //push selected categories to parent state.
        const selectedCategories = [];
        Object.keys(this.state.filter.categories).forEach(function(categoryName, index) {
            if (thisComp.state.filter.categories[categoryName] === true) {
                selectedCategories.push(categoryName);
            }
        });
        // this.props.setFilterCategories(selectedCategories);

        //push selected clergies to parent state.
        const selectedClergies = [];
        Object.keys(this.state.filter.clergies).forEach(function(clergyId, index) {
            if (thisComp.state.filter.clergies[clergyId] === true) {
                selectedClergies.push(clergyId);
            }
        });
        // this.props.setFilterClergies(selectedClergies);
        // this.props.setActiveOnly(this.state.activeOnly);
        this.props.setFilters({
            categories: selectedCategories,
            clergies: selectedClergies,
            activeOnly: !this.state.activeOnly
        });
        this.setState({ open: false });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleOpen = e => {
        let categories = {};
        let clergies = {};
        this.props.filters.categories.forEach(val => {
            categories[val] = true;
        });
        this.props.filters.clergies.forEach(val => {
            clergies[val] = true;
        });
        const filters = { categories: categories, clergies: clergies };
        const activeOnly = !this.props.filters.activeOnly;
        this.setState({ open: true, anchorEl: e.currentTarget, filter: filters, activeOnly: activeOnly });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const hasNoFilters =
            this.props.filters.categories.every(e => {
                return !e;
            }) &&
            this.props.filters.clergies.every(e => {
                return !e;
            }) &&
            this.props.filters.activeOnly;
        return (
            <div style={{ position: 'relative' }}>
                {!hasNoFilters ? (
                    <PrimaryButton onClick={this.handleOpen} style={{ whiteSpace: 'pre', padding: 13 }}>
                        <FilterIcon />
                        Filters ON...
                    </PrimaryButton>
                ) : (
                    <OutlineButton onClick={this.handleOpen} style={{ whiteSpace: 'pre', padding: 13 }}>
                        <FilterIcon />
                        Filters...
                    </OutlineButton>
                )}
                <Popover
                    anchorEl={anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    anchorReference={'anchorEl'}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <div className={classes.paper}>
                        <Typography variant="headline" id="modal-title">
                            Filter
                        </Typography>
                        <div className={classes.paperScroll}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.activeOnly}
                                        onChange={this.handleChange('activeOnly')}
                                        value="ActiveOnly"
                                        color="primary"
                                        name={'activeOnly'}
                                    />
                                }
                                label="Include contacts No Longer Available"
                            />
                            {this.renderCategoryFilters()}
                            {this.renderClergyFilters()}
                        </div>
                        <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                            <Button onClick={this.handleClose} size="small" className={cx(classes.buttonCancel)}>
                                CANCEL
                            </Button>{' '}
                            <Button onClick={this.handleApplyFilters} size="small" className={cx(classes.buttonOk)}>
                                OK
                            </Button>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                </Popover>
            </div>
        );
    }

    renderCategoryFilters() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="title" id="contact-filter-category" className={classes.buttonGroup}>
                    Category
                </Typography>
                <div className={classes.buttonGroup}>
                    <OutlineButton onClick={this.handleBulkSelectChange('categories')}>Select All</OutlineButton>
                    <OutlineButton onClick={this.handleBulkUnselectChange('categories')}>Unselect All</OutlineButton>
                </div>
                <Grid container spacing={0}>
                    {CONTACT_CATEGORY_OPTIONS.map(mappedOption => {
                        const optVal = mappedOption.value;
                        return (
                            <Grid key={optVal} item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.filter.categories[optVal] || false}
                                            onChange={this.handleCategoryChange}
                                            value={optVal}
                                            color="primary"
                                        />
                                    }
                                    label={mappedOption.label}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Fragment>
        );
    }

    renderClergyFilters() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="title" id="contact-filter-clergy" className={classes.buttonGroup}>
                    Clergy / Celebrants
                </Typography>
                <div className={classes.buttonGroup}>
                    <OutlineButton onClick={this.handleBulkSelectChange('clergies')}>Select All</OutlineButton>
                    <OutlineButton onClick={this.handleBulkUnselectChange('clergies')}>Unselect All</OutlineButton>
                </div>
                <Grid container spacing={0}>
                    {CONTACT_CLERGY_OPTIONS.map(mappedOption => {
                        const optVal = mappedOption.value;
                        return (
                            <Grid key={optVal} item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.filter.clergies[optVal] || false}
                                            onChange={this.handleClergyChange}
                                            value={optVal}
                                            color="primary"
                                        />
                                    }
                                    label={mappedOption.label}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Fragment>
        );
    }
}

const styles = ({ theme }) => ({
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
        // marginRight: '10px'
        // boxShadow: 'none',
        // borderRadius: '18px'
    },
    paper: {
        width: '100%',
        maxWidth: 440,
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa',
        padding: 25
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 160px)',
        padding: '6px 0'
    },
    selectButton: {
        marginRight: 10,
        marginTop: 15
    },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' }
});

export default compose(withRouter, withStyles(styles))(ContactsFilters);
