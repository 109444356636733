import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '../extras/Popover';
import Grid from '../../../component/form/Grid';
import ArrangementProgress from '../extras/ArrangementProgress';

class FilterModal extends Component {
    render() {
        const { open, onClose, anchorEl } = this.props;
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                variant="simple"
                className="popover--filter document"
                onClose={onClose}
            >
                <Grid container>
                    <Grid item>
                        <h2>Arrangement Progress</h2>
                        <h3>Summary</h3>
                        <Grid container spacing={12}>
                            <ArrangementProgress adminSummary={{ AdminChecks: [{}] }} groupedIndices={[]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        );
    }
}

export default withStyles({})(FilterModal);
