import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { IconButton, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../../component/form/Grid';
import { isNullOrUndefined } from '../../../util/objects';
import Paper from '@material-ui/core/Paper/Paper';
import CoffinModal from '../Common/CoffinModal';
import CoffinInvalidModal from '../Common/CoffinInvalidModal';
import { getCoffinList } from './CoffinListConstants';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import { getOfficeFromKey } from '../../../util/brands';
import cx from 'classnames';
import { InlineField } from '../../../component/form/Inline';
import BrandedTableKey from '../../../component/BrandedTableKey';
import { CommittalIcon, EditIcon, FireIcon } from '../../../component/IconIndex';
import LinkButton from '../../../component/form/LinkButton';
import Inline from '../../workQueue2/extras/Inline';
import LinearProgress from '@material-ui/core/LinearProgress';
import BrandDot from '../../../component/table/BrandDot';
import ErrorFallback from '../../../component/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class CoffinList extends Component {
    state = {
        coffins: null,
        modalItemId: null,
        invalidModalItem: null
    };

    componentDidMount() {
        getCoffinList().then(coffins => this.setState({ coffins }));
    }

    render() {
        const { coffins, modalItemId, invalidModalItem } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Mortuary</span>
                    <span className={classes.header}>Coffin Distribution</span>
                </Typography>
                <br />
                {isNullOrUndefined(coffins) ? this.renderLoading() : this.renderBody()}
                <CoffinModal
                    context={this}
                    itemId={modalItemId}
                    open={!isNullOrUndefined(modalItemId)}
                    onClose={() => this.setState({ modalItemId: null })}
                    onSaved={() => this.onSaved()}
                />
                <CoffinInvalidModal
                    item={invalidModalItem}
                    open={!isNullOrUndefined(invalidModalItem)}
                    onClose={() => this.setState({ invalidModalItem: null })}
                />
            </Fragment>
        );
    }

    renderKey() {
        return (
            <InlineField center wrap>
                <h5 className="key-heading">Legend:</h5>
                <BrandedTableKey />
            </InlineField>
        );
    }

    renderBody() {
        const { classes } = this.props;
        const { coffins } = this.state;

        if (coffins.error) return <div>failed to get coffin items: {coffins.error}</div>;
        if (coffins.items === null || coffins.items.length === 0) return <div>No items to display.</div>;

        return (
            <Paper className={classes.paper} elevation={0}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title">Coffin distribution list - {coffins.items.length} items</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderKey()}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Table
                            className={cx(classes.table, 'table')}
                            columns={[
                                'Key',
                                'Deceased Name',
                                'Selected Coffin',
                                'Disposal',
                                'Assigned Coffin',
                                'Assign'
                            ]}
                        >
                            {coffins.items.map((coffinItem, index) => this.renderRow(coffinItem, index))}
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Paper className={classes.paper} elevation={0}>
                <LinearProgress />
            </Paper>
        );
    }

    renderRow = (coffinItem, index) => {
        const { classes } = this.props;
        const { label, color, style } = getOfficeFromKey(coffinItem.LegacyKey);
        return (
            <TableRow className={cx('row--' + style, 'row')} key={index} pad={true}>
                <TableCell className={'td'} dataLabel={'Key'}>
                    <LinkButton href={`/funeral/${coffinItem.LegacyKey}/${coffinItem.ID}`}>
                        <BrandDot dotColour={color} title={label} label={coffinItem.LegacyKey} />
                    </LinkButton>
                </TableCell>
                <TableCell className={'td'} dataLabel={'Deceased'}>
                    {coffinItem.Deceased}
                </TableCell>
                <TableCell className={'td td--img'} dataLabel={'Selected Coffin'}>
                    {coffinItem.SelectedCoffinCode ? (
                        <>
                            <Inline center>
                                {coffinItem.SelectedCoffinImage && (
                                    <img
                                        alt={'Image of coffin ' + coffinItem.SelectedCoffinCode}
                                        src={coffinItem.SelectedCoffinImage}
                                    />
                                )}
                                <div>
                                    <small>
                                        {coffinItem.SelectedCoffinCode}
                                        <br />
                                    </small>
                                    {coffinItem.SelectedCoffinDescription}
                                </div>
                            </Inline>
                            {!!coffinItem.SelectedCoffinComment && (
                                <p className={'quoted ' + classes.quoted}>{coffinItem.SelectedCoffinComment}</p>
                            )}
                        </>
                    ) : (
                        <>No coffin selected!</>
                    )}
                </TableCell>
                <TableCell className={'td td-center'}>
                    {'Cremated' === coffinItem.Disposal && (
                        <small>
                            <FireIcon />
                            <br />
                            Cremation
                        </small>
                    )}
                    {'Buried' === coffinItem.Disposal && (
                        <small>
                            <CommittalIcon />
                            <br />
                            Burial
                        </small>
                    )}
                </TableCell>
                <TableCell className={'td td--img'} dataLabel={'Assigned Coffin'}>
                    {coffinItem.ActualCoffinCode ? (
                        <>
                            <Inline center>
                                {coffinItem.ActualCoffinImage && (
                                    <img
                                        alt={'Image of coffin ' + coffinItem.ActualCoffinCode}
                                        src={coffinItem.ActualCoffinImage}
                                    />
                                )}
                                <div>
                                    <small>
                                        {coffinItem.ActualCoffinCode}
                                        <br />
                                    </small>
                                    {coffinItem.ActualCoffinDescription}
                                </div>
                            </Inline>
                            {!!coffinItem.ActualCoffinComment && (
                                <p className={'quoted ' + classes.quoted}>{coffinItem.ActualCoffinComment}</p>
                            )}
                        </>
                    ) : (
                        coffinItem.SelectedCoffinCode && <>Use selected coffin.</>
                    )}
                </TableCell>
                <TableCell className={'td td-center'}>
                    <IconButton onClick={() => this.onSelectRow(index)}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    onSaved() {
        this.setState({ coffins: null });
        getCoffinList().then(coffins => this.setState({ coffins, modalItemId: null }));
    }

    onSelectRow(index) {
        const { coffins } = this.state;
        const item = coffins.items[index];

        if (!item.SelectedCoffinCode) {
            this.setState({ invalidModalItem: item });
        } else {
            this.setState({ modalItemId: item.ID });
        }
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },

    paperModal: {
        position: 'absolute',
        backgroundColor: 'white',
        minWidth: '30%',
        minHeight: '30%',
        width: '30%',
        height: 'auto',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },

    paperMainPage: {
        padding: spacing.unit * 3,
        position: 'absolute',
        backgroundColor: 'white',
        outline: 'none'
    },

    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },

    addButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        backgroundColor: palette.button.alt.backgroundColor,
        borderRadius: 18
    },

    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    icon: {
        fontSize: 16,
        color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& tbody td': {
            padding: 8
        },
        '& .td--img img': {
            maxWidth: 144,
            maxHeight: 64,
            objectFit: 'contain'
        },
        '& .td.td-center': {
            textAlign: 'center'
        }
    },
    quoted: {
        margin: '6px 0 0'
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(CoffinList), {
    fallback: ErrorFallback
});
