import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Grid from '../../../../component/form/Grid';
import TextField from '../../../../component/form/TextField';
import { InlineField } from '../../../../component/form/Inline';
import Checkbox from '../../../../component/form/Checkbox';
import PrimaryButton, { OutlineButton } from '../../../../component/form/PrimaryButton';
import CloseIcon from '../../../../component/icon/CloseIcon';
import { createForm } from '../../../../util/form';
import Label from '../../../../component/form/Label';
import TimeSelect from '../../../../component/form/TimeSelect';
import { adjustTimeString, dateTimeToString } from '../../../../util/date';
import TickCircleIcon from '../../../../component/icon/TickCircleIcon';

class ReflectionRoomBookingModal extends Component {
    state = {
        ReflectionRoomID: null
    };

    form = createForm(this, {});

    componentWillReceiveProps(nextProps, nextContext) {
        const { ReflectionRoomID } = this.state;
        const { data } = nextProps;
        if (data && ReflectionRoomID !== data.ID) {
            this.form.state = { ...data };
            if (nextProps.defaultDate && !data.Date) this.form.state.Date = '' + nextProps.defaultDate;
            this.setState({ ReflectionRoomID: data.ID });
        }
    }

    render() {
        const { open, classes } = this.props;
        const form = this.form;
        const readOnly = !!form.getField('Confirmed');

        return (
            <Dialog open={open} className={classes.root}>
                <DialogTitle>Reflection Room booking</DialogTitle>

                <DialogContent className={classes.modalContent}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Checkbox label="Confirmed" form={form} name="Confirmed" disabled />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Date" type="date" form={form} name="Date" readOnly={readOnly} />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <TextField label="Start Time" type="time" form={form} name="Time" readOnly={readOnly} />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <TimeSelect label="Duration (Hours)" form={form} name="Duration" readOnly={readOnly} />
                        </Grid>

                        <Grid item xs={12}>
                            <Label text={'Attendees'} />
                            <InlineField>
                                <Checkbox label="Applicant" form={form} name="Applicant" readOnly={readOnly} />
                                <Checkbox
                                    label="Authorised Contact"
                                    form={form}
                                    name="AuthorisedContact"
                                    readOnly={readOnly}
                                />
                            </InlineField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Attendee Names"
                                form={form}
                                name="AttendeeNames"
                                multiline
                                InputLabelProps={{ shrink: true }}
                                placeholder={'Enter any names, if known...'}
                                readOnly={readOnly}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <div style={{ width: '100%' }}>
                        <div style={{ float: 'right' }}>
                            <PrimaryButton disabled={readOnly} onClick={() => this.closeMe({ ...this.form.state })}>
                                <TickCircleIcon />
                                <span className={classes.svgLabel}>Update Booking</span>
                            </PrimaryButton>
                        </div>
                        <OutlineButton onClick={() => this.closeMe()}>
                            <CloseIcon />
                            <span className={classes.svgLabel}>Close</span>
                        </OutlineButton>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

    closeMe = data => {
        const { onClose } = this.props;
        if (onClose) {
            if (data) {
                data.Start = dateTimeToString(data.Date + ' ' + data.Time);
                data.End = dateTimeToString(data.Date + ' ' + adjustTimeString(data.Time, data.Duration * 60));
            }
            onClose(data);
        }
        this.setState({ ReflectionRoomID: null });
    };
}

const styles = ({ spacing }) => ({
    modalFooter: {
        padding: spacing.unit * 2
    }
});

export default withStyles(styles)(ReflectionRoomBookingModal);
