import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { theme } from '../../Theme.js';
import { DotLegend } from '../../component/form/Legend';
import { SaveButton } from '../../component/form/PrimaryButton';
import { InlineField, InlineHeader } from '../../component/form/Inline';
import { getTransfersFunc, saveTransfersFunc } from './GetSaveTransfersFunc';
import { joinDefined, prettyDisposal, prettyPhone } from '../../util/strings';
import { dateTimeToString, dayDelta, shortDateFromString } from '../../util/date';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import cloneDeep from 'lodash.clonedeep';
import BrandedTableKey from '../../component/BrandedTableKey';
import SaveIcon from '../../component/icon/SaveIcon';
import moment from 'moment';
import LinkButton from '../../component/form/LinkButton';
import Grid from '../../component/form/Grid';
import GatedComponent from '../../component/GatedComponent';
import TextField from '../../component/form/TextField';
import Spinner from '../../component/Spinner';
import { isNullOrUndefined } from '../../util/objects';
import { diff } from '../../util/functions';
import Checkbox from '../../component/form/Checkbox';
import cx from 'classnames';
import { getOfficeFromKey } from '../../util/brands';
import LinearProgress from '@material-ui/core/LinearProgress';
import BrandDot from '../../component/table/BrandDot';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class Transfers extends Component {
    state = {
        transfers: {
            edges: []
        },
        loaded: false
    };

    componentDidMount() {
        const that = this;
        this.unblock = this.props.history.block(this.onHistoryBlock);
        getTransfersFunc().then(original => {
            const transfers = cloneDeep(original);
            that.setState({ transfers, original, loaded: true });
        });
    }

    componentWillUnmount() {
        this.unblock();
    }

    onHistoryBlock = () => {
        return this.isDirty() ? 'You have unsaved changes, are you sure you want to leave this page?' : null;
    };

    isDirty = () => {
        const { transfers, original } = this.state;
        if (!original || !transfers) return null;
        const amDirty = diff(transfers, original, true);
        if (!amDirty) return null;
        return amDirty && amDirty.edges && amDirty.edges.filter(e => !!e.node).length > 0;
    };

    render() {
        const { classes } = this.props;
        const { transfers, loaded } = this.state;
        const isDirty = loaded && this.isDirty();
        return (
            <Fragment>
                {this.renderTitle()}
                <br />
                <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="title">
                                Ready List - {transfers.edges.length} item{transfers.edges.length === 1 ? '' : 's'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderKey()}
                        </Grid>
                    </Grid>
                    <div className={classes.wideScroll}>{this.renderTransfers()}</div>
                    {transfers.edges.length !== 0 && (
                        <GatedComponent showComponentCode="FM_ACCESS_Transfers_Process_Deceased">
                            {() => {
                                return (
                                    <div className={classes.footer}>
                                        <SaveButton
                                            disabled={!loaded || !isDirty}
                                            onClick={() => this.commitTransferChanges()}
                                        >
                                            {!!loaded ? <SaveIcon /> : <Spinner />}
                                            <span className={classes.svgLabel}>
                                                {!!loaded ? 'Process Transfers' : 'Processing...'}
                                            </span>
                                        </SaveButton>
                                    </div>
                                );
                            }}
                        </GatedComponent>
                    )}
                </Paper>
            </Fragment>
        );
    }

    renderTitle() {
        const { classes } = this.props;
        return (
            <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                Transfers
                <span className={classes.header}>Ready List</span>
            </Typography>
        );
    }

    renderKey() {
        return (
            <InlineField center wrap>
                <h5 className="key-heading">Legend:</h5>
                <BrandedTableKey />
                <DotLegend
                    title="Created in the last 7 days"
                    text="New"
                    dotColor={theme.palette.legend.transfers.newColor}
                />
                <DotLegend
                    title="Updated in the last 7 days"
                    text="Updated"
                    dotColor={theme.palette.legend.transfers.updatedColor}
                />
                <DotLegend
                    title="Service in less than 3 days"
                    text="Urgent"
                    dotColor={theme.palette.legend.transfers.urgentColor}
                />
                <DotLegend
                    title="Coroner required"
                    text="Police Transfer"
                    dotColor={theme.palette.legend.transfers.policeTransferColor}
                />
            </InlineField>
        );
    }

    renderTransfers() {
        const { transfers, loaded } = this.state;

        if (!loaded && transfers.edges.length === 0) return <LinearProgress />;

        const transfersList = () => {
            if (transfers.edges.length === 0) {
                return (
                    <tr>
                        <td colSpan={'10'}>
                            <InlineHeader header="No Transfers" />
                        </td>
                    </tr>
                );
            }
            return transfers.edges.map((transfer, i) => this.renderRow(transfer, i));
        };

        const { classes } = this.props;
        return (
            <div className={!loaded ? classes.loadingTable : ''}>
                <table className={cx(classes.table, 'table')}>
                    <thead>
                        <tr className={'row row--header row--padded'}>
                            <th className={cx(classes.colSm, 'td')}>Key</th>
                            <th className="td">Deceased Name</th>
                            <th className="td">Transfer from</th>
                            <th className="td">Date of Service</th>
                            <th className="td">Doctor</th>
                            <th className={cx(classes.colXs, 'td')}>Disposal</th>
                            <th className="td">Transfer Notes</th>
                            <th className={cx(classes.colMd, 'td')} title={'When call was taken'}>
                                Called
                            </th>
                            <th className={cx(classes.colXs, 'td')} title={'Certificates received'}>
                                Certs
                            </th>
                            <th className={cx(classes.colXs, 'td')} title={'Ready for collection'}>
                                Ready
                            </th>
                            <th className={cx(classes.colXs, 'td')} title={'Transfer actioned'}>
                                Actioned
                            </th>
                        </tr>
                    </thead>
                    <tbody>{transfersList()}</tbody>
                </table>
            </div>
        );
    }

    getStateColor = transferState => {
        switch (transferState.toLowerCase()) {
            case 'urgent':
                return theme.palette.legend.transfers.urgentColor;
            case 'new':
                return theme.palette.legend.transfers.newColor;
            case 'updated':
                return theme.palette.legend.transfers.updatedColor;
            case 'police transfer':
                return theme.palette.legend.transfers.policeTransferColor;
            default:
                return '';
        }
    };

    renderRow = (transfer, rowIndex) => {
        const { classes } = this.props;
        const { TransferComment } = this.state;
        const transferStates = [];

        //records created in the last 3 days are new
        if (dayDelta(transfer.node.Created, new Date()) < 3) {
            transferStates.push('New');
        }

        //records edited in the last 7 days are updated
        else if (dayDelta(transfer.node.LastEdited, new Date()) < 7) {
            transferStates.push('Updated');
        }

        //records with dateofservice soon are urgent
        if (transfer.node.DateOfService && dayDelta(transfer.node.DateOfService, new Date()) > -3) {
            transferStates.push('Urgent');
        }

        if (transfer.node.Certification.TypeOfBdmDeathCertificate === 'Coroner') {
            transferStates.push('Police Transfer');
        }

        const { color, style, label } = getOfficeFromKey(transfer.node.LegacyKey);

        return (
            <tr key={rowIndex} className={'row row--padded row--' + style}>
                <td className={'td'} data-label={'Key'}>
                    {!!transferStates.length && (
                        <ul className={classes.dotList}>
                            {transferStates.map((transferState, i) => (
                                <li key={i} title={transferState}>
                                    <DotLegend dotColor={this.getStateColor(transferState)} />
                                </li>
                            ))}
                        </ul>
                    )}
                    <LinkButton
                        onClick={e =>
                            this.props.history.push(
                                '/funeral/' + transfer.node.LegacyKey + '/' + transfer.node.ID + '/certification'
                            )
                        }
                    >
                        <BrandDot dotColour={color} title={label} label={<u>{transfer.node.LegacyKey}</u>} />
                    </LinkButton>
                </td>
                <td className={'td'} data-label={'Deceased Name'}>
                    <div>
                        {joinDefined(
                            [
                                (transfer.node.Surname || '').toUpperCase(),
                                transfer.node.FirstName,
                                transfer.node.MiddleName
                            ],
                            ' '
                        )}
                    </div>
                </td>
                <td className={'td'} data-label={'Transfer from'}>
                    <div>
                        {transfer.node.UseResidentialAddress && transfer.node.UsePlaceOfDeathAddress ? (
                            joinDefined(
                                [
                                    transfer.node.ResidentialAddress1,
                                    transfer.node.ResidentialAddress2,
                                    transfer.node.ResidentialTown,
                                    transfer.node.ResidentialState,
                                    transfer.node.ResidentialCountry
                                ],
                                ', '
                            )
                        ) : transfer.node.UsePlaceOfDeathAddress && transfer.node.PlaceOfDeathType === 'AddressBook' ? (
                            <div>
                                <div>
                                    {transfer.node.PlaceOfDeath.Name}
                                    {transfer.node.PlaceOfDeath.Phone ? (
                                        <Fragment>
                                            <br />
                                            {prettyPhone(transfer.node.PlaceOfDeath.Phone, false)}
                                        </Fragment>
                                    ) : null}
                                </div>
                                <div>
                                    <small>
                                        {joinDefined(
                                            [
                                                transfer.node.PlaceOfDeath.AddressLine1,
                                                transfer.node.PlaceOfDeath.AddressLine2,
                                                joinDefined(
                                                    [
                                                        transfer.node.PlaceOfDeath.Suburb,
                                                        transfer.node.PlaceOfDeath.State
                                                    ],
                                                    ' '
                                                )
                                            ],
                                            ', '
                                        )}
                                    </small>
                                </div>
                            </div>
                        ) : transfer.node.UsePlaceOfDeathAddress && transfer.node.PlaceOfDeathType === 'Residential' ? (
                            joinDefined(
                                [
                                    transfer.node.PlaceOfDeathResidentialAddress.AddressLine1,
                                    transfer.node.PlaceOfDeathResidentialAddress.AddressLine2,
                                    transfer.node.PlaceOfDeathResidentialAddress.Suburb,
                                    transfer.node.PlaceOfDeathResidentialAddress.State
                                ],
                                ', '
                            )
                        ) : transfer.node.TransferFromType === 'Residential' ? (
                            joinDefined(
                                [
                                    transfer.node.TransferFromResidentialAddress.AddressLine1,
                                    transfer.node.TransferFromResidentialAddress.AddressLine2,
                                    transfer.node.TransferFromResidentialAddress.Suburb,
                                    transfer.node.TransferFromResidentialAddress.State
                                ],
                                ', '
                            )
                        ) : (
                            <div>
                                <div>
                                    {transfer.node.TransferFrom.Name}
                                    {transfer.node.TransferFrom.Phone ? (
                                        <Fragment>
                                            <br />
                                            {prettyPhone(transfer.node.TransferFrom.Phone, false)}
                                        </Fragment>
                                    ) : null}
                                </div>
                                <div>
                                    <small>
                                        {joinDefined(
                                            [
                                                transfer.node.TransferFrom.AddressLine1,
                                                transfer.node.TransferFrom.AddressLine2,
                                                joinDefined(
                                                    [
                                                        transfer.node.TransferFrom.Suburb,
                                                        transfer.node.TransferFrom.State
                                                    ],
                                                    ' '
                                                )
                                            ],
                                            ', '
                                        )}
                                    </small>
                                </div>
                            </div>
                        )}
                    </div>
                </td>
                <td className={'td'} data-label={'Date of Service'}>
                    <div>{shortDateFromString(transfer.node.DateOfService)}</div>
                </td>
                <td className={'td'} data-label={'Doctor'}>
                    <div>
                        {transfer.node.Certification.TypeOfBdmDeathCertificate === 'Coroner' ? (
                            <Fragment>
                                {transfer.node.Certification.Coroner.Name}
                                {transfer.node.Certification.Coroner.Phone ||
                                transfer.node.Certification.Coroner.Mobile ? (
                                    <Fragment>
                                        <br />
                                        {prettyPhone(
                                            joinDefined(
                                                [
                                                    transfer.node.Certification.Coroner.Phone,
                                                    transfer.node.Certification.Coroner.Mobile
                                                ],
                                                '/'
                                            ),
                                            false
                                        )}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {transfer.node.Certification.Doctor.Name}
                                {transfer.node.Certification.Doctor.Phone ||
                                transfer.node.Certification.Doctor.Mobile ? (
                                    <Fragment>
                                        <br />
                                        {prettyPhone(
                                            joinDefined(
                                                [
                                                    transfer.node.Certification.Doctor.Phone,
                                                    transfer.node.Certification.Doctor.Mobile
                                                ],
                                                '/'
                                            ),
                                            false
                                        )}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        )}
                    </div>
                </td>
                <td className="td tickBox" data-label={'Disposal'}>
                    <div>{prettyDisposal(!!transfer.node.Disposal && transfer.node.Disposal.CrematedOrBuried)}</div>
                </td>
                <td
                    className={cx('td', classes.clickableCell)}
                    data-label={'Transfer Notes'}
                    onClick={e => this.toggleTextField(rowIndex, 'TransferComment')}
                >
                    <div title="Click to enter notes...">
                        {!isNullOrUndefined(TransferComment) && TransferComment === rowIndex ? (
                            <TextField
                                style={{ minWidth: 200 }}
                                InputProps={{ autoFocus: true }}
                                multiline={true}
                                placeholder="Enter transfer notes here..."
                                value={transfer.node.TransferComment}
                                onChange={e => {
                                    const { transfers } = this.state;
                                    transfers.edges[rowIndex].node.TransferComment = e.target.value || null;
                                    this.setState({ transfers });
                                }}
                                onBlur={e => this.setState({ TransferComment: null })}
                            />
                        ) : (
                            (transfer.node.TransferComment && <small>{transfer.node.TransferComment}</small>) || (
                                <span className={classes.noCallTime}>click to record a note</span>
                            )
                        )}
                    </div>
                </td>
                <td
                    data-label={'Called'}
                    title={'When call was taken'}
                    className={cx('td', classes.clickableCell)}
                    onClick={e => this.toggleField(rowIndex, 'TransferCalled', this.getTimestamp())}
                >
                    <div title="Click to enter time...">
                        {transfer.node.TransferCalled ? (
                            moment(transfer.node.TransferCalled).isValid() ? (
                                moment(transfer.node.TransferCalled).format('llll')
                            ) : (
                                transfer.node.TransferCalled
                            )
                        ) : (
                            <span className={classes.noCallTime}>click to record a time</span>
                        )}
                    </div>
                </td>
                <GatedComponent isEnabledCode="FM_ACCESS_Transfers_Checkoff">
                    {isEnabled => {
                        return (
                            <Fragment>
                                <td className="td tickBox" data-label={'Certificates received'}>
                                    <Checkbox
                                        title={'Certificates received?'}
                                        className={classes.checkBox}
                                        checked={transfer.node.TransferCertificates}
                                        onClick={e =>
                                            this.toggleField(
                                                rowIndex,
                                                'TransferCertificates',
                                                !transfer.node.TransferCertificates,
                                                isEnabled
                                            )
                                        }
                                    />
                                </td>
                                <td className="td tickBox" data-label={'Ready for Collection'}>
                                    <Checkbox
                                        title={'Ready for Collection?'}
                                        className={classes.checkBox}
                                        checked={transfer.node.TransferReady}
                                        onClick={e =>
                                            this.toggleField(
                                                rowIndex,
                                                'TransferReady',
                                                !transfer.node.TransferReady,
                                                isEnabled
                                            )
                                        }
                                    />
                                </td>
                                <td className="td tickBox" data-label={'Transfer Actioned'}>
                                    <Checkbox
                                        title={'Transfer Actioned?'}
                                        className={classes.checkBox}
                                        checked={transfer.node.TransferActioned}
                                        onClick={e =>
                                            this.toggleField(
                                                rowIndex,
                                                'TransferActioned',
                                                !transfer.node.TransferActioned,
                                                isEnabled
                                            )
                                        }
                                    />
                                </td>
                            </Fragment>
                        );
                    }}
                </GatedComponent>
            </tr>
        );
    };

    getTimestamp() {
        return dateTimeToString(new Date());
    }

    toggleTextField(rowIndex, fieldName) {
        const { editComment } = this.state;
        if (!editComment) {
            this.setState({ [fieldName]: rowIndex });
        }
    }

    toggleField(rowIndex, fieldName, newValue, canEdit = true) {
        const { transfers } = this.state;
        const row = transfers.edges[rowIndex];
        if (!canEdit) {
            return;
        }

        var mergedRow = {
            node: Object.assign({}, row.node, { [fieldName]: newValue })
        };

        const mergedTransfers = { edges: [...transfers.edges] };
        mergedTransfers.edges[rowIndex] = mergedRow;

        this.setState({ transfers: mergedTransfers });
    }

    commitTransferChanges() {
        const { transfers, original } = this.state;
        this.setState({ loaded: false });
        const me = this;
        saveTransfersFunc(transfers, original).then(
            data => {
                if (data.updateManyFunerals && data.updateManyFunerals.edges.length) {
                    const newResult = cloneDeep(original);
                    const newItems = data.updateManyFunerals.edges;
                    for (let i = 0; i < newItems.length; i++) {
                        const id = newItems[i].node.ID;
                        const oldResult = newResult.edges.find(e => e.node.ID === id);
                        oldResult.node = newItems[i].node;
                    }
                    const newTransfers = newResult.edges.filter(
                        x => !(!!x.node.TransferReady && !!x.node.TransferActioned && !!x.node.TransferCertificates)
                    );
                    me.setState({
                        loaded: true,
                        transfers: { edges: newTransfers },
                        original: cloneDeep({ edges: newTransfers })
                    });
                } else {
                    this.setState({ loaded: true });
                }
            },
            e => {
                console.error('error occurred saving transfers', e);
                me.setState({ loaded: true });
                me.props.setSnackbarMessage(
                    'Oops, there was an error - your changes have NOT saved.',
                    false,
                    null,
                    new Error(e)
                );
            }
        );
    }
}

const styles = ({ typography, spacing, palette, breakpoints, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    },

    paper: {
        padding: spacing.unit * 3
    },

    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        marginLeft: -12
    },

    keyTitle: {
        margin: '3px 0 0 0'
    },

    noCallTime: {
        fontWeight: 'light',
        fontStyle: 'italic',
        fontSize: '14px',
        color: '#AAA'
    },

    clickableCell: {
        '&:hover': {
            cursor: 'pointer !important',
            background: '#fff !important'
        },
        '& > :first-child': {
            width: '100%'
        }
    },
    loadingTable: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    iconContainer: {
        textAlign: 'center',
        border: '3px solid #757575',
        borderRadius: 5,
        width: '50px !important',
        height: 50
    },

    icon: {
        // fontSize: 16,
        color: 'white',
        // height: '30px',
        width: 44,
        height: 44
    },

    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        width: '100%',
        '& td.tickBox': {
            textAlign: 'center',
            minWidth: 75
        }
    },

    footer: {
        zIndex: 1202,
        position: 'fixed',
        right: 24,
        bottom: 15,
        [breakpoints.down('xs')]: {
            //bottom: 15,
            right: 29
        },
        '& button': {
            minHeight: 48,
            [breakpoints.down('xs')]: {
                minWidth: 36,
                padding: '12px 30px',
                borderRadius: 24
            }
        }
    },

    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            // display: 'none'
        }
    },

    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },

    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },

    dotList: {
        display: 'inline-flex',
        margin: 0,
        padding: 0,
        listStyle: 'none'
    },

    colXs: {
        width: '60px'
    },

    colSm: {
        width: '80px'
    },

    colMd: {
        width: '130px'
    },
    checkBox: {
        '& svg': {
            fontSize: '2.75rem'
        }
    }
});

// prettier-ignore
export default withErrorBoundary(
    compose(withSnackbarMessage, withStyles(styles))(Transfers),
    { fallback: ErrorFallback }
);
