import ClientTab from './client/ClientTab';
import FamilyTab from './family/FamilyTab';
//import CertificationTab from './certification/CertificationTab';
import DetailsTab from './details/DetailsTab';
import SummaryTab from './summary/SummaryTab';

export const TAB = {
    deceased: ClientTab,
    family: FamilyTab,
    //certification: CertificationTab,
    funeralDetails: DetailsTab,
    summary: SummaryTab
};

export const TABS = Object.keys(TAB).map(key => {
    const tab = TAB[key];
    tab.key = tab.label
        .toLowerCase()
        .split(' ')
        .join('-');

    const spaceIndex = tab.label.indexOf(' ');
    tab.propertyKey = tab.key.replace('-', '');
    if (spaceIndex >= 0) {
        //this is a bit round-about. we need it to match TAB[propertyName]
        tab.propertyKey =
            tab.propertyKey.substr(0, spaceIndex) +
            tab.propertyKey[spaceIndex].toUpperCase() +
            tab.propertyKey.substr(spaceIndex + 1);
    }

    tab.path = `/prearrangement/:key/:id/${tab.key}`;
    return TAB[key];
});

// Builds a lookup of funeral field names to the keys of the tabs that query/mutate those fields
export const FIELD_TAB_LOOKUP = TABS.reduce((lookup, tab) => {
    tab.fragment.definitions.forEach(d =>
        d.selectionSet.selections.forEach(selection => {
            const fieldName = selection.name.value;
            if (fieldName === 'ID' || fieldName === 'LegacyKey') return;
            if (!lookup[fieldName]) lookup[fieldName] = [];
            if (!lookup[fieldName].includes(tab.propertyKey)) lookup[fieldName].push(tab.propertyKey);
        })
    );
    return lookup;
}, {});
