import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AnonPage from './layout/AnonPage';
import { isSignedIn } from '../util/sessions';

const NotFound = ({ classes }) => {
    const message = (
        <Fragment>
            <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                Error 404: Page Not Found
            </Typography>
            <Typography gutterBottom>
                The page you are looking for has passed on. Please accept our condolences.
            </Typography>
            <Typography gutterBottom>
                <Link to="/">Return to Home</Link>
            </Typography>
        </Fragment>
    );
    return !!isSignedIn() ? message : <AnonPage>{message}</AnonPage>;
};

const styles = ({ spacing, typography }) => ({
    pageTitle: {
        fontWeight: typography.fontWeightLight
    }
});

export default withStyles(styles)(NotFound);
