import React, { Component } from 'react';
import FamilyFragment from '../../page/funeral/family/FamilyFragment';
import Grid from './Grid';
import gql from 'graphql-tag';
import { getClient } from '../../apollo';
import Select from './Select';
import { joinDefined, stringIsNullOrEmpty } from '../../util/strings';

class FamilyMemberAutocomplete extends Component {
    state = {
        options: [],
        funeralId: undefined
    };

    componentDidMount() {
        this.loadFamilyMembers();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.funeralId !== this.state.funeralId && nextProps.funeralId !== null)
            this.loadFamilyMembers(nextProps);
    }

    loadFamilyMembers(nextProps) {
        const { funeralId } = nextProps || this.props;
        if (!!funeralId && funeralId !== this.state.funeralId) {
            const that = this;
            getFamilyMembersFunc(funeralId).then(options => {
                that.setState({ options, funeralId });
            });
        }
    }

    render() {
        const { options } = this.state;
        const { onChange, value } = this.props;

        const newValue = !!value && !!options.find(val => val.value.toUpperCase() === value.toUpperCase()) ? value : '';
        return (
            <Grid>
                <Select
                    value={newValue}
                    label={!!newValue ? 'A family member' : 'Custom details'}
                    placeholder={
                        options.length > 0 ? 'Add details or select family member...' : 'Please enter details.'
                    }
                    options={options}
                    {...this.props}
                    onChange={e => {
                        const item = options.find(val => e.target.value === val.value);
                        onChange(item);
                    }}
                />
            </Grid>
        );
    }
}

//reads the family fragment
const readOneFuneralQuery = gql`
    ${FamilyFragment}
    query loadFamilyData($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            LegacyKey
            Surname
            ...${FamilyFragment.definitions[0].name.value}
        }
    }`;

//gets the family list from the database and joins it into an options list
const getFamilyMembersFunc = async funeralId => {
    if (!funeralId) return [];

    const asyncQuery = await getClient().query({ query: readOneFuneralQuery, variables: { id: funeralId } });
    if (!asyncQuery || !asyncQuery.data) return [];

    const funeral = asyncQuery.data.readOneFuneral;
    const options = []; //{ label: 'None', value: '', metadata: null }];

    //get children
    for (let x = 0; x < funeral.ChildrenDetails.length; x++) {
        const child = funeral.ChildrenDetails[x];
        const relationship = getRelationship('child', child.Gender);
        const label =
            joinDefined(
                [
                    child.GivenName,
                    child.OtherGivenNames,
                    child.FamilyName || child.FamilyNameAtBirth || funeral.Surname
                ],
                ' '
            ) +
            ' (' +
            relationship +
            ')';
        options.push({
            label: label,
            metadata: {
                relationship: relationship,
                source: {
                    ID: child.ID,
                    FirstName: child.GivenName,
                    MiddleName: child.OtherGivenNames,
                    Surname: child.FamilyName || child.FamilyNameAtBirth || funeral.Surname
                }
            },
            value: label
        });
    }

    //get latest relationship
    const currentRelationship = funeral.Marriages.length > 0 ? funeral.Marriages[0] : null;

    if (currentRelationship && currentRelationship.FirstName) {
        const relationship = getRelationship('spouse', currentRelationship.Gender, currentRelationship.Type);
        const label =
            joinDefined(
                [
                    currentRelationship.FirstName,
                    currentRelationship.MiddleName,
                    currentRelationship.CurrentSurname,
                    funeral.Surname
                ],
                ' '
            ) +
            ' (' +
            relationship +
            ')';
        options.push({
            label: label,
            metadata: {
                relationship: relationship,
                source: {
                    ID: currentRelationship.ID,
                    FirstName: currentRelationship.FirstName,
                    MiddleName: currentRelationship.MiddleName,
                    Surname: currentRelationship.CurrentSurname || funeral.Surname
                }
            },
            value: label
        });
    }

    //get parents
    const parents = [funeral.Parent1, funeral.Parent2];
    for (let x = 0; x < parents.length; x++) {
        const parent = parents[x];
        const relationship = parent.Type;
        const label =
            joinDefined([parent.FirstName, parent.MiddleName, parent.Surname || funeral.Surname], ' ') +
            ' (' +
            relationship +
            ')';

        if (stringIsNullOrEmpty(parent.FirstName)) continue;

        options.push({
            label: label,
            metadata: {
                relationship: relationship,
                source: {
                    ID: parent.ID,
                    FirstName: parent.FirstName,
                    MiddleName: parent.MiddleName,
                    Surname: parent.Surname || funeral.Surname
                }
            },
            value: label
        });
    }

    return options.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });
};

// here we assume there are only two genders. fight me
const getRelationship = (source, gender, status) => {
    const lcGender = gender && gender.toLowerCase();

    if (source === 'spouse') {
        if (status === 'Defacto') return 'De facto';
        if (status === 'Divorced') return 'Ex-Spouse';
        if (lcGender === 'male') return 'Widower';
        if (lcGender === 'female') return 'Widow';
        return 'Spouse';
    }

    if (source === 'child') {
        if (lcGender === 'male') return 'Son';
        if (lcGender === 'female') return 'Daughter';
        return 'Child';
    }

    if (source === 'parent') {
        if (lcGender === 'male') return 'Father';
        if (lcGender === 'female') return 'Mother';
        return 'Parent';
    }

    return 'Unknown';
};

export default FamilyMemberAutocomplete;
