import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import Inline from './extras/Inline';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import CheckableStar from './CheckableStar';
import TickableCircle from './TickableCircle';
import { getUser } from '../../util/sessions';
import Button from '../../component/form/LinkButton';
import { getTaskStatus, getTaskStatusClass, updateTaskFunc } from './TaskConstants';
import { dateTimeToString } from '../../util/date';
import ViewTaskModal from './Modals/ViewTaskModal';

const styles = ({ palette, funeralHome }) => ({
    alignCentre: {
        alignItems: 'center'
    },
    taskCard: {
        borderBottom: '1px solid #dedede',
        margin: 0,
        marginTop: '0!important',
        marginLeft: -26,
        marginRight: -26,
        fontSize: '85%',
        cursor: 'pointer',
        '& > div': {
            padding: '12px 12px 12px 24px',
            display: 'flex',
            flexWrap: 'wrap',
            '&:hover': {
                filter: 'drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1))',
                background: '#FFFFFFCC'
            }
        },
        '& > div > div:first-child': {
            flex: '0 0 26px'
        },
        '& > div > div:last-child': {
            flex: '1 1 338px'
        },
        '& > div > div > *:not(:last-child)': {
            paddingBottom: 6,
            justifyContent: 'space-between'
        }
    },
    taskPopped: {
        '& > div': {
            paddingLeft: 20,
            borderLeft: '4px solid ' + palette.primary.main,
            background: palette.contentBackground[funeralHome],
            '&:hover': { background: palette.contentBackground[funeralHome] + 'CC' }
        }
    },
    smallText: {
        fontSize: '0.875em'
    },
    taskTitle: {
        color: palette.primary.main,
        fontSize: '1.5em'
    },
    wpstar: {
        width: '30px',
        height: '30px'
    }
});

export default compose(
    withStyles(styles),
    withRouter
)(({ tasks, refetch, classes, history, isRecord = false }) => {
    const [loadingTick, setLoadingTick] = React.useState(false);
    const [loadingStar, setLoadingStar] = React.useState(false);
    const [popupTaskID, setPopupTaskID] = React.useState(false);
    const me = getUser();

    const updateTick = task => {
        setLoadingTick(task.ID);
        const updateTask = { ...task };
        if (!task.Completed) {
            updateTask.Completed = dateTimeToString(new Date());
        } else {
            updateTask.Completed = null;
        }
        updateTaskFunc(updateTask).then(
            () => {
                refetch().then(() => setLoadingTick(false));
            },
            () => {
                setLoadingTick(false);
            }
        );
    };

    const handleClose = () => {
        setPopupTaskID(false);
    };

    const updateStar = task => {
        setLoadingStar(task.ID);
        const updateTask = { ...task };
        const myTask = task && task.AssignedMembers.find(e => !!e && Number(e.ID) === Number(me.ID));
        if (!myTask) {
            updateTask.AssignedMembers.push({ ID: me.ID, _join: { TaskMembers: { Starred: null } } });
        }
        const myStar = updateTask && updateTask.AssignedMembers.find(e => !!e && Number(e.ID) === Number(me.ID));
        if (myStar) {
            if (!myStar._join.TaskMembers.Starred) {
                myStar._join.TaskMembers.Starred = dateTimeToString(new Date());
            } else {
                myStar._join.TaskMembers.Starred = null;
            }
            updateTaskFunc(updateTask).then(
                () => {
                    refetch().then(() => setLoadingStar(false));
                },
                () => {
                    setLoadingStar(false);
                }
            );
        }
    };

    const renderTask = task => {
        const myTask = task && task.AssignedMembers.find(e => !!e && Number(e.ID) === Number(me.ID));
        const myStar = myTask && myTask._join && myTask._join.TaskMembers && myTask._join.TaskMembers.Starred;
        const dueClass = getTaskStatusClass(task);
        const status = getTaskStatus(task);
        const meTickLoading = task.ID === loadingTick;
        const meStarLoading = task.ID === loadingStar;

        return (
            <div onClick={() => setPopupTaskID(task.ID)} aria-label="Task Actions">
                <div className={`task-status ${dueClass}`} title={status} />
                <div>
                    <Inline>
                        <Typography className={classes.smallText}>
                            <strong>Task ID:</strong> #{task.ID}
                        </Typography>
                        {task.Due && (
                            <Typography className={cx(classes.smallText, dueClass, 'due-date')}>
                                <strong>Due:</strong> {moment(task.Due).format('DD/MM/YYYY [at] LT')}
                            </Typography>
                        )}
                    </Inline>
                    <Typography variant="h4" className={classes.taskTitle}>
                        {task.Title}
                    </Typography>
                    {/*<Typography>{task.Description}</Typography>*/}
                    <InlineFieldRightAlignChildren className={classes.alignCentre}>
                        <div>
                            <Button variant="link-orange" text="View Details" onClick={() => setPopupTaskID(task.ID)} />
                        </div>
                        <TickableCircle
                            value={!!task.Completed}
                            loading={meTickLoading}
                            onChange={() => updateTick(task)}
                        />
                        <CheckableStar value={!!myStar} loading={meStarLoading} onChange={() => updateStar(task)} />
                    </InlineFieldRightAlignChildren>
                </div>
            </div>
        );
    };
    return (
        !!tasks &&
        tasks.length > 0 && (
            <ul>
                {tasks.map(task => (
                    <li key={task.ID} className={cx(classes.taskCard, task.ID === popupTaskID && classes.taskPopped)}>
                        {renderTask(task)}
                        <ViewTaskModal open={task.ID === popupTaskID} task={{ node: task }} onClose={handleClose} />
                    </li>
                ))}
            </ul>
        )
    );
});
