import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../icon/CloseIcon';
import { isNullOrUndefined } from '../../util/objects';
import Grid from './Grid';
import Fab from '@material-ui/core/Fab';

class PhotoModal extends Component {
    render() {
        const { photo, onClose, classes } = this.props;
        const open = !isNullOrUndefined(photo);

        return (
            <Dialog open={open} classes={{ paper: classes.paperRoot }} className={classes.root} onClose={onClose}>
                <div className={classes.buttonClose}>
                    <Fab onClick={onClose}>
                        <CloseIcon />
                    </Fab>
                </div>
                <DialogTitle>
                    <div>
                        <strong>{photo && photo.Name}</strong>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid pc={1}>
                        <img src={photo && photo.AbsoluteLink} alt={photo && photo.Name} />
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const styles = ({ palette }) => ({
    paperRoot: {
        maxWidth: '95vw',
        overflow: 'unset'
    },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    content: {
        margin: 24,
        marginTop: 0,
        padding: 0
    }
});

export default withStyles(styles)(PhotoModal);
