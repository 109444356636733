import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from './Select';
import TextField from './TextField';
import cx from 'classnames';

/**
 * primary coloured button
 */
class ActionButton extends Component {
    state = {
        open: this.props.startopen
    };

    render() {
        const { classes, onClick, type, text, icon, control, className, color = 'primary' } = this.props;
        const { open } = this.state;

        if (open) {
            //should probably remove concept of 'type'. just pick your control using the control property
            if (type === 'date') return this.renderDate();

            if (type === 'select') return this.renderSelect();

            return control;
        }

        const onClickFunc =
            type !== 'select' && type !== 'date' && !control ? onClick : e => this.setState({ open: true });

        return (
            <Fragment>
                <Button
                    variant="contained"
                    color={color}
                    onClick={onClickFunc}
                    className={cx(classes.addButtonDesktop, className)}
                    {...this.props}
                    classes={{ label: classes.addButtonLabel }}
                >
                    <span className={classes.icon}>{icon}</span>
                    <span className={classes.text}>{text}</span>
                </Button>
            </Fragment>
        );
    }

    renderDate() {
        const controlprops = this.props.controlprops || {};
        return <TextField type="date" {...controlprops} onBlur={e => this.setState({ open: !!e.target.value })} />;
    }

    renderSelect() {
        const { controlprops } = this.props;
        controlprops.selectProps = {
            ...controlprops.selectProps,
            onBlur: () => this.setState({ open: false })
        };
        return <Select {...controlprops} />;
    }
}

const styles = ({ funeralHome, palette }) => ({
    addButtonDesktop: {
        boxShadow: 'none',
        borderRadius: '5px',
        padding: '8px 10px',
        fontSize: '0.975rem',
        lineHeight: 'normal',
        background: 'white',
        color: palette.contentForeground[funeralHome],
        border: '1px solid ' + palette.contentForeground[funeralHome],
        '&:hover': {
            backgroundColor: palette.button.outline.hoverBackgroundColor
        },
        width: '100%',
        justifyContent: 'left'
    },

    addButtonLabel: {
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '14px'
    },

    icon: {
        '& > svg': {
            width: '18px;',
            height: '18px;'
        }
    },

    text: {
        marginLeft: '7px'
    }
});

export default withStyles(styles)(ActionButton);
