import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../util/strings';

export default withStyles({})(({ variant, data, max = 10, children }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `star-rating-component--${variant}`;

    return (
        <div className={`star-rating-component ${cssVariant || ''}`}>
            <div className="data-container">
                <p className="data">
                    {data}
                    {(!isNaN(data) && max && '/' + max) || ''}
                </p>
            </div>
            <div className="star-position">
                <div className="star-rating-container">
                    <div className="number">
                        <p>{data}</p>
                    </div>
                    <div className="container">{children}</div>
                </div>
            </div>
        </div>
    );
});
