import Summary from './Summary';
import SummaryFragment from './SummaryFragment';
import { flattenCateringStaffAllocations, flattenConnection, flattenStaffAllocations } from '../../../util/functions';
import { flattenBookableObjects, isRelatedObjectDefined, sanitizeObjectWithFK } from '../../../util/bookable';
import { messages, validationHelper } from '../../../util/validation';
import { flattenBookingItems } from '../../../util/products';

export default {
    label: 'Summary',
    component: Summary,
    fragment: SummaryFragment,
    onLoad: data => {
        flattenConnection(data, 'Cars');
        flattenConnection(data, 'CateringStaffs');
        flattenConnection(data, 'Clergy');
        flattenConnection(data, 'DefaultMusicians');
        flattenConnection(data, 'EnterMusicians');
        flattenConnection(data, 'ExitMusicians');
        flattenConnection(data, 'GravesideMusicians');
        flattenConnection(data, 'LimoBookings');
        flattenConnection(data, 'RslMasonic');
        flattenConnection(data, 'PlaceOfViewingItems');
        flattenConnection(data, 'ReflectionMusicians');
        flattenConnection(data, 'SpecialInstructions');
        flattenConnection(data, 'ValuableItems');
        flattenConnection(data, 'Arrangers');
        flattenConnection(data, 'Conductors');
        flattenConnection(data, 'Coordinators');

        flattenBookableObjects(['Florists', 'PersonalisedTouches', 'MemorialStationary', 'Caterers'], data);

        if (data.Disposal) {
            flattenBookingItems(data.Disposal, 'DisposalBookingItems');
            data.Disposal.DisposalBookingItems = (data.Disposal.DisposalBookingItems || []).filter(x =>
                isRelatedObjectDefined(x)
            );
            flattenStaffAllocations(data.Disposal);
        }

        flattenStaffAllocations(data.PlaceOfService);
        if (data.PlaceOfViewingItems) {
            for (let x = 0; x < data.PlaceOfViewingItems.length; x++)
                flattenStaffAllocations(data.PlaceOfViewingItems[x]);
        }

        if (data.RefreshmentsVenue) {
            flattenStaffAllocations(data.RefreshmentsVenue);
            flattenCateringStaffAllocations(data.RefreshmentsVenue);
        }
    },

    formatSaveData: (saveData, state) => {
        if (saveData.Arrangers) {
            for (let x = 0; x < saveData.Arrangers.length; x++) {
                sanitizeObjectWithFK(saveData.Arrangers[x], 'Member', false);
                sanitizeObjectWithFK(saveData.Arrangers[x], 'Funeral', false);
            }
        }

        if (saveData.Conductors) {
            for (let x = 0; x < saveData.Conductors.length; x++) {
                sanitizeObjectWithFK(saveData.Conductors[x], 'Member', false);
                sanitizeObjectWithFK(saveData.Conductors[x], 'Funeral', false);
            }
        }

        if (saveData.Coordinators) {
            for (let x = 0; x < saveData.Coordinators.length; x++) {
                sanitizeObjectWithFK(saveData.Coordinators[x], 'Member', false);
                sanitizeObjectWithFK(saveData.Coordinators[x], 'Funeral', false);
            }
        }
    },

    validation: {
        required: [],
        suggested: [],
        onValidate: {
            Arrangers: (newValue, persistedValue, hasValue, getField) => {
                const arrangers = persistedValue || [];
                return arrangers.length > 0
                    ? validationHelper.ok()
                    : validationHelper.suggested(messages.atLeastOne('Arranger'));
            },
            Coordinators: (newValue, persistedValue, hasValue, getField) => {
                const coordinators = persistedValue || [];
                return coordinators.length > 0
                    ? validationHelper.ok()
                    : validationHelper.suggested(messages.atLeastOne('Coordinator'));
            }
        }
    }
};
