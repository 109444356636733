import {
    Arrangers,
    Cars,
    Caterers,
    Certification,
    Clergy,
    Conductors,
    Coordinators,
    DefaultMusicians,
    Disposal,
    EnterMusicians,
    ExitMusicians,
    Florists,
    GravesideMusicians,
    Hearse,
    Informant,
    LimoBookings,
    MemorialStationary,
    NewspaperNotices,
    PersonalisedTouches,
    PlaceOfService,
    PlaceOfViewingItems,
    ReflectionMusicians,
    RefreshmentsVenue,
    RslMasonic,
    SpecialInstructions,
    ValuableItems,
    RunsheetPDFs
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment SummaryFields on Funeral {
                Created
                CreatedBy {
                    ID
                    FirstName
                    Surname
                }
                Office {
                    ID
                    BusinessName
                    Phone
                }

                DateOfService

                WebcastRequired
                WebcastConfirmed
                WebcastEmailedLinkToFamily
                WebcastComment

                AdminChecksComplete

                DoctorAttachedToFlag

                FirstName
                MiddleName
                Surname

                ApprovedByClient
                ApprovedOn
                RejectionNote
                RejectedOn
            }
        `,
        Cars,
        Caterers,
        Certification,
        Clergy,
        DefaultMusicians,
        Disposal,
        EnterMusicians,
        ExitMusicians,
        Florists,
        GravesideMusicians,
        Hearse,
        LimoBookings,
        MemorialStationary,
        NewspaperNotices,
        PersonalisedTouches,
        PlaceOfService,
        PlaceOfViewingItems,
        ReflectionMusicians,
        RefreshmentsVenue,
        RslMasonic,
        SpecialInstructions,
        ValuableItems,
        Arrangers,
        Conductors,
        Coordinators,
        Informant,
        RunsheetPDFs
    ],
    'Funeral'
);
