import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined } from '../../util/strings';
import ButtonWrapper from '../../page/workQueue2/extras/Button';

const styles = () => ({});

const UserCircle = ({ classes, user, person, abbreviation, onDelete }) => {
    const personName = user || (person && joinDefined([person.FirstName, person.Surname], ' '));
    return (
        <ButtonWrapper variant={'tag'} onClick={onDelete} title={personName || user}>
            {personName || user}
        </ButtonWrapper>
    );
};

export const GetInitials = user => {
    return !!user && firstLetter(user.FirstName) + firstLetter(user.Surname);
};

const firstLetter = str => {
    if (!str) return '';

    str = str.trim();

    if (str.length === 0) return '';

    return str[0].toUpperCase();
};

export default withStyles(styles)(UserCircle);
