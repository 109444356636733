import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../../util/strings';

const Table = withStyles({})(({ children, columns, columnspans, ...props }) => {
    const { className, sortableHeaderCell } = props;

    return (
        <table className={`table ${className || ''}`}>
            {columns && (
                <thead>
                    <HeaderRow pad>
                        {columns.map((column, columnIndex) => (
                            <Cell
                                dataLabel={column.label}
                                colSpan={(!!columnspans && columnspans[columnIndex]) || null}
                                key={column.label}
                                className={column.headerClassName}
                            >
                                {(sortableHeaderCell && sortableHeaderCell(column)) || column.label}
                            </Cell>
                        ))}
                    </HeaderRow>
                </thead>
            )}
            <tbody>{children}</tbody>
        </table>
    );
});

const HeaderRow = withStyles({})(({ className, pad, children }) => {
    return <tr className={`row row--header ${pad ? 'row--padded' : ''} ${className || ''}`}>{children}</tr>;
});

const HeaderRowNested = withStyles({})(({ className, pad, children }) => {
    return <tr className={`row row--header-nested ${pad ? 'row--padded' : ''}  ${className || ''}`}>{children}</tr>;
});

const Row = withStyles({})(({ className, pad, variant, children, onClick }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `row--${variant}`;
    return (
        <tr
            className={`row row--normal ${pad ? 'row--padded' : ''} ${className || ''} ${cssVariant || ''} ${(onClick &&
                'hasClick') ||
                ''}`}
            onClick={onClick}
        >
            {children}
        </tr>
    );
});

const RowNested = withStyles({})(({ className, pad, children, onClick }) => {
    return (
        <tr className={`row row--nested ${pad ? 'row--padded' : ''} ${className || ''}`} onClick={onClick}>
            {children}
        </tr>
    );
});

const Cell = withStyles({})(({ className, pad, children, dataLabel, colSpan, rowSpan }) => {
    return (
        <td
            className={`td ${pad ? 'td--padded' : ''} ${className || ''}`}
            data-label={dataLabel}
            colSpan={colSpan}
            rowSpan={rowSpan}
        >
            {children}
        </td>
    );
});

export default Table;

export { HeaderRow, HeaderRowNested, Row, RowNested, Cell };
