import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabbedModal from '../../../component/form/TabbedModal';
import QuotesModalChooseTab from './QuotesModalChooseTab';
import QuotesModalConfirmTab from './QuotesModalConfirmTab';
import QuotesModalAdjustTab from './QuotesModalAdjustTab';
import DataForm from '../../../component/form/DataForm';
import EnvelopeIcon from '../../../component/icon/EnvelopeIcon';
import PrimaryButton, { AltButton, OutlineButton } from '../../../component/form/PrimaryButton';
import Icon from '@material-ui/core/Icon/Icon';
import CloseIcon from '../../../component/icon/CloseIcon';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import gql from 'graphql-tag';
import { compose, Mutation } from 'react-apollo';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import QuotesModalHistoryTab from './QuotesModalHistoryTab';
import { Quote } from '../fragments/Quote';
import { QuoteAction } from '../fragments/QuoteAction';
import cloneDeep from 'lodash.clonedeep';
import { cleanUpQuoteConnections } from '../QuotesConstants';
import GatedComponent from '../../../component/GatedComponent';
import { getAllOffices, getOfficeFromKey } from '../../../util/brands';
import { matchPath } from 'react-router';
import { getEnquiryClient } from '../../../apollo';
import moment from 'moment';
import { stringToDate } from '../../../util/date';
import { setPricesPerOffice } from '../../../util/products';
import { createForm } from '../../../util/form';

const TABS = {
    unsaved: ['Select quote template', 'Check quote items'],
    draft: ['Select quote template', 'Check quote items', 'Preview and send'],
    released: ['View quote', 'Review and resend']
    // accepted: ['View quote']
};

const createNewQuote = () => {
    return {
        AcceptedBy: {},
        CreatedBy: {},
        QuoteItems: [],
        QuoteActions: []
    };
};

class QuotesModal extends Component {
    form = null;
    emailForm = null;
    state = {
        tabIndex: 0,
        selectedQuote: createNewQuote(),
        adminAltered: null
    };

    static getDerivedStateFromProps({ open, viewQuote }, state) {
        const newState = { open };
        if (state.open !== open && open) {
            // when the open state changes to true we reset the state
            newState.selectedQuote = viewQuote ? cloneDeep(viewQuote) : createNewQuote();
            newState.tabIndex = viewQuote && !(viewQuote.Released || viewQuote.Accepted) ? 1 : 0;
            if (viewQuote && !!viewQuote.Released) newState.adminAltered = getOriginalQuoteStatus(viewQuote);
        }
        return newState;
    }

    render() {
        const { classes, emailTo, onClose, open, substitutionFields, viewQuote } = this.props;
        const {
            form: {
                fields: { OfficeID }
            }
        } = this.props; // enquiryForm
        const { selectedQuote, adminAltered } = this.state;
        const originalStatus = getOriginalQuoteStatus(viewQuote);

        // Check which list of tabs to use.
        let list;
        if (selectedQuote) {
            if (!(!!selectedQuote.ID && selectedQuote.ID > 0)) {
                list = TABS.unsaved;
            } else if (selectedQuote.Released || selectedQuote.Accepted) {
                list = TABS.released;
            } else {
                list = TABS.draft;
            }
        } else {
            list = TABS.unsaved;
        }

        const tabs = {
            released: !!(selectedQuote && selectedQuote.Released),
            list: list,
            selected: this.state.tabIndex,
            accepted: selectedQuote.Accepted
        };

        const formData = {
            quote: selectedQuote,
            template: (selectedQuote && selectedQuote.QuoteTemplate && selectedQuote.QuoteTemplate.ID) || null,
            ID: selectedQuote.ID
        };

        const emailData = {
            emailTemplate: null,
            emailSubject: this.setSubject(viewQuote),
            emailTo: emailTo,
            emailCC: true
        };

        if (!this.emailForm && viewQuote) this.emailForm = createForm(this, emailData);

        return (
            <TabbedModal
                tabs={tabs.list}
                tabIndex={tabs.selected}
                className={classes.root}
                contentClassName={classes.paper}
                onChange={this.onChangeTab}
                onClose={onClose}
                open={open}
            >
                {open && (
                    <Fragment>
                        <DataForm
                            loading={false}
                            error={false}
                            data={formData}
                            customClient={getEnquiryClient()}
                            mutation={selectedQuote.ID ? updateQuote : createQuote}
                            variables={this.cleanupData}
                            onSaved={this.savedQuote}
                            buttonStyles={classes.saveButton}
                            customStateModifier={(f, o) => this.setNestedQuote(f, o)}
                            buttonLabels={{
                                isSaved: selectedQuote.ID
                                    ? selectedQuote.Accepted
                                        ? 'Accepted'
                                        : selectedQuote.Released
                                        ? 'Released'
                                        : 'Draft'
                                    : 'No Quote',
                                isUnsaved:
                                    !!selectedQuote.Released || adminAltered !== originalStatus
                                        ? 'Save'
                                        : 'Save & Preview',
                                isNew: 'No Quote',
                                isSaving:
                                    !!selectedQuote.Released || adminAltered !== originalStatus
                                        ? 'Saving...'
                                        : 'Generating PDF...'
                            }}
                        >
                            {(form, save) => {
                                this.form = form;
                                let tab;
                                if (tabs.released || tabs.accepted) {
                                    if (tabs.selected === 0) {
                                        tab = <QuotesModalHistoryTab quote={viewQuote} />;
                                    } else {
                                        tab = (
                                            <QuotesModalConfirmTab
                                                readQuote={viewQuote}
                                                editQuote={form.fields.quote}
                                                emailForm={this.emailForm}
                                                setAdminAction={this.setAdminAction}
                                                adminAltered={adminAltered}
                                                originalStatus={originalStatus}
                                                setSubject={this.setSubject}
                                                substitutionFields={substitutionFields}
                                                OfficeID={OfficeID}
                                            />
                                        );
                                    }
                                } else {
                                    if (tabs.selected === 0) {
                                        tab = (
                                            <QuotesModalChooseTab
                                                form={form}
                                                quotesType={this.props.enqType}
                                                isImminent={this.props.isImminent}
                                            />
                                        );
                                    } else if (tabs.selected === 1) {
                                        tab = (
                                            <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                                                {canEdit => {
                                                    return <QuotesModalAdjustTab canEdit={canEdit} form={form} />;
                                                }}
                                            </GatedComponent>
                                        );
                                    } else if (tabs.selected === 2) {
                                        tab = (
                                            <QuotesModalConfirmTab
                                                readQuote={selectedQuote}
                                                editQuote={form.fields.quote}
                                                emailForm={this.emailForm}
                                                setAdminAction={this.setAdminAction}
                                                adminAltered={adminAltered}
                                                originalStatus={originalStatus}
                                                setSubject={this.setSubject}
                                                substitutionFields={substitutionFields}
                                                OfficeID={OfficeID}
                                            />
                                        );
                                    }
                                }

                                return (
                                    <Fragment>
                                        {tab}
                                        <DialogActions className={classes.actionsBox}>
                                            <div className={classes.actions}>
                                                {this.getActions({ form, save, tabs })}
                                            </div>
                                        </DialogActions>
                                    </Fragment>
                                );
                            }}
                        </DataForm>
                    </Fragment>
                )}
            </TabbedModal>
        );
    }

    setNestedQuote(form, original) {
        form.original.quote = cloneDeep(original);
        cleanUpQuoteConnections(form.original.quote);
        const quote = cloneDeep(form.original.quote);
        form.setOriginalState({ quote });
    }

    setSubject = quote => {
        const {
            form: {
                fields: { OfficeID }
            }
        } = this.props; // enquiryForm
        const { selectedQuote } = this.state;
        const office = getAllOffices().find(e => Number(e.ID) === Number(OfficeID));
        return (
            'Quote #' +
            (!!quote ? quote.ID : selectedQuote.ID) +
            ' from ' +
            (!!office ? office.label : 'H.Parsons Funeral Directors')
        );
    };

    setAdminAction = action => {
        const { selectedQuote } = this.state;
        const { quote } = this.form.fields;

        const originalState = getOriginalQuoteStatus(selectedQuote);

        if (action !== originalState) {
            if (action === 'Accepted') quote.Accepted = true;
            else if (action === 'Declined' || action === 'Released') quote.Accepted = false;
            // always set the date, to trip off a "change" for save. It gets cleaned during save.
            quote.RespondedDate = stringToDate(moment());
        } else {
            quote.Accepted = this.state.selectedQuote.Accepted;
            quote.RespondedDate = this.state.selectedQuote.RespondedDate;
        }
        this.setState({ adminAltered: action });
        this.form.setField({ quote: quote });
    };

    getActions({ form, save, tabs }) {
        const { loading } = form;
        const { classes } = this.props;
        const viewQuote = this.state.selectedQuote;
        const { template, quote } = form.fields;
        const { emailTo, emailCC, emailSubject, emailBody } = (this.emailForm && this.emailForm.state) || {};
        const canEmail = (!!emailTo || !!emailCC) && !!emailSubject && !!emailBody;
        const originalState = getOriginalQuoteStatus(viewQuote);
        const needUpdate = this.state['adminAltered'] !== originalState;

        return (
            <Fragment>
                <OutlineButton
                    onClick={loading ? undefined : this.byeClose}
                    color="primary"
                    disabled={loading}
                    style={{ float: 'left' }}
                >
                    <CloseIcon />
                    <span className={classes.svgLabel}>Cancel</span>
                </OutlineButton>

                {tabs.selected >= 1 /* if not first tab, show a "prev" button */ && (
                    <PrimaryButton
                        onClick={loading ? undefined : this.prevTab}
                        disabled={loading}
                        style={{ float: 'left' }}
                    >
                        <Icon>navigate_before</Icon>
                        <span className={classes.svgLabel}>Previous step</span>
                    </PrimaryButton>
                )}

                {/* if not last tab, show a "next" button, unless on middle tab with a dirty form */}
                {tabs.selected < tabs.list.length - 1 &&
                    (!!template || !!viewQuote.ID) &&
                    !(!!form.isDirty && tabs.selected === 1 && tabs.list.length === 3) && (
                        <PrimaryButton onClick={loading ? undefined : this.nextTab} disabled={loading}>
                            <span className={classes.svgLabel}>Next step</span>
                            <Icon>navigate_next</Icon>
                        </PrimaryButton>
                    )}

                {/* show a "save" button if uh, it's complicated... */}
                {(tabs.selected !== 2 || !!form.isDirty) &&
                    tabs.selected !== 0 &&
                    quote &&
                    ((!quote.Accepted && !quote.Released) || !!needUpdate) &&
                    save}

                {/* show a "send email" button if uh, it's also complicated... */}
                {tabs.selected === tabs.list.length - 1 && viewQuote.ID && (
                    <Mutation
                        client={getEnquiryClient()}
                        mutation={createQuoteAction}
                        onCompleted={this.savedQuote}
                        onError={e => this.handleMutateError(e)}
                    >
                        {(mutate, { loading }) => {
                            const disabled = loading;
                            return (
                                <Fragment>
                                    <AltButton
                                        onClick={e => this.onSave(mutate, !disabled && this.sendEmail(form.fields), e)}
                                        disabled={!!loading || !canEmail || !!needUpdate}
                                    >
                                        <EnvelopeIcon />
                                        <span className={classes.svgLabel}>Send quote</span>
                                    </AltButton>
                                </Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Fragment>
        );
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    nextTab = () => {
        this.setState({ tabIndex: this.state.tabIndex + 1 });
    };

    prevTab = () => {
        this.setState({ tabIndex: this.state.tabIndex - 1 });
    };

    savedQuote = ({ createQuote, createQuoteAction, updateQuote }) => {
        if (createQuote) {
            const newState = {};
            newState.selectedQuote = cloneDeep(createQuote);
            cleanUpQuoteConnections(newState.selectedQuote);
            newState.tabIndex = 2;
            this.props.setSnackbarMessage('Success, quote saved.', true);
            if (this.props.onQuoteCreated) this.props.onQuoteCreated(newState.selectedQuote);
            this.setState(newState);
        } else if (createQuoteAction) {
            let newState = { selectedQuote: cloneDeep(this.state.selectedQuote), tabIndex: 0 };
            newState.selectedQuote.QuoteActions.push({
                ID: createQuoteAction.ID,
                EmailTo: createQuoteAction.EmailTo,
                Created: createQuoteAction.Created,
                ActionedBy: {
                    ID: createQuoteAction.ActionedBy.ID,
                    FirstName: createQuoteAction.ActionedBy.FirstName,
                    Surname: createQuoteAction.ActionedBy.Surname
                }
            });
            this.props.setSnackbarMessage('Success, sent email to: ' + createQuoteAction.EmailTo, true);
            this.props.onQuoteUpdated(newState.selectedQuote); // push result into enquiry data
            if (this.props.onQuoteReleased) this.props.onQuoteReleased(createQuoteAction.Quote.ID);
            this.setState(newState);
        } else if (updateQuote) {
            if (updateQuote.Released) {
                this.setState({
                    tabIndex: 0
                });
            } else {
                this.setState({
                    tabIndex: 2
                });
            }
            // this.byeClose();
            this.props.setSnackbarMessage('Success, your changes were saved.', true);

            let newState = { selectedQuote: cloneDeep(updateQuote) };
            cleanUpQuoteConnections(newState.selectedQuote);

            if (this.props.onQuoteUpdated) {
                let updatedCopy = cloneDeep(updateQuote);
                cleanUpQuoteConnections(updatedCopy);
                this.props.onQuoteUpdated(updatedCopy);
            }
            this.setState(newState);
        }
    };

    byeClose = () => {
        this.setState({ tabIndex: 0, selectedQuote: createNewQuote(), adminAltered: null });
        this.props.onClose();
    };

    sendEmail = ({ quote }) => {
        if (!quote) return false;

        const sendQuote = this.cleanupData({ quote: quote });

        const { emailTo, emailCC, emailSubject, emailBody, emailTemplate } = this.emailForm.state;

        const sendAction = {
            EmailTo: emailTo,
            EmailMe: emailCC,
            EmailSubject: emailSubject,
            EmailBody: emailBody,
            QuoteID: this.state.selectedQuote.ID || sendQuote.ID,
            QuoteEmailTemplateID: emailTemplate
        };

        return { input: sendAction };
    };

    onSave = (mutate, variables, e) => {
        e.preventDefault();
        if (variables) mutate({ variables });
    };

    handleMutateError(e) {
        console.error('A GQL Mutation Error Occurred:', e);
        this.props.setSnackbarMessage(
            'Oops, there was an error - your changes have NOT saved.',
            false,
            null,
            new Error(e)
        );
    }

    cleanupData = e => {
        if (!e.quote) e.quote = this.form.getField('quote');

        const quote = JSON.parse(JSON.stringify(e.quote));
        const quoteID = this.state.selectedQuote.ID;
        quote.EnquiryID = this.props.form.getField('ID');
        if (quoteID) quote.ID = quoteID;
        delete quote.Created;
        delete quote.LegacyKey;
        delete quote.QuoteActions;
        delete quote.__typename;
        delete quote.AbsolutePDFLink;
        delete quote.PDFLastEdited;
        delete quote.CalcQuoteTotal;

        if (quote.QuoteItems) {
            quote.QuoteItems.forEach(obj => {
                delete obj.Title;
                delete obj.Cost;
                delete obj.TotalIncGST;
                delete obj.__typename;
                if (!!obj.Product) {
                    obj.ProductID = obj.Product.ID;
                    delete obj.Product;
                }
                if (!!obj.Variation) {
                    obj.VariationID = obj.Variation.ID;
                    delete obj.Variation;
                }
                if (!!obj.Price) {
                    obj.Price = Number(obj.Price);
                }
            });
        }
        if (this.state.adminAltered) {
            if (this.state.adminAltered === 'Accepted') quote.Accepted = true;
            else if (this.state.adminAltered === 'Declined') quote.Accepted = false;
            else if (this.state.adminAltered === 'Released') {
                quote.RespondedDate = null; // pruned, to prevent being marked as Declined
            }
            quote.Released = true;
            delete quote.AcceptedBy;
        }

        return quote;
    };
}

const getOriginalQuoteStatus = selectedQuote => {
    return (
        (selectedQuote &&
            !!selectedQuote.Released &&
            (!!selectedQuote.Accepted ? 'Accepted' : !!selectedQuote.RespondedDate ? 'Declined' : 'Released')) ||
        null
    );
};

const createQuoteAction = gql`
    ${QuoteAction}
    mutation CreateQuoteAction($input: CreateQuoteActionInput!) {
        createQuoteAction(input: $input) {
            ...QuoteAction
        }
    }
`;

const createQuote = gql`
    ${Quote}
    mutation CreateQuote($input: CreateQuoteInput!) {
        createQuote(input: $input) {
            ...Quote
        }
    }
`;

const updateQuote = gql`
    ${Quote}
    mutation UpdateQuote($input: UpdateQuoteInput!) {
        updateQuote(input: $input) {
            ...Quote
        }
    }
`;

const lookupPriceOverride = ({ Product, Variation }) => {
    const overrides =
        Variation && !!Number(Variation.ID)
            ? Variation.PriceOverrides
            : Product && !!Number(Product.ID)
            ? Product.PriceOverrides
            : false;
    const {
        params: { pathRoot, pathType, pathObj }
    } = matchPath(window.location.pathname, {
        path: `/:pathRoot/:pathType/:pathObj/:pathID`
    });
    const key = pathRoot === 'funeral' ? pathType : pathObj;
    const funeralHome = !!key && getOfficeFromKey(key);
    if (!!funeralHome && !!overrides && overrides.edges.length > 0) {
        const pricesPerOffice = setPricesPerOffice(overrides.edges);
        const override = pricesPerOffice.find(e => Number(e.office) === Number(funeralHome.ID));
        if (!!override) {
            return { Cost: Number(override.price) };
        }
    }
    return false;
};

export const lookupItemSource = ({ Product, Variation }) => {
    const quoteItem = {
        Title: '',
        Cost: 0,
        Code: 0,
        GST: false
    };
    if (!!Product && Number(Product.ID) > 0) {
        quoteItem.Title = Product.Title;
        quoteItem.Cost = Product.BasePrice;
        quoteItem.GST = Product.GST;
        quoteItem.Code = Product.InternalItemID;
    }
    if (!!Variation && Number(Variation.ID) > 0) {
        quoteItem.Title += ' | ' + (Variation.ShortDescription || Variation.InternalItemID);
        quoteItem.Cost = Variation.Price;
        quoteItem.GST = Variation.GST;
        quoteItem.Code += ' | ' + Variation.InternalItemID;
    }
    const Override = lookupPriceOverride({ Product, Variation });
    if (!!Override) {
        quoteItem.Cost = Override.Cost;
    }
    return quoteItem;
};

const styles = ({ breakpoints, spacing }) => ({
    root: {
        maxHeight: 'none',
        [breakpoints.down('xs')]: {
            margin: spacing.unit
        }
    },
    paper: {
        padding: '10px 10px 0',
        width: '100%',
        minHeight: '38vh',
        maxWidth: 1440,
        [breakpoints.down('xs')]: {
            padding: 0
        }
    },
    actions: {
        margin: 0,
        width: '100%',
        textAlign: 'right',
        boxShadow: '-10px -25px 25px 0px #ffffff',
        zIndex: 1,
        '& button': {
            marginRight: 6,
            position: 'static',
            '&:last-child': {
                marginRight: 0
            },
            [breakpoints.down('xs')]: {
                padding: spacing.unit,
                minWidth: spacing.unit * 2,
                '& span > span, & span > svg': {
                    width: 24,
                    height: 24,
                    fontSize: 26,
                    margin: spacing.unit
                }
            }
        }
    },
    actionsBox: {
        margin: -12,
        padding: '24px 40px 50px',
        [breakpoints.down('xs')]: {
            padding: '24px 24px 32px'
        }
    },
    svgLabel: {
        marginLeft: spacing.unit,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    saveButton: {
        position: 'static'
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(QuotesModal);
