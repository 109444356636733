import React, { Component, Fragment } from 'react';
import Grid from './Grid';
import Checkbox from './Checkbox';
import Table, { TableCell, TableRow } from './Table';
import { joinDefined, pad } from '../../util/strings';
import { dateToString, stringToDate } from '../../util/date';
import DeleteIcon from '../icon/DeleteIcon';
import { IconButton, Typography } from '@material-ui/core';
import { InlineField } from './Inline';
import PrimaryButton from './PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import gql from 'graphql-tag';
import { ApolloConsumer, Query } from 'react-apollo';
import BiggerMessageBar from '../BiggerMessageBar';
import TickCircleIcon from '../icon/TickCircleIcon';
import CloseIcon from '../icon/CloseIcon';
import EditIcon from '../icon/EditIcon';
import CateringStaffAutoComplete from './CateringStaffAutoComplete';
import TextField from './TextField';
import TickIcon from '../icon/TickIcon';
import QuestionMarkCircleIcon from '../icon/QuestionMarkCircleIcon';
import AddIcon from '../icon/AddIcon';

class CateringStaffAllocationTable extends Component {
    state = {
        editField: null,
        editIndex: null
    };

    render() {
        const {
            classes,
            form,
            cateringStaffAllocationsField,
            cateringStaffRequirements,
            eventDate,
            numberCaterersNeeded,
            numberPeopleCatering,
            disabled
        } = this.props;
        const { editIndex, editClone } = this.state;

        const messageType = cateringStaffRequirements => {
            if (cateringStaffRequirements.find(e => e.messageType === 'error')) return 'error';
            if (cateringStaffRequirements.find(e => e.messageType === 'warning')) return 'warning';
            if (cateringStaffRequirements.find(e => e.messageType === 'good')) return 'good';
            return 'info';
        };

        const cateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        const canAdd = cateringStaffAllocations.filter(e => !!e.Availability).length < numberCaterersNeeded;

        return (
            <Fragment>
                {numberPeopleCatering > 0 && (
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            Venue needs {numberCaterersNeeded} confirmed catering staff for {numberPeopleCatering}{' '}
                            guests.
                        </Typography>
                    </Grid>
                )}
                {cateringStaffAllocations.length > 0 && (
                    <Fragment>
                        <Grid item xs={12}>
                            <InlineField lineHeight={'normal'}>
                                <Grid pc={1}>
                                    <Table columns={['', 'Allocated Staff', 'Status', 'Arrive', 'Leave', '']}>
                                        {cateringStaffAllocations.map((obj, i) => (
                                            <TableRow
                                                key={i}
                                                className={
                                                    (!obj.Availability && classes.notavailable) ||
                                                    (obj.Confirmed && classes.confirmed)
                                                }
                                            >
                                                <TableCell tiny centred>
                                                    {(obj.Confirmed && <TickIcon className={classes.tickIcon} />) ||
                                                        (!obj.Availability && <CloseIcon color="disabled" />) ||
                                                        (obj.Availability && (
                                                            <QuestionMarkCircleIcon color="primary" />
                                                        ))}
                                                </TableCell>
                                                <TableCell className={classes.readonlyCell}>
                                                    {obj && obj.Name}
                                                </TableCell>

                                                {!obj.Availability ? (
                                                    <TableCell colSpan={3}>
                                                        <InlineField>
                                                            <Checkbox
                                                                disabled={disabled}
                                                                label={'Available'}
                                                                value={obj.CateringStaffID}
                                                                checked={obj.Availability}
                                                                name="Availability"
                                                                onClick={obj =>
                                                                    this.onAvailableCateringStaffAllocation(obj)
                                                                }
                                                            />
                                                            <Query query={readUnavailableReasonsQuery}>
                                                                {({ loading, error, data }) => {
                                                                    //const contact = (data && data.readAddressBooks && data.readAddressBooks.edges[0].node) || {};
                                                                    if (loading) return 'Loading...';
                                                                    if (error) return `Error! ${error.message}`;
                                                                    if (data && data.readCateringStaffDaysoffReasons) {
                                                                        return (
                                                                            <Fragment>
                                                                                <select
                                                                                    disabled={disabled}
                                                                                    name="avaibilityDescription"
                                                                                    className={
                                                                                        classes.avaibilityDescription
                                                                                    }
                                                                                    data-id={obj.CateringStaffID}
                                                                                    onChange={obj =>
                                                                                        this.onAvailabilityDescriptionCateringStaffAllocation(
                                                                                            obj
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option key="-1" value={obj.Points}>
                                                                                        {obj.AvailabilityDescription ||
                                                                                            'Please select reason unavailable...'}
                                                                                    </option>
                                                                                    {data.readCateringStaffDaysoffReasons.map(
                                                                                        reason => (
                                                                                            <option
                                                                                                key={reason.ID}
                                                                                                value={
                                                                                                    reason.ShiftPenaltyCount
                                                                                                }
                                                                                            >
                                                                                                {reason.Reason}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </Fragment>
                                                                        );
                                                                    }
                                                                }}
                                                            </Query>
                                                        </InlineField>
                                                    </TableCell>
                                                ) : editIndex === i ? (
                                                    <Fragment>
                                                        <TableCell tiny>
                                                            <InlineField>
                                                                <Checkbox
                                                                    label={'Available'}
                                                                    value={obj.CateringStaffID}
                                                                    checked={obj.Availability}
                                                                    name="Availability"
                                                                    disabled
                                                                />
                                                                <Checkbox
                                                                    label={'Confirmed'}
                                                                    value={obj.CateringStaffID}
                                                                    checked={obj.Confirmed}
                                                                    name="Confirmed"
                                                                    disabled
                                                                />
                                                            </InlineField>
                                                        </TableCell>
                                                        <TableCell className={classes.editCell}>
                                                            <TextField
                                                                type="time"
                                                                value={editClone.TimeFrom}
                                                                onChange={e =>
                                                                    this.onEditField('TimeFrom', e.target.value)
                                                                }
                                                            />
                                                        </TableCell>

                                                        <TableCell className={classes.editCell}>
                                                            <TextField
                                                                type="time"
                                                                value={editClone.TimeTo}
                                                                onChange={e =>
                                                                    this.onEditField('TimeTo', e.target.value)
                                                                }
                                                            />
                                                        </TableCell>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <TableCell tiny>
                                                            <InlineField>
                                                                <Checkbox
                                                                    label={'Available'}
                                                                    value={obj.CateringStaffID}
                                                                    checked={obj.Availability}
                                                                    name="Availability"
                                                                    onClick={obj =>
                                                                        this.onAvailableCateringStaffAllocation(obj)
                                                                    }
                                                                    disabled={disabled}
                                                                />
                                                                <Checkbox
                                                                    label={'Confirmed'}
                                                                    value={obj.CateringStaffID}
                                                                    checked={obj.Confirmed}
                                                                    name="Confirmed"
                                                                    disabled={!obj.Availability || disabled}
                                                                    onClick={obj =>
                                                                        this.onConfirmCateringStaffAllocation(obj)
                                                                    }
                                                                />
                                                            </InlineField>
                                                        </TableCell>

                                                        <TableCell className={classes.readonlyCell}>
                                                            {(!!obj.TimeFrom &&
                                                                moment('2001-01-01 ' + obj.TimeFrom).format('h:mma')) ||
                                                                ''}
                                                        </TableCell>
                                                        <TableCell className={classes.readonlyCell}>
                                                            {(!!obj.TimeTo &&
                                                                moment('2001-01-01 ' + obj.TimeTo).format('h:mma')) ||
                                                                ''}
                                                        </TableCell>
                                                    </Fragment>
                                                )}

                                                <TableCell tiny centred className={classes.readonlyCell}>
                                                    {editIndex === i ? (
                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                title={'Confirm'}
                                                                onClick={() => this.onSaveRow()}
                                                            >
                                                                <TickCircleIcon className={classes.icon} />
                                                            </IconButton>

                                                            <IconButton
                                                                className={classes.iconButton}
                                                                title={'Cancel'}
                                                                onClick={() => this.onCancelEditRow()}
                                                            >
                                                                <CloseIcon className={classes.icon} />
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                            <IconButton
                                                                disabled={
                                                                    !!obj.Confirmed || !obj.Availability || disabled
                                                                }
                                                                className={classes.iconButton}
                                                                title={'Edit'}
                                                                onClick={() => this.onEditRow(i)}
                                                            >
                                                                <EditIcon className={classes.icon} />
                                                            </IconButton>

                                                            <IconButton
                                                                className={classes.iconButton}
                                                                title={'Remove'}
                                                                onClick={() => this.onDeleteRow(i)}
                                                                disabled={disabled}
                                                            >
                                                                <DeleteIcon className={classes.icon} />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {canAdd && (
                                            <TableRow>
                                                <TableCell tiny centred>
                                                    <AddIcon color={'primary'} />
                                                </TableCell>
                                                <TableCell colSpan="5" className={classes.editCell}>
                                                    <CateringStaffAutoComplete
                                                        onSelect={(e, item) => {
                                                            if (
                                                                cateringStaffAllocations.find(
                                                                    e => e.CateringStaffID === item.ID
                                                                )
                                                            )
                                                                return null;
                                                            const {
                                                                TimeFrom,
                                                                TimeTo,
                                                                DateFrom,
                                                                DateTo
                                                            } = this.getTimesForStaff();
                                                            this.onChange(cateringStaffAllocations.length, {
                                                                Name: item.Name,
                                                                CateringStaffID: item.ID,
                                                                Availability: true,
                                                                TimeFrom,
                                                                TimeTo,
                                                                DateFrom,
                                                                DateTo
                                                            });
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </Grid>
                            </InlineField>
                        </Grid>
                    </Fragment>
                )}
                <Grid item xs={12}>
                    <InlineField lineHeight={'normal'}>
                        <ApolloConsumer>
                            {client => (
                                <Fragment>
                                    {cateringStaffRequirements.length === 0 ? (
                                        <PrimaryButton
                                            disabled={!!this.state.disabledAllocateButton}
                                            onClick={async () => {
                                                this.setState({ disabledAllocateButton: true });
                                                const { data } = await client.query({
                                                    query: readCateringStaffAllocations,
                                                    fetchPolicy: 'network-only',
                                                    variables: {
                                                        count: numberCaterersNeeded,
                                                        bookingDate: eventDate,
                                                        funeralID: form.getField('ID')
                                                    }
                                                });
                                                this.onAllocateStaff(data.readCateringStaffAllocations);
                                            }}
                                        >
                                            {!!this.state.disabledAllocateButton
                                                ? 'loading...'
                                                : '+ Auto-allocate Catering Staff'}
                                        </PrimaryButton>
                                    ) : (
                                        <BiggerMessageBar messageType={messageType(cateringStaffRequirements)}>
                                            {joinDefined(
                                                cateringStaffRequirements.map(e => e.message),
                                                ', '
                                            )}
                                            .
                                        </BiggerMessageBar>
                                    )}
                                </Fragment>
                            )}
                        </ApolloConsumer>
                    </InlineField>
                </Grid>
            </Fragment>
        );
    }

    onEditField(fieldName, value) {
        // console.log('edit field');
        const { editClone } = this.state;
        editClone[fieldName] = value;
        this.setState({ editClone });
    }

    onSaveRow() {
        // console.log('save row');
        const { onChange } = this.props;
        const { editClone, editIndex } = this.state;

        if (onChange) onChange(editIndex, editClone);
        else this.onChange(editIndex, editClone);

        this.onCancelEditRow();
    }

    onCancelEditRow() {
        // console.log('cancel editrow');
        this.setState({ editIndex: null, editClone: null });
    }

    onEditRow(index) {
        // console.log('edit row');
        if (this.state.editIndex) this.onSaveRow();
        const { form, cateringStaffAllocationsField } = this.props;
        const cateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        this.setState({ editIndex: index, editClone: { ...cateringStaffAllocations[index] } });
    }

    onChange(index, NewCateringStaffAllocation) {
        // console.log('NewCateringStaffAllocation', NewCateringStaffAllocation);
        const { form, cateringStaffAllocationsField } = this.props;
        const oldAllocations = form.getField(cateringStaffAllocationsField);
        oldAllocations[index] = NewCateringStaffAllocation;
        form.setField({ [cateringStaffAllocationsField]: oldAllocations });
    }

    getTimesForStaff() {
        // console.log('onallocatestaff');
        this.setState({ disabledAllocateButton: false });

        const { eventDate, eventTime, eventDuration, eventEnd } = this.props;
        const minuteBuffer = 120; // catering starts 2 hours before event
        const durationBuffer = 4; // catering is minimum four hours

        let startTimeString = '' + eventTime;
        if (!(!!eventDate && moment(eventDate).isValid())) return;
        const theDate = dateToString(eventDate);
        if (!startTimeString) startTimeString = '09:30:00';
        else if (startTimeString.length === 5) startTimeString += ':00';
        else if (startTimeString.length !== 8)
            throw new Error('unexpected start time format ' + startTimeString + '. Expected=HH:mm:ss');

        const startTime = stringToDate(`${theDate} ${startTimeString}`);
        let endTime = new Date(startTime.getTime() + (eventDuration || 1) * 60 * 60000); // if no duration, assume 1hr

        const adjustedStartTime = new Date(startTime.getTime() - minuteBuffer * 60000);
        let adjustedEndTime = new Date(adjustedStartTime.getTime() + durationBuffer * 60 * 60000);
        if (eventEnd) {
            let endTimeString = '' + eventEnd;
            if (endTimeString.length === 5) endTimeString += ':00';
            else if (endTimeString.length !== 8)
                throw new Error('unexpected end time format ' + endTimeString + '. Expected=HH:mm:ss');
            endTime = stringToDate(`${theDate} ${endTimeString}`);
        }
        if (adjustedEndTime < endTime) {
            adjustedEndTime = endTime;
        }

        const TimeFrom = `${pad(adjustedStartTime.getHours(), 2)}:${pad(adjustedStartTime.getMinutes(), 2)}:00`;
        const TimeTo = `${pad(adjustedEndTime.getHours(), 2)}:${pad(adjustedEndTime.getMinutes(), 2)}:00`;
        const DateFrom = dateToString(adjustedStartTime);
        const DateTo = dateToString(adjustedEndTime);
        return { TimeFrom, TimeTo, DateFrom, DateTo };
    }

    onAllocateStaff(data) {
        const { TimeFrom, TimeTo, DateFrom, DateTo } = this.getTimesForStaff();
        const cateringStaff = data
            ? data.edges.map(e => {
                  return {
                      ID: e.node.ID,
                      Name: e.node.CateringStaff.Name,
                      Availability: e.node.Availability,
                      Confirmed: e.node.Confirmed,
                      AvailabilityDescription: e.node.AvailabilityDescription,
                      Points: e.node.Points,
                      TimeFrom: e.TimeFrom || TimeFrom,
                      TimeTo: e.TimeTo || TimeTo,
                      DateFrom: DateFrom,
                      DateTo: DateTo,
                      CateringStaffID: e.node.CateringStaff.ID
                  };
              })
            : [];

        const { form, cateringStaffAllocationsField } = this.props;
        form.setField({ [cateringStaffAllocationsField]: cateringStaff });
    }

    onAvailableCateringStaffAllocation(cateringStaffAllocationItem) {
        const { form, cateringStaffAllocationsField } = this.props;
        let CateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        let available = cateringStaffAllocationItem.target.checked;
        let NewCateringStaffAllocations = CateringStaffAllocations.map(item => {
            if (cateringStaffAllocationItem.target.value === item.CateringStaffID) {
                item.Availability = available;
                item.Confirmed = false;
                item.Points = available ? null : item.Points; //reset Points if user is available
            }
            return item;
        });
        form.setField({ [cateringStaffAllocationsField]: NewCateringStaffAllocations });
    }

    onAvailabilityDescriptionCateringStaffAllocation(cateringStaffAllocationItem) {
        const { form, cateringStaffAllocationsField } = this.props;
        let CateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        let availbilityDescription = cateringStaffAllocationItem.target.selectedOptions[0].text;
        let points = cateringStaffAllocationItem.target.value;

        let NewCateringStaffAllocations = CateringStaffAllocations.map(item => {
            if (cateringStaffAllocationItem.target.dataset.id === item.CateringStaffID) {
                item.AvailabilityDescription = availbilityDescription;
                item.Points = points;
            }
            return item;
        });
        form.setField({ [cateringStaffAllocationsField]: NewCateringStaffAllocations });
    }

    onConfirmCateringStaffAllocation(cateringStaffAllocationItem) {
        const { form, cateringStaffAllocationsField } = this.props;
        let CateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        let confirmed = cateringStaffAllocationItem.target.checked;
        let NewCateringStaffAllocations = CateringStaffAllocations.map(item => {
            if (cateringStaffAllocationItem.target.value === item.CateringStaffID) {
                item.Confirmed = confirmed;
            }
            return item;
        });
        form.setField({ [cateringStaffAllocationsField]: NewCateringStaffAllocations });
    }

    onDeleteRow(index) {
        const { form, cateringStaffAllocationsField } = this.props;
        let CateringStaffAllocations = form.getField(cateringStaffAllocationsField) || [];
        CateringStaffAllocations.splice(index, 1);
        form.setField({ [cateringStaffAllocationsField]: CateringStaffAllocations });
    }
}

const styles = ({ palette }) => ({
    iconButton: {
        height: '24px',
        width: '24px'
        //marginTop: '12px'
    },
    icon: {
        height: '18px',
        width: '18px',
        verticalAlign: 'middle'
    },
    readonlyCell: {
        // padding: '10px 0'
    },
    editCell: {
        padding: '0!important'
    },
    tinyCell: {
        width: 1
    },
    viewCalendarButton: {
        float: 'right'
    },
    confirmed: {
        '&:nth-child(n)': {
            background: '#e9faf0'
        }
    },
    notavailable: {
        '& select': {
            height: '30px',
            margin: '3px 0'
        },
        '& td:nth-child(2)': { textDecoration: 'line-through' }
    },
    avaibilityDescription: {
        width: '100%',
        height: '100%'
    },
    tickIcon: { color: palette.button.save }
});

export default withStyles(styles)(CateringStaffAllocationTable);

const readCateringStaffAllocations = gql`
    fragment CateringStaffAllocationFragment on CateringStaffAllocation {
        ID
        Availability
        AvailabilityDescription
        Points
        Start
        End
        Confirmed
        CateringStaff {
            ID
            Name
        }
    }

    query readCateringStaffAllocations($count: Int, $bookingDate: String, $funeralID: String) {
        readCateringStaffAllocations(Count: $count, BookingDate: $bookingDate, FuneralID: $funeralID) {
            edges {
                node {
                    ...CateringStaffAllocationFragment
                }
            }
        }
    }
`;

const readUnavailableReasonsQuery = gql`
    query readDaysOffReasons {
        readCateringStaffDaysoffReasons {
            ID
            Reason
            ShiftPenaltyCount
        }
    }
`;
