import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import burgerSvg from '../../_asset/burger.svg';
import cx from 'classnames';
import HeaderSearchInput from './HeaderSearchInput';
import HeaderActions from './HeaderActions';

class Header extends Component {
    state = {
        searchOpen: false
    };

    onToggleSearch = () => {
        this.setState({ searchOpen: !this.state.searchOpen });
    };

    render() {
        const { classes, onToggleDrawerOpen, drawerMinimized } = this.props;
        return (
            <Fragment>
                <AppBar
                    position="absolute"
                    className={cx(classes.appBar, { [classes.appBarDrawerMinimized]: drawerMinimized })}
                >
                    <Toolbar className={classes.toolbar}>
                        <Hidden mdUp>
                            <IconButton
                                style={{ width: 40, height: 40 }}
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onToggleDrawerOpen}
                                title={'Show'}
                            >
                                <img src={burgerSvg} alt="open menu" className={classes.burger} />
                            </IconButton>
                        </Hidden>
                        {this.renderDesktopSearch()}
                        <HeaderActions onToggleSearch={this.onToggleSearch} />
                    </Toolbar>
                </AppBar>
                {this.renderMobileSearch()}
            </Fragment>
        );
    }

    renderDesktopSearch() {
        const { classes } = this.props;
        return (
            <Hidden xsDown>
                <HeaderSearchInput className={classes.desktopSearch} />
            </Hidden>
        );
    }

    renderMobileSearch() {
        const { classes } = this.props;
        const { searchOpen } = this.state;
        return (
            <Hidden smUp>
                {searchOpen && <div onClick={this.onToggleSearch} className={classes.searchOverlay} />}
                <Paper
                    square
                    elevation={1}
                    onMouseDown={this.onSearchMouse}
                    onMouseUp={this.onSearchMouse}
                    onTouchStart={this.onSearchMouse}
                    onTouchEnd={this.onSearchMouse}
                    className={cx(classes.searchPaper, { [classes.searchHidden]: !searchOpen })}
                >
                    <HeaderSearchInput mobile open={searchOpen} />
                </Paper>
            </Hidden>
        );
    }
}

const styles = ({ breakpoints, sizes, transitions, palette, spacing, zIndex }) => ({
    appBar: {
        height: sizes.headerHeight,
        boxShadow: 'none',
        backgroundColor: palette.common.white,
        borderBottom: `1px solid ${palette.grey.A100}`,
        [breakpoints.up('md')]: {
            marginLeft: sizes.drawerWidth,
            width: `calc(100% - ${sizes.drawerWidth}px)`
        },
        transition: transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen
        })
    },
    appBarDrawerMinimized: {
        marginLeft: sizes.drawerWidthMinimized,
        [breakpoints.up('md')]: {
            width: `calc(100% - ${sizes.drawerWidthMinimized}px)`
        }
    },
    toolbar: {
        height: sizes.headerHeight,
        color: 'inherit',
        justifyContent: 'space-between',
        [breakpoints.down('sm')]: {
            paddingLeft: spacing.unit,
            paddingRight: spacing.unit
        }
    },
    burger: {
        width: 16
    },
    desktopSearch: {
        flex: '1 1 auto',
        [breakpoints.down('sm')]: {
            marginLeft: spacing.unit * 2
        },
        marginRight: spacing.unit * 2
    },
    searchOverlay: {
        zIndex: zIndex.modal,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    searchPaper: {
        position: 'fixed',
        zIndex: zIndex.modal,
        width: '100%',
        minHeight: sizes.headerHeight,
        padding: spacing.unit * 2,
        right: 0,
        transition: transitions.create(['opacity', 'width'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    searchHidden: {
        opacity: 0,
        width: 0,
        pointerEvents: 'none'
    }
});

export default withStyles(styles)(Header);
