import React, { Fragment } from 'react';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import RatingBar from '../../../component/metrics/RatingBar';
import DataHighlight from '../../../component/metrics/DataHighlight';
import MetricContent from '../../../component/metrics/MetricContent';
import MetricCard from '../../../component/metrics/MetricCard';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import { getMyClient } from '../../../apollo';
import { prettyNumber } from '../../../util/strings';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type, label } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myEnquiries', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myConversions', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ServicePerformance($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;
        const enqMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myEnquiries')) || null;
        const enqResultRaw = (enqMetric && JSON.parse(enqMetric.result)) || [];
        const enqResult = (enqResultRaw && enqResultRaw.Results) || [];
        const enqPhoneType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PHONE');
        const enqPreneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PRENEED');
        const enqPhoneCount = (enqPhoneType && enqPhoneType.Count) || 0;
        const enqPreneedCount = (enqPreneedType && enqPreneedType.Count) || 0;
        const enqTotalPoints = enqPreneedCount * 3 + enqPhoneCount * 5;

        const conMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myConversions')) || null;
        const conResultRaw = (conMetric && JSON.parse(conMetric.result)) || [];
        const conResult = (conResultRaw && conResultRaw.Results) || [];
        const conPhoneType = conResult.length && conResult.find(e => e.EnquiryType === 'PHONE');
        const conPreneedType = conResult.length && conResult.find(e => e.EnquiryType === 'PRENEED');
        const conPhoneCount = (conPhoneType && conPhoneType.Count) || 0;
        const conPreneedCount = (conPreneedType && conPreneedType.Count) || 0;
        const conEarnedPoints = conPreneedCount * 3 + conPhoneCount * 5;

        const conGoals = (conResultRaw && conResultRaw.Goals) || {};
        const conGoal = conGoals.Conversions || null;

        const phoneConvertedRate = Math.min(
            1, // can't go more than perfect!
            conPhoneCount / enqPhoneCount || 0
        );
        const preNeedConvertedRate = Math.min(
            1, // can't go more than perfect!
            conPreneedCount / enqPreneedCount || 0
        );
        const convertedRate = conEarnedPoints / enqTotalPoints;
        const convertedGoal = (conPhoneCount + conPreneedCount) / conGoal;
        const variant = metricColour(convertedRate);

        const explainGoal = (
            <p>
                Number of Conversions: {conPhoneCount + conPreneedCount} (Goal:{' '}
                {Number((conGoal / (type === 'quarter' ? 4 : type === 'month' ? 12 : 1)).toFixed(0))})
            </p>
        );

        return (
            <Fragment>
                <Grid item xs={12} lg={6}>
                    <MetricCard variant={variant}>
                        <RatingBar
                            type="Phone"
                            loading={reloading}
                            rate={`${Number((Number(phoneConvertedRate) * 100).toFixed(2))}%`}
                        />
                        <RatingBar
                            type="Pre Need"
                            loading={reloading}
                            rate={`${Number((Number(preNeedConvertedRate) * 100).toFixed(2))}%`}
                        />
                    </MetricCard>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MetricCard variant={variant}>
                        <DataHighlight
                            loading={reloading}
                            variant={variant}
                            topText="You've earned"
                            data={
                                <span>
                                    <span style={{ fontSize: '5rem' }}>{prettyNumber(conEarnedPoints)}</span>
                                    <br />
                                    points out of
                                    <br />
                                    {prettyNumber(enqTotalPoints)}
                                </span>
                            }
                            bottomText={`for ${label}`}
                        />
                    </MetricCard>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    {(convertedGoal < 0.2 && (
                        <MetricContent heading="Uho spaghettio!">
                            {explainGoal}
                            <p>Looks like you're not even trying!</p>
                            <p>
                                It's not too late to turn these tinned tomatoes into a tasty bolognese sauce! Keep at
                                it!
                            </p>
                        </MetricContent>
                    )) ||
                        (convertedGoal < 0.4 && (
                            <MetricContent heading="Keep on trying!">
                                {explainGoal}
                                <p>Looks like you're a little behind where you wanted to be this period.</p>
                                <p>
                                    So let's suck on a few oranges, put in your mouth guard, get out of the sheds and
                                    get running! You can do it!
                                </p>
                            </MetricContent>
                        )) ||
                        (convertedGoal < 0.6 && (
                            <MetricContent heading="Coasting, not boasting.">
                                {explainGoal}
                                <p>Looks like you're getting a feel for the road, but there's more to do.</p>
                                <p>
                                    This thing won't drive itself, so let's crank up the radio, wind down the windows
                                    and put the foot to the floor!
                                </p>
                            </MetricContent>
                        )) ||
                        (convertedGoal < 0.8 && (
                            <MetricContent heading="Smooth Sailing.">
                                {explainGoal}
                                <p>Looks like you're sailing through your workload.</p>
                                <p>
                                    That treasure island is on the horizon and getting closer, so don't put your oars
                                    back in the boat just yet. Keep paddling!
                                </p>
                            </MetricContent>
                        )) || (
                            <MetricContent heading="Super Effort!">
                                {explainGoal}
                                <p>What's that in the sky? A bird? A plane? No, it's your result!</p>
                                <p>
                                    You've put in a heroic effort for the company, leaping into action to save the day.
                                    Thank you!
                                </p>
                            </MetricContent>
                        )}
                </Grid>
            </Fragment>
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>{type === 'quarter' ? 'Quarterly' : type === 'month' ? 'Monthly' : 'Yearly'} Progress</h2>
            </Grid>
            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {renderResult}
            </Query>
        </Grid>
    );
});
