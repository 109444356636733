import React from 'react';
import gql from 'graphql-tag';
import { Paper } from '@material-ui/core';
import Query from 'react-apollo/Query';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import { getMyClient } from '../../../apollo';
import { dateTimeToString } from '../../../util/date';
import Inline, { inlineAlignment } from '../../workQueue2/extras/Inline';
import Spinner from '../../../component/Spinner';
import TaskCard from '../../workQueue2/TaskCard';
import TaskFragment1 from '../../workQueue/components/TaskFragment';

const styles = ({ breakpoints, spacing }) => ({
    griddler: {
        display: 'grid',
        gridGap: `${spacing.unit * 4}px ${spacing.unit * 2}px`,
        gridTemplateColumns: 'repeat(1, 100%)',
        [breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 50%)'
        },
        [breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, 32.875%)'
        },
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 24.5%)'
        },
        [breakpoints.up('xxl')]: {
            gridTemplateColumns: 'repeat(6, 16.2%)'
        },
        '& > div.task-card.dash-card': {
            width: `calc(100% - ${spacing.unit * 2}px)`,
            height: '100%',
            margin: 0
        }
    }
});

export default withStyles(styles)(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period } = props;
    const { type, label, start, until } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        userId: parseInt(period.userID),
        taskFilter: [
            { field: 'Category', value: 'Goal' },
            {
                field: 'Due:GreaterThanOrEqual',
                value: dateTimeToString(start)
            },
            {
                field: 'Created:LessThanOrEqual',
                value: dateTimeToString(until)
            }
        ]
    };

    const query = gql`
        ${TaskFragment1}
        query MyGoals($taskFilter: [Filters]!, $userId: Int!) {
            readTasks(userId: $userId, isArchived: false, filters: $taskFilter) {
                edges {
                    node {
                        ...TaskFragment1
                    }
                }
            }
        }
    `;

    const { classes } = props;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);
        const { readTasks } = (!error && data) || false;
        const tasks = { Tasks: (readTasks && readTasks.edges.map(e => e.node)) || { Tasks: [] } };
        return (
            <Grid container spacing={24}>
                <Grid item pc>
                    <Inline className="dashboard-header" alignment={inlineAlignment.rightAlignSiblings} center>
                        <div>
                            <h2>Your Individual performance objectives</h2>
                            <p>You've been assigned the following goals {`for ${label}`}...</p>
                        </div>
                        <h3 className="goals metrics">
                            {(reloading && <Spinner />) ||
                                (tasks.Tasks.length &&
                                    `${tasks.Tasks.filter(e => !!e.Completed).length}/${
                                        tasks.Tasks.length
                                    } goals completed`) ||
                                'No goals'}
                        </h3>
                    </Inline>
                </Grid>
                <Grid item pc>
                    <div className="workqueue-page">
                        {(reloading && <Spinner />) ||
                            (tasks.Tasks.length && (
                                <div className={classes.griddler}>
                                    {tasks.Tasks.map(task => (
                                        <TaskCard variant="goal" key={'list-item-' + task.ID} task={task} />
                                    ))}
                                </div>
                            )) || (
                                <Paper elevation={0}>
                                    <p style={{ textAlign: 'center', padding: 32 }}>
                                        You have no goals assigned to you for this period.
                                    </p>
                                </Paper>
                            )}
                    </div>
                </Grid>
            </Grid>
        );
    };
    return (
        <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
            {result => renderResult(result)}
        </Query>
    );
});
