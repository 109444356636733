import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment RslMasonic on Funeral {
        RslMasonic {
            ${BookingsFragmentInclude}
        }
    }
`;
