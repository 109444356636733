import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from './Grid';
import { InlineField, InlineFieldRightAlignChildren } from './Inline';
import MapMarkerIcon from '../icon/MapMarkerIcon';
import PhoneSquareIcon from '../icon/PhoneSquareIcon';
import MobileIcon from '../icon/MobileIcon';
import EnvelopeSquareIcon from '../icon/EnvelopeSquareIcon';
import DeleteIcon from '../icon/DeleteIcon';
import EditIcon from '../icon/EditIcon';
import FaxIcon from '../icon/FaxIcon';
import { IconButton } from '@material-ui/core';
import Label from './Label';
import cx from 'classnames';
import { joinDefined } from '../../util/strings';

//styles needs to be defined first
const styles = () => ({
    contactCardContainer: {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
        gridTemplateRows: 'auto'
    },

    contactCard: {
        border: '1px solid #CDCDCD',
        borderRadius: '10px',
        padding: '10px 15px',
        background: 'white'
    },

    iconButton: {
        height: '32px',
        width: '32px'
    },

    icon: {
        height: '18px',
        width: '18px',
        verticalAlign: 'middle'
    },

    infoIcon: {
        color: '#CDCDCD'
    },

    detailsFont: {
        fontSize: '13px',
        fontWeight: '400',
        '& > span': {
            verticalAlign: 'text-top'
        }
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    detailsGrid: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
        gridTemplateRows: 'repeat(3, 1fr)'
    }
});

/**
 * container used to display contact cards
 */
export const ContactCardContainer = withStyles(styles)(({ children, ...props }) => {
    const { classes } = props;
    return (
        <Grid container className={classes.contactCardContainer}>
            {children}
        </Grid>
    );
});

/**
 * contact card
 */
export const ContactCard = withStyles(styles)(
    ({ key, contact = sampleContact, canDelete = true, canEdit = true, onDelete, onEdit, ...props }) => {
        const { classes } = props;
        const formattedAddress = joinDefined(
            [
                contact.AddressLine1,
                contact.AddressLine2,
                contact.Suburb,
                contact.State,
                contact.Country,
                contact.PostCode
            ],
            ', '
        );

        return (
            <Grid item key={key}>
                <Grid className={classes.contactCard}>
                    <InlineFieldRightAlignChildren>
                        <Label>{contact.Name}</Label>

                        {canEdit && onEdit && (
                            <IconButton
                                className={classes.iconButton}
                                title={'Edit'}
                                onClick={() => onEdit(key, contact)}
                            >
                                <EditIcon className={classes.icon} />
                            </IconButton>
                        )}
                        {canDelete && onDelete && (
                            <IconButton
                                className={classes.iconButton}
                                title={'Delete'}
                                onClick={() => onDelete(key, contact)}
                            >
                                <DeleteIcon className={classes.icon} />
                            </IconButton>
                        )}
                    </InlineFieldRightAlignChildren>

                    <InlineField>
                        <span>
                            <MapMarkerIcon className={cx(classes.icon, classes.infoIcon)} />
                        </span>
                        <span className={classes.detailsFont}>
                            <span>{formattedAddress}</span>
                        </span>
                    </InlineField>

                    <div className={classes.detailsGrid}>
                        {contact.Phone && (
                            <InlineField lineHeight={2}>
                                <span>
                                    <PhoneSquareIcon className={cx(classes.icon, classes.infoIcon)} />
                                </span>
                                <span className={classes.detailsFont}>
                                    <span>{contact.Phone}</span>
                                </span>
                            </InlineField>
                        )}

                        {contact.Mobile && (
                            <InlineField lineHeight={2}>
                                <span>
                                    <MobileIcon className={cx(classes.icon, classes.infoIcon)} />
                                </span>
                                <span className={classes.detailsFont}>
                                    <span>{contact.Mobile}</span>
                                </span>
                            </InlineField>
                        )}

                        {contact.Email && (
                            <InlineField lineHeight={2}>
                                <span>
                                    <EnvelopeSquareIcon className={cx(classes.icon, classes.infoIcon)} />
                                </span>
                                <span className={classes.detailsFont}>
                                    <span>{contact.Email}</span>
                                </span>
                            </InlineField>
                        )}

                        {contact.Fax && (
                            <InlineField lineHeight={2}>
                                <span>
                                    <FaxIcon className={cx(classes.icon, classes.infoIcon)} />
                                </span>
                                <span className={classes.detailsFont}>
                                    <span>{contact.Fax}</span>
                                </span>
                            </InlineField>
                        )}
                    </div>
                </Grid>
            </Grid>
        );
    }
);

const sampleContact = {
    Name: '',
    AddressLine1: '',
    AddressLine2: '',
    Suburb: '',
    PostCode: '',
    State: '',
    Country: '',
    Phone: '',
    Mobile: '',
    Email: '',
    Fax: ''
};
