import gql from 'graphql-tag';

export default gql`
    fragment ChildrenDetails on Funeral {
        ChildrenDetails {
            ID
            LastEdited
            Created
            GivenName
            FamilyName
            FamilyNameAtBirth
            OtherGivenNames
            DateOfBirth
            Age
            AgeExtra
            Gender
            LifeStatus
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementChildrenDetails on Prearrangement {
        ChildrenDetails {
            ID
            LastEdited
            Created
            GivenName
            FamilyName
            OtherGivenNames
            DateOfBirth
            Age
            AgeExtra
            Gender
            LifeStatus
        }
    }
`;
