import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({})(({ heading, children }) => {
    return (
        <div className="metric-content">
            <h3 className="metrics">{heading}</h3>
            {children}
        </div>
    );
});
