import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 44.798 44.797" {...props}>
        <path
            fill="currentColor"
            d="M43.148,4.922,39.866,1.641a5.6,5.6,0,0,0-7.918,0L26.009,7.579,24.03,5.6a2.8,2.8,0,0,0-3.959,0L8.686,16.984a.7.7,0,0,0,0,.99l.99.99a.7.7,0,0,0,.99,0L22.049,7.58l1.979,1.979L8.212,25.376A28.614,28.614,0,0,0,.006,42.445l0,.02A2.1,2.1,0,0,0,2.087,44.8a28.388,28.388,0,0,0,17.327-8.22L43.149,12.842a5.6,5.6,0,0,0,0-7.919ZM17.434,34.6A25.907,25.907,0,0,1,2.9,41.893a25.912,25.912,0,0,1,7.292-14.538L26.009,11.539h0l7.241,7.241ZM41.169,10.862,35.23,16.8,27.988,9.559,33.927,3.62a2.8,2.8,0,0,1,3.959,0L41.168,6.9A2.8,2.8,0,0,1,41.169,10.862Z"
        />
    </SvgIcon>
);
