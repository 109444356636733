import gql from 'graphql-tag';

export const ProductPriceOverrides = gql`
    fragment ProductPriceOverrides on Product {
        PriceOverrides {
            edges {
                node {
                    ID # Brand or Branch
                    ... on OfficesBranch {
                        BrandID
                        _join {
                            PriceOverride {
                                ID
                                BasePrice
                            }
                        }
                    }
                    ... on OfficesUsername {
                        Branches {
                            nodes {
                                ID
                            }
                        }
                        _join {
                            PriceOverride {
                                ID
                                BasePrice
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const VariationPriceOverrides = gql`
    fragment VariationPriceOverrides on Variation {
        PriceOverrides {
            edges {
                node {
                    ID # Brand or Branch
                    ... on OfficesBranch {
                        BrandID
                        _join {
                            PriceOverride {
                                ID
                                BasePrice
                            }
                        }
                    }
                    ... on OfficesUsername {
                        Branches {
                            nodes {
                                ID
                            }
                        }
                        _join {
                            PriceOverride {
                                ID
                                BasePrice
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default gql`
    ${ProductPriceOverrides}
    ${VariationPriceOverrides}
    fragment Product on Product {
        ID
        ImageURL
        ImagePortraitURL
        ImageLandscapeURL
        Title
        BasePrice
        Quantity
        GST
        LegacyKey
        AllowPurchase
        CoffinTrim
        InternalItemID
        Parent {
            ... on ProductCategory {
                ID
                URLSegment
            }
            ... on CoreProductCategory {
                ID
                URLSegment
            }
        }
        ProductCategories {
            ... on ProductCategory {
                ID
                URLSegment
            }
            ... on CoreProductCategory {
                ID
                URLSegment
            }
        }
        RelatedProducts {
            edges {
                node {
                    ID
                }
            }
        }
        Variations(limit: 999) {
            edges {
                node {
                    ID
                    Price
                    GST
                    LegacyKey
                    InternalItemID
                    AttributeValues {
                        edges {
                            node {
                                ID
                                Value
                                Type {
                                    ID
                                    Label
                                }
                            }
                        }
                    }
                    ...VariationPriceOverrides
                }
            }
        }
        ...ProductPriceOverrides
    }
`;
