import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 34.495 45.999" {...props}>
        <path
            fill="currentColor"
            d="M33.334,10.125,24.458,1.21A4.1,4.1,0,0,0,21.543,0H13.03a4.1,4.1,0,0,0-2.917,1.213L1.24,10.125A4.163,4.163,0,0,0,.162,14.046l7.17,28.821a4.117,4.117,0,0,0,4,3.132H23.242a4.117,4.117,0,0,0,4-3.132l7.174-28.818a4.168,4.168,0,0,0-1.078-3.923ZM24.449,42.173a1.243,1.243,0,0,1-1.207.951H11.331a1.242,1.242,0,0,1-1.207-.951L2.949,13.349a1.272,1.272,0,0,1,.329-1.2l8.87-8.91a1.243,1.243,0,0,1,.881-.367h8.513a1.238,1.238,0,0,1,.879.365l8.873,8.912a1.278,1.278,0,0,1,.329,1.2L24.449,42.173Z"
            transform="translate(-0.04 0)"
        />
    </SvgIcon>
);
