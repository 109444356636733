import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment GravesideMusicians on Funeral {
        GravesideMusicians {
            ${BookingsFragmentInclude}
        }
    }
`;
