import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabbedModal from '../form/TabbedModal';
import SelectionTab, { limitOtherProducts } from './SelectionTab';
import SummaryTab from './SummaryTab';
//import { TABS } from './CoffinConstants';
import {
    fallbackImages,
    getItemVariation,
    getProducts,
    getProductsByIds,
    populateConfig,
    ProductConfig
} from '../../util/products';
import { IndexOf } from '../../util/arrays';
import { compose, Query, withApollo } from 'react-apollo';
import { TabTypes } from './ProductModalConstants';
import { getUtilitiesClient } from '../../apollo';
import { numberAsText } from '../../util/strings';
import { getFuneralOfficeID } from '../../util/brands';

// We keep the config globally so that we can continue to populate it with
// product data as we load more products into the list, and so the config
// will persist if we close the modal.

/*

tabs = export const TABS = ['Coffins/Casket', 'Handles', 'Fittings', 'Name Plate', 'Summary'];
tabs =  [
    {
        name:'Products',
        metadata: ProductConfig.Coffin.metadata
        stepLabel="One",
        StepTitle="Select a coffin or casket",
        stepSubtitle="Please select one coffin or casket, from the list below:",
        type:TabTypes.Selection
    },
    {
        name:'Summary',
        type:TabTypes.Summary
    },
]

open, form, formCartProperty
formCartProperty = path to storage of items on form
tabs =
*/

class ProductSelectionModal extends Component {
    state = {
        tabIndex: 0,
        searchTerm: '',
        cart: [],
        opened: false
    };

    static getDerivedStateFromProps({ open, form, tabs, formCartProperty }, state) {
        const newState = { opened: open };

        if (!open || tabs == null || tabs.length === 0) return { tabIndex: 0, opened: false };

        const bookingItems = form.getField(formCartProperty);

        const justOpened = !state.opened && open;

        if (justOpened) {
            newState.tabIndex = 0;
            newState.cart = [];

            newState.categoryUrlsegment = tabs[0].metadata.queryKey;

            if (bookingItems && bookingItems.length) {
                // populate the cart state from the booking items

                newState.cart = bookingItems.map(({ ID, Quantity, Comment, ProductID, VariationID, Returns }, i) => {
                    const cartItem = {
                        id: ID,
                        key: i,
                        quantity: Quantity,
                        comment: Comment,
                        productId: ProductID,
                        variationId: VariationID,
                        returns: Returns
                    };
                    limitOtherProducts(cartItem, ProductConfig);
                    return cartItem;
                });

                const ids = newState.cart.reduce((acc, { productId }) => {
                    if (!ProductConfig.productMap[productId]) acc.push(Number(productId));
                    return acc;
                }, []);
                // Pre-load all the products which are in the cart
                if (ids.length) {
                    getUtilitiesClient()
                        .query({ query: getProductsByIds, variables: { ids } })
                        .then(res => {
                            populateConfig(ProductConfig, res.data.readProducts);
                        });
                }
            }
        }
        return newState;
    }

    render() {
        const { searchTerm, categoryUrlsegment } = this.state;
        return (
            <Fragment>
                <Query
                    query={getProducts}
                    variables={{ searchTerm, categoryUrlsegment }}
                    context={{ debounceKey: 3 }}
                    client={getUtilitiesClient()}
                    skip={!this.state.opened || !categoryUrlsegment}
                >
                    {this.renderTabs}
                </Query>
            </Fragment>
        );
    }

    renderTabs = ({ data: { readProducts } = {}, loading, fetchMore }) => {
        const { classes, form, onClose, tabs, ...other } = this.props;
        const { tabIndex, cart } = this.state;

        const indexOfSummary = IndexOf(tabs, x => x.type === TabTypes.Summary);
        const hasSummary = indexOfSummary >= 0;
        const recordID = form.getField('ID');
        const officeID = getFuneralOfficeID(recordID);
        let tab;
        if (tabs[tabIndex].type === TabTypes.Selection) {
            if (!loading) populateConfig(ProductConfig, readProducts);

            const productIds = readProducts ? readProducts.edges.map(({ node }) => node.ID) : [];
            const onLoadMore = () => {
                if (!(readProducts && readProducts.pageInfo.hasNextPage)) {
                    return;
                }
                fetchMore({
                    variables: {
                        offset: productIds.length
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResult;
                        return {
                            readProducts: {
                                ...fetchMoreResult.readProducts,
                                edges: [...previousResult.readProducts.edges, ...fetchMoreResult.readProducts.edges]
                            }
                        };
                    }
                });
            };

            tab = (
                <SelectionTab
                    cart={cart}
                    config={ProductConfig}
                    officeID={officeID}
                    metadata={tabs[tabIndex].metadata}
                    stepLabel={tabs[tabIndex].stepLabel || 'Step ' + numberAsText(1 + tabIndex) + ':'}
                    stepTitle={tabs[tabIndex].stepTitle || 'Choose products'}
                    stepSubtitle={tabs[tabIndex].stepSubtitle || null}
                    onCancel={() => onClose()}
                    onConfirm={hasSummary ? () => this.onChangeTab(indexOfSummary) : null}
                    onBack={tabIndex - 1 >= 0 ? () => this.onChangeTab(tabIndex - 1) : null}
                    onNext={tabIndex + 1 < tabs.length ? () => this.onChangeTab(tabIndex + 1) : null}
                    loading={loading}
                    loadMore={onLoadMore}
                />
            );
        } else if (tabs[tabIndex].type === TabTypes.Summary) {
            tab = (
                <SummaryTab
                    cart={cart}
                    config={ProductConfig}
                    officeID={officeID}
                    stepLabel={tabs[tabIndex].stepLabel || 'Step ' + numberAsText(1 + tabIndex) + ':'}
                    stepTitle={tabs[tabIndex].stepTitle || 'Confirm the order'}
                    stepSubtitle={tabs[tabIndex].stepSubtitle || null}
                    onCancel={() => onClose()}
                    onConfirm={this.onConfirm}
                    onBack={tabIndex - 1 >= 0 ? () => this.onChangeTab(tabIndex - 1) : null}
                />
            );
        } else {
            tab = <div>ruh roh</div>;
        }

        return (
            <TabbedModal
                tabs={tabs.map(x => x.name)}
                tabIndex={tabIndex}
                className={classes.root}
                contentClassName={classes.content}
                onChange={i => this.onChangeTab(i)}
                fullScreen
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
                {...other}
            >
                {tab}
            </TabbedModal>
        );
    };

    onChangeSearchTerm = searchTerm => {
        this.setState({ searchTerm });
    };

    onChangeTab = tabIndex => {
        const { tabs } = this.props;
        this.setState({
            categoryUrlsegment: tabs[tabIndex].metadata ? tabs[tabIndex].metadata.queryKey : null,
            tabIndex
        });
    };

    onConfirm = () => {
        const { onClose, onConfirm } = this.props;
        const { cart } = this.state;

        const bookingItems = [];
        const selectedProducts = [];

        // Build booking items and products from cart
        for (let x = 0; x < cart.length; x++) {
            const item = cart[x];
            const variation = getItemVariation(item, ProductConfig);
            const product = ProductConfig.productMap[item.productId];

            bookingItems.push({
                ID: item.id,
                Quantity: item.quantity,
                UnitPrice: variation.price,
                Total: variation.price * item.quantity,
                Comment: item.comment,
                ProductID: item.productId,
                VariationID: item.variationId,
                URLSegment: product.urlSegment,
                Returns: item.returns
            });

            selectedProducts.push({
                label: product.title,
                image:
                    product.image || fallbackImages[product.urlSegment][x % fallbackImages[product.urlSegment].length],
                product
            });
        }

        onConfirm(bookingItems, selectedProducts);

        onClose();
    };
}

const styles = ({ breakpoints, spacing }) => ({
    root: {
        padding: 40,
        maxHeight: '94vh',
        maxWidth: 1600,
        height: 1200,
        [breakpoints.down('xs')]: {
            padding: spacing.unit * 2
        }
    },
    content: {
        display: 'flex',
        flex: '1 1 auto',
        height: 0
    }
});

export default compose(withApollo, withStyles(styles))(ProductSelectionModal);
