import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withRouter } from 'react-router';
import TextField from '../../../component/form/TextField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import Select from '../../../component/form/Select';
import Label from '../../../component/form/Label';
import RadioGroup from '../../../component/form/RadioGroup';
import { TitleOptions } from '../../quotes/EnquiryConstants';
import SignatureField from '../../../component/form/SignatureField';
import AutoComplete from '../../../component/form/AutoComplete';
import { APPLICANT_RELATIONSHIP_OPTIONS } from '../../funeral/certification/CertificationConstants';
import moment from 'moment';
import { GENDER_OPTIONS } from '../../funeral/deceased/DeceasedConstants';
import { PLACE_OF_SERVICE_TYPE_OPTIONS } from '../../funeral/details/DetailsConstants';
import InputAdornment from '@material-ui/core/InputAdornment';
import { OutlineButton } from '../../../component/form/PrimaryButton';

class MVCDocument extends Component {
    render() {
        const { page } = this.props.tabProps;
        return (
            <Fragment>
                {page === 1 && this.renderStepOne()}
                {page === 2 && this.renderStepTwo()}
            </Fragment>
        );
    }

    renderStepOne() {
        return (
            <Fragment>
                {this.renderCompanyDetails()}
                {this.renderDeceasedDetails()}
                {this.renderBookingDetails()}
            </Fragment>
        );
    }

    renderCompanyDetails() {
        const { form } = this.props;
        return (
            <section id="companyDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Funeral Director Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="FuneralCompany"
                            form={form}
                            label="Funeral Director Company"
                            placeholder="Funeral Director Company"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="FuneralPhone" form={form} label="Phone Number" placeholder="Phone Number" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="Email" form={form} label="Email" placeholder="Email" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ArrangerName" form={form} label="Arranger Name" placeholder="Arranger Name" />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderDeceasedDetails() {
        const { form } = this.props;
        return (
            <section id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Deceased Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="DeceasedFirstName" form={form} label="First name" placeholder="First Name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="DeceasedMiddleName"
                            form={form}
                            label="Middle name(s)"
                            placeholder="Middle name(s)"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="DeceasedLastName" form={form} label="Family Name" placeholder="Family Name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Gender" name="DeceasedGender" form={form} options={GENDER_OPTIONS} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="DeceasedDateOfBirth"
                            form={form}
                            type="date"
                            label="Date of Birth"
                            placeholder="Date of Birth"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="DeceasedDateOfDeath"
                            form={form}
                            type="date"
                            label="Date of Death"
                            placeholder="Date of Death"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Age" placeholder="Deceased Age" name="DeceasedAge" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for deceased's address"
                            componentFields={{
                                line1: 'DeceasedAddress.AddressLine1',
                                line2: 'DeceasedAddress.AddressLine2',
                                city: 'DeceasedAddress.Suburb',
                                state: 'DeceasedAddress.State',
                                country: 'DeceasedAddress.Country',
                                code: 'DeceasedAddress.Postcode'
                            }}
                            form={form}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderBookingDetails() {
        const { form } = this.props;
        return (
            <section id="deceasedDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Booking Details</h3>
                        <h4>Delivery Details</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="DeliveryDate"
                            form={form}
                            type="date"
                            label="Date of Delivery"
                            placeholder="Date of Delivery"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            name="DeliveryTime"
                            form={form}
                            type="time"
                            label="Time of Delivery"
                            placeholder="Time of Delivery"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Service Details</h4>
                        <Select
                            allowNone={false}
                            label="Service type"
                            options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                            name="ServiceType"
                            form={form}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h4>Pre-arrangement Details</h4>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Label text="Cemetery Name (if known)" />
                        <TextField label="Cemetery Name" name="CemeteryName" form={form} placeholder="Cemetery Name" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Label text="Site Reference(if known)" />
                        <TextField
                            label="Site Reference"
                            name="SiteReference"
                            form={form}
                            placeholder="Site Reference"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RadioGroup
                            label="Are there any special instructions?"
                            name="HasSpecialInstructions"
                            form={form}
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                        />
                        <TextField
                            name="SpecialInstructions"
                            form={form}
                            label="Special Instructions"
                            placeholder="Special Instructions"
                            disabled={!form.getField('HasSpecialInstructions')}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderStepTwo() {
        return (
            <Fragment>
                {this.renderApplicantDetails()}
                {this.renderSignature()}
            </Fragment>
        );
    }

    renderApplicantDetails() {
        const { form } = this.props;
        return (
            <section id="mvcApplicantDetails">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h3>Applicant Details</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutoComplete
                            name="ApplicantRelationship"
                            label="Relationship to the deceased"
                            form={form}
                            limit={100}
                            options={APPLICANT_RELATIONSHIP_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select label="Title" name="ApplicantTitle" form={form} options={TitleOptions} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ApplicantFirstName" form={form} label="First name" placeholder="First name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ApplicantLastName" form={form} label="Surname" placeholder="Surname" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ApplicantPhone" form={form} label="Phone" placeholder="Phone" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ApplicantMobile" form={form} label="Mobile" placeholder="Mobile" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name="ApplicantEmail" form={form} label="Email" placeholder="Email" />
                    </Grid>
                    <Grid item xs={12}>
                        <Label text="Address" />
                        <AddressAutocomplete
                            placeholder="Search for applicant's address"
                            componentFields={{
                                line1: 'ApplicantAddress.AddressLine1',
                                line2: 'ApplicantAddress.AddressLine2',
                                city: 'ApplicantAddress.Suburb',
                                state: 'ApplicantAddress.State',
                                country: 'ApplicantAddress.Country',
                                code: 'ApplicantAddress.Postcode'
                            }}
                            form={form}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderSignature() {
        const { form, classes } = this.props;
        return (
            <section id="mvcSignature">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <p>
                            Conditions: Mountain View Crematoria cannot guarantee the cremation will take place same day
                            as delivery although every attempt will be made to do so. Request for same day service for
                            deliveries after 1:00pm, an additional fee will apply. All cremations will be carried out in
                            accordance with NSW State Health Authority guidelines.
                        </p>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <SignatureField form={form} name="ApplicantSignature" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            type="date"
                            label="Signature Date"
                            form={form}
                            name="SignatureDate"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <OutlineButton
                                            className={classes.addNewButton}
                                            onClick={() =>
                                                form.setField({ SignatureDate: moment().format('YYYY-MM-DD') })
                                            }
                                            title="Add Today's Date"
                                        >
                                            Add Today's Date
                                        </OutlineButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }
}

const styles = () => ({
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export default compose(withRouter, withStyles(styles))(MVCDocument);
