import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/**
 * defaults how the alignment of the inline field works
 */
export const inlineAlignment = {
    default: 0,
    rightAlignSiblings: 1,
    right: 2
};

//inline grid that contains elements in a left-to-right line
const Inline = withStyles({})(
    ({ children, className, alignment = inlineAlignment.default, center = false, nowrap }) => {
        let defaultClassName = null;

        if (alignment === inlineAlignment.rightAlignSiblings) {
            defaultClassName = 'inline-right-align-siblings';
        } else if (alignment === inlineAlignment.right) {
            defaultClassName = 'inline inline--right';
        } else {
            defaultClassName = 'inline';
        }

        return (
            <div
                style={nowrap && { flexWrap: 'nowrap' }}
                className={`${defaultClassName} ${className || ''} ${center ? 'inline--vertical-center' : ''}`}
            >
                {children}
            </div>
        );
    }
);

export default Inline;
