import gql from 'graphql-tag';
import { getTransfersClient } from '../../apollo';
import TransfersFragment from './TransfersFragment';

const transfersQuery = gql`
    ${TransfersFragment}
    query {
        readFunerals(transfer: true) {
            edges {
                node {
                    ...Transfers
                }
            }
        }
    }
`;

const transfersMutation = gql`
    ${TransfersFragment}
    mutation updateManyFunerals($input: [UpdateFuneralInput]!) {
        updateManyFunerals(input: $input) {
            edges {
                node {
                    ...Transfers
                }
            }
        }
    }
`;
/*
const transfersCreation = gql`
    ${TransfersFragment}
    mutation createTransfer($input: CreateFuneralInput!) {
        createFuneral(input: $input) {
            ...Transfers
        }
    }
`;
*/
//gets the family list from the database and joins it into an options list
const getTransfersFunc = async () => {
    const asyncQuery = await getTransfersClient().query({ query: transfersQuery, fetchPolicy: 'network-only' });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readFunerals : { edges: [] };
};
/*
//creates new transfer/funeral in the database
const createTransferFunc = async input => {
    if (!!input.TransferFrom && input.TransferFromType !== 'Residential') {
        input.TransferFromID = input.TransferFrom.ID;
    } else {
        input.TransferFromResidentialAddressID = input.TransferFromResidentialAddress.ID;
    }
    delete input.TransferFrom;
    delete input.TransferFromResidentialAddress;
    const asyncQuery = await getClient().mutate({
        mutation: transfersCreation,
        variables: { input }
    });

    return asyncQuery.data;
};
*/
//saves the transfers to the database
const saveTransfersFunc = async (transfers, originalTransfers) => {
    const input = [];
    const ids = [];

    for (let x = 0; x < transfers.edges.length; x++) {
        const transfer = trimTransfer(transfers.edges[x], originalTransfers.edges[x]);
        if (!transfer) continue;

        ids.push(transfer.ID);
        //delete transfer.ID;
        input.push(transfer);
    }

    //nothing to do
    if (ids.length === 0) return Promise.resolve(transfers);

    const asyncQuery = await getTransfersClient().mutate({
        mutation: transfersMutation,
        variables: { input }
    });

    return asyncQuery.data;
};

const trimTransfer = (transfer, original) =>
    trim(transfer.node, original.node, [
        'TransferComment',
        'TransferCalled',
        'TransferCertificates',
        'TransferReady',
        'TransferActioned'
    ]);

const trim = (newObject, original, properties) => {
    let diffObj = {};

    for (let x = 0; x < properties.length; x++) {
        if (newObject[properties[x]] !== original[properties[x]]) diffObj[properties[x]] = newObject[properties[x]];
    }

    if (Object.keys(diffObj).length <= 0) return null;

    diffObj.ID = newObject.ID;
    return diffObj;
};

export { getTransfersFunc, saveTransfersFunc /*, createTransferFunc */ };
