import gql from 'graphql-tag';

export default gql`
    fragment BillTo on Funeral {
        BillTo {
            ID
            Name #only old records
            FirstName #only new records
            MiddleName #only new records
            Surname #only new records
            RelationToDeceased
            Contact
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
            Phone
            Mobile
            BillingId
            Email
            BillingSource
        }
    }
`;
