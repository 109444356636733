import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment ReflectionMusicians on Funeral {
        ReflectionMusicians {
            ${BookingsFragmentInclude}
        }
    }
`;
