import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

/**
 * Extension on the Radio from material ui adding:
 * - default theme/style
 * - wrapped with label
 * - optional wiring to provided form state
 */
const FormRadio = ({ classes, id, name, form, checked, label, value, onChange, ...props }) => {
    if (!value) value = null;
    const radio = (
        <Radio
            id={id || (name ? `radio-${name}-${value}` : undefined)}
            name={name}
            checked={(form ? form.getField(name) === value : false) || checked}
            onChange={onChange || (form && (e => form.setField({ [name]: value || null })))}
            color="primary"
            className={classes.radio}
            value={value}
            {...props}
        />
    );
    if (label) {
        return (
            <FormControlLabel
                className={classes.labelRoot}
                classes={{ label: classes.label }}
                control={radio}
                label={label}
            />
        );
    }
    return radio;
};

const styles = () => ({
    radio: {
        width: 36,
        height: 36,
        borderRadius: 5
    },
    labelRoot: {
        marginLeft: -8,
        marginRight: 8
    },
    label: {
        fontSize: '0.7rem'
    }
});

export default withStyles(styles)(FormRadio);
