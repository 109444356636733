import gql from 'graphql-tag';
import { OrdersFragmentInclude } from './Bookings';

export default gql`
    fragment MemorialStationary on Funeral {
        MemorialStationaryBy
        MemorialStationary {
            ${OrdersFragmentInclude}
        }
    }
`;
