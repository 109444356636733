import React from 'react';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import ScorePill from '../../../component/metrics/ScorePill';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import { getMyClient } from '../../../apollo';
import { isNullOrUndefined } from '../../../util/objects';
import Spinner from '../../../component/Spinner';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period } = props;
    const { type } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myNetPromoterScores', variables: JSON.stringify({ ...period, variant: 'individual' }) },
            { name: 'myNetPromoterScores', variables: JSON.stringify({ ...period, variant: 'team' }) },
            { name: 'myNetPromoterScores', variables: JSON.stringify({ ...period, variant: 'company' }) }
        ]
    };

    const query = gql`
        query ScoreNPS($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const convertNPS = val => {
        if (val === null) return null;
        if (val < -20) return 0;
        if (val < 0) return 0.2;
        if (val < 20) return 0.4;
        if (val < 45) return 0.6;
        if (val < 70) return 0.8;
        return 1;
    };

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);
        const { loadMetrics } = (!error && data) || [
            { Score: null, Variant: 'individual' },
            { Score: null, Variant: 'team' },
            { Score: null, Variant: 'company' }
        ];

        return (
            (loadMetrics &&
                loadMetrics.map(metric => {
                    const { Score, Variant } = (metric.result && JSON.parse(metric.result)) || null;
                    const pillColour = metricColour(convertNPS(Score));
                    return (
                        <Grid key={Variant} item xs={12} md={4}>
                            <ScorePill
                                title={Variant}
                                data={reloading ? <Spinner /> : isNullOrUndefined(Score) ? 'N/A' : Math.round(Score)}
                                variant={pillColour}
                            />
                        </Grid>
                    );
                })) ||
            null
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>Net Promoter Scores</h2>
            </Grid>

            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {result => renderResult(result)}
            </Query>
        </Grid>
    );
});
