import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { onChangeWrapper } from '../../util/functions';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { getValidationDecorations } from '../../util/validation';
import { ListSubheader } from '@material-ui/core';

// This wraps the Select component to render either a
// native select or stylized select depending on breakpoints
class CustomSelect extends Component {
    render() {
        let {
            readOnly,
            className,
            fullWidth,
            id,
            name,
            label,
            value,
            options,
            placeholder,
            controlProps,
            labelProps,
            selectProps,
            optionProps,
            menuItemProps,
            form,
            valueField,
            disabled,
            classes,
            required,
            defaultValue,
            renderValue,
            title,
            allowNone = true
        } = this.props;

        id = id || (name ? `choose-${name}` : undefined);
        const inputProps = { id, name };
        if (selectProps && selectProps.inputProps) Object.assign(inputProps, selectProps.inputProps);

        if (typeof value === 'undefined' || value === null) {
            value = form ? form.getField(name) : null;
            // if value is still null (might be a "Create" use case), Check if there is a default value
            if (!value && value !== false && (defaultValue || defaultValue === false)) {
                value = defaultValue;
                // naughty, can't set state during render!
                // form.setField({ [name]: value });
            }
        }

        if (readOnly || (selectProps && selectProps.readOnly)) {
            const selected = options.find(v => {
                return v.value === value;
            });
            return (
                <div className={classes.readOnlyBucket}>
                    <div className={cx(classes.readOnlyLabel, classes.label)}>{label}</div>
                    <div className={classes.readOnly}>{selected ? selected.label : ''}</div>
                </div>
            );
        }

        const decorations = getValidationDecorations(this.props, classes.selectInner);

        const commonSelectProps = {
            ...selectProps,
            value: value !== null && value !== undefined ? value : '',
            onChange: e => onChangeWrapper(e, this),
            inputProps,
            className: cx(classes.select, disabled && classes.disabledClass),
            classes: { select: decorations.rootClass },
            placeholder,
            displayEmpty: true
        };
        const moreSelectProps = { ...commonSelectProps };
        if (renderValue) {
            moreSelectProps.renderValue = renderValue;
        }

        return (
            <FormControl
                {...controlProps}
                required={!!required}
                disabled={!!disabled}
                fullWidth={fullWidth}
                title={title}
                className={cx(className, controlProps && controlProps.className)}
            >
                {label && (
                    <InputLabel
                        {...labelProps}
                        shrink
                        htmlFor={id}
                        className={classes.label}
                        classes={{ shrink: decorations.labelShrinkClass }}
                    >
                        {label}
                    </InputLabel>
                )}
                <Hidden mdUp>
                    <Select {...commonSelectProps} native>
                        {allowNone ? <option value="">{placeholder || 'Please select...'}</option> : ''}
                        {options.map((opt, idx) => {
                            const menuItem = (o, i) => (
                                <option
                                    {...optionProps}
                                    key={i}
                                    value={valueField ? o[valueField] : o.value}
                                    disabled={o.disabled}
                                >
                                    {o.label}
                                </option>
                            );
                            return opt.options ? (
                                <optgroup key={idx} label={opt.label}>
                                    {opt.value && menuItem(opt, idx)}
                                    {opt.options.map((o, i) => menuItem(o, i))}
                                </optgroup>
                            ) : (
                                menuItem(opt, idx)
                            );
                        })}
                    </Select>
                </Hidden>
                <Hidden smDown>
                    <Select {...moreSelectProps}>
                        {allowNone ? (
                            <MenuItem {...menuItemProps} value="">
                                {placeholder || 'Please select...'}
                            </MenuItem>
                        ) : (
                            ''
                        )}
                        {options.map((opt, idx) => {
                            const menuItem = (o, i, extraClass = null) => (
                                <MenuItem
                                    {...menuItemProps}
                                    className={cx(menuItemProps && menuItemProps.className, extraClass)}
                                    key={i}
                                    value={valueField ? o[valueField] : o.value}
                                    disabled={o.disabled}
                                >
                                    {renderValue ? renderValue(o.value) || placeholder : o.label}
                                </MenuItem>
                            );
                            return opt.options
                                ? [
                                      opt.value ? (
                                          menuItem(opt, idx)
                                      ) : (
                                          <ListSubheader className={classes.optgroupHead}>{opt.label}</ListSubheader>
                                      ),
                                      opt.options.map((o, i) => menuItem(o, i, classes.optgroupItem))
                                      // <ListSubheader className={classes.optgroupFoot} />
                                  ]
                                : menuItem(opt, idx);
                        })}
                    </Select>
                </Hidden>
                {decorations.errorMsg && (
                    <FormHelperText error={decorations.inError} classes={{ error: decorations.validationLabel }}>
                        {decorations.errorMsg}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

CustomSelect.defaultProps = {
    fullWidth: true
};

//const placeholderId = 'PLACEHOLDER';

const styles = ({ spacing, palette, transitions, validationLabel }) => ({
    select: {
        '&:before': {
            display: 'none'
        },
        '&:after': {
            display: 'none'
        },
        padding: 0,
        'label + &': {
            marginTop: spacing.unit * 2
        },
        backgroundColor: palette.common.white
    },
    selectInner: {
        backgroundColor: 'transparent !important',
        padding: spacing.unit,
        paddingRight: spacing.unit * 3,
        height: 21,
        maxWidth: '100%',
        width: `calc(100% - ${spacing.unit * 4 + 1}px)`,
        borderRadius: 3,
        border: `1px solid ${palette.grey.A100}`,
        '&:focus': {
            borderRadius: 3,
            borderColor: palette.primary.light,
            boxShadow: `0 0 0 0.1rem rgba(65, 59, 190,.25)` // active color in RGB
        }
    },
    optgroupHead: {
        pointerEvents: 'none',
        marginBottom: -(spacing.unit * 1.5)
    },
    optgroupItem: {
        fontSize: '90%',
        marginTop: -spacing.unit,
        padding: spacing.unit,
        paddingLeft: spacing.unit * 4,
        '& + li': { marginTop: 'unset' }
    },
    optgroupFoot: {
        borderBottom: '1px solid #e7e7e7',
        pointerEvents: 'none'
    },
    readOnly: {
        whiteSpace: 'pre-wrap',
        padding: '5px 5px 5px 0',
        minHeight: 34,
        fontSize: '0.95em',
        fontStyle: 'italic'
    },
    label: {
        zIndex: 1,
        pointerEvents: 'none',
        textTransform: 'capitalize',
        top: 2,
        left: 8,
        color: palette.text.hint,
        transition: transitions.create(['transform', 'color', 'padding', 'left', 'background-color', 'border-radius'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    labelShrink: {
        top: 0,
        left: 0,
        padding: '2px 8px',
        backgroundColor: palette.action.active,
        color: palette.common.white + ' !important',
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        zIndex: 1,
        cursor: 'pointer',
        pointerEvents: 'auto'
    },
    disabledClass: { background: '#efefef', color: palette.text.primary },
    readOnlyLabel: {
        color: '#000',
        fontWeight: '500',
        fontSize: '0.95em',
        display: 'inline-block'
    },

    validationStyle: {
        fontSize: '12px',
        fontStyle: 'italic',
        color: 'red',
        marginTop: '5px'
    },

    validationErrorBorder: {
        border: `1px solid ${palette.validation.error} !important`
    },
    validationErrorLabel: {
        color: `${palette.validation.error} !important`,
        border: `1px solid ${palette.validation.error} !important`
    },
    validationSuggestedBorder: {
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationSuggestedLabel: {
        color: `${palette.validation.suggested} !important`,
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationOptionalBorder: {
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationOptionalLabel: {
        color: `${palette.validation.optional} !important`,
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationLabel: { ...validationLabel }
});

export default withStyles(styles)(CustomSelect);
