import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import Label from '../../../component/form/Label';
import TextField from '../../../component/form/TextField';
import TimeSelect from '../../../component/form/TimeSelect';
import PrimaryButton, { AltButton } from '../../../component/form/PrimaryButton';
import OptionalTextField from '../../../component/form/OptionalTextField';
import Select from '../../../component/form/Select';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../../component/form/AddressBookAutocomplete';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import ColumnLayout from '../../../component/ColumnLayout';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import {
    COFFIN_BEARERS_OPTIONS,
    COFFIN_BEARING_TYPE_OPTIONS,
    DISPOSAL_METHOD_OPTIONS,
    DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT,
    DRESSING_DELIVER_TO_FUNERAL_HOME,
    DRESSING_STATUS_OPTIONS,
    GRAVE_DEPTH_OPTIONS,
    GROUND_TYPE_OPTIONS,
    PARSONS_GROUP_FUNERAL_HOMES,
    PLACE_OF_SERVICE_TYPE_OPTIONS,
    PLACE_OF_VIEWING_TYPE_OPTIONS
} from './DetailsConstants';
import RadioGroup from '../../../component/form/RadioGroup';
import { ImageList, ImageListItem } from '../../../component/form/ImageList';
import { IconButton, Typography } from '@material-ui/core';
import { CoffinIcon, DeleteIcon, DiamondIcon, EditIcon, TickCircleIcon } from '../../../component/IconIndex';
import { adjustTimeString, dateToString, niceDateFromString, niceTimeFromString } from '../../../util/date';
import { getArray, isNullOrUndefined } from '../../../util/objects';
import { isContactDefined } from '../../../util/bookable';
import ValuablesModal from './valuables-modal/ValuablesModal';
import ReflectionRoomBookingModal from './reflectionroom-modal/ReflectionRoomModal';
import { PreloadProducts, ProductConfig, ProductMetadata } from '../../../util/products';
import ProductSelectionModal from '../../../component/product-modal/ProductSelectionModal';
import { TabTypes } from '../../../component/product-modal/ProductModalConstants';
import DisplayContact from '../../../component/bookings/DisplayContact';
import ValuablesForm from '../../mortuary/Common/ValuablesForm';
import SupplierRequest from '../../../component/bookings/SupplierRequest';
import moment from 'moment';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import VenueBooking from '../../../component/bookings/VenueRequest';
import CremationRequest, { SameDayCremationProductID } from '../../../component/bookings/CremationRequest';
import { getDefaultCrematorium } from '../../../environment';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { joinDefined } from '../../../util/strings';

const defaultCrematoriumQuery = gql`
    query ReadOneAddressBook($id: ID!) {
        readOneAddressBook(ID: $id) {
            ID
            Name
        }
    }
`;

class Details extends Component {
    state = {
        placeOfCommittal: undefined,
        arrangeForHearse: false,
        arrangeForCar: false,
        arrangeForLimo: false,
        coffinBearingsRequired: false,
        popupValuablesOpen: false,
        popupAddValuable: false,
        popupCoffinOpen: false,
        popupReflectionRoom: false,
        loading: true
    };
    // default gap between service start & disposal start, in minutes
    DISPOSAL_GAP_MVC = 120;
    DISPOSAL_GAP_CREMATION = 90;
    DISPOSAL_GAP_OTHER = 120;

    static getDerivedStateFromProps(props, state) {
        const firstLoad = !props.form.loading && state.loading;
        if (!firstLoad) return null;
        const { form } = props;
        const CoffinBearings = form.getField('CoffinBearings') || [];
        return {
            loading: props.form.loading,
            arrangeForCar: form.getField('CarRequired'),
            arrangeForLimo: form.getField('LimoRequired'),
            coffinBearingsRequired: CoffinBearings.length > 0
        };
    }

    componentDidUpdate(prevProps) {
        const { form } = prevProps;
        if (!form) return;
        const products = (form.getField('Disposal.DisposalBookingItems') || []).map(({ ProductID }) => ({ ProductID }));
        PreloadProducts(this, products);
    }

    updateContactList = (result, field) => {
        if (!result) return null;
        const { form } = this.props;
        const newList = { [field]: form.getField(field) };
        if (!newList[field]) newList[field] = [];
        newList[field].unshift({ ID: null, Comment: null, Confirmed: null, Contact: { ID: result.ID } });
        form.setField(newList);
    };

    render() {
        const { form, locked } = this.props;

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
                <ReflectionRoomBookingModal
                    open={!!this.state.popupReflectionRoom}
                    data={form.getField('ReflectionRoom')}
                    defaultDate={form.getField('Disposal.Date') || form.getField('DateOfService')}
                    onClose={newItems => {
                        this.setState({ popupReflectionRoom: false });
                        if (newItems) form.setField({ ReflectionRoom: newItems });
                    }}
                />
                <ValuablesModal
                    onClose={() => this.setState({ popupValuablesOpen: false })}
                    open={!!this.state.popupValuablesOpen}
                    addNew={!!this.state.popupAddValuable}
                    valuableitems={form.getField('ValuableItems') || []}
                    form={form}
                    disabled={locked}
                />
                <ProductSelectionModal
                    onClose={() => this.setState({ popupCoffinOpen: false })}
                    open={!!this.state.popupCoffinOpen}
                    onConfirm={coffinBookingItems => this.onSubmitCoffinBookingItems(coffinBookingItems)}
                    form={form}
                    formCartProperty="Disposal.DisposalBookingItems"
                    tabs={[
                        {
                            name: 'Coffin',
                            metadata: ProductMetadata.Coffins,
                            // stepLabel: 'Step One:',
                            stepTitle: 'Select one coffin or casket',
                            // stepSubtitle: 'Please select one coffin or casket from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Lining',
                            metadata: ProductMetadata.Lining,
                            // stepLabel: 'Step Two:',
                            stepTitle: 'Select a seal or lining for the coffin',
                            // stepSubtitle: 'Please select a seal or lining from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Handles',
                            metadata: ProductMetadata.Handles,
                            // stepLabel: 'Step Three:',
                            stepTitle: 'Select one set of coffin handles',
                            // stepSubtitle: 'Please select from the list of compatible handles below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Fittings',
                            metadata: ProductMetadata.Fittings,
                            // stepLabel: 'Step Four:',
                            stepTitle: 'Select any coffin fittings',
                            // stepSubtitle: 'Please select the fittings from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Name Plate',
                            metadata: ProductMetadata.NamePlates,
                            // stepLabel: 'Step Five:',
                            stepTitle: 'Select one Name Plate style',
                            // stepSubtitle: 'Please select a Name Plate form the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Summary',
                            // stepLabel: 'Step Six:',
                            stepTitle: 'Confirm your order',
                            // stepSubtitle: 'Please review your selections and confirm the order.',
                            type: TabTypes.Summary
                        }
                    ]}
                />
            </Fragment>
        );
    }

    renderFirstColumn() {
        return (
            <Grid container spacing={16}>
                {this.renderPlaceOfService()}
                {this.renderClergy()}
                {this.renderDisposalDetails()}
                {this.renderAshes()}
                {this.renderGraveDetails()}
                {this.renderHearse()}
                {this.renderTransportation()}
            </Grid>
        );
    }

    renderSecondColumn() {
        return (
            <Grid container spacing={16}>
                {this.renderPlaceOfViewing()}
                {this.renderCoffinBearings()}
                {this.renderValuables()}
                {this.renderDressing()}
                {this.renderCoffinDetails()}
                {this.renderMortuaryPreparationNotes()}
            </Grid>
        );
    }

    renderWebcastRequired() {
        const { form, locked } = this.props;
        return (
            <Fragment>
                <Grid item xs={12} sm={6}>
                    <TextField
                        placeholder="Webcast URL..."
                        form={form}
                        name="WebcastComment"
                        disabled={!!form.getField('WebcastConfirmed') || locked}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InlineField>
                        <Checkbox
                            label="Confirmed"
                            form={form}
                            name="WebcastConfirmed"
                            disabled={!form.getField('WebcastComment') || locked}
                        />
                        <Checkbox
                            label="Emailed link to family"
                            form={form}
                            name="WebcastEmailedLinkToFamily"
                            disabled={!form.getField('WebcastConfirmed') || !form.getField('WebcastComment') || locked}
                        />
                    </InlineField>
                </Grid>
            </Fragment>
        );
    }

    renderPlaceOfService() {
        const { form, locked } = this.props;
        const hasService = ['No Service No Attendance', 'Graveside'].indexOf(form.getField('PlaceOfService.Type')) < 0;
        const webcastRequired = form.getField('WebcastRequired');
        const placeOfService = form.getField('PlaceOfService') || {};
        const notParsons = !(
            placeOfService.Location &&
            placeOfService.Location.ID &&
            PARSONS_GROUP_FUNERAL_HOMES.indexOf(parseInt(placeOfService.Location.ID)) > -1
        );

        return (
            <Grid bucket={true}>
                <InlineHeader header="Service" />
                {(!!hasService && (
                    <Fragment>
                        <VenueBooking
                            disabled={locked}
                            labelSupplier={'Place Of Service'}
                            name={'PlaceOfService'}
                            form={form}
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfService, ADDRESS_BOOK_CATEGORY.placeOfCommittal]}
                            allowPurchaseOrder={notParsons}
                        >
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            disabled={locked}
                                            allowNone={false}
                                            label="Service type"
                                            options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                                            name="PlaceOfService.Type"
                                            form={form}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            disabled={locked}
                                            type="date"
                                            label="Date of Service"
                                            name="PlaceOfService.Date"
                                            form={form}
                                            onBlur={e => {
                                                this.useAsDefaultValue(
                                                    [
                                                        'HearseToPassHouseDate',
                                                        'HearseToPassHouseAfterServiceDate',
                                                        'HearseDriveAwayDate'
                                                        // 'FollowToCommittalDate'
                                                    ],
                                                    e.target.value
                                                );
                                                this.updateDisposalStart();
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            disabled={locked}
                                            type="time"
                                            label="Time of Service"
                                            name="PlaceOfService.Time"
                                            form={form}
                                            onBlur={() => {
                                                this.useAsDefaultValue('PlaceOfService.Duration', 1);
                                                this.updateDisposalStart();
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TimeSelect
                                            disabled={locked}
                                            defaultValue={1.0}
                                            label="Duration (Hours)"
                                            form={form}
                                            name="PlaceOfService.Duration"
                                            onChange={this.updateDisposalStart}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <InlineField>
                                <Checkbox disabled={locked} label="Private" form={form} name="PlaceOfService.Private" />

                                <Checkbox
                                    name={'FollowToDisposal'}
                                    form={form}
                                    disabled={!!form.getField('Disposal.NoAttendance') || locked}
                                    label={'Follow to Committal'}
                                />

                                <Checkbox disabled={locked} label="Webcast" form={form} name="WebcastRequired" />
                            </InlineField>

                            {webcastRequired && this.renderWebcastRequired()}
                        </VenueBooking>
                    </Fragment>
                )) || (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <Select
                                disabled={locked}
                                allowNone={false}
                                label="Service type"
                                options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                                name="PlaceOfService.Type"
                                form={form}
                            />
                        </Grid>
                        {form.getField('PlaceOfService.Type') === 'Graveside' && (
                            <Fragment>
                                <Grid item xs={12} sm={6}>
                                    <TimeSelect
                                        disabled={locked}
                                        defaultValue={1.0}
                                        label="Duration (Hours)"
                                        form={form}
                                        name="Disposal.Duration"
                                    />
                                </Grid>
                                <InlineField>
                                    <Checkbox disabled={locked} label="Private" form={form} name="Disposal.Private" />
                                    <Checkbox disabled={locked} label="Webcast" form={form} name="WebcastRequired" />
                                </InlineField>
                                {webcastRequired && this.renderWebcastRequired()}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderClergy() {
        const { form, locked } = this.props;
        const clergyRequired = form.getField('ClergyRequired');
        return (
            <Grid bucket={true}>
                <InlineHeader header="Clergy / Celebrant">
                    <Checkbox disabled={locked} form={form} label="Required" name="ClergyRequired" />
                    <Checkbox
                        label="No fees required"
                        form={form}
                        name="ClergyFeeNotRequired"
                        disabled={!clergyRequired || locked}
                    />
                </InlineHeader>
                {clergyRequired && (
                    <Fragment>
                        <SupplierRequest
                            disabled={locked}
                            form={form}
                            name={'Clergy'}
                            categories={[ADDRESS_BOOK_CATEGORY.clergy]}
                            labelSupplier={'Clergy / Celebrant'}
                            allowPurchaseOrder={!form.getField('ClergyFeeNotRequired')}
                        />
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderMVC = () => {
        const { form, locked } = this.props;
        const defaultItem = form.getField('Disposal.SameDayCremation') ? SameDayCremationProductID : null;
        const defaultCrematoriumID = getDefaultCrematorium();
        return (
            <Query query={defaultCrematoriumQuery} variables={{ id: defaultCrematoriumID }}>
                {({ data }) => (
                    <Fragment>
                        {this.renderReflectionRoom()}
                        <CremationRequest
                            form={form}
                            locked={locked}
                            defaultLineItemCode={defaultItem}
                            defaultCrematorium={(data && data.readOneAddressBook) || { Name: 'Default Crematorium' }}
                        />
                    </Fragment>
                )}
            </Query>
        );
    };

    renderDisposalDetails() {
        const { form, locked } = this.props;
        const showDisposalOptions = form.getField('Disposal.CrematedOrBuried') !== 'Body Not Recovered';
        const isBurial = 'Buried' === form.getField('Disposal.CrematedOrBuried');
        const defaultCrematoriumID = getDefaultCrematorium();
        const showDefaultCrematoriumOption =
            defaultCrematoriumID && form.getField('Disposal.CrematedOrBuried') === 'Cremated';
        const usingDefaultCrematorium =
            defaultCrematoriumID && form.getField('Disposal.Location.ID') === defaultCrematoriumID;
        const cremationHappened = form.getField('CremationOrder.CremationNumber') > 0;
        return (
            <Grid bucket={true}>
                <InlineHeader header="Committal" />
                <InlineField>
                    <RadioGroup
                        options={DISPOSAL_METHOD_OPTIONS}
                        form={form}
                        name="Disposal.CrematedOrBuried"
                        disabled={!!cremationHappened || locked}
                    />
                </InlineField>
                {showDisposalOptions && (
                    <Fragment>
                        <VenueBooking
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfCommittal, ADDRESS_BOOK_CATEGORY.placeOfService]}
                            labelSupplier={'Place Of Committal'}
                            name={'Disposal'}
                            form={form}
                            commentField={'CemeterySection'}
                            offsite={(usingDefaultCrematorium && this.renderMVC) || false}
                            allowPurchaseOrder={true}
                            disabled={locked}
                        >
                            <InlineField>
                                <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            label={!isBurial ? 'Date of Delivery' : 'Date of Committal'}
                                            form={form}
                                            name="Disposal.Date"
                                            disabled={locked}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="time"
                                            label={!isBurial ? 'Time of Delivery' : 'Time of Committal'}
                                            form={form}
                                            name="Disposal.Time"
                                            disabled={locked}
                                        />
                                    </Grid>
                                </Grid>
                            </InlineField>
                            {!(
                                form.getField('Disposal.CrematedOrBuried') === 'Repatriated' ||
                                form.getField('Disposal.CrematedOrBuried') === 'Body Donation'
                            ) && (
                                <InlineField>
                                    <Checkbox disabled={locked} label="Private" form={form} name="Disposal.Private" />
                                    <Checkbox
                                        disabled={locked}
                                        label="No Attendance"
                                        form={form}
                                        name="Disposal.NoAttendance"
                                        onChange={e => {
                                            if (e.target.checked) form.setField({ FollowToDisposal: null });
                                        }}
                                    />
                                    {showDefaultCrematoriumOption && (
                                        <Query query={defaultCrematoriumQuery} variables={{ id: defaultCrematoriumID }}>
                                            {({ data }) => (
                                                <>
                                                    <Checkbox
                                                        disabled={
                                                            (form.getField('Disposal.Confirmed') &&
                                                                !form.getField('CremationOrder')) ||
                                                            locked
                                                        }
                                                        onChange={e => {
                                                            form.setField({
                                                                'Disposal.Location.ID': e.target.checked
                                                                    ? defaultCrematoriumID
                                                                    : null
                                                            });
                                                            this.updateDisposalStart();
                                                        }}
                                                        label={
                                                            'Use ' +
                                                            ((data &&
                                                                data.readOneAddressBook &&
                                                                data.readOneAddressBook.Name) ||
                                                                'Default Crematorium')
                                                        }
                                                        checked={!!usingDefaultCrematorium}
                                                    />
                                                </>
                                            )}
                                        </Query>
                                    )}
                                </InlineField>
                            )}
                        </VenueBooking>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderTransportation() {
        //   const { arrangeForCar, arrangeForLimo } = this.state;
        const { classes, form, locked } = this.props;
        const cars = getArray(this, 'Cars');
        const arrangeForCar = form.getField('CarRequired');
        const arrangeForLimo = form.getField('LimoRequired');

        return (
            <Fragment>
                <Grid bucket={true}>
                    <InlineHeader header="Cars">
                        <Checkbox
                            onClick={this.onArrangeForCarChecked}
                            label="Arrange for a Company Mourning car"
                            form={form}
                            name="CarRequired"
                            disabled={locked}
                        />
                    </InlineHeader>

                    {arrangeForCar && (
                        <Fragment>
                            {cars.map((_, i) => (
                                <Grid container spacing={16} className={classes.listItem} key={i}>
                                    <InlineField>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="date"
                                                    label="Date required"
                                                    form={form}
                                                    name={`Cars[${i}].Date`}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="time"
                                                    label="Time required"
                                                    form={form}
                                                    name={`Cars[${i}].Time`}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TimeSelect
                                                    label="Duration (Hours)"
                                                    form={form}
                                                    name={`Cars[${i}].Duration`}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={1} className={classes.alignWithLabel}>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    title={'Delete'}
                                                    onClick={() => this.deleteCar(i)}
                                                    disabled={locked}
                                                >
                                                    <DeleteIcon className={classes.icon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </InlineField>

                                    <InlineField>
                                        <Grid item xs={12} sm={11}>
                                            <TextField
                                                placeholder="Address/comments"
                                                form={form}
                                                name={`Cars[${i}].Address`}
                                                disabled={locked}
                                            />
                                        </Grid>
                                    </InlineField>
                                </Grid>
                            ))}

                            <InlineField>
                                <AltButton disabled={locked} onClick={this.addCar}>
                                    + Add {cars.length > 0 ? 'another' : ''} car
                                </AltButton>
                            </InlineField>
                        </Fragment>
                    )}
                </Grid>

                <Grid bucket={true}>
                    <InlineField>
                        <Label>Limo</Label>
                        <Checkbox
                            onClick={this.onArrangeForLimoChecked}
                            label="Arrange for a hire car or Limo"
                            form={form}
                            name="LimoRequired"
                            disabled={locked}
                        />
                    </InlineField>

                    {arrangeForLimo && (
                        <SupplierRequest
                            form={form}
                            name={'LimoBookings'}
                            labelSupplier={'Hired Car/Limo'}
                            exposeBookingFields={true}
                            exposeBookingDuration={true}
                            allowPurchaseOrder={true}
                            disabled={locked}
                        />
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderCoffinBearings() {
        const { form, classes, locked } = this.props;
        const { coffinBearingsRequired } = this.state;
        const coffinBearings = getArray(this, 'CoffinBearings');

        return (
            <Grid bucket={true}>
                <InlineHeader header={'Coffin Bearings'}>
                    <Checkbox
                        label="Yes, coffin bearing is required"
                        onClick={this.onCoffinBearingsRequiredChecked}
                        checked={coffinBearingsRequired}
                        disabled={locked}
                    />
                </InlineHeader>

                {coffinBearingsRequired && (
                    <Fragment>
                        {coffinBearings.map((bearing, i) => (
                            <Grid container spacing={16} className={classes.listItem} key={i}>
                                <InlineField>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={4}>
                                            <Select
                                                allowNone={false}
                                                className={classes.alignWithFloatingLabel}
                                                placeholder="Select a style of bearing"
                                                options={COFFIN_BEARING_TYPE_OPTIONS}
                                                form={form}
                                                name={`CoffinBearings[${i}].Type`}
                                                disabled={locked}
                                            />
                                        </Grid>
                                        <Grid item xs={11} sm={8}>
                                            <AddressBookAutocomplete
                                                className={classes.alignWithFloatingLabel}
                                                placeholder="Bearing Location"
                                                categories={[
                                                    ADDRESS_BOOK_CATEGORY.placeOfService,
                                                    ADDRESS_BOOK_CATEGORY.placeOfCommittal
                                                ]}
                                                form={form}
                                                name={`CoffinBearings[${i}].Address`}
                                                labelFieldFunc={getLabelWithSuburb}
                                                disabled={locked}
                                            />
                                        </Grid>
                                    </Grid>
                                </InlineField>

                                <InlineField>
                                    <Grid container spacing={16}>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                type="date"
                                                label="Date of Bearing"
                                                form={form}
                                                name={`CoffinBearings[${i}].Date`}
                                                disabled={locked}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                type="time"
                                                label="Time of Bearing"
                                                form={form}
                                                name={`CoffinBearings[${i}].Time`}
                                                disabled={locked}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TimeSelect
                                                defaultValue={0.0}
                                                label="Duration"
                                                form={form}
                                                name={`CoffinBearings[${i}].Duration`}
                                                disabled={locked}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={1} className={classes.alignWithLabel}>
                                            <IconButton
                                                className={classes.iconButton}
                                                title={'Delete Coffin Bearing'}
                                                onClick={() => this.deleteCoffinBearer(i)}
                                                disabled={locked}
                                            >
                                                <DeleteIcon className={classes.icon} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </InlineField>

                                <InlineField>
                                    <RadioGroup
                                        options={COFFIN_BEARERS_OPTIONS}
                                        form={form}
                                        name={`CoffinBearings[${i}].Bearers`}
                                        disabled={locked}
                                    />
                                </InlineField>

                                <InlineField>
                                    <Grid container spacing={16}>
                                        {bearing.Bearers !== 'Bearers by Funeral Director' && (
                                            <Grid item xs={12} sm={6}>
                                                <Label text={'Family bearers required'} />
                                                <TextField
                                                    form={form}
                                                    type={'number'}
                                                    inputProps={{ min: '0' }}
                                                    name={`CoffinBearings[${i}].NumberOfFamilyRequired`}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                        )}
                                        {bearing.Bearers !== 'Bearers by Family' && (
                                            <Grid item xs={12} sm={6}>
                                                <Label text={'Staff bearers required'} />
                                                <TextField
                                                    form={form}
                                                    type={'number'}
                                                    inputProps={{ min: '0' }}
                                                    name={`CoffinBearings[${i}].NumberOfStaffRequired`}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </InlineField>

                                <InlineField>
                                    <OptionalTextField
                                        buttonlabel="+ Add Bearing Comment"
                                        textfieldlabel={'Bearing comment'}
                                        form={form}
                                        name={`CoffinBearings[${i}].Comment`}
                                    />
                                </InlineField>
                            </Grid>
                        ))}
                        <InlineField>
                            <AltButton disabled={locked} onClick={() => this.addCoffinBearer()}>
                                + Add another bearing
                            </AltButton>
                        </InlineField>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderHearse() {
        const { form, locked } = this.props;

        const hearseRequired = form.getField('HearseRequired');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Hearse">
                    <Checkbox disabled={locked} label="Arrange for a Hearse" form={form} name="HearseRequired" />
                </InlineHeader>

                {hearseRequired && (
                    <Fragment>
                        <InlineField>
                            <Grid item xs={12} sm={4}>
                                <Checkbox
                                    name={'HearseToPassHouse'}
                                    form={form}
                                    onClick={e =>
                                        this.onToggleHearseOption(
                                            e.target.checked,
                                            'HearseToPassHouseDate',
                                            'HearseToPassHouseTime',
                                            null,
                                            'PlaceOfService.Date',
                                            'PlaceOfService.Time',
                                            'PlaceOfService.Duration',
                                            true
                                        )
                                    }
                                    label={'Pass house before Service'}
                                    disabled={locked}
                                />
                            </Grid>

                            {!!form.getField('HearseToPassHouse') && (
                                <Fragment>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            disabled={locked}
                                            type="date"
                                            label="Date"
                                            form={form}
                                            name="HearseToPassHouseDate"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            disabled={locked}
                                            type="time"
                                            label="Time"
                                            form={form}
                                            name="HearseToPassHouseTime"
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </InlineField>

                        <InlineField>
                            <Grid item xs={12} sm={4}>
                                <Checkbox
                                    name={'HearseToPassHouseAfterService'}
                                    form={form}
                                    onClick={e =>
                                        this.onToggleHearseOption(
                                            e.target.checked,
                                            'HearseToPassHouseAfterServiceDate',
                                            'HearseToPassHouseAfterServiceTime',
                                            null,
                                            'PlaceOfService.Date',
                                            'PlaceOfService.Time',
                                            'PlaceOfService.Duration',
                                            false
                                        )
                                    }
                                    label={'Pass house after Service'}
                                    disabled={locked}
                                />
                            </Grid>
                            {!!form.getField('HearseToPassHouseAfterService') && (
                                <Fragment>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            type="date"
                                            label="Date"
                                            form={form}
                                            name="HearseToPassHouseAfterServiceDate"
                                            disabled={locked}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            type="time"
                                            label="Time"
                                            form={form}
                                            name="HearseToPassHouseAfterServiceTime"
                                            disabled={locked}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </InlineField>

                        <InlineField>
                            <Grid item xs={12} sm={4}>
                                <Checkbox
                                    name={'HearseDriveAway'}
                                    form={form}
                                    onClick={e =>
                                        this.onToggleHearseOption(
                                            e.target.checked,
                                            'HearseDriveAwayDate',
                                            'HearseDriveAwayTime',
                                            null,
                                            'PlaceOfService.Date',
                                            'PlaceOfService.Time',
                                            'PlaceOfService.Duration',
                                            false
                                        )
                                    }
                                    label={'Drive Away'}
                                    disabled={locked}
                                />
                            </Grid>
                            {!!form.getField('HearseDriveAway') && (
                                <Fragment>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            disabled={locked}
                                            type="date"
                                            label="Date"
                                            form={form}
                                            name="HearseDriveAwayDate"
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            disabled={locked}
                                            type="time"
                                            label="Time"
                                            form={form}
                                            name="HearseDriveAwayTime"
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </InlineField>

                        <InlineField>
                            <OptionalTextField
                                name="HearseComment"
                                buttonlabel="+ Add a Hearse Comment"
                                textfieldlabel={'Hearse comment'}
                                form={form}
                            />
                        </InlineField>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderPlaceOfViewing() {
        const { form, classes, locked } = this.props;
        const PlaceOfViewingItems = getArray(this, 'PlaceOfViewingItems');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Viewings">
                    <Checkbox
                        form={form}
                        name="PlaceOfViewingRequired"
                        onClick={this.onPlaceOfViewingChecked}
                        label={'Yes, arrange viewings'}
                        disabled={locked}
                    />
                </InlineHeader>

                {form.getField('PlaceOfViewingRequired') && (
                    <Fragment>
                        {PlaceOfViewingItems.map((placeOfViewing, i) => (
                            <Grid container spacing={16} className={classes.listItem} key={i}>
                                <Grid pc={1}>
                                    <Label text={'Viewing' + (PlaceOfViewingItems.length > 1 ? ' #' + (1 + i) : '')} />
                                </Grid>
                                <VenueBooking
                                    categories={[ADDRESS_BOOK_CATEGORY.placeOfService]}
                                    labelSupplier={'Place Of Viewing'}
                                    name={`PlaceOfViewingItems[${i}]`}
                                    form={form}
                                    allowPurchaseOrder={true}
                                    disabled={locked}
                                >
                                    <Grid item xs={12}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12} sm={6}>
                                                <Select
                                                    allowNone={false}
                                                    label="Viewing Type"
                                                    options={PLACE_OF_VIEWING_TYPE_OPTIONS}
                                                    name={`PlaceOfViewingItems[${i}].Type`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={11} sm={5}>
                                                <TextField
                                                    type="date"
                                                    label={'Date of Viewing'}
                                                    name={`PlaceOfViewingItems[${i}].Date`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} className={classes.alignWithLabel}>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    title={'Delete Place of Viewing'}
                                                    onClick={() => this.deletePlaceOfViewing(i)}
                                                    disabled={locked}
                                                >
                                                    <DeleteIcon className={classes.icon} />
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    type="time"
                                                    label="Time of Viewing"
                                                    name={`PlaceOfViewingItems[${i}].Time`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TimeSelect
                                                    defaultValue={2.0}
                                                    label="Duration (Hours)"
                                                    name={`PlaceOfViewingItems[${i}].Duration`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                            </Grid>

                                            <InlineField>
                                                <Checkbox
                                                    label="Private"
                                                    name={`PlaceOfViewingItems[${i}].ViewingPrivate`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                                <Checkbox
                                                    label="Advertise"
                                                    name={`PlaceOfViewingItems[${i}].TypeOfViewingAdv`}
                                                    form={form}
                                                    disabled={locked}
                                                />
                                            </InlineField>
                                        </Grid>
                                    </Grid>
                                </VenueBooking>
                            </Grid>
                        ))}

                        <InlineField>
                            <AltButton disabled={locked} onClick={this.addPlaceOfViewing}>
                                + Add another Viewing
                            </AltButton>
                        </InlineField>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderAshes() {
        const { form, locked } = this.props;
        const showAshes = (form ? form.getField('Disposal.CrematedOrBuried') : null) === 'Cremated';
        if (!showAshes) return <Fragment />;
        const showCrematorium = form.getField('AshesDetail.FamilyHasReservation');
        const crematorium = form.getField('AshesDetail.Crematorium');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Ashes" />
                <InlineField>
                    <Checkbox
                        disabled={locked}
                        label="Family has reservation"
                        form={form}
                        name="AshesDetail.FamilyHasReservation"
                    />
                </InlineField>
                {showCrematorium && (
                    <InlineField>
                        <AddressBookAutocomplete
                            disabled={locked}
                            placeholder="Search for a place of Crematorium"
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfCommittal]}
                            name="AshesDetail.Crematorium"
                            form={form}
                            labelFieldFunc={getLabelWithSuburb}
                        />
                    </InlineField>
                )}
                {showCrematorium && isContactDefined(crematorium) && (
                    <InlineField>
                        <Grid item xs={12}>
                            <DisplayContact disabled={locked} contactID={crematorium.ID} />
                        </Grid>
                    </InlineField>
                )}
                <InlineField>
                    <OptionalTextField
                        name="AshesDetail.Comment"
                        form={form}
                        buttonlabel="+ Add Ashes comment"
                        textfieldlabel={'Ashes Comment'}
                    />
                </InlineField>
            </Grid>
        );
    }

    renderReflectionRoom() {
        const { form, classes, locked } = this.props;
        const noAttendance = form.getField('Disposal.NoAttendance');
        if (!!noAttendance) return null;
        const defaultCrematoriumID = getDefaultCrematorium();
        const usingDefaultCrematorium =
            defaultCrematoriumID && form.getField('Disposal.Location.ID') === defaultCrematoriumID;

        const showBookingTable = form.getField('ReflectionRoomRequired') === 'Required';
        const ReflectionRoom = form.getField('ReflectionRoom');
        const HasReflectionRoom = ReflectionRoom && ReflectionRoom.Date;

        if (HasReflectionRoom) {
            // cool beans
        } else if (!usingDefaultCrematorium && !HasReflectionRoom) return null;

        const formattedNames =
            HasReflectionRoom &&
            joinDefined(
                [
                    ReflectionRoom.Applicant && 'The applicant',
                    ReflectionRoom.AuthorisedContact && 'The authorised contacts',
                    ReflectionRoom.AttendeeNames && ReflectionRoom.AttendeeNames.replace(/\n/g, ', ')
                ],
                ', '
            );

        return (
            <Fragment>
                <Grid item>
                    <RadioGroup
                        label={'Reflection Room'}
                        options={[
                            { value: 'Required', label: 'Required' },
                            { value: 'NotRequired', label: 'Not required' },
                            { value: '', label: 'Unsure' }
                        ]}
                        form={form}
                        name="ReflectionRoomRequired"
                        disabled={locked}
                    />

                    {showBookingTable && (
                        <Fragment>
                            <InlineHeader
                                header={
                                    'Reflection Room booking: ' +
                                    (HasReflectionRoom
                                        ? !ReflectionRoom.Confirmed
                                            ? 'Requested'
                                            : 'Confirmed'
                                        : 'None')
                                }
                            />
                            {HasReflectionRoom ? (
                                <Table columns={['Date', 'Time', 'Attendees', 'Edit']}>
                                    <TableRow pad>
                                        <TableCell dataLabel="Date">
                                            {niceDateFromString(ReflectionRoom.Date)}
                                        </TableCell>
                                        <TableCell dataLabel="Time">
                                            {niceTimeFromString(ReflectionRoom.Start)}
                                            {' - '}
                                            {niceTimeFromString(ReflectionRoom.End)}
                                        </TableCell>
                                        <TableCell dataLabel="Attendees">{formattedNames}</TableCell>
                                        <TableCell dataLabel="Edit" className={classes.colSm}>
                                            <IconButton
                                                className={classes.icon}
                                                onClick={() => this.handleShowReflectionRoomBookingModal()}
                                                disabled={locked}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            ) : (
                                <AltButton
                                    disabled={locked}
                                    onClick={() => this.handleShowReflectionRoomBookingModal()}
                                >
                                    Request Booking...
                                </AltButton>
                            )}
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderGraveDetails() {
        const { form, classes, locked } = this.props;

        const crematedOrBuried = form ? form.getField('Disposal.CrematedOrBuried') : null;
        const showGraveDetails =
            crematedOrBuried === 'Buried' || crematedOrBuried === 'Repatriated' || crematedOrBuried === 'Body Donation';
        if (!showGraveDetails) return <Fragment />;

        return (
            <Grid bucket={true}>
                <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 16 }}>
                    <InlineHeader header="Grave Details" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        allowNone={false}
                        className={classes.alignWithFloatingLabel}
                        label={'Ground Type'}
                        options={GROUND_TYPE_OPTIONS}
                        form={form}
                        name="Grave.GroundDescription"
                        disabled={locked}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        allowNone={false}
                        className={classes.alignWithFloatingLabel}
                        label={'Depth of Grave'}
                        options={GRAVE_DEPTH_OPTIONS}
                        form={form}
                        name="Grave.DepthOption"
                        disabled={locked}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={locked}
                        label="Portion/garden name"
                        form={form}
                        name="Grave.PortionGardenName"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField disabled={locked} label="Beam/row/lot" form={form} name="Grave.BeamRowLot" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField disabled={locked} label="Side/section" form={form} name="Grave.SideSection" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField disabled={locked} label="Grave number" name="Grave.Number" form={form} />
                </Grid>

                <InlineField>
                    <Checkbox
                        disabled={locked}
                        label="Reservation required"
                        name="Grave.ReservationRequired"
                        form={form}
                    />
                </InlineField>

                <InlineField>
                    <Label>Grave Ledger</Label>
                    <Checkbox disabled={locked} label="Ledger work required" form={form} name="LedgerRequired" />
                </InlineField>

                {form.getField('LedgerRequired') && (
                    <SupplierRequest
                        disabled={locked}
                        form={form}
                        name={'GraveLedger'}
                        labelSupplier={'Grave Ledger'}
                        allowPurchaseOrder={true}
                    />
                )}
            </Grid>
        );
    }

    renderValuables() {
        const { form, classes, locked } = this.props;
        const valuables = form ? form.getField('ValuableItems') : [];

        return (
            <Grid bucket={true}>
                <InlineHeader header="Valuables" />
                <Grid pc={1}>
                    {(valuables && valuables.length > 0 && (
                        <Typography variant={'body1'} style={{ marginLeft: -4, marginRight: -4 }}>
                            {valuables.map((e, i) => {
                                return (
                                    <span key={i} className={classes.listItems}>
                                        {e.Signed && (
                                            <span
                                                className={classes.itemSigned}
                                                title="Item is signed on Valuables Form"
                                            >
                                                <TickCircleIcon />
                                            </span>
                                        )}
                                        {e.Description}
                                        {e.Quantity > 1 && <span className={classes.itemQty}>x{e.Quantity}</span>}
                                    </span>
                                );
                            })}
                        </Typography>
                    )) || <Typography variant={'body1'}>There are currently no valuables.</Typography>}
                </Grid>
                <InlineField>
                    {/*<PrimaryButton onClick={this.onClickAddValuable}>Add valuable...</PrimaryButton>*/}
                    {valuables && valuables.length > 0 ? (
                        <Fragment>
                            <PrimaryButton onClick={this.onClickViewAllValuables}>
                                <DiamondIcon />
                                Manage Valuables...
                            </PrimaryButton>
                            <ValuablesForm form={form} />
                        </Fragment>
                    ) : (
                        <PrimaryButton disabled={locked} onClick={this.onClickAddValuable}>
                            <DiamondIcon />
                            Add Valuables...
                        </PrimaryButton>
                    )}
                </InlineField>
            </Grid>
        );
    }

    renderDressing() {
        const { form, classes, locked } = this.props;

        const dressingStatus = form.getField('FuneralDressing.Status');
        const showDateTimeFields = dressingStatus === DRESSING_DELIVER_TO_FUNERAL_HOME;
        const showReceivedField = dressingStatus === DRESSING_COLLECT_AT_TIME_OF_ARRANGEMENT || !!showDateTimeFields;
        const dressingShroud = form.getField('FuneralDressing.Shroud');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Dressing">
                    <Checkbox disabled={locked} label="Greek shroud" form={form} name="FuneralDressing.GreekShroud" />
                    <Checkbox disabled={locked} label="Shroud" form={form} name="FuneralDressing.Shroud" />
                </InlineHeader>
                <InlineField>
                    {!dressingShroud && (
                        <Select
                            disabled={locked}
                            label={'Dressing Instructions'}
                            allowNone={false}
                            options={DRESSING_STATUS_OPTIONS}
                            form={form}
                            name="FuneralDressing.Status"
                        />
                    )}

                    {showReceivedField && !dressingShroud && (
                        <Checkbox
                            disabled={locked}
                            className={classes.alignWithFloatingLabel}
                            label="Received"
                            form={form}
                            name="FuneralDressing.Received"
                        />
                    )}
                </InlineField>

                {showDateTimeFields && (
                    <InlineField>
                        <Grid item xs={12} sm={6}>
                            <Label text={'Date to receive clothing'} />
                            <TextField disabled={locked} type="date" form={form} name="FuneralDressing.Date" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label text={'Estimated time'} />
                            <TextField disabled={locked} type="time" form={form} name="FuneralDressing.Time" />
                        </Grid>
                    </InlineField>
                )}

                <InlineField>
                    <OptionalTextField
                        buttonlabel="+ Add Dressing Comment"
                        textfieldlabel="Dressing Comment"
                        form={form}
                        name="FuneralDressing.Comment"
                    />
                </InlineField>
            </Grid>
        );
    }

    renderCoffinDetails() {
        const { form, locked } = this.props;
        const CoffinProducts = form.getField('Disposal.DisposalBookingItems');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Coffin Details" />
                {CoffinProducts && CoffinProducts.length > 0 && (
                    <Grid item xs={12}>
                        <ImageList>
                            {CoffinProducts.sort((a, b) => {
                                if (a.URLSegment === 'coffins') return -1;
                                if (b.URLSegment === 'coffins') return 1;
                                return Number(a.ID) < Number(b.ID) ? -1 : 1;
                            }).map((value, i) => {
                                const product = ProductConfig.productMap[value.ProductID];
                                if (!product) return null;
                                if (value.Quantity && product.urlSegment === 'coffins') {
                                    product.tileWidth = 2;
                                    product.tileHeight = 2;
                                }
                                return (
                                    <ImageListItem
                                        key={i}
                                        value={product}
                                        image={product.imageLandscape}
                                        quantity={value.Quantity}
                                    />
                                );
                            })}
                        </ImageList>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PrimaryButton forModal disabled={locked} onClick={() => this.setState({ popupCoffinOpen: true })}>
                        <CoffinIcon />
                        {!CoffinProducts || CoffinProducts.length === 0
                            ? 'Add Coffin Details...'
                            : 'Manage Coffin Details...'}
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    }

    renderMortuaryPreparationNotes() {
        const { form } = this.props;
        const isCrypt =
            (form.getField('Disposal.CrematedOrBuried') === 'Buried' ||
                form.getField('Disposal.CrematedOrBuried') === 'Repatriated') &&
            (form.getField('Grave.GroundDescription') === 'Crypt' || form.getField('Grave.DepthOption') === 'Crypt');
        const isCremation = form.getField('Disposal.CrematedOrBuried') === 'Cremated';
        const hasPacemaker = !!isCremation && !!form.getField('RemovePacemaker');
        return (
            <Grid bucket={true}>
                <InlineHeader header="Mortuary preparation notes" />
                <Grid item xs={12}>
                    <OptionalTextField
                        buttonlabel={'+ Add Notes'}
                        multiline
                        placeholder="Enter any notes..."
                        name="CoffinComment"
                        form={form}
                    />
                </Grid>
                {!!hasPacemaker && (
                    <Grid item xs={12}>
                        <BiggerMessageBar messageType="warning">Pacemaker must be removed.</BiggerMessageBar>
                    </Grid>
                )}
                {!!isCrypt && (
                    <Grid item xs={12}>
                        <BiggerMessageBar messageType="info" className="miniMessageSpecial">
                            Embalming is required for Crypt.
                        </BiggerMessageBar>
                    </Grid>
                )}
            </Grid>
        );
    }

    updateDisposalStart = () => {
        const { form } = this.props;
        // If committal confirmed, or no service, or committal days after service, do nothing.
        const Disposal = form.getField('Disposal') || {};
        if (!!Disposal.Confirmed) return;
        const PlaceOfService = form.getField('PlaceOfService');
        if (!PlaceOfService || ['Graveside', 'No Service No Attendance'].indexOf(PlaceOfService.Type) > -1) return;
        if (!!PlaceOfService.Date && !!Disposal.Date) {
            if (moment(Disposal.Date).isAfter(PlaceOfService.Date, 'days')) {
                return;
            }
        }
        // If we're still here it means the committal will be same day as service. Now check the gap between them.
        Disposal.Date = PlaceOfService.Date;
        const time = moment(PlaceOfService.Time, 'HH:mm:ss');
        if (!!time && time.isValid()) {
            const defaultCrematoriumID = getDefaultCrematorium();
            const usingDefaultCrematorium =
                defaultCrematoriumID && form.getField('Disposal.Location.ID') === defaultCrematoriumID;
            const disposalGap = !!usingDefaultCrematorium
                ? this.DISPOSAL_GAP_MVC
                : Disposal.CrematedOrBuried === 'Cremated'
                ? this.DISPOSAL_GAP_CREMATION
                : this.DISPOSAL_GAP_OTHER;
            const newTime = time.add(disposalGap, 'm');
            Disposal.Time = newTime.format('HH:mm:ss');
        }
        form.setField({ Disposal });
    };

    useAsDefaultValue(properties, value) {
        const { form } = this.props;

        if (!Array.isArray(properties)) {
            properties = properties.split(',');
        }
        properties.forEach(property => {
            const existingValue = form.getField(property);
            if (!existingValue) form.setField({ [property]: value });
        });
    }

    onSubmitCoffinBookingItems(coffinBookingItems) {
        const { form } = this.props;
        form.setField({ 'Disposal.DisposalBookingItems': coffinBookingItems });
    }

    onArrangeForCarChecked = e => {
        this.itemRequiredCheck(e, 'arrangeForCar', 'Cars', this.addCar);
    };

    onArrangeForHearseChecked = e => {
        this.setState({ hearseRequired: e.target.checked });
    };

    addCar = () => {
        const { form } = this.props;
        const theDate = form.getField('PlaceOfService.Date') || new Date();
        const theTime = adjustTimeString(form.getField('PlaceOfService.Time') || '14:30:00', -30);
        this.addItem('Cars', {
            Date: dateToString(theDate),
            Time: theTime,
            Address: '',
            Duration: 2.0
        });
    };

    deleteCar = i => {
        this.deleteItem('arrangeForCar', 'Cars', i);
    };

    onArrangeForLimoChecked = e => {
        this.setState({ arrangeForLimo: true });
        //this.itemRequiredCheck(e, 'arrangeForLimo', 'Limos', this.addLimo);
    };

    addLimo = () => {
        const { form } = this.props;
        const theDate = form.getField('PlaceOfService.Date') || new Date();
        const theTime = adjustTimeString(form.getField('PlaceOfService.Time') || '14:30:00', -30);
        this.addItem('Limos', {
            Date: dateToString(theDate),
            Time: theTime,
            Address: '',
            Duration: 2.0
        });
    };

    deleteLimo = i => {
        this.deleteItem('arrangeForLimo', 'Limos', i);
    };

    onCoffinBearingsRequiredChecked = e => {
        this.itemRequiredCheck(e, 'coffinBearingsRequired', 'CoffinBearings', this.addCoffinBearer);
    };

    addCoffinBearer = () => {
        const { form } = this.props;
        const PlaceOfService = form.getField('PlaceOfService') || {};
        const hasService = ['No Service No Attendance', 'Graveside'].indexOf(form.getField('PlaceOfService.Type')) < 0;
        const theService = (!!hasService && PlaceOfService) || form.getField('Disposal') || {};
        this.addItem('CoffinBearings', {
            Type: COFFIN_BEARING_TYPE_OPTIONS[0].value,
            Address: (theService.Location && theService.Location.Name) || null,
            Date: theService.Date || null,
            Time: theService.Time || null,
            Duration: theService.Duration || 0.0,
            NumberOfStaffRequired: 0,
            NumberOfFamilyRequired: 0,
            Comment: null,
            Bearers: null
        });
    };

    deleteCoffinBearer = i => {
        this.deleteItem('coffinBearingsRequired', 'CoffinBearings', i);
    };

    onPlaceOfViewingChecked = e => {
        this.itemRequiredCheck(e, null, 'PlaceOfViewingItems', this.addPlaceOfViewing);
    };

    handleShowReflectionRoomBookingModal() {
        this.setState({ popupReflectionRoom: true });
    }

    handleCloseReflectionRoomBookingModal() {
        this.setState({ popupReflectionRoom: false });
    }

    addPlaceOfViewing = () => {
        this.addItem('PlaceOfViewingItems', {
            LocationID: null,
            Type: PLACE_OF_VIEWING_TYPE_OPTIONS[0].value,
            Date: dateToString(new Date()),
            Time: '14:00:00',
            Duration: 2.0,
            ViewingPrivate: false,
            TypeOfViewingAdv: false
        });
    };

    deletePlaceOfViewing = i => {
        this.deleteItem(null, 'PlaceOfViewingItems', i);
    };

    itemRequiredCheck = (e, stateProperty, arrayName, addFunc) => {
        if (!isNullOrUndefined(stateProperty)) this.setState({ [stateProperty]: e.target.checked });

        if (e.target.checked) {
            const wasData = this.state[arrayName] || [];
            if (wasData && wasData.length) {
                wasData.forEach(w => this.addItem(arrayName, w));
            } else if (getArray(this, arrayName).length <= 0) {
                addFunc();
            }
        } else if (!e.target.checked && getArray(this, arrayName).length > 0) {
            this.setState({ [arrayName]: getArray(this, arrayName) });
            this.props.form.setField({ [arrayName]: [] });
        }
    };

    addItem = (arrayName, obj) => {
        const { form } = this.props;
        const array = getArray(this, arrayName);
        array.push(obj);
        form.setField({ [arrayName]: array });
    };

    deleteItem = (stateProperty, arrayName, index) => {
        const { form } = this.props;
        const array = getArray(this, arrayName);
        array.splice(index, 1);
        form.setField({ [arrayName]: array });

        if (!isNullOrUndefined(stateProperty) && array.length <= 0) this.setState({ [stateProperty]: false });
    };

    onClickViewAllValuables = () => {
        this.setState({
            popupValuablesOpen: true,
            popupAddValuable: false
        });
    };

    onClickAddValuable = () => {
        this.setState({
            popupValuablesOpen: true,
            popupAddValuable: true
        });
    };

    onToggleHearseOption(
        checked,
        hearseDateProp,
        hearseTimeProp,
        hearseDurationProp,
        eventDateProp,
        eventTimeProp,
        eventDurationProp,
        before
    ) {
        if (!checked) return;

        const { form } = this.props;
        // const hearseDate = form.getField(hearseDateProp);
        // const hearseTime = form.getField(hearseTimeProp);
        // if (hearseDate || hearseTime) return;

        const eventDate = form.getField(eventDateProp);
        const eventTime = form.getField(eventTimeProp);
        const duration = form.getField(eventDurationProp) || 1;
        const minuteAdjustment = (before ? -0.5 : duration) * 60; // half hour before, or right after
        const adjustedTime = adjustTimeString(eventTime, minuteAdjustment);

        const newState = {};

        newState[hearseDateProp] = eventDate;
        newState[hearseTimeProp] = adjustedTime;
        if (hearseDurationProp) newState[hearseDurationProp] = '' + Number(duration).toFixed(2);

        form.setField(newState);
    }

    openCalendar = () => {
        const { form } = this.props;
        const serviceDate = form.getField('Disposal.Date') || form.getField('DateOfService');
        const date = serviceDate ? '/' + serviceDate.replace(/-/g, '').trim() : '';
        const url = `/calendar${date}`;
        const win = window.open(url, '_blank');
        win.focus();
    };
}

const styles = ({ palette }) => ({
    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    iconButton: {
        // height: '24px',
        // width: '24px',
        float: 'right',
        marginRight: -8
        // marginTop: '12px'
    },
    title: {
        fontSize: 14,
        paddingTop: 10
    },
    gridTitle: {
        paddingTop: 10,
        lineHeight: '1.5em !important'
    },
    listItem: {
        width: '100%',
        margin: '0 0 6px 0',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        }
    },
    icon: {
        height: '24px',
        width: '24px',
        '& > span > svg': { height: '18px', width: '18px' },
        verticalAlign: 'middle'
    },
    listItems: {
        backgroundColor: 'white',
        border: `1px solid ${palette.divider}`,
        borderRadius: 16,
        padding: '4px 10px',
        margin: 2,
        float: 'left',
        whiteSpace: 'pre-line',
        position: 'relative'
    },
    colSm: {
        width: 1,
        whiteSpace: 'nowrap'
    },
    itemQty: {
        background: `${palette.divider}`,
        borderRadius: '0 10px 10px 0',
        margin: '0 -8px 0 5px',
        padding: '2px 5px'
    },
    itemSigned: {
        margin: '-1px 8px -8px -6px',
        float: 'left',
        '& svg': {
            borderRadius: 48,
            height: 24,
            width: 24,
            color: '#54be82',
            background: '#e9faf0'
        }
    }
});

export default withStyles(styles)(Details);
