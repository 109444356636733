import gql from 'graphql-tag';
import { getClient } from '../../../apollo';
import AdminSummaryFragment from './AdminSummaryFragment';
import { deleteTypeName, groupBy } from '../../../util/objects';
import { diff, flattenConnection } from '../../../util/functions';
import AdminCheck from '../../fragments/AdminChecks';
import cloneDeep from 'lodash.clonedeep';
import { getCategoryIndices } from '../../mortuary/Coffin/CoffinListConstants';
import { QueryKeys } from '../../../util/products';
import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import { getUser } from '../../../util/sessions';

const getChecklistQuery = () => gql`
    ${AdminCheck}
    query {
        readAdminChecks {
            edges {
                node {
                    ...AdminCheck
                }
            }
        }
    }
`;

const adminSummaryQuery = funeralId => gql`
    ${AdminSummaryFragment}
    query {
        readOneFuneral(ID: ${funeralId}) {
            LegacyKey
            ...AdminSummary
        }
    }
`;

const adminSummaryMutation = gql`
    ${AdminSummaryFragment}
    mutation SaveFuneral($input: UpdateFuneralInput!) {
        updateFuneral(input: $input) {
            ...AdminSummary
        }
    }
`;

export const getChecklistFunc = async () => {
    const asyncQuery = await getClient().query({ query: getChecklistQuery() });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readAdminChecks : {};
};

//gets the admin summary from the database
const getAdminSummaryFunc = async funeralId => {
    const asyncQuery = await getClient().query({ query: adminSummaryQuery(Number(funeralId) || 0) });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readOneFuneral : {};
};

//saves the admin summary to the database
export const saveAdminSummaryFuncAsync = async (funeralId, adminSummary, original) => {
    const AdminChecksComplete = adminSummary.AdminChecks.filter(
        x => x._join.FuneralAdminCheck.Checked && x.TabURLSegment !== 'mortuary' //skip the mortuary!
    ).length;
    const AdminChecksRequired = adminSummary.AdminChecks.filter(
        x => x.TabURLSegment !== 'mortuary' //skip the mortuary!
    ).length;

    adminSummary.AdminChecksComplete = AdminChecksComplete / AdminChecksRequired;

    if (original.AdminChecksComplete < 0.5 && adminSummary.AdminChecksComplete > 0.5) {
        let currentUser = getUser();
        if (adminSummary.Coordinators.length < 2) {
            if (adminSummary.Coordinators.length === 0) {
                adminSummary.Coordinators.push({ ID: null, Allocation: 'Primary', MemberID: currentUser.ID });
            } else if (adminSummary.Coordinators[0].ID !== currentUser.ID) {
                const alloc = adminSummary.Coordinators[0].Allocation === 'Primary' ? 'Secondary' : 'Primary';
                adminSummary.Coordinators.push({ ID: null, Allocation: alloc, MemberID: currentUser.ID });
            }
        }
    }

    const input = diff(adminSummary, original);
    deleteTypeName(input);
    const output = input;
    if (output.AdminChecks) {
        output.AdminChecks = output.AdminChecks.map(e => {
            return { ID: e.ID, Checked: e._join.FuneralAdminCheck.Checked };
        });
        const asyncQuery = await getClient().mutate({
            mutation: adminSummaryMutation,
            variables: {
                input: {
                    ID: funeralId,
                    ...input
                }
            }
        }); //.then(({ data }) => data, e => console.error('failed to get admin check summary', e));
        return asyncQuery.data;
    }
    return null;
};

//saves the checkout checklist to the database
export const saveCheckoutChecklistFunc = (funeralId, adminSummary, original) => {
    const input = diff(adminSummary, original);
    deleteTypeName(input);
    const output = input;
    if (!output.AdminChecks) return Promise.resolve(null);

    output.AdminChecks = output.AdminChecks.map(e => ({ ID: e.ID, Checked: e._join.FuneralAdminCheck.Checked }));

    return getClient()
        .mutate({
            mutation: adminSummaryMutation,
            variables: {
                input: {
                    ID: funeralId,
                    ...input
                }
            }
        })
        .then(
            ({ data }) => data,
            e => console.error('failed to get checklist', e)
        );
};

export const loadAdminSummary = funeralId => {
    if (funeralId === null) return Promise.resolve(null);
    return getChecklistFunc().then(allResult => {
        // grabbed all system adminchecks
        const checklist = cloneDeep(allResult);
        return getAdminSummaryFunc(funeralId).then(result => {
            if (!result) return { adminSummary: null, groupedIndices: [] };
            // grabbed adminchecks on this funeral
            const original = cloneDeep(result);
            flattenConnection(original, 'AdminChecks');
            const adminSummary = cloneDeep(original);
            if (!!checklist && !!checklist.edges && checklist.edges.length > 0) {
                adminSummary.AdminChecks = checklist.edges.map(e => {
                    // merge all adminchecks into the funeral's adminchecks
                    const obj = adminSummary.AdminChecks.find(o => {
                        return o.ID === e.node.ID;
                    });
                    if (obj) return obj;
                    // this is a new admincheck, so add it to the funeral
                    const newObj = e.node;
                    newObj._join = { FuneralAdminCheck: { Checked: null, ID: null } };
                    return newObj;
                });
            }
            original.AdminChecks.sort((a, b) => Number(a.ID) - Number(b.ID));
            adminSummary.AdminChecks.sort((a, b) => Number(a.ID) - Number(b.ID));
            const groupedIndices = groupBy(
                adminSummary.AdminChecks,
                x => x.TabURLSegment,
                (_, i) => i,
                true
            );
            return { adminSummary, groupedIndices, original };
        });
    });
};

export const filterAdminChecks = (funeral, tabUrlSegment, adminChecks) => {
    return adminChecks.map((adminCheck, index) => {
        const include =
            adminCheck.TabURLSegment === tabUrlSegment &&
            (stringIsNullOrEmpty(adminCheck.OptionsFlag) || !shouldIgnore(adminCheck.OptionsFlag, funeral));

        return { adminCheck, index, include };
    });
};

const shouldIgnore = (metaFlag, funeral) => {
    if (metaFlag === 'coffin' || metaFlag === 'coffin-premium') return !HasCoffin(funeral);

    if (metaFlag === 'coffin-handle') return !HasCoffinHandle(funeral);

    if (metaFlag === 'coffin-fitting') return !HasCoffinFitting(funeral);

    if (metaFlag === 'coffin-comment-religious') return !HasCoffinReligiousComment(funeral);

    if (metaFlag === 'dressing-comment') return !HasDressingComment(funeral);

    if (metaFlag === 'flowers-comment') return !HasFlowersComment(funeral);

    if (metaFlag === 'flowers-deliver') return !FlowersNeedDelivery(funeral);

    console.error('Unknown meta flag', metaFlag);
    return true;
};

const HasCoffin = form => {
    const items = getCategoryIndices(form.state, QueryKeys.Coffins);
    return items.length > 0;
};
const HasCoffinHandle = form => {
    const items = getCategoryIndices(form.state, QueryKeys.Handles);
    return items.length > 0;
};
const HasCoffinFitting = form => {
    const items = getCategoryIndices(form.state, QueryKeys.Fittings);
    return items.length > 0;
};
const HasCoffinReligiousComment = form => {
    const coffinComments = getCategoryIndices(form.state, QueryKeys.Coffins).map(
        ({ bookingItem }) => bookingItem.Comment
    );
    const joinedComments = joinDefined(coffinComments, ' ').toLowerCase();

    return !stringIsNullOrEmpty(joinedComments) && joinedComments.indexOf('religious hardware') >= 0;
};
const HasDressingComment = form => {
    const comment = form.getField('FuneralDressing.Comment');
    return !stringIsNullOrEmpty(comment);
};
const HasFlowersComment = form => {
    const comment = joinDefined(
        form.getField('Florists').map(x => x.Comment),
        ' '
    );
    return !stringIsNullOrEmpty(comment);
};
const FlowersNeedDelivery = form => {
    const florists = form.getField('Florists');
    for (let x = 0; x < florists.length; x++) {
        const flowers = florists[x].BookingItems.edges || florists[x].BookingItems || [];
        if (flowers.length > 0) {
            return true;
        }
    }

    return false;
};
