import { COMPONENT } from '../../../component/form/AddressAutocomplete';

export const MARITAL_STATUS_OPTIONS = [
    { label: 'Married', value: 'Married' },
    { label: 'De facto', value: 'Defacto' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Never married', value: 'Never married' },
    { label: 'Unknown', value: 'Unknown' }
];

export const LIFE_STATUS_OPTIONS = [
    { label: 'Alive', value: 'Alive' },
    { label: 'Stillborn', value: 'Stillborn' },
    { label: 'Deceased', value: 'Deceased' },
    { label: 'Unknown', value: 'Unknown' }
];

export const GENDER_OPTIONS = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Intersex', value: 'Intersex' },
    { label: 'Unknown', value: 'Unknown' }
];

export const PARENT_TYPE_OPTIONS = [
    { label: 'Father', value: 'Father' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Parent', value: 'Parent' }
];

export const CHILD_AGE_OPTIONS = [
    { label: 'Seconds', value: 'Seconds' },
    { label: 'Minutes', value: 'Minutes' },
    { label: 'Hours', value: 'Hours' },
    { label: 'Days', value: 'Days' },
    { label: 'Weeks', value: 'Weeks' },
    { label: 'Months', value: 'Months' },
    { label: 'Years', value: 'Years' },
    { label: 'Not Stated', value: 'Not Stated' },
    { label: 'Unknown', value: 'Unknown' }
];

export const oppositeGender = gender => {
    if (gender === 'Male') return 'Female';
    if (gender === 'Female') return 'Male';

    return 'Unknown';
};

export const createMarriageAddressComponents = index => {
    return {
        //[COMPONENT.line1]: `Marriages[${index}].AddressLine1`,
        //[COMPONENT.line2]: `Marriages[${index}].AddressLine2`,
        [COMPONENT.city]: `Marriages[${index}].Suburb`,
        [COMPONENT.state]: `Marriages[${index}].State`,
        [COMPONENT.country]: `Marriages[${index}].Country`
        //[COMPONENT.code]: `Marriages[${index}].PostCode`
    };
};

export const MarriageFieldVerboseNames = [
    { Field: 'Type', Name: 'Type' },
    { Field: 'FirstName', Name: 'First name' },
    { Field: 'MiddleName', Name: 'Middle name' },
    { Field: 'Surname', Name: 'Surname before marriage' },
    { Field: 'CurrentSurname', Name: 'Current surname' },
    { Field: 'AgeMarried', Name: 'Age married' },
    { Field: 'Gender', Name: 'Gender' },
    { Field: 'Suburb', Name: 'Suburb' },
    { Field: 'State', Name: 'State' },
    { Field: 'Country', Name: 'Country' }
];

export const ChildFieldVerboseNames = [
    { Field: 'GivenName', Name: 'First name' },
    { Field: 'OtherGivenNames', Name: 'Middle name' },
    { Field: 'FamilyNameAtBirth', Name: 'Birth surname' },
    { Field: 'FamilyName', Name: 'Surname' },
    { Field: 'Gender', Name: 'Gender' },
    { Field: 'DateOfBirth', Name: 'Date of birth' },
    { Field: 'LifeStatus', Name: 'Life status' }
];
