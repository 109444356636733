import gql from 'graphql-tag';

export default gql`
    fragment ExitMusicSongs on Funeral {
        ExitMusicSongs {
            ID
            Name
            Artist
            Pathname
            SongSort
        }
    }
`;
