import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 37.326 37.326" {...props}>
        <path
            fill="currentColor"
            d="M16.381,15.725,20.493,16.9a1.494,1.494,0,0,1-.408,2.931h-2.69a2.812,2.812,0,0,1-1.648-.532.549.549,0,0,0-.714.087l-.831.831a.592.592,0,0,0,.073.889A5.123,5.123,0,0,0,17.4,22.163h.1v1.75a.585.585,0,0,0,.583.583h1.166a.585.585,0,0,0,.583-.583v-1.75h.1A3.947,3.947,0,0,0,23.708,19.6a3.845,3.845,0,0,0-2.559-4.943l-4.309-1.232a1.494,1.494,0,0,1,.408-2.931h2.69a2.812,2.812,0,0,1,1.648.532.549.549,0,0,0,.714-.087l.831-.831a.592.592,0,0,0-.073-.889,5.123,5.123,0,0,0-3.128-1.057h-.1V6.415a.585.585,0,0,0-.583-.583H18.08a.585.585,0,0,0-.583.583v1.75h-.255a3.827,3.827,0,0,0-3.806,4.192A3.985,3.985,0,0,0,16.381,15.725ZM34.994,21H32.661A15.164,15.164,0,1,0,3.5,15.164,15.059,15.059,0,0,0,4.666,21H2.333A2.335,2.335,0,0,0,0,23.329V34.994a2.335,2.335,0,0,0,2.333,2.333H34.994a2.335,2.335,0,0,0,2.333-2.333V23.329A2.335,2.335,0,0,0,34.994,21ZM18.663,2.333A12.831,12.831,0,1,1,5.832,15.164,12.85,12.85,0,0,1,18.663,2.333Zm16.33,32.661H2.333V23.329H5.9a15.219,15.219,0,0,0,4.71,4.666H7.582A.585.585,0,0,0,7,28.578v1.166a.585.585,0,0,0,.583.583H29.744a.585.585,0,0,0,.583-.583V28.578a.585.585,0,0,0-.583-.583H26.719a15.219,15.219,0,0,0,4.71-4.666h3.565Z"
        />
    </SvgIcon>
);
