import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from './Grid';

const ModalStepSubHeader = ({ classes, header, children }) => (
    <Grid pc={1} className={classes.root}>
        <span className={classes.header}>{header}</span>
        {children}
    </Grid>
);

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :not(:first-child)': {
            marginLeft: '5px'
        }
    },
    header: {
        fontSize: '20px',
        marginBottom: '20px'
    }
});

export default withStyles(styles)(ModalStepSubHeader);
