import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from './TextField';
import Select from './Select';
import { CONTACT_AUS_STATES } from '../../page/contacts/contactConstants';

export default class CustomAddressModal extends Component {
    static defaultProps = {
        title: 'Enter address details',
        onClose: () => {}
    };

    state = {};

    getId() {
        return `CustomAddressModal-${this.props.title}`;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onBlur = e => {
        // sure it seems redundant, but actually we're gonna strip away naughty stray spaces.
        const val = (e.target.value || '').trim();
        this.setState({ [e.target.name]: val });
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();

        //clear the state
        this.setState({
            line1: null,
            line2: null,
            city: null,
            state: null,
            code: null,
            country: null
        });
    };

    onApply = () => {
        const { onApply, customAddressFields, form } = this.props;

        let address = {};
        if (customAddressFields) {
            Object.keys(customAddressFields)
                .filter(key => customAddressFields[key])
                .forEach(key => (address[customAddressFields[key]] = this.state[key] || null));
            if (form) {
                if (form.setField) {
                    form.setField({ ...address });
                } else {
                    form.setState({ ...address });
                }
            }
        }

        if (onApply) onApply(address);

        this.onClose();
    };

    render() {
        return (
            <Dialog open={this.props.open} aria-labelledby={this.getId()}>
                {this.renderForm()}
            </Dialog>
        );
    }

    renderForm = () => {
        const { title, customAddressFields } = this.props;
        if (!customAddressFields) return <div />;

        const required = ['line1', 'city', 'code']; // typically required.
        const disabled = !Object.keys(customAddressFields)
            .filter(key => required.indexOf(key) !== -1)
            .every(key => !!this.state[key]);

        return (
            <div>
                <DialogTitle id={this.getId()}>{title}</DialogTitle>
                <DialogContent>
                    {<span>Specific Location</span>}
                    {customAddressFields.line1 && this.renderField('line1', 'Address line 1 (required)')}
                    {customAddressFields.line2 && this.renderField('line2', 'Address line 2')}
                    {customAddressFields.city && this.renderField('city', 'Suburb/City (required)')}
                    {customAddressFields.state && this.renderField('state', 'State')}
                    {customAddressFields.code && this.renderField('code', 'Postcode (required)')}
                    {customAddressFields.country && this.renderField('country', 'Country (if not Australia)')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.onApply} color="primary" disabled={!!disabled}>
                        Ok
                    </Button>
                </DialogActions>
            </div>
        );
    };

    renderField(name, label, autoFocus, required) {
        if (name === 'state') {
            if (!this.state.country || this.state.country === 'Australia') {
                return (
                    <Select
                        label={label}
                        name={name}
                        required={required}
                        value={this.state[name] || ''}
                        onChange={this.onChange}
                        options={CONTACT_AUS_STATES}
                    />
                );
            }
        }
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        );
    }
}
