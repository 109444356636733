import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from '../icon/StarIcon';

export default withStyles({})(({ percent }) => {
    return (
        <div className="star-container">
            <div className="back-stars">
                <StarIcon filled="filled" className="star-icon grey" />
                <StarIcon filled="filled" className="star-icon grey" />
                <StarIcon filled="filled" className="star-icon grey" />
                <StarIcon filled="filled" className="star-icon grey" />
                <StarIcon filled="filled" className="star-icon grey" />
                <div className="front-stars" style={{ width: percent }}>
                    <StarIcon filled="filled" className="star-icon" />
                    <StarIcon filled="filled" className="star-icon" />
                    <StarIcon filled="filled" className="star-icon" />
                    <StarIcon filled="filled" className="star-icon" />
                    <StarIcon filled="filled" className="star-icon" />
                </div>
            </div>
        </div>
    );
});
