import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import SaveIcon from '../../../component/icon/SaveIcon';
import { compose } from 'react-apollo/index';
import Spinner from './Spinner';
import { deleteTypeName, getProperty, isNullOrUndefined, resetAllProperties, setProperty } from '../../../util/objects';
import PrimaryButton, { ModalPrimaryButton } from '../PrimaryButton';
import { gqlArr as gql } from '../../../util/graphql';
import { diff } from '../../../util/functions';
import cx from 'classnames';
import { removeById } from '../../../util/arrays';
import { enableValidation, validationUtility } from '../../../util/validation';
import Inline from '../../../page/workQueue2/extras/Inline';
import TickCircleIcon from '../../icon/TickCircleIcon';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';

/*
 * DataForm is used for saving DataObjects.
 *
 * @param data is typically the raw DataObject loaded by a GraphQL Query.
 * @param onLoad is a function to augment the DataObject so it can become form data with additional preset values.
 *   Each funeral tab has an additional onLoad.
 * @param mutation is the mutation gql. Without it, updateFuneral is assumed.
 * @param variables is the data to save. Remove form data here that is not on the DataObject.
 *   Without it, a funeral DataObject is assumed - funeral tabs use formatSaveData(differences, state) instead.
 * @param onSaved is a function to run after saving. Sends the returned DataObject (not the form data).
 *
 */

export const DataFormSaveMode = {
    currentTab: 0,
    currentFragment: 1,
    all: 2
};

class DataForm extends React.Component {
    original = {};
    state = {
        validation: validationUtility.createState()
    };
    loaded = {};
    isModified = false;

    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    componentWillMount() {
        this.updateFormState(this.props);
        this.unblock = this.props.history.block(this.onHistoryBlock);
    }

    componentWillReceiveProps(nextProps) {
        this.updateFormState(nextProps);
    }

    componentWillUnmount() {
        this.unblock();
    }

    getValidation = (fieldName, forceFieldNameAsKey = false, revalidate = false) => {
        return validationUtility.getValidationResult(fieldName, this.state.validation, forceFieldNameAsKey, revalidate);
    };

    validateForm = () => {
        let result = !isNullOrUndefined(this.props.validation)
            ? validationUtility.validate(this, this.props.validation, true)
            : validationUtility.validateTabs(this, this.tabLookup, true);
        this.generateForm();
        return result;
    };

    generateForm() {
        const { loading: queryLoading } = this.props;
        if (!this.form) {
            const form = {
                state: this.getTabState(),
                setState: this.setNestedState,
                getState: this.getNestedState,
                addToNestedArray: this.addToNestedArray,
                removeFromNestedArray: this.removeFromNestedArray,
                loading: queryLoading,
                validation: this.state.validation,
                getValidation: this.getValidation,
                validateForm: this.validateForm,
                invalidate: allTabs => this.invalidate(allTabs),
                save: callback => this.executeManualSave(callback),
                isModified: this.isModified,

                //legacy, for compatibility:
                validateField: () => {},
                setField: this.setNestedState,
                getField: this.getNestedState
            };
            this.form = form;
        } else {
            this.form.state = this.getTabState();
            this.form.loading = queryLoading;
            this.form.validation = this.state.validation;
            this.form.isModified = this.isModified;
        }
        return this.form;
    }

    getStateID(tabId) {
        const { saveMode, tab } = this.props;
        let stateID = false;
        switch (saveMode) {
            case DataFormSaveMode.currentFragment:
                if (tab !== undefined) {
                    if (tab.fragment.UniqueID === undefined) {
                        //ok, so we need a way to differentiate fragments, but there is no neat way to do this.
                        //lets use id of the first tab using this fragment as uniqueid
                        tab.fragment.UniqueID = tab.id;
                    }
                    stateID = tab.fragment.UniqueID;
                }
                break;
            case DataFormSaveMode.all:
                stateID = 'Combined';
                break;
            case DataFormSaveMode.currentTab:
            default:
                stateID = tabId || tab.id;
                break;
        }
        return stateID;
    }

    //gets the state relative to the current tab
    getTabState(tabId) {
        return this.state[this.getStateID(tabId)];
    }

    //sets the state specific to the current tab
    setTabState(state, tabId) {
        this.setState({ [this.getStateID(tabId)]: state });
    }

    //gets the original state relative to the current tab
    getOriginalState(tabId) {
        return this.original[this.getStateID(tabId)] || {};
    }

    //sets the original state specific to the current tab
    setOriginalState(state, tabId) {
        this.original[this.getStateID(tabId)] = state;
    }

    getNestedState = field => {
        return getProperty(this.getTabState(), field);
    };

    setNestedState = (newState, includeOriginal = false) => {
        const state = this.getTabState();
        for (let fieldName in newState) {
            setProperty(state, fieldName, newState[fieldName]);
        }
        this.setTabState(state);
        if (includeOriginal) this.setOriginalState(cloneDeep(state));
    };

    addToNestedArray = (item, arrayName, context) => {
        const array = this.getNestedState(arrayName);
        array.push(item);
        this.setNestedState({ [arrayName]: array }, true);
        this.verifyIsModified();
        context.forceUpdate();
    };

    removeFromNestedArray = (itemId, arrayName, context) => {
        const array = this.getNestedState(arrayName);
        removeById(array, itemId);
        this.setNestedState({ [arrayName]: array }, true);
        this.verifyIsModified();
        context.forceUpdate();
    };

    updateFormState(props) {
        const { id, tab, tabs, loading, data, onLoad, onChange, createNew } = props;
        const newState = {};
        this.generateForm();

        if (id !== this.props.id) {
            this.original = {};
            this.loaded = {};
            Object.keys(this.getTabState()).forEach(key => (newState[key] = undefined));
            Object.keys(tabs).forEach(key => (this.original[tabs[key].id] = {}));
        }
        if (!loading && data) {
            if (tab) {
                const stateID = this.getStateID(tab.id);
                if (stateID === 'Combined' && !this.loaded[tab.id]) {
                    this.loaded[tab.id] = true;
                    const original = deleteTypeName(cloneDeep(data));
                    Object.assign(original, this.getOriginalState());
                    if (onLoad) onLoad(original);
                    if (tab.onLoad) tab.onLoad(original);
                    if (onChange) onChange(this.form);

                    Object.assign(newState, cloneDeep(original), this.getTabState()); //loading new data and then overwriting one that already present

                    if (createNew === true) {
                        //clear all preset properties from original (if we have default parameters)
                        resetAllProperties(original);
                    }

                    this.setOriginalState(original, tab.id);
                } else if (!this.loaded[stateID]) {
                    this.loaded[stateID] = true;
                    const original = deleteTypeName(cloneDeep(data));
                    if (onLoad) onLoad(original);
                    if (tab.onLoad) tab.onLoad(original);
                    if (onChange) onChange(this.form);

                    Object.assign(newState, cloneDeep(original));

                    if (createNew !== true) {
                        this.setOriginalState(original, tab.id);
                    } else {
                        //clear all preset properties from original (if we have default parameters)
                        resetAllProperties(original);
                        this.setOriginalState(original, tab.id);
                    }
                    /*
          console.log(
            `${props.name} assigned orig for ${tab.id}`,
            { ...newState },
            { ...original }
          );
           */
                }
            } else {
                throw new Error('no tab/view supplied to dataform');
            }
        }

        if (!this.tabLookup) {
            this.tabLookup = tabs.reduce((acc, t) => {
                acc[t.id] = t;
                t.key = this.getStateID(t.id); //for compatibility with validation utility, which uses key
                return acc;
            }, {});
        }

        if (Object.keys(newState).length) {
            this.setTabState(newState, tab.id);
        }
    }

    /**
     * Copies over all ID fields from the src object to the tgt object.
     */
    mergeIds(src, tgt) {
        if (!src || !tgt) return;
        if (Array.isArray(src)) {
            src.forEach((_, i) => this.mergeIds(src[i], tgt[i]));
        } else if (typeof src === 'object') {
            //if (src.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (src.hasOwnProperty('ID') && !tgt.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (tgt.ID === 0 || tgt.ID === '0') tgt.ID = null; // remove ID = 0 so that new items can be saved
            Object.keys(src).forEach(k => this.mergeIds(src[k], tgt[k]));
        }
    }

    getMutation() {
        let input = cloneDeep(this.input);

        const { fragments, tabFragmentLookup /*, purgeCachedTypes*/ } = this.props;

        // Recurse all nested objects of the original and make sure IDs are included in the input.
        this.mergeIds(this.getOriginalState(), input);

        if (this.props.tab) {
            // Go through all modified keys to determine which tabs have been modified
            const modifiedTabs = {};
            const requiredFields = ['ID'];
            Object.keys(input).forEach(key => {
                if (requiredFields.includes(key)) return;
                const tabKeys = tabFragmentLookup[key] || [];
                tabKeys.forEach(k => {
                    if (this.tabLookup[k]) modifiedTabs[k] = true;
                    else
                        console.error(
                            'cannot find tab ',
                            k,
                            ' in tab collection ',
                            this.tabLookup,
                            '. Check the id property of the tab'
                        );
                });
            });
            this.modifiedTabKeys = Object.keys(modifiedTabs);

            this.modifiedTabKeys.forEach(k => {
                const { formatSaveData } = this.tabLookup[k];
                if (formatSaveData) formatSaveData(input, this.getTabState(), this.original);
            });

            // Build up all the list of required fragment parts and field names to query in the response
            const fieldNames = [];

            const fragmentParts = [];

            const related = [];

            //console.log('your input:', input, this.getOriginalState());
            Object.keys(input).forEach(key => {
                if (requiredFields.includes(key)) return;
                if (typeof input[key] === 'object' && input[key] !== null && input[key] !== undefined) {
                    // For object fields we find the matching fragment that selects that object
                    let fragment = fragments[key];

                    if (!fragment) {
                        //this is likely a nested fragment that needs to be resolved via the lookup
                        const tabNames = tabFragmentLookup[key];
                        if (isNullOrUndefined(tabNames)) {
                            console.log('undefined tabNames', key, tabFragmentLookup);
                            throw new Error('data form cannot resolve tab name from property in fragment lookup');
                        }

                        let identifiedFragment = null;
                        let nestedPropertyPaths = null;

                        if (tabNames.length === 1 && this.tabLookup[tabNames[0]]) {
                            identifiedFragment = this.tabLookup[tabNames[0]].fragment;
                        } else if (Array.isArray(input[key])) {
                            // prettier-ignore
                            for (let x = 0; x < tabNames.length &&
                            identifiedFragment === null; x++) {
                                const tabName = tabNames[x];
                                //console.log('searching ' + tabName + ' in fragments');
                                if (fragments[tabName])
                                    identifiedFragment = fragments[tabName];
                                else if (this.tabLookup[tabName])
                                    identifiedFragment = this.tabLookup[tabName].fragment;
                            }
                        } else {
                            //this input contains modified nested properties. find the appropriate fragment
                            nestedPropertyPaths = Object.keys(input[key]).map(x => `${key}_${x}`);

                            // prettier-ignore
                            for (let x = 0; x < tabNames.length &&
                            identifiedFragment === null; x++) {
                                const tabName = tabNames[x];

                                // prettier-ignore
                                for (let y = 0; y <
                                nestedPropertyPaths.length &&
                                identifiedFragment === null; y++) {
                                    const propertyPath = nestedPropertyPaths[y];
                                    const mappedTabName = tabFragmentLookup[propertyPath];
                                    if (mappedTabName) {
                                        identifiedFragment = fragments[tabName];
                                    }
                                }
                            }
                        }

                        //the data form failed to identify the tab, and subsequently the fragment that this nested property exists on
                        if (isNullOrUndefined(identifiedFragment)) {
                            console.log(
                                `data form '${this.props.name}' cannot resolve tab from property '${key}' in fragment lookup`
                            );
                            console.log('tabNames', tabNames);
                            console.log('this.tabLookup', this.tabLookup);
                            console.log('this.tabLookup[tabNames]', this.tabLookup[tabNames]);
                            console.log('tabFragmentLookup', tabFragmentLookup);
                            console.log('input', input);
                            console.log('fragments', fragments);
                            console.log('fragmentParts', fragmentParts);
                            console.log('nestedPropertyPaths', nestedPropertyPaths);
                            console.log('this.tabLookup[tabNames[0]]', this.tabLookup[tabNames[0]]);

                            throw new Error('data form cannot resolve tab from property in fragment lookup');
                        }

                        fragment = identifiedFragment;
                    }

                    fragmentParts.push(fragment);
                } else if (key.length > 2 && key.endsWith('ID')) {
                    //this is related. like ContactID. so we nest
                    const relatedFragment = key.substring(0, key.length - 2);
                    if (fragments[relatedFragment]) {
                        related.push(fragments[relatedFragment]);
                    } else {
                        const tabNames = tabFragmentLookup[relatedFragment];
                        const tabLookup = !!tabNames && this.tabLookup[tabNames[0]];
                        if (!!tabLookup) related.push(tabLookup.fragment);
                    }
                    //note: added this if condition to block from undefined nested fragments
                    //typically this works if your nested bits are defined separately, but sometimes
                    //they're defined inside the single fragment, which means they dont need to be spread
                } else {
                    fieldNames.push(key);
                }
            });

            let { mutationName, mutationInputType, createNew } = this.props;

            if (createNew && !input.ID) {
                mutationName = mutationName.replace('update', 'create');
                mutationInputType = mutationInputType.replace('Update', 'Create');
                if (this.props.idType && this.props.id) {
                    input[this.props.idType] = this.props.id;
                }
            }

            return {
                mutation: gql`

                mutation ${mutationName}($input: ${mutationInputType}!) {
                    ${mutationName}(input: $input) {
                        ${requiredFields.join('\n')}
                        ${fieldNames.join('\n')}
                        ${fragmentParts.map(f => '...' + f.definitions[0].name.value).join('\n')}
                        ${related.map(f => '...' + f.definitions[0].name.value).join('\n')}
                    }
                }
                ${fragmentParts}
                ${related}
        `,

                variables: {
                    input
                } /*,
                update: cache => {
                    console.dir({ ...cache, purgeCachedTypes });
                    purgeCachedTypes.forEach(type => {
                        Object.keys(cache.data.data).forEach(
                            key => key.match('/^' + type + '/') && cache.data.data.delete(key)
                        );
                    });
                    console.dir({ ...cache });
                }*/
            };
        }
    }

    onHistoryBlock = (location, action) => {
        const currentParts = this.props.history.location.pathname.split('/').filter(x => x.length > 0);

        const currentId = currentParts.length > 0 ? currentParts[currentParts.length - 1] : null;

        const targetParts = location.pathname.split('/').filter(x => x.length > 0);

        const targetId = targetParts.length > 0 ? targetParts[targetParts.length - 1] : null;

        // We don't block the navigation if we're navigate to another form page for the
        // same item, or if we haven't made any changes to the funeral
        if (!!this.isModified && currentId !== targetId) {
            if (!!this.props.historyBlockHandler) {
                this.locationCache = location;
                this.props.historyBlockHandler(this.onConfirm, this.onCancel);
                return false;
            } else {
                return 'You have unsaved changes, are you sure you want to leave this page?';
            }
        }

        //console.log('focused item id:', currentId, ' next:', targetId);
    };

    onConfirm = force => {
        if (force) this.unblock();
        this.props.history.push(this.locationCache.pathname);
    };

    onCancel = () => {
        this.locationCache = false;
    };

    onSave = e => {
        e.preventDefault();
        const { saveNotValid } = this.props;

        if (enableValidation) {
            const isValid = this.validateForm();

            if (!isValid) {
                this.forceUpdate();
                if (!saveNotValid) return;
            }
        }

        const { onBeforeSave } = this.props;
        if (!onBeforeSave || onBeforeSave(this)) {
            this.executeSave();
        }
    };

    executeSave(refetchQueries) {
        const { customClient, client } = this.props;
        const mutation = this.getMutation();
        if (!!refetchQueries) {
            mutation.refetchQueries = refetchQueries;
            mutation.awaitRefetchQueries = true;
        }
        (customClient || client)
            .mutate(mutation)
            .then(this.onSaveCompleted)
            .catch(this.handleMutateError);
        this.savePending = true;
        this.forceUpdate();
    }

    /**
     * if you need to call save from the form object, this will verify whether it can actually execute
     */
    executeManualSave(params) {
        const { callback, refetchQueries } = params || {};
        const { loading } = this.props;
        const hasData = Object.keys(this.getOriginalState()).length > 0;
        if (!hasData && loading) return;

        this.verifyIsModified();

        if (loading || this.savePending || !hasData || !this.isModified) return;

        if (!!callback) this.saveCallback = callback;

        this.executeSave(refetchQueries);
    }

    onSaveCompleted = ({ data }) => {
        const updated = Object.keys(data)[0];
        this.isModified = false;
        this.savePending = false;

        const { onSaved, onLoad, onChange } = this.props;

        if (!data[updated]) {
            console.log('warning! save function returned a null object!', data);
        }
        let original = deleteTypeName(cloneDeep(data[updated]));
        if (onLoad) onLoad(original);
        if (this.modifiedTabKeys && this.modifiedTabKeys.length) {
            this.modifiedTabKeys.forEach(tabKey => {
                const onLoadTab = this.tabLookup[tabKey].onLoad;
                if (onLoadTab) onLoadTab(original);
            });
        }

        const oldOriginal = this.getOriginalState();
        const newOriginal = { ...oldOriginal, ...original };

        this.setOriginalState(newOriginal);
        this.setTabState(cloneDeep(newOriginal));

        if (onSaved) {
            // console.log('sending to save func', { ...newOriginal });
            onSaved({ ...newOriginal });
        }

        if (onChange) {
            onChange(this.form);
        }

        if (!!this.saveCallback) {
            this.saveCallback();
            this.saveCallback = false;
        }
        this.props.setSnackbarMessage('Success, your changes have been saved.', true);
    };

    handleMutateError = e => {
        this.savePending = false;
        const { name, onGqlError } = this.props;
        if (onGqlError) onGqlError(e, `Mutation failed for '${name}'`);
        else console.error('gql error', e);
        this.forceUpdate();
    };

    render() {
        const { children, error, loading: queryLoading, data, readOnly, createNew } = this.props;
        if (error) {
            console.error(error);
            return <div>Error loading data.</div>;
        }

        if (!queryLoading && !data && !createNew) {
            return <div>Data source not found.</div>;
        }

        const button =
            readOnly === true || queryLoading === true
                ? null
                : isNullOrUndefined(this.props.onStepperNext)
                ? this.renderAllButtons()
                : this.renderStepperNext();

        return <Fragment>{children(this.generateForm(), button)}</Fragment>;
    }

    invalidate(allTabs) {
        const { tab, parentDataForm, context, name } = this.props;
        //console.log(`invalidating ${name}`);

        //console.log(`invalidated ${tab.id}`);
        if (!!allTabs) {
            for (let tabID in this.loaded) {
                if (this.loaded.hasOwnProperty(tabID)) {
                    this.loaded[tabID] = false;
                }
            }
        } else {
            const stateID = this.getStateID(tab.id);
            if (stateID === 'Combined' && !this.loaded[tab.id]) {
                this.loaded[tab.id] = false; //unload the tab so it can get loaded again before next render
            } else {
                this.loaded[stateID] = false; //unload the tab so it can get loaded again before next render
            }
        }

        if (parentDataForm) {
            parentDataForm.invalidate(); //traverse to the parent and update
        } else {
            if (!context) console.error(`${name} needs context={this}, in order to trigger a re-render`);

            context.forceUpdate(); //force the context to rerender so the tab gets loaded
        }
    }

    renderSave() {
        const { classes, loading, validate, buttonStyles } = this.props;

        const hasData = Object.keys(this.getOriginalState()).length > 0;
        if (!hasData && loading) return;

        this.verifyIsModified();

        const isValid = isNullOrUndefined(validate) || validate();

        const disabled = loading || this.savePending || !hasData || !this.isModified || !isValid;

        return [
            <ModalPrimaryButton
                className={cx(classes.button, buttonStyles)}
                onClick={disabled ? undefined : e => this.onSave(e)}
                disabled={disabled}
            >
                {this.renderButtonState(disabled)}
            </ModalPrimaryButton>,
            !disabled
        ];
    }

    renderAllButtons() {
        const { additionalActions, readOnly, buttonContainerStyles } = this.props;

        const saveButton = readOnly ? [null, true] : this.renderSave();

        if (additionalActions && additionalActions.length) {
            return (
                <Inline className={buttonContainerStyles}>
                    {additionalActions.map((action, i) => {
                        if (!action.component) action.component = PrimaryButton;
                        return (
                            <action.component
                                key={'action' + i}
                                onClick={() => action.onClick()}
                                disabled={
                                    (!action.skipSave && !!saveButton[1]) || !!action.disabled || this.savePending
                                }
                            >
                                {action.label}
                            </action.component>
                        );
                    })}
                    {saveButton[0]}
                </Inline>
            );
        } else {
            return <Inline className={buttonContainerStyles}>{saveButton[0]}</Inline>;
        }
    }

    renderStepperNext() {
        const { loading, validate, classes, buttonStyles } = this.props;

        const hasData = Object.keys(this.getOriginalState()).length > 0;
        if (!hasData && loading) return;

        this.verifyIsModified();

        const isValid = isNullOrUndefined(validate) || validate();

        return (
            <ModalPrimaryButton
                className={cx(classes.button, buttonStyles)}
                onClick={e => this.onStepperNext(e)}
                disabled={!isValid}
            >
                {this.renderButtonState(!isValid)}
            </ModalPrimaryButton>
        );
    }

    onStepperNext(e) {
        const { onStepperNext, loading } = this.props;

        const hasData = Object.keys(this.getOriginalState()).length > 0;
        const disabled = loading || this.savePending || !hasData || !this.isModified;

        if (!disabled) {
            this.onSave(e);
        }
        onStepperNext();
    }

    verifyIsModified() {
        const { onChange } = this.props;
        // Recalculate the modified state
        this.input = diff(this.getTabState(), this.getOriginalState(), false);

        if (onChange) {
            if (this.last_input === undefined || this.input === undefined) {
                this.last_input = this.input;
            } else {
                if (diff(this.input, this.last_input, false) !== {}) {
                    // TODO: fix this junk
                    onChange(this.form);
                    this.last_input = cloneDeep(this.input);
                }
            }
        }

        // console.log(Object.keys(this.input));
        this.isModified = Object.keys(this.input).length;
    }

    renderButtonState(disabled) {
        const { classes, buttonLabels } = this.props;
        const { isSaving, isUnsaved, isNew, isSaved } = buttonLabels || {};

        let icon;
        let style;
        if (disabled) {
            style = { color: 'rgba(0, 0, 0, 0.26)', fill: 'rgba(0, 0, 0, 0.26)' };
            icon = <TickCircleIcon fontSize="inherit" style={style} />;
        } else {
            style = {};
            icon = <SaveIcon fontSize="inherit" style={style} />;
        }

        if (this.savePending) {
            return (
                <Fragment>
                    <Spinner />
                    <Typography variant="button" className={classes.saveButtonTypography} style={style}>
                        {isSaving || 'Saving...'}
                    </Typography>
                </Fragment>
            );
        } else if (Object.keys(this.getOriginalState()).length === 0) {
            return (
                <Fragment>
                    {icon}
                    <Typography variant="button" className={classes.saveButtonTypography} style={style}>
                        {isNew || 'Save New'}
                    </Typography>
                </Fragment>
            );
        } else if (this.isModified) {
            return (
                <Fragment>
                    {icon}
                    <Typography variant="button" className={classes.saveButtonTypography} style={style}>
                        {isUnsaved || 'Save Changes'}
                    </Typography>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {icon}
                <Typography variant="button" className={classes.saveButtonTypography} style={style}>
                    {isSaved || 'Saved'}
                </Typography>
            </Fragment>
        );
    }
}

const styles = ({ zIndex, palette, breakpoints }) => ({
    button: {
        border: '1px solid' + palette.button.save,
        borderRadius: 24,
        color: '#ffffff',
        zIndex: zIndex.drawer + 2,
        minWidth: 160,
        minHeight: 36,
        padding: '0 10px;',
        boxShadow: 'none !important',
        height: '15px',
        //marginTop: '10px',
        backgroundColor: palette.button.save,
        textTransform: 'none',
        '& span > svg': {
            height: '20px',
            width: '20px',
            fill: '#67F7A6'
        },
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.button.save
        }
    },
    buttonIcon: {
        // marginRight: 8
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    saveButtonTypography: {
        color: '#FFFFFF',
        textTransform: 'capitalize'
    }
});

export default compose(withApollo, withRouter, withSnackbarMessage, withStyles(styles))(DataForm);
