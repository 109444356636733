import gql from 'graphql-tag';

export default gql`
    fragment FuneralDressing on Funeral {
        FuneralDressing {
            ID
            Status
            Date
            Time
            Received
            GreekShroud
            Shroud
            Comment
        }
    }
`;
