import { Clergy, Disposal, Grave, PlaceOfService, PlaceOfViewingItems } from '../PrearrangementFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment PrearrangementDetailsFields on Prearrangement {
                DateOfService
                WebcastRequired
                DetailsChangesFromPrePlan
            }
        `,
        Clergy,
        Disposal,
        Grave,
        PlaceOfService,
        PlaceOfViewingItems
    ],
    'Prearrangement'
);
