import gql from 'graphql-tag';

export default gql`
    fragment AshesDetail on Funeral {
        AshesDetail {
            ID
            FamilyHasReservation
            Comment
            Crematorium {
                ID
                Name
                State
                Suburb
                Postcode
                AddressLine1
                AddressLine2
                ClergyType
                Notes
                Phone
                Fax
                Mobile
                Email
                AddressBookCategory
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementAshesDetail on Prearrangement {
        AshesDetail {
            ID
            FamilyHasReservation
            Comment
            Crematorium {
                ID
                Name
                State
                Suburb
                Postcode
                AddressLine1
                AddressLine2
                ClergyType
                Notes
                Phone
                Fax
                Mobile
                Email
                AddressBookCategory
            }
        }
    }
`;
