import React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { compose } from 'react-apollo';

class SortDropdown extends React.Component {
    handleChange = event => {
        this.props.setSortField(event.target.value);
    };

    options = [
        { field: 'LastEdited', direction: 'DESC', label: 'Recently updated' },
        { field: 'Created', direction: 'DESC', label: 'Recently created' },
        { field: 'Name', direction: 'ASC', label: 'Business Name' },
        { field: 'Contact', direction: 'ASC', label: 'Contact Person' }
    ];

    render() {
        const { classes, sortField, sortDirection } = this.props;
        const selected = this.options.find(obj => {
            return obj.field === sortField;
        });
        return (
            <Select
                value={sortField + sortDirection}
                onChange={this.handleChange}
                displayEmpty
                name="sortField"
                className={classes.select}
            >
                {selected && (
                    <MenuItem value={sortField + (selected.direction === 'ASC' ? 'DESC' : 'ASC')}>
                        {selected.label} (reverse)
                    </MenuItem>
                )}
                {this.options.map((obj, i) => {
                    return (
                        <MenuItem key={i} value={obj.field + obj.direction}>
                            {obj.label}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
}

const styles = ({ typography }) => ({
    select: {
        minWidth: 150,
        width: 200,
        marginBottom: 20
    }
});

export default compose(
    withRouter,
    withStyles(styles)
)(SortDropdown);
