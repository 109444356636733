import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const ModalStepTitle = ({ classes, header, number, title, children }) => (
    <span className={classes.root}>
        <span>
            <Typography variant="display1" className={classes.number}>
                {number ? `Step ${number}:` : header}
            </Typography>
            <Typography variant="display1" className={classes.title}>
                {title}
            </Typography>
        </span>
        {children}
    </span>
);

const styles = ({ palette, typography }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        width: '100%',
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :not(:first-child)': {
            marginLeft: '5px'
        }
    },

    number: {
        display: 'inline-block',
        color: palette.primary.main,
        textTransform: 'capitalize',
        marginRight: 12
    },

    title: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        marginBottom: 24
    }
});

export default withStyles(styles)(ModalStepTitle);
