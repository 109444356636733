export const CONTACT_CATEGORY_OPTIONS = [
    { label: 'Billing', value: 'BILLING' },
    { label: 'Charity', value: 'CHARITY' },
    { label: 'Clergy / Celebrant', value: 'CLERGY' },
    { label: 'Doctor', value: 'DOCTOR' },
    { label: 'Florist', value: 'FLORIST' },
    { label: 'Funeral fund', value: 'FUNERAL FUND' },
    { label: 'Hospital / Nursing home', value: 'HOSPITAL/NURSING HOME' },
    { label: 'Miscellaneous', value: 'MISCELLANEOUS' },
    { label: 'Place of committal', value: 'PLACE OF COMMITTAL' },
    { label: 'Place of service', value: 'PLACE OF SERVICE' },
    { label: 'Support services', value: 'SUPPORT SERVICES' },
    { label: 'Catering', value: 'CATERING' }
];

export const CONTACT_CLERGY_OPTIONS = [
    { label: 'Anglican', value: '8' },
    { label: 'Baptist', value: '3' },
    { label: 'Catholic', value: '5' },
    { label: 'Christian (other)', value: '9' },
    { label: 'Hindu', value: '7' },
    { label: 'Orthodox', value: '12' },
    { label: 'Other religions', value: '11' },
    { label: 'Presbyterian', value: '6' },
    { label: 'Salvation Army', value: '10' },
    { label: 'Uniting', value: '4' },
    { label: 'Secular (male)', value: '1' },
    { label: 'Secular (female)', value: '2' }
];

export const CONTACT_AUS_STATES = [
    { value: 'NSW', label: 'New South Wales' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
];

/**
 * @return {boolean}
 */
export function CONTACT_CLERGY_CHRISTIAN(whom) {
    return [5, 12, 3, 4, 6, 8, 9, 10].indexOf(Number(whom)) > -1;
}

/**
 * @return {boolean}
 */
export function CONTACT_CLERGY_RELIGIOUS(whom) {
    if (!whom) return false;
    return !([1, 2].indexOf(Number(whom)) > -1);
}
