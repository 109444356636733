import gql from 'graphql-tag';
import { OrdersFragmentInclude } from './Bookings';

export default gql`
    fragment NewspaperNotice on Funeral {
        NewspaperNotices {
            ${OrdersFragmentInclude}
        }
    }
`;
