import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles, withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import Fragment from '@material-ui/core/Hidden';
import blue from '@material-ui/core/colors/blue';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CalendarIcon, ContactsIcon, PayableIcon, SearchIcon, StarIcon } from '../IconIndex';
import { clearToken, getUser } from '../../util/sessions';
import cx from 'classnames';
import EditContactModal from '../EditContactModal';
import NotificationsPopover from '../../page/workQueue/notifications/NotificationPopover';
import CreateEditTaskModal from '../../page/workQueue/taskModal/CreateEditTaskModal';
import { createOrUpdateTaskFunc } from '../../page/workQueue/components/GetSaveTasks';
import AppointmentPopover from '../../page/calendar/AppointmentPopover';
import GatedComponent from '../GatedComponent';
import { resetClient } from '../../apollo';
import { setSnackbarMessage } from '../../context/SnackbarMessage';
import TaskModal from '../../page/workQueue2/Modals/TaskModal';
import Fab from '@material-ui/core/Fab';
import { joinDefined } from '../../util/strings';
import { CapsuleLegend } from '../form/Legend';
import { compose } from 'react-apollo';
import { getOffices } from '../../environment';
// import TransfersIcon from '../icon/TransfersIcon';
// import NewTransferModal from '../../page/transfers/NewTransferModal';

class HeaderActions extends Component {
    state = {
        addressBookModalOpen: false,
        appointmentModalOpen: false,
        newTransferModalOpen: false,
        showCreateEditTaskModal: false,
        showNewTaskModal: false,
        userMenuAnchor: undefined,
        addMenuAnchor: undefined
    };

    onToggleUserMenu = (e = {}) => {
        this.setState({ userMenuAnchor: this.state.userMenuAnchor ? undefined : e.currentTarget });
    };

    onToggleAddMenu = (e = {}) => {
        this.setState({ addMenuAnchor: this.state.addMenuAnchor ? undefined : e.currentTarget });
    };

    onClickProfile = () => {
        this.onToggleUserMenu();
        this.props.history.push('/account');
    };

    onClickLogout = () => {
        this.onToggleUserMenu();
        clearToken();
        resetClient();
        setSnackbarMessage('You have signed out.', true);
    };

    onCloseNewContact = () => {
        this.setState({ addressBookModalOpen: false });
    };

    render() {
        const { classes } = this.props;
        const { addressBookModalOpen } = this.state;
        return (
            <GatedComponent isEnabledCode={'OBJ_EDIT_ALL'}>
                {(canAccess, data) => {
                    const { readCurrentUser } = data;
                    const allOffices = getOffices();
                    const myOffice =
                        readCurrentUser.MyLastOfficeID && allOffices.find(e => e.ID === readCurrentUser.MyLastOfficeID);

                    return (
                        <div className={classes.headerBar}>
                            {!!canAccess && this.renderSearchButton()}
                            {!!canAccess && this.renderNotificationsButton()}
                            {!!canAccess && this.renderAddButton(myOffice)}
                            {this.renderUserButton(readCurrentUser, myOffice)}
                            {this.renderUserMenu(readCurrentUser, canAccess)}
                            {!!canAccess && this.renderAddMenu(myOffice)}
                            {!!canAccess && (
                                <Fragment>
                                    <EditContactModal open={!!addressBookModalOpen} onClose={this.onCloseNewContact} />
                                    <CreateEditTaskModal
                                        onClose={() => this.setState({ showCreateEditTaskModal: false })}
                                        open={this.state.showCreateEditTaskModal}
                                        onSubmit={task => createOrUpdateTaskFunc(task, false)}
                                        user={getUser()}
                                    />
                                    <TaskModal
                                        open={this.state.showNewTaskModal}
                                        onClose={() => this.setState({ showNewTaskModal: false })}
                                    />
                                    {/*<NewTransferModal
                                        open={!!this.state.newTransferModalOpen}
                                        onClose={() => this.setState({ newTransferModalOpen: false })}
                                    />*/}
                                    <AppointmentPopover
                                        open={!!this.state.appointmentModalOpen}
                                        onClose={() =>
                                            this.setState({
                                                appointmentModalOpen: false,
                                                editAppointmentId: null
                                            })
                                        }
                                        defaults={{ Office: myOffice ? myOffice.LegacyKey : null }}
                                        allowLocation={true}
                                        allowRecurring={true}
                                    />
                                </Fragment>
                            )}
                        </div>
                    );
                }}
            </GatedComponent>
        );
    }

    renderAddButton(office) {
        const { classes, theme } = this.props;
        const fgColour = office ? theme.palette.contentForeground[office.RegistrantSuffix] : '#35327c';
        const bgColour = office ? theme.palette.contentBackground[office.RegistrantSuffix] : '#DCE4F0';
        return (
            <Fragment>
                <Hidden xsDown>
                    <CapsuleLegend
                        onClick={this.onToggleAddMenu}
                        className={classes.capsuleLegend}
                        text={
                            <span>
                                <span style={{ margin: '0 6px' }}>
                                    <Hidden xsDown> Add New </Hidden>
                                </span>
                                <Fab
                                    title={'Add New'}
                                    color="primary"
                                    style={{ backgroundColor: fgColour }}
                                    aria-label="add new"
                                    className={cx(classes.addButtonDesktop, classes.marginLeft)}
                                    classes={{ label: classes.addButtonLabel }}
                                    onClick={this.onToggleAddMenu}
                                >
                                    <Icon>add</Icon>
                                    <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
                                </Fab>
                            </span>
                        }
                        foreground={fgColour}
                        background={bgColour}
                    />
                </Hidden>
                <Hidden smUp>
                    <Fab color="primary" className={classes.addButtonMobile} onClick={this.onToggleAddMenu}>
                        <Icon>add</Icon>
                        <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
                    </Fab>
                </Hidden>
            </Fragment>
        );
    }

    renderNotificationsButton() {
        return <NotificationsPopover />;
    }

    renderUserButton(readCurrentUser, office) {
        const { classes, theme } = this.props;
        const profileName =
            joinDefined(
                [
                    readCurrentUser.FirstName ? readCurrentUser.FirstName.charAt(0) : '',
                    readCurrentUser.Surname ? readCurrentUser.Surname.charAt(0) : ''
                ],
                ''
            ) || '?';

        const fgColour = office ? theme.palette.contentForeground[office.RegistrantSuffix] : '#35327c';
        const bgColour = office ? theme.palette.contentBackground[office.RegistrantSuffix] : '#DCE4F0';

        if (office)
            return (
                <CapsuleLegend
                    onClick={this.onToggleUserMenu}
                    className={classes.capsuleLegend}
                    text={
                        <span>
                            <span style={{ margin: '0 6px' }}>
                                <Hidden xsDown>{office && office.Title}</Hidden>
                                <Hidden smUp>{office && office.RegistrantSuffix}</Hidden>
                            </span>
                            <Fab
                                title={joinDefined([readCurrentUser.FirstName, readCurrentUser.Surname], ' ')}
                                color="primary"
                                aria-label="user settings"
                                className={cx(classes.userButton, classes.marginLeft)}
                                classes={{ label: classes.userButtonLabel }}
                                onClick={this.onToggleUserMenu}
                            >
                                {profileName}
                                <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
                            </Fab>
                        </span>
                    }
                    foreground={fgColour}
                    background={bgColour}
                />
            );
        return (
            <Fab
                title={joinDefined([readCurrentUser.FirstName, readCurrentUser.Surname], ' ')}
                color="primary"
                aria-label="user settings"
                className={cx(classes.userButton, classes.marginLeft)}
                classes={{ label: classes.userButtonLabel }}
                onClick={this.onToggleUserMenu}
            >
                {profileName}
                <Icon className={classes.userArrow}>keyboard_arrow_down</Icon>
            </Fab>
        );
    }

    renderSearchButton() {
        const { classes, onToggleSearch } = this.props;
        return (
            <Hidden smUp>
                <div className={classes.searchWrapper}>
                    <IconButton className={classes.iconButton} onClick={onToggleSearch}>
                        <SearchIcon className={classes.searchIcon} />
                    </IconButton>
                </div>
            </Hidden>
        );
    }

    renderUserMenu(readCurrentUser, canAccess) {
        const anchorEl = this.state.userMenuAnchor;

        return (
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={this.onToggleUserMenu}
                transformOrigin={{ vertical: -68, horizontal: 'right' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem style={{ paddingTop: 24, outline: 0 }} button={false}>
                    <span>
                        {readCurrentUser.FirstName} {readCurrentUser.Surname}
                        <br />
                        <small style={{ opacity: 0.5 }}>{readCurrentUser.Email}</small>
                    </span>
                </MenuItem>
                {!!canAccess && <MenuItem onClick={this.onClickProfile}>Account Settings</MenuItem>}
                <MenuItem onClick={this.onClickLogout}>Sign Out</MenuItem>
            </Menu>
        );
    }

    renderAddMenu(office) {
        const { classes } = this.props;
        const anchorEl = this.state.addMenuAnchor;
        if (!office) {
            office = getOffices()[0];
        }
        return (
            <Menu
                id="add-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={this.onToggleAddMenu}
                transformOrigin={{ vertical: -64, horizontal: 'right' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <GatedComponent showComponentCode={'FM_ACCESS_Enquiries_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                className="targetNewPage"
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    const newPath = `/quotes/enquiry/${office.RegistrantSuffix}/0`;
                                    let win = window.open(newPath, '_blank');
                                    win.focus();
                                }}
                            >
                                <PayableIcon className={classes.newIcon} />
                                Enquiry
                            </MenuItem>
                        );
                    }}
                </GatedComponent>

                {/* <GatedComponent showComponentCode={'FM_ACCESS_Enquiries_Create'}>
                    {() => {
                        return (
                            <MenuItem onClick={() => this.setState({ newTransferModalOpen: true })}>
                                <TransfersIcon className={classes.newIcon} />
                                Transfer...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>*/}

                <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    this.setState({ addressBookModalOpen: true });
                                }}
                            >
                                <ContactsIcon className={classes.newIcon} /> Contact...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
                {1 === 2 && (
                    <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Create'}>
                        {() => {
                            return (
                                <MenuItem
                                    onClick={() =>
                                        this.setState({ showCreateEditTaskModal: true }, () => this.onToggleAddMenu())
                                    }
                                >
                                    <StarIcon className={classes.newIcon} /> Task...
                                </MenuItem>
                            );
                        }}
                    </GatedComponent>
                )}
                <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => this.setState({ showNewTaskModal: true }, () => this.onToggleAddMenu())}
                            >
                                <StarIcon className={classes.newIcon} /> Task...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
                <GatedComponent showComponentCode={'FM_ACCESS_Appointments_Create'}>
                    {() => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    this.onToggleAddMenu();
                                    this.setState({ appointmentModalOpen: true });
                                }}
                            >
                                <CalendarIcon className={classes.newIcon} />
                                Appointment...
                            </MenuItem>
                        );
                    }}
                </GatedComponent>
            </Menu>
        );
    }
}

const styles = ({ spacing, breakpoints, palette }) => ({
    addButtonDesktop: {
        width: 40,
        height: 40,
        boxShadow: 'none',
        flex: '0 0 auto'
    },
    addButtonMobile: {
        width: 40,
        height: 40,
        boxShadow: 'none',
        flex: '0 0 auto'
    },
    addButtonLabel: {
        fontSize: '1rem'
    },
    iconButton: {
        flex: '0 0 auto'
    },
    userButton: {
        backgroundColor: blue[200],
        width: 40,
        height: 40,
        boxShadow: 'none',
        flex: '0 0 auto'
    },
    userButtonLabel: {
        fontSize: '1rem'
    },
    searchIcon: {
        fontSize: 16,
        color: palette.basePrimary['none']
    },
    userArrow: {
        position: 'absolute',
        color: palette.common.black,
        right: -12,
        bottom: -8,
        fontSize: 16
    },
    newIcon: {
        fontSize: 16,
        marginRight: 16,
        color: palette.basePrimary['none']
    },
    capsuleLegend: {
        display: 'inline-block',
        position: 'relative',
        whiteSpace: 'pre',
        padding: 2,
        width: 'unset',
        cursor: 'pointer'
    },
    searchWrapper: {
        flexGrow: 1,
        paddingLeft: spacing.unit * 2
    },
    headerBar: {
        display: 'flex',
        gap: spacing.unit * 2 + 'px',
        alignItems: 'center',
        justifyContent: 'end',
        [breakpoints.down('xs')]: {
            width: '100%'
        }
    }
});

export default compose(withStyles(styles), withTheme(), withRouter)(HeaderActions);
