import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SortDropdown from './SortDropdown';
import { compose, Query } from 'react-apollo';
import ContactCard from './ContactCard';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import ContactsFilters from './ContactsFilters';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import { prettyNumber } from '../../util/strings';
import PrimaryButton from '../../component/form/PrimaryButton';
import Icon from '@material-ui/core/Icon/Icon';
import TextField from '@material-ui/core/TextField/TextField';
import LinkButton from '../../component/form/LinkButton';
import CloseIcon from '../../component/icon/CloseIcon';
import { LinearProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getUtilitiesClient } from '../../apollo';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class Contacts extends React.Component {
    constructor(props) {
        super(props);
        const urlSearch = props.match.params.search;

        this.state = {
            searchKeyword:
                (!!urlSearch && typeof urlSearch !== undefined && decodeURIComponent(urlSearch).trim()) || '',
            contacts: [],
            total: 0,
            offset: 0,
            limit: 96,
            sortField: 'LastEdited',
            sortDirection: 'DESC',
            filterCategories: [], //array of Object {value: STRING}
            filterClergies: [], //array of Object {value: STRING}
            activeOnly: true
        };
    }

    setFilters = ({ categories, clergies, activeOnly }) => {
        this.setState({ filterCategories: categories, filterClergies: clergies, activeOnly: activeOnly, offset: 0 });
    };

    setSortField = newSortField => {
        const newState = { sortField: null, sortDirection: null };
        newState.sortField = newSortField.replace(/(ASC|DESC)/, '');
        newState.sortDirection = newSortField.replace(/.*(ASC|DESC)/, '$1');
        newState.offset = 0;
        this.setState(newState);
    };

    render() {
        const variables = {
            limit: this.state.limit,
            offset: this.state.offset,
            contains: this.state.searchKeyword,
            sortBy: [{ field: this.state.sortField, direction: this.state.sortDirection }],
            filterCategories: this.state.filterCategories,
            filterClergies: this.state.filterClergies,
            activeOnly: this.state.activeOnly
        };
        // TODO: find a better alternative to fetchPolicy for when a contact is updated.
        return (
            <Fragment>
                {this.renderHeader()}
                <Query query={query} client={getUtilitiesClient()} variables={variables} notifyOnNetworkStatusChange>
                    {results => this.renderList(results)}
                </Query>
            </Fragment>
        );
    }

    renderList = ({ loading, error, data, fetchMore, networkStatus }) => {
        if (error) return 'Error loading contacts data';
        if (loading && networkStatus === 1)
            return (
                <div>
                    Loading data, please wait a moment...
                    <LinearProgress />
                </div>
            );

        const edges = data && data.readAddressBooks && data.readAddressBooks.edges;
        const total = edges && data.readAddressBooks.pageInfo.totalCount;
        const contacts = edges && edges.length && edges.map(e => e.node);
        const contactsArray = contacts || [];
        const busy = loading || networkStatus !== 7;

        let more = 'End of results.';
        if (edges && edges.length + this.state.offset < total) {
            more = (
                <PrimaryButton
                    disabled={busy}
                    onClick={() =>
                        /*this.setState({ offset: edges.length + this.state.offset })*/
                        fetchMore({
                            variables: { offset: edges.length },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;

                                const newObj = Object.assign(
                                    {},
                                    /*...prev,*/ {
                                        readAddressBooks: {
                                            edges: [
                                                ...prev.readAddressBooks.edges,
                                                ...fetchMoreResult.readAddressBooks.edges
                                            ],
                                            __typename: 'readAddressBooksConnection', // required, to prevent death
                                            pageInfo: fetchMoreResult.readAddressBooks.pageInfo
                                        }
                                    }
                                );
                                return newObj;
                            }
                        })
                    }
                >
                    {busy ? 'Loading...' : 'Load more results'}
                </PrimaryButton>
            );
        }

        const opacity = busy ? 0.5 : 1;
        return (
            <div style={{ margin: 0 }}>
                {this.renderTotalCount(total, busy)}
                <div style={{ opacity: opacity }}>
                    <div className={this.props.classes.gridContainer}>
                        {contactsArray.map(contact => {
                            return this.renderItem(contact);
                        })}
                    </div>
                </div>
                <div style={{ textAlign: 'center', padding: 24 }}>{total > 0 && more}</div>
            </div>
        );
    };

    renderItem = contact => {
        return (
            <div key={contact.ID} className={this.props.classes.gridItem}>
                <ContactCard contact={contact} />
            </div>
        );
    };

    isFilterOn() {
        return (
            this.state.searchKeyword ||
            !this.state.activeOnly ||
            this.state.filterCategories.length ||
            this.state.filterClergies.length
        );
    }

    renderTotalCount(total, busy) {
        // if (!total) return <div>No results.</div>;
        const { classes, history } = this.props;
        const empty = this.isFilterOn();
        return (
            <Typography className={classes.total} gutterBottom>
                {busy ? (
                    'Loading...'
                ) : (
                    <Fragment>
                        {prettyNumber(total)} contacts found.
                        {!total && <span> Please try different keywords and filters.</span>}
                        {total > this.state.limit && !!empty && (
                            <span> Consider adding some keywords or filters to reduce your results.</span>
                        )}
                        {!!empty && (
                            <span style={{ paddingLeft: 24 }}>
                                <LinkButton
                                    onClick={() => {
                                        this.setFilters({ categories: [], clergies: [], activeOnly: true });
                                        this.searchFieldInput.value = '';
                                        this.setState({ searchKeyword: '' });
                                        history.push('/contacts');
                                    }}
                                    text={'Clear filters'}
                                >
                                    <CloseIcon className={classes.closeIcon} />
                                </LinkButton>
                            </span>
                        )}
                    </Fragment>
                )}
            </Typography>
        );
    }

    renderHeader() {
        const { classes } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        <div style={{ display: 'inline-block' }}>Contacts</div>
                        {this.renderSearchForm()}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InlineFieldRightAlignChildren lineHeight={2.35}>
                        <div />
                        <Typography variant="subheading" className={classes.blockRight}>
                            Sort by:
                        </Typography>
                        <SortDropdown
                            sortField={this.state.sortField}
                            sortDirection={this.state.sortDirection}
                            setSortField={this.setSortField}
                        />
                        <ContactsFilters
                            setFilters={this.setFilters}
                            filters={{
                                categories: this.state.filterCategories,
                                clergies: this.state.filterClergies,
                                activeOnly: this.state.activeOnly
                            }}
                        />
                    </InlineFieldRightAlignChildren>
                </Grid>
            </Grid>
        );
    }

    onSearchFieldRef = input => {
        this.searchFieldInput = input;
    };

    onSearchSubmit = e => {
        if (e) e.preventDefault();
        this.setState({ searchKeyword: this.searchFieldInput.value, offset: 0 });
        const { history } = this.props;
        history.push('/contacts/' + encodeURIComponent(this.searchFieldInput.value));
    };

    renderSearchForm() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.onSearchSubmit} className={classes.searchForm} style={{ minWidth: 200 }}>
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: classes.searchField,
                            focused: classes.searchFieldFocused
                        },
                        inputProps: {
                            ref: this.onSearchFieldRef
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <PrimaryButton
                                    onClick={this.onSearchSubmit}
                                    style={{
                                        borderRadius: '0 3px 3px 0',
                                        //margin: '0 0 0 -44px',
                                        padding: '0 1rem',
                                        border: '1px solid transparent',
                                        height: 39,
                                        minWidth: 39,
                                        width: 44
                                        // position: 'absolute'
                                    }}
                                >
                                    <Icon>search</Icon>
                                </PrimaryButton>
                            </InputAdornment>
                        )
                    }}
                    defaultValue={this.state.searchKeyword}
                    placeholder="Filter contacts by keyword"
                    fullWidth={true}
                />
            </form>
        );
    }
}

const query = gql`
    query(
        $contains: String
        $limit: Int
        $offset: Int
        $sortBy: [SortField]
        $filterCategories: [String]
        $filterClergies: [String]
        $activeOnly: Boolean
    ) {
        readAddressBooks(
            contains: $contains
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            filterCategories: $filterCategories
            filterClergies: $filterClergies
            activeOnly: $activeOnly
        ) {
            edges {
                node {
                    ID
                    Name
                    Contact
                    Email
                    Phone
                    Fax
                    Mobile
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    AddressBookCategory
                    ClergyCategory
                    ClergyType
                    Religion
                    Notes
                    ClergyBaseRate
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                    NotCurrent
                    RegistrationNoMisc
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                totalCount
            }
        }
    }
`;

const styles = ({ typography, palette, transitions, breakpoints }) => ({
    pageTitle: {
        color: palette.contentForeground['none']
    },
    blockLeft: {
        float: 'left'
    },
    blockRight: {
        float: 'right',
        marginTop: 5,
        whiteSpace: 'pre'
    },
    button: {
        marginRight: '10px',
        boxShadow: 'none',
        borderRadius: '18px'
    },
    addNewButton: {
        backgroundColor: 'white',
        marginRight: '0'
    },
    sortDropdownHolder: {
        float: 'left'
    },
    searchForm: {
        position: 'relative',
        width: 'calc(100% - 7rem)',
        float: 'right'
    },
    searchField: {
        backgroundColor: palette.common.white,
        borderRadius: 3,
        border: `1px solid ${palette.grey.A100}`,
        padding: '2px 8px',
        paddingRight: 0,
        transition: transitions.create(['border-color', 'box-shadow']),
        '& > input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    searchFieldFocused: {
        borderColor: palette.primary.light,
        boxShadow: `0 0 0 0.1rem rgba(65, 59, 190,.25)` // active color in RGB
    },
    total: {
        marginBottom: 15
    },
    closeIcon: {
        borderRadius: 20,
        border: '1px solid red',
        height: 16,
        width: 16,
        color: 'red',
        marginRight: 3,
        verticalAlign: 'text-top'
    },
    gridItem: {
        '& > div': {
            height: '100%',
            width: '100%',
            margin: 0
        }
    },
    gridContainer: {
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: 'auto',
        [breakpoints.up('xs')]: {
            gridTemplateColumns: 'auto'
        },
        [breakpoints.up('md')]: {
            gridTemplateColumns: 'auto auto'
        },
        [breakpoints.up('lg')]: {
            gridTemplateColumns: 'auto auto auto'
        },
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'auto auto auto auto'
        },
        [breakpoints.up('xxl')]: {
            gridTemplateColumns: 'auto auto auto auto auto auto'
        }
    }
});

export default withErrorBoundary(compose(withRouter, withStyles(styles))(Contacts), { fallback: ErrorFallback });
