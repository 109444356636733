import { LegacyDocuments } from '../PrearrangementFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment PrearrangementSummaryFields on Prearrangement {
                ApplicantTitle
                ApplicantSurname
                ApplicantGivenName
                ApplicantMiddleName

                DoctorCode
                DoctorComment

                BillingNotes

                Enquiries {
                    ID
                    LegacyKey
                    Funeral {
                        ID
                        LegacyKey
                    }
                }
                Quote {
                    ID
                    Enquiry {
                        ID
                        LegacyKey
                    }
                }
                LatestPrePlanForm {
                    ID
                }
            }
        `,
        LegacyDocuments
    ],
    'Prearrangement'
);
