import gql from 'graphql-tag';

export default gql`
    fragment Lifestory on Funeral {
        Lifestory {
            ID
            Presentation
            PresentationByWho
            PresentationFamilyToPrepareWhat
            PresentationFamilyToPreparePowerpointHandoverDate
            PresentationFamilyToPreparePowerpointHandoverTime
            PresentationFamilyToPreparePowerpointHowSend
            PresentationFamilyToPreparePowerpointReceived
            PresentationFamilyToPreparePowerpointNotes
            PresentationFamilyToPreparePowerpointHandoverQty
            PresentationAdditionalPhotos
            PresentationAdditionalPhotosQty
            PresentationAdditionalPhotosHowSend
            PhotoScanningRequired
            PhotoScanningRequiredDate
            PhotoScanningRequiredTime
            PhotoScanningRequiredNotes
            FoyerPhotoRequired
        }
    }
`;
