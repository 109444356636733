import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowSolidIcon from './icons/ArrowSolidIcon';
import { stringIsNullOrEmpty } from '../../util/strings';
import Spinner from '../Spinner';

export default withStyles({})(({ heading, stats, variant, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `arrow--${variant}`;

    return (
        <div className="stats-content">
            <h4>{heading}</h4>
            <div className="container">
                <p>{loading ? <Spinner size="xs" /> : stats}</p>
                <ArrowSolidIcon className={`arrow ${cssVariant || ''}`} />
            </div>
        </div>
    );
});
