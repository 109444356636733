import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';

const ComboListAutoComplete = ({ category, className, name, ...props }) => {
    var convertResults = data => {
        if (!data || !data.readComboLists) return null;

        var result = [];
        for (let x = 0; x < data.readComboLists.edges.length; x++) {
            for (let y = 0; y < data.readComboLists.edges[x].node.Items.edges.length; y++) {
                result.push(data.readComboLists.edges[x].node.Items.edges[y].node);
            }
        }
        return result;
    };

    return (
        <GqlAutocomplete
            className={className}
            readAllQuery={readAllComboListsQuery}
            categories={category}
            convertResults={convertResults}
            valueField={'Name'}
            findById={true}
            name={name}
            allowUnlisted={true}
            clearOnSelect={false}
            {...props}
        />
    );
};

const readAllComboListsQuery = gql`
    query ReadComboLists($filter: String, $contains: String, $limit: Int) {
        readComboLists(key: $filter) {
            edges {
                node {
                    ID
                    LegacyKey
                    Items(contains: $contains, limit: $limit) {
                        edges {
                            node {
                                ID
                                Name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ComboListAutoComplete;
