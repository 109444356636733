import gql from 'graphql-tag';

export default gql`
    fragment PlaceOfDeath on Funeral {
        PlaceOfDeath {
            ID
            Name
            State
        }
    }
`;
