import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 54.234 40.676" {...props}>
        <path
            fill="currentColor"
            d="M49.15,64H5.084A5.084,5.084,0,0,0,0,69.084V99.591a5.084,5.084,0,0,0,5.084,5.084H49.15a5.084,5.084,0,0,0,5.084-5.084V69.084A5.084,5.084,0,0,0,49.15,64Zm1.695,35.591a1.7,1.7,0,0,1-1.695,1.695H5.084A1.7,1.7,0,0,1,3.39,99.591V69.084A1.7,1.7,0,0,1,5.084,67.39H49.15a1.7,1.7,0,0,1,1.695,1.695ZM11.864,81.8a5.932,5.932,0,1,0-5.932-5.932A5.932,5.932,0,0,0,11.864,81.8Zm0-8.474a2.542,2.542,0,1,1-2.542,2.542A2.545,2.545,0,0,1,11.864,73.322Zm21.93,2.439L23.727,85.827,20.441,82.54a2.542,2.542,0,0,0-3.6,0L7.524,91.862a2.542,2.542,0,0,0-.745,1.8v2.966A1.271,1.271,0,0,0,8.05,97.9H46.184a1.271,1.271,0,0,0,1.271-1.271V86.88a2.543,2.543,0,0,0-.745-1.8l-9.321-9.322A2.542,2.542,0,0,0,33.793,75.761ZM44.065,94.507h-33.9v-.5l8.474-8.474,5.084,5.084L35.591,78.757l8.474,8.474Z"
            transform="translate(0 -64)"
        />
    </SvgIcon>
);
