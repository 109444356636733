import React, { Fragment } from 'react';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import DataHighlight from '../../../component/metrics/DataHighlight';
import BarChart from '../../../component/metrics/BarChart';
import Graph from '../../../component/metrics/Graph';
import MetricCard from '../../../component/metrics/MetricCard';
import { getMyClient } from '../../../apollo';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import PieChart from '../../../component/metrics/PieChart';
import { prettyNumber } from '../../../util/strings';
import { CONVERSION_TYPE_COLOURS } from '../Metrics';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type, label } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myEnquiries', variables: JSON.stringify({ ...period, variant }) },
            //{ name: 'myFunerals', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myConversions', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ServicePerformance($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const heading = variant === 'team' ? "Your Team's" : variant === 'company' ? "The Company's" : 'Your';
    const titles = variant === 'team' ? 'Your team have' : variant === 'company' ? 'The company has' : "You've";

    const { colourPreneed, colourPhone, colourAtneed } = CONVERSION_TYPE_COLOURS;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;
        const enqMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myEnquiries')) || null;
        const enqResultRaw = (enqMetric && JSON.parse(enqMetric.result)) || [];
        const enqResult = (enqResultRaw && enqResultRaw.Results) || [];
        const enqCount = (enqResult.length && enqResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;
        /*
        const funMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myFunerals')) || null;
        const funResultRaw = (funMetric && JSON.parse(funMetric.result)) || 0;
        const funResult = (funResultRaw && funResultRaw.Results) || [];
        const funCount = funResult.Count || 0;
        */
        const conMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myConversions')) || null;
        const conResultRaw = (conMetric && JSON.parse(conMetric.result)) || [];
        const conResult = (conResultRaw && conResultRaw.Results) || [];
        const conCount = (conResult.length && conResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;

        const enqGoal = (
            ((enqResultRaw && enqResultRaw.Goals && enqResultRaw.Goals.Total) || 0) /
            (type === 'quarter' ? 4 : type === 'month' ? 12 : 1)
        ).toFixed(0);
        const funGoal = (
            ((conResultRaw && conResultRaw.Goals && conResultRaw.Goals.Conversions) || 0) /
            (type === 'quarter' ? 4 : type === 'month' ? 12 : 1)
        ).toFixed(0);

        const enqRate = Math.min(1, enqCount / enqGoal);
        const conRate = Math.min(1, conCount / funGoal);
        // const funRate = Math.min(1, funCount / funGoal);

        const enqColour = metricColour(enqRate);
        const conColour = metricColour(conRate);
        // const funColour = metricColour(funRate);

        const enqPhoneType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PHONE');
        const enqAtneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'ATNEED');
        const enqPreneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PRENEED');
        const enqPhoneCount = (enqPhoneType && enqPhoneType.Count) || 0;
        const enqAtneedCount = (enqAtneedType && enqAtneedType.Count) || 0;
        const enqPreneedCount = (enqPreneedType && enqPreneedType.Count) || 0;
        const enqPhoneRate = (enqPhoneCount / enqCount) * 100 || 0;
        const enqAtneedRate = (enqAtneedCount / enqCount) * 100 || 0;
        const enqPreneedRate = (enqPreneedCount / enqCount) * 100 || 0;

        const conPhoneType = conResult.length && conResult.find(e => e.EnquiryType === 'PHONE');
        const conAtneedType = conResult.length && conResult.find(e => e.EnquiryType === 'ATNEED');
        const conPreneedType = conResult.length && conResult.find(e => e.EnquiryType === 'PRENEED');
        const conPhoneCount = (conPhoneType && conPhoneType.Count) || 0;
        const conAtneedCount = (conAtneedType && conAtneedType.Count) || 0;
        const conPreneedCount = (conPreneedType && conPreneedType.Count) || 0;
        const conPhoneRate = (conPhoneCount / enqPhoneCount) * 100 || 0;
        const conAtneedRate = (conAtneedCount / enqAtneedCount) * 100 || 0;
        const conPreneedRate = (conPreneedCount / enqPreneedCount) * 100 || 0;

        return (
            <Fragment>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="highlight-container">
                        <MetricCard variant={enqColour}>
                            <DataHighlight
                                topText={`${titles} taken`}
                                data={
                                    <Fragment>
                                        {prettyNumber(enqPhoneCount)} phone
                                        <br />
                                        {prettyNumber(enqPreneedCount)} pre-need
                                        <br />
                                        {prettyNumber(enqAtneedCount)} at-need
                                    </Fragment>
                                }
                                bottomText={`enquiries for ${label}`}
                                variant={enqColour}
                            />
                        </MetricCard>
                        <MetricCard variant={conColour}>
                            <DataHighlight
                                topText={`${titles} converted`}
                                data={
                                    <Fragment>
                                        {prettyNumber(conPhoneCount)} phone
                                        <br />
                                        {prettyNumber(conPreneedCount)} pre-need
                                        <br />
                                        {prettyNumber(conAtneedCount)} at-need
                                    </Fragment>
                                }
                                bottomText={`enquiries for ${label}`}
                                variant={conColour}
                            />
                        </MetricCard>
                    </div>
                </Grid>
                {/*<Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="two">
                            <BarChart
                                label="Enquires Taken"
                                goal={<span>Goal: {prettyNumber(enqGoal)}</span>}
                                stats={`${(enqRate * 100).toFixed(0)}%`}
                                statsNumber={`${enqCount}`}
                                variant={enqColour}
                                loading={reloading}
                            />
                            <BarChart
                                label="Funerals Created"
                                goal={<span>Goal: {prettyNumber(funGoal)}</span>}
                                stats={`${(funRate * 100).toFixed(0)}%`}
                                statsNumber={`${funCount}`}
                                variant={funColour}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>*/}
                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph
                            variant="three"
                            title={
                                <span>
                                    Number of Enquiries: {prettyNumber(enqCount)} (Goal: {prettyNumber(enqGoal)})
                                </span>
                            }
                        >
                            <BarChart
                                label="Phone"
                                stats={`${enqPhoneRate}%`}
                                statsNumber={enqPhoneCount}
                                //variant={metricColour(enqPhoneRate)}
                                colour={colourPhone}
                                loading={reloading}
                            />
                            <BarChart
                                label="Pre-Need"
                                stats={`${enqPreneedRate}%`}
                                statsNumber={enqPreneedCount}
                                //variant={metricColour(enqPreneedRate)}
                                colour={colourPreneed}
                                loading={reloading}
                            />
                            <BarChart
                                label="At-Need"
                                stats={`${enqAtneedRate}%`}
                                statsNumber={enqAtneedCount}
                                //variant={metricColour(enqAtneedRate)}
                                colour={colourAtneed}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="three" title="Enquiry Conversion Rates:">
                            <BarChart
                                label="Phone"
                                stats={`${conPhoneRate}%`}
                                statsNumber={`${conPhoneRate.toFixed(0)}%`}
                                variant={metricColour(conPhoneRate)}
                                // colour={colourPhone}
                                loading={reloading}
                            />
                            <BarChart
                                label="Pre-Need"
                                stats={`${conPreneedRate}%`}
                                statsNumber={`${conPreneedRate.toFixed(0)}%`}
                                variant={metricColour(conPreneedRate)}
                                // colour={colourPreneed}
                                loading={reloading}
                            />
                            <BarChart
                                label="At-Need"
                                stats={`${conAtneedRate}%`}
                                statsNumber={`${conAtneedRate.toFixed(0)}%`}
                                variant={metricColour(conAtneedRate)}
                                // colour={colourAtneed}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MetricCard>
                        <Graph variant="three" title="Enquiries Taken By Type:">
                            <PieChart
                                loading={reloading}
                                data={[
                                    { label: 'Phone', value: enqPhoneCount, colour: colourPhone },
                                    { label: 'Pre-Need', value: enqPreneedCount, colour: colourPreneed },
                                    { label: 'At-Need', value: enqAtneedCount, colour: colourAtneed }
                                ]}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
            </Fragment>
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>{heading} Performance</h2>
            </Grid>
            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {result => renderResult(result)}
            </Query>
        </Grid>
    );
});
