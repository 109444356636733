import gql from 'graphql-tag';

export default gql`
    fragment CemeteryCremetorium on Funeral {
        CemeteryCremetorium {
            ID
            Confirmed
            Responded
            Comment
            Notes
            Contact {
                ID
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementCemeteryCremetorium on Prearrangement {
        CemeteryCremetorium {
            ID
            Confirmed
            Responded
            Comment
            Notes
            Contact {
                ID
            }
        }
    }
`;
