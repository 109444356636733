import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import DrawerList from './DrawerList';
import { buildFlatList } from './drawerConstants';
import cx from 'classnames';

class DrawerComponent extends Component {
    state = { itemsList: [] };

    componentDidMount() {
        this.rebuildItemsList();
    }

    rebuildItemsList() {
        const { items } = this.props;
        if (!items) throw new Error('items not set on drawer,ya duffa!');

        const itemsList = buildFlatList(items);
        this.setState({ itemsList });
    }

    render() {
        return (
            <Fragment>
                {this.renderDesktopDrawer()}
                {this.renderMobileDrawer()}
            </Fragment>
        );
    }

    renderDesktopDrawer() {
        const { classes, minimized } = this.props;
        const { drawerPaper, drawerPaperMinimized } = classes;
        return (
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: cx(drawerPaper, { [drawerPaperMinimized]: minimized })
                    }}
                >
                    {this.renderDrawerList(true)}
                </Drawer>
            </Hidden>
        );
    }

    renderMobileDrawer() {
        const { classes, open, onToggleOpen } = this.props;
        return (
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={onToggleOpen}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {this.renderDrawerList()}
                </Drawer>
            </Hidden>
        );
    }

    renderDrawerList(desktop) {
        const { minimized, onToggleMinimized, onToggleOpen, items } = this.props;

        if (!items) throw new Error('Drawer requires items props');

        const { itemsList } = this.state;

        return (
            <DrawerList
                items={items}
                itemsList={itemsList}
                onClickHamburger={desktop ? onToggleMinimized : onToggleOpen}
                minimized={desktop && minimized}
                {...this.props}
            />
        );
    }
}

const styles = ({ breakpoints, sizes, transitions }) => ({
    drawerPaper: {
        width: sizes.drawerWidth,
        [breakpoints.up('md')]: {
            position: 'relative',
            height: `calc(100vh - ${sizes.footerHeight}px)`
        },
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        }),
        overflow: 'hidden'
    },
    drawerPaperMinimized: {
        width: sizes.drawerWidthMinimized
    }
});

export default withStyles(styles)(DrawerComponent);
