import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../icon/CloseIcon';

/**
 * tag button used for filtering. can be 'removed'
 */
class TagButton extends Component {
    render() {
        const { classes, onClickRemove, text, className } = this.props;

        return (
            <span color="primary" className={cx(classes.root, className)}>
                <span className={classes.text}>{text}</span>
                {onClickRemove && (
                    <IconButton onClick={onClickRemove} className={classes.icon} title="Remove">
                        <CloseIcon />
                    </IconButton>
                )}
            </span>
        );
    }
}

const styles = ({ spacing }) => ({
    root: {
        backgroundColor: '#3f51b5',
        borderRadius: '8px',
        color: '#FFFFFF',
        padding: '2px 6px',
        display: 'inline-block',
        marginRight: spacing.unit,
        marginBottom: spacing.unit,
        fontSize: '0.875rem',
        '&:last-of-type': { marginRight: 0 }
    },
    text: {
        whiteSpace: 'nowrap'
    },
    icon: {
        height: '20px',
        width: '20px',
        marginLeft: '8px',
        '& > span:first-child': {
            border: '1px solid #FFFFFF',
            width: 'unset',
            borderRadius: '50%',
            padding: '2px',
            '& > svg': {
                height: '12px',
                width: '12px',
                color: '#FFFFFF',
                '&:hover': {
                    color: '#CCCCCC'
                }
            }
        }
    }
});

export default withStyles(styles)(TagButton);
