import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { AltButton } from '../PrimaryButton';

class ModalGQLError extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = () => {};

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.paper}>
                <Typography variant="subheading" align="center" style={{ marginBottom: 10, marginTop: 10 }}>
                    <div>{this.props.message}</div>
                </Typography>

                <Grid item xs={6} className={classes.modalButtonContainer}>
                    <AltButton onClick={this.props.onClose} className={classes.button} variant="contained">
                        <Typography variant="button" style={{ color: '#FFFFFF', textDecoration: 'capitalize' }}>
                            Close
                        </Typography>
                    </AltButton>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        maxWidth: '100%',
        maxHeight: '100%',
        overflowY: 'scroll',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        border: '0 solid #FFFFFF',
        //color: "#FFFFFF",
        padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    formControl: {
        margin: theme.spacing.unit,
        width: '100%'
    },
    textBody: {
        paddingLeft: 10
    },
    statusField: {
        marginBottom: 20
    },
    modalButtonContainer: {
        display: 'inline',
        bottom: 20,
        right: 20
    },
    button: {
        float: 'right',
        marginRight: 10,
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF'
    }
});

export default withStyles(styles)(ModalGQLError);
