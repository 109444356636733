import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import Route from '../component/Route';
import NotFound from './NotFound';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import WorkQueue from './workQueue/WorkQueue';
import Funeral from './funeral/Funeral';
import Prearrangement from './prearrangement/Prearrangement';
import Calendar from './calendar/Calendar';
import Contacts from './contacts/Contacts';
import Quotes from './quotes/Quotes';
import Enquiry from './quotes/Enquiry';
import Search from './Search';
import Transfers from './transfers/Transfers';
import Music from './music/Music';
import Account from './account/Account';
import MortuaryList from './mortuary/MortuaryList';
import MortuaryItem from './mortuary/MortuaryItem';
import CheckoutList from './mortuary/Checkout/CheckoutList';
import CheckoutItem from './mortuary/Checkout/CheckoutItem';
import CoffinList from './mortuary/Coffin/CoffinList';
import DailyRunSheet2 from './reports/DailyRunSheetV2';
import CateringReport from './reports/CateringReport';
import WorkQueuePage from './workQueue2/WorkQueuePage';
import Documentation from './documentation/Documentation';
import Metrics from './metrics/Metrics';
import Snackbar from '@material-ui/core/Snackbar';
import BiggerMessageBar from '../component/BiggerMessageBar';
import RefereeDoctorList from './referee/RefereeDoctorList';
import { getServerLink } from '../apollo';
import { isLocalClient, isLocalServer } from '../environment';
import OnlineOrders from './arrangermanager/OnlineOrders';
import IconsPreview from './IconsPreview';
import DepositsReport from './reports/DepositsReport';
import Invoices from './financial/Invoices';
import Payments from './financial/Payments';
import Business from './financial/Financial';

const localLogin = isLocalClient() && !isLocalServer();

const Main = ({ signedIn, classes }) => (
    <main className={signedIn ? classes.main : classes.mainAnon}>
        <div className={classes.innerMain}>
            <Switch>
                {localLogin && <Route anon path="/sign-in" component={SignIn} />}
                {!localLogin && <Route anon path="/sign-in/:id/:hash/:remember?" component={SignIn} />}
                {!localLogin && (
                    <Route
                        anon
                        path="/sign-in"
                        component={() => {
                            window.location.href = getServerLink() + '/Security/react';
                            return null;
                        }}
                    />
                )}
                <Route auth title={'Dashboard'} path="/" exact component={Dashboard} />

                <Route auth title={'Work Queue Cards'} path="/work-queue-cards/:key?" exact component={WorkQueue} />
                <Route auth title={'Task'} path="/work-queue/task/:id?" component={WorkQueuePage} />
                <Route auth title={'Task Queue'} path="/work-queue/:queue/:key?/:id?" component={WorkQueuePage} />
                <Route auth title={'Task Queue'} path="/work-queue" exact component={WorkQueuePage} />

                <Route auth title={'Funeral'} path="/funeral/:key/:id" component={Funeral} />
                <Route auth title={'Prearrangement'} path="/prearrangement/:key/:id" component={Prearrangement} />
                <Route auth title={'Search'} path="/search/:brand?" exact component={Search} />
                <Route auth title={'Documentation'} path="/documentation/:key/:id" component={Documentation} />

                <Route auth title={'Calendar'} path="/calendar/:date?" component={Calendar} />
                <Route auth title={'Contacts'} path="/contacts/:search?" exact component={Contacts} />
                <Route auth title={'Music'} path="/music" exact component={Music} />
                <Route auth title={'Account Settings'} path="/account" exact component={Account} />
                <Route auth title={'Metrics'} path="/metrics" exact component={Metrics} />

                <Route auth title={'Enquiries'} path="/quotes" exact component={Quotes} />
                <Route auth title={'New Enquiry'} path="/quotes/enquiry" exact component={Enquiry} />
                <Route auth title={'Enquiry'} path="/quotes/enquiry/:key/:id" component={Enquiry} />
                <Route auth title={'Online Orders'} path="/quotes/orders" exact component={OnlineOrders} />

                <Route auth title={'Referee Doctor'} path="/refereedoctor" exact component={RefereeDoctorList} />
                <Route auth title={'Transfers'} path="/transfers" exact component={Transfers} />
                <Route auth title={'Deposits'} path="/deposits" exact component={DepositsReport} />

                <Route auth title={'Business'} path="/financial" exact component={Business} />
                <Route auth title={'Invoices & Bills'} path="/financial/invoices-bills" exact component={Invoices} />
                <Route auth title={'Payments'} path="/financial/payments" exact component={Payments} />

                <Route auth title={'Catering Usage'} path="/catering-usage/:date?" exact component={CateringReport} />
                <Route auth title={'Run Sheet'} path="/daily-run-sheet/:date?" component={DailyRunSheet2} />

                <Route auth title={'Mortuary'} path="/mortuary" exact component={MortuaryList} />
                <Route auth title={'Mortuary Action'} path="/mortuary/item/:key/:id" component={MortuaryItem} />
                <Route auth title={'Coffins'} path="/mortuary/coffins" exact component={CoffinList} />
                <Route auth title={'Checkout'} path="/mortuary/checkout" exact component={CheckoutList} />
                <Route auth title={'Checkout Item'} path="/mortuary/checkout/:key/:id" component={CheckoutItem} />

                <Route auth title={'Icons Preview'} path="/icons" component={IconsPreview} />

                <Route title={'Page Not Found'} component={NotFound} />
            </Switch>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={window.appData && window.appData.newContent}
                message={
                    <div style={{ color: 'black', margin: '-20px -24px' }}>
                        <BiggerMessageBar messageType={'fire'}>
                            We're on fire! We've updated the system with a hotfix.
                            <br />
                            It will be loaded when all tabs are closed.
                        </BiggerMessageBar>
                    </div>
                }
            />

            <div className={classes.innerFoot}>&nbsp;</div>
        </div>
    </main>
);

const styles = ({ palette, spacing, sizes, funeralHome, breakpoints }) => ({
    main: {
        marginTop: sizes.headerHeight,
        flex: '1 1 auto',
        overflowY: 'auto',
        backgroundColor: palette.contentBackground[funeralHome],
        flexDirection: 'column',
        display: 'flex'
    },
    mainAnon: {
        flex: '1 1 auto',
        overflowY: 'auto',
        flexDirection: 'column',
        display: 'flex'
    },
    innerFoot: {
        // background: 'red',
        //height: 24, // ensure scrolling content has a gap at the bottom.
        [breakpoints.down('xs')]: {
            height: sizes.footerHeight // safari needs bigger gap, for when interface elements appear.
        }
    },
    innerMain: {
        flex: '1 1 auto',
        padding: spacing.unit * 3,
        flexDirection: 'column',
        height: '100%', // safari needs for visible search results. also crops calendar viewport.
        [breakpoints.down('xs')]: {
            padding: '6px 12px 12px'
        }
    }
});

export default withStyles(styles)(Main);
