import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import { IconButton } from '@material-ui/core';
import MenuIcon from '../icon/MenuIcon';
import Label from './Label';

function SimpleMenu(props) {
    const { children } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
                <Label>More</Label>
            </IconButton>
            <Menu
                id="simple-menu"
                getContentAnchorEl={null}
                anchorEl={anchorEl || null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="simple-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {children}
            </Menu>
        </Fragment>
    );
}

export default SimpleMenu;
