import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Inline from '../../workQueue2/extras/Inline';
import TaskChecklistIcon from '../../../component/icon/TaskChecklistIcon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import LinkButton from '../../../component/form/LinkButton';
import Checkbox from '../../../component/form/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import List from '@material-ui/core/List/List';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { compose } from 'react-apollo';
import { SaveButton } from '../../../component/form/PrimaryButton';
import SaveIcon from '../../../component/icon/SaveIcon';
import { Icon } from '@material-ui/core';
import { BuildArrangementChecksMutation } from '../ArrangementChecklistConstants';
import { getClient } from '../../../apollo';
import Label from '../../../component/form/Label';
import Hidden from '@material-ui/core/Hidden';
import Button from '../../workQueue2/extras/Button';
import BackArrow from '../../../component/icon/BackArrow';

class DrawerList extends Component {
    state = {
        isSaving: false,
        modified: []
    };

    render() {
        const { loading } = this.props;
        const checklist = this.renderContent();
        const hasChecklist = checklist.length > 0;
        return (
            <div className="documentation-page sidebar">
                {this.renderTitle(hasChecklist)}
                <div style={{ paddingBottom: 20 }}>
                    {!!loading
                        ? 'loading... '
                        : !!hasChecklist
                        ? checklist
                        : 'There is currently no checklist for this form.'}
                    {!!hasChecklist && this.renderSaveButton()}
                    <div style={{ clear: 'both' }} />
                </div>
            </div>
        );
    }

    renderSaveButton() {
        const { classes } = this.props;
        const { isSaving, modified } = this.state;

        return (
            <SaveButton
                disabled={isSaving || modified.length < 1}
                onClick={e => {
                    this.setState({ isSaving: true });
                    this.saveDataAsync();
                }}
                className={classes.saveButton}
            >
                {isSaving ? (
                    <Fragment>
                        <SaveIcon /> <span className={classes.svgLabel}>Saving...</span>
                    </Fragment>
                ) : modified.length > 0 ? (
                    <Fragment>
                        <SaveIcon /> <span className={classes.svgLabel}>Save Checklist</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Icon>check_circle_outline</Icon> <span className={classes.svgLabel}>Saved</span>
                    </Fragment>
                )}
            </SaveButton>
        );
    }

    renderTitle(hasChecklist) {
        const { classes, formTitle, hideDrawer } = this.props;
        return (
            <div className="sidebar-title">
                <Hidden lgUp>
                    <div style={{ position: 'absolute', right: 10, top: 22, zIndex: 1 }}>
                        <Button
                            variant="primary icon-button white shadow"
                            size="lg"
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={hideDrawer}
                            title="Hide Checklist"
                        >
                            <BackArrow />
                        </Button>
                    </div>
                </Hidden>
                <Inline center>
                    <IconButton onClick={hideDrawer} title="Hide Checklist">
                        <TaskChecklistIcon />
                    </IconButton>
                    <h2 className={classes.branded}>Checklist:</h2>
                </Inline>
                <h3>{formTitle}</h3>
                {!!hasChecklist && (
                    <Inline>
                        <Label>Please confirm the following items</Label>
                    </Inline>
                )}
            </div>
        );
    }

    renderContent() {
        const { data, pages } = this.props;
        const checksStructure = {};
        const that = this;
        if (data) {
            for (let i = 0; i < data.length; i++) {
                const { Page, Title } = data[i].ArrangementCheck;
                if (checksStructure[Page] === undefined) {
                    checksStructure[Page] = {
                        Title: (pages && pages[Page] && pages[Page].Title) || Title,
                        Checks: [],
                        Disabled: pages && pages[Page] && pages[Page].Disabled
                    };
                }
                data[i].index = i;
                checksStructure[Page].Checks.push(data[i]);
            }
        }
        return Object.keys(checksStructure).map(function(key) {
            const disabled = checksStructure[key].Disabled;
            return (
                <ExpansionPanel key={key} defaultExpanded className="no-shadow">
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="checklist-accordion"
                    >
                        <h4>{checksStructure[key].Title}</h4>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="expansion-panel">
                        <List className="list">
                            {checksStructure[key].Checks.map(function(check) {
                                return (
                                    <ListItem key={check.ID}>
                                        <ListItemText className="list-link">
                                            <LinkButton
                                                text={check.ArrangementCheck.Title}
                                                onClick={() => {
                                                    that.onClick(check.index);
                                                }}
                                            />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                disabled={disabled}
                                                checked={check.Checked}
                                                onChange={e => {
                                                    that.onChange(check.index, e.target.checked);
                                                }}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        });
    }

    onClick = index => {
        const { data, onNavigate } = this.props;
        if (onNavigate) {
            onNavigate(data[index].ArrangementCheck.Page, data[index].ArrangementCheck.Section);
        }
    };

    onChange = (index, checked) => {
        const { modified } = this.state;
        const { data } = this.props;

        if (modified.indexOf(index) === -1) {
            modified.push(index);
        }

        data[index].Checked = checked;
        this.setState({ modified });
    };

    getVariables = () => {
        const { data, formID } = this.props;
        const { modified } = this.state;
        const vars = {};
        for (let i = 0; i < modified.length; i++) {
            const { ID, Checked } = data[modified[i]];
            vars['input' + i] = { ID, Checked };
        }
        let complete = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].Checked) {
                complete++;
            }
        }
        vars['inputProgress'] = {
            ID: formID,
            Progress: Math.round((complete * 100) / data.length) / 100
        };
        return vars;
    };

    saveDataAsync = async () => {
        const { modified } = this.state;
        const { formObject } = this.props;
        const that = this;
        getClient()
            .mutate({
                mutation: BuildArrangementChecksMutation(modified, formObject),
                variables: this.getVariables(),
                refetchQueries: ['SummaryQuery'],
                awaitRefetchQueries: true
            })
            .then(
                x => that.setState({ isSaving: false, modified: [] }),
                x => that.setState({ isSaving: false })
            );
    };
}

const styles = ({ sizes, spacing, palette }) => ({
    list: {
        paddingTop: 0,
        overflow: 'hidden',
        overflowY: 'auto'
    },
    listWithFooter: {
        paddingTop: 0,
        margin: '0px 0px 72px 0px',
        overflow: 'auto'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        height: sizes.headerHeight
    },
    titleText: {
        flex: '1 0 auto'
    },
    titleIcon: {
        width: 38,
        marginRight: spacing.unit,
        marginLeft: spacing.unit * 2
    },
    burger: {
        width: 16
        // marginRight: spacing.unit
    },
    burgerMinimized: {
        marginRight: 0
    },
    burgerButton: {
        marginRight: spacing.unit / 2
    },
    footer: {
        display: 'relative',
        position: 'absolute',
        bottom: '70px',
        padding: '15px 15px'
    },
    drawerPaperMinimized: {},
    drawerPaper: {},
    branded: {
        color: palette.primary.main
    },
    saveButton: {
        // width: 80,
        marginTop: '1rem',
        float: 'right'
    }
});

export default compose(withRouter, withStyles(styles))(DrawerList);
