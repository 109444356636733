import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 29.059 33.21" {...props}>
        <path
            fill="currentColor"
            d="M25.945,4.151H22.832V.778A.781.781,0,0,0,22.054,0h-.519a.781.781,0,0,0-.778.778V4.151H8.3V.778A.781.781,0,0,0,7.524,0H7.005a.781.781,0,0,0-.778.778V4.151H3.113A3.114,3.114,0,0,0,0,7.265V30.1A3.114,3.114,0,0,0,3.113,33.21H25.945A3.114,3.114,0,0,0,29.059,30.1V7.265A3.114,3.114,0,0,0,25.945,4.151ZM3.113,6.227H25.945a1.041,1.041,0,0,1,1.038,1.038v3.113H2.076V7.265A1.041,1.041,0,0,1,3.113,6.227ZM25.945,31.134H3.113A1.041,1.041,0,0,1,2.076,30.1V12.454H26.983V30.1A1.041,1.041,0,0,1,25.945,31.134ZM9.6,20.756H7.005a.781.781,0,0,1-.778-.778V17.383a.781.781,0,0,1,.778-.778H9.6a.781.781,0,0,1,.778.778v2.595A.781.781,0,0,1,9.6,20.756Zm6.227,0H13.232a.781.781,0,0,1-.778-.778V17.383a.781.781,0,0,1,.778-.778h2.595a.781.781,0,0,1,.778.778v2.595A.781.781,0,0,1,15.827,20.756Zm6.227,0H19.459a.781.781,0,0,1-.778-.778V17.383a.781.781,0,0,1,.778-.778h2.595a.781.781,0,0,1,.778.778v2.595A.781.781,0,0,1,22.054,20.756Zm-6.227,6.227H13.232a.781.781,0,0,1-.778-.778V23.61a.781.781,0,0,1,.778-.778h2.595a.781.781,0,0,1,.778.778V26.2A.781.781,0,0,1,15.827,26.983Zm-6.227,0H7.005a.781.781,0,0,1-.778-.778V23.61a.781.781,0,0,1,.778-.778H9.6a.781.781,0,0,1,.778.778V26.2A.781.781,0,0,1,9.6,26.983Zm12.454,0H19.459a.781.781,0,0,1-.778-.778V23.61a.781.781,0,0,1,.778-.778h2.595a.781.781,0,0,1,.778.778V26.2A.781.781,0,0,1,22.054,26.983Z"
        />
    </SvgIcon>
);
