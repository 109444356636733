import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Grid from '../../../component/form/Grid';
import Stats from '../../../component/metrics/Stats';
import BarChart from '../../../component/metrics/BarChart';
import Graph from '../../../component/metrics/Graph';
import MetricCard from '../../../component/metrics/MetricCard';
import { getMyClient } from '../../../apollo';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import FlexGrid from '../../workQueue2/extras/FlexGrid';
import { CONVERSION_TYPE_COLOURS } from '../Metrics';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myConducted', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myPreFuneralAppointments', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ScoreArrangerMyPerformance($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;

        const conductedMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myConducted')) || null;
        const conductedResult = (conductedMetric && JSON.parse(conductedMetric.result)) || [];
        const conductedCount = parseInt(conductedResult.Count);

        const mpfMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myPreFuneralAppointments')) || null;
        const mpfResult = (mpfMetric && JSON.parse(mpfMetric.result)) || [];
        const mpfCount = (mpfResult.length && mpfResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;
        const mpfPercent = conductedCount > 0 ? mpfCount / conductedCount : 0;
        const mpfRate = Math.min(1, conductedCount > 0 ? mpfCount / conductedCount : 0);
        const mpfColor = metricColour(mpfRate);

        const phoneResult = mpfResult.length && mpfResult.filter(e => e.LocationType === 'Phone');
        const phoneCount =
            (phoneResult && phoneResult.length && phoneResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;
        const phoneRate = Math.min(1, conductedCount > 0 ? phoneCount / mpfCount : 0);
        const phoneColor = CONVERSION_TYPE_COLOURS['colourPhone'];

        const fToFResult = mpfResult.length && mpfResult.filter(e => e.LocationType !== 'Phone');
        const fToFCount =
            (fToFResult && fToFResult.length && fToFResult.map(e => Number(e.Count)).reduce((a, b) => a + b)) || 0;
        const fToFRate = Math.min(1, conductedCount > 0 ? fToFCount / mpfCount : 0);
        const fToFColor = CONVERSION_TYPE_COLOURS['colourAtneed'];

        function gcd_rec(a, b) {
            if (a < b) return a;
            return b;
        }

        const ratioF =
            !!fToFCount && !!phoneCount ? Number(fToFCount / gcd_rec(fToFCount, phoneCount)).toFixed(2) : fToFCount;
        const ratioP =
            !!fToFCount && !!phoneCount ? Number(phoneCount / gcd_rec(fToFCount, phoneCount)).toFixed(2) : phoneCount;

        const pills = (
            <Grid item sm={12} md={6}>
                <MetricCard>
                    <FlexGrid variant="justify-center" className="full-height">
                        <div className="content-container">
                            <Stats heading="Conducted Funerals" stats={conductedCount || 0} loading={reloading} />
                            <Stats heading="Pre-Funeral Appointments" stats={mpfCount} loading={reloading} />
                            <Stats
                                heading="Pre-Funeral Appointment Rate"
                                stats={`${(mpfPercent * 100).toFixed(0)}%`}
                                loading={reloading}
                            />
                            <Stats heading="Face-to-Face Appointments" stats={fToFCount} loading={reloading} />
                            <Stats heading="Phone Appointments" stats={phoneCount} loading={reloading} />
                            <Stats
                                heading="Face-to-Face vs Phone Ratio"
                                stats={
                                    <Fragment>
                                        {Number(ratioF)} : {Number(ratioP)}
                                    </Fragment>
                                }
                                loading={reloading}
                            />
                        </div>
                    </FlexGrid>
                </MetricCard>
            </Grid>
        );

        // todo
        const graphs = (
            <Grid item sm={12} md={6}>
                <MetricCard>
                    <Graph variant="three">
                        <BarChart
                            label="Pre-Funeral Apppointment Rate"
                            // goal="Goal: 100%"
                            stats={`${(mpfRate * 100).toFixed(0)}%`}
                            statsNumber={`${(mpfRate * 100).toFixed(0)}%`}
                            variant={mpfColor}
                            loading={reloading}
                        />
                        <BarChart
                            label="Phone Appointments"
                            // goal="Goal: 60%"
                            stats={`${(phoneRate * 100).toFixed(0)}%`}
                            statsNumber={`${phoneCount}`}
                            colour={phoneColor}
                            loading={reloading}
                        />
                        <BarChart
                            label="Face-to-Face Appointments"
                            // goal="Goal: 40%"
                            stats={`${(fToFRate * 100).toFixed(0)}%`}
                            statsNumber={`${fToFCount}`}
                            colour={fToFColor}
                            loading={reloading}
                        />
                    </Graph>
                </MetricCard>
            </Grid>
        );

        return (
            <Fragment>
                {variant === 'individual' ? pills : graphs}
                {variant === 'individual' ? graphs : pills}
            </Fragment>
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>{variant === 'individual' ? 'Your' : "Your Team's"} Performance - Pre-Funeral Appointments</h2>
            </Grid>
            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {result => renderResult(result)}
            </Query>
        </Grid>
    );
});
