import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DisplayContact from './DisplayContact';
import Label from '../form/Label';
import { InlineField, InlineHeaderRightAlignChildren } from '../form/Inline';
import AddressBookAutocomplete from '../form/AddressBookAutocomplete';
import LinkButton from '../form/LinkButton';
import { Query } from 'react-apollo';
import Spinner from '../Spinner';
import Grid from '../form/Grid';
import QuotesIcon from '../icon/QuotesIcon';
import PurchaseOrderModal from './PurchaseOrderModal';
import GatedComponent from '../GatedComponent';
import { isRelatedObjectUndefined } from '../../util/graphql';
import { AltButton } from '../form/PrimaryButton';
import { nameQuery } from './SupplierBooking';
import { GST, LoadProducts } from '../../util/products';
import { isNullOrUndefined } from '../../util/objects';
import PurchaseInvoiceModal from './PurchaseInvoiceModal';
import AlertModal from '../../page/workQueue2/extras/AlertModal';
import { getUser } from '../../util/sessions';
import { getUtilitiesClient } from '../../apollo';

class PurchaseOrderAddressBookAutocomplete extends Component {
    state = {
        orderModalOpen: false,
        defaultLineItem: null,
        invalidLineItem: false,
        loading: false,
        alertModalOpen: false
    };

    componentDidMount() {
        const { defaultLineItemCode } = this.props;
        if (isNullOrUndefined(defaultLineItemCode)) return;

        this.setState({ loading: true });
        LoadProducts(null, defaultLineItemCode).then(products => {
            const product = products.find(e => '' + e.internalId === '' + defaultLineItemCode);
            if (!product) {
                console.error('Failed to get default line item with code ', defaultLineItemCode);
                this.setState({
                    defaultLineItem: { ID: 0 },
                    invalidLineItem: true,
                    loading: false
                });
                return;
            }
            const cost = product.original.price;
            const lineItem = {
                UnitPrice: cost, //exGST
                Price: cost * (!!product.original.gst ? GST + 1 : 1), //incGST
                Quantity: 1,
                ProductID: product.id,
                InternalItemID: product.internalId,
                Title: product.title,
                GST: product.original.gst
            };

            this.setState({ defaultLineItem: lineItem, invalidLineItem: false, loading: false });
        });
    }

    render() {
        const { form, dataObjectType, contactField, categories, labelSupplier, labelField, disabled } = this.props;
        const contactFieldPath = `${dataObjectType}.${contactField}`;
        const contact = form.getField(contactFieldPath);
        const { alertModalOpen } = this.state;
        const { FirstName } = getUser();
        return (
            <Grid pc={1}>
                {isRelatedObjectUndefined(contact) ? (
                    <Fragment>
                        <InlineField>
                            <Label text={'Select a ' + labelSupplier} htmlFor={`select-${contactFieldPath}`} />
                        </InlineField>
                        <InlineField>
                            <AddressBookAutocomplete
                                disabled={disabled}
                                labelField={labelField}
                                name={contactFieldPath}
                                form={form}
                                placeholder={'Search for a ' + labelSupplier + '...'}
                                categories={categories}
                            />
                        </InlineField>
                    </Fragment>
                ) : (
                    <Query client={getUtilitiesClient()} query={nameQuery} variables={{ id: contact.ID }}>
                        {response => this.renderContact(response)}
                    </Query>
                )}
                <AlertModal
                    title="Confirmation"
                    onClose={() => this.setState({ alertModalOpen: false })}
                    onClickSecondaryAction={() => this.setState({ alertModalOpen: false })}
                    open={alertModalOpen}
                    variant="warning"
                    primaryAction="Nah Yeah, do it"
                    secondaryAction="Oops, need this"
                    onClickPrimaryAction={() => {
                        this.removeContact();
                        this.setState({ alertModalOpen: false });
                    }}
                >
                    <p>
                        Woah there {FirstName}! You're trying to change this {labelSupplier}, but we have already raised
                        a bill.
                    </p>
                    <p>Are you sure? (The bill will be cancelled.)</p>
                </AlertModal>
            </Grid>
        );
    }

    renderContact({ loading, error, data }) {
        const { classes } = this.props;

        if (loading || this.state.loading) {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }

        if (error) {
            return <div className={classes.error}>{error ? error.toString() : 'An unknown error occurred'}</div>;
        }

        const contact = (data && data.readOneAddressBook) || {};
        if (isRelatedObjectUndefined(contact)) {
            return <div className={classes.error}>{'Failed to load contact'}</div>;
        }

        return this.renderPanel(contact);
    }

    renderPanel(contact) {
        const { form, dataObjectType, labelSupplier, purchaseOrderField, createPurchaseOrderMutation, disabled } = this.props;
        const { orderModalOpen, defaultLineItem, invalidLineItem } = this.state;

        return (
            <Fragment>
                <GatedComponent isEnabledCode={'FM_ACCESS_Quotes_Edit'}>
                    {isEnabled => {
                        return (
                            <PurchaseOrderModal
                                canEdit={isEnabled}
                                disabled={disabled}
                                open={orderModalOpen}
                                booking={{
                                    ID: form.getField(`${dataObjectType}.ID`),
                                    Contact: contact,
                                    BookingItems:
                                        !invalidLineItem && !isNullOrUndefined(defaultLineItem) ? [defaultLineItem] : []
                                }}
                                contact={contact}
                                funeralID={form.getField('ID')}
                                label={labelSupplier}
                                isOrder={false}
                                purchaseOrderField={purchaseOrderField}
                                mutation={createPurchaseOrderMutation}
                                onClose={reload => {
                                    const me = this;
                                    if (reload && form.doRefetch)
                                        form.doRefetch(() => me.setState({ orderModalOpen: false }));
                                    else this.setState({ orderModalOpen: false });
                                }}
                            />
                        );
                    }}
                </GatedComponent>

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InlineHeaderRightAlignChildren header={labelSupplier}>
                            <span style={{ float: 'right', fontSize: '0.875rem' }}>
                                <LinkButton
                                    disabled={disabled}
                                    text={`Change ${labelSupplier}`}
                                    onClick={() => this.promptRemoveContact()}
                                />
                            </span>
                        </InlineHeaderRightAlignChildren>
                        <Grid item xs={12}>
                            <DisplayContact disabled={disabled} contactItem={contact} />
                        </Grid>
                    </Grid>
                    {this.renderPurchaseOrderDetails()}
                </Grid>
            </Fragment>
        );
    }

    renderPurchaseOrderDetails() {
        const { form, dataObjectType, purchaseOrderField, classes, allowPurchaseOrder, labelSupplier, disabled } = this.props;

        const purchaseOrderFieldPath = `${dataObjectType}.${purchaseOrderField}`;
        const purchaseOrder = form.getField(purchaseOrderFieldPath);

        if (!isRelatedObjectUndefined(purchaseOrder)) {
            return (
                <Grid item xs={12}>
                    <span className={classes.floatRight}>
                        <PurchaseInvoiceModal
                            hasPurchaseOrder={purchaseOrder}
                            labelSupplier={labelSupplier}
                            form={form}
                        />
                    </span>
                </Grid>
            );
        }

        if (!allowPurchaseOrder) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <span className={classes.floatRight}>
                    <AltButton onClick={e => this.setState({ orderModalOpen: true })} disabled={form.isDirty || disabled}>
                        <QuotesIcon /> Create Bill...
                    </AltButton>
                    {form.isDirty && <div className={classes.saveInfo}>Please save the record first</div>}
                </span>
            </Grid>
        );
    }

    promptRemoveContact() {
        const { form, dataObjectType, purchaseOrderField } = this.props;
        const purchaseOrderFieldPath = `${dataObjectType}.${purchaseOrderField}`;
        const po = form.getField(purchaseOrderFieldPath) || null;
        if (!isRelatedObjectUndefined(po) && !!po.Invoice && !!po.Invoice.XeroID) {
            this.setState({ alertModalOpen: true });
        } else {
            this.removeContact();
        }
    }

    removeContact() {
        const { form, dataObjectType, purchaseOrderField, contactField } = this.props;

        const contactFieldPath = `${dataObjectType}.${contactField}`;
        const purchaseOrderFieldPath = `${dataObjectType}.${purchaseOrderField}`;

        form.setField({
            [purchaseOrderFieldPath]: null,
            [contactFieldPath]: null,
            [contactFieldPath + 'ID']: null
        });
    }
}

const styles = () => ({
    floatRight: {
        float: 'right'
    },
    error: {
        paddingLeft: '1.25rem',
        fontSize: '75%',
        opacity: '0.5'
    },
    saveInfo: {
        fontSize: '11px',
        fontStyle: 'italic',
        color: '#333333',
        textAlign: 'right',
        marginTop: '15px',
        marginRight: '5px'
    }
});

export default withStyles(styles)(PurchaseOrderAddressBookAutocomplete);
