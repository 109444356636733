import React, { Component } from 'react';
import { compose } from 'react-apollo';
import gql from 'graphql-tag';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '../../component/form/Grid';
import { withStyles } from '@material-ui/core';
import Checkbox from '../../component/form/Checkbox';
import Inline, { inlineAlignment } from '../workQueue2/extras/Inline';
import DocumentEmailModal from './modals/DocumentEmailModal';
import { getClient } from '../../apollo';
import ArrangementIcon from '../../component/icon/ArrangementIcon';
import { withRouter } from 'react-router';
import LinkButton from '../../component/form/LinkButton';

class TabDeclaration extends Component {
    constructor() {
        super();
        this.state = {
            openSendEmailModal: false
        };
    }

    render() {
        const { form, classes } = this.props;
        const { openSendEmailModal } = this.state;
        const officeName = form.getField('Office.BusinessName');

        return (
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                        className="summary-accordion"
                    >
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Inline center>
                                <div className="progress-details">
                                    <ArrangementIcon className={`${classes.branded} large-icon`} />
                                </div>
                                <h3>Client Declaration</h3>
                            </Inline>
                        </Inline>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <Grid container>
                            {!!form.getField('Office.QuoteTermsPDF') &&
                                !!form.getField('Office.QuoteTermsPDF.AbsoluteLink') && (
                                    <Grid item xs={12}>
                                        <Inline>
                                            <Checkbox
                                                name="Arrangement.AgreeTC"
                                                form={form}
                                                onChange={e =>
                                                    updateFuneralArrangementFunc(
                                                        form.getField('Arrangement.ID'),
                                                        e.target.checked,
                                                        form.getField('Arrangement.OptToSendEmail')
                                                    )
                                                }
                                            />
                                            <p>
                                                I agree to &nbsp;
                                                <LinkButton
                                                    target="_blank"
                                                    href={form.getField('Office.QuoteTermsPDF.AbsoluteLink')}
                                                    text={`${officeName} terms and conditions`}
                                                />
                                            </p>
                                        </Inline>
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <Inline nowrap={true}>
                                    <Checkbox
                                        name="Arrangement.OptToSendEmail"
                                        form={form}
                                        disabled={!form.getField('Arrangement.AgreeTC')}
                                        onChange={e => this.onChangeEmailOption(e)}
                                    />
                                    <p>
                                        {officeName +
                                            ' are dedicated to keeping you informed. We have found the best and fastest way to communicate with you is via email and/or telephone for urgent matters. By providing us with your email address, you agree to receive email correspondence from us on (including but limited to): changes or modifications to the funeral, quotations, invoices, requests to confirm various funeral details, newsletters and other promotional correspondence. You may opt-out of receiving emails from ' +
                                            officeName +
                                            ' at any time by contacting our team.'}
                                    </p>
                                </Inline>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <DocumentEmailModal
                    open={openSendEmailModal}
                    onClose={() => this.setState({ openSendEmailModal: false })}
                    parentForm={form}
                    arrangementID={form.getField('Arrangement.ID')}
                />
            </Grid>
        );
    }

    onChangeEmailOption(e) {
        const { form, onSendEmailOptionSelect } = this.props;
        updateFuneralArrangementFunc(
            form.getField('Arrangement.ID'),
            form.getField('Arrangement.AgreeTC'),
            e.target.checked
        );
        if (onSendEmailOptionSelect) {
            onSendEmailOptionSelect(e.target.checked);
        }
    }
}

const updateArrangementMutation = gql`
    mutation UpdateFuneralArrangement($input: UpdateFuneralArrangementInput!) {
        updateFuneralArrangement(input: $input) {
            ID
            AgreeTC
            OptToSendEmail
        }
    }
`;

const updateFuneralArrangementFunc = async (id, agreeTc, optToSendEmail) => {
    return await getClient().mutate({
        mutation: updateArrangementMutation,
        variables: {
            input: {
                ID: id,
                AgreeTC: agreeTc,
                OptToSendEmail: optToSendEmail
            }
        }
    });
};

const styles = ({ palette }) => ({
    branded: {
        color: palette.primary.main
    }
});

export default compose(withRouter, withStyles(styles))(TabDeclaration);
