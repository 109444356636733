import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from '../../component/form/PrimaryButton';
import TextField from '../../component/form/TextField';
import { Icon } from '@material-ui/core';
import { InlineFieldRightAlignChildren, InlineHeaderRightAlignChildren } from '../../component/form/Inline';
import Select from '../../component/form/Select';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import moment from 'moment';
import { PreNeedTypes } from './EnquiryConstants';
import { getOffices } from '../../environment';
import SelectOffice from '../../component/form/SelectOffice';

class QuotesSearch extends Component {
    state = {
        searchKeyword: '',
        searchType: null,
        preneedType: null,
        searchOffice: null,
        searchByDate: null,
        searchBegin: null,
        searchUntil: null
    };

    static getDerivedStateFromProps(props, { searchOffice }) {
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('office');
        if (searchOffice !== key) return { searchOffice: key };
        return null;
    }

    onSearchFieldRef = input => {
        this.searchFieldInput = input;
    };

    onSearchSubmit = e => {
        if (e) e.preventDefault();
        this.props.onSearchSubmit({ ...this.state });
    };

    onChangeDate = (e, fieldName) => {
        this.setState({ [fieldName]: e.target.value }, () => {
            if (
                !this.state[fieldName] ||
                (moment(this.state[fieldName], 'YYYY-MM-DD', true).isValid() &&
                    moment(this.state[fieldName]).isBefore() &&
                    moment(this.state[fieldName]).isAfter('1900-01-01'))
            )
                this.onSearchSubmit();
        });
    };

    render() {
        const { history, classes } = this.props;
        const allOffices = getOffices();
        const { ID: value } = allOffices.find(obj => obj.RegistrantSuffix === this.state.searchOffice) || { ID: '' };
        return (
            <div style={{ margin: '0 0 16px' }}>
                <InlineHeaderRightAlignChildren header={'Filter:'} className={classes.addWrap}>
                    <SelectOffice
                        allowNone={true}
                        allowRetired={true}
                        fullWidth={false}
                        placeholder={'From any office'}
                        value={value}
                        onChange={e => {
                            const { RegistrantSuffix } = allOffices.find(obj => obj.ID === e.target.value) || {};
                            history.replace('?office=' + (RegistrantSuffix || ''));
                            this.setState({ searchOffice: RegistrantSuffix || '' }, this.onSearchSubmit);
                        }}
                    />
                    <Select
                        fullWidth={false}
                        placeholder={'Any enquiry type'}
                        options={[
                            { value: 'PHONE', label: 'Phone Enquiries' },
                            { value: 'PRENEED', label: 'Pre-need Enquiries' },
                            { value: 'ATNEED', label: 'At-need Enquiries' }
                            // { value: 'PLAQUE', label: 'Plaque' }
                        ]}
                        value={this.state.searchType}
                        onChange={e => this.setState({ searchType: e.target.value }, this.onSearchSubmit)}
                    />
                    <form onSubmit={this.onSearchSubmit} style={{ position: 'relative' }}>
                        <TextField
                            fullWidth
                            type={'search'}
                            inputProps={{
                                ref: this.onSearchFieldRef,
                                style: { paddingRight: 44 }
                            }}
                            placeholder="Enter keywords..."
                            value={this.state.searchKeyword}
                            onChange={e => this.setState({ searchKeyword: e.target.value })}
                        />
                        <PrimaryButton
                            onClick={this.onSearchSubmit}
                            style={{
                                position: 'absolute',
                                borderRadius: '0 3px 3px 0',
                                margin: '0 0 0 -44px',
                                border: '1px solid transparent',
                                height: 39,
                                minWidth: 39,
                                width: 44,
                                padding: 0
                            }}
                        >
                            <Icon>search</Icon>
                        </PrimaryButton>

                        {/*<PrimaryButton onClick={this.handleOpen} style={{ float: 'right' }}>*/}
                        {/*<FilterIcon />*/}
                        {/*Filters*/}
                        {/*</PrimaryButton>*/}
                    </form>
                    <Select
                        fullWidth={false}
                        placeholder={'Anytime'}
                        options={[
                            { label: 'Date Created', value: 'Created' },
                            { label: 'Last Updated', value: 'LastEdited' },
                            { label: 'Date of Death', value: 'DateOfDeath1' }
                        ]}
                        value={this.state.searchByDate}
                        onChange={e => {
                            this.setState({ searchByDate: e.target.value }, this.onSearchSubmit);
                        }}
                    />
                </InlineHeaderRightAlignChildren>
                {(this.state.searchType === 'PRENEED' || !!this.state.searchByDate) && (
                    <InlineFieldRightAlignChildren className={classes.addTop}>
                        <div />
                        {this.state.searchType === 'PRENEED' && (
                            <Select
                                name="PreneedType"
                                options={PreNeedTypes}
                                fullWidth={false}
                                label={'Preneed Type'}
                                value={this.state.preneedType}
                                onChange={e => this.setState({ preneedType: e.target.value }, this.onSearchSubmit)}
                            />
                        )}
                        {!!this.state.searchByDate && (
                            <Fragment>
                                <TextField
                                    onChange={e => this.onChangeDate(e, 'searchBegin')}
                                    value={this.state.searchBegin}
                                    label={'From'}
                                    type="date"
                                    fullWidth={false}
                                    max={this.state.searchUntil}
                                />
                                <TextField
                                    onChange={e => this.onChangeDate(e, 'searchUntil')}
                                    notFuture
                                    value={this.state.searchUntil}
                                    label={'Until'}
                                    type="date"
                                    fullWidth={false}
                                    min={this.state.searchBegin}
                                />
                            </Fragment>
                        )}
                    </InlineFieldRightAlignChildren>
                )}
            </div>
        );
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    addWrap: {
        gap: '0 ' + spacing.unit + 'px',
        '& > *': {
            flexGrow: 1,
            //marginBottom: 6
            marginLeft: '0!important'
        }
    },
    addTop: {
        '& > *': {
            //marginTop: 10
        }
    }
});

export default compose(withStyles(styles), withRouter)(QuotesSearch);
