import React from 'react';
import { CircularProgress } from '@material-ui/core';
import QuestionMarkCircleIcon from './QuestionMarkCircleIcon';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';

export function ProgressPieIcon({ value, classes }) {
    const content = (
        <>
            <QuestionMarkCircleIcon style={{ position: 'absolute' }} />
            <CircularProgress
                value={value}
                variant="static"
                thickness={22}
                style={{ opacity: Math.max(0.33, value / 100), width: 35, height: 35, color: '#26cc6f' }}
            />
        </>
    );
    return classes ? <span className={classes ? classes.progressQuestionMark : null}>{content}</span> : content;
}

const styles = () => ({
    progressQuestionMark: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35px',
        width: '35px',
        color: '#CCC',
        '& > svg': {
            height: '35px',
            width: '35px'
        }
    }
});

export default compose(withStyles(styles))(ProgressPieIcon);
