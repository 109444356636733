import gql from 'graphql-tag';
import { Invoice } from './Invoices';

export default gql`
    fragment PurchaseOrders on Funeral {
        PurchaseOrders {
            ID
            Created
            LegacyID
            LegacyKey
            XeroID
            XeroStatus
            Funeral {
                ID
            }
            Products {
                ID
                Qty
                UnitPrice
                Product {
                    ID
                    InternalItemID
                    Title
                    GST
                    BasePrice
                }
                Variation {
                    ID
                    InternalItemID
                    ShortDescription
                    GST
                    Price
                    Product {
                        ID
                        Title
                    }
                }
            }
            SupplierCode
            DeliveryDate
            Supplier {
                ID
            }
        }
    }
`;

//for some reason, it doesn't like nesting this inside the Invoices collection. nasty
export const PurchaseOrderObject = gql`
    ${Invoice}
    fragment PurchaseOrderObject on PurchaseOrder {
        ID
        Created
        LegacyID
        LegacyKey
        XeroID
        XeroStatus
        Funeral {
            ID
        }
        Products {
            ID
            Qty
            UnitPrice
            Product {
                ID
                InternalItemID
                Title
                GST
                BasePrice
            }
            Variation {
                ID
                InternalItemID
                ShortDescription
                GST
                Price
                Product {
                    ID
                    Title
                }
            }
        }
        SupplierCode
        DeliveryDate
        Supplier {
            ID
        }
        Invoice {
            ...InvoiceObject
        }
    }
`;

export const PurchaseOrderBooking = gql`
    fragment PurchaseOrderBooking on FuneralBooking {
        PurchaseOrder {
            ID
            Created
            LegacyID
            LegacyKey
            XeroID
            XeroStatus
            Funeral {
                ID
            }
            Products {
                ID
                Qty
                UnitPrice
                Product {
                    ID
                    InternalItemID
                    Title
                    GST
                    BasePrice
                }
                Variation {
                    ID
                    InternalItemID
                    ShortDescription
                    GST
                    Price
                    Product {
                        ID
                        Title
                    }
                }
            }
            SupplierCode
            DeliveryDate
            Supplier {
                ID
            }
        }
    }
`;
