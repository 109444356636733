import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { withApollo } from 'react-apollo';
import { gqlArr as gql } from '../../util/graphql';
import Spinner from '../../component/Spinner';
import { withRouter } from 'react-router';
import { FIELD_TAB_LOOKUP, TAB } from '../../page/funeral/funeralConstants';
import { deleteTypeName, getProperty, isNullOrUndefined, setProperty } from '../../util/objects';
import { diff } from '../../util/functions';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import SaveIcon from '../icon/SaveIcon';
import * as Fragments from '../../page/funeral/FuneralFragments';
import { ModalPrimaryButton } from './PrimaryButton';
import { enableValidation, validationUtility } from '../../util/validation';

/*
 * DataForm is used for saving DataObjects.
 *
 * @param data is typically the raw DataObject loaded by a GraphQL Query.
 * @param loading boolean to indicate if the data is busy loading
 * @param error boolean to indicate if the data failed to load
 * @param onLoad is a function to augment the DataObject so it can become form data with additional preset values.  Each funeral tab has an additional onLoad.
 * @param mutation is the mutation gql. Without it, updateFuneral is assumed.
 * @param variables is the data to save. Remove form data here that is not on the DataObject. Without it, a funeral DataObject is assumed - funeral tabs use formatSaveData(differences, state) instead.
 * @param allowSave boolean to show/hide the save button. default is true, show button.
 * @param onSaved is a function to run after saving. Sends the returned DataObject (not the form data).
 * @param refetch is an asynchronous function that is executed after save, usually to read new list data for a parent.
 * @param validation a function for validation
 * @param requireValid boolean to prevent save if any required fields are invalid. default is true, prevents save.
 */

class DataForm extends React.Component {
    original = {};
    state = {
        fields: {},
        validation: validationUtility.createState(),
        isDirty: false
    };
    loaded = {};
    savePending = false;
    saveAttempted = false;

    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    componentWillMount() {
        this.updateFormState(this.props);
        this.unblock = this.props.history.block(this.onHistoryBlock);
    }

    componentWillReceiveProps(nextProps) {
        this.updateFormState(nextProps);
    }

    componentWillUnmount() {
        this.unblock();
    }

    getValidation = (fieldName, forceFieldNameAsKey = false, revalidate = false) => {
        return validationUtility.getValidationResult(fieldName, this.state.validation, forceFieldNameAsKey, revalidate);
    };

    getNestedState = field => {
        return getProperty(this.state.fields, field);
    };

    setNestedState = (newState, dirty = true) => {
        const clonedState = cloneDeep(newState);
        const state = this.state.fields;
        for (let fieldName in clonedState) {
            setProperty(state, fieldName, clonedState[fieldName]);
        }
        if (dirty) {
            // Recalculate the modified state
            this.input = diff(state, this.original, false);
        }
        this.setState({ isDirty: !!Object.keys(this.input).length, fields: state });
    };

    getOriginalState = field => {
        return getProperty(this.original, field);
    };

    setOriginalState = newState => {
        const { fields } = this.state;
        for (let fieldName in newState) {
            setProperty(fields, fieldName, newState[fieldName]);
        }
        this.input = [];
        Object.assign(this.original, cloneDeep(fields));
        this.setState({ isDirty: false, fields });
    };

    getIsDirtyField = field => {
        const original = this.getOriginalState(field);
        const current = this.getNestedState(field);
        const isDiff = diff([current], [original], false);
        return isDiff.length !== 0;
    };

    updateFormState(props) {
        const { id, tab, loading, data, onLoad } = props;
        const newState = {};
        if (id !== this.props.id) {
            // Clear the state when loading a new funeral
            this.original = {};
            this.loaded = {};
            Object.keys(this.state.fields).forEach(key => (newState[key] = undefined));
        }
        if (!loading && data) {
            if (tab) {
                if (!this.loaded[tab.key]) {
                    this.loaded[tab.key] = true;
                    const original = deleteTypeName(cloneDeep(data));
                    if (onLoad) onLoad(original);
                    if (tab.onLoad) tab.onLoad(original);
                    Object.assign(this.original, original);
                    Object.assign(newState, cloneDeep(original));
                }
            } else if (!this.loaded[0]) {
                this.loaded[0] = true;
                const original = deleteTypeName(cloneDeep(data));
                if (onLoad) onLoad(original);
                Object.assign(this.original, original);
                Object.assign(newState, cloneDeep(original));
            }
        }
        if (Object.keys(newState).length) {
            this.setNestedState(newState);
        }
    }

    /**
     * Copies over all ID fields from the src object to the tgt object.
     */
    mergeIds(src, tgt) {
        if (!src || !tgt) return;
        if (Array.isArray(src)) {
            src.forEach((_, i) => this.mergeIds(src[i], tgt[i]));
        } else if (typeof src === 'object') {
            //if (src.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (src.hasOwnProperty('ID') && !tgt.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (tgt.ID === 0 || tgt.ID === '0') tgt.ID = null; // remove ID = 0 so that new items can be saved
            Object.keys(src).forEach(k => this.mergeIds(src[k], tgt[k]));
        }
    }

    getMutation() {
        const input = cloneDeep(this.input);
        const output = cloneDeep(this.state.fields);

        const fragments = this.props.fragments || Fragments;
        const tabFragmentLookup = this.props.tabFragmentLookup || FIELD_TAB_LOOKUP;
        const tabs = this.props.tabs || TAB;

        // Recurse all nested objects of the original and make sure IDs are included in the input.
        this.mergeIds(this.original, input);

        if (this.props.tab) {
            // Go through all modified keys to determine which tabs have been modified
            const modifiedTabs = {};
            const requiredFields = ['ID', 'LegacyKey'];
            Object.keys(input).forEach(key => {
                if (requiredFields.includes(key)) return;
                const tabKeys = tabFragmentLookup[key] || [];
                tabKeys.forEach(k => (tabs[k] ? (modifiedTabs[k] = true) : null));
            });
            this.modifiedTabKeys = Object.keys(modifiedTabs);
            this.modifiedTabKeys.forEach(k => {
                const { formatSaveData } = tabs[k];
                if (formatSaveData) formatSaveData(input, output, this.original);
            });

            if (!!this.props.whitelistFields) {
                this.purgeInput(input, '');
            }

            // Build up all the list of required fragments and field names to query in the response
            const fieldNames = [];
            const fragmentParts = [];
            const related = [];
            Object.keys(input).forEach(key => {
                if (requiredFields.includes(key)) return;
                if (typeof input[key] === 'object' && input[key] !== null) {
                    // For object fields we find the matching fragment that selects that object
                    fragmentParts.push(fragments[key]);
                } else if (key.length > 2 && key.endsWith('ID')) {
                    //this is related. like ContactID. so we nest
                    const relatedFragment = key.substring(0, key.length - 2);
                    related.push(fragments[relatedFragment]);
                } else {
                    fieldNames.push(key);
                }
            });

            /*
            console.log('modifiedTabKeys', this.modifiedTabKeys);
            console.log('fieldNames', fieldNames);
            console.log('fragments', fragments);
            console.log('related', related);
            */

            let mutationName = this.props.mutationName || 'updateFuneral';
            let mutationInputType = this.props.mutationInputType || 'UpdateFuneralInput';

            return {
                mutation: gql`
                    mutation ${mutationName}($input: ${mutationInputType}!) {
                        ${mutationName}(input: $input) {
                        ${requiredFields.join('\n')}
                        ${fieldNames.join('\n')}
                        ${fragmentParts.map(f => '...' + f.definitions[0].name.value).join('\n')}
                        ${related.map(f => '...' + f.definitions[0].name.value).join('\n')}
                    }
                    }
                    ${fragmentParts}
                    ${related}
                `,
                variables: {
                    input
                }
            };
        } else {
            // this thing doesn't work. supply your own damn mutations!
            const fragments = [];
            const updateTable = 'thing';
            const mutations = {
                mutation: gql`
                    mutation SaveData($input: UpdateDataInput!) {
                        ${updateTable}(input: $input) {
                        ID
                        LegacyKey
                        ${fragments.map(f => '...' + f.definitions[0].name.value).join('\n')}
                    }
                    }
                    ${fragments}
                `,
                variables: {
                    // id: this.original.ID,
                    input
                }
            };

            if (this.props.mutation) {
                mutations.mutation = this.props.mutation;
            }
            if (this.props.variables) {
                mutations.variables.input = this.props.variables(input);
            }

            return mutations;
        }
    }

    purgeInput = (input, prefix) => {
        const whitelist = this.props.whitelistFields;

        Object.keys(input).forEach(key => {
            if (key === 'ID') {
                return true;
            }
            const plainKey = prefix + key;
            if (whitelist.includes(plainKey)) {
                //key present in whitelist, our work there done
                return;
            } else if (typeof input[key] === 'object') {
                if (Array.isArray(input[key]) && input[key].length > 0) {
                    if (whitelist.find(allowed => allowed.startsWith(plainKey + '[].'))) {
                        //some keys lower in hierarchy are allowed, so keeping it and digging deeper
                        input[key].forEach(child => {
                            this.purgeInput(child, plainKey + '[].');
                        });
                        return;
                    }
                } else if (input !== null) {
                    if (whitelist.find(allowed => allowed.startsWith(plainKey + '.'))) {
                        //some keys lower in hierarchy are allowed, so keeping it too
                        this.purgeInput(input[key], plainKey + '.');
                        return;
                    }
                }
            }
            //key was not found so purging it out from data
            delete input[key];
        });
    };

    onHistoryBlock = (location, action) => {
        const parts = this.props.history.location.pathname.split('/');
        let currentPath = '';
        let targetPath = location.pathname;
        if (!targetPath.endsWith('/')) targetPath += '/';
        const bits = targetPath.split('/');

        // Take only the first 4 parts of the url for the current path i.e. /funeral/:key/:id/
        if (parts[1] === 'funeral' || parts[1] === 'prearrangement') {
            currentPath = parts.slice(0, 4).join('/') + '/';
        } else if (parts[1] === 'quotes' && parts[2] === 'enquiry') {
            currentPath =
                parts.slice(0, 3).join('/') + (bits[3] ? '/' + bits[3] + (parts[4] ? '/' + parts[4] : '') : '') + '/';

            if (!targetPath === currentPath && this.state.isDirty) {
                return 'You have unsaved changes, are you sure you want to leave this page?';
            }
        } else {
            currentPath = parts.join('/') + '/';
        }

        // We don't block the navigation if we're navigate to another form page for the
        // same funeral, or if we haven't made any changes to the funeral
        if (!targetPath.startsWith(currentPath) && this.state.isDirty) {
            return 'You have unsaved changes, are you sure you want to leave this page?';
        }
    };

    isValidForm = () => {
        const { validation, tabs } = this.props;
        if (!isNullOrUndefined(validation)) {
            return validationUtility.validate(this, validation);
        } else if (enableValidation) {
            return validationUtility.validateTabs(this, tabs || TAB);
        }
        return true;
    };

    onSave = e => {
        e.preventDefault();
        this.saveAttempted = true;
        const { onBeforeSave, requireValid = true } = this.props;

        //validation
        if (!this.isValidForm() && !!requireValid) {
            this.forceUpdate();
            return;
        }

        if (onBeforeSave) {
            onBeforeSave(this).then(() => this.executeSave());
            this.savePending = true;
            this.forceUpdate();
        } else {
            this.executeSave();
        }
    };

    executeSave() {
        const { customClient, client } = this.props;
        (customClient || client)
            .mutate(this.getMutation())
            .then(this.onSaveCompleted)
            .catch(this.handleMutateError);
        this.savePending = true;
        this.forceUpdate();
    }

    onSaveCompleted = ({ data }) => {
        const updates = Object.keys(data)[0];

        this.savePending = false;

        const { onSaved, onLoad, customStateModifier } = this.props;
        const tabs = this.props.tabs || TAB;

        let updated = deleteTypeName(cloneDeep(data[updates]), true); // keep deletions
        if (onLoad) onLoad(updated);
        if (this.modifiedTabKeys && this.modifiedTabKeys.length) {
            this.modifiedTabKeys.forEach(tabKey => {
                const onLoadTab = tabs[tabKey].onLoad;
                if (onLoadTab) onLoadTab(updated);
            });
        }
        let updatedState = { isDirty: false };
        if (customStateModifier) {
            customStateModifier(this, updated);
        } else {
            this.original = Object.assign(this.original, updated);
            updatedState.fields = deleteTypeName(cloneDeep(this.original)); // remove deletions
        }

        this.setState(updatedState);

        if (onSaved) {
            onSaved(data);
        }

        this.props.setSnackbarMessage('Success, your changes have been saved.', true);
    };

    handleMutateError = e => {
        this.savePending = false;
        console.error('A GQL Mutation Error Occurred:', e);
        this.props.setSnackbarMessage(
            'Oops, there was an error - your changes have NOT saved.',
            false,
            null,
            new Error(e)
        );
    };

    renderNotFound() {
        return (
            <div style={{ padding: '24px 32px' }}>
                <p>
                    <strong>Data not found.</strong>
                </p>
                <p>Sorry, the information you requested does not exist. Please check your details and try again.</p>
            </div>
        );
    }

    render() {
        const { children, error, loading: queryLoading, data, refetch, continuousValidation } = this.props;
        if (error) {
            console.error(error);
            return 'Error loading data.';
        }
        if (!queryLoading && data === null) {
            //return 'Data source not found.';
            if (this.props.tab && !this.loaded[this.props.tab.key]) return this.renderNotFound();
            else if (!this.loaded[0]) return this.renderNotFound();
        }

        const form = {
            getOriginal: property => this.getOriginalState(property),
            fields: this.state.fields,
            setField: this.setNestedState,
            getField: this.getNestedState,
            validation: this.state.validation,
            validateForm: this.isValidForm,
            getValidation: this.getValidation,
            loading: queryLoading,
            isDirty: !!this.state.isDirty,
            isDirtyField: this.getIsDirtyField,
            doRefetch: whenDone =>
                refetch().then(thing => {
                    const { id, tab, onLoad } = this.props;
                    let newData = {};
                    for (var prop in thing.data) {
                        newData = thing.data[prop]; // assumes only one data point
                        break;
                    }
                    const newProps = { loading: false, data: newData, id, tab, onLoad };
                    if (tab) this.loaded[tab.key] = false;
                    this.updateFormState(newProps);
                    whenDone(newData);
                })
        };
        if (!!continuousValidation) this.isValidForm();

        const button = this.renderSave();

        return (
            <div style={{ position: 'relative' }}>
                <form noValidate aria-live="assertive">
                    {children(form, button)}
                </form>
            </div>
        );
    }

    renderSave() {
        const { classes, buttonStyles, loading, allowSave } = this.props;

        if (allowSave !== undefined && !allowSave) return;

        const hasSavedData = Object.keys(this.original).length > 0;
        if (!hasSavedData && loading) return;

        const disabled = loading || this.savePending || !this.state.isDirty;
        return (
            <ModalPrimaryButton
                className={cx(classes.button, buttonStyles)}
                onClick={disabled ? undefined : e => this.onSave(e)}
                disabled={disabled}
            >
                {this.renderButtonState()}
            </ModalPrimaryButton>
        );
    }

    renderButtonState() {
        const { classes, buttonLabels } = this.props;

        const { isSaving, isUnsaved, isNew, isSaved } = buttonLabels || {};
        if (this.savePending) {
            return (
                <Fragment>
                    <Spinner className={classes.buttonIcon} />
                    <span className={classes.svgLabel}>{isSaving || 'Saving...'}</span>
                </Fragment>
            );
        } else if (Object.keys(this.original).length === 0) {
            return (
                <Fragment>
                    <SaveIcon />
                    <span className={classes.svgLabel}>{isNew || 'Save New'}</span>
                </Fragment>
            );
        } else if (this.state.isDirty) {
            return (
                <Fragment>
                    <SaveIcon />
                    <span className={classes.svgLabel}>{isUnsaved || 'Save Changes'}</span>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Icon className={classes.buttonIcon}>check_circle_outline</Icon>
                <span className={classes.svgLabel}>{isSaved || 'Saved'}</span>
            </Fragment>
        );
    }

    setField = (newState, dirty) => this.setNestedState(newState, dirty);

    getField = field => this.getNestedState(field);
}

const styles = ({ zIndex, palette, breakpoints }) => ({
    button: {
        border: '1.5px solid' + palette.button.save,
        borderRadius: 24,
        color: '#ffffff',
        position: 'fixed',
        right: 24,
        bottom: 15,
        zIndex: zIndex.drawer + 2,
        minWidth: 180,
        minHeight: 48,
        boxShadow: 'none !important',
        backgroundColor: palette.button.save,
        textTransform: 'none',
        '& span > svg': {
            height: '20px',
            width: '20px',
            // marginRight: 12,
            fill: '#67F7A6'
        },
        '&:hover': {
            border: '1.5px solid' + palette.button.save,
            backgroundColor: '#ffffff',
            color: palette.button.save
        },
        [breakpoints.down('xs')]: {
            minWidth: 36,
            bottom: 15,
            right: 29
        }
    },
    buttonIcon: {
        // marginRight: 8
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            // display: 'none'
        }
    }
});

export default compose(withApollo, withSnackbarMessage, withRouter, withStyles(styles))(DataForm);
