import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment ClientFields on Prearrangement {
                Type
                FirstName
                MiddleName
                Surname
                KnownAs
                FamilyNameAtBirth
                DateOfBirth
                Gender
                Age
                ResidentialAddress1
                ResidentialAddress2
                ResidentialTown
                ResidentialState
                ResidentialCountry
                PostCode
                PlaceOfBirth1
                PlaceOfBirthCity
                PlaceOfBirthState
                PlaceOfBirthPostcode
                PlaceOfBirthCountry
                PlaceOfBirthLocation
                Occupation
                Retired
                Pensioner
                PensionType
                Aboriginal
                TorresStrait
                OriginatingOffice
                ContractAmount
                TravelProtection
                FuneralFundCode
                FuneralFundName
                ClientChangesFromPrePlan
            }
        `
    ],
    'Prearrangement'
);
