import React, { Component } from 'react';
import CloseIcon from '../../../component/icon/CloseIcon';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Fab from '@material-ui/core/Fab';
import { compose, Mutation, Query } from 'react-apollo';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { DocumentFields } from '../../documentation/DocumentationFragments';
import PrimaryButton, { SaveButton } from '../../../component/form/PrimaryButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MemorialBookIcon, RepeatIcon, SaveIcon } from '../../../component/IconIndex';
import Grid from '../../../component/form/Grid';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import { shortDateTimeFromString } from '../../../util/date';
import { getClient } from '../../../apollo';
import Spinner from '../../../component/Spinner';

const readBook = gql`
    query readMemorialBook ($id: ID!) {
        readOneMemorialBook(filter: {FuneralID: {eq: $id}}) {
            ID
            FuneralID
            PDF {
                ${DocumentFields}
                LastEdited
            }
        }
    }
`;

const updateBook = gql`
    mutation updateMemorialBook ($id: ID!, $funeralID: ID!) {
        updateMemorialBook (input: {ID: $id, FuneralID: $funeralID }) {
            ID
            FuneralID
            PDF {
                ${DocumentFields}
                LastEdited
            }
        }
    }
`;

const createBook = gql`
    mutation createMemorialBook ($funeralID: ID!) {
        createMemorialBook (input: {FuneralID: $funeralID}) {
            ID
            FuneralID
            PDF {
                ${DocumentFields}
                LastEdited
            }
        }
    }
`;

class MemorialBook extends Component {
    state = {
        open: false
    };

    static getDerivedStateFromProps(newProps, oldState) {
        if (newProps.open && !oldState.open) {
            return { open: true };
        }
        return null;
    }

    render() {
        const { funeralID } = this.props;
        return (
            <Grid bucket>
                <InlineHeader header="Memorial Book" />
                {funeralID ? (
                    <Query query={readBook} variables={{ id: funeralID }}>
                        {({ data, loading, error, networkStatus }) => {
                            if (error) return 'There was an error loading the memorial book, try again later.';
                            if (loading && networkStatus === 1) return 'Loading...';
                            const { readOneMemorialBook } = data || {};
                            const hasMemorialBook = readOneMemorialBook && readOneMemorialBook.ID > 0;
                            const urlMemorialBook =
                                !!hasMemorialBook &&
                                readOneMemorialBook.PDF.ID > 0 &&
                                readOneMemorialBook.PDF.AbsoluteLink;
                            const { LastEdited } = !!hasMemorialBook ? readOneMemorialBook.PDF : {};

                            return (
                                <Grid item xs={12}>
                                    <p style={{ marginTop: 0 }}>
                                        {!!urlMemorialBook ? (
                                            <>Memorial Book was last updated {shortDateTimeFromString(LastEdited)}.</>
                                        ) : (
                                            <>The memorial book has not been created.</>
                                        )}
                                    </p>
                                    <InlineField>
                                        {!!urlMemorialBook && (
                                            <PrimaryButton disabled={loading} onClick={this.toggleMemorialBookModal}>
                                                <MemorialBookIcon /> View Memorial Book...
                                            </PrimaryButton>
                                        )}

                                        <Mutation
                                            mutation={hasMemorialBook ? updateBook : createBook}
                                            onCompleted={this.updateCache}
                                            onError={this.onError}
                                        >
                                            {(mutate, { loading: busy }) => {
                                                const onSubmit = e => {
                                                    if (e) e.preventDefault();
                                                    mutate({
                                                        variables: {
                                                            funeralID,
                                                            id: readOneMemorialBook ? readOneMemorialBook.ID : 0
                                                        }
                                                    });
                                                };

                                                return (
                                                    <>
                                                        <SaveButton disabled={busy} onClick={onSubmit}>
                                                            {busy ? (
                                                                <Spinner />
                                                            ) : urlMemorialBook ? (
                                                                <RepeatIcon />
                                                            ) : (
                                                                <SaveIcon />
                                                            )}
                                                            {urlMemorialBook
                                                                ? busy
                                                                    ? 'Updating...'
                                                                    : 'Update'
                                                                : busy
                                                                ? 'Creating...'
                                                                : 'Create Memorial Book'}
                                                        </SaveButton>
                                                    </>
                                                );
                                            }}
                                        </Mutation>
                                    </InlineField>
                                </Grid>
                            );
                        }}
                    </Query>
                ) : (
                    ''
                )}
                {this.renderModal()}
            </Grid>
        );
    }

    renderModal() {
        const { classes, funeralID } = this.props;
        const { open } = this.state;
        if (!open) return null;
        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={open}
                onClose={() => this.onClose()}
                className={classes.root}
            >
                <>
                    <div className={classes.buttonClose}>
                        <Fab onClick={() => this.onClose()}>
                            <CloseIcon />
                        </Fab>
                    </div>

                    <div className={classes.paper}>
                        <Query query={readBook} variables={{ id: funeralID }}>
                            {results => this.renderBook(results)}
                        </Query>
                    </div>
                </>
            </Dialog>
        );
    }

    renderBook = ({ data, loading, error }) => {
        if (error) return 'error';
        const { classes } = this.props;
        const bookData = Object.keys(data)[0];
        const readOneMemorialBook = data[bookData];
        const hasMemorialBook = readOneMemorialBook && readOneMemorialBook.ID > 0;
        const urlMemorialBook =
            hasMemorialBook && readOneMemorialBook.PDF.ID > 0 && readOneMemorialBook.PDF.AbsoluteLink;

        return (
            <div className={classes.paperScroll} style={{ height: hasMemorialBook ? 800 : null }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {loading && <LinearProgress />}
                    {hasMemorialBook && (
                        <iframe
                            src={
                                urlMemorialBook + '?modified=' + encodeURIComponent(readOneMemorialBook.PDF.LastEdited)
                            }
                            style={{ width: '100%', height: '100%' }}
                            title="memorialbook-pdf"
                        />
                    )}
                </div>
            </div>
        );
    };

    toggleMemorialBookModal = () => {
        this.setState({ open: true });
    };

    updateCache = data => {
        const { funeralID } = this.props;
        const client = getClient();
        const prop = Object.keys(data)[0];
        client.writeQuery({
            query: readBook,
            data: { readOneMemorialBook: { ...data[prop] } },
            variables: { id: funeralID }
        });
        this.setState({ open: true });
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({ open: false });
    };

    onError = obj => {
        const errorMessage = obj && obj.message ? obj.message : 'Failed to save memorial Book PDF.';
        this.props.setSnackbarMessage(errorMessage);
    };
}

const styles = ({ spacing, funeralHome, palette, transitions }) => ({
    root: {
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            overflowX: 'visible',
            borderRadius: '10px',
            maxWidth: 'unset',
            '& > div': {
                overflowY: 'unset',
                flexDirection: 'unset'
            }
        }
    },
    paper: {
        maxWidth: 'calc(100vw - 64px)',
        width: 660,
        maxHeight: 'calc(100vh - 32px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: 18,
        '&:hover div.theNav': {
            display: 'flex',
            '& button svg': {
                fontSize: '3rem'
            }
        }
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        marginTop: 0,
        padding: '12px 0',
        //height: 800,
        position: 'relative'
    },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        zIndex: 1,
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(MemorialBook);
