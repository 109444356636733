import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '../../../component/Spinner';
import { compose } from 'react-apollo';
import CloseIcon from '../../../component/icon/CloseIcon';
import { OutlineButton } from '../../../component/form/PrimaryButton';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { prettyXML } from '../../../util/strings';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BiggerMessageBar from '../../../component/BiggerMessageBar';

class WebServiceLogModal extends Component {
    state = {
        log: null,
        document: null,
        loading: false
    };

    render() {
        const { classes, log, onClose, ...other } = this.props;

        if (!log) {
            return null;
        }

        const { loading } = this.state;

        return (
            <Dialog {...other} className={classes.root}>
                <DialogTitle className={classes.modalTitle}>
                    LifeLink Response #{log.ID}
                    {log.TestMode && ': TEST ONLY'}
                </DialogTitle>
                {loading ? (
                    <div className={classes.loading}>
                        <Spinner />
                    </div>
                ) : (
                    <Fragment>
                        <DialogContent>
                            <BiggerMessageBar messageType={log.Result === 'Success' ? 'good' : 'error'}>
                                {log.Result}
                            </BiggerMessageBar>
                            <div className={classes.modalContent}>
                                <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary
                                        classes={{ root: classes.panelHead }}
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        Response Received
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.modalContentResponse}>
                                        {prettyXML(log.Response || log.ResponseBody || '(none)')}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        classes={{ root: classes.panelHead }}
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        Data Sent
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.modalContentResponse}>
                                        {(log.Request && prettyXML(log.Request)) || '(unknown)'}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <OutlineButton onClick={onClose}>
                                <CloseIcon />
                                <span className={classes.svgLabel}>Close</span>
                            </OutlineButton>
                        </DialogActions>
                    </Fragment>
                )}
            </Dialog>
        );
    }
}

const styles = theme => ({
    root: {
        '& > div:nth-child(2) > div': {
            maxWidth: 1024,
            width: '98vw',
            maxHeight: '94vh',
            [theme.breakpoints.down('md')]: {
                margin: 16
            }
        }
    },

    modalTitle: {
        padding: 24,
        margin: 0,
        marginBottom: 20,
        background: theme.palette.contentForeground[theme.funeralHome],
        '& > h2': {
            color: '#FFFFFF',
            fontWeight: 'lighter',
            [theme.breakpoints.up('md')]: {
                fontSize: 26
            }
        }
    },

    modalFooter: {
        padding: '10px 20px 20px',
        margin: 0
    },

    panelHead: {
        backgroundColor: theme.palette.contentBackground[theme.funeralHome],
        color: theme.palette.contentForeground[theme.funeralHome],
        position: 'sticky',
        top: 0,
        zIndex: 1,
        minHeight: 64
    },

    modalContentResponse: {
        //maxHeight: 'calc(94vh - 260px)',
        overflow: 'auto'
    },

    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            flex: 1,
            margin: theme.spacing.unit * 2 + 'px 0',
            boxShadow: 'none',
            '&:before': {
                height: 0
            }
        }
    },

    loading: {
        minHeight: '300px',
        minWidth: '400px',
        display: 'flex',
        justifyContent: 'space-around',
        '& > div': {
            alignSelf: 'center'
        }
    }
});

// prettier-ignore
export default compose(
    withStyles(styles)
)(WebServiceLogModal);
