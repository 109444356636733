import { ChildrenDetails, Marriages, Parent1, Parent2 } from '../PrearrangementFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment PrearrangementFamilyFields on Prearrangement {
                DeFacto
                DefactoGivenName
                DefactoSurname
                DefactoAge
                DefactoAgeType

                Surname
                Gender
                FamilyChangesFromPrePlan
            }
        `,
        ChildrenDetails,
        Marriages,
        Parent1,
        Parent2
    ],
    'Prearrangement'
);
