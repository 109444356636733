import gql from 'graphql-tag';
import { OrdersFragmentInclude } from './Bookings';

export default gql`
    fragment Caterers on Funeral {
        Caterers {
            ${OrdersFragmentInclude}
        }
    }
`;
