import gql from 'graphql-tag';

export default gql`
    fragment CateringStaffs on Funeral {
        CateringStaffs {
            ID
            StaffID
            #Unavailable
            UnavailableComment
            #ShiftPenaltyCount
            #ShiftPenaltyReasonsId
            Confirmed
        }
    }
`;
