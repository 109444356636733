import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabbedModal from '../../../../component/form/TabbedModal';
import Spinner from '../../../../component/Spinner';
import { withSnackbarMessage } from '../../../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import ModalStepTitle from '../../../../component/form/ModalStepTitle';
import CloseIcon from '../../../../component/icon/CloseIcon';
import { OutlineButton } from '../../../../component/form/PrimaryButton';
import { prettyPrice } from '../../../../util/strings';
import TickIcon from '../../../../component/icon/TickIcon';
import TableData from '../../../../component/form/TableData';
import { flattenConnection } from '../../../../util/functions';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import { quoteTypes } from '../../../../util/quotes';
import { GST } from '../../../../util/products';
import { lookupItemSource } from '../../../quotes/quotes-modal/QuotesModal';
import BiggerMessageBar from '../../../../component/BiggerMessageBar';

class QuoteModal extends Component {
    state = {
        tabIndex: 0,
        quoteIndex: null,
        quote: null,
        loading: false
    };

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    render() {
        const { classes, form, quote, onClose, ...other } = this.props;

        if (!quote) {
            return null;
        }

        const { tabIndex, loading } = this.state;

        const quoteType = Object.keys(quoteTypes).find(key => quoteTypes[key] === quote.QuoteType);

        return (
            <TabbedModal
                tabIndex={tabIndex}
                tabs={['Quote #' + quote.ID, 'View PDF']}
                onChange={this.onChangeTab}
                {...other}
            >
                <div style={{ position: 'relative' }}>
                    {loading ? (
                        <div className={classes.loading}>
                            <Spinner />
                        </div>
                    ) : (
                        <div className={classes.paper}>
                            <div>
                                <ModalStepTitle header={quoteType + ' Quote:'} title={`${quote.Title}`} />
                                {(tabIndex === 0 && (
                                    <div className={classes.columnRoot}>{this.renderTable(quote)}</div>
                                )) || <div className={classes.columnRoot}>{this.renderPreview(quote)}</div>}
                            </div>
                        </div>
                    )}
                    <DialogActions className={classes.actionsBox}>
                        <div className={classes.actions}>
                            <OutlineButton onClick={onClose} color="primary" disabled={loading}>
                                <CloseIcon />
                                <span className={classes.svgLabel}>Close</span>
                            </OutlineButton>
                        </div>
                    </DialogActions>
                </div>
            </TabbedModal>
        );
    }

    renderPreview = quote => {
        return (
            <div>
                <Typography>Total of Quote (Incl GST): {prettyPrice(quote.CalcQuoteTotal)}</Typography>
                <br />

                <div style={{ background: 'grey' }}>
                    {quote.PDFLastEdited && quote.AbsolutePDFLink && quote.AbsolutePDFLink.endsWith('.pdf') ? (
                        <iframe
                            src={quote.AbsolutePDFLink + '?modified=' + encodeURIComponent(quote.PDFLastEdited)}
                            style={{ width: '100%', height: 595 }}
                            title="quote-pdf"
                        />
                    ) : (
                        <BiggerMessageBar messageType={'error'}>Sorry, file is missing.</BiggerMessageBar>
                    )}
                </div>
            </div>
        );
    };

    renderTable = rawQuote => {
        const { classes } = this.props;
        const quote = { ...rawQuote };
        flattenConnection(quote, 'QuoteItems');

        let quoteTotal = 0;
        const data = quote.QuoteItems.map(e => {
            const source = lookupItemSource(e);
            const needGST = !!source.GST || false;
            const price = !!needGST ? Number(e.Price) * (GST + 1) : 0;
            const Total = !!e.Complimentary || !!e.Optional || !!e.Prepaid ? 0 : Number(e.Qty) * price;
            const addedGST = !!e.Complimentary || !!e.Optional || !!e.Prepaid || !needGST ? 0 : price / (GST * 100 + 1);
            const SubTotal = Total - addedGST;
            const displayData = {
                data: {
                    Code: source.Code || 0,
                    Description: source.Title || '(unknown)',
                    Price: Number(price),
                    Qty: Number(e.Qty).toFixed(0),
                    Optional: e.Optional,
                    Complimentary: e.Complimentary,
                    Prepaid: e.Prepaid,
                    SubTotal: SubTotal.toFixed(2),
                    Total: Total.toFixed(2),
                    GST: addedGST.toFixed(2)
                    //TODO: get saved GST value from QuoteItem
                }
            };
            quoteTotal = quoteTotal + Total;
            return displayData.data;
        });

        const columns = [
            { id: 'Code', label: 'Product Code' },
            { id: 'Description', label: 'Description of service' },
            {
                id: 'Price',
                label: 'Price',
                render: val => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right' }
            },
            {
                id: 'Qty',
                label: 'Quantity',
                styles: { textAlign: 'right', width: 1 }
            },
            {
                id: 'SubTotal',
                label: 'Subtotal (exGST)',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'GST',
                label: 'GST',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5rem' }
            },
            {
                id: 'Total',
                label: 'Total',
                render: (val, row, rowIndex) => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5rem' }
            },
            {
                id: 'Prepaid',
                label: 'Prepaid',
                render: (val, row, rowIndex) => {
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },

                styles: { textAlign: 'center', width: 1 }
            },
            {
                id: 'Complimentary',
                label: 'Complimentary',
                render: (val, row, rowIndex) => {
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },

                styles: { textAlign: 'center', width: 1 }
            },

            {
                id: 'Optional',
                label: 'Optional',
                render: (val, row, rowIndex) => {
                    return val ? <TickIcon color={'primary'} /> : <CloseIcon color={'disabled'} />;
                },
                styles: { textAlign: 'center', width: 1 }
            }
        ];

        return (
            <div>
                <Typography>Total of Quote (Incl GST): {prettyPrice(quoteTotal)}</Typography>
                <div className={classes.dataList}>
                    <TableData
                        columns={columns}
                        data={data}
                        footer={[
                            { id: 'Qty', label: 'TOTAL' },
                            {
                                id: 'SubTotal',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['SubTotal'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'GST',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['GST'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'Total',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['Total'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                },
                                styles: { fontWeight: 'bolder' }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    };
}

const styles = ({ breakpoints }) => ({
    root: {
        display: 'flex',
        minHeight: '33vh'
    },
    actions: {
        margin: 0,
        width: '100%',
        textAlign: 'right',
        boxShadow: '-10px -25px 25px 0px #ffffff',
        zIndex: 1,
        '& button': {
            float: 'right',
            [breakpoints.down('xs')]: {
                padding: 8,
                minWidth: 38,
                minHeight: 38
            }
        }
    },
    actionsBox: {
        margin: 0,
        padding: '10px 40px 40px',
        [breakpoints.down('xs')]: {
            padding: '24px 24px 32px'
        }
    },

    paper: {
        padding: 42,
        width: '100%',
        minHeight: '38vh',
        maxHeight: 'calc(100vh - 375px)',
        maxWidth: 1280,
        overflow: 'auto',
        [breakpoints.down('xs')]: {
            padding: 0
        }
    },
    columnRoot: {
        width: '100%',
        paddingBottom: 30
    },
    dataList: { marginTop: 16 },
    loading: {
        minHeight: '300px',
        minWidth: '400px',
        display: 'flex',
        justifyContent: 'space-around',
        '& > div': {
            alignSelf: 'center'
        }
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(QuoteModal);
