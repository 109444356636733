import gql from 'graphql-tag';
import EventConflictPartial from './EventConflictPartial';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment RefreshmentsVenue on Funeral {
        RefreshmentsVenue {
            ID
            Comment
            Requested
            Responded
            Confirmed
            Date
            Time
            Duration
            NumberOfPeople
            Type
            CateringRequired
            Location {
                ID
                Name
                State
                Suburb
                Postcode
                Phone
                Fax
                Email
                AddressBookCategory
                Mobile
                Notes
                AddressLine1
                AddressLine2
            }
            OffsiteAddressLine1
            OffsiteSuburb
            OffsiteState
            OffsiteCountry
            OffsitePostCode
            StaffAllocations {
                ID
                Title
                Allocation
                Start
                End
                Type
                Location
                Comments
                Recurring
                Member {
                    ID
                    FirstName
                    Surname
                    Email
                }
            }
            Bookings {
                ${BookingsFragmentInclude}
            }
            EventConflicts {
                ${EventConflictPartial}
            }
            CateringStaffAllocations {
                ID
                Availability
                AvailabilityDescription
                Points
                Start
                End
                Confirmed
                CateringStaff {
                    ID
                    Name
                }
            }
        }
    }
`;
