import gql from 'graphql-tag';

export default gql`
    fragment BdmResponse on Funeral {
        BdmResponse {
            AllItems {
                Key
                Value
            }
        }
    }
`;
