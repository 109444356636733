import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 17.657 13.911" {...props}>
        <path
            fill="currentColor"
            d="M4.051,50.238a.276.276,0,0,0-.391,0L1.211,52.687-.134,51.342a.276.276,0,0,0-.39,0l-.391.391a.276.276,0,0,0-.081.2.276.276,0,0,0,.081.2l1.931,1.931a.276.276,0,0,0,.39,0L4.441,51.02a.276.276,0,0,0,0-.39Zm-2.842,6.1a1.1,1.1,0,1,0,0,2.207,1.1,1.1,0,0,0,0-2.207ZM4.051,44.72a.276.276,0,0,0-.391,0L1.211,47.169-.134,45.824a.276.276,0,0,0-.39,0l-.391.391a.276.276,0,0,0-.081.2.276.276,0,0,0,.081.2l1.931,1.931a.276.276,0,0,0,.39,0L4.441,45.5a.276.276,0,0,0,0-.39ZM16.385,56.894H5.9a.276.276,0,0,0-.276.276v.552A.276.276,0,0,0,5.9,58H16.385a.276.276,0,0,0,.276-.276V57.17A.276.276,0,0,0,16.385,56.894Zm0-11.036H5.9a.276.276,0,0,0-.276.276v.552a.276.276,0,0,0,.276.276H16.385a.276.276,0,0,0,.276-.276v-.552A.276.276,0,0,0,16.385,45.858Zm0,5.518H5.9a.276.276,0,0,0-.276.276V52.2a.276.276,0,0,0,.276.276H16.385a.276.276,0,0,0,.276-.276v-.552A.276.276,0,0,0,16.385,51.376Z"
            transform="translate(0.996 -44.639)"
        />
    </SvgIcon>
);
