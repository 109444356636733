import { COMPONENT } from '../../../component/form/AddressAutocomplete';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { GetDocumentForLocation } from '../../documentation/DocumentationConstants';

export const APPLICANT_RELATIONSHIP_OPTIONS = [
    { label: 'Agent', value: 'Agent' },
    { label: 'Aunt', value: 'Aunt' },
    { label: 'Brother', value: 'Brother' },
    { label: 'Brother-in-Law', value: 'Brother-in-Law' },
    { label: 'Caseworker', value: 'Caseworker' },
    { label: 'Cousin', value: 'Cousin' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Daughter-in-Law', value: 'Daughter-in-Law' },
    { label: 'De facto', value: 'De facto' },
    { label: 'Director of Nursing', value: 'Director of Nursing' },
    { label: 'Executor', value: 'Executor' },
    { label: 'Executrix', value: 'Executrix' },
    { label: 'Ex-Spouse', value: 'Ex-Spouse' },
    { label: 'Father', value: 'Father' },
    { label: 'Father-in-Law', value: 'Father-in-Law' },
    { label: 'Foster Father', value: 'Foster Father' },
    { label: 'Foster Mother', value: 'Foster Mother' },
    { label: 'Friend', value: 'Friend' },
    { label: 'Funeral Director', value: 'Funeral Director' },
    { label: 'Goddaughter', value: 'Goddaughter' },
    { label: 'Godfather', value: 'Godfather' },
    { label: 'Godmother', value: 'Godmother' },
    { label: 'Godson', value: 'Godson' },
    { label: 'Granddaughter', value: 'Granddaughter' },
    { label: 'Grandfather', value: 'Grandfather' },
    { label: 'Grandmother', value: 'Grandmother' },
    { label: 'Grandnephew', value: 'Grandnephew' },
    { label: 'Grandniece', value: 'Grandniece' },
    { label: 'Grandson', value: 'Grandson' },
    { label: 'Great-Aunt', value: 'Great-Aunt' },
    { label: 'Great-Granddaughter', value: 'Great-Granddaughter' },
    { label: 'Great-Grandson', value: 'Great-Grandson' },
    { label: 'Great-Uncle', value: 'Great-Uncle' },
    { label: 'Guardian', value: 'Guardian' },
    { label: 'Half Brother', value: 'Half Brother' },
    { label: 'Half Sister', value: 'Half Sister' },
    { label: 'Informant', value: 'Informant' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Mother-in-Law', value: 'Mother-in-Law' },
    { label: 'Nephew', value: 'Nephew' },
    { label: 'Niece', value: 'Niece' },
    { label: 'No Relation', value: 'No Relation' },
    { label: 'Parent', value: 'Parent' },
    { label: 'Second Cousin', value: 'Second Cousin' },
    // { label: 'I am the client', value: 'Self' },
    { label: 'Sister', value: 'Sister' },
    { label: 'Sister-in-Law', value: 'Sister-in-Law' },
    { label: 'Son', value: 'Son' },
    { label: 'Son-in-Law', value: 'Son-in-Law' },
    { label: 'Step Brother', value: 'Step Brother' },
    { label: 'Step Daughter', value: 'Step Daughter' },
    { label: 'Step Father', value: 'Step Father' },
    { label: 'Step Mother', value: 'Step Mother' },
    { label: 'Step Sister', value: 'Step Sister' },
    { label: 'Step Son', value: 'Step Son' },
    { label: 'Third Cousin', value: 'Third Cousin' },
    { label: 'Uncle', value: 'Uncle' },
    { label: 'Widow', value: 'Widow' },
    { label: 'Widower', value: 'Widower' }
];

export const CERTIFICATION_OF_DEATH = [
    { label: "Coroner's Disposal Order", value: 'Coroner' },
    { label: 'Medical Certificate Cause of Death (MCCD)', value: 'MCCD' },
    { label: 'Medical Certificate Cause of Perinatal Death (MCPD)', value: 'MCPD' }
];

export const CORONERS_CAUSES = [
    { label: 'Dispensed With', value: 'Dispensed With' },
    { label: 'Held', value: 'Held' },
    { label: 'Section16A', value: 'Section16A' },
    { label: 'Terminated', value: 'Terminated' }
];

export const RESIDENTIAL_ADDRESS_COMPONENTS = {
    [COMPONENT.line1]: 'Informant.ResidentialAddress1',
    [COMPONENT.city]: 'Informant.SuburbCityAust',
    [COMPONENT.state]: 'Informant.ResidentialState',
    [COMPONENT.code]: 'Informant.ResidentialPostcode',
    [COMPONENT.country]: 'Informant.Country'
};

export const POSTAL_ADDRESS_COMPONENTS = {
    [COMPONENT.line1]: 'Informant.PostalAddressLine1',
    [COMPONENT.line2]: 'Informant.PostalAddressLine2',
    [COMPONENT.city]: 'Informant.PostalSuburb',
    [COMPONENT.state]: 'Informant.PostalState',
    [COMPONENT.code]: 'Informant.PostalPostcode',
    [COMPONENT.country]: 'Informant.PostalCountry'
};

export const getRequiredDocuments = form => {
    const requiredDocuments = [];
    const coroner = form.getField('Certification.TypeOfBdmDeathCertificate') === 'Coroner';
    const disposalType = form.getField('Disposal.CrematedOrBuried');
    const disposalDate = form.getField('Disposal.Date');
    const isCrypt =
        (form.getField('Disposal.CrematedOrBuried') === 'Buried' ||
            form.getField('Disposal.CrematedOrBuried') === 'Repatriated') &&
        (form.getField('Grave.GroundDescription') === 'Crypt' || form.getField('Grave.DepthOption') === 'Crypt');

    if (coroner) {
        requiredDocuments.push({
            description: "Coroner's Disposal Order",
            fieldName: 'Certification.FileCorDisposalOrder'
        });
    } else {
        requiredDocuments.push({
            description: 'MCCD/MCPD',
            fieldName: 'Certification.FileMedCertCause'
        });
    }

    if (disposalType === 'Cremated') {
        requiredDocuments.push({
            description: 'Statutory Declaration Form (A-Form)',
            fieldName: 'Certification.FileStatDecForm',
            formType: 'ArrangementFormStatutoryForms'
        });
        requiredDocuments.push({
            description: 'Crematorium Booking Form',
            fieldName: 'Certification.FileCremBookingForm',
            formType: 'ArrangementFormCremationBooking'
        });
        if (
            (!!disposalDate && new Date(disposalDate) > new Date('2024-10-01 00:00:00')) ||
            (!disposalDate && new Date() > new Date('2024-10-01 00:00:00'))
        ) {
            requiredDocuments.push({
                description: 'Contract for Cremation',
                fieldName: 'Certification.FileCremContractForm',
                formType: 'ArrangementFormCremationBooking'
            });
        }
        if (coroner) {
            requiredDocuments.push({
                description: "Coroner's Cremation Permit",
                fieldName: 'Certification.FileCorCremPermit'
            });
        } else {
            requiredDocuments.push({
                description: 'Attending Practitioner Certificate of Cremation',
                fieldName: 'Certification.FileCremCertificate'
            });

            if (isRelatedObjectDefined(form.getField('Certification.Referee'))) {
                requiredDocuments.push({
                    description: 'Refereeing Doctor Form',
                    fieldName: 'Certification.FileRefereeingDoctorForm'
                });
            }
        }
    } else if (!!isCrypt) {
        requiredDocuments.push({
            description: 'Burial Booking Form',
            fieldName: 'Certification.FileBurialBookingForm',
            formType: 'ArrangementFormBurialBooking'
        });
        requiredDocuments.push({
            description: 'Embalming Certificate',
            fieldName: 'Certification.FileEmbalmingCert'
        });
        requiredDocuments.push({
            description: 'Disclaimer',
            fieldName: 'Certification.FileDisclaimer'
        });
    } else if (disposalType === 'Buried') {
        requiredDocuments.push({
            description: 'Burial Booking Form',
            fieldName: 'Certification.FileBurialBookingForm',
            formType: 'ArrangementFormBurialBooking'
        });
    }
    /* not sure if these conditions need fields:
    else if (disposalType === 'Repatriated') {

    } else if (disposalType === 'Body Donation') {

    } else if (disposalType === 'Body Not Recovered') {

    }
    */

    return requiredDocuments;
};

export const allRequiredDocumentFields = [
    'Certification.FileCorDisposalOrder',
    'Certification.FileMedCertCause',
    'Certification.FileStatDecForm',
    'Certification.FileCorCremPermit',
    'Certification.FileCremCertificate',
    'Certification.FileCremBookingForm',
    'Certification.FileCremContractForm',
    'Certification.FileRefereeingDoctorForm',
    'Certification.FileBurialBookingForm',
    'Certification.FileEmbalmingCert',
    'Certification.FileDisclaimer'
];

export const getSignedArrangementFormForDocument = (form, fieldName, formType) => {
    if (formType === undefined) return false;
    const arrangement = form.getField('Arrangement') || {};
    const forms = arrangement.ArrangementForms || [];
    const formLink = GetDocumentForLocation(fieldName, form).path;

    for (let i = 0; i < forms.length; i++) {
        if (forms[i].Type === formType && forms[i].Signed && forms[i].PDF && forms[i].PDF.AbsoluteLink) {
            return { ...forms[i], formLink };
        }
    }
    return { formLink };
};
