import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from './TextField';
import LinkButton from './LinkButton';
import PrimaryButton from './PrimaryButton';

/**
 * Creates a button that can be clicked to add optional information in a TextField.
 * Button is hidden when TextField is not empty.
 */

class OptionalTextField extends Component {
    state = {
        showButton: true,
        autoFocus: false
    };

    static getDerivedStateFromProps({ value, name, form }, { showButton }) {
        if (!value && form) value = form.getField(name) || null;
        if (value && !!showButton) return { showButton: false };
        return null;
    }

    onChangeTextField = e => {
        const props = this.props;
        if (props.onChange) props.onChange(e);
        if (e.defaultPrevented) return;
    };

    onBlurTextField = e => {
        const props = this.props;
        if (props.onBlur) props.onBlur(e);
        if (!e.target.value || e.target.value.length === 0) this.setState({ showButton: true });
    };

    onClickPrimaryButton = () => {
        this.setState({ showButton: false, autoFocus: true });
    };

    render() {
        const { showButton, autoFocus } = this.state;
        if (showButton) {
            const { buttonlabel, classes, linkbutton } = this.props;

            if (linkbutton) {
                return (
                    <LinkButton
                        onClick={this.onClickPrimaryButton}
                        className={classes.addButtonDesktop}
                        text={buttonlabel}
                    />
                );
            }

            return <PrimaryButton onClick={this.onClickPrimaryButton}>{buttonlabel}</PrimaryButton>;
        } else {
            const { textfieldlabel, classes, ...props } = this.props;
            classes.notrequired = true; // make the linters happy.
            return (
                <TextField
                    {...props}
                    autoFocus={autoFocus}
                    label={textfieldlabel ? textfieldlabel : props.label}
                    onChange={this.onChangeTextField}
                    onBlur={this.onBlurTextField}
                />
            );
        }
    }
}

const styles = ({ funeralHome, palette }) => ({});

export default withStyles(styles)(OptionalTextField);
