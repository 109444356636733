import Family from '../../funeral/family/Family';
import FamilyFragment from './FamilyFragment';
import { flattenConnection } from '../../../util/functions';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { joinValidationResults, messages, validationHelper } from '../../../util/validation';
import { ChildFieldVerboseNames, MarriageFieldVerboseNames } from '../../funeral/family/FamilyConstants';
import { isNullOrUndefined } from '../../../util/objects';

export default {
    label: 'Family',
    component: Family,
    fragment: FamilyFragment,
    onLoad: data => {
        flattenConnection(data, 'ChildrenDetails');
        flattenConnection(data, 'Marriages');
        data.FamilyChangesFromPrePlan = JSON.parse(data.FamilyChangesFromPrePlan) || {};
    },
    validation: {
        onValidate: {
            Marriages: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromPrePlan') || {};
                const existingMarriages = [];

                const validationResults = [];
                (persistedValue || []).some((marriage, index) => {
                    const invalidFields = [];
                    const suggestedFields = [];
                    existingMarriages.push(marriage.ID);

                    //if this is the current marriage, we only validate for 'Married' status. others don't matter
                    if (index === 0) {
                        if (marriage.Type !== 'Married') {
                            return true;
                        }

                        if (stringIsNullOrEmpty(marriage.FirstName)) {
                            invalidFields.push(`Marriages[${index}].FirstName`);
                        }

                        if (Number(marriage.AgeMarried) < 0) {
                            invalidFields.push(`Marriages[${index}].AgeMarried`);
                        }

                        if (stringIsNullOrEmpty(marriage.Suburb)) {
                            suggestedFields.push(`Marriages[${index}].Suburb`);
                        }

                        if (invalidFields.length > 0)
                            validationResults.push(validationHelper.required(messages.required, invalidFields));

                        if (suggestedFields.length > 0)
                            validationResults.push(validationHelper.suggested(messages.suggested, suggestedFields));

                        return false;
                    }
                    return false;
                });

                if (changes.Marriages !== undefined) {
                    const newMarriages = [];
                    changes.Marriages.forEach(marriage => {
                        if (existingMarriages.indexOf(marriage.ID) === -1) {
                            const fields = [];
                            MarriageFieldVerboseNames.forEach(element => {
                                fields.push(`${element.Name}: ${marriage[element.Field]}`);
                            });
                            newMarriages.push(fields.join('; '));
                        }
                    });
                    if (newMarriages.length > 0) {
                        return validationHelper.optional('Family entered: ' + newMarriages.join(' // '));
                    }
                }

                return joinValidationResults(validationResults);
            },

            ChildrenDetails: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromPrePlan') || {};
                const existingChildren = [];

                const validationResults = [];
                (persistedValue || []).forEach((child, index) => {
                    const invalidFields = [];
                    existingChildren.push(child.ID);

                    if (stringIsNullOrEmpty(child.GivenName)) {
                        invalidFields.push(`ChildrenDetails[${index}].GivenName`);
                    }

                    //either dob or age is required. if dob is null, the Age field will be showing. so show the validation error on that
                    if (isNullOrUndefined(child.DateOfBirth) && Number(child.Age) < 0) {
                        invalidFields.push(`ChildrenDetails[${index}].Age`);
                    }

                    if (stringIsNullOrEmpty(child.Gender)) {
                        invalidFields.push(`ChildrenDetails[${index}].Gender`);
                    }

                    if (invalidFields.length > 0)
                        validationResults.push(validationHelper.required(messages.required, invalidFields));
                });

                if (changes.ChildrenDetails !== undefined) {
                    const newChildren = [];
                    changes.ChildrenDetails.forEach(child => {
                        if (existingChildren.indexOf(child.ID) === -1) {
                            const fields = [];
                            ChildFieldVerboseNames.forEach(element => {
                                fields.push(`${element.Name}: ${child[element.Field]}`);
                            });
                            newChildren.push(fields.join('; '));
                        }
                    });
                    if (newChildren.length > 0) {
                        return validationHelper.optional('Family entered: ' + newChildren.join(' // '));
                    }
                }

                return joinValidationResults(validationResults);
            }
        }
    }
};
