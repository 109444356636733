import { isNullOrUndefined } from './objects';

export const IndexOf = (array, func) => {
    for (let x = 0; x < array.length; x++) {
        if (func(array[x])) return x;
    }
    return -1;
};

/**
 * gets the added and removed items from two arrays
 */
export const ArrayDiff = (existingArray, newArray, compareFunc) => {
    const removedItems = [];
    for (let x = 0; x < existingArray.length; x++) {
        if (!ArrayContains(newArray, existingArray[x], compareFunc)) removedItems.push(existingArray[x]);
    }

    const addedItems = [];
    for (let x = 0; x < newArray.length; x++) {
        if (!ArrayContains(existingArray, newArray[x], compareFunc)) addedItems.push(newArray[x]);
    }

    return { removedItems, addedItems };
};

/**
 * works out if the arays are equal
 * @param {*} a
 * @param {*} b
 * @param {*} compareFunc
 */
export const ArraysAreEqual = (a, b, compareFunc) => {
    if (isNullOrUndefined(a)) return isNullOrUndefined(b);

    if (isNullOrUndefined(b)) return false;

    if (a.length !== b.length) return false;

    for (let x = 0; x < a.length; x++) {
        if (!ArrayContains(b, a[x], compareFunc)) return false;
    }

    return true;
};

/**
 * checks if the array contains
 */
export const ArrayContains = (array, item, compareFunc) => {
    for (let x = 0; x < array.length; x++) {
        if (compareFunc(item, array[x])) return true;
    }

    return false;
};

export const indexOf = (array, func) => {
    for (let x = 0; x < array.length; x++) {
        if (func(array[x])) return x;
    }
    return -1;
};

export const removeById = (array, id) => {
    const index = indexOf(array, x => x.ID === id);

    if (index < 0) {
        return;
    }

    array.splice(index, 1);
};

/*
joins an array of sub arrays into one array
*/
export const joinArray = array => {
    let merged = [];
    for (let x = 0; x < array.length; x++) {
        merged = merged.concat(array[x]);
    }
    return merged;
};
