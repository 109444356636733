import gql from 'graphql-tag';
import { ProductPriceOverrides, VariationPriceOverrides } from '../../fragments/Product';

//for some reason, it doesn't like nesting this inside the Invoices collection. nasty
export const Quote = gql`
    ${ProductPriceOverrides}
    ${VariationPriceOverrides}
    fragment Quote on Quote {
        ID
        LastEdited
        LegacyKey
        Created
        Title
        QuoteType
        Released
        Accepted
        RespondedDate
        AbsolutePDFLink
        PDFLastEdited
        CalcQuoteTotal
        AcceptedBy {
            ID
            Email
            FirstName
            Surname
        }
        CreatedBy {
            ID
            Email
            FirstName
            Surname
        }
        Enquiry {
            ID
        }
        QuoteItems {
            edges {
                node {
                    ID
                    Qty
                    Complimentary
                    Optional
                    Prepaid
                    Price
                    Title
                    TotalIncGST
                    Product {
                        ID
                        GST
                        Title
                        BasePrice
                        InternalItemID
                        ...ProductPriceOverrides
                    }
                    Variation {
                        ID
                        GST
                        ShortDescription
                        Price
                        InternalItemID
                        ...VariationPriceOverrides
                    }
                }
            }
        }
        QuoteActions {
            edges {
                node {
                    ID
                    Created
                    EmailTo
                    ActionedBy {
                        ID
                        FirstName
                        Surname
                    }
                }
            }
        }
        Arrangement {
            ID
            LegacyKey
            IsDraft
            Enquiries {
                ID
                Funeral {
                    ID
                    LegacyKey
                }
            }
        }
        QuoteTemplate {
            ID
            LegacyKey
        }
    }
`;
