import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import YearlyIncentives from './components/YearlyIncentives';
import AverageRating from './components/AverageRating';
import NetPromoterScore from './components/NetPromoterScore';
import PerformanceGoals from './components/PerformanceGoals';
import ArrangerPerformanceArrangements from './components/ArrangerPerformanceArrangements';
import ArrangerMyPerformance from './components/ArrangerMyPerformance';
import { withRouter } from 'react-router';

class MetricsArranger extends Component {
    state = {};

    render() {
        return (
            <Fragment>
                {this.renderYearlyIncentives()}
                {this.renderArrangementPerformance()}
                {this.renderArrangementTeamPerformance()}
                {this.renderYourPerformance()}
                {this.renderTeamPerformance()}
                {this.renderAverageRating()}
                {this.renderNetScore()}
                {this.renderPerformanceGoals()}
            </Fragment>
        );
    }

    renderArrangementPerformance() {
        return <ArrangerPerformanceArrangements variant="individual" period={this.props.period} />;
    }

    renderArrangementTeamPerformance() {
        return <ArrangerPerformanceArrangements variant="team" period={this.props.period} />;
    }

    renderYourPerformance() {
        return <ArrangerMyPerformance variant="individual" period={this.props.period} />;
    }

    renderTeamPerformance() {
        return <ArrangerMyPerformance variant="team" period={this.props.period} />;
    }

    renderYearlyIncentives() {
        return <YearlyIncentives period={this.props.period} />;
    }

    renderAverageRating() {
        return <AverageRating period={this.props.period} />;
    }

    renderNetScore() {
        return <NetPromoterScore period={this.props.period} />;
    }

    renderPerformanceGoals() {
        return <PerformanceGoals period={this.props.period} />;
    }
}

const styles = ({ palette, typography, funeralHome }) => ({
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    }
});

export default compose(withRouter, withStyles(styles))(MetricsArranger);
