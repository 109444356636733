import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../../../component/form/Grid';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton, { AltButton, OutlineButton } from '../../../component/form/PrimaryButton';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { getMyClient } from '../../../apollo';
import { joinDefined, prettyJSON } from '../../../util/strings';
import { niceDateTimeFromString } from '../../../util/date';
import CloseIcon from '../../../component/icon/CloseIcon';
import { Hidden } from '@material-ui/core';
import Spinner from '../../../component/Spinner';
import Label from '../../../component/form/Label';
import LinearProgress from '@material-ui/core/LinearProgress';

class ChangelogModal extends Component {
    state = {
        open: false
    };

    onCancel() {
        this.setState({ open: false });
    }

    handleScroll = (e, onLoadMore) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            // Your logic for when the user reaches the bottom goes here
            onLoadMore();
        }
    };

    render() {
        const { open } = this.state;
        const { LegacyKey, FuneralID, classes } = this.props;
        return (
            <Fragment>
                <OutlineButton text="View Changelog..." onClick={() => this.setState({ open: true })} />
                {!!open && (
                    <Dialog open={!!open} className={classes.root}>
                        <DialogTitle className={classes.modalTitle}>Changelog: Funeral {LegacyKey}</DialogTitle>
                        <Query
                            client={getMyClient()}
                            query={queryChangeLog}
                            variables={{ funeralID: Number(FuneralID), limit: 25, offset: 0 }}
                            fetchPolicy="network-only"
                            notifyOnNetworkStatusChange
                        >
                            {results => this.renderForm(results)}
                        </Query>
                    </Dialog>
                )}
            </Fragment>
        );
    }

    renderForm = ({ error, loading, data, fetchMore }) => {
        const { classes } = this.props;
        const { readDataChangeRecords } = data || {};

        const onLoadMore = () => {
            if (loading || !(readDataChangeRecords && readDataChangeRecords.pageInfo.hasNextPage)) {
                return null;
            }
            fetchMore({
                variables: { offset: readDataChangeRecords.edges.length },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign(
                        {},
                        {
                            readDataChangeRecords: {
                                edges: [
                                    ...prev.readDataChangeRecords.edges,
                                    ...fetchMoreResult.readDataChangeRecords.edges
                                ],
                                __typename: 'readDataChangeRecordsConnection',
                                pageInfo: fetchMoreResult.readDataChangeRecords.pageInfo
                            }
                        }
                    );
                }
            });
        };

        return (
            <Fragment>
                <DialogContent className={classes.modalContent} onScroll={e => this.handleScroll(e, onLoadMore)}>
                    <Grid container spacing={16}>
                        <div className={classes.loading}>
                            {(!!loading && (
                                <>
                                    <LinearProgress /> Loading...
                                </>
                            )) ||
                                (!!error && `Error! ${error}`)}
                        </div>
                        {!!readDataChangeRecords &&
                            readDataChangeRecords.edges &&
                            readDataChangeRecords.edges.map(e => this.renderEntry(e.node))}
                    </Grid>
                    {!!readDataChangeRecords && (
                        <div className={classes.loadMore}>
                            {readDataChangeRecords.pageInfo.hasNextPage ? (
                                <PrimaryButton onClick={onLoadMore} disabled={loading}>
                                    {loading ? (
                                        <>
                                            <Spinner /> <span>Loading more...</span>
                                        </>
                                    ) : (
                                        'Load more changelog'
                                    )}
                                </PrimaryButton>
                            ) : (
                                'End of changelog.'
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <div style={{ flexGrow: 2 }}>
                        <AltButton
                            onClick={() =>
                                document
                                    .querySelectorAll('.prettyJSON-label')
                                    .forEach(e => e.classList.add('prettyJSON-open'))
                            }
                        >
                            + <Hidden xsDown> Expand All</Hidden>
                        </AltButton>
                        &nbsp;
                        <AltButton
                            onClick={() =>
                                document
                                    .querySelectorAll('.prettyJSON-label')
                                    .forEach(e => e.classList.remove('prettyJSON-open'))
                            }
                        >
                            - <Hidden xsDown> Collapse All</Hidden>
                        </AltButton>
                    </div>
                    <OutlineButton onClick={() => this.onCancel()} color="primary">
                        <CloseIcon />
                        <Hidden smDown>Close</Hidden>
                    </OutlineButton>
                </DialogActions>
            </Fragment>
        );
    };

    renderEntry = entry => {
        const { classes } = this.props;
        return (
            <Grid item xs={12} key={entry.ID}>
                <p>
                    On {niceDateTimeFromString(entry.Created)},{' '}
                    {joinDefined([entry.ChangedBy.FirstName, entry.ChangedBy.Surname], ' ') || entry.ChangedBy.Email}
                    {' wrote:'}
                </p>
                <div className={classes.prettyChanged}>
                    <div className={classes.prettyData}>
                        <Label text={`Funeral data ${(entry.Variables && 'sent') || 'before'}`} />
                        {prettyJSON(entry.Variables || entry.Before)}
                    </div>
                    <div className={classes.prettyData}>
                        <Label text="Funeral data written:" />
                        {prettyJSON(entry.After)}
                    </div>
                </div>
            </Grid>
        );
    };
}

const queryChangeLog = gql`
    query ReadChangelog($funeralID: Int!, $limit: Int, $offset: Int) {
        readDataChangeRecords(
            filter: { ChangeRecordID: { eq: $funeralID }, ChangeRecordClass: { eq: _FuneralManagerFuneral } }
            limit: $limit
            offset: $offset
        ) {
            edges {
                node {
                    ID
                    Created
                    ChangeType
                    Before
                    After
                    Variables
                    ChangedBy {
                        ID
                        Surname
                        FirstName
                        Email
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

const styles = theme => ({
    root: {
        '& > div:nth-child(2) > div': {
            maxWidth: 1024,
            width: '98vw',
            height: 800,
            maxHeight: '94vh',
            [theme.breakpoints.down('md')]: {
                margin: 16
            }
        }
    },
    modalTitle: {
        padding: 24,
        margin: 0,
        background: theme.palette.contentForeground[theme.funeralHome],
        '& > h2': {
            color: '#FFFFFF',
            fontWeight: 'lighter',
            [theme.breakpoints.up('md')]: {
                fontSize: 26
            }
        }
    },
    modalFooter: {
        padding: '10px 20px 20px',
        margin: 0
    },
    modalContent: {
        position: 'relative',
        paddingTop: 16,
        height: 'auto',
        overflowY: 'auto',
        overflowX: 'auto',
        '& p': {
            color: theme.palette.contentForeground[theme.funeralHome]
        },
        [theme.breakpoints.up('md')]: {
            padding: '16px 48px 24px 48px'
        }
    },
    prettyChanged: {
        borderBottom: '2px solid #00000033',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    prettyData: { minWidth: 240, width: '50%', padding: 16, paddingTop: 0 },
    loading: { width: '100%', position: 'sticky', top: 0 },
    loadMore: { textAlign: 'center', margin: '12px auto 6px' }
});

export default withStyles(styles)(ChangelogModal);
