import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import ColumnLayout from '../../../component/ColumnLayout';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import RadioGroup from '../../../component/form/RadioGroup';
import TextField from '../../../component/form/TextField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import CateringStaffAllocationTable from '../../../component/form/CateringStaffAllocationTable';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import {
    CATERING_STAFF_REQUIRED,
    OFFSITE_COMPONENT_FIELDS,
    REFRESHMENTS_VENUE_OPTIONS,
    SUPPLIER_OPTIONS
} from './OptionalsConstants';
import ContactView from '../../contacts/ContactView';
import { adjustTimeString } from '../../../util/date';
import { TabTypes } from '../../../component/product-modal/ProductModalConstants';
import { getProductIds, PreloadProducts, ProductMetadata } from '../../../util/products';
import TimeSelect from '../../../component/form/TimeSelect';
import SupplierRequest from '../../../component/bookings/SupplierRequest';
import SupplierItems from '../../../component/bookings/SupplierItems';
import { isNullOrUndefined } from '../../../util/objects';
import Label from '../../../component/form/Label';
import VenueBooking from '../../../component/bookings/VenueRequest';
import { Default_Catering_ID } from '../../../util/bookable';

class Optionals extends Component {
    loadingProducts = false;

    componentDidUpdate() {
        const { form } = this.props;
        if (isNullOrUndefined(form) || this.loadingProducts) return;

        this.loadingProducts = true;
        const productIds = getProductIds(
            ['Florists', 'PersonalisedTouches', 'MemorialStationary', 'NewspaperNotices', 'Caterers'],
            form
        );
        PreloadProducts(this, productIds).then(e => (this.loadingProducts = false));
    }

    render() {
        return (
            <Fragment>
                <ContactView />
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const { form, locked } = this.props;
        const refreshmentsVenueType = form.getField('RefreshmentsVenue.Type');
        const required = refreshmentsVenueType === 'Venue' || refreshmentsVenueType === 'Offsite';

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid bucket={true}>
                        <InlineField>
                            <RadioGroup
                                disabled={locked}
                                label="Refreshments Venue"
                                options={REFRESHMENTS_VENUE_OPTIONS}
                                form={form}
                                name="RefreshmentsVenue.Type"
                                onChange={e => this.onChangeRefreshmentsVenueType(e.target.value)}
                            />
                        </InlineField>
                        {required && this.renderVenue()}
                    </Grid>
                    {required && <Grid bucket={true}>{this.renderCatering()}</Grid>}
                </Grid>
            </Fragment>
        );
    }

    renderSecondColumn() {
        return (
            <Fragment>
                <Grid container spacing={16}>
                    {this.renderFlowers()}
                    {this.renderNewspaperNotice()}
                    {this.renderMemorialStationery()}
                    {this.renderPersonalTouches()}
                </Grid>
            </Fragment>
        );
    }

    renderVenue() {
        const { form, locked } = this.props;
        const refreshmentsVenueType = form.getField('RefreshmentsVenue.Type');
        return (
            <Fragment>
                <VenueBooking
                    disabled={locked}
                    labelSupplier={'Refreshments Venue'}
                    label={'Refreshments'}
                    name={'RefreshmentsVenue'}
                    form={form}
                    categories={[ADDRESS_BOOK_CATEGORY.placeOfService]}
                    offsite={(refreshmentsVenueType === 'Offsite' && this.renderOffsite) || false}
                    allowPurchaseOrder={true}
                >
                    {this.renderVenueDetails()}
                </VenueBooking>
            </Fragment>
        );
    }

    renderOffsite = () => {
        const { form } = this.props;
        return (
            <Grid item xs={12}>
                <Label text={'Offsite Address'} />
                <AddressAutocomplete
                    form={form}
                    name="RefreshmentsVenue"
                    placeholder="Search for an address"
                    componentFields={OFFSITE_COMPONENT_FIELDS}
                    allowCustomAddress={true}
                />
            </Grid>
        );
    };

    renderVenueDetails() {
        const { form, locked } = this.props;
        return (
            <Fragment>
                <InlineField>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={locked}
                            label="Number of guests"
                            form={form}
                            type="number"
                            inputProps={{ min: '0' }}
                            name="RefreshmentsVenue.NumberOfPeople"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={locked}
                            label="Date of Refreshments"
                            type="date"
                            form={form}
                            name="RefreshmentsVenue.Date"
                        />
                    </Grid>
                </InlineField>
                <InlineField>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={locked}
                            label="Start Time"
                            type="time"
                            form={form}
                            name="RefreshmentsVenue.Time"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TimeSelect
                            disabled={locked}
                            label="Duration (Hours)"
                            form={form}
                            name="RefreshmentsVenue.Duration"
                        />
                    </Grid>
                </InlineField>
            </Fragment>
        );
    }

    renderCatering() {
        const { form, locked } = this.props;
        const cateringRequired = form.getField('RefreshmentsVenue.CateringRequired');
        const numberPeopleCatering = form.getField('RefreshmentsVenue.NumberOfPeople') || 0;
        const numberCaterersNeeded = CATERING_STAFF_REQUIRED(numberPeopleCatering);
        const cateringRequirements = [];
        if (!form || !!form.loading) {
            cateringRequirements.push({ message: 'Loading, please wait..', messageType: 'info' });
        } else {
            if (!form.getField('RefreshmentsVenue.Date')) {
                cateringRequirements.push({ message: 'Enter the Date', messageType: 'error' });
            }
            if (!form.getField('RefreshmentsVenue.Time')) {
                cateringRequirements.push({ message: 'Enter the Start Time', messageType: 'error' });
            }
            if (numberPeopleCatering < 1) {
                cateringRequirements.push({ message: 'Enter the number of guests', messageType: 'error' });
            } else {
                const caterersAllocated = (form.getField('RefreshmentsVenue.CateringStaffAllocations') || []).filter(
                    e => !!e.Availability
                );
                const caterersConfirmed = caterersAllocated.filter(e => !!e.Confirmed);
                if (caterersConfirmed.length > numberCaterersNeeded)
                    cateringRequirements.push({
                        message: `Catering over-allocated, only ${numberCaterersNeeded} required`,
                        messageType: 'warning'
                    });
                if (caterersConfirmed.length === numberCaterersNeeded)
                    cateringRequirements.push({
                        message: 'Catering fully allocated and confirmed',
                        messageType: 'good'
                    });
                else if (caterersAllocated.length === numberCaterersNeeded)
                    cateringRequirements.push({
                        message: 'Catering fully allocated but not confirmed',
                        messageType: 'info'
                    });
            }
        }
        if (!!form.isDirty) cateringRequirements.push({ message: 'Please save changes', messageType: 'warning' });

        return (
            <Fragment>
                <InlineHeader header="Refreshments Venue Catering">
                    <Checkbox
                        disabled={locked}
                        label="Required"
                        form={form}
                        name={'RefreshmentsVenue.CateringRequired'}
                    />
                </InlineHeader>
                {cateringRequired && (
                    <Fragment>
                        <SupplierRequest
                            disabled={locked}
                            form={form}
                            categories={[ADDRESS_BOOK_CATEGORY.catering]}
                            name={'Caterers'}
                            label={'Refreshments'}
                            labelSupplier={'Refreshments Supplier'}
                            defaultSupplierID={Default_Catering_ID}
                            eventField={'RefreshmentsVenue'}
                            allowPurchaseOrder={true}
                        >
                            {(name, i, label) => {
                                return (
                                    <SupplierItems
                                        disabled={locked}
                                        key={i}
                                        name={name}
                                        index={i}
                                        label={label}
                                        form={form}
                                        productsModalTabs={[
                                            {
                                                name: 'Refreshments Menu',
                                                metadata: ProductMetadata.Catering,
                                                type: TabTypes.Selection
                                            },
                                            {
                                                name: 'Summary',
                                                type: TabTypes.Summary
                                            }
                                        ]}
                                        itemStyle={'card'}
                                    />
                                );
                            }}
                        </SupplierRequest>

                        <InlineHeader header={'Catering Staff'} />
                        <CateringStaffAllocationTable
                            disabled={locked}
                            cateringStaffRequirements={cateringRequirements || []}
                            cateringStaffAllocationsField={`RefreshmentsVenue.CateringStaffAllocations`}
                            numberPeopleCatering={numberPeopleCatering || 0}
                            numberCaterersNeeded={numberCaterersNeeded || 0}
                            eventDate={form.getField(`RefreshmentsVenue.Date`)}
                            eventTime={form.getField(`RefreshmentsVenue.Time`)}
                            eventDuration={form.getField(`RefreshmentsVenue.Duration`)}
                            form={form}
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    }

    renderNewspaperNotice() {
        const { form, locked } = this.props;
        const newspaperNotice = form.getField('NewspaperNoticeRequired');
        const complementaryNotice = form.getField('ComplementaryNoticeRequired');

        return (
            <Grid bucket={true}>
                <Grid item xs={12}>
                    <RadioGroup
                        disabled={locked}
                        options={[
                            { label: 'Required', value: 'Required' },
                            { label: 'Not Required', value: 'NotRequired' },
                            { label: 'Unsure', value: '' }
                        ]}
                        label="Complementary Funeral Notice"
                        form={form}
                        name="ComplementaryNoticeRequired"
                    />
                    {complementaryNotice === 'Required' && (
                        <TextField disabled={locked} label="Notice" form={form} name="ComplementaryNoticeMessage" />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <InlineHeader header="Newspaper Notice">
                        <Checkbox disabled={locked} label="Required" form={form} name="NewspaperNoticeRequired" />
                    </InlineHeader>
                </Grid>

                {newspaperNotice && (
                    <SupplierRequest
                        disabled={locked}
                        hasNotes
                        form={form}
                        name={'NewspaperNotices'}
                        label={'Newspaper Notice'}
                        labelSupplier={'Newspaper'}
                        categories={[ADDRESS_BOOK_CATEGORY.miscellaneous]}
                        allowPurchaseOrder={true}
                    >
                        {(name, i, label) => {
                            return (
                                <SupplierItems
                                    disabled={locked}
                                    key={i}
                                    name={name}
                                    index={i}
                                    label={label}
                                    form={form}
                                    productsModalTabs={[
                                        {
                                            name: 'Newspaper Notices',
                                            metadata: ProductMetadata.NewspaperNotices,
                                            type: TabTypes.Selection
                                        },

                                        {
                                            name: 'Summary',
                                            type: TabTypes.Summary
                                        }
                                    ]}
                                />
                            );
                        }}
                    </SupplierRequest>
                )}
            </Grid>
        );
    }

    renderFlowers() {
        const { form, locked } = this.props;
        const required = form.getField('FlowersRequired');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Flowers">
                    <Checkbox
                        disabled={locked}
                        label="Required"
                        name="FlowersRequired"
                        form={form}
                        falseValue={false}
                    />
                </InlineHeader>
                {required && (
                    <SupplierRequest
                        disabled={locked}
                        form={form}
                        name={'Florists'}
                        label={'Flowers'}
                        labelSupplier={'Florist'}
                        categories={[ADDRESS_BOOK_CATEGORY.florist]}
                        allowPurchaseOrder={true}
                    >
                        {(name, i, label) => {
                            return (
                                <SupplierItems
                                    disabled={locked}
                                    key={i}
                                    name={name}
                                    index={i}
                                    label={'Flowers'}
                                    form={form}
                                    productsModalTabs={[
                                        {
                                            name: 'Flowers',
                                            metadata: ProductMetadata.Flowers,
                                            type: TabTypes.Selection
                                        },
                                        {
                                            name: 'Summary',
                                            type: TabTypes.Summary
                                        }
                                    ]}
                                    itemStyle={'card'}
                                />
                            );
                        }}
                    </SupplierRequest>
                )}
            </Grid>
        );
    }

    renderMemorialStationery() {
        const { form, locked } = this.props;
        const required = form.getField('MemorialStationaryBy') === 'Supplier';

        return (
            <Grid bucket={true}>
                <InlineField>
                    <RadioGroup
                        disabled={locked}
                        label="Memorial Stationery"
                        options={SUPPLIER_OPTIONS}
                        form={form}
                        name="MemorialStationaryBy"
                    />
                </InlineField>

                {required && (
                    <SupplierRequest
                        disabled={locked}
                        form={form}
                        name={'MemorialStationary'}
                        label={'Memorial Stationery'}
                        allowPurchaseOrder={true}
                        // categories={[ADDRESS_BOOK_CATEGORY.supportServices]}
                    >
                        {(name, i, label) => {
                            return (
                                <SupplierItems
                                    disabled={locked}
                                    key={i}
                                    name={name}
                                    index={i}
                                    label={label}
                                    form={form}
                                    productsModalTabs={[
                                        {
                                            name: 'Memorial Stationery',
                                            metadata: ProductMetadata.MemorialStationery,
                                            type: TabTypes.Selection
                                        },
                                        {
                                            name: 'Summary',
                                            type: TabTypes.Summary
                                        }
                                    ]}
                                />
                            );
                        }}
                    </SupplierRequest>
                )}
            </Grid>
        );
    }

    renderPersonalTouches() {
        const { form, locked } = this.props;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Personal Touches" />
                <SupplierRequest
                    disabled={locked}
                    form={form}
                    name={'PersonalisedTouches'}
                    label={'Personal Touches'}
                    allowPurchaseOrder={true}
                    // categories={[ADDRESS_BOOK_CATEGORY.supportServices]}
                >
                    {(name, i, label) => {
                        return (
                            <SupplierItems
                                disabled={locked}
                                key={i}
                                name={name}
                                index={i}
                                label={label}
                                form={form}
                                productsModalTabs={[
                                    {
                                        name: 'Personal Touches',
                                        metadata: ProductMetadata.PersonalTouches,
                                        type: TabTypes.Selection
                                    },
                                    {
                                        name: 'Summary',
                                        type: TabTypes.Summary
                                    }
                                ]}
                            />
                        );
                    }}
                </SupplierRequest>
            </Grid>
        );
    }

    onChangeRefreshmentsVenueType(type) {
        const { form } = this.props;

        if (type === 'Not Required') {
            form.setField({
                'RefreshmentsVenue.CateringRequired': false,
                'RefreshmentsVenue.Type': type
            });
            return;
        }

        const refreshmentsID = form.getField('RefreshmentsVenue.ID') || null;
        const refreshmentsDate = form.getField('RefreshmentsVenue.Date');
        const refreshmentsTime = form.getField('RefreshmentsVenue.Time');

        if (refreshmentsDate || refreshmentsTime) {
            form.setField({
                'RefreshmentsVenue.CateringRequired': true
            });
            return;
        }

        const serviceDate = form.getField('PlaceOfService.Date') || null;
        const serviceTime = form.getField('PlaceOfService.Time') || null;
        const adjustedTime = (!!serviceTime && adjustTimeString(serviceTime, 60)) || null;
        const placeOfService = form.getField('PlaceOfService.Location');

        form.setField({
            'RefreshmentsVenue.ID': refreshmentsID,
            'RefreshmentsVenue.Date': serviceDate,
            'RefreshmentsVenue.Time': adjustedTime,
            'RefreshmentsVenue.Duration': 1,
            'RefreshmentsVenue.Location': placeOfService ? { ...placeOfService } : null,
            'RefreshmentsVenue.CateringRequired': true,
            'RefreshmentsVenue.Type': type
        });
    }
}

const styles = ({ palette, typography }) => ({
    alignWithFloatingLabel: {
        marginTop: '16px'
    },

    alignWithLabel: {
        marginTop: '10px'
    },

    orderStatus: {
        fontSize: '12px'
    },

    flowerList: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 8,
        justifyContent: 'space-evenly'
    },

    flowerItem: {
        flex: '0 0 auto',
        display: 'flex',
        padding: '16px 16px 12px',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '8px',
        border: `1px solid ${palette.divider}`,
        width: '200px',
        position: 'relative'
    },

    flowerItemImage: {
        width: '100%',
        height: 128,
        border: `1px solid ${palette.divider}`,
        marginBottom: 12,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    flowerItemName: {
        fontSize: typography.caption.fontSize
    },
    flowerItemQty: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: `${palette.divider}`,
        borderRadius: '5px 0px 0px 0px',
        padding: '2px 5px'
    }
});

export default withStyles(styles)(Optionals);
