import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import RouteTabs from '../../component/RouteTabs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { joinDefined } from '../../util/strings';
import cx from 'classnames';
import { FIELD_TAB_LOOKUP, TAB, TABS } from './prearrangementConstants';
import DataForm from '../../component/form/DataForm';
import * as Fragments from './PrearrangementFragments';
import { AltButton, OutlineButton } from '../../component/form/PrimaryButton';
import Spinner from '../../component/Spinner';
import GatedComponent from '../../component/GatedComponent';
import { getEnquiryClient } from '../../apollo';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class Prearrangement extends React.Component {
    state = {
        // showAdminSummary: false,
        // adminData: { adminSummary: null, groupedIndices: null, original: null }
    };

    isDirty = true;

    // componentWillMount() {
    //     this.loadAdminSummary(this.props.match.params.id);
    // }

    // loadAdminSummary(funeralId) {
    //     if (funeralId !== null) {
    //         const that = this;
    //         getAdminSummaryFunc(funeralId).then(result => {
    //             const original = cloneDeep(result);
    //             flattenConnection(original, 'AdminChecks');
    //             const adminSummary = cloneDeep(original);
    //             const groupedIndices = groupBy(adminSummary.AdminChecks, x => x.TabURLSegment, (_, i) => i, true);
    //             that.setState({ adminData: { adminSummary, groupedIndices, original } });
    //         });
    //     }
    // }
    prefilledEnquiry = gql`
        mutation CreateEnquiry($input: CreateEnquiryInput!) {
            createEnquiry(input: $input) {
                ID
                LegacyKey
                Prearrangement {
                    ID
                }
            }
        }
    `;

    getCurrentTab() {
        const { match, location } = this.props;
        return location.pathname.replace(match.url + '/', '');
    }

    render() {
        const { classes } = this.props;
        //const { adminData, showAdminSummary } = this.state;

        return (
            <Fragment>
                {this.renderTitle()}
                <br />
                <Paper className={classes.paper} elevation={0}>
                    {/*<SnappableLayout*/}
                    {/*snappableComponent={*/}
                    {/*showAdminSummary ? (*/}
                    {/*<AdminSummary*/}
                    {/*currentTab={this.getCurrentTab()}*/}
                    {/*{...adminData}*/}
                    {/*onClose={() => this.toggleAdminSummary()}*/}
                    {/*/>*/}
                    {/*) : null*/}
                    {/*}*/}
                    {/*>*/}
                    <RouteTabs tabs={TABS}>{this.renderTabs}</RouteTabs>
                    {/*</SnappableLayout>*/}
                </Paper>
            </Fragment>
        );
    }

    renderTitle() {
        const { classes, match } = this.props;
        const { key, id } = match.params;
        return (
            <div className={classes.pageTitleContainer}>
                {(id || key) && (
                    <Query query={nameQuery} variables={{ id, key }}>
                        {this.renderName}
                    </Query>
                )}
            </div>
        );
    }

    doConversion = async data => {
        const input = {
            PrearrangementID: data.ID // cms will trigger a prefill of enquiry from prearrangement
        };
        const asyncQuery = await getEnquiryClient().mutate({
            mutation: this.prefilledEnquiry,
            variables: { input: input }
        });

        return asyncQuery.data;
    };

    viewFuneralDetails = (id, legacyId) => {
        const path = `/funeral/${legacyId}/${id}`;
        //const win = window.open(path);
        //win.focus();
        this.props.history.push(path);
    };

    renderName = ({ loading, error, data }) => {
        const { classes } = this.props;
        const { FirstName, MiddleName, Surname, LegacyKey, Enquiries, Type } =
            (data && data.readOnePrearrangement) || {};
        const hasEnquiries = !!Enquiries && Enquiries.length > 0;
        const hasAtNeed = !!hasEnquiries && Enquiries.find(e => e.EnquiryType === 'ATNEED');
        const hasFuneral = !!hasEnquiries && Enquiries.find(e => e.Funeral && Number(e.Funeral.ID) !== 0);
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    Prearrangement {Type !== 'Prearrangment' && Type} {LegacyKey}
                    <span className={cx(classes.nameTitle, !loading && classes.nameTitleActive)}>
                        {joinDefined([FirstName, MiddleName, Surname], ' ')}
                    </span>
                </Typography>
                {!loading && !!hasFuneral && (
                    <span className={classes.adminButton}>
                        <OutlineButton
                            target="_blank"
                            onClick={() => this.viewFuneralDetails(hasFuneral.Funeral.ID, hasFuneral.Funeral.LegacyKey)}
                        >
                            View Funeral {hasFuneral.Funeral.LegacyKey}
                        </OutlineButton>
                    </span>
                )}
                {!loading &&
                    !hasFuneral &&
                    ((!!hasAtNeed && (
                        <span className={classes.adminButton}>
                            <OutlineButton
                                target="_blank"
                                onClick={() =>
                                    this.props.history.push(`/quotes/enquiry/${hasAtNeed.LegacyKey}/${hasAtNeed.ID}`)
                                }
                            >
                                View At-Need Enquiry {hasAtNeed.LegacyKey}
                            </OutlineButton>
                        </span>
                    )) || (
                        <span className={classes.adminButton}>
                            <AltButton
                                disabled={!!this.isDirty}
                                onClick={() =>
                                    this.doConversion(data.readOnePrearrangement).then(result => {
                                        this.props.history.push(
                                            `/quotes/enquiry/${result.createEnquiry.LegacyKey}/${result.createEnquiry.ID}`
                                        );
                                    })
                                }
                            >
                                Convert to At-Need
                            </AltButton>
                        </span>
                    ))}
            </Fragment>
        );
    };

    renderTabs = selectedIndex => {
        const { fragment } = TABS[selectedIndex];
        const { id } = this.props.match.params;

        const query = gql`
            ${fragment}
            query LoadTabData($id: ID!) {
                readOnePrearrangement(ID: $id) {
                    ID
                    LegacyKey
                    ...${fragment.definitions[0].name.value}
                }
            }
        `;

        return (
            <Query query={query} variables={{ id }}>
                {results => this.renderForm(selectedIndex, results)}
            </Query>
        );
    };

    renderForm = (selectedIndex, { loading, error, data }) => {
        const { classes } = this.props;
        const { id } = this.props.match.params;
        const tab = TABS[selectedIndex];

        return (
            <GatedComponent isEnabledCode={'FM_ACCESS_Funeral_Edit'}>
                {isEnabled => {
                    return (
                        <DataForm
                            id={id}
                            tab={tab}
                            loading={loading}
                            error={error}
                            data={data && data.readOnePrearrangement}
                            allowSave={isEnabled}
                            onSaved={() => this.onPrearrangementSaved()}
                            mutationName="updatePrearrangement"
                            mutationInputType="UpdatePrearrangementInput"
                            fragments={Fragments}
                            tabs={TAB}
                            tabFragmentLookup={FIELD_TAB_LOOKUP}
                            requireValid={false}
                            continuousValidation={true}
                        >
                            {(form, button) => {
                                if (this.isDirty !== form.isDirty) {
                                    this.isDirty = form.isDirty;
                                    this.forceUpdate();
                                }
                                return (
                                    <Fragment>
                                        <div className={classes.content}>
                                            {!!loading && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 99,
                                                        margin: -20,
                                                        height: '100%',
                                                        width: '100%',
                                                        background: 'rgba(255,255,255,0.5)'
                                                    }}
                                                >
                                                    <span>Loading data, please wait a moment...</span>
                                                    <div style={{ float: 'left', marginRight: 8 }}>
                                                        <Spinner />
                                                    </div>
                                                </div>
                                            )}

                                            <tab.component form={form} />
                                        </div>
                                        {button}
                                    </Fragment>
                                );
                            }}
                        </DataForm>
                    );
                }}
            </GatedComponent>
        );
    };

    onPrearrangementSaved() {
        // this.saveAdminSummary();
    }
}

const nameQuery = gql`
    query NameQuery($id: ID!) {
        readOnePrearrangement(ID: $id) {
            ID
            LegacyKey
            Type
            FirstName
            Surname
            MiddleName
            Office {
                ID
            }
            Enquiries {
                ID
                LegacyKey
                EnquiryType
                Funeral {
                    ID
                    LegacyKey
                    Created
                }
            }
        }
    }
`;

const styles = ({ spacing, typography, palette, transitions }) => ({
    // paper: {
    // maxWidth: 1680
    // },
    pageTitleContainer: {
        //maxWidth: '1680px',
        width: '100%',
        display: 'flex'
    },

    adminButton: {
        flexGrow: '0'
    },
    adminIcon: {
        width: '16px',
        margin: '0 0.2rem 0 0'
    },
    pageTitle: {
        color: palette.action.active,
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1'
    },

    content: {
        padding: spacing.unit * 4
    },
    keyline: {
        width: 1,
        height: 30,
        display: 'inline-block',
        margin: '0 16px',
        opacity: 0.5
        // backgroundColor: palette.action.active
    },
    nameTitle: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        opacity: 0,
        color: palette.text.primary,
        transition: transitions.create(['opacity'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    nameTitleActive: {
        opacity: 1
    }
});

// prettier-ignore
export default withErrorBoundary(
    compose(withRouter, withStyles(styles))(Prearrangement),
    { fallback: ErrorFallback }
);
