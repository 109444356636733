import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { getAssetsClient } from '../../apollo';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Card from '@material-ui/core/Card/Card';
import CardMedia from '@material-ui/core/CardMedia/CardMedia';
import CardActions from '@material-ui/core/CardActions/CardActions';
import CardContent from '@material-ui/core/CardContent/CardContent';
import UploadIcon from '../icon/UploadIcon';
import { getMaxUploadSize } from '../../environment';

class UploadFile extends Component {
    onChange = mutate => ({
        target: {
            validity,
            files: [file]
        }
    }) => {
        const { parentId, folderPath } = this.props;
        const publish = this.props.publish !== false;

        if (typeof file !== 'undefined') {
            const size = file.size;
            const maxSize = getMaxUploadSize();
            if (maxSize < size) return alert('Sorry, file is too big!\nLimit is ' + maxSize / 1024 / 1024 + ' MB');
        }

        return (
            validity.valid &&
            mutate({
                variables: {
                    file: file,
                    parentId: parentId,
                    publish: publish,
                    folderPath: folderPath
                }
            })
        );
    };

    onCompleted = data => {
        if (this.props.onComplete) {
            this.props.onComplete(this.legacify(data));
        }

        const { name, form } = this.props;
        if (name && form) {
            const { ID, AbsoluteLink, FileName } = (data && data.uploadFile) || {};
            form.setState({
                [`${name}.ID`]: ID,
                [`${name}.AbsoluteLink`]: AbsoluteLink,
                [`${name}.Name`]: FileName.replace(/(.*?\/)+/, '')
            });
        }
    };

    legacify = data => {
        const legacyData = (data && data.uploadFile) || {};
        legacyData.id = legacyData.ID;
        legacyData.filename = legacyData.FileName;
        legacyData.url = legacyData.AbsoluteLink;
        legacyData.title = legacyData.Title;
        legacyData.parentId = legacyData.ParentID;

        return {
            uploadFile: legacyData
        };
    };

    handleDeleteFile = () => {
        const { name, form, onDelete } = this.props;

        if (!name || !form) return;

        if (onDelete) {
            onDelete(form.getState(name));
        } else {
            form.setState({
                [`${name}.ID`]: 0,
                [`${name}.AbsoluteLink`]: null,
                [`${name}.Name`]: null
            });
        }
    };

    render() {
        const { buttonOnly, iconOnly, iconButton } = this.props;

        let content;
        if (iconOnly) content = (mutation, { loading }) => this.renderIcon(mutation, loading);
        else if (buttonOnly) content = (mutation, { loading }) => this.renderButton(mutation, loading);
        else if (iconButton) content = (mutation, { loading }) => this.renderIconButton(mutation, loading);
        else content = (mutation, { loading }) => this.renderControl(mutation, loading);

        return (
            <Mutation
                mutation={uploadFilesQuery}
                fetchPolicy="no-cache"
                onCompleted={this.onCompleted}
                client={getAssetsClient()}
            >
                {content}
            </Mutation>
        );
    }

    renderIcon(mutate, loading) {
        const { classes, label = 'Choose File', accept, required, disabled } = this.props;
        const helloKitty = Math.floor(Math.random() * 10000 + 1);
        return (
            <Fragment>
                <input
                    disabled={disabled}
                    type="file"
                    accept={accept}
                    required={required}
                    style={{ display: 'none' }}
                    className={classes.input}
                    id={'raised-button-file' + helloKitty}
                    onChange={this.onChange(mutate)}
                />
                <label htmlFor={'raised-button-file' + helloKitty}>
                    <div className={classes.wrapper}>
                        <IconButton component="span" onClick={() => true} disabled={loading} title={label}>
                            {loading ? <CircularProgress size={24} /> : <UploadIcon />}
                        </IconButton>
                    </div>
                </label>
            </Fragment>
        );
    }

    renderIconButton(mutate, loading) {
        const { classes, label = 'Choose File', accept, required } = this.props;
        const helloKitty = Math.floor(Math.random() * 10000 + 1);
        return (
            <Fragment>
                <input
                    type="file"
                    accept={accept}
                    required={required}
                    style={{ display: 'none' }}
                    className={classes.input}
                    id={'raised-button-file' + helloKitty}
                    onChange={this.onChange(mutate)}
                />
                <label htmlFor={'raised-button-file' + helloKitty}>
                    <div className={classes.wrapper}>
                        {loading ? <CircularProgress size={24} /> : <UploadIcon />} {label}
                    </div>
                </label>
            </Fragment>
        );
    }

    renderButton(mutate, loading) {
        const { classes, disabled, label = 'Choose File', accept, required } = this.props;
        const helloKitty = Math.floor(Math.random() * 10000 + 1);
        const maxSize = getMaxUploadSize();
        return (
            <Fragment>
                <div className={classes.wrapper}>
                    <input
                        type="file"
                        accept={accept}
                        required={required}
                        className={classes.inputBig}
                        id={'raised-button-file' + helloKitty}
                        onChange={this.onChange(mutate)}
                        disabled={disabled || loading}
                    />
                    <div className={classes.niceButt}>
                        <div
                            style={{
                                background: '#d5d5d5',
                                padding: '8px 16px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {loading ? <CircularProgress size={24} /> : <UploadIcon color="primary" />}
                            &nbsp;{label}...
                        </div>
                        <Typography>
                            Choose a file or drag one here.
                            <br />
                            <small>Maximum size: {maxSize / 1024 / 1024 + ' MB'}</small>
                        </Typography>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderControl = (mutate, loading) => {
        const { classes, form, name } = this.props;
        const imgSrc = name ? form.getState(name + '.AbsoluteLink') : null;
        return (
            <Fragment>
                <Card className={classes.card}>
                    {imgSrc && <CardMedia className={classes.cover} image={imgSrc} />}
                    <CardContent className={classes.cardText}>
                        <a
                            href={form && form.getState(name + '.AbsoluteLink')}
                            className={classes.fileNameLink}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            <Typography className={classes.fileNameLink}>
                                {form && form.getState(name + '.Name')}
                            </Typography>
                        </a>
                    </CardContent>
                    <div className={classes.details}>
                        <CardActions className={classes.content}>
                            {this.renderButton(mutate, loading)}
                            {imgSrc && (
                                <Button
                                    variant="contained"
                                    className={classes.deleteImageButton}
                                    onClick={this.handleDeleteFile}
                                >
                                    <Typography variant="button">Remove</Typography>
                                </Button>
                            )}
                        </CardActions>
                    </div>
                </Card>
            </Fragment>
        );
    };
}

export const uploadFilesQuery = gql`
    mutation($file: UploadType!, $parentId: Int, $publish: Boolean, $folderPath: String) {
        uploadFile(file: $file, parentId: $parentId, publish: $publish, folderPath: $folderPath) {
            ID
            FileName
            Title
            ParentID
            AbsoluteLink
        }
    }
`;

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    },
    inputBig: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        opacity: 0,
        width: '100%',
        height: '100%'
    },
    wrapper: {
        //  margin: theme.spacing.unit,
        position: 'relative'
    },
    niceButt: {
        width: '100%',
        border: '1px solid #dedede',
        background: 'white',
        padding: theme.spacing.unit * 2,
        display: 'flex',
        flexWrap: 'wrap',
        '& > :first-child': {
            marginRight: theme.spacing.unit * 2
        }
    },
    buttonProgress: {
        position: 'absolute',
        left: ' 110%'
    },
    card: {
        display: 'flex',
        height: 80
    },
    content: {
        flex: '1 0 auto'
    },
    cover: {
        minWidth: 80,
        maxWidth: 320,
        width: '33%'
    },
    cardText: {
        flexGrow: 2,
        overflow: 'hidden',
        wordBreak: 'break-all'
    },
    details: {
        display: 'flex',
        flexDirection: 'column'
    },
    fileNameLink: {
        color: theme.palette.primary.main
    },
    uploadImageButton: {
        backgroundColor: theme.palette.primary.main,
        float: 'left',
        borderRadius: 30,
        padding: '0.15rem 2rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        letterSpacing: '0.05rem'
    },
    deleteImageButton: {
        backgroundColor: theme.palette.secondary.main,
        float: 'left',
        marginLeft: 10
    }
});

export default withStyles(styles)(UploadFile);

export const getFileName = fileUploadName => {
    const last = fileUploadName.lastIndexOf('/');
    if (last === -1) return fileUploadName;

    return fileUploadName.substring(last + 1);
};
