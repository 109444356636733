import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { getOfficeFromKey } from '../../../util/brands';

export default withStyles({})(({ brand, label, variant, legacyKey }) => {
    let cssVariant = null;
    if (!brand) {
        brand = getOfficeFromKey(legacyKey, true);
    }
    variant = brand.style;
    label = brand.label;
    const prefix = brand.letterCode;

    if (!stringIsNullOrEmpty(variant)) cssVariant = `table-key--${variant}`;

    return (
        <div className={`table-key ${cssVariant || ''}`}>
            <span>{prefix}</span>
            {label}
        </div>
    );
});
