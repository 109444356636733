import { dateTimeToString, dateToString } from '../../../util/date';

export const TAB_URL_SEGMENTS = [
    { label: 'Deceased', value: 'deceased' },
    { label: 'Family', value: 'family' },
    { label: 'Certification', value: 'certification' },
    { label: 'Funeral Details', value: 'funeral-details' },
    { label: 'Presentation', value: 'presentation' },
    { label: 'Optionals', value: 'optionals' },
    { label: 'BDMS', value: 'bdms' },
    { label: 'Summary', value: 'summary' },
    { label: 'Billing', value: 'billing' },
    { label: 'Mortuary', value: 'mortuary' }
];

export const AuditEventTypes = {
    Comment: 0,
    Assign: 1,
    Unassign: 2,
    Archive: 3,
    Restore: 4,
    Due: 5,
    InProgress: 6,
    Completed: 7
};

export const CreateAuditEvent = (User, Type, Comment) => {
    return {
        node: {
            //ID: null,
            //Created: null,
            Type,
            Comment,
            Member: Type === AuditEventTypes.Assign ? { ...User } : null
            //AddedBy: null
        }
    };
};

export const CreateTask = user => {
    const task = {
        node: {
            //ID: null,
            //Created: null,
            Title: '',
            Description: '',
            RequiredAction: '',
            TabURLSegment: '',
            Due: null,
            Completed: null,
            Starred: null,
            Archived: null,
            Comment: null,
            Funeral: null,
            Audits: { edges: [] },
            AssignedMembers: []
        }
    };

    if (user) {
        task.node.AssignedMembers.push({
            FirstName: user.FirstName,
            Surname: user.Surname,
            Email: user.Email,
            ID: user.ID
        });
    }

    return task;
};

export const CreateMockTasks = () => {
    return {
        edges: [
            {
                node: {
                    ID: 0,
                    Funeral: {
                        node: {
                            ID: '32611',
                            LegacyKey: '23653P',
                            FirstName: 'Bob',
                            MiddleName: 'John',
                            Surname: 'Smith',
                            DateOfService: dateToString(new Date(2018, 6, 8)),
                            Confirmed: true,
                            AdminChecksComplete: 30,
                            AdminChecks: [
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15,
                                16,
                                17,
                                18,
                                19,
                                20,
                                21,
                                22,
                                23,
                                24,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                31,
                                32,
                                33,
                                34,
                                35,
                                36,
                                37,
                                38,
                                39,
                                40
                            ]
                        }
                    },
                    Title: 'Send Reminder',
                    Description: 'The invoice has not yet been paid and is overdue',
                    RequiredAction: 'Please issue a reminder email',
                    TabURLSegment: 'billing',
                    Created: dateToString(new Date(2018, 6, 7)),
                    Due: dateToString(new Date(2018, 6, 7)),
                    Completed: null,
                    Starred: dateToString(new Date(2018, 6, 5)),
                    Archived: null,
                    Assigned: {
                        edges: [
                            {
                                node: { ID: 0, UserID: 'ME', Date: dateTimeToString(new Date(2018, 6, 5)) }
                            }
                        ]
                    },
                    Audits: {
                        edges: [
                            {
                                node: {
                                    ID: 0,
                                    Type: AuditEventTypes.Comment,
                                    Description: 'Contacted the applicant and discussed payment options',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 1, 0))
                                }
                            },
                            {
                                node: {
                                    ID: 1,
                                    Type: AuditEventTypes.Assign,
                                    Description: 'Task assigned to Bob Smith',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 2, 0))
                                }
                            },
                            {
                                node: {
                                    ID: 2,
                                    Type: AuditEventTypes.Unassign,
                                    Description: 'Bob Smith was removed from this task',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 3, 0))
                                }
                            },
                            {
                                node: {
                                    ID: 3,
                                    Type: AuditEventTypes.Archive,
                                    Description: 'Assigned',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 4, 0))
                                }
                            },
                            {
                                node: {
                                    ID: 4,
                                    Type: AuditEventTypes.Restore,
                                    Description: 'Assigned',
                                    UserID: 'me',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 4, 0))
                                }
                            }
                        ]
                    }
                }
            },
            //example of a non-funeral task
            {
                node: {
                    ID: 1,
                    Title: 'Book Musician',
                    Description: 'Check if Prince is available',
                    RequiredAction: 'Call the musician',
                    Created: dateToString(new Date(2018, 6, 7)),
                    Due: dateToString(new Date(2018, 6, 7)),
                    Completed: null,
                    Starred: dateTimeToString(new Date(2018, 6, 5)),
                    Assigned: {
                        edges: [{ node: { ID: 0, UserID: 'me', Date: dateTimeToString(new Date(2018, 6, 5)) } }]
                    },
                    Audits: {
                        edges: [
                            {
                                node: {
                                    ID: 0,
                                    Type: AuditEventTypes.Assign,
                                    Description: 'Assigned',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 5, 8, 40, 2))
                                }
                            }
                        ]
                    }
                }
            },

            {
                node: {
                    ID: 2,
                    Funeral: {
                        node: {
                            ID: '1',
                            LegacyKey: '1W',
                            FirstName: 'Terrence',
                            MiddleName: '',
                            Surname: 'Beckett',
                            DateOfService: dateTimeToString(new Date(2018, 6, 8)),
                            Confirmed: true,
                            AdminChecksComplete: 20,
                            AdminChecks: [
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15,
                                16,
                                17,
                                18,
                                19,
                                20,
                                21,
                                22,
                                23,
                                24,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                31,
                                32,
                                33,
                                34,
                                35,
                                36,
                                37,
                                38,
                                39,
                                40
                            ]
                        }
                    },
                    Title: 'Send Reminder',
                    Description: 'The invoice has not yet been paid and is overdue',
                    RequiredAction: 'Please issue a reminder email',
                    TabURLSegment: 'billing',
                    Created: dateToString(new Date(2018, 6, 7)),
                    Due: dateToString(new Date(2018, 6, 7)),
                    Completed: null,
                    Starred: dateTimeToString(new Date(2018, 6, 5)),
                    Archived: null,
                    Assigned: {
                        edges: [
                            {
                                node: { ID: 0, UserID: 'me', Date: dateTimeToString(new Date(2018, 6, 5)) }
                            }
                        ]
                    },
                    Audits: {
                        edges: [
                            {
                                node: {
                                    ID: 4,
                                    Type: AuditEventTypes.Assign,
                                    Description: 'Assigned',
                                    UserID: 'ME',
                                    Date: dateTimeToString(new Date(2018, 6, 1, 8, 4, 0))
                                }
                            }
                        ]
                    }
                }
            }

            /*
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'Frank',
                    MiddleName: 'The',
                    Surname: 'Tank',
                    DateOfService: 'Sunday, March 20, 2018',
                    Confirmed: true,
                    OutstandingTaskCount: 4,
                    CompletionStatus: 90,
                    TabURLSegment:'deceased'
                }
            },
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'Sarah',
                    MiddleName: 'Rodney',
                    Surname: 'Chairface',
                    DateOfService: 'Monday, March 13, 2018',
                    Confirmed: false,
                    OutstandingTaskCount: 1,
                    CompletionStatus: 99,
                    ActionPath:'deceased'
                }
            },
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'John',
                    MiddleName: 'Johnno',
                    Surname: 'Johnston',
                    DateOfService: 'Monday, March 13, 2018',
                    Confirmed: false,
                    OutstandingTaskCount: 0,
                    CompletionStatus: 100,
                    ActionPath:'deceased'
                }
            },
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'Bubba',
                    MiddleName: 'Chub',
                    Surname: 'Rub',
                    DateOfService: 'Tuesday, March 14, 2018',
                    Confirmed: false,
                    OutstandingTaskCount: 40,
                    CompletionStatus: 50,
                    ActionPath:'deceased'
                }
            },
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'Rodney',
                    MiddleName: 'Rango',
                    Surname: 'McTickler',
                    DateOfService: 'Wednesday, March 15, 2018',
                    Confirmed: false,
                    OutstandingTaskCount: 6,
                    CompletionStatus: 88,
                    ActionPath:'deceased'
                }
            },
            {
                node: {
                    ID: '32611',
                    LegacyKey: '23653P',
                    FirstName: 'Happy',
                    MiddleName: 'Horace',
                    Surname: 'McGee',
                    DateOfService: 'Thursday, March 16, 2018',
                    Confirmed: false,
                    OutstandingTaskCount: 2,
                    CompletionStatus: 96,
                    ActionPath:'deceased'
                }
            }*/
        ]
    };
};
