export const BILL_TO_OPTIONS = [
    { label: 'Applicant', value: 'Applicant' },
    { label: 'Select From Address Book', value: 'AddressBook' },
    { label: 'Family Member', value: 'FamilyMember' },
    { label: 'Other', value: 'Other' },
    { label: 'Unknown', value: '' }
];

export const CUSTOMER_SATISFACTION_OPTIONS = [
    { label: 'Satisfied', value: 'Satisfied' },
    { label: 'Unsatisfied', value: 'Unsatisfied' },
    { label: 'Not specified', value: 'Not Specified' }
];
