import gql from 'graphql-tag';
import { OrdersFragmentInclude } from './Bookings';

export default gql`
    
    fragment Florist on Funeral {
        Florists {
            ${OrdersFragmentInclude}
        }
    }
`;
