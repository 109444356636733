import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Grid from './Grid';

const ModalStepHeader = ({ classes, header, children }) => (
    <Grid pc={1} className={classes.root}>
        <Typography variant="display1" className={classes.header}>
            {header}
        </Typography>
        {children}
    </Grid>
);

const styles = ({ typography }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :not(:first-child)': {
            marginLeft: '5px'
        }
    },
    header: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        fontSize: '22.5px',
        marginBottom: '20px'
    }
});

export default withStyles(styles)(ModalStepHeader);
