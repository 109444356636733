import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing, typography }) => ({
    dot: {
        height: 10,
        width: 10,
        borderRadius: 10,
        display: 'inline-block'
    },
    label: {
        marginLeft: spacing.unit,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    wrapper: {
        display: 'inline-flex!important',
        flexWrap: 'nowrap!important',
        wordBreak: 'keep-all!important',
        alignItems: 'center'
    }
});
export default withStyles(styles)(({ classes, label, dotColour, title }) => {
    return (
        <span className={classes.wrapper}>
            <span className={classes.dot} style={{ backgroundColor: dotColour }} title={title} />
            {label && <span className={classes.label}>{label}</span>}
        </span>
    );
});
