import gql from 'graphql-tag';
import {
    AFormDataFragment,
    BurialFormDataFragment,
    CremationFormDataFragment,
    LetterOfAuthorityDataFragment,
    SummaryFragment
} from './DocumentationFragments';
import Summary from './Tab_Summary';
import CrematoriaBookingDocument from './CremationForms/GenericCremationDocument';
import BurialDocument from './BurialForms/BurialDocument';
import StatDocument from './aForm/StatDocument';
import ArrangementForm from './ArrangementForm';
import { validationHelper } from '../../util/validation';
import { stringIsNullOrEmpty } from '../../util/strings';
import { isNullOrUndefined, updateNested } from '../../util/objects';
import MVCDocument from './CremationForms/MVCDocument';
import mvcLogo from '../../_asset/mvcLogo.png';
import LetterOfAuthority from './CremationForms/LetterOfAuthority';

export const DisposalQuery = gql`
    query DisposalQuery($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            Disposal {
                ID
                CrematedOrBuried
                Location {
                    ID
                    Name
                }
            }
            Grave {
                ID
                GroundDescription
                DepthOption
            }
            Certification {
                ID
                TypeOfBdmDeathCertificate
            }
            Arrangement {
                ID
            }
        }
    }
`;

export const CreateArrangementMutation = gql`
    mutation CreateArrangementMutation($input: UpdateFuneralInput!) {
        updateFuneral(input: $input) {
            ID
            Arrangement {
                ID
            }
        }
    }
`;

export const TabTemplates = {
    Summary: {
        id: 'Summary',
        label: 'Summary',
        fragment: SummaryFragment,
        queryName: 'SummaryQuery',
        component: Summary,
        path: '/documentation/:key/:id/summary',
        onLoad: data => {},
        formatSaveData: (saveData, state) => {}
    },
    StatutoryForms: {
        AForm: {
            id: 'AForm',
            label: 'Statutory Declaration Form (A-Form)',
            fragment: SummaryFragment,
            queryName: 'AFormQuery',
            component: ArrangementForm,
            path: '/documentation/:key/:id/a-form',
            tabProps: {
                formObject: 'ArrangementFormStatutoryForms',
                formType: 'AForm', //For ArrangementChecks
                formLabel: 'Statutory Declaration Form (A-Form)', //For summary sidebar title
                signatures: ['ApplicantSignature', 'WitnessSignature'],
                tabs: [
                    {
                        id: 'AFormStepOne',
                        label: 'Applicant Details',
                        fragment: AFormDataFragment,
                        component: StatDocument,
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {
                            if (saveData['CrematoriumLocation'] !== undefined) {
                                saveData['CrematoriumLocationID'] = saveData['CrematoriumLocation']['ID'];
                                delete saveData['CrematoriumLocation'];
                            }
                        },
                        validation: {
                            required: [
                                'ApplicantFirstName',
                                'ApplicantLastName',
                                'ApplicantAge',
                                'DeceasedFirstName',
                                'DeceasedLastName',
                                'DeceasedAge',
                                'DeceasedOccupation',
                                'DeceasedMartialStatus',
                                'DeceasedGender',
                                'SurvivingRelative',
                                'ExecutorOfEstate',
                                'WrittenAuthority',
                                'RelativesInformed',
                                'RelativeObjections'
                            ],
                            suggested: [],
                            onValidate: {
                                //Little bit of hack here - I did not found other way
                                //to listen for addressautocomplete validation other
                                //then hook on to one of the address fields
                                'ApplicantAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('ApplicantAddress'));
                                },
                                'DeceasedAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                CrematoriumLocation: (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(persistedValue);
                                },
                                ApplicantRelationship: (newValue, persistedValue, hasValue, getField) => {
                                    if (!stringIsNullOrEmpty(persistedValue)) return validationHelper.ok();
                                    return validationHelper.required();
                                },
                                ApplicationReasons: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('IsRelative') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                },
                                RequestedBy: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('IsRelative') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                },
                                ReasonForObjection: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('RelativeObjections') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        }
                    },
                    {
                        id: 'AFormStepTwo',
                        label: 'Deceased Information',
                        fragment: AFormDataFragment,
                        component: StatDocument,
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {
                            if (
                                state['DeceasedPlaceOfDeathType'] === 'AddressBook' &&
                                saveData['DeceasedPlaceOfDeathAddressBook']
                            ) {
                                saveData['DeceasedPlaceOfDeathAddressBookID'] =
                                    state['DeceasedPlaceOfDeathAddressBook']['ID'];
                                delete saveData['DeceasedPlaceOfDeathAddressBook'];
                                delete saveData['DeceasedPlaceOfDeath'];
                            }
                        },
                        validation: {
                            required: ['DeceasedDateOfDeath', 'DeceasedTimeOfDeath'],
                            suggested: [],
                            onValidate: {
                                DeceasedPlaceOfDeathAddressBook: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'AddressBook') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(persistedValue);
                                },
                                'DeceasedPlaceOfDeath.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'Residential') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('DeceasedPlaceOfDeath'));
                                },
                                Instructions: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('WrittenInstruction') || !stringIsNullOrEmpty(persistedValue)) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                }
                            }
                        }
                    },
                    {
                        id: 'AFormStepThree',
                        label: 'Medical Details',
                        fragment: AFormDataFragment,
                        component: StatDocument,
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {},
                        validation: {
                            required: ['RegistryOffice'],
                            suggested: [],
                            onValidate: {
                                MedicalPractitionerFullName: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('MedicalPractitionerCopyFrom') !== 'DontCopy' ||
                                        !stringIsNullOrEmpty(persistedValue)
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                'MedicalPractitionerAddress.AddressLine1': (
                                    newValue,
                                    persistedValue,
                                    hasValue,
                                    getField
                                ) => {
                                    if (getField('MedicalPractitionerCopyFrom') !== 'DontCopy') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('MedicalPractitionerAddress'));
                                },
                                LatestPractitionerFullName: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('LatestPractitionerCopyFrom') !== 'DontCopy' ||
                                        !stringIsNullOrEmpty(persistedValue)
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                'LatestPractitionerAddress.AddressLine1': (
                                    newValue,
                                    persistedValue,
                                    hasValue,
                                    getField
                                ) => {
                                    if (getField('LatestPractitionerCopyFrom') !== 'DontCopy') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('LatestPractitionerAddress'));
                                },
                                DeviceType: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('BatteryPoweredDevice') !== 'Yes' ||
                                        !stringIsNullOrEmpty(persistedValue)
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                }
                            }
                        }
                    },
                    {
                        id: 'AFormStepFour',
                        label: 'Consent & Signature',
                        fragment: AFormDataFragment,
                        component: StatDocument,
                        disabled: true,
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {},
                        validation: {
                            required: [
                                'DeclarationDate',
                                'WitnessFirstName',
                                'WitnessLastName',
                                'WitnessQualification',
                                'SignatureDate'
                            ],
                            suggested: [],
                            onValidate: {
                                'DeclarationLocation.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeclarationLocation'));
                                },
                                WitnessSeenDocument: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('WitnessFamiliar') !== 'DontKnow' ||
                                        !stringIsNullOrEmpty(persistedValue)
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                }
                            }
                        }
                    }
                ]
            },
            onLoad: data => {
                const formData = (data.Arrangement && data.Arrangement.ArrangementForms) || null;
                if (formData) {
                    data.Arrangement.ArrangementForms = FilterNested(
                        Object.values(formData),
                        'ArrangementFormStatutoryForms'
                    );
                }
            },
            formatSaveData: (saveData, state) => {}
        }
    },
    CremationBooking: {
        Generic: {
            id: 'CrematoriaBooking',
            label: 'Crematorium Booking Form',
            fragment: SummaryFragment,
            queryName: 'CrematoriaBookingQuery',
            component: ArrangementForm,
            path: '/documentation/:key/:id/cremation-booking',
            tabProps: {
                formObject: 'ArrangementFormCremationBooking',
                formType: 'CBooking',
                formLabel: 'Crematoria Booking Form',
                signatures: ['ApplicantSignature'],
                template: 'Generic',
                tabs: [
                    {
                        id: 'CrematoriaBookingStepOne',
                        label: 'Deceased Details',
                        fragment: CremationFormDataFragment,
                        component: CrematoriaBookingDocument,
                        validation: {
                            required: [
                                'FuneralCompany',
                                'FuneralPhone',
                                'Email',
                                'ArrangerName',
                                'DeceasedFirstName',
                                'DeceasedLastName',
                                'DeceasedGender',
                                'DeceasedOccupation',
                                'DeceasedDateOfBirth',
                                'DeceasedDateOfDeath',
                                'DeceasedAge',
                                'DeceasedMartialStatus',
                                'DeceasedReligion'
                            ],
                            onValidate: {
                                'DeceasedAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                DeceasedPlaceOfDeathAddressBook: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'AddressBook') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(persistedValue);
                                },
                                'DeceasedPlaceOfDeath.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'Residential') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('DeceasedPlaceOfDeath'));
                                },
                                SpecialInstructions: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasSpecialInstructions') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    },
                    {
                        id: 'CrematoriaBookingStepTwo',
                        label: 'Booking Details',
                        fragment: CremationFormDataFragment,
                        component: CrematoriaBookingDocument,
                        validation: {
                            required: ['DeliveryDate', 'DeliveryTime', 'ServiceType'],
                            suggested: ['CemeteryName', 'SiteReference'],
                            onValidate: {
                                SpecialInstructions: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasSpecialInstructions') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    },
                    {
                        id: 'CrematoriaBookingStepThree',
                        label: 'Applicant Details',
                        fragment: CremationFormDataFragment,
                        component: CrematoriaBookingDocument,
                        validation: {
                            required: [
                                'ApplicantRelationship',
                                'ApplicantTitle',
                                'ApplicantFirstName',
                                'ApplicantLastName',
                                'ApplicantAge',
                                'ApplicantBusinessName',
                                'ApplicantEmail',
                                'SignatureDate'
                            ],
                            suggested: ['ApplicantPhone', 'ApplicantMobile'],
                            onValidate: {
                                'ApplicantAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                'DeclarationLocation.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeclarationLocation'));
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    }
                ]
            },
            onLoad: data => {
                const formData = (data.Arrangement && data.Arrangement.ArrangementForms) || null;
                if (formData) {
                    data.Arrangement.ArrangementForms = FilterNested(
                        Object.values(formData),
                        'ArrangementFormCremationBooking'
                    );
                }
            },
            formatSaveData: (saveData, state) => {}
        },
        MVC: {
            id: 'Mountain View Crematoria Booking Form',
            label: 'Crematorium Booking Form',
            connectedForms: ['LetterOfAuthority'],
            tabProps: {
                header: {
                    logo: mvcLogo,
                    alt: 'Mountain View Crematoria Logo'
                },
                formType: 'MVC',
                formLabel: 'Mountain View Crematoria Booking Form',
                template: 'MVC',
                tabs: [
                    {
                        id: 'CrematoriaBookingStepOne',
                        label: 'Deceased Details',
                        fragment: CremationFormDataFragment,
                        component: MVCDocument,
                        validation: {
                            required: [
                                'FuneralCompany',
                                'FuneralPhone',
                                'Email',
                                'ArrangerName',
                                'DeceasedFirstName',
                                'DeceasedLastName',
                                'DeceasedGender',
                                'DeceasedDateOfBirth',
                                'DeceasedDateOfDeath',
                                'DeceasedAge',
                                'DeliveryDate',
                                'DeliveryTime',
                                'ServiceDetails',
                                'ServiceType'
                            ],
                            suggested: ['CemeteryName', 'SiteReference'],
                            onValidate: {
                                'DeceasedAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                SpecialInstructions: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasSpecialInstructions') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    },
                    {
                        id: 'CrematoriaBookingStepTwo',
                        label: 'Applicant&Signature',
                        fragment: CremationFormDataFragment,
                        component: MVCDocument,
                        validation: {
                            required: ['ApplicantTitle', 'ApplicantFirstName', 'ApplicantLastName', 'SignatureDate'],
                            suggested: [
                                'ApplicantPhone',
                                'ApplicantMobile',
                                'ApplicantEmail',
                                'CemeteryName',
                                'SiteReference'
                            ],
                            onValidate: {
                                ApplicantRelationship: (newValue, persistedValue, hasValue, getField) => {
                                    if (!stringIsNullOrEmpty(persistedValue)) return validationHelper.ok();
                                    return validationHelper.required();
                                },
                                'ApplicantAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('ApplicantAddress'));
                                },

                                'DeceasedAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                SpecialInstructions: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasSpecialInstructions') || !stringIsNullOrEmpty(persistedValue))
                                        return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    }
                ]
            }
        },
        LSC: {
            id: 'Lakeside Crematoria Booking Form',
            label: 'Crematorium Booking Form',
            tabProps: {
                formLabel: 'Lakeside Crematoria Booking Form',
                template: 'LSC'
            }
        },
        WNC: {
            id: 'Woronora Booking Form',
            label: 'Crematorium Booking Form',
            tabProps: {
                formLabel: 'Woronora Crematoria Booking Form',
                template: 'WNC'
            }
        }
    },
    BurialBooking: {
        Generic: {
            id: 'BurialBookingForm',
            label: 'Burial Booking Form',
            fragment: SummaryFragment,
            queryName: 'BurialBookingQuery',
            component: ArrangementForm, //BurialForm,
            path: '/documentation/:key/:id/burial-booking',
            tabProps: {
                formObject: 'ArrangementFormBurialBooking',
                formType: 'BBooking',
                formLabel: 'Burial Booking Form',
                signatures: ['ApplicantSignature', 'WitnessSignature'],
                template: 'Generic',
                tabs: [
                    {
                        id: 'BurialBookingStepOne',
                        label: 'Deceased Details',
                        fragment: BurialFormDataFragment,
                        component: BurialDocument,
                        validation: {
                            required: [
                                'FuneralCompany',
                                'FuneralPhone',
                                'Email',
                                'ArrangerName',
                                'DeceasedFirstName',
                                'DeceasedLastName',
                                'DeceasedGender',
                                'DeceasedOccupation',
                                'DeceasedDateOfBirth',
                                'DeceasedDateOfDeath',
                                'DeceasedAge',
                                'DeceasedMartialStatus',
                                'DeceasedReligion',
                                'NextOfKinFullName'
                            ],
                            onValidate: {
                                'DeceasedAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('DeceasedAddress'));
                                },
                                DeceasedPlaceOfDeathAddressBook: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'AddressBook') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(persistedValue);
                                },
                                'DeceasedPlaceOfDeath.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('DeceasedPlaceOfDeathType') !== 'Residential') {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('DeceasedPlaceOfDeath'));
                                },
                                InfectiousDiseaseDetails: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('InfectiousDisease') || persistedValue) return validationHelper.ok();
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {
                            if (data['NumberOfCurrentlyBurried'] < 1) data['NumberOfCurrentlyBurried'] = 1;
                        },
                        formatSaveData: (saveData, state) => {}
                    },
                    {
                        id: 'BurialBookingStepTwo',
                        label: 'Booking Details',
                        fragment: BurialFormDataFragment,
                        component: BurialDocument,
                        validation: {
                            required: [
                                'BookingDate',
                                'BookingTime',
                                'GraveGroundType',
                                'GraveDepth',
                                'GraveDenomination',
                                'GravePortionGardenName',
                                'GraveBeamRowLot',
                                'GraveSection',
                                'GraveNumber',
                                'GraveReservationRequired',
                                'ServiceType'
                            ],
                            onValidate: {
                                CemeteryLocation: (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(persistedValue);
                                },
                                Celebrant: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasCelebrant')) {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(persistedValue);
                                },
                                SpecialRequirements: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('HasSpecialRequirements') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                ReservationFirstName: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('GraveReservationRequired') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                'ReservationAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('GraveReservationRequired')) {
                                        return validationHelper.ok();
                                    }
                                    return ValidateAddress(getField('ReservationAddress'));
                                },
                                CurrentlyBurriedFirstName: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('GraveGroundType') !== 'Reopened' || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                CurrentlyBurriedLastName: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('GraveGroundType') !== 'Reopened' || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                ReopenApplicantFirstName: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('GraveGroundType') !== 'Reopened' ||
                                        getField('ApplicantForReopen') !== 'Other' ||
                                        persistedValue
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                ReopenApplicantLastName: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('GraveGroundType') !== 'Reopened' ||
                                        getField('ApplicantForReopen') !== 'Other' ||
                                        persistedValue
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                AuthorityForReopen: (newValue, persistedValue, hasValue, getField) => {
                                    if (
                                        getField('GraveGroundType') !== 'Reopened' ||
                                        getField('ApplicantForReopen') !== 'Other' ||
                                        persistedValue
                                    ) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {
                            if (saveData['CemeteryLocation'] !== undefined) {
                                saveData['CemeteryLocationID'] = saveData['CemeteryLocation']['ID'];
                                delete saveData['CemeteryLocation'];
                            }
                            if (saveData['Celebrant'] !== undefined) {
                                saveData['CelebrantID'] = saveData['Celebrant']['ID'];
                                delete saveData['Celebrant'];
                            }
                        }
                    },
                    {
                        id: 'BurialBookingStepThree',
                        label: 'Applicant Details',
                        fragment: BurialFormDataFragment,
                        component: BurialDocument,
                        validation: {
                            required: [
                                'ApplicantRelationship',
                                'ApplicantTitle',
                                'ApplicantFirstName',
                                'ApplicantLastName',
                                'ApplicantAge',
                                'ApplicantBusinessName',
                                'ApplicantPhone',
                                'ApplicantMobile',
                                'ApplicantEmail',
                                'IntermentRightsCertificateNo'
                            ],
                            onValidate: {
                                'ApplicantAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                    return ValidateAddress(getField('ApplicantAddress'));
                                },
                                IntermentConsent: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('IntermentRightsHolder') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                IntermentRightsSigneeName: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('IntermentRightsHolder') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                IntermentSignatureDate: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('IntermentRightsHolder') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                IntermentRightsCertificateName: (newValue, persistedValue, hasValue, getField) => {
                                    if (!getField('IntermentRightsHolder') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                },
                                IntermentRightsHolderName: (newValue, persistedValue, hasValue, getField) => {
                                    if (getField('IntermentRightsHolder') || persistedValue) {
                                        return validationHelper.ok();
                                    }
                                    return validationHelper.required();
                                }
                            }
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    },
                    {
                        id: 'BurialBookingStepFour',
                        label: 'Consent & Signature',
                        fragment: BurialFormDataFragment,
                        component: BurialDocument,
                        validation: {
                            required: ['DeclarationDate', 'SignatureDate'],
                            onValidate: {}
                        },
                        onLoad: data => {},
                        formatSaveData: (saveData, state) => {}
                    }
                ]
            },
            onLoad: data => {
                const formData = (data.Arrangement && data.Arrangement.ArrangementForms) || null;
                if (formData) {
                    data.Arrangement.ArrangementForms = FilterNested(
                        Object.values(formData),
                        'ArrangementFormBurialBooking'
                    );
                }
            },
            formatSaveData: (saveData, state) => {}
        },
        WCB: {
            id: 'Wollongong Council Burial Booking Form',
            tabProps: {
                formLabel: 'Wollongong Council Burial Booking Form',
                template: 'WCB',
                passDown: {
                    diseaseLists: true,
                    coffinShapes: [
                        { label: 'Square', value: 'Square' },
                        { label: 'Tapered', value: 'Tapered' }
                    ],
                    coffinLidRounded: true
                }
            }
        },
        LSB: {
            id: 'Lakeside Burial Booking Form',
            tabProps: {
                formLabel: 'Lakeside Burial Booking Form',
                template: 'LSB',
                passDown: {
                    childBabyGraveType: true
                }
            }
        },
        WNB: {
            id: 'Woronora Burial Booking Form',
            tabProps: {
                formLabel: 'Woronora Burial Booking Form',
                template: 'WNB'
            }
        },
        SHB: {
            id: 'Shellharbour Burial Booking Form',
            tabProps: {
                formLabel: 'Shellharbour Burial Booking Form',
                template: 'SHB',
                passDown: {
                    coffinShapes: [
                        { label: 'Square', value: 'Square' },
                        { label: 'Tapered', value: 'Tapered' }
                    ]
                }
            }
        },
        KB: {
            id: 'Kiama Burial Booking Form',
            tabProps: {
                formLabel: 'Kiama Burial Booking Form',
                template: 'KB'
            }
        },
        RWB: {
            id: 'Rookwood Burial Booking Form',
            tabProps: {
                formLabel: 'Rookwood Burial Booking Form',
                template: form => {
                    const religion = form.getField('DeceasedReligion');
                    return religion && religion.toLowerCase() === 'catholic' ? 'RWCB' : 'RWOB';
                },
                passDown: {
                    coffinShapes: [
                        { label: 'Square', value: 'Square' },
                        { label: 'Tapered', value: 'Tapered' }
                    ],
                    intermentMoreThanOne: true
                }
            }
        }
    },
    LetterOfAuthority: {
        id: 'Letter Of Authority',
        label: 'Letter Of Authority',
        fragment: SummaryFragment,
        queryName: 'LOAQuery',
        component: ArrangementForm,
        path: '/documentation/:key/:id/letter-of-authority',
        tabProps: {
            formObject: 'ArrangementFormLetterOfAuthority',
            formType: 'LetterOfAuthority',
            formLabel: 'Letter Of Authority',
            signatures: ['ApplicantSignature'],
            // hideSidebar: true,
            tabs: [
                {
                    id: 'LOA',
                    label: 'Letter Of Authority',
                    fragment: LetterOfAuthorityDataFragment,
                    component: LetterOfAuthority,
                    validation: {
                        required: [
                            'ApplicantFirstName',
                            'ApplicantLastName',
                            'ContactFirstName',
                            'ContactLastName',
                            'ContactEmail',
                            'ContactPhone'
                        ],
                        optional: [],
                        onValidate: {
                            'ContactAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                                return ValidateAddress(getField('ContactAddress'));
                            }
                        }
                    },
                    onLoad: data => {},
                    formatSaveData: (saveData, state) => {}
                }
            ]
        },
        onLoad: data => {
            const formData = (data.Arrangement && data.Arrangement.ArrangementForms) || null;
            if (formData) {
                data.Arrangement.ArrangementForms = FilterNested(
                    Object.values(formData),
                    'ArrangementFormLetterOfAuthority'
                );
            }
        },
        formatSaveData: (saveData, state) => {}
    }
};

export const GetDocumentForLocation = (documentType, form) => {
    if (!form) return false;
    switch (documentType) {
        case 'Certification.FileStatDecForm':
            //A-Form
            return {
                ...TabTemplates.StatutoryForms.AForm,
                FormID: 'ArrangementFormStatutoryForms'
            };
        case 'Certification.FileCremBookingForm':
            //Cremation booking form
            const crematoriaID = parseInt(form.getField('Disposal.Location.ID'));
            switch (crematoriaID) {
                case 7485: //LakeSide
                case 2567: //LakeSide
                    if (!TabTemplates.CremationBooking.LSC.updated) {
                        TabTemplates.CremationBooking.LSC = updateNested(
                            TabTemplates.CremationBooking.Generic,
                            TabTemplates.CremationBooking.LSC
                        );
                        TabTemplates.CremationBooking.LSC.updated = true;
                    }
                    return {
                        ...TabTemplates.CremationBooking.LSC,
                        FormID: 'ArrangementFormCremationBooking'
                    };
                case 6463: //MountainView
                    if (!TabTemplates.CremationBooking.MVC.updated) {
                        TabTemplates.CremationBooking.MVC = updateNested(
                            TabTemplates.CremationBooking.Generic,
                            TabTemplates.CremationBooking.MVC
                        );
                        TabTemplates.CremationBooking.MVC.updated = true;
                    }
                    return {
                        ...TabTemplates.CremationBooking.MVC,
                        FormID: 'ArrangementFormCremationBooking'
                    };
                default:
                    return {
                        ...TabTemplates.CremationBooking.Generic,
                        FormID: 'ArrangementFormCremationBooking'
                    };
            }

        case 'Certification.FileBurialBookingForm':
            //Burial booking form
            const cemeteryID = parseInt(form.getField('Disposal.Location.ID'));
            switch (cemeteryID) {
                //Wollongong council
                case 267: //Wollongong Cemetery
                case 2465:
                    if (!TabTemplates.BurialBooking.WCB.updated) {
                        TabTemplates.BurialBooking.WCB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.WCB
                        );
                        TabTemplates.BurialBooking.WCB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.WCB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                case 7486:
                case 2554: //Lakeside
                    if (!TabTemplates.BurialBooking.LSB.updated) {
                        TabTemplates.BurialBooking.LSB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.LSB
                        );
                        TabTemplates.BurialBooking.LSB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.LSB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                case 7966:
                case 512: //Woronora
                    if (!TabTemplates.BurialBooking.WNB.updated) {
                        TabTemplates.BurialBooking.WNB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.WNB
                        );
                        TabTemplates.BurialBooking.WNB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.WNB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                case 4890:
                case 4379: //Shellharbour
                    if (!TabTemplates.BurialBooking.SHB.updated) {
                        TabTemplates.BurialBooking.SHB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.SHB
                        );
                        TabTemplates.BurialBooking.SHB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.SHB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                case 1116:
                case 153: //Kiama
                    if (!TabTemplates.BurialBooking.KB.updated) {
                        TabTemplates.BurialBooking.KB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.KB
                        );
                        TabTemplates.BurialBooking.KB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.KB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                case 123:
                case 535:
                case 769:
                case 2035:
                case 7328:
                case 7329: //Rookwood
                    if (!TabTemplates.BurialBooking.RWB.updated) {
                        TabTemplates.BurialBooking.RWB = updateNested(
                            TabTemplates.BurialBooking.Generic,
                            TabTemplates.BurialBooking.RWB
                        );
                        TabTemplates.BurialBooking.RWB.updated = true;
                    }
                    return {
                        ...TabTemplates.BurialBooking.RWB,
                        FormID: 'ArrangementFormBurialBooking'
                    };
                default:
                    return {
                        ...TabTemplates.BurialBooking.Generic,
                        FormID: 'ArrangementFormBurialBooking'
                    };
            }
        default:
            return false;
    }
};

export const FilterNested = function(childrens, type) {
    return childrens.filter(children => {
        return children && children.ID > 0 && children.Type === type;
    });
};

export const ValidateAddress = address => {
    //We can put here more extensive validation if needed, but this one will do for now
    if (isNullOrUndefined(address) || (stringIsNullOrEmpty(address.ID) && stringIsNullOrEmpty(address.Suburb)))
        return validationHelper.required();
    return validationHelper.ok();
};
