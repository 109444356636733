import { Application, BdmResponse, Disposal, LegacyDocuments, WebServiceLogs } from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment BdmsFields on Funeral {
                FuneralDataStatus
                ExportDate
                ExportStatus
                ExportId
                DeathCertificateStatus
                DeathCertificateNumber
                FormattedBDMResponse
                LifeLinkValidationField {
                    AllItems {
                        Key
                        Value
                    }
                }
                DeathCertificateNotRequired
                LatestBDMForm {
                    ID
                }
            }
        `,
        BdmResponse,
        Disposal,
        Application,
        WebServiceLogs,
        LegacyDocuments
    ],
    'Funeral'
);

export const UpdateFileMutation = gql`
    mutation($input: UpdateFileInput!) {
        updateFile(input: $input) {
            ID
            Name
            Size
            AbsoluteLink
        }
    }
`;
