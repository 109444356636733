import gql from 'graphql-tag';

export default gql`
    fragment ReflectionRoom on Funeral {
        ID
        ReflectionRoom {
            ID
            Confirmed
            Start
            End
            Date
            Time
            Duration
            NumberOfPeople
            AuthorisedContact
            Applicant
            AttendeeNames
        }
    }
`;
