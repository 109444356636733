import { IconButton, withStyles } from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { compose } from 'react-apollo';
import { createForm } from '../../util/form';
import { niceDateFromString } from '../../util/date';
import { deleteTypeName, isNullOrUndefined } from '../../util/objects';
import { getUser } from '../../util/sessions';
import { joinDefined } from '../../util/strings';
import UploadField, { getFileName } from '../../component/form/FileUpload';
import Button from './extras/Button';
import Inline, { inlineAlignment } from './extras/Inline';
import LeftLabel from './extras/LeftLabel';
import RadioGroup from '../../component/form/RadioGroup';
import EditIcon from '../../component/icon/EditIcon';
import RepeatIcon from '../../component/icon/RepeatIcon';
import Grid from '../../component/form/Grid';
import ScrollingWrapper from './extras/ScrollingWrapper';
import TextField from '../../component/form/TextField';
import TaskLabelAutoComplete from './extras/TaskLabelAutoComplete';
import FileUploadCard from './extras/FileUploadCard';
import FlexGrid from './extras/FlexGrid';
import BackArrow from '../../component/icon/BackArrow';
import Spinner from '../../component/Spinner';
import AlertModal from './extras/AlertModal';
import RecurrenceModal from './Modals/RecurrenceModal';
import TaskModal from './Modals/TaskModal';
import TaskChecklist from './TaskChecklist';
import {
    AuditEventTypes,
    getTaskStatus,
    getTaskStatusClass,
    TASK_CATEGORY_OPTIONS,
    TaskProgressField,
    taskURLObj,
    TeamDropdownField,
    updateTaskFunc
} from './TaskConstants';
import TaskStar from './TaskStar';
import SaveIcon from '../../component/icon/SaveIcon';
import CloseIcon from '../../component/icon/CloseIcon';
import TickIcon from '../../component/icon/TickIcon';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import TaskAudit from './TaskAudit';
import LinkButton from '../../component/form/LinkButton';
import { WhiteLoader } from '../../component/WhiteLoader';

class TaskDetail extends Component {
    state = {
        showRecurrenceModal: false,
        showRecurrenceArchiveAlertModal: false,
        showArchiveAlertModal: false,
        showSaveWarningModal: false,
        showEditModal: false,
        item: null,
        taskForm: null,
        loading: false
    };

    static getDerivedStateFromProps({ task, formContext }, state) {
        const newState = {};
        if (task && (!state.item || state.item !== task.ID)) {
            const oldContext = state.taskForm !== null ? state.taskForm.context : formContext;
            newState.item = task.ID;
            newState.taskForm = createForm(oldContext, cloneDeep({ ...task, NewComment: null }));
        }
        return newState;
    }

    componentDidUpdate() {
        const { taskForm } = this.state;
        if (taskForm !== null && taskForm.context === null) {
            taskForm.context = this;
        }
    }

    render() {
        const { onUpdate, task, loading } = this.props;
        const me = getUser();
        if (loading && !task) {
            return (
                <div className="view-task-container">
                    <WhiteLoader actionWord={`Loading task`} />
                </div>
            );
        }

        if (!task) return null;

        const {
            showRecurrenceModal,
            showRecurrenceArchiveAlertModal,
            showArchiveAlertModal,
            showSaveWarningModal,
            showEditModal
        } = this.state;
        return (
            <Fragment>
                <div className="view-task-container" style={{ opacity: loading || this.state.loading ? 0.5 : null }}>
                    {this.renderTaskDetails()}
                </div>
                <RecurrenceModal
                    open={showRecurrenceModal}
                    task={task}
                    onClose={() => this.handleCloseRecurrenceModal()}
                />
                <TaskModal
                    taskID={task.ID}
                    open={showEditModal}
                    onSaved={result => {
                        this.setState(
                            {
                                loading: false,
                                item: result.ID,
                                taskForm: createForm(this, result)
                            },
                            () => onUpdate && onUpdate(result)
                        );
                    }}
                    onClose={() => this.handleCloseEditModal()}
                />

                <AlertModal
                    variant="warning"
                    open={showSaveWarningModal}
                    title="Prompt - Close task without saving"
                    primaryAction="Oh yeah, save changes"
                    onClickPrimaryAction={() => this.handleCloseWarningModalWithSave('showSaveWarningModal')}
                    secondaryAction="Nah, discard changes"
                    onClickSecondaryAction={() => this.handleCloseWarningModalWithoutSave('showSaveWarningModal')}
                    onClose={() => this.handleCloseWarningModal('showSaveWarningModal')}
                >
                    <p>Woah there {me.FirstName}! Looks like you're closing the task without saving your updates.</p>
                    <p>Do you want to update the task?</p>
                </AlertModal>

                <AlertModal
                    variant="primary"
                    open={showArchiveAlertModal}
                    title="Prompt - Archive Task"
                    primaryAction="Yeah, let's archive it"
                    onClickPrimaryAction={() => this.handleCloseWarningModalWithSave('showArchiveAlertModal')}
                    secondaryAction="Wait up, I need it"
                    onClickSecondaryAction={() => this.handleCloseWarningModal('showArchiveAlertModal')}
                    onClose={() => this.handleCloseWarningModal('showArchiveAlertModal')}
                >
                    <p>Woah there {me.FirstName}! Looks like you're archiving a task.</p>
                    <p>Are you sure you don't want it anymore?</p>
                </AlertModal>
                <AlertModal
                    variant="primary"
                    open={showRecurrenceArchiveAlertModal}
                    title="Prompt - Archive Recurring Task"
                    primaryAction="Yeah, let's archive it"
                    onClickPrimaryAction={() => this.handleCloseWarningModalWithSave('showRecurrenceArchiveAlertModal')}
                    secondaryAction="Wait up, I need it"
                    onClickSecondaryAction={() =>
                        this.handleCloseWarningModalWithoutSave('showRecurrenceArchiveAlertModal')
                    }
                    onClose={() => this.handleCloseWarningModal('showRecurrenceArchiveAlertModal')}
                >
                    <p>Woah there {me.FirstName}! Looks like you're archiving a recurring task.</p>
                    <p>Would you like to archive:</p>
                    <RadioGroup
                        options={[
                            { label: 'This recurrence only', value: 'taskOnly' },
                            { label: 'All occurrences', value: 'allTasks' }
                        ]}
                    />
                </AlertModal>
            </Fragment>
        );
    }

    renderTaskHeader() {
        const { task } = this.props;
        const { loading } = this.state;
        const convertDateToCheck = date => !isNullOrUndefined(date);
        const dueClass = getTaskStatusClass(task);
        const me = getUser();
        const myTask = task && task.AssignedMembers.find(e => !!e && Number(e.ID) === Number(me.ID));
        const myStar = myTask && myTask._join && myTask._join.TaskMembers && myTask._join.TaskMembers.Starred;
        return (
            <Fragment>
                <div className="details-header-desktop">
                    <div className="task-header">
                        <Inline className="header" alignment={inlineAlignment.rightAlignSiblings}>
                            <div>
                                <TaskStar
                                    value={convertDateToCheck(myStar)}
                                    disabled={loading || !myTask}
                                    onChange={checked => {
                                        const AssignedMembers = task.AssignedMembers.map(e => {
                                            e._join = {
                                                TaskMembers: {
                                                    Starred:
                                                        Number(e.ID) === Number(me.ID)
                                                            ? checked
                                                                ? new Date()
                                                                : null
                                                            : e._join &&
                                                              e._join.TaskMembers &&
                                                              e._join.TaskMembers.Starred
                                                }
                                            };
                                            return e;
                                        });
                                        this.onStarChange('AssignedMembers', AssignedMembers);
                                    }}
                                />
                                <IconButton className="edit-task" onClick={() => this.handleShowEditModal()}>
                                    <EditIcon />
                                </IconButton>
                            </div>
                            {!!task.RecurFreq && (
                                <span title="Recurring task">
                                    <IconButton
                                        disabled
                                        className="repeat-task"
                                        onClick={() => this.handleShowRecurrenceModal()}
                                    >
                                        <RepeatIcon />
                                    </IconButton>
                                </span>
                            )}
                            {!!task.Due && (
                                <p className={`due-date ${dueClass}`}>
                                    <span className="bold"> Due:</span> {moment(task.Due).format('ddd, ll')}
                                </p>
                            )}
                        </Inline>
                    </div>
                </div>

                <div className="details-header-mobile">
                    <div className="task-header">
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <Inline center>
                                    <Button
                                        variant="primary icon-button white shadow"
                                        title="Back to Task List"
                                        size="lg"
                                        onClick={() => this.handleShowSaveWarningModal()}
                                    >
                                        <BackArrow className="back-arrow" />
                                    </Button>
                                    <h1 className="record-id">Task #{task.ID}</h1>
                                </Inline>
                            </div>
                            <div>
                                <Inline>
                                    {!!task.RecurFreq && (
                                        <IconButton className="repeat-task" disabled>
                                            <RepeatIcon />
                                        </IconButton>
                                    )}
                                    <TaskStar
                                        value={convertDateToCheck(myStar)}
                                        disabled={loading || !myTask}
                                        onChange={checked => {
                                            const AssignedMembers = task.AssignedMembers.map(e => {
                                                e._join = {
                                                    TaskMembers: {
                                                        Starred:
                                                            Number(e.ID) === Number(me.ID)
                                                                ? checked
                                                                    ? new Date()
                                                                    : null
                                                                : e._join &&
                                                                  e._join.TaskMembers &&
                                                                  e._join.TaskMembers.Starred
                                                    }
                                                };
                                                return e;
                                            });
                                            this.onStarChange('AssignedMembers', AssignedMembers);
                                        }}
                                    />
                                    <IconButton className="edit-task" onClick={() => this.handleShowEditModal()}>
                                        <EditIcon />
                                    </IconButton>
                                </Inline>
                            </div>
                        </Inline>
                    </div>
                </div>
            </Fragment>
        );
    }

    onStarChange(propertyName, value) {
        const { task } = this.props;

        this.updateTask({
            ...task,
            [propertyName]: value
        });
    }

    // this should be coming via a request call back
    renderTaskDetails() {
        const { taskForm } = this.state;
        const { task } = this.props;
        const status = getTaskStatus(task);
        const assignedMembers = task.AssignedMembers.filter(e => !!e);
        const linkObj = taskURLObj(task);
        const statusClass = getTaskStatusClass(task);
        const category = TASK_CATEGORY_OPTIONS.find(e => !!e && e.value === task.Category);
        return (
            <Fragment>
                {this.renderTaskHeader()}
                <ScrollingWrapper>
                    <div className="task-body">
                        <div className={`task-card${!!task.Archived ? ' archived' : ''}`}>
                            <Grid container spacing={8}>
                                <Grid item pc>
                                    <div className="details-header-mobile alerts">
                                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                            <p>
                                                <strong className={`task-status ${statusClass}`}> {status}</strong>
                                            </p>
                                            <div>
                                                {task.Due && (
                                                    <p
                                                        className={
                                                            'due-date' + (this.status === 'Overdue' ? ' overdue' : '')
                                                        }
                                                    >
                                                        <span className="bold">Due: </span>
                                                        {moment(task.Due).format('ll')}
                                                    </p>
                                                )}
                                            </div>
                                        </Inline>
                                    </div>
                                </Grid>

                                <Grid item pc>
                                    <div className="details-header-desktop">
                                        <h1 className="record-id">Task #{task.ID}</h1>
                                    </div>
                                    <h2 className="task-heading">{task.Title}</h2>
                                    <p className="task-description">{task.Description}</p>
                                </Grid>

                                {!!task.RequiredAction && (
                                    <Grid item pc>
                                        <div className="task-message">
                                            <h3 className="spacing">Message:</h3>
                                            <p className="quoted">{task.RequiredAction}</p>
                                        </div>
                                    </Grid>
                                )}

                                <Grid item pc>
                                    <TaskChecklist task={task} canCreate />
                                </Grid>

                                <Grid item pc>
                                    <div className="task-assignments">
                                        <LeftLabel label="Category:">
                                            <p>{(category && category.label) || 'Custom Task'}</p>
                                        </LeftLabel>
                                        {!!linkObj && (
                                            <LeftLabel label="Refers to:">
                                                <p>
                                                    <LinkButton
                                                        href={linkObj.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        text={`${linkObj.category || ''} ${linkObj.title}`}
                                                    />
                                                </p>
                                            </LeftLabel>
                                        )}

                                        <LeftLabel label="Assigned Team:">{TeamDropdownField(taskForm)}</LeftLabel>

                                        <LeftLabel label="Assigned Staff:">
                                            {/*<div className="user-container">
                                                {assignedMembers.map(user => (
                                                    <UserCircle key={'user' + user.ID} person={user} />
                                                ))}
                                            </div>*/}
                                            <p>
                                                {joinDefined(
                                                    assignedMembers
                                                        .sort((a, b) => (a.Surname < b.Surname ? -1 : 1))
                                                        .map(
                                                            user =>
                                                                joinDefined([user.FirstName, user.Surname], ' ') ||
                                                                user.Email
                                                        ),
                                                    ', '
                                                ) || '(none)'}
                                            </p>
                                        </LeftLabel>

                                        <LeftLabel label="Recurring:">
                                            {(!!task.RecurFreq > 0 && (
                                                <p>
                                                    Every {task.RecurFreq !== 1 && task.RecurFreq} {task.RecurType}
                                                    {task.RecurFreq !== 1 && 's'}
                                                    {(!!task.RecurEnds &&
                                                        ` until ${niceDateFromString(task.RecurEnds)}`) ||
                                                        ', for all eternity'}
                                                </p>
                                            )) || <p>(does not recur)</p>}
                                        </LeftLabel>

                                        <LeftLabel label="Progress:">
                                            <p>
                                                <strong className={`task-status ${statusClass}`}> {status}</strong>
                                            </p>
                                        </LeftLabel>
                                    </div>
                                </Grid>

                                <Grid item pc>
                                    {this.renderTaskActions()}
                                </Grid>
                                <Grid item pc>
                                    <TaskAudit
                                        label="Task History:"
                                        audits={task.Audits.edges.filter(e => e.node.Type !== 0)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </ScrollingWrapper>
                {this.renderTaskFooter()}
            </Fragment>
        );
    }

    renderTaskActions() {
        const { labels, task } = this.props;
        const { taskForm } = this.state;
        const item = taskForm.state;
        const user = getUser();
        const assignedLabels = item.AssignedLabels.filter(e => !!e.Member && Number(e.Member.ID) === Number(user.ID));
        const attachments = item.Attachments;
        return (
            <div className="task-actions">
                <Grid>
                    <h2 className="spacing">Task Actions</h2>

                    <LeftLabel label="Set Progress:">{TaskProgressField(taskForm)}</LeftLabel>

                    <LeftLabel label="Apply Labels:">
                        <TaskLabelAutoComplete
                            labels={labels}
                            memberId={user.ID}
                            onSelect={label => this.onSelectTaskLabel(label)}
                            placeholder="Select from your labels..."
                        />
                        <div className="tag-list">
                            {assignedLabels
                                .filter(e => labels.find(l => l.ID === e.ID && !l.NotActive))
                                .map(label => (
                                    <Button
                                        onClick={() => this.onTaskLabelRemove(label)}
                                        key={'user' + label.ID}
                                        variant="tag"
                                    >
                                        {label.Title}
                                    </Button>
                                ))}
                        </div>
                    </LeftLabel>

                    <div className="attach" style={{ width: '100%' }}>
                        <form>
                            <label className="form-label">Upload an Attachment:</label>
                            <UploadField
                                buttonOnly
                                label="Choose File"
                                folderPath={`/documents/task/${task.ID}`}
                                onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                            />
                        </form>

                        {!!attachments && attachments.length > 0 && (
                            <div className="file-list">
                                <h4>Attached Files:</h4>
                                <FlexGrid>
                                    {attachments.map(attachment => (
                                        <FileUploadCard
                                            variant="mini"
                                            key={'attachment' + attachment.ID}
                                            fileName={attachment.Name}
                                            fileLink={attachment.AbsoluteLink}
                                        />
                                    ))}
                                </FlexGrid>
                            </div>
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        <label className="form-label">Leave a Note:</label>
                        <TextField
                            placeholder="Notes are written here..."
                            multiline
                            name="NewComment"
                            form={taskForm}
                        />
                        {this.renderAdditionalNotes()}
                    </div>
                </Grid>
            </div>
        );
    }

    onTaskProgressChange(e) {
        const value = e.target.value;
        const { taskForm } = this.state;
        taskForm.setField({
            Completed: value === 'Completed' ? new Date() : null,
            InProgress: value === 'InProgress'
        });
    }

    renderAdditionalNotes() {
        const { task } = this.props;
        if (!(task && task.Audits)) return null;
        const comments = task.Audits.edges.filter(
            audit => audit.node.Type === AuditEventTypes.Comment && !!audit.node.Comment
        );

        return (
            <div style={{ width: '100%' }}>
                <Grid container direction="column">
                    {!!comments && comments.length > 0 && (
                        <Grid item pc>
                            <h3>Notes:</h3>
                        </Grid>
                    )}
                    <div className="notes-container" style={{ width: '100%' }}>
                        {!!comments &&
                            comments.length > 0 &&
                            comments.map((audit, index) => (
                                <Fragment key={index}>
                                    <Grid item pc>
                                        <Inline
                                            alignment={inlineAlignment.rightAlignSiblings}
                                            center
                                            //className="task-details"
                                        >
                                            <div>
                                                {joinDefined(
                                                    [audit.node.AddedBy.FirstName, audit.node.AddedBy.Surname],
                                                    ' '
                                                )}{' '}
                                                wrote:
                                            </div>

                                            <div>{moment(audit.node.Created).format('DD/MM/YYYY hh:mma')}</div>
                                        </Inline>
                                        <div className="notes-readonly">
                                            <pre>{audit.node.Comment}</pre>
                                        </div>
                                    </Grid>
                                </Fragment>
                            ))}
                    </div>
                </Grid>
            </div>
        );
    }

    onTeamRemove(group) {
        const { taskForm } = this.state;
        let AssignedTeams = taskForm.getField('AssignedTeams');
        AssignedTeams = AssignedTeams.filter(obj => group.ID !== obj.ID);
        taskForm.setField({ AssignedTeams });
    }

    onSelectTeam(e, form, groups) {
        form.setField({
            AssignedTeams: groups.filter(group => e.target.value === group.ID)
        });
    }

    onMemberRemove(member) {
        const { taskForm } = this.state;
        let AssignedMembers = taskForm.getField('AssignedMembers');
        AssignedMembers = AssignedMembers.filter(obj => member.ID !== obj.ID);
        taskForm.setField({ AssignedMembers });
    }

    onSelectStaffMember(member) {
        const { taskForm } = this.state;
        const AssignedMembers = taskForm.getField('AssignedMembers');
        const cloned = deleteTypeName(cloneDeep(member));
        cloned._join = {};
        AssignedMembers.push(cloned);

        taskForm.setField({ AssignedMembers });
    }

    onSelectTaskLabel(label) {
        const { taskForm } = this.state;
        const AssignedLabels = taskForm.getField('AssignedLabels') || [];
        const me = getUser();
        const cloned = deleteTypeName(cloneDeep(label));
        cloned.Member = { ID: '' + me.ID };
        if (!AssignedLabels.find(has => has.ID === cloned.ID)) {
            AssignedLabels.push(cloned);
            taskForm.setField({ AssignedLabels });
        }
    }

    onTaskLabelRemove(label) {
        const { taskForm } = this.state;
        let AssignedLabels = taskForm.getField('AssignedLabels');
        AssignedLabels = AssignedLabels.filter(obj => label.ID !== obj.ID);
        taskForm.setField({ AssignedLabels });
    }

    updateTask(task, displayTask = true) {
        const { taskForm } = this.state;
        const { onUpdate } = this.props;

        this.setState({ loading: true });
        const that = this;

        task = task ? task : taskForm.state;

        return updateTaskFunc(task).then(
            result => {
                const updatedTask = cloneDeep(result.data.updateTask);
                const updatedTaskState = cloneDeep(result.data.updateTask);

                that.setState(
                    {
                        loading: false,
                        item: updatedTask.ID,
                        taskForm: createForm(that, updatedTaskState)
                    },
                    () => onUpdate && onUpdate(updatedTask)
                );
            },
            e => that.onGqlError('Failed to update task.', e)
        );
    }

    renderTaskFooter() {
        const { taskForm, loading } = this.state;
        const noChange = isEqual(taskForm.original, taskForm.state);

        return (
            <div className="task-footer">
                <Inline alignment={inlineAlignment.rightAlignSiblings}>
                    <div className="align-left">
                        <Button variant="secondary" onClick={() => this.handleShowSaveWarningModal()}>
                            <CloseIcon />
                            <Hidden smDown>Close</Hidden>
                        </Button>
                    </div>
                    <Button
                        disabled={noChange || loading}
                        variant="confirmation"
                        onClick={() => this.handleSaveButton()}
                    >
                        {(loading && <Spinner size="sm" />) || noChange ? <TickIcon /> : <SaveIcon />}
                        <Hidden smDown>{noChange ? 'Saved' : 'Save Changes'}</Hidden>
                    </Button>
                </Inline>
            </div>
        );
    }

    handleShowRecurrenceModal() {
        this.setState({ showRecurrenceModal: true });
    }

    handleShowEditModal() {
        this.setState({ showEditModal: true });
    }

    handleCloseRecurrenceModal() {
        this.setState({ showRecurrenceModal: false });
    }

    handleCloseEditModal() {
        this.setState({ showEditModal: false });
    }

    handleShowSaveWarningModal() {
        const { taskForm } = this.state;
        const { onClose } = this.props;
        const noChange = isEqual(taskForm.original, taskForm.state);

        if (noChange) {
            onClose();
        } else {
            this.setState({ showSaveWarningModal: true });
        }
    }

    handleSaveButton() {
        const { taskForm } = this.state;
        const { task } = this.props;
        const noChange = isEqual(taskForm.original, taskForm.state);

        if (noChange) {
            return null;
        } else {
            if (!!taskForm.state.Archived && !task.Archived) {
                if (task.RecurFreq > 0) {
                    this.setState({ showRecurrenceArchiveAlertModal: true });
                } else {
                    this.setState({ showArchiveAlertModal: true });
                }
            } else {
                this.updateTask(taskForm.state, true);
            }
        }
    }

    handleCloseWarningModal(modal) {
        !!modal && this.setState({ [modal]: false });
    }

    handleCloseWarningModalWithSave(modal) {
        const { onClose } = this.props;
        const { taskForm } = this.state;
        const that = this;
        this.updateTask(taskForm.state, true).then(
            result => {
                !!modal && this.setState({ [modal]: false });
                onClose();
            },
            e => that.onGqlError('Failed to update task.', e)
        );
    }

    handleCloseWarningModalWithoutSave(modal) {
        const { onClose } = this.props;
        this.setState({ [modal]: false });
        onClose();
    }

    handleUploadAttachment(uploadFile, attachments) {
        const { taskForm } = this.state;

        attachments.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        taskForm.setState({
            Attachments: attachments
        });

        this.setState({
            loading: false
        });
    }
    onGqlError(action, error) {
        if (error) console.error(error);
        this.props.setSnackbarMessage(action, false, null, new Error(error));
    }
}

export default compose(withSnackbarMessage, withStyles({}))(TaskDetail);
