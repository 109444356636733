import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';
import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withRouter } from 'react-router';
import TextField from '../../../component/form/TextField';
import RadioGroup from '../../../component/form/RadioGroup';
import SignatureField from '../../../component/form/SignatureField';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';

class LetterOfAuthority extends Component {
    render() {
        return this.props.form ? (
            <Fragment>
                {this.renderContactQuestion()}
                {this.renderLetterOfAuthority()}
            </Fragment>
        ) : null;
    }

    renderContactQuestion() {
        const { form } = this.props;
        return (
            <section>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <RadioGroup
                            label="I, the funeral applicant, will remain the person of contact"
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: '' }
                            ]}
                            form={form}
                            name="PersonOfContact"
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }

    renderLetterOfAuthority() {
        const { form } = this.props;
        return (
            <section id="letterAuthority">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <div>
                            <span>I</span>
                            <TextField
                                className="doc"
                                label="First name"
                                placeholder="First name of applicant"
                                form={form}
                                name="ApplicantFirstName"
                            />
                            <TextField
                                className="doc"
                                label="Surname"
                                placeholder="Surname of applicant"
                                form={form}
                                name="ApplicantLastName"
                            />
                            <span>give permission for</span>
                            <TextField
                                className="doc"
                                label="Contact first name"
                                placeholder="Selected contact first name"
                                form={form}
                                name="ContactFirstName"
                            />
                            <TextField
                                className="doc"
                                label="Contact surname"
                                placeholder="Selected contact surname"
                                form={form}
                                name="ContactLastName"
                            />
                            <span>, of</span>
                            <AddressAutocomplete
                                className="doc address"
                                placeholder="Search contact address"
                                form={form}
                                componentFields={{
                                    line1: 'ContactAddress.AddressLine1',
                                    line2: 'ContactAddress.AddressLine2',
                                    city: 'ContactAddress.Suburb',
                                    state: 'ContactAddress.State',
                                    country: 'ContactAddress.Country',
                                    code: 'ContactAddress.Postcode'
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField label="Email" placeholder="Contact email" form={form} name="ContactEmail" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField label="Phone" placeholder="Contact phone" form={form} name="ContactPhone" />
                    </Grid>

                    <Grid item xs={12}>
                        <p>To</p>
                        <ol>
                            <li>
                                <RadioGroup
                                    label="Send and receive correspondence PRIOR to the cremation taking place"
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: '' }
                                    ]}
                                    form={form}
                                    name="CorrespondenceBefore"
                                />
                            </li>
                            <li>
                                <RadioGroup
                                    label="Send and receive correspondence AFTER to the cremation taking place"
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: '' }
                                    ]}
                                    form={form}
                                    name="CorrespondenceAfter"
                                />
                            </li>
                            <li>
                                <RadioGroup
                                    label="Decide on the disposition of the cremated remains, including deciding on choice of containment AND collection of the cremated remains from Mountain View Crematoria"
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: '' }
                                    ]}
                                    form={form}
                                    name="DecideDisposition"
                                />
                            </li>
                        </ol>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <p>
                            I, the funeral applicant, understand and agree that my selected contact person will make
                            these decisions on my behalf.
                        </p>

                        <SignatureField form={form} name="ApplicantSignature" />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <p>
                            I, the selected contact person, understand and agree to pay for any additional disbursement
                            expenses, including my selection of containment and/or memorialisation or optional postage
                            expenses will be at my cost and will abide by the then current Mountain View Crematoria
                            terms and conditions.
                        </p>

                        <SignatureField form={form} name="ContactSignature" />
                    </Grid>
                </Grid>
            </section>
        );
    }
}

export default compose(withRouter, withStyles({}))(LetterOfAuthority);
