import React from 'react';
import { Pie } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';

export default withStyles({})(({ data, loading }) => {
    // some array reducers use first acc as first object, so need to check
    const total = (data && data.reduce((acc, val) => Number(val.value) + (isNaN(acc) ? Number(acc.value) : acc))) || 1;
    const colours = (data && data.filter(e => !!e.colour).map(e => e.colour)) || [];

    const myData = {
        labels: data && data.map(e => `${e.label} (${Number(((e.value / total) * 100).toFixed(2))}%)`),
        datasets: [
            {
                data: data && data.map(e => e.value),
                backgroundColor: colours.length > 0 ? colours : ['#7d9cc4', '#44AF69', '#F7DD60', '#f89e53', '#D14F57'],
                hoverBackgroundColor: [],
                hoverBorderColor: '#ffffff'
            }
        ]
    };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {(!!loading && <Spinner />) || (
                <Pie
                    options={{ maintainAspectRatio: false, tooltips: { enabled: true } }}
                    height={400}
                    legend={{
                        position: 'bottom',
                        labels: {
                            fontColor: '#000000',
                            fontSize: 16
                        }
                    }}
                    data={myData}
                />
            )}
        </div>
    );
});
