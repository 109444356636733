import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../component/icon/CloseIcon';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import { getClient, getServiceURLHostname } from '../../../apollo';
import { compose } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { withRouter } from 'react-router';
import { getOfficeDocuments } from '../funeralConstants';
import Typography from '@material-ui/core/Typography';
import { getBrandWithBranches, getOfficeFromKey } from '../../../util/brands';
import Fab from '@material-ui/core/Fab';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { DownloadIcon } from '../../../component/IconIndex';
import { IconButton } from '@material-ui/core';
import BiggerMessageBar from '../../../component/BiggerMessageBar';

class DocumentsPopover extends Component {
    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    state = {
        open: false,
        officeID: null,
        documents: [],
        funeralID: null
    };

    componentDidMount() {
        this.loadOfficeDocuments(this.props.match.params.key);
    }

    componentDidUpdate() {
        if (this.state.open !== this.props.open) {
            const key = this.props.match.params.key;
            const office = getOfficeFromKey(key);
            const officeID = office && office.ID;
            this.setState({
                open: this.props.open,
                funeralID: this.props.match.params.id,
                officeID
            });
        }
    }

    loadOfficeDocuments() {
        const that = this;
        const key = this.props.match.params.key;
        if (key !== null) {
            getClient()
                .query({
                    query: getOfficeDocuments
                })
                .then(
                    ({ data }) => {
                        if (!data || !data.readDocumentTemplates) {
                            that.onError('Failed to get merge template data or data empty.');
                            return;
                        }

                        if (data.readDocumentTemplates.length > 0) {
                            that.setState({
                                documents: data.readDocumentTemplates.filter(
                                    e => !!e.Template && !!e.Template.AbsoluteLink
                                )
                            });
                        }
                    },
                    error => that.onError('Failed to get merge template data.', error)
                );
        }
    }

    render() {
        const { classes } = this.props;
        const { open, funeralID, officeID, documents } = this.state;
        const allBranches = getBrandWithBranches(officeID);
        const templateDocs =
            (officeID &&
                documents.filter(e => e.ID && allBranches.find(obj => parseInt(e.Office.ID) === parseInt(obj.ID)))) ||
            [];
        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                aria-labelledby="Documents"
                aria-describedby="Documents"
                open={open}
                onClose={() => this.onClose()}
                className={classes.root}
            >
                <div className={classes.buttonClose}>
                    <Fab onClick={() => this.onClose()}>
                        <CloseIcon />
                    </Fab>
                </div>
                <div className={classes.paper}>
                    <Typography variant="headline" id="modal-title" className={classes.popoverTitle}>
                        Generate Merge Documents
                    </Typography>
                    <Typography className={classes.popoverTitle}>
                        All documents will be in MSWord (.docx) format.
                    </Typography>
                    <div className={classes.paperScroll}>
                        {templateDocs.length > 0 ? (
                            <Table>
                                <TableHeaderRow>
                                    <TableHeaderCell>Document Name</TableHeaderCell>
                                    <TableHeaderCell>Download</TableHeaderCell>
                                </TableHeaderRow>
                                {templateDocs
                                    .sort((a, b) => (a.Title < b.Title ? -1 : 1))
                                    .map((obj, i) => (
                                        <TableRow key={i} pad={true}>
                                            <TableCell>
                                                <strong>{obj.Title}</strong>
                                                <br />
                                                <small>
                                                    {obj.Template.Name} ({obj.Template.Size})
                                                </small>
                                                <iframe
                                                    title={'downloader' + obj.ID}
                                                    name={'downloader' + obj.ID}
                                                    className={classes.iframe}
                                                />
                                            </TableCell>
                                            <TableCell pad={false} tiny centred>
                                                <IconButton
                                                    href={
                                                        '//' +
                                                        getServiceURLHostname() +
                                                        '/merge-doc-funeral/download/' +
                                                        obj.ID +
                                                        '/' +
                                                        funeralID +
                                                        '/' +
                                                        this.state.officeID
                                                    }
                                                    target={'downloader' + obj.ID}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </Table>
                        ) : (
                            <BiggerMessageBar messageType={'warning'}>
                                {officeID ? (
                                    <>
                                        No documents were found.
                                        <br />
                                        <small>Check for Document Templates with files for this office.</small>
                                    </>
                                ) : (
                                    <>
                                        Office not recognised.
                                        <br />
                                        <small>Check the Funeral Key matches an office.</small>
                                    </>
                                )}
                            </BiggerMessageBar>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.clear();
    }

    clear() {
        this.setState({ open: false });
    }

    onError(msg, obj) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        console.error(errorMessage);
        this.props.setSnackbarMessage(msg);
    }
}

const styles = ({ spacing, typography, palette, transitions }) => ({
    root: {
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            overflowX: 'visible',
            borderRadius: '10px',
            maxWidth: 'unset',
            '& > div': {
                overflowY: 'unset',
                flexDirection: 'unset'
            }
        }
    },
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
    },
    popoverTitle: {
        margin: '0 6px'
    },
    paper: {
        maxWidth: 'calc(100vw - 64px)',
        width: 660,
        maxHeight: 'calc(100vh - 32px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: 25
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        marginTop: 24,
        padding: '12px 0'
    },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    iframe: {
        position: 'absolute',
        background: 'transparent',
        border: 0,
        marginTop: -8,
        pointerEvents: 'none'
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(DocumentsPopover);
