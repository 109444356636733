import gql from 'graphql-tag';

export default gql`
    fragment MemorialPhoto on Funeral {
        MemorialPhoto {
            ... on File {
                ID
                AbsoluteLink
                Name
            }
            ... on Image {
                ID
                AbsoluteLink
                Name
            }
        }
    }
`;
