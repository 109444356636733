import { getClient } from '../apollo';
import gql from 'graphql-tag';
import { getOffices, getSiteConfig } from '../environment';

export function getAllOffices() {
    const { CMBrandID } = getSiteConfig();
    return getOffices()
        .filter(obj => obj.ID !== CMBrandID)
        .map(getOfficeSummary);
}

export function getBrandsOnly() {
    const { CMBrandID } = getSiteConfig();
    return getOffices()
        .filter(obj => obj.ID !== CMBrandID && !obj.BrandID)
        .map(getOfficeSummary);
}

export function getBrandWithBranches(officeID) {
    const { CMBrandID } = getSiteConfig();
    return getOffices()
        .filter(
            obj =>
                obj.ID !== CMBrandID &&
                (obj.BrandID === officeID ||
                    obj.ID === officeID ||
                    (!!obj.Branches && obj.Branches.nodes.find(node => node.ID === officeID)))
        )
        .map(getOfficeSummary);
}

export function getOfficeSummary(office) {
    return {
        ID: office.ID,
        LegacyKey: office.LegacyKey,
        color: office.OfficeColour || office.ForegroundColour,
        label: office.Title,
        letterCode: office.RegistrantSuffix,
        logo: office.Logo,
        path: '/search/' + office.RegistrantSuffix,
        style: 'tablestyle' + office.ID
    };
}

function fakeOffice(letter) {
    return {
        ID: '0',
        LegacyKey: letter,
        color: '#35327c',
        label: 'unsure',
        letterCode: 'none',
        logo: '/fm-logo.png/',
        path: '/search/' + letter,
        style: 'tablestyle0'
    };
}

/**
 * Find the matching office (either brand or branch).
 * @param key The funeral or enquiry LegacyKey.
 * @param strict If strict, force a result even if no key was provided.
 * @returns {{path: string, color: string, letterCode: string, logo: string, style: string, ID: string, label: string, LegacyKey: string}|null}
 */
export function getOfficeFromKey(key = null, strict = false) {
    if (!key) {
        if (!strict) {
            key = '0P';
        } else {
            return null;
        }
    }
    const letter = key.replace(/\d+/g, '').toUpperCase();
    const office = getOffices().find(h => h.RegistrantSuffix === letter);
    return office ? getOfficeSummary(office) : getBrandFromKey(key);
}

export function getBrandFromKey(key = null, strict = false) {
    if (!key) {
        if (!strict) {
            key = '0P';
        } else {
            return null;
        }
    }
    const letter = key
        .replace(/\d+/g, '')
        .replace(/.*?(.)$/, '$1')
        .toUpperCase();
    const office = getOffices().find(h => h.RegistrantSuffix === letter);
    return office ? getOfficeSummary(office) : fakeOffice(letter);
}

export function getOfficeFromURLSegment(urlSegment) {
    const path = '/search/' + urlSegment;
    const funeralHomes = getAllOffices();
    return funeralHomes.find(h => h.path === path) || fakeOffice(urlSegment);
}

/**
 * Retrieve the funeral's office ID from preloaded data.
 * @param funeralID the ID of the funeral
 */
export const getFuneralOfficeID = funeralID => {
    let result = null;
    try {
        const data =
            funeralID &&
            getClient().readQuery({
                variables: { id: funeralID },
                query: gql`
                    query readOneFuneral($id: ID!) {
                        readOneFuneral(ID: $id) {
                            ID
                            OfficeID
                        }
                    }
                `
            });
        if (data && data.readOneFuneral) {
            result = { ...data };
        }
    } catch (e) {
        result = null;
    }
    return (!!result && result.readOneFuneral && result.readOneFuneral.OfficeID) || null;
};
