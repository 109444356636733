import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import Hidden from '@material-ui/core/Hidden';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Table, { Cell, Row } from '../../page/workQueue2/extras/Table';
import Inline, { inlineAlignment } from '../../page/workQueue2/extras/Inline';
import Button from '../../page/workQueue2/extras/Button';
import Grid from '../form/Grid';
import { countTo, getProperty, isNullOrUndefined } from '../../util/objects';
import { createTableQuery } from './DataTableConstants';
import AlertBar from '../form/AlertBar';
import { stringIsNullOrEmpty } from '../../util/strings';
import BackArrow from '../icon/BackArrow';
import NextArrow from '../icon/NextArrow';
import SearchWithButton from '../../page/workQueue2/extras/SearchWithButton';
import Spinner from '../Spinner';
import Checkbox from '../form/Checkbox';
import { indexOf } from '../../util/arrays';
import FilterIcon from '../icon/FilterIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getClient } from '../../apollo';
import StaffAutoComplete from '../form/StaffAutoComplete';
import { OutlineButton } from '../form/PrimaryButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BrandedTableKey from '../BrandedTableKey';
import Typography from '@material-ui/core/Typography';
import { getOfficeFromKey } from '../../util/brands';
import DataTableFilter from './DataTableFilter';

class DataTable extends Component {
    state = {
        variables: null,
        showFilterPop: false,
        filterBy: null,
        isDragOverRow: {}
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { variables } = newProps;
        const { filterBy: oldFilterBy } = oldState;
        //need to convert variables filterBy into state filterBy for the filter modal
        if (variables && variables.filterBy && !oldFilterBy) {
            const filterBy = [];
            for (let index in variables.filterBy) {
                let f = variables.filterBy[index];
                if (!(f.field in filterBy)) {
                    filterBy[f.field] = [];
                }
                filterBy[f.field].push(f.value);
            }

            return {
                variables: {
                    ...variables,
                    offset: (variables && variables.offset) || 0,
                    limit: (variables && variables.limit) || 10,
                    contains: (variables && variables.contains) || '',
                    filterBy: (variables && variables.filterBy) || '',
                    sortBy: (variables && variables.sortBy) || []
                },
                filterBy
            };
        }
        return null;
    }

    render() {
        const { customClient, queryName, fragment, dataObject, isFilterable, isSortable } = this.props;
        const client = customClient || getClient;
        const { variables, reloadData } = this.state;
        const query = createTableQuery(queryName, fragment, dataObject, isFilterable, isSortable);
        let fetchPolicy = reloadData ? 'network-only' : 'cache-and-network';

        return (
            <div className="data-table">
                <Query query={query} variables={variables} fetchPolicy={fetchPolicy} client={client()}>
                    {({ data, loading, error }) => {
                        if (error) return this.renderError('an error occurred executing the query', error);

                        if (!loading && isNullOrUndefined(data)) return this.renderError('missing data', data);

                        if (!loading && isNullOrUndefined(data[queryName])) {
                            return this.renderError('object type was not returned with data', data);
                        }

                        if (loading && isNullOrUndefined(data[queryName])) return this.renderLoading();

                        if (isNullOrUndefined(data[queryName].edges))
                            return this.renderError('query requires pagination to be enabled');

                        const sortDirection = getProperty(data, 'SortDirection.inputFields');
                        const filterOptions = getProperty(data, 'FilterOptions.inputFields');
                        const customFilters = getProperty(data, 'CustomFilters.inputFields');
                        return this.renderTable(data[queryName], loading, {
                            sortDirection,
                            filterOptions,
                            customFilters
                        });
                    }}
                </Query>
            </div>
        );
    }

    renderError(message, obj) {
        // eslint-disable-next-line no-console
        console.error(message, obj);
        return <AlertBar variant="error">{message}</AlertBar>;
    }

    renderLoading() {
        return <LinearProgress />;
    }

    renderTable(data, loading, fieldsInfo) {
        const {
            columns,
            searchVariable,
            tableTitle,
            isFilterable,
            aboveTable,
            extraData,
            onHeaderClick,
            classes,
            headerMessage = {}
        } = this.props;
        const { variables } = this.state;
        const sortBy = variables.sortBy || [];
        const { sortDirection, customFilters } = fieldsInfo;
        const availableFilters =
            customFilters && customFilters.length && isFilterable && Array.isArray(isFilterable)
                ? customFilters.filter(obj => isFilterable.includes(obj.name))
                : customFilters;

        const processedColumns = [];
        //Added support for header labels that are not predefined but rendered based on table content
        columns.forEach((element, index) => {
            processedColumns[index] = { ...element };

            processedColumns[index].label = !!element.renderLabel
                ? element.renderLabel(data.edges, classes, onHeaderClick, extraData)
                : element.label;

            if (headerMessage.show && headerMessage.markedColumns && headerMessage.markedColumns.indexOf(index) > -1) {
                processedColumns[index].headerClassName = 'arrow';
            }
        });

        return (
            <Fragment>
                {(searchVariable || tableTitle || availableFilters.length) && this.renderHeader(data, availableFilters)}
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        {aboveTable}
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ position: 'relative' }}>
                            {!!headerMessage.show && <div className="action-container">{headerMessage.content}</div>}
                            <Table
                                columns={processedColumns}
                                sortableHeaderCell={column =>
                                    this.renderSortableHeaderCell(
                                        column,
                                        sortDirection,
                                        sortBy,
                                        headerMessage.show ? headerMessage.markedColumns : []
                                    )
                                }
                            >
                                {data.edges.map(({ node }, rowIndex) =>
                                    this.renderRow(node, processedColumns, rowIndex)
                                )}
                            </Table>
                            {loading && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        background: '#FFFFFF99'
                                    }}
                                >
                                    <Spinner />
                                </div>
                            )}
                        </div>
                    </Grid>
                    {!data.edges.length && (
                        <Grid item>
                            <p>
                                There are currently no results
                                {searchVariable &&
                                    variables[searchVariable] &&
                                    ' for your query. Try other words or check the spelling'}
                                .
                            </p>
                        </Grid>
                    )}
                    <Grid item>{this.renderPagination(data)}</Grid>
                </Grid>
            </Fragment>
        );
    }

    renderHeader(data, filterByInfo) {
        const { tableTitle, brandProperty } = this.props;
        const { totalCount } = data.pageInfo;
        const { variables } = this.state;
        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <Typography variant="title">
                            {tableTitle ? tableTitle + ' - ' : ''} {totalCount} Item{totalCount === 1 ? '' : 's'}
                        </Typography>
                        <SearchWithButton
                            placeholder="Search ..."
                            searchKeyword={variables.contains}
                            onSearchSubmit={s => this.setSearch(s)}
                        />
                        {filterByInfo && this.renderFilterBy(filterByInfo)}
                    </Inline>
                    <Grid item xs={12} style={{ marginTop: '1em' }}>
                        {brandProperty && this.renderTableBrandedKey()}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderSortableHeaderCell(column, sortInfo, sortBy) {
        if (!sortInfo) return null;
        if (!column.propertyPath) return null;
        const { classes } = this.props;
        const isSortable = indexOf(sortInfo, x => {
            if ('SortDirection' === x.type.name) return x.name === column.propertyPath;
            const bits = column.propertyPath.split('.');
            return (
                bits.length > 1 &&
                x.name === bits[0] &&
                x.type.inputFields &&
                x.type.inputFields.find(obj => obj.name === bits[1])
            );
        });
        if (isSortable === -1) {
            return column.label;
        }

        const currentSort = sortBy && sortBy.length ? sortBy[0] : {};

        const currentDirection =
            currentSort.direction && currentSort.direction.length ? currentSort.direction.toLowerCase() : undefined;

        return (
            <TableSortLabel
                active={currentSort.field === column.propertyPath}
                direction={currentDirection}
                classes={{
                    root: classes.headerCellSort,
                    active: classes.headerCellSortActive
                }}
                onClick={() => {
                    this.setSortBy(column);
                }}
            >
                {column.label}
            </TableSortLabel>
        );
    }

    renderFilterBy(filterByInfo) {
        const {
            showFilterPop,
            variables: { filterBy }
        } = this.state;
        const filterByKeys = filterByInfo && filterByInfo.map(obj => obj.name);
        const filtersOn =
            !!filterByKeys &&
            !!filterByKeys.find(key => !!filterBy.find(by => by.field === key && !!Object.values(by.value).length));
        return (
            <Fragment>
                <Button
                    className="data-table--filter-button"
                    variant={`${!!filtersOn ? 'primary' : 'secondary'}`}
                    onClick={event =>
                        this.setState({
                            showFilterPop: event.currentTarget
                        })
                    }
                >
                    <FilterIcon />
                    <Hidden smDown>Filters</Hidden>
                    {!!filtersOn && <span>&nbsp;ON</span>}...
                </Button>

                {showFilterPop && (
                    <DataTableFilter
                        showFilterPop={showFilterPop}
                        onClose={() => this.setState({ showFilterPop: false })}
                        filterByInfo={filterByInfo}
                        filterByKeys={filterByKeys}
                        setFilters={this.setFilterBys}
                        activeFilters={filterBy}
                    />
                )}
            </Fragment>
        );
    }

    renderFilterSection(key, node) {
        const label = node.Label;
        const options = node.Options;
        const formType = node.FormType;
        if (!Array.isArray(options)) {
            return null;
        }

        if (formType === 'Autocomplete') {
            return (
                <Fragment key={key}>
                    <Grid item xs={12}>
                        <h3>{label}</h3>
                    </Grid>
                    {this.renderAutocomplete(key, node, options)}
                </Fragment>
            );
        } else {
            return (
                <Fragment key={key}>
                    <Grid item xs={12}>
                        <h3>{label}</h3>
                    </Grid>

                    {options &&
                        options.map(filter => {
                            let cKey = filter.Key || filter;
                            let cValue = filter.Value || filter;
                            let isChecked = false;
                            const { filterBy } = this.state;
                            let filterSectionValues = filterBy[key];
                            if (filterSectionValues && Array.isArray(filterSectionValues)) {
                                isChecked = filterSectionValues.indexOf(cKey) !== -1;
                            }

                            return (
                                <Grid item xs={6} key={cKey}>
                                    <Checkbox
                                        label={cValue}
                                        value={cKey}
                                        onChange={this.handleCheckboxChange(key)}
                                        checked={isChecked || false}
                                    />
                                </Grid>
                            );
                        })}
                </Fragment>
            );
        }
    }

    renderAutocomplete(key, node, options) {
        const { filterBy } = this.state;
        const { classes } = this.props;
        let filterSectionValues = filterBy[key];

        return (
            <Grid container spacing={24}>
                <Grid pc={1}>
                    <StaffAutoComplete
                        onSelect={(picked, pepper) => {
                            this.handleStaffPick(key, { target: { value: pepper.ID } }, options);
                        }}
                    />

                    <div className={classes.buttonGroup}>
                        <OutlineButton onClick={this.handleBulkSelectChange(key, options)}>Select All</OutlineButton>
                        <OutlineButton onClick={this.handleBulkUnselectChange(key, options)}>
                            Unselect All
                        </OutlineButton>
                    </div>

                    <Grid container spacing={0}>
                        {filterSectionValues &&
                            filterSectionValues.length > 0 &&
                            filterSectionValues.map(checkbox => {
                                const mappedOption = options.find(e => checkbox === e.Key);
                                if (!!mappedOption) {
                                    let optVal = checkbox;
                                    return (
                                        <Grid key={optVal} item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        onChange={this.handleCheckboxChange(key)}
                                                        value={optVal}
                                                        color="primary"
                                                    />
                                                }
                                                label={mappedOption && mappedOption.Value}
                                            />
                                        </Grid>
                                    );
                                }
                                return null;
                            })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    handleBulkSelectChange = (key, options) => event => {
        const { filterBy } = this.state;

        if (!(key in filterBy)) {
            filterBy[key] = [];
        }

        options.forEach(opt => {
            if (filterBy[key].indexOf(opt.Key) === -1) {
                filterBy[key].push(opt.Key);
            }
        });

        this.setState({
            filterBy: filterBy
        });
    };

    handleBulkUnselectChange = (key, options) => event => {
        const { filterBy } = this.state;
        filterBy[key] = [];
        this.setState({
            filterBy: filterBy
        });
    };

    handleStaffPick(key, event, options) {
        const { value } = event.target;
        const mappedOption = options.find(e => value === e.Key);

        if (mappedOption) {
            const { filterBy } = this.state;

            if (!(key in filterBy)) {
                filterBy[key] = [];
            }
            filterBy[key].push(value);
            this.setState({
                filterBy: filterBy
            });
        }
    }

    renderTableBrandedKey() {
        return (
            <Fragment>
                <Inline center wrap>
                    <h5 className="key-heading">Legend:</h5>
                    <BrandedTableKey />
                </Inline>
            </Fragment>
        );
    }

    renderRow(row, columns, rowIndex) {
        const { onClickRow, brandProperty } = this.props;
        const property = getProperty(row, brandProperty) || brandProperty;
        const brand = brandProperty ? getOfficeFromKey(property) : '';

        return (
            <Row
                pad
                variant={brand && brand.style}
                key={row.ID}
                onClick={onClickRow ? () => onClickRow(row) : undefined}
                onDragOver={event => this.handleRowDragOver(event, row, rowIndex)}
                onDragLeave={event => this.handleRowDragLeave(event, rowIndex)}
                onDrop={event => this.handleRowDragDrop(event, row)}
            >
                {this.state.isDragOverRow[rowIndex] ? (
                    <Cell className={columns[0].className} colSpan={columns.length}>
                        Please drag your file here to upload
                    </Cell>
                ) : (
                    columns.map(column => this.renderCell(row, column, rowIndex))
                )}
            </Row>
        );
    }

    handleRowDragOver(event, row, index) {
        if (
            !!this.props.rowDragDrop &&
            event &&
            event.dataTransfer &&
            event.dataTransfer.types &&
            event.dataTransfer.types.includes('Files') &&
            !!this.props.allowDragDrop &&
            this.props.allowDragDrop(row)
        ) {
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
            this.setState({ isDragOverRow: { [index]: true } });
        }
    }

    handleRowDragLeave(event, index) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ isDragOverRow: { [index]: false } });
    }

    handleRowDragDrop(event, row) {
        event.stopPropagation();
        event.preventDefault();
        if (event.dataTransfer.files.length > 0) {
            let file = event.dataTransfer.files[0];
            this.props.rowDragDrop(file, row);
            this.setState({ isDragOverRow: {} });
        }
    }

    renderCell(row, column, rowIndex) {
        const { classes, onCellClick, extraData } = this.props;
        const value = !stringIsNullOrEmpty(column.propertyPath) ? getProperty(row, column.propertyPath) : null;

        return (
            <Cell key={column.label} dataLabel={column.label} className={column.className}>
                {!isNullOrUndefined(column.renderCell)
                    ? column.renderCell(row, value, rowIndex, classes, onCellClick, extraData)
                    : value}
            </Cell>
        );
    }

    renderPagination(data) {
        const { totalCount, hasNextPage, hasPreviousPage } = data.pageInfo;

        if (!(hasNextPage || hasPreviousPage)) {
            return null;
        }

        const {
            variables: { offset, limit }
        } = this.state;
        let counter = 1;

        const division = totalCount / limit;
        const floor = Math.floor(division);
        const numberOfPages = division === floor ? floor : floor + 1;
        const current = offset / limit + 1;

        return (
            <Fragment>
                <Inline className="pagination button-alignment">
                    {(hasPreviousPage && (
                        <Button onClick={() => this.previousOffset()} variant="pagination-primary">
                            <BackArrow className="icon" />
                        </Button>
                    )) || (
                        <div className="button-base pagination-primary pagination-disabled">
                            <BackArrow className="icon" />
                        </div>
                    )}
                    {(hasNextPage && (
                        <Button onClick={() => this.nextOffset()} variant="pagination-primary">
                            <NextArrow className="icon" />
                        </Button>
                    )) || (
                        <div className="button-base pagination-primary pagination-disabled">
                            <NextArrow className="icon" />
                        </div>
                    )}

                    {/*for first page*/}
                    <Fragment>
                        <Button
                            variant={current === 1 ? 'pagination-current' : 'pagination-primary'}
                            onClick={() => this.setOffset(0)}
                        >
                            1
                        </Button>

                        {numberOfPages > 9 && current > 5 && (
                            <div className="button-base pagination-primary pagination-disabled">...</div>
                        )}
                    </Fragment>

                    {countTo(numberOfPages - 2).map(page => {
                        // (numberOfPages - 2) to skip first and last
                        page += 2; // start from 2
                        const position = (page - 1) * limit;
                        if (
                            counter <= 7 &&
                            (page >= current - 3 || (current > numberOfPages - 3 && numberOfPages - page < 7))
                        ) {
                            counter++;
                            return (
                                <Button
                                    key={page}
                                    variant={page === current ? 'pagination-current' : 'pagination-primary'}
                                    onClick={() => this.setOffset(position)}
                                >
                                    {page}
                                </Button>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {/*for last page*/}
                    <Fragment>
                        {numberOfPages > 9 && current < numberOfPages - 4 && (
                            <div className="button-base pagination-primary pagination-disabled">...</div>
                        )}

                        <Button
                            variant={numberOfPages === current ? 'pagination-current' : 'pagination-primary'}
                            onClick={() => this.setOffset((numberOfPages - 1) * limit)}
                        >
                            {numberOfPages}
                        </Button>
                    </Fragment>
                </Inline>
            </Fragment>
        );
    }

    setSortBy = column => {
        const { variables } = this.state;
        let newVars = { ...variables };
        const currentSort = newVars.sortBy && newVars.sortBy.length ? newVars.sortBy[0] : {};
        let newSortBy = { direction: 'DESC' };
        let changeDirection = currentSort.field === column.propertyPath;

        if (changeDirection) {
            newSortBy.direction = currentSort.direction === 'DESC' ? 'ASC' : 'BYE';
        }
        if ('BYE' !== newSortBy.direction) {
            newSortBy.field = column.propertyPath;
            newVars.sortBy = [newSortBy];
        } else {
            newVars.sortBy = [];
        }

        newVars.offset = 0;

        this.setState({
            variables: newVars
        });
    };

    setFilterBys = filterBy => {
        const { variables } = this.state;
        const newVars = { ...variables };

        newVars.filterBy = filterBy.map(obj => ({ field: obj.name, value: obj.value }));
        newVars.offset = 0;

        this.setState({
            showFilterPop: false,
            variables: newVars
        });
    };

    setNoFilterBys = () => {
        const { variables } = this.state;
        const newVars = { ...variables };

        newVars.filterBy = [];
        newVars.offset = 0;

        this.setState({
            showFilterPop: false,
            variables: newVars,
            filterBy: []
        });
    };

    setSearch = searchTerm => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.contains = searchTerm;
        newVars.offset = 0;
        this.setState({
            variables: newVars
        });
    };

    setOffset = page => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = page;
        this.setState({
            variables: newVars
        });
    };

    nextOffset = () => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = variables.offset + variables.limit;
        this.setState({
            variables: newVars
        });
    };

    previousOffset = () => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = variables.offset - variables.limit;
        this.setState({
            variables: newVars
        });
    };
}

const styles = ({ typography, palette, funeralHome, spacing }) => ({
    headerCellSort: {
        textDecoration: 'underline',
        '&:hover,&:focus': { color: 'inherit' }
    },
    headerCellSortActive: {
        color: 'inherit',
        '&:hover,&:focus': { color: 'inherit' }
    }
});

export default compose(withStyles(styles))(DataTable);
