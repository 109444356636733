import gql from 'graphql-tag';
import { BookingsFragmentInclude } from './Bookings';

export default gql`
    fragment LimoBookings on Funeral {
        LimoBookings {
            ${BookingsFragmentInclude}
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementLimos on Prearrangement {
        Limos {
            ID
            Date
            Time
            Duration
            Address
        }
    }
`;
