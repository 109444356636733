import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import { joinDefined } from '../../../util/strings';
import { getOfficeFromKey } from '../../../util/brands';
import { Button } from '@material-ui/core';

class CoffinInvalidModal extends Component {
    render() {
        const { item, onClose, open } = this.props;
        if (item === null) return null;

        const { LegacyKey, Arrangers } = item;

        return (
            <Dialog open={open}>
                <DialogTitle>Coffin Details</DialogTitle>

                <DialogContent>
                    <Grid item xs={12}>
                        <p>{`The funeral record '${LegacyKey}' has no coffin selected. A coffin needs to be selected to continue`}</p>
                        <p>{getArrangersString(Arrangers, LegacyKey)}</p>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const getArrangersString = (arrangers, legacyKey) => {
    const arrangersString = joinDefined(
        (arrangers || []).map(({ Member }) => `${Member.FirstName} ${Member.Surname}`),
        ', '
    );

    const office = getOfficeFromKey(legacyKey).label;

    if (arrangersString.length > 0) {
        return `Please contact ${arrangersString} from ${office} for more information`;
    }

    return `No arrangers were assigned to this funeral. Please contact ${office} directly for more information.`;
};

const styles = ({ palette }) => ({
    formControl: {
        width: '100%'
    },

    select: {
        width: '100%'
    },

    icon: {
        color: 'white'
    },

    buttonCancel: {
        outline: palette.action.active,
        marginRight: 10
    },
    buttonCancelCopy: {
        lineHeight: '2em'
    },
    buttonOk: { color: '#26CC6F' },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    actionButtons: {
        marginTop: 20,
        textAlign: 'center'
    },
    saveButton: {
        color: 'white',
        borderRadius: 20,
        marginRight: 10,
        background: palette.button.save
    },
    classes: {}
});

export default withStyles(styles)(CoffinInvalidModal);
