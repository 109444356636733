import React from 'react';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import StarRating from '../../../component/metrics/StarRating';
import Stars from '../../../component/metrics/Stars';
import { metricColour } from '../../../component/metrics/metricConstatnts';
import { getMyClient } from '../../../apollo';
import Spinner from '../../../component/Spinner';
import { isNullOrUndefined } from '../../../util/objects';

export default withStyles({})(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period } = props;
    const { type } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myAverageRating', variables: JSON.stringify({ ...period, variant: 'individual' }) },
            { name: 'myAverageRating', variables: JSON.stringify({ ...period, variant: 'team' }) },
            { name: 'myAverageRating', variables: JSON.stringify({ ...period, variant: 'company' }) }
        ]
    };

    const query = gql`
        query ScoreRating($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const convertRating = val => {
        return (val && Number(val) / 10) || null;
    };

    const botStars = (val = 0) => {
        const round = Math.ceil(val / 2);
        const extra = val / 2 - round;
        return `${(val / 2 + extra) * 20}%`;
    };
    const topStars = (val = 0) => {
        return `${Math.ceil(val / 2) * 20}%`;
    };

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);
        const { loadMetrics } = (!error && data) || [
            { Score: null, Variant: 'individual' },
            { Score: null, Variant: 'team' },
            { Score: null, Variant: 'company' }
        ];

        return (
            (loadMetrics &&
                loadMetrics.map(metric => {
                    const { Score, Variant } = (metric.result && JSON.parse(metric.result)) || null;
                    const pillColour = metricColour(convertRating(Score));
                    return (
                        <Grid key={Variant} item xs={12} sm={4}>
                            <h3 className="metrics">{`${Variant} Average:`}</h3>
                            <StarRating
                                max={10}
                                data={reloading ? <Spinner /> : isNullOrUndefined(Score) ? 'N/A' : Score}
                                variant={pillColour}
                                loading={reloading}
                            >
                                <Stars percent={reloading ? 0 : topStars(Score)} />
                                <Stars percent={reloading ? 0 : botStars(Score)} />
                            </StarRating>
                        </Grid>
                    );
                })) ||
            null
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>Customer Ratings</h2>
            </Grid>

            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {result => renderResult(result)}
            </Query>
        </Grid>
    );
});
