import React from 'react';
import Grid from './form/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LinkButton from './form/LinkButton';
import Separator from './form/Separator';
import BiggerMessageBar from './BiggerMessageBar';

export default ({ error, componentStack, resetError }) => (
    <WrapErrorFallbackComponent error={error} componentStack={componentStack} resetError={resetError} />
);

const ErrorFallbackComponent = ({ classes, error, resetError }) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <Grid pc={1}>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    Whoops!
                </Typography>
                <Typography gutterBottom>There was an error. Probably nothing you did!</Typography>
                <Typography gutterBottom>
                    <LinkButton onClick={resetError} text={'Try again'} /> or try moving to another page.
                </Typography>
                <Separator margin={24} />
                <Typography gutterBottom>
                    <LinkButton onClick={() => setShow(!show)} text={!show ? 'Show' : 'Hide'} /> the error message.
                </Typography>
                {!!show && <BiggerMessageBar messageType={'error'}>{error.toString()}</BiggerMessageBar>}
            </Grid>
        </>
    );
};

const styles = ({ spacing, typography }) => ({
    pageTitle: {
        fontWeight: typography.fontWeightLight
    }
});

const WrapErrorFallbackComponent = withStyles(styles)(ErrorFallbackComponent);
