import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { prettyPrice } from '../../util/strings';
import { CONTACT_CLERGY_OPTIONS } from './contactConstants';
import UsdCircleIcon from '../../component/icon/UsdCircleIcon';

class ContactCharges extends React.Component {
    state = {
        open: false,
        loadedData: null
    };
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { contactId, children } = this.props;
        return (
            <Fragment>
                <span onClick={this.handleClickOpen}>{children}</span>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <Query query={contactQuery} variables={{ id: contactId }} fetchPolicy={'network-only'}>
                        {results => this.renderContent(results)}
                    </Query>
                </Dialog>
            </Fragment>
        );
    }

    renderContent(results) {
        if (results.error) {
            console.error(results.error);
            return <div>Error: Can't read data!</div>;
        }

        const contactEdges = results.data.readAddressBooks && results.data.readAddressBooks.edges;

        //start to run Query, return loading message.
        if (!contactEdges || results.loading) {
            return <div>Loading...</div>;
        }

        //loaded, but no edges, return not found message.
        if (contactEdges.length <= 0) {
            return <div>Contact Not Found!</div>;
        }

        const contactData = contactEdges[0].node;

        const { classes } = this.props;

        const clg = CONTACT_CLERGY_OPTIONS.find(e => {
            return e.value === contactData.ClergyCategory;
        });

        return (
            <div style={{ width: 600, maxWidth: 'calc(100vw - 64px)', overflow: 'hidden' }}>
                <div style={{ position: 'relative' }}>
                    <UsdCircleIcon
                        style={{
                            opacity: 0.025,
                            width: 300,
                            height: 300,
                            position: 'absolute',
                            right: -24,
                            top: -24,
                            transform: 'rotate(20deg)',
                            zIndex: 0
                        }}
                    />
                </div>
                <DialogTitle className={classes.title}>CONTACT FEES</DialogTitle>
                <DialogContent>
                    <Typography variant={'headline'}>
                        {contactData.Name}
                        <span style={{ fontSize: '0.875rem', marginLeft: 12 }}>{contactData.Contact}</span>
                    </Typography>
                    <Typography variant={'title'}>
                        {clg && clg.label}{' '}
                        {contactData.Religion && clg && (Number(clg.value) === 9 || Number(clg.value) === 11)
                            ? ' - ' + contactData.Religion
                            : ''}
                    </Typography>
                    <div style={{ paddingBottom: 24, marginTop: 12, borderTop: '1px dotted #999' }} />
                    <Typography>
                        Base rate: <strong>{prettyPrice(contactData.ClergyBaseRate)}</strong>
                    </Typography>
                    <Typography>
                        Attending committal rate:{' '}
                        <strong>{prettyPrice(contactData.ClergyFollowToCommittalRate)}</strong>
                    </Typography>
                    <Typography>
                        Attending refreshments rate:{' '}
                        <strong>{prettyPrice(contactData.ClergyFollowAfterRefreshmentsRate)}</strong>
                    </Typography>
                    <Typography>
                        Fees include GST: <strong>{contactData.FeesInclGst ? 'Yes' : 'No'}</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonCancel} onClick={this.handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

const contactQuery = gql`
    query($id: String) {
        readAddressBooks(id: $id) {
            edges {
                node {
                    ID
                    ClassName
                    LastEdited
                    Created
                    Name
                    Contact
                    Religion
                    ClergyCategory
                    ClergyBaseRate
                    FeesInclGst
                    ClergyFollowToCommittalRate
                    ClergyFollowAfterRefreshmentsRate
                }
            }
        }
    }
`;

const styles = ({ theme }) => ({
    root: {
        width: '100%',
        marginTop: 10,
        overflowX: 'auto'
    },
    table: {
        minWidth: '80%'
    }
});

export default compose(withRouter, withStyles(styles))(ContactCharges);
