import DeceasedTab from './deceased/DeceasedTab';
import FamilyTab from './family/FamilyTab';
import CertificationTab from './certification/CertificationTab';
import DetailsTab from './details/DetailsTab';
import PresentationTab from './presentation/PresentationTab';
import OptionalsTab from './optionals/OptionalsTab';
import BdmsTab from './bdms/BdmsTab';
import SummaryTab from './summary/SummaryTab';
import BillingTab from './billing/BillingTab';
import gql from 'graphql-tag';
import DocumentTemplate from '../fragments/DocumentTemplate';

export const TAB = {
    deceased: DeceasedTab,
    family: FamilyTab,
    certification: CertificationTab,
    funeralDetails: DetailsTab,
    presentation: PresentationTab,
    optionals: OptionalsTab,
    bdms: BdmsTab,
    summary: SummaryTab,
    billing: BillingTab
};

export const TABS = Object.keys(TAB).map(key => {
    const tab = TAB[key];
    tab.key = tab.label
        .toLowerCase()
        .split(' ')
        .join('-');

    const spaceIndex = tab.label.indexOf(' ');
    tab.propertyKey = tab.key.replace('-', '');
    if (spaceIndex >= 0) {
        //this is a bit round-about. we need it to match TAB[propertyName]
        tab.propertyKey =
            tab.propertyKey.substr(0, spaceIndex) +
            tab.propertyKey[spaceIndex].toUpperCase() +
            tab.propertyKey.substr(spaceIndex + 1);
    }

    tab.path = `/funeral/:key/:id/${tab.key}`;
    if (!tab.title) tab.title = tab.label;
    return tab;
});

export const FUNERAL_TAB_SEGMENTS = TABS.map(e => e.key);

// Builds a lookup of funeral field names to the keys of the tabs that query/mutate those fields
export const FIELD_TAB_LOOKUP = TABS.reduce((lookup, tab) => {
    tab.fragment.definitions.forEach(d =>
        d.selectionSet.selections.forEach(selection => {
            const fieldName = selection.name.value;
            if (fieldName === 'ID' || fieldName === 'LegacyKey') return;
            if (!lookup[fieldName]) lookup[fieldName] = [];
            if (!lookup[fieldName].includes(tab.propertyKey)) lookup[fieldName].push(tab.propertyKey);
        })
    );
    return lookup;
}, {});

export const ADDRESS_BOOK_CATEGORY = {
    hospitalNursingHome: {
        key: 'HOSPITAL/NURSING HOME',
        label: 'Hospital / Nursing home'
    },
    clergy: {
        key: 'CLERGY',
        label: 'Clergy'
    },
    placeOfService: {
        key: 'PLACE OF SERVICE',
        label: 'Place of service'
    },
    florist: {
        key: 'FLORIST',
        label: 'Florist'
    },
    catering: {
        key: 'CATERING',
        label: 'Catering'
    },
    charity: {
        key: 'CHARITY',
        label: 'Charity'
    },
    placeOfCommittal: {
        key: 'PLACE OF COMMITTAL',
        label: 'Place of committal'
    },
    doctor: {
        key: 'DOCTOR',
        label: 'Doctor'
    },
    supportServices: {
        key: 'SUPPORT SERVICES',
        label: 'Support services'
    },
    miscellaneous: {
        key: 'MISCELLANEOUS',
        label: 'Miscellaneous'
    },
    funeralFund: {
        key: 'FUNERAL FUND',
        label: 'Funeral fund'
    },
    billing: {
        key: 'BILLING',
        label: 'Billing'
    },
    unknown: {
        key: 'NULL',
        label: 'Unknown'
    }
};

export const getOfficeDocuments = gql`
    ${DocumentTemplate}
    query getFuneralMergeDocs {
        readDocumentTemplates {
            ...DocumentTemplate
            ...CremationDocumentTemplate
        }
    }
`;

export const ADDRESS_BOOK_CATEGORIES = Object.values(ADDRESS_BOOK_CATEGORY);

export const PO_SUPPLIER_LIST = [
    { key: 'RslMasonic', label: 'RSL/Masonic Representatives', link: 'presentation' },
    { key: 'ReflectionMusicians', label: 'Reflection Musicians', link: 'presentation' },
    { key: 'GravesideMusicians', label: 'Graveside Musicians', link: 'presentation' },
    { key: 'DefaultMusicians', label: 'Default Musicians', link: 'presentation' },
    { key: 'EnterMusicians', label: 'Enter Musicians', link: 'presentation' },
    { key: 'ExitMusicians', label: 'Exit Musicians', link: 'presentation' },
    { key: 'Disposal.Bookings', label: 'Place Of Committal', link: 'funeral-details' },
    { key: 'Clergy', label: 'Celebrant', link: 'funeral-details' },
    { key: 'LimoBookings', label: 'Hired Car or Limo', link: 'funeral-details' },
    { key: 'GraveLedger', label: 'Grave Ledger', link: 'funeral-details' },
    { key: 'Florists', label: 'Florists', link: 'optionals' },
    { key: 'PersonalisedTouches', label: 'Personalised Touches', link: 'optionals' },
    { key: 'MemorialStationary', label: 'Memorial Stationery', link: 'optionals' },
    { key: 'NewspaperNotices', label: 'Newspapers', link: 'optionals' },
    { key: 'Caterers', label: 'Refreshments', link: 'optionals' }
];

export const PO_DOCTOR_LIST = [
    { label: 'Referee Doctor', key: 'Certification.RefereePurchaseOrder', link: 'certification' },
    { label: 'Hospital', key: 'Certification.HospitalPurchaseOrder', link: 'certification' },
    { label: 'Doctor', key: 'Certification.DoctorPurchaseOrder', link: 'certification' }
];
