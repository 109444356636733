import React, { Component, Fragment } from 'react';
import { compose, Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import Typography from '@material-ui/core/Typography/Typography';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Paper from '@material-ui/core/Paper/Paper';
import cx from 'classnames';
import { applyUrlParams, joinDefined } from '../../util/strings';
import gql from 'graphql-tag';
import DataFormView from '../../component/form/v2/DataFormView';
import { withStyles } from '@material-ui/core';
import { OutlineButton } from '../../component/form/PrimaryButton';
import './css/documentation.scss';
import {
    CreateArrangementMutation,
    DisposalQuery,
    GetDocumentForLocation,
    TabTemplates
} from './DocumentationConstants';
import { getRequiredDocuments } from '../funeral/certification/CertificationConstants';
import { createForm } from '../../util/form';
import ProgressModal from './modals/ProgressModal';
import BackIcon from '../../component/icon/BackIcon';
import { InlineFieldRightAlignChildren } from '../../component/form/Inline';
import RecordDrawer from '../workQueue2/RecordDrawer';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class Documentation extends Component {
    state = {
        selectedIndex: 0,
        showProgressModal: false,
        disposalInfo: false,
        failedToFetch: false
    };

    validList = { validForms: {} };

    componentDidMount() {
        this.updateRoute(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateRoute(nextProps);
    }

    getTabPath(tab, props) {
        return applyUrlParams(tab.path, props.match.params);
    }

    updateRoute(props) {
        if (Tabs.length > 0) {
            const { history, location, onChange } = props;
            let selectedIndex = Tabs.findIndex(tab => location.pathname.startsWith(this.getTabPath(tab, props)));
            if (selectedIndex === -1) {
                // Redirect to the first tab path
                history.replace(this.getTabPath(Tabs[0], props));
                selectedIndex = 0;
            }
            if (selectedIndex !== this.state.selectedIndex) {
                this.setState({ selectedIndex });
                if (onChange) onChange(Tabs[selectedIndex].label);
            }
        }
    }

    onChange = (e, selectedIndex) => {
        const { history } = this.props;
        history.push(this.getTabPath(Tabs[selectedIndex], this.props));
    };

    render() {
        const { showProgressModal } = this.state;
        const { classes, match } = this.props;
        const { key, id } = match.params;
        return (
            <div className="documentation-page">
                <ProgressModal
                    open={!!showProgressModal}
                    anchorEl={showProgressModal}
                    onClose={() => this.setState({ showProgressModal: false })}
                />

                <InlineFieldRightAlignChildren lineHeight={'inherit'}>
                    {((id || key) && (
                        <Query query={nameQuery} variables={{ id, key }}>
                            {this.renderName}
                        </Query>
                    )) || (
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            Documentation
                            <div className={classes.keyline} />
                            Unknown?
                        </Typography>
                    )}

                    <OutlineButton href={applyUrlParams('/funeral/:key/:id/certification', match.params)}>
                        <BackIcon />
                        Back to record
                    </OutlineButton>

                    {/*<AltButton onClick={event => this.setState({ showProgressModal: event.currentTarget })}>*/}
                    {/*    <ClipboardIcon className={classes.adminIcon} />*/}
                    {/*    <Hidden smDown>Arrangement Progress</Hidden>*/}
                    {/*</AltButton>*/}
                    <RecordDrawer funeralId={id} legacyKey={key} />
                </InlineFieldRightAlignChildren>
                <br />

                <Query query={DisposalQuery} variables={{ id }}>
                    {this.renderBodyOrCreateArrangement}
                </Query>
            </div>
        );
    }

    renderName = ({ loading, error, data }) => {
        const { classes } = this.props;
        const { FirstName, MiddleName, Surname, LegacyKey, Cancelled } = (data && data.readOneFuneral) || {};
        return (
            <Fragment>
                {Cancelled && <BiggerMessageBar messageType="error">This record is cancelled.</BiggerMessageBar>}
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    Documentation
                    <div className={classes.keyline} />
                    {LegacyKey}
                    <span className={cx(classes.nameTitle, !loading && classes.nameTitleActive)}>
                        {joinDefined([FirstName, MiddleName, Surname], ' ')}
                    </span>
                </Typography>
            </Fragment>
        );
    };

    renderBodyOrCreateArrangement = ({ loading, error, data }) => {
        const { classes } = this.props;
        const { id } = this.props.match.params;

        if (!!loading)
            return (
                <span className={classes.loading}>
                    <CircularProgress />
                </span>
            );
        if (!!error || this.state.failedToFetch) {
            this.state.failedToFetch = true;
            return 'Error';
        }

        const arrangementID =
            data && data.readOneFuneral && data.readOneFuneral.Arrangement && data.readOneFuneral.Arrangement.ID
                ? data.readOneFuneral.Arrangement.ID
                : 0;

        if (arrangementID > 0) {
            //arrangement already exist, so just rendering body
            return this.renderBody({ loading, error, data });
        } else {
            //arrangement not created yet, lets create new one before showing the body
            const mutationVars = {
                input: {
                    ID: id,
                    Arrangement: {}
                }
            };
            //saving query result for future use in renderBody if it was not saved already
            if (!this.state.disposalInfo) {
                this.setState({ disposalInfo: data });
            }
            return (
                <Mutation mutation={CreateArrangementMutation} variables={mutationVars}>
                    {this.renderBodyMutationWrapper}
                </Mutation>
            );
        }
    };

    renderBodyMutationWrapper = (mutation, { loading, error, data }) => {
        if (this.state.disposalInfo) {
            if (loading || error) {
                return this.renderBody({ loading, error, data });
            }
            if (data === undefined) {
                //we are not loading and no data present, then mutation was not triggered yet, lets trigger it
                mutation();
                return this.renderBody({ loading: true, error, data });
            } else {
                //mutation is done, lets update our cached data with new ArrangementID we got from mutation
                this.state.disposalInfo.readOneFuneral.Arrangement.ID = data.updateFuneral.Arrangement.ID;
                data = this.state.disposalInfo;
                return this.renderBody({ loading, error, data });
            }
        } else {
            //disposalInfo should be set already. Unexpected error occured.
            return this.renderBody({ error: true });
        }
    };

    renderBody = ({ loading, error, data }) => {
        const { classes } = this.props;
        const disposalInfo = (data && data.readOneFuneral) || {};

        if (!!loading)
            return (
                <span className={classes.loading}>
                    <CircularProgress />
                </span>
            );
        if (!!error) return 'Error';

        if (Tabs.length === 0) {
            //Converting query result to form
            let disposalForm = createForm({}, disposalInfo);
            const requiredDocuments = getRequiredDocuments(disposalForm);

            for (let i = 0; i < requiredDocuments.length; i++) {
                const document = GetDocumentForLocation(requiredDocuments[i].fieldName, disposalForm);
                delete document.FormID;

                if (document !== false) {
                    Tabs.push(document);
                    if (document.connectedForms && document.connectedForms.length > 0) {
                        document.connectedForms.forEach(connectedFormName => {
                            switch (connectedFormName) {
                                case 'LetterOfAuthority':
                                    Tabs.push({
                                        ...TabTemplates.LetterOfAuthority,
                                        FormID: 'ArrangementFormLetterOfAuthority'
                                    });
                                    break;
                                default:
                                    let connectedDocument = GetDocumentForLocation(connectedFormName, disposalForm);
                                    if (connectedDocument !== false) {
                                        Tabs.push(connectedDocument);
                                    }
                                    break;
                            }
                        });
                    }
                }
            }
            Tabs.push({ ...TabTemplates.Summary }); //We always have summary section
            this.updateRoute(this.props);
        }

        const tabProps = Tabs[this.state.selectedIndex].tabProps || {};
        tabProps.validList = this.validList;

        return (
            <Paper elevation={0} className={classes.paper}>
                <DataFormView
                    tabs={Tabs}
                    createNew={false}
                    createNewFunc={null}
                    context={this}
                    objectType="Funeral"
                    itemId={this.props.match.params.id}
                    name="Documentation"
                    className={classes.page}
                    onPageChange={this.onChange}
                    selectedIndex={this.state.selectedIndex}
                    queryName={Tabs[this.state.selectedIndex].queryName || 'TabQuery'}
                    readOnly={true}
                    tabProps={tabProps}
                />
            </Paper>
        );
    };
}

const Tabs = [];

const nameQuery = gql`
    query NameQuery($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            LegacyKey
            FirstName
            Surname
            MiddleName
            Cancelled
        }
    }
`;

const styles = ({ typography, palette, transitions }) => ({
    page: {
        // display: 'flex',
        // height: '100%',
        // flexDirection: 'column'
    },
    pageTitleContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    pageTitle: {
        color: palette.action.active,
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1',
        flexWrap: 'wrap'
    },
    keyline: {
        width: 1,
        height: 30,
        display: 'inline-block',
        margin: '0 16px',
        opacity: 0.5,
        backgroundColor: palette.action.active
    },
    nameTitle: {
        display: 'inline-block',
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        opacity: 0,
        color: palette.text.primary,
        transition: transitions.create(['opacity'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    nameTitleActive: {
        opacity: 1
    },
    paper: {
        borderRadius: 4,
        paddingTop: 12,
        '& section ': {
            borderBottom: ' 1px solid ' + palette.primary.main
        },
        '& h2': {
            color: palette.primary.main
        }
    },
    form: {
        flexGrow: 1,
        '& form > :last-child': {
            position: 'fixed',
            zIndex: 1202,
            bottom: 22,
            right: 24
        }
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(Documentation), {
    fallback: ErrorFallback
});
