import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 11.34 11.29" {...props}>
        <path
            fill="currentColor"
            d="M5.86,11.2,6,11a.31.31,0,0,0,0-.43H6L1.52,6.07H11a.29.29,0,0,0,.3-.3V5.52a.29.29,0,0,0-.3-.3H1.52L6,.7A.31.31,0,0,0,6,.27H6L5.85.09a.3.3,0,0,0-.42,0h0L.09,5.43a.3.3,0,0,0,0,.42h0L5.43,11.2a.3.3,0,0,0,.42,0Z"
            transform="translate(0 0)"
        />
    </SvgIcon>
);
