import React, { Component, Fragment } from 'react';
import Grid from '../../../component/form/Grid';
import { withStyles } from '@material-ui/core';
import { AltButton } from '../../../component/form/PrimaryButton';
import ReactToPrint from 'react-to-print';
import Table, { TableCell, TableRow } from '../../../component/form/Table';
import { dateTimeToString } from '../../../util/date';
import { PreloadProducts } from '../../../util/products';
import {
    CheckListTable,
    CoffinDetailsTable,
    DeceasedDetailsTable,
    formStyles,
    FuneralDetailsTable,
    getContact,
    GraveTable,
    qualityWorkCheckList
} from './FormFields';
import PrintIcon from '../../../component/icon/PrintIcon';
import { getDressingStatus } from '../MortuaryItemConstants';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { isNullOrUndefined } from '../../../util/objects';

class IdentificationForm extends Component {
    state = {
        doctor: null,
        loading: true
    };

    componentDidMount() {
        const { form } = this.props;

        getContact(form, 'Certification.Referee.ID', 'No Referee')
            .then(contact => this.setState({ doctor: contact.Name }))
            .then(() => PreloadProducts(this, form.getField('Disposal.DisposalBookingItems')))
            .then(() => this.setState({ loading: false }));
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <ReactToPrint
                    pageStyle={'@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'}
                    trigger={() => (
                        <AltButton>
                            <PrintIcon />
                            Print ID Form
                        </AltButton>
                    )}
                    content={() => this.formRef}
                />
                <div className={classes.hidePrint}>{this.renderForm()}</div>
            </Fragment>
        );
    }

    renderForm() {
        const { classes, form } = this.props;
        if (!form) return null;

        const crematedOrBuried = form.getField('Disposal.CrematedOrBuried');
        const showReferee = crematedOrBuried === 'Cremated';

        const { loading } = this.state;

        return (
            <div ref={e => (this.formRef = e)} className={classes.root}>
                {(loading && <h1>Data is still loading, please close and try again in a moment...</h1>) || (
                    <Grid container spacing={16} className={classes.print}>
                        <Grid container item xs={12}>
                            <span className={classes.center}>ID Form - Printed {dateTimeToString(new Date())}</span>
                        </Grid>

                        <Grid item xs={12}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <span className={classes.titleBoxLeft}>&nbsp;</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={classes.titleBoxRight}>
                                            <span className={classes.title}>BRADMA</span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Grid>

                        <Grid item xs={12}>
                            <Table>
                                <DeceasedDetailsTable form={form} />
                                {!!showReferee && this.renderReferee()}
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                {this.renderClothing()}
                                {<CoffinDetailsTable form={form} />}
                                {(crematedOrBuried === 'Buried' || crematedOrBuried === 'Repatriated') && (
                                    <GraveTable form={form} />
                                )}
                                {this.renderMortuaryPreparationNotes()}
                            </Table>
                        </Grid>

                        <Grid item xs={12}>
                            <Table>
                                <FuneralDetailsTable form={form} />
                            </Table>
                        </Grid>

                        <Grid item xs={12}>
                            <Table>{this.renderQualityWorkInstruction()}</Table>
                        </Grid>

                        <Grid item xs={12}>
                            <Table>
                                <CheckListTable form={form} />
                            </Table>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }

    renderMortuaryPreparationNotes() {
        const { form, classes } = this.props;
        const coffinComment = form.getField('CoffinComment');
        //        if (isNullOrUndefined(coffinComment)) return null;

        const isCrypt =
            (form.getField('Disposal.CrematedOrBuried') === 'Buried' ||
                form.getField('Disposal.CrematedOrBuried') === 'Repatriated') &&
            (form.getField('Grave.GroundDescription') === 'Crypt' || form.getField('Grave.DepthOption') === 'Crypt');

        return (
            <Fragment>
                <TableRow>
                    <TableCell colSpan={6}>
                        <span className={classes.tableTitle}>Mortuary Preparation Notes</span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={6}>
                        <span className={classes.notesPre}>
                            {isNullOrUndefined(coffinComment) ? 'none' : coffinComment}
                        </span>
                    </TableCell>
                </TableRow>
                {!!isCrypt && (
                    <TableRow>
                        <TableCell colSpan={6}>Embalming is required for Crypt.</TableCell>
                    </TableRow>
                )}
            </Fragment>
        );
    }

    renderReferee() {
        const { doctor } = this.state;
        const { classes, form } = this.props;

        return (
            <Fragment>
                <TableRow>
                    <TableCell colSpan={6}>
                        <span className={classes.tableTitle}>Cremation Referee</span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell colSpan={5}>
                        {doctor} {(!!form.getField('RefereeCremationCheck') && ' (CONFIRMED)') || ' (Assigned only)'}
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    renderClothing() {
        const { classes, form } = this.props;
        const comment = form.getField('FuneralDressing.Comment');
        return (
            <Fragment>
                <TableRow>
                    <TableCell colSpan={6}>
                        <span className={classes.tableTitle}>Clothing</span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.leftCol}>Status</TableCell>
                    <TableCell colSpan={5}>{getDressingStatus(form)}</TableCell>
                </TableRow>
                {!stringIsNullOrEmpty(comment) && (
                    <TableRow>
                        <TableCell>Comment</TableCell>
                        <TableCell colSpan={5}>{comment}</TableCell>
                    </TableRow>
                )}
            </Fragment>
        );
    }

    renderQualityWorkInstruction() {
        const { classes } = this.props;

        return (
            <Fragment>
                <TableRow>
                    <TableCell colSpan={6}>
                        <span className={classes.tableTitle}>Quality Work Instruction</span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}>
                        <span className={classes.tableTitle}>Check List</span>
                    </TableCell>
                    <TableCell>
                        <span className={classes.tableTitle}>Check</span>
                    </TableCell>
                    <TableCell>
                        <span className={classes.tableTitle}>Signed</span>
                    </TableCell>
                </TableRow>
                {qualityWorkCheckList.map((x, i) => (
                    <TableRow key={i}>
                        <TableCell colSpan={4}>{x}</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                ))}
            </Fragment>
        );
    }
}

export default withStyles(formStyles)(IdentificationForm);
