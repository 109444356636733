export const VALUABLES_TO_BE_PLACE_OPTIONS = [
    { label: 'On Body', value: 'On Body' },
    { label: 'In Coffin', value: 'In Coffin' },
    { label: 'On Coffin', value: 'On Coffin' },
    { label: 'In Memoriam Book', value: 'In Memoriam Book' },
    { label: 'On Display in Chapel', value: 'On Display in Chapel' },
    { label: 'In Hands', value: 'In Hands' }
];

export const VALUABLES_LOCATION_OPTIONS = [
    { label: 'Awaiting Delivery', value: 'Awaiting Delivery' },
    { label: 'Received', value: 'Received' },
    { label: 'Placed', value: 'Placed' },
    { label: 'Returned', value: 'Returned' }
];
