import moment from 'moment/moment';
import { CALENDAR_TYPES } from './CalendarConstants';
import React, { Fragment } from 'react';
import LinkButton from '../../component/form/LinkButton';
import { joinDefined } from '../../util/strings';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import GatedComponent from '../../component/GatedComponent';
import { CalendarIcon } from '../../component/IconIndex';
import Tooltip from '../../component/form/Tooltip';

function AppointmentPill({ allocation, classes, onEdit }) {
    const { Funeral, Appointment } = allocation;
    const hours = moment(allocation.End).diff(allocation.Start, 'hours', true);
    const calType = CALENDAR_TYPES.find(e => e.type === allocation.Type);
    return (
        <div
            key={allocation.ID}
            className={classes.appointmentRow}
            style={{ opacity: moment(allocation.End) < moment() ? 0.5 : null }}
        >
            <div className={classes.bgHolder}>
                <CalendarIcon className={classes.bgIcon} />
            </div>
            <Tooltip placement="top" title={moment(allocation.Start).format('LL')}>
                <div
                    className={classes.appointmentTime}
                    style={{
                        backgroundColor: calType && calType.color
                    }}
                >
                    {
                        moment(allocation.Start)
                            .calendar()
                            .split(' at')[0]
                    }
                    <strong>{moment(allocation.Start).format('h:mma')}</strong>
                    <small>{allocation.Type}</small>
                </div>
            </Tooltip>

            {onEdit && (
                <GatedComponent isEnabledCode={'FM_ACCESS_Appointments_Edit'}>
                    {() => (
                        <Fab
                            size="small"
                            title="Edit"
                            className={classes.edit}
                            style={{ backgroundColor: calType.color, outline: 'revert' }}
                            onClick={e => (!!onEdit ? onEdit(e) : null)}
                        >
                            <Icon>edit</Icon>
                        </Fab>
                    )}
                </GatedComponent>
            )}

            <div className={classes.appointmentDescription}>
                <p>
                    {!!Funeral && Funeral.ID !== '0' ? (
                        <Fragment>
                            <LinkButton
                                text={Funeral.LegacyKey}
                                onClick={() => this.props.history.push(`/funeral/${Funeral.LegacyKey}/${Funeral.ID}`)}
                            />
                            {' | '}
                            {joinDefined([Funeral.FirstName, Funeral.MiddleName, Funeral.Surname], ' ')}
                            &nbsp;-&nbsp;
                        </Fragment>
                    ) : (
                        Appointment.Reason
                    )}
                    <small>
                        <br />
                        {allocation.LocationFlattened}.
                        <br />
                        {`${+hours.toFixed(2)} hour${hours === 1 ? '' : 's'}`}
                        {Appointment.Members.length > 0 && (
                            <>
                                {' '}
                                with{' '}
                                {joinDefined(
                                    Appointment.Members.map(obj => joinDefined([obj.FirstName, obj.Surname], ' ')),
                                    ', '
                                )}
                            </>
                        )}
                        .
                    </small>
                </p>
            </div>
        </div>
    );
}

const styles = ({ palette, funeralHome }) => ({
    appointmentRow: {
        display: 'flex',
        flexGrow: 1,
        margin: '0.5rem',
        border: '1px solid ' + palette.contentBackground[funeralHome],
        borderRadius: 4,
        alignContent: 'center',
        position: 'relative'
    },
    appointmentTime: {
        backgroundColor: palette.contentForeground[funeralHome],
        color: '#FFFFFF',
        padding: '0.5rem 1rem',
        width: 140,
        minWidth: 140,
        whiteSpace: 'pre',
        textAlign: 'center',
        borderRadius: '4px 0 0 4px',
        lineHeight: 1.125,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > small': {
            fontVariant: 'all-small-caps',
            opacity: 0.75
        }
    },
    appointmentDescription: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        padding: '1px 1rem',
        borderRadius: '0 4px 4px 0',
        '& > p': {
            margin: '3px 0',
            lineHeight: 0.975,
            '& small': {
                fontSize: 12
            }
        }
    },
    edit: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        right: -20,
        color: 'white'
    },
    bgHolder: {
        position: 'absolute',
        inset: 0,
        overflow: 'hidden',
        pointerEvents: 'none'
    },
    bgIcon: {
        opacity: 0.025,
        width: 300,
        height: 300,
        position: 'absolute',
        right: -24,
        top: 1,
        transform: 'rotate(20deg)',
        zIndex: 0
    }
});
export default withStyles(styles)(AppointmentPill);
