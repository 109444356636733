import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import AutoComplete from './AutoComplete';
import { joinDefined } from '../../util/strings';
import { getUtilitiesClient } from '../../apollo';
import { Query } from 'react-apollo';
import Spinner from '../Spinner';

const StaffAutoComplete = ({
    category,
    className,
    name,
    defaultMe = true,
    clearOnSelect = true,
    preloadAll,
    ...props
}) => {
    if (preloadAll) {
        let query = null;
        if (typeof category === 'string') {
            query = gql`
                query ReadAllMembers {
                    readMembers(groupCode: "${category}") {
                        edges {
                            node {
                                ID
                                Email
                                Surname
                                FirstName
                                LegacyKey
                            }
                        }
                    }
                }
            `;
        } else if (Array.isArray(category)) {
            query = gql`
                query ReadAllMembers {
                    ${category.map((cat, index) => {
                        return `members${index}: readMembers(groupCode: "${cat}") {
                            edges {
                                node {
                                    ID
                                    Email
                                    Surname
                                    FirstName
                                    LegacyKey
                                }
                            }
                        }`;
                    })}
                }
            `;
        }
        return (
            <Query query={query} client={getUtilitiesClient()}>
                {({ data, loading, error }) => {
                    if (loading)
                        return (
                            <Fragment>
                                <Spinner />
                                Loading Staff Members
                            </Fragment>
                        );

                    if (error) return <Fragment>Failed to load staff members</Fragment>;

                    const members = [];
                    let memberIDs = [];
                    for (let memberGroup in data) {
                        const group = data[memberGroup].edges;
                        for (let i = 0; i < group.length; i++) {
                            let member = group[i].node;
                            member.key = member.ID;
                            member.label = joinDefined([member.FirstName, member.Surname], ' ');
                            if (memberIDs.indexOf(member.ID) < 0) {
                                members.push(member);
                                memberIDs.push(member.ID);
                            }
                        }
                    }
                    members.sort((member1, member2) => {
                        return member1.label.localeCompare(member2.label);
                    });
                    memberIDs = members.map(member => {
                        return member.ID;
                    });
                    const { value } = props;
                    return (
                        <AutoComplete
                            label="Staff member..."
                            options={members}
                            limit={100}
                            value={
                                defaultMe &&
                                (memberIDs.indexOf(value) > -1 ? members[memberIDs.indexOf(value)].label : 'Me')
                            }
                            onChange={result => {
                                props.onSelect(null, result);
                            }}
                        />
                    );
                }}
            </Query>
        );
    }
    return (
        <GqlAutocomplete
            findById
            className={className}
            readAllQuery={readAllStaff}
            readOneQuery={readOneStaff}
            queryClient={getUtilitiesClient()}
            placeholder="Staff member..."
            labelFieldFunc={e => joinDefined([e.FirstName, e.Surname], ' ')}
            multiple={true}
            name={name}
            clearOnSelect={clearOnSelect}
            {...props}
        />
    );
};

const memberFragment = gql`
    fragment MemberFragment on Member {
        ID
        Email
        Surname
        FirstName
        LegacyKey
    }
`;

const readOneStaff = gql`
    ${memberFragment}
    query ReadMember($id: ID!) {
        readOneMember(ID: $id) {
            ...MemberFragment
        }
    }
`;

const readAllStaff = gql`
    ${memberFragment}

    query ReadMembers($contains: String) {
        readMembers(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...MemberFragment
                }
            }
        }
    }
`;

export default StaffAutoComplete;
