import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle, Hidden, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import EnquiryLookup from '../../component/EnquiryLookup';
import PrimaryButton, { OutlineButton } from '../../component/form/PrimaryButton';
import TickCircleIcon from '../../component/icon/TickCircleIcon';
import CloseIcon from '../../component/icon/CloseIcon';
import { isNullOrUndefined } from '../../util/objects';
import SearchIcon from '../../component/icon/SearchIcon';

class EnquirySearchModal extends Component {
    state = {
        selectedEnquiry: null
    };

    render() {
        const { open, classes } = this.props;
        const { selectedEnquiry } = this.state;
        return (
            <Dialog
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
                className={classes.root}
                classes={{
                    paper: classes.paper
                }}
                open={open}
                onClose={this.handleClose}
            >
                <DialogTitle className={classes.diagHeader}>
                    <Hidden xsDown>
                        <SearchIcon className={classes.starButton} />
                        <div className={classes.diagHeaderTitle}>Prefill From Existing Record</div>
                        <div className={classes.diagHeaderSubtitle}>Select an Enquiry or Prearrangement</div>
                    </Hidden>
                    <Hidden smUp>
                        <div className={classes.diagHeaderTitle}>Prefill From Existing Record</div>
                    </Hidden>
                </DialogTitle>
                <DialogContent>
                    <Paper elevation={0}>{this.renderBody()}</Paper>
                </DialogContent>
                <DialogActions>
                    <div className={classes.footer}>
                        <OutlineButton onClick={this.handleClose}>
                            <CloseIcon />
                            <Hidden smDown>Cancel</Hidden>
                        </OutlineButton>
                        <PrimaryButton disabled={isNullOrUndefined(selectedEnquiry)} onClick={this.handleAccept}>
                            <TickCircleIcon />
                            <Hidden smDown>Use Record</Hidden>
                        </PrimaryButton>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

    renderBody() {
        const { classes, initialSearchTerm, ignoreEnquiryId } = this.props;
        const { selectedEnquiry } = this.state;
        return (
            <div className={classes.body}>
                <div className={classes.enquiryLookup}>
                    <EnquiryLookup
                        initialSearchTerm={initialSearchTerm}
                        selectedEnquiry={selectedEnquiry}
                        ignoreEnquiryId={ignoreEnquiryId}
                        onSelectEnquiry={e => this.setState({ selectedEnquiry: e })}
                    />
                </div>
            </div>
        );
    }

    handleClose = e => {
        const { onClose } = this.props;
        onClose();
        this.setState({ selectedEnquiry: null });
    };

    handleAccept = e => {
        const { onAccept } = this.props;
        const { selectedEnquiry } = this.state;
        onAccept(selectedEnquiry);
        this.setState({ selectedEnquiry: null });
    };
}

const styles = ({ breakpoints, palette }) => ({
    root: {},
    body: {
        padding: 30,
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [breakpoints.down('xs')]: {
            padding: 6
        }
    },
    paper: {
        width: 1100,
        maxWidth: '95vw',
        [breakpoints.down('xs')]: {
            margin: 6
        }
    },
    enquiryLookup: {
        minHeight: 300
    },
    diagHeader: {
        background: palette.primary.main,
        padding: 30
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 24px',
        width: '100%'
    }
});

export default compose(withSnackbarMessage, withStyles(styles))(EnquirySearchModal);
